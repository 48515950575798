import type { Backgrounds, BackgroundLayer, OpenedBackgroundShorthand } from '../shorthand-css-data';
import type {
    CSSAstNode,
    EvaluatedAst,
    OpenedShorthand,
    SimpleOpenedShorthand,
    ShorthandOpener,
    ShorthandPart,
    ShorthandCloser,
} from '../shorthand-types';

import {
    imageSourceDataType,
    bgPositionDataType,
    bgSizeDataType,
    repeatStyleDataType,
    attachmentDataType,
    backgroundOriginDataType,
    backgroundClipDataType,
    backgroundColorDataType,
} from '../../css-data-types';
import { valueTextNode } from '../../tokenizers';
import {
    splitSimpleShorthandOpener,
    unorderedListShorthandOpener,
    getOpenedLayer,
    layersShorthandOpener,
    createShorthandOpener,
    getOpenedNode,
    shorthandCloserTemplate,
    createShorthandCloser,
    fixAstNodesPositions,
} from '../shorthand-parser-utils';

// background
export const openBackgroundShorthand = <V>(): ShorthandOpener<V, Backgrounds, OpenedBackgroundShorthand<V>> => {
    const bgLayerShorthandParts: ShorthandPart<V>[] = [
        { prop: 'background-image', dataType: imageSourceDataType },
        { prop: 'background-position', dataType: bgPositionDataType, multipleItems: true },
        { prop: 'background-size', dataType: bgSizeDataType, multipleItems: true },
        { prop: 'background-repeat', dataType: repeatStyleDataType, multipleItems: true },
        { prop: 'background-attachment', dataType: attachmentDataType },
        {
            prop: 'background-origin',
            dataType: backgroundOriginDataType,
            partOpener: splitSimpleShorthandOpener(['background-origin', 'background-clip']),
        },
        { prop: 'background-clip', dataType: backgroundClipDataType },
    ];
    const lastBgLayerShorthandParts: ShorthandPart<V>[] = [
        {
            prop: 'background-color',
            dataType: backgroundColorDataType,
        },
    ].concat(bgLayerShorthandParts);
    const openBackgroundShorthandLayerInner = unorderedListShorthandOpener<V, Backgrounds>(bgLayerShorthandParts);
    const openBackgroundShorthandLastLayerInner = unorderedListShorthandOpener<V, Backgrounds>(
        lastBgLayerShorthandParts
    );

    const backgroundShorthandOpener = createShorthandOpener({
        prop: 'background',
        parts: lastBgLayerShorthandParts as ShorthandPart<V, Backgrounds>[],
        shorthandOpener: (astNodes, api) =>
            layersShorthandOpener(
                'background',
                openBackgroundShorthandLayerInner,
                bgLayerShorthandParts,
                openBackgroundShorthandLastLayerInner,
                lastBgLayerShorthandParts
            )(astNodes, api),
    });

    const multipleItemProps = bgLayerShorthandParts
        .filter((part) => part.multipleItems)
        .map((part) => part.prop as Backgrounds);

    return (shortHand, api) => {
        const openedShorthand = backgroundShorthandOpener(shortHand, api);

        const layers: OpenedShorthand<V>[] = [];
        let currLayer: OpenedShorthand<V> | undefined;
        for (let i = 0; (currLayer = getOpenedLayer(openedShorthand, i, multipleItemProps)) !== undefined; i++) {
            layers.push(currLayer);
        }

        const color = layers[0]['background-color'] as EvaluatedAst<V>;
        for (const layer of layers) {
            delete layer['background-color'];
        }

        return {
            layers: layers as BackgroundLayer<V>[],
            color,
        };
    };
};

export const closeBackgroundShorthand =
    <V>(): ShorthandCloser<V, Backgrounds, OpenedBackgroundShorthand<V>> =>
    (opened, api, detachExpression) => {
        const backgroundLayerCloser = createShorthandCloser<V, Backgrounds>(
            shorthandCloserTemplate<Backgrounds>`${'background-image'} ${'background-position'} / ${'background-size'} ${'background-repeat'} ${'background-attachment'} ${'background-origin'} ${'background-clip'}`
        );
        let nonColorLayers: CSSAstNode<V>[] = [];
        opened.layers.forEach((layer) => {
            nonColorLayers = nonColorLayers.concat(
                backgroundLayerCloser(layer as unknown as SimpleOpenedShorthand<V, Backgrounds>, api, detachExpression)
            );
            nonColorLayers.push({ ...valueTextNode(','), type: ',' });
        });
        nonColorLayers.pop();
        return fixAstNodesPositions(nonColorLayers.concat(getOpenedNode(opened.color, api, detachExpression)), api);
    };
