export const StylablePanelTranslationKeys = {
    customizeDesign: 'StylablePanel_CustomizeDesign_Nav_Button_Label',
    elementree: {
        dropDownLabel: 'StylablePanel_Design_Element_Dropdown_Label',
        warningBanner: 'StylablePanel_Design_Temporary_CSS_Notification',
    },
    states: {
        viewMoreTooltip: 'StylablePanel_Design_State_Tooltip',
        regularLabel: 'StylablePanel_Design_State_Dropdown_Regular',
        native: {
            hoverLabel: 'StylablePanel_Design_State_Dropdown_Hover',
            focusLabel: 'StylablePanel_Design_State_Dropdown_Selected',
            disabledLabel: 'StylablePanel_Design_State_Dropdown_Disabled',
            activeLabel: 'StylablePanel_Design_State_Dropdown_Clicked',
        },
        overrides: {
            applyRegularDesign: 'StylablePanel_Design_State_Apply_Regular_Design',
            applyToOtherStates: 'StylablePanel_Design_State_Apply_to_Other_States',
            stateApplyToState: 'StylablePanel_Design_State_Apply_to_State',
            stateApplyToSpecificState: 'StylablePanel_Design_State_Apply_to_Specific_State',
        },
    },
    dialog: {
        stateOverrides: {
            title: 'StylablePanel_Design_State_Choose_States_Header',
            description: 'StylablePanel_Design_State_Choose_States_Apply_Design_Label',
            applyButtonLabel: 'StylablePanel_Design_State_Choose_States_Apply_Button',
        },
    },
    picker: {
        fill: {
            section: {
                solidTitle: 'StylablePanel_FillPicker_Main_Color_Tooltip',
                gradientTitle: 'StylablePanel_FillPicker_Main_Gradient_Tooltip',
                imageTitle: 'StylablePanel_FillPicker_Main_Image_Tooltip',
                patternTitle: 'StylablePanel_FillPicker_Main_Pattern_Tooltip',
            },
        },
        color: {
            myColorsTitle: 'StylablePanel_ColorPicker_MyColors_Label',
            myColorsInformationTooltip: 'ColorPicker2_MainPanel_CustomColors_Tooltip',
            addColorLabel: 'StylablePanel_ColorPicker_AddColor_Label',
            advancedSettingsBtnLabel: 'COLOR_PICKER_SECTION_advanced_settings_label',
            advancedSettingsBtnTooltipConnected: 'COLOR_PICKER_SECTION_advanced_settings_connected_tooltip',
            advancedSettingsBtnTooltipNotConnected: 'COLOR_PICKER_SECTION_advanced_settings_not_connected_tooltip',
            colorPickerInputConnectedTooltip: 'COLOR_PICKER_SECTION_connected_tooltip',
            themeSelection: {
                title: 'StylablePanel_ColorPicker_SiteColors_Label',
                changeLinkLabel: 'StylablePanel_ColorPicker_ChangeSiteColors_Label',
                changeLinkTooltip: 'StylablePanel_ColorPicker_ChangeSiteColors_Tooltip',
                doneLinkLabel: 'StylablePanel_ColorPicker_ChangeEditPalette_DoneButton',
            },
            palettePicker: {
                title: 'StylablePanel_ColorPicker_ChangeEditPalette_PalettesStrip',
            },
            colorAdder: {
                hexTabLabel: 'StylablePanel_ColorPicker_ColorSpace_HEX',
                rgbTabLabel: 'StylablePanel_ColorPicker_ColorSpace_RGB',
                hsbTabLabel: 'StylablePanel_ColorPicker_ColorSpace_HSB',
                cancelButtonLabel: 'StylablePanel_ColorPicker_ColorSpace_CancelButton',
                addButtonLabel: 'StylablePanel_ColorPicker_ColorSpace_AddButton',
            },
        },
        gradient: {
            colorPickerTitle: 'StylablePanel_Gradient_Color_ColorPicker_Header',
            myGradientsTitle: 'StylablePanel_FillPicker_Gradient_Main_MyGradients_Label',
            myGradientsInformationTooltip: 'StylablePanel_FillPicker_Gradient_Main_MyGradients_Tooltip',
            addGradientTooltip: 'StylablePanel_FillPicker_Gradient_Main_AddGradient_Tooltip',
            saveCustomGradient: 'StylablePanel_FillPicker_Gradient_Main_SaveGradient_Button',
            scaleLabel: 'StylablePanel_FillPicker_Gradient_Scale_Label',
            scaleTooltip: 'StylablePanel_FillPicker_Gradient_Scale_Tooltip',
            typeSelector: {
                headerLabel: 'StylablePanel_FillPicker_Gradient_Type_Header',
                linearLabel: 'StylablePanel_FillPicker_Gradient_Type_Linear_Label',
                radialLabel: 'StylablePanel_FillPicker_Gradient_Type_Radial_Label',
                stripesLabel: 'StylablePanel_FillPicker_Gradient_Type_Stripes_Label',
                stepsLabel: 'StylablePanel_FillPicker_Gradient_Type_Steps_Label',
                repeatingLabel: 'StylablePanel_FillPicker_Gradient_Type_Repeating_Label',
            },
            directionSelector: {
                defaultOptionLabel: 'StylablePanel_Picker_Gradient_DirectionSelector_Default_Option_Label',
                topLeftOptionLabel: 'StylablePanel_Picker_Gradient_DirectionSelector_TopLeft_Option_Label',
                topRightOptionLabel: 'StylablePanel_Picker_Gradient_DirectionSelector_TopRight_Option_Label',
                bottomRightOptionLabel: 'StylablePanel_Picker_Gradient_DirectionSelector_BottomRight_Option_Label',
                bottomLeftOptionLabel: 'StylablePanel_Picker_Gradient_DirectionSelector_BottomLeft_Option_Label',
            },
            actions: {
                save: 'StylablePanel_FillPicker_Mode_Apply',
                cancel: 'StylablePanel_FillPicker_Mode_Cancel',
                tooltips: {
                    rotate: 'StylablePanel_FillPicker_Gradient_Rotate_Tooltip',
                    focal: 'StylablePanel_FillPicker_Gradient_MoveRadialPoint_Tooltip',
                    flip: 'StylablePanel_FillPicker_Gradient_Reverse_Tooltip',
                    position: 'StylablePanel_FillPicker_Gradient_Focal_Point_Tooltip',
                },
                labels: {
                    rotate: 'StylablePanel_FillPicker_Gradient_Rotate_Title',
                    focal: 'StylablePanel_FillPicker_Gradient_MoveRadialPoint_Title',
                    position: 'StylablePanel_FillPicker_Gradient_Position_Title',
                },
            },
            rotateTooltip: 'StylablePanel_FillPicker_Gradient_Rotate_Tooltip', // TODO: remove after fill picker experiment is finished
            focalTooltip: 'StylablePanel_FillPicker_Gradient_MoveRadialPoint_Tooltip', // TODO: remove after fill picker experiment is finished
            closeTooltip: 'StylablePanel_FillPicker_Gradient_Close_Tooltip', // TODO: remove after fill picker experiment is finished
        },
        image: {
            changeImageButtonLabel: 'StylablePanel_FillPicker_Image_ChangeButton',
            scaleLabel: 'StylablePanel_FillPicker_Scale_Label',
            scaleInformationTooltip: 'StylablePanel_FillPicker_Image_Scale_Tooltip',
            sizeSelector: {
                label: 'StylablePanel_FillPicker_Image_ScaleOptions_Label',
                coverOptionLabel: 'StylablePanel_FillPicker_Image_ScaleOptions_Dropdown_Fill',
                containOptionLabel: 'StylablePanel_FillPicker_Image_ScaleOptions_Dropdown_Fit',
                customOptionLabel: 'StylablePanel_FillPicker_Image_ScaleOptions_Dropdown_Custom',
                tileOptionLabel: 'StylablePanel_FillPicker_Image_ScaleOptions_Dropdown_Tile',
                informationTooltip: 'StylablePanel_FillPicker_Image_ScaleOptions_Tooltip',
            },
            actions: {
                save: 'StylablePanel_FillPicker_Mode_Apply',
                cancel: 'StylablePanel_FillPicker_Mode_Cancel',
                tooltips: {
                    focal: 'StylablePanel_FillPicker_Image_MoveFocalPoint_Tooltip',
                },
                labels: {
                    focal: 'StylablePanel_FillPicker_Image_PositionImage_Title',
                },
            },
            focalTooltip: 'StylablePanel_FillPicker_Image_MoveFocalPoint_Tooltip', // TODO: remove after fill picker experiment is finished
        },
        shadow: {
            colorLabel: 'StylablePanel_Design_Shadows_Settings_Color_Label',
            innerShadowToggleSectionLabel: 'StylablePanel_Design_Shadows_Settings_Type_Label',
            innerShadowToggleLabel: 'StylablePanel_Design_Shadows_Settings_Type_Inner_Label',
            outerShadowToggleLabel: 'StylablePanel_Design_Shadows_Settings_Type_Outer_Label',
            directionInputLabel: 'StylablePanel_Design_Shadows_Settings_Angle_Label',
            distanceInputLabel: 'StylablePanel_Design_Shadows_Settings_Distance_Label',
            blurInputLabel: 'StylablePanel_Design_Shadows_Settings_Blur_Label',
            sizeInputLabel: 'StylablePanel_Design_Shadows_Settings_Size_Label',
        },
        advancedSettings: {
            title: 'COLOR_PICKER_SECTION_advanced_settings_panel_title',
            description: 'COLOR_PICKER_SECTION_advanced_settings_description',
            learnMore: 'COLOR_PICKER_SECTION_advanced_settings_learn_more_link',
            editDefaults: 'COLOR_PICKER_SECTION_advanced_settings_edit_defaults_link',
            editDefaultsTooltip: 'COLOR_PICKER_SECTION_advanced_settings_edit_defaults_tooltip',
            labels: {
                color_11: 'COLOR_PICKER_SECTION_advanced_settings_prim_background_label',
                color_12: 'COLOR_PICKER_SECTION_advanced_settings_sec_background_label',
                color_47: 'COLOR_PICKER_SECTION_advanced_settings_lines_label',
                color_45: 'COLOR_PICKER_SECTION_advanced_settings_titles_label',
                color_46: 'COLOR_PICKER_SECTION_advanced_settings_subtitles_label',
                color_15: 'COLOR_PICKER_SECTION_advanced_settings_body_text_label',
                color_14: 'COLOR_PICKER_SECTION_advanced_settings_sec_text_label',
                color_18: 'COLOR_PICKER_SECTION_advanced_settings_links_label',
                color_48: 'COLOR_PICKER_SECTION_advanced_settings_prim_button_fill_label',
                color_51: 'COLOR_PICKER_SECTION_advanced_settings_prim_button_fill_hover_label',
                color_54: 'COLOR_PICKER_SECTION_advanced_settings_prim_button_fill_disabled_label',
                color_49: 'COLOR_PICKER_SECTION_advanced_settings_prim_button_border_label',
                color_52: 'COLOR_PICKER_SECTION_advanced_settings_prim_button_border_hover_label',
                color_55: 'COLOR_PICKER_SECTION_advanced_settings_prim_button_border_disabled_label',
                color_50: 'COLOR_PICKER_SECTION_advanced_settings_prim_button_text_label',
                color_53: 'COLOR_PICKER_SECTION_advanced_settings_prim_button_text_hover_label',
                color_56: 'COLOR_PICKER_SECTION_advanced_settings_prim_button_text_disabled_label',
                color_57: 'COLOR_PICKER_SECTION_advanced_settings_sec_button_fill_label',
                color_60: 'COLOR_PICKER_SECTION_advanced_settings_sec_button_fill_hover_label',
                color_63: 'COLOR_PICKER_SECTION_advanced_settings_sec_button_fill_disabled_label',
                color_58: 'COLOR_PICKER_SECTION_advanced_settings_sec_button_border_label',
                color_61: 'COLOR_PICKER_SECTION_advanced_settings_sec_button_border_hover_label',
                color_64: 'COLOR_PICKER_SECTION_advanced_settings_sec_button_border_disabled_label',
                color_59: 'COLOR_PICKER_SECTION_advanced_settings_sec_button_text_label',
                color_62: 'COLOR_PICKER_SECTION_advanced_settings_sec_button_text_hover_label',
                color_65: 'COLOR_PICKER_SECTION_advanced_settings_sec_button_text_disabled_label',
            },
        },
    },
    component: {
        dimensionInput: {
            tooltip: {
                invalidUnit: 'StylablePanel_Design_Invalid_CSS_Input_Notification',
                unsupportedUnit: 'StylablePanel_Design_Invalid_Input_Unit_Notification',
            },
            unitDropdown: {
                unitlessLabel: 'StylablePanel_Design_Unitless_Fallback_Label',
            },
        },
    },
    controller: {
        layers: {
            layerAction: {
                hideLabel: 'StylablePanel_Design_Fills_Layer_Hide_Tooltip',
                showLabel: 'StylablePanel_Design_Fills_Layer_Show_Tooltip',
                duplicateLabel: 'StylablePanel_Design_Fills_Layer_Duplicate_Tooltip',
                removeLabel: 'StylablePanel_Design_Fills_Layer_Delete_Tooltip',
            },
        },
        fills: {
            title: 'StylablePanel_Design_Fills_Tab_Title',
            fillPickerTitle: 'StylablePanel_FillPicker_Main_Header',
            addLayerButtonLabel: 'StylablePanel_Design_Fills_Layer_Add_Text',
            opacityInformationTooltip: 'StylablePanel_Design_Fills_Layer_Opacity_Tooltip',
            layerThumbnailTooltip: 'StylablePanel_Design_Fills_Layer_Change_Tooltip',
        },
        borders: {
            title: 'StylablePanel_Design_Borders_Tab_Title',
            sectionLabel: 'StylablePanel_Design_Borders_BorderDesign_Label',
            sectionTooltip: 'StylablePanel_Design_Borders_BorderDesign_Tooltip_Text',
            fillPickerTitle: 'StylablePanel_Design_Borders_Fill_FillPicker_Header',
            widthTooltip: 'StylablePanel_Design_Borders_Width_Tooltip',
            styleTooltip: 'StylablePanel_Design_Borders_Style_Tooltip',
            fillTooltip: 'StylablePanel_Design_Borders_Fill_Tooltip',
            lockTooltip: 'StylablePanel_Design_Borders_Lock_Tooltip',
            unlockTooltip: 'StylablePanel_Design_Borders_Unlock_Tooltip',
        },
        corners: {
            title: 'StylablePanel_Design_Corners_Tab_Title',
            sectionLabel: 'StylablePanel_Design_Corners_Radius_Label',
            sectionTooltip: 'StylablePanel_Design_Corners_Radius_Tooltip',
            lockTooltip: 'StylablePanel_Design_Corners_Lock_Tooltip',
            unlockTooltip: 'StylablePanel_Design_Corners_Unlock_Tooltip',
        },
        spacing: {
            title: 'StylablePanel_Controller_Spacing_Title',
        },
        edge: {
            lockTooltip: 'StylablePanel_Design_Layout_Padding_Lock_Tooltip',
            unlockTooltip: 'StylablePanel_Design_Layout_Padding_Unlock_Tooltip',
        },
        padding: {
            title: 'StylablePanel_Design_Button_Layout_Padding_Label',
            sectionTooltip: 'StylablePanel_Design_Button_Layout_Padding_Tooltip',
        },
        margin: {
            title: 'StylablePanel_Design_Button_Layout_Margin_Label',
            sectionTooltip: 'StylablePanel_Design_Button_Layout_Margin_Tooltip',
        },
        shadows: {
            title: 'StylablePanel_Design_Shadows_Tab_Title',
            colorPickerTitle: 'StylablePanel_Design_Shadows_Settings_Color_ColorPicker_Header',
            shadowPickerTitle: 'StylablePanel_Design_Shadows_Settings_Header',
            addLayerButtonLabel: 'StylablePanel_Design_Shadows_AddShadow_Text',
            layerThumbnailTooltip: 'StylablePanel_Design_Shadows_CustomizeShadow_Tooltip',
        },
        layout: {
            title: 'StylablePanel_Design_Layout_Tab_Title',
        },
        text: {
            title: 'StylablePanel_Design_Text_Tab_Title',
            showLabel: 'StylablePanel_Design_Text_Tab_ShowText_Label',
            fontThemeSelector: {
                label: 'StylablePanel_Design_Text_Tab_Theme_Label',
                informationTooltip: 'StylablePanel_Design_Text_Tab_Theme_Tooltip',
                customOption: 'text_editor_theme_custom',
                saveTheme: 'text_editor_theme_save2',
            },
            fontFamilySelectorLabel: 'StylablePanel_Design_Text_Tab_Font_Label',
            fontFamilySelectorInformationTooltip: 'StylablePanel_Design_Text_Tab_Font_Tooltip',
            fontFamilySelectorDefaultOption: 'StylablePanel_Design_Text_Tab_Font_Option_Default',
            fontWeightSelector: {
                label: 'StylablePanel_Design_Text_Tab_FontWeight_Label',
                thinOptionLabel: 'StylablePanel_Design_Text_Tab_FontWeight_Dropdown_Thin',
                extraLightOptionLabel: 'StylablePanel_Design_Text_Tab_FontWeight_Dropdown_ExtraLight',
                lightOptionLabel: 'StylablePanel_Design_Text_Tab_FontWeight_Dropdown_Light',
                normalOptionLabel: 'StylablePanel_Design_Text_Tab_FontWeight_Dropdown_Normal',
                mediumOptionLabel: 'StylablePanel_Design_Text_Tab_FontWeight_Dropdown_Medium',
                semiBoldOptionLabel: 'StylablePanel_Design_Text_Tab_FontWeight_Dropdown_SemiBold',
                boldOptionLabel: 'StylablePanel_Design_Text_Tab_FontWeight_Dropdown_Bold',
                extraBoldOptionLabel: 'StylablePanel_Design_Text_Tab_FontWeight_Dropdown_ExtraBold',
                heavyOptionLabel: 'StylablePanel_Design_Text_Tab_FontWeight_Dropdown_Black',
            },
            fontSizeSelectorLabel: 'StylablePanel_Design_Text_Tab_FontSize_Label',
            fontSizeSelectorInformationTooltip: 'StylablePanel_Design_Text_Tab_FontSize_Tooltip',
            colorPickers: {
                textTitle: 'StylablePanel_Design_Text_Tab_TextColor_ColorPicker_Header',
                backgroundTitle: 'StylablePanel_Design_Text_Tab_HighlightColor_ColorPicker_Header',
                strokeTitle: 'StylablePanel_Design_Text_Tab_OutlineColor_ColorPicker_Header',
            },
            tooltips: {
                bold: 'StylablePanel_Design_Text_Tab_Bold_Tooltip',
                italic: 'StylablePanel_Design_Text_Tab_Italic_Tooltip',
                underline: 'StylablePanel_Design_Text_Tab_Underline_Tooltip',
                strikethrough: 'StylablePanel_Design_Text_Tab_Strikethrough_Tooltip',
                textColor: 'StylablePanel_Design_Text_Tab_TextColor_Tooltip',
                highlightColor: 'StylablePanel_Design_Text_Tab_HighlightColor_Tooltip',
                outlineColor: 'StylablePanel_Design_Text_Tab_OutlineColor_Tooltip',
                capitalization: 'StylablePanel_Design_Text_Tab_Capitalization_Tooltip',
                textDirection: 'StylablePanel_Design_Text_Tab_TextDirection_Tooltip',
                textAlignment: 'StylablePanel_Design_Text_Tab_Alignment_Tooltip',
            },
            letterSpacingSelectorLabel: 'StylablePanel_Design_Text_Tab_CharacterSpacing_Label',
            letterSpacingSelectorInformationTooltip: 'StylablePanel_Design_Text_Tab_CharacterSpacing_Tooltip',

            lineHeightSelectorInformationTooltip: 'text_editor_line_spacing_tooltip',
            lineHeightSelectorLabel: 'text_editor_line_spacing_label',
            lineHeightSelectorAutomatic: 'text_editor_line_spacing_automatic',
            lineHeightSelectorCustom: 'text_editor_line_spacing_customize',
        },
        icon: {
            title: 'StylablePanel_Design_Icon_Tab_Title',
            showLabel: 'StylablePanel_Design_Button_Icon_Tab_Show_Label',
            sizeLabel: 'StylablePanel_Design_Icon_Tab_Size_Label',
            colorLabel: 'StylablePanel_Design_Icon_Tab_Color_Label',
            colorPickerTitle: 'StylablePanel_Design_Icon_Tab_Color_ColorPicker_Header',
            angleInputLabel: 'StylablePanel_Design_Rotate_Label',
        },
        separators: {
            title: 'StylablePanel_Design_Separators_Tab_Label',
            showLabel: 'StylablePanel_Design_Separators_Icons_Label',
            colorLabel: 'StylablePanel_Design_Separators_Color_Opacity_Label',
        },
        effects: {
            title: 'StylablePanel_Controller_Effects_Title',
        },
    },
    notifications: {
        cornerRadiusResetText: 'Notifications_CornerRadius_Reset_Text',
        borderFillResetText: 'Notifications_BorderFill_Reset_Text',
        tracking: {
            locked: 'Controller_Locked',
            unlocked: 'Controller_Unlocked',
        },
    },
};
