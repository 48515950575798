import { DIMENSION_ID, getDimensionInputDefaults } from '@wix/stylable-panel-common';
import { DeclarationMap, StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import { INITIAL_IMAGE_SOURCE, INITIAL_UNIVERSAL } from '@wix/shorthands-opener';
import React, { useCallback, useMemo } from 'react';
import {
    BorderBottomLeftRadiusInputVisualizer,
    BorderBottomRightRadiusInputVisualizer,
    BorderTopLeftRadiusInputVisualizer,
    BorderTopRightRadiusInputVisualizer,
    getDimensionConfig,
} from '../../generated-visualizers';
import { useBorderOutsideChange, useTranslate } from '../../hooks';
import type { DeclarationIO, OpenedDeclarationList } from '../../types';
import {
    createDeclarationMapFromVisualizerValue,
    getDeclarationValue,
    getSimpleShorthandChange,
    getVisualizerWrapper,
} from '../../utils';
import type { DimensionVisualizer } from '../../visualizer-factories';
import { getCornerProp, getCornerTitle } from './corner-utils';
import type { CornerDeclarationMap, CornerName, CornerProps, CornerVisualizerProps } from './corner-visualizer-types';
import { classes, style } from './corner-visualizer.st.css';

type CornerInputVisualizerComp = DimensionVisualizer<CornerProps>;

const cornerInputVisualizerMap: Record<CornerName, CornerInputVisualizerComp> = {
    topLeft: BorderTopLeftRadiusInputVisualizer as CornerInputVisualizerComp,
    topRight: BorderTopRightRadiusInputVisualizer as CornerInputVisualizerComp,
    bottomRight: BorderBottomRightRadiusInputVisualizer as CornerInputVisualizerComp,
    bottomLeft: BorderBottomLeftRadiusInputVisualizer as CornerInputVisualizerComp,
};

export const CornerVisualizerConfig = {
    resetBorders: true,
};

export type CornerInputProps = {
    corner: CornerName;
    focused: string;
    outsideValues: DeclarationMap;
    handleChangeFromWithin: (change: boolean) => void;
    linked: boolean;
    openedDeclarationList: OpenedDeclarationList<CornerProps>;
    handleChangeHover: (hover: string) => void;
    handleChangeFocus: (focused: string) => void;
    props: CornerVisualizerProps;
};

export function CornerInput({
    corner,
    outsideValues,
    handleChangeFromWithin,
    linked,
    openedDeclarationList,
    handleChangeHover,
    handleChangeFocus,
    props,
}: CornerInputProps) {
    const { drivers, propertyWrappers, DefaultPropertyWrapper, onDelete, onChange, panelHost } = props;

    const translate = useTranslate(panelHost);
    const CornerInputVisualizer = useMemo(() => cornerInputVisualizerMap[corner], [corner]);

    const cornerProp = useMemo(() => getCornerProp(corner), [corner]);
    const CornerInputWrapper = useMemo(
        () => getVisualizerWrapper(propertyWrappers, cornerProp, DefaultPropertyWrapper),
        [DefaultPropertyWrapper, cornerProp, propertyWrappers]
    );

    const dimensionConfig = getDimensionConfig({
        id: DIMENSION_ID.CORNERS,
        dimensionUnits: panelHost?.dimensionUnits,
        dimensionKeywords: panelHost?.dimensionKeywords,
    });

    const mouseEnter = useCallback(
        (inputName: string) => {
            handleChangeHover(inputName);
        },
        [handleChangeHover]
    );

    const mouseLeave = useCallback(() => {
        handleChangeHover('');
    }, [handleChangeHover]);

    const getBorderImageSource = useCallback((): string | undefined => {
        const { 'border-image-source': source } = outsideValues;
        return source !== INITIAL_IMAGE_SOURCE && source !== INITIAL_UNIVERSAL ? source : undefined;
    }, [outsideValues]);

    const handleBorderOutsideChange = useBorderOutsideChange(props);

    const handleInputCompChange = useCallback(
        (corner: CornerName, value?: string) => {
            if (!onChange) {
                return;
            }

            const prop = linked ? 'border-radius' : getCornerProp(corner);
            const toChange = getSimpleShorthandChange<'border-radius', CornerProps>(
                'border-radius',
                { [prop]: value } as CornerDeclarationMap,
                openedDeclarationList,
                props
            );
            onChange(toChange);
        },
        [linked, onChange, openedDeclarationList, props]
    );

    const changeCorner = useCallback(
        (corner: CornerName, newValue?: string) => {
            handleChangeFromWithin(true);
            handleInputCompChange(corner, newValue?.trim());

            if (CornerVisualizerConfig.resetBorders) {
                const borderImageSource = getBorderImageSource();
                if (borderImageSource && handleBorderOutsideChange) {
                    handleBorderOutsideChange(borderImageSource, outsideValues);

                    const message = StylablePanelTranslationKeys.notifications.borderFillResetText;

                    panelHost?.onEditorNotify &&
                        panelHost.onEditorNotify({
                            type: 'warning',
                            title: translate(message),
                            message,
                        });
                }
            }
        },
        [
            handleChangeFromWithin,
            handleInputCompChange,
            getBorderImageSource,
            handleBorderOutsideChange,
            outsideValues,
            panelHost,
            translate,
        ]
    );

    const wrapperProps: DeclarationIO<CornerProps> = {
        value: getDeclarationValue(openedDeclarationList, cornerProp, getDimensionInputDefaults(panelHost).cornerVisualizer.radius),
        onChange: (declarations) => {
            const changeValue = createDeclarationMapFromVisualizerValue(declarations, { value: [], drivers })[
                cornerProp
            ];
            changeCorner(corner, changeValue);
        },
        onDelete,
    };

    const handleMouseEnter = useCallback(() => mouseEnter(corner), [corner, mouseEnter]);
    const handleFocus = useCallback(() => handleChangeFocus(corner), [corner, handleChangeFocus]);
    const handleBlur = useCallback(() => handleChangeFocus(''), [handleChangeFocus]);

    return (
        <div
            className={style(classes.inputContainer, { cornerName: corner }, `${corner}Corner`)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={mouseLeave}
        >
            <CornerInputWrapper
                title={getCornerTitle(corner) ?? ''}
                mainProp={cornerProp}
                claims={new Set([cornerProp])}
                {...wrapperProps}
            >
                <CornerInputVisualizer
                    className={classes.inputElement}
                    {...wrapperProps}
                    config={dimensionConfig}
                    drivers={drivers}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    panelHost={panelHost}
                />
            </CornerInputWrapper>
        </div>
    );
}
