import React, { useCallback } from 'react';
import { CompositeBlock } from '@wix/stylable-panel-components';
import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';

import { useTranslate } from '../../hooks';
import { PanelEventList } from '../../hosts/bi';
import { FontFamilyVisualizer, EMPTY_FONT_FAMILY } from '../font-family-visualizer/font-family-visualizer';
import { createDeclarationMapFromVisualizerValue, createVisualizerValueFromDeclarationMap } from '../../utils';
import type { OpenedDeclarationArray } from '../../declaration-types';

import { classes } from './text-visualizer.st.css';
import type { TextVisualizerProps } from './use-text-visualizer';

export type FontFamilyBlockProps = {
    value: string;
    props: TextVisualizerProps;
    changeFromBlock: (prop: string, valueFunction: (value: string) => string, value: string) => void;
};

export function FontFamilyBlock({ value, props, changeFromBlock }: FontFamilyBlockProps) {
    const { drivers, panelHost } = props;

    const translate = useTranslate(panelHost);

    const onSelectFontFamily = useCallback(
        (change: string) => {
            changeFromBlock(
                'font-family',
                (changeValue) => (changeValue !== EMPTY_FONT_FAMILY ? changeValue : ''),
                change
            );

            if (!panelHost) {
                return;
            }
            const { reportBI } = panelHost;
            const { TEXT_DROPDOWN_SELECT } = PanelEventList;
            reportBI?.(TEXT_DROPDOWN_SELECT, { font: change });
        },
        [changeFromBlock, panelHost]
    );

    const handleOnChange = useCallback(
        (change: OpenedDeclarationArray<'font-family'>) => {
            const { 'font-family': fontFamily } = createDeclarationMapFromVisualizerValue(change, {
                value: [],
                drivers,
            });
            if (fontFamily) {
                onSelectFontFamily(fontFamily);
            }
        },
        [drivers, onSelectFontFamily]
    );

    return (
        <CompositeBlock
            key="font-family"
            className={classes.controllerBlock}
            title={translate(StylablePanelTranslationKeys.controller.text.fontFamilySelectorLabel)}
            information={translate(StylablePanelTranslationKeys.controller.text.fontFamilySelectorInformationTooltip)}
            divider
        >
            <FontFamilyVisualizer
                className={classes.dropDown}
                drivers={drivers}
                value={createVisualizerValueFromDeclarationMap({
                    'font-family': value,
                })}
                panelHost={panelHost}
                onChange={handleOnChange}
            />
        </CompositeBlock>
    );
}
