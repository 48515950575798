import { DEFAULT_PLANE } from '@wix/stylable-panel-common';
import { Text } from '@wix/stylable-panel-components';
import type { DeclarationMap } from '@wix/stylable-panel-drivers';
import React from 'react';
import type { CustomInputProps } from '../drivers';
import { getTranslate } from '../hosts/translate';
import type { ControllerComponent, ExtendedControllerProps } from '../types';
import type { PropsToShorthandFunction } from '../utils/props-to-shorthand';
import { classes, style } from './common-controller.st.css';

const { hasOwnProperty } = Object.prototype;

export function CommonControllerFactory(
    baseProp: string,
    inputProps: string[],
    InputComp: React.ComponentType<CustomInputProps>,
    propsToShorthand: PropsToShorthandFunction,
    titleKey?: string,
    outsideProps?: string[],
    mainController = false
): React.ComponentClass<ExtendedControllerProps> {
    return class CommonController extends React.Component<ExtendedControllerProps> implements ControllerComponent {
        public static INPUT_PROPS: string[] = inputProps;
        public static OUTSIDE_PROPS: string[] | undefined = outsideProps;
        public static IS_CONTROLLER = true;

        public render() {
            const { value, siteVarsDriver, plane = DEFAULT_PLANE, panelHost, onChange, className } = this.props;
            const inputValue = propsToShorthand(value);

            const translate = getTranslate(panelHost);

            return (
                <div
                    className={style(classes.root, { mainController, plane }, className)}
                    data-common-controller-highlight-parts
                >
                    {titleKey && <Text className={classes.title}>{translate(titleKey)}</Text>}
                    <InputComp
                        className={classes.input}
                        value={inputValue}
                        outsideValues={this.getOutsideValues()}
                        siteVarsDriver={siteVarsDriver}
                        panelHost={panelHost}
                        plane={plane}
                        onChange={this.handleInputCompChange}
                        onOutsideChange={onChange}
                    />
                </div>
            );
        }

        private handleInputCompChange = (value: string) => {
            const { onChange } = this.props;
            if (onChange) {
                onChange(
                    this.createCompleteChangeFromPartial({
                        [baseProp]: value,
                    })
                );
            }
        };

        private createCompleteChangeFromPartial(value: DeclarationMap) {
            const newChange: DeclarationMap = Object.assign({}, value);

            inputProps.forEach((prop: string) => {
                if (!hasOwnProperty.call(newChange, prop)) {
                    newChange[prop] = undefined;
                }
            });

            return newChange;
        }

        private getOutsideValues() {
            if (!outsideProps || outsideProps.length === 0) {
                return {};
            }

            return outsideProps.reduce((outsideValues, prop) => {
                outsideValues[prop] = this.props.value[prop];
                return outsideValues;
            }, {} as DeclarationMap);
        }
    };
}
