import type { Descriptors, Delimiters } from '../tokenizers';
import type {
    AstItem,
    PredicateMatch,
    PredicateIndexMatch,
    DataTypePredicate,
    PredicatePrefix,
    DataType,
} from './data-types-types';
import {
    DataTypeType,
    KeywordsMap,
    INTERNAL_VALUE_SEPERATOR,
    DATA_TYPES_MAP,
    getInitialValue,
} from './data-types-consts';

export interface Dimension {
    number: number;
    unit: string;
    raw: string;
}

export interface DimensionPredicateOptions {
    units?: string | KeywordsMap;
    min?: number;
    max?: number;
    nonZero?: boolean;
    integer?: boolean;
}

const DIMENSION_REGEX = /^[+-]?(\d+|\d*\.\d+|\d*\.?\d+[eE][+-]?\d+)(\D*$)/;
const DIMENSION_REGEX_UNIT_INDEX = 2;
const SPX_FORMATTER_REGEX = /^spx\((-?[\d.]+)\)$/;
const SPX_ONLY_REGEX = /^(-?[\d.]+)spx$/;
const CONTAINING_SPX_REGEX = /(-?[\d.]+)spx/;
// TODO: support unicode and escaped characters
const IDENTIFIER_REGEX = /^-?[A-Za-z_]([A-Za-z0-9_-])*/;
const HEX_COLOR_REGEX = /^#([a-fA-F\d]{3}){1,2}$/;
const EMPTY_UNIT = '';
export const EMPTY_DIMENSION: Dimension = { number: 0, unit: EMPTY_UNIT, raw: '0' };

const getSpxValue = (text: string) => {
    const [_, spxFormatterValue] = SPX_FORMATTER_REGEX.exec(text) ?? [];
    const [__, spxValue] = SPX_ONLY_REGEX.exec(text) ?? [];
    return spxFormatterValue ?? spxValue;
};

export const parseDimension = (text: string): Dimension | undefined => {
    const number = parseFloat(text);
    const spxValue = getSpxValue(text);
    if (typeof spxValue !== 'undefined') {
        return {
            number: Number(spxValue),
            unit: 'spx',
            raw: `spx(${spxValue})`,
        };
    }
    if (!isNaN(number)) {
        const dimensionMatch = DIMENSION_REGEX.exec(text);
        return dimensionMatch
            ? { number, unit: dimensionMatch[DIMENSION_REGEX_UNIT_INDEX] || EMPTY_UNIT, raw: text }
            : undefined;
    }
    return;
};

export const unorderedListPredicate =
    (keywords: string | KeywordsMap): DataTypePredicate =>
    (ast) =>
        ast.type === 'text' && (typeof keywords === 'string' ? ast.text === keywords : keywords.has(ast.text));

export const functionPredicate =
    (functions: string | KeywordsMap): DataTypePredicate =>
    (ast) =>
        ast.type === 'call' && (typeof functions === 'string' ? ast.name === functions : functions.has(ast.name));

// <hex-color>
// TODO: Match #RRGGBBAA? (Only Internet Explorer doesn't support it)
export const hexColorPredicate = (): DataTypePredicate => (ast) =>
    ast.type === 'text' && HEX_COLOR_REGEX.test(ast.text);

// <string>
export const stringPredicate = (): DataTypePredicate => (ast) => ast.type === 'string';

export const dimensionPredicate =
    ({ units, min, max, nonZero = false, integer = false }: DimensionPredicateOptions = {}): DataTypePredicate =>
    (ast) => {
        if (ast.type === 'text') {
            const dimension = parseDimension(ast.text);
            if (
                !dimension ||
                (min !== undefined && dimension.number < min) ||
                (max !== undefined && dimension.number > max)
            ) {
                return false;
            }
            if (nonZero && dimension.number === 0) {
                return false;
            }
            if (integer) {
                return dimension.number % 1 === 0;
            }
            if (min === undefined && max === undefined && dimension.number === 0) {
                return true;
            }
            if (typeof units === 'string') {
                return dimension.unit === units;
            }
            return units ? units.has(dimension.unit) : !dimension.unit;
        }

        return false;
    };

export const customIdentPredicate =
    (forbiddenKeywords?: string | KeywordsMap): DataTypePredicate =>
    (ast) =>
        ast.type === 'text' &&
        !(forbiddenKeywords && unorderedListPredicate(forbiddenKeywords)(ast)) &&
        IDENTIFIER_REGEX.test(ast.text);

export const typePredicate =
    (typeList: Array<'string' | 'call' | 'text' | ',' | '/'>): DataTypePredicate =>
    (ast, index, items) => {
        const startIndex = index ?? 0;
        const astItems = items ?? [{ value: ast }];

        let item: AstItem | undefined;
        let i = startIndex;
        let matchAmount = 0;
        while ((item = astItems[i++])) {
            const itemType = item.value.type;
            if (typeList.every((typeItem) => itemType !== typeItem)) {
                break;
            }
            matchAmount++;
        }

        return matchAmount;
    };

export const curlyBracesPredicate =
    (predicates: DataTypePredicate[], min: number, max: number): DataTypePredicate =>
    (ast, index, items) => {
        const startIndex = index ?? 0;
        const astItems = items ?? [{ value: ast }];

        let item: AstItem | undefined;
        let i = startIndex;
        let matchAmount = 0;
        while ((item = astItems[i++])) {
            let match: PredicateMatch | undefined = undefined;
            for (const predicate of predicates) {
                const predicateMatch = predicate(item.value, i - 1, items);
                if (predicateMatch) {
                    match = predicateMatch;
                    break;
                }
            }
            if (!match || ++matchAmount === max) {
                break;
            }
        }

        return matchAmount >= min ? matchAmount : false;
    };

export const asteriskPredicate =
    (predicate: DataTypePredicate): DataTypePredicate =>
    (ast, index, items) => {
        const startIndex = index ?? 0;
        const astItems = items ?? [{ value: ast }];

        let item: AstItem | undefined;
        let i = startIndex;
        let matchAmount = 0;
        while ((item = astItems[i])) {
            if (!predicate(item.value, i++, astItems)) {
                break;
            }
            matchAmount++;
        }

        return matchAmount;
    };

export const seperatorPredicate = (): DataTypePredicate => (ast) =>
    ast.type === '/' && ast.text === INTERNAL_VALUE_SEPERATOR;

export const seperatorPrefix = (dataType: DataTypeType): PredicatePrefix => ({
    dataType,
    prefixChar: INTERNAL_VALUE_SEPERATOR,
});

export const predicateUnion =
    (predicates: DataTypePredicate[]): DataTypePredicate =>
    (ast, index, items, prev) => {
        for (const predicate of predicates) {
            const predicateMatch = predicate(ast, index, items, prev);
            if (predicateMatch) {
                return predicateMatch;
            }
        }
        return false;
    };

export const findPredicateIndexMatch = (
    items: AstItem[],
    predicate: DataTypePredicate
): PredicateIndexMatch | undefined => {
    let item: AstItem | undefined;
    let i = 0;
    while ((item = items[i])) {
        const predicateMatch = predicate(item.value, i, items);
        if (predicateMatch) {
            return { index: i, length: Number(predicateMatch) };
        }
        i++;
    }

    return undefined;
};

export const createDataType = (dataType: DataTypeType, predicates: DataTypePredicate[]): DataType => {
    const dataTypeData = DATA_TYPES_MAP.get(dataType);
    const prefix = dataTypeData?.prefix ? seperatorPrefix(dataTypeData.prefix) : undefined;

    return {
        dataType,
        initial: getInitialValue(dataType),
        prefix,
        predicate: (ast, index, items, prev) => {
            let predicateAst = ast;
            let predicateIndex = index;
            if (prefix) {
                if (index === undefined || !items || prev !== prefix.dataType) {
                    return false;
                }
                const prefixType: Descriptors | Delimiters =
                    prefix.prefixChar === INTERNAL_VALUE_SEPERATOR ? '/' : 'text';
                if (ast.type !== prefixType || ast.text !== prefix.prefixChar) {
                    return false;
                }
                predicateIndex = index + 1;
                if (!items[predicateIndex]) {
                    return false;
                }
                predicateAst = items[predicateIndex].value;
            }
            const predicateMatch = predicateUnion(predicates)(predicateAst, predicateIndex, items, prev);
            if (predicateMatch) {
                return !prefix ? predicateMatch : Number(predicateMatch) + 1;
            }
            return false;
        },
    };
};

export const createUnorderedListDataType = (dataType: DataTypeType): DataType => {
    const dataTypeData = DATA_TYPES_MAP.get(dataType);

    return {
        dataType,
        initial: getInitialValue(dataType),
        predicate: dataTypeData?.keywords ? unorderedListPredicate(dataTypeData.keywords) : () => false,
    };
};

type DeclarationMap = Record<string, string | undefined>;
export const fixSpxValues = (values: DeclarationMap) => {
    const valuesWithValidSpx = { ...values };
    Object.entries(valuesWithValidSpx).forEach(([key, value]) => {
        if (typeof value === 'string' && CONTAINING_SPX_REGEX.test(value)) {
            const formattedSpxValue = value.replace(
                new RegExp(CONTAINING_SPX_REGEX, 'g'),
                (spxVal) => parseDimension(spxVal)?.raw || spxVal
            );
            valuesWithValidSpx[key] = formattedSpxValue || value;
        }
    });
    return valuesWithValidSpx;
};
