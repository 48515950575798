import { areRecordsEqual, getDimensionInputDefaults } from '@wix/stylable-panel-common';
import {
    DEFAULT_LOAD_SITE_COLORS,
    DEFAULT_WRAP_SITE_COLOR,
    GenericDeclarationMap,
    parseGradient,
    stringifyBorderImage,
    stringifyGradient,
    StylablePanelTranslationKeys,
    type FullDeclarationMap,
} from '@wix/stylable-panel-drivers';
import {
    BorderColors,
    BorderStyles,
    createCssValueAST,
    INITIAL_UNIVERSAL,
    parseDimension,
} from '@wix/shorthands-opener';
import { useCallback, useMemo, useRef } from 'react';
import {
    createNonCommittedDeclarationNode,
    getFullDeclarationsExpression,
    getFullExpression,
    OriginNode,
    type DeclarationValue,
    type OpenedDeclarationArray,
} from '../../declaration-types';
import { useTranslate } from '../../hooks';
import { FillPickerTabs } from '../../pickers';
import type { DeclarationVisualizerProps, OpenedDeclarationList } from '../../types';
import {
    controllerToVisualizerChange,
    getOpenedDeclarationList,
    getShorthandControllerValue,
    getSimpleShorthandChange,
    getSimpleShorthandDeclarationsChange,
    wrapExistingDeclaration,
} from '../../utils';
import {
    ALL_PROPS_LIST,
    BorderConfig,
    BorderDeclarationMap,
    BorderEdgeProps,
    BorderEdges,
    BorderLonghandProps,
    BorderOrigins,
    BorderProps,
    BorderShorthandKeys,
    BorderSide,
    BorderVisualizerStateActionType,
    BORDER_LONGHAND_PROPS,
    BORDER_SHORTHAND_KEYS,
    createBorderAST,
    createBorderImageASTs,
    DEFAULT_COLOR,
    DEFAULT_STYLE,
    EdgesOrigins,
    EMPTY_IMAGE_BORDER_CHANGE_VALUE,
    FullEdgesValue,
    getDefaultBorderDimensions,
    getSideStyle,
    getSideValues,
    getSideWidth,
    SelectedBorder,
    SIDES,
    stringifyBorder,
} from './border-utils';
import { BorderType } from './border-visualizer-side-controls';
import { useBorderVisualizerState } from './use-border-visualizer-state';
import type { DimensionInputConfig } from '@wix/stylable-panel-components';

export const BorderVisualizerConfig = {
    resetCorners: true,
};

export const MINIMUM_BORDER_WIDTH = 0;
export const MINIMUM_DOUBLE_BORDER_WIDTH = 3;

const normalizeDoubleWidth = (
    width: string | undefined,
    sideStyle: string,
    defaultWidth: { value: number; unit: string },
    defaultDoubleWidth: { value: number; unit: string }
) => {
    const parsedWidth = parseDimension(width || '');
    const isDoubleStyle = sideStyle === 'double';
    const defaultWidthStr = `${defaultWidth.value}${defaultWidth.unit}`;
    const defaultDoubleWidthStr = `${defaultDoubleWidth.value}${defaultDoubleWidth.unit}`;
    const fallbackWidth = isDoubleStyle ? defaultDoubleWidthStr : defaultWidthStr;
    if (!width) {
        return fallbackWidth;
    }
    if (isDoubleStyle) {
        const isValidWidth = parsedWidth?.number && parsedWidth.number >= MINIMUM_DOUBLE_BORDER_WIDTH;
        return isValidWidth ? width : defaultDoubleWidthStr;
    }
    return width;
};

const SOLID_WIDTH_THRESHOLD = 3;

const EMPTY_CORNER_RADIUSES = ['0', '0px'];

type BorderFullProp = 'border' | BorderEdgeProps;

export const EMPTY_BORDER_LONGHANDS_CHANGE_VALUE = BORDER_LONGHAND_PROPS.reduce(
    (emptyChangeValue, prop) => ({ ...emptyChangeValue, [prop]: '' }),
    {} as Record<BorderLonghandProps, string>
);
export const EMPTY_BORDER_EDGE_LONGHANDS_CHANGE_VALUES = SIDES.reduce((changeValue, side) => {
    changeValue[side] = {
        [`border-${side}-width`]: '',
        [`border-${side}-style`]: '',
        [`border-${side}-color`]: '',
    };
    return changeValue;
}, {} as Record<BorderEdges, Record<string, string>>);

export const getEmptyCornersDecl = (defaultEmptyCorners: string) =>
    ({
        'border-radius': defaultEmptyCorners,
    } as BorderDeclarationMap);

function getPartSideOrigin(
    part: 'width' | 'style' | 'color',
    side: BorderEdges,
    openedDeclarationList: OpenedDeclarationList<BorderProps>
): OriginNode | null {
    const decls = openedDeclarationList[`border-${side}-${part}`];
    return getFullExpression(decls[decls.length - 1]);
}

function getSideOrigins(
    side: BorderEdges,
    openedDeclarationList: OpenedDeclarationList<BorderProps>,
    borderType: BorderType
): BorderOrigins {
    return {
        width: getPartSideOrigin('width', side, openedDeclarationList),
        style: getPartSideOrigin('style', side, openedDeclarationList),
        fill: borderType === BorderType.Solid ? getPartSideOrigin('color', side, openedDeclarationList) : null,
    };
}

function getWidthOrigins(openedDeclarationList: OpenedDeclarationList<BorderProps>): EdgesOrigins {
    return {
        top: getPartSideOrigin('width', 'top', openedDeclarationList),
        right: getPartSideOrigin('width', 'right', openedDeclarationList),
        bottom: getPartSideOrigin('width', 'bottom', openedDeclarationList),
        left: getPartSideOrigin('width', 'left', openedDeclarationList),
    };
}

export const useBorderVisualizer = (
    props: DeclarationVisualizerProps<BorderProps>,
    noResizing: boolean | undefined,
    getDimensionConfigForSide: (
        side: BorderEdges,
        declarationMapValue?: GenericDeclarationMap<BorderProps>
    ) => DimensionInputConfig
) => {
    const { panelHost, siteVarsDriver, onChange } = props;

    const translate = useTranslate(panelHost);

    const changeFromWithin = useRef(false);
    const fallbackValue = useRef<FullDeclarationMap>({});

    const openedDeclarationList = useRef(
        getOpenedDeclarationList<BorderShorthandKeys, BorderProps>(BORDER_SHORTHAND_KEYS, ALL_PROPS_LIST, props)
    );
    const declarationMapValue = useRef(getShorthandControllerValue(openedDeclarationList.current, props));
    const prevOpenedDeclarationList = useRef<OpenedDeclarationList<BorderProps>>();
    const prevDeclarationMapValue = useRef<BorderDeclarationMap>();

    const { borderType, selected, editing, stylePicker, linked, dispatchState } = useBorderVisualizerState(
        declarationMapValue.current,
        changeFromWithin.current,
        panelHost
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const { DEFAULT_WIDTH, DEFAULT_DOUBLE_WIDTH, DEFAULT_WIDTHS, EMPTY_WIDTHS, DEFAULT_EMPTY_CORNERS } = useMemo(
        () => getDefaultBorderDimensions(panelHost),
        [panelHost?.dimensionUnits?.defaults]
    );

    const updateDeclarationMapValue = () => {
        prevOpenedDeclarationList.current = openedDeclarationList.current;
        prevDeclarationMapValue.current = declarationMapValue.current;
        openedDeclarationList.current = getOpenedDeclarationList<BorderShorthandKeys, BorderProps>(
            BORDER_SHORTHAND_KEYS,
            ALL_PROPS_LIST,
            props
        );
        declarationMapValue.current = getShorthandControllerValue(openedDeclarationList.current, props);

        if (
            (editing === SelectedBorder.None &&
                !changeFromWithin.current &&
                !areRecordsEqual(prevDeclarationMapValue.current, declarationMapValue.current)) ||
            prevDeclarationMapValue.current['border-image-source'] !==
                declarationMapValue.current['border-image-source']
        ) {
            dispatchState({ type: BorderVisualizerStateActionType.Calc });
        } else if (
            !(changeFromWithin.current && areRecordsEqual(prevDeclarationMapValue.current, declarationMapValue.current))
        ) {
            changeFromWithin.current = false;
        }
    };

    const toggleLinked = useCallback(() => {
        dispatchState({ type: BorderVisualizerStateActionType.ToggleLinked });

        const message = linked
            ? StylablePanelTranslationKeys.notifications.tracking.unlocked
            : StylablePanelTranslationKeys.notifications.tracking.locked;
        panelHost?.onEditorNotify?.({
            type: 'tracking',
            title: translate(message),
            message,
        });
    }, [dispatchState, linked, panelHost, translate]);

    const selectBorder = useCallback(
        (select: SelectedBorder) => {
            dispatchState({ type: BorderVisualizerStateActionType.SelectBorder, select });
        },
        [dispatchState]
    );

    const handleBlur = useCallback(
        () => dispatchState({ type: BorderVisualizerStateActionType.HandleBlur }),
        [dispatchState]
    );

    const openStylePicker = useCallback(
        (select: SelectedBorder) => {
            dispatchState({ type: BorderVisualizerStateActionType.OpenStylePicker, select });
        },
        [dispatchState]
    );

    const closeStylePicker = useCallback(
        () => dispatchState({ type: BorderVisualizerStateActionType.CloseStylePicker }),
        [dispatchState]
    );

    const getSiteColorWrappedFill = useCallback(
        (fill: string) => {
            const loadSiteColors = siteVarsDriver?.loadSiteColors?.bind(siteVarsDriver) ?? DEFAULT_LOAD_SITE_COLORS;
            const wrapSiteColor = siteVarsDriver?.wrapSiteColor?.bind(siteVarsDriver) ?? DEFAULT_WRAP_SITE_COLOR;
            loadSiteColors();
            if (borderType === BorderType.Solid) {
                return wrapSiteColor(fill);
            }
            if (borderType === BorderType.Gradient) {
                const gradient = parseGradient(fill);
                if (gradient) {
                    for (const colorStop of gradient.colorStops) {
                        colorStop.color = wrapSiteColor(colorStop.color);
                    }
                    return stringifyGradient(gradient, false);
                }
            }
            return fill;
        },
        [borderType, siteVarsDriver]
    );

    const BorderInitialConfig = useMemo(
        () => getDimensionInputDefaults(panelHost).borderInitialConfig,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [panelHost?.dimensionUnits?.defaults]
    );

    const getSideValueForChange = useCallback(
        (side: BorderEdges) => {
            const {
                width,
                style: sideStyle,
                fill: sideFill,
            } = getSideValues(side, declarationMapValue.current, borderType, panelHost);
            if (
                DEFAULT_WIDTHS.includes(width) &&
                (sideStyle === DEFAULT_STYLE || sideStyle === INITIAL_UNIVERSAL) &&
                (sideFill === DEFAULT_COLOR || sideFill === INITIAL_UNIVERSAL)
            ) {
                return BorderInitialConfig;
            }
            const fill = getSiteColorWrappedFill(sideFill);

            const dimensionConfig = getDimensionConfigForSide(side);
            const spxFormatter = dimensionConfig.units?.spx?.formatter;
            const hasSpxValue = /(-?[\d.]+)spx/.test(width);

            const formattedWidth = spxFormatter && hasSpxValue ? spxFormatter(width) : width;

            return { width: formattedWidth, style: sideStyle, fill };
        },
        [borderType, getDimensionConfigForSide, getSiteColorWrappedFill, panelHost, DEFAULT_WIDTHS, BorderInitialConfig]
    );

    const getBorderChangeDeclarationsFromMap = useCallback(
        (value: BorderDeclarationMap) =>
            controllerToVisualizerChange(value, props, BORDER_SHORTHAND_KEYS, openedDeclarationList.current),
        [props]
    );

    const getBorderChangeDeclaration = useCallback(
        (prop: BorderProps, value: DeclarationValue) =>
            wrapExistingDeclaration(prop, value, props, BORDER_SHORTHAND_KEYS, openedDeclarationList.current),
        [props]
    );

    const onShorthandChange = useCallback(
        (value: BorderDeclarationMap) =>
            onChange?.(
                getSimpleShorthandChange<BorderShorthandKeys, BorderProps>(
                    BORDER_SHORTHAND_KEYS,
                    value,
                    openedDeclarationList.current,
                    props
                )
            ),
        [onChange, props]
    );

    const onShorthandDeclarationsChange = useCallback(
        (value: OpenedDeclarationArray<BorderShorthandKeys | BorderProps>) =>
            onChange?.(
                getSimpleShorthandDeclarationsChange<BorderShorthandKeys, BorderProps>(
                    BORDER_SHORTHAND_KEYS,
                    value,
                    openedDeclarationList.current,
                    props
                )
            ),
        [onChange, props]
    );

    const onPreservingChange = useCallback(
        (prop: BorderFullProp, value: BorderConfig, origins: BorderOrigins, clearValue: BorderDeclarationMap) => {
            if (!onChange) {
                return;
            }

            const changeDeclaration = getBorderChangeDeclaration(prop, createBorderAST(value, origins));
            const clearDeclarations = getBorderChangeDeclarationsFromMap(clearValue);
            onShorthandDeclarationsChange([changeDeclaration, ...clearDeclarations]);
        },
        [getBorderChangeDeclarationsFromMap, getBorderChangeDeclaration, onShorthandDeclarationsChange, onChange]
    );

    const notifyCornersReset = useCallback(() => {
        const {
            [`border-top-left-radius`]: topLeft,
            [`border-top-right-radius`]: topRight,
            [`border-bottom-right-radius`]: bottomRight,
            [`border-bottom-left-radius`]: bottomLeft,
        } = declarationMapValue.current;
        if (
            [topLeft, topRight, bottomRight, bottomLeft].every(
                (radiusValue) => !radiusValue || !!~EMPTY_CORNER_RADIUSES.indexOf(radiusValue)
            )
        ) {
            return;
        }
        const message = StylablePanelTranslationKeys.notifications.cornerRadiusResetText;
        panelHost?.onEditorNotify &&
            panelHost.onEditorNotify({
                type: 'warning',
                title: translate(message),
                message,
            });
    }, [panelHost, translate]);

    const changeWidth = useCallback(
        (side: BorderEdges, value?: string) => {
            const { style: sideStyle, fill } = getSideValueForChange(side);
            if (borderType === BorderType.Solid) {
                changeFromWithin.current = true;

                onPreservingChange(
                    linked ? 'border' : `border-${side}`,
                    {
                        width: normalizeDoubleWidth(value, sideStyle, DEFAULT_WIDTH, DEFAULT_DOUBLE_WIDTH),
                        style: sideStyle,
                        fill,
                    },
                    { ...getSideOrigins(side, openedDeclarationList.current, borderType), width: null },
                    (linked
                        ? EMPTY_BORDER_LONGHANDS_CHANGE_VALUE
                        : EMPTY_BORDER_EDGE_LONGHANDS_CHANGE_VALUES[side]) as BorderDeclarationMap
                );
            } else {
                const image = borderType === BorderType.Image || borderType === BorderType.Pattern;

                const widths = getSideWidth(declarationMapValue.current, borderType);
                widths[side] = value;
                SIDES.forEach((widthSide) => {
                    const widthSideValue = widths[widthSide];
                    widths[widthSide] =
                        !widthSideValue || DEFAULT_WIDTHS.includes(widthSideValue)
                            ? DEFAULT_WIDTH.value + DEFAULT_WIDTH.unit
                            : widthSideValue;
                });
                const widthOrigins = getWidthOrigins(openedDeclarationList.current);
                delete widthOrigins[side];
                if (linked || image) {
                    widths.top = widths.right = widths.bottom = widths.left = value;
                    widthOrigins.top =
                        widthOrigins.right =
                        widthOrigins.bottom =
                        widthOrigins.left =
                            widthOrigins[side];
                }

                notifyCornersReset();
                changeFromWithin.current = true;

                const borderImageDecl = getBorderChangeDeclaration(
                    'border-image',
                    createBorderImageASTs(
                        {
                            'border-image-source': fill,
                            'border-image-width': linked || image ? value : undefined,
                            'border-image-slice': image ? declarationMapValue.current['border-image-slice'] : undefined,
                            'border-image-repeat': declarationMapValue.current['border-image-repeat'],
                        },
                        widths as FullEdgesValue,
                        widthOrigins
                    )['border-image']
                );
                const emptyCornersDecl = getEmptyCornersDecl(DEFAULT_EMPTY_CORNERS);
                const resetCornersDecls = BorderVisualizerConfig.resetCorners
                    ? getBorderChangeDeclarationsFromMap(emptyCornersDecl)
                    : [];

                onShorthandDeclarationsChange([borderImageDecl, ...resetCornersDecls]);
            }
        },
        [
            borderType,
            linked,
            getSideValueForChange,
            getBorderChangeDeclarationsFromMap,
            getBorderChangeDeclaration,
            onShorthandDeclarationsChange,
            onPreservingChange,
            notifyCornersReset,
            DEFAULT_WIDTH,
            DEFAULT_DOUBLE_WIDTH,
            DEFAULT_WIDTHS,
            DEFAULT_EMPTY_CORNERS,
        ]
    );

    const normalizeEmptyWidth = useCallback(
        (width: string) => (!~EMPTY_WIDTHS.indexOf(width) ? width : BorderInitialConfig.width),
        [EMPTY_WIDTHS, BorderInitialConfig]
    );

    const changeFill = useCallback(
        (
            tab: FillPickerTabs,
            side: BorderEdges,
            value: string | null,
            declarations?: OpenedDeclarationArray<string>
        ) => {
            if (!value) {
                return;
            }
            if (noResizing) {
                const attributeName = linked ? 'border-color' : (`border-${side}-color` as BorderColors);
                return onChange?.([
                    createNonCommittedDeclarationNode({ name: attributeName, value: createCssValueAST(value) }),
                ]);
            }
            if (tab === FillPickerTabs.Solid) {
                const prop: BorderFullProp = linked ? 'border' : `border-${side}`;
                if (value === DEFAULT_COLOR) {
                    changeFromWithin.current = true;
                    onShorthandChange({ [prop]: '' } as BorderDeclarationMap);
                } else if (!!~value.indexOf('gradient') || !!~value.indexOf('url')) {
                    // TODO: Probably not needed when switching tabs sends a value change
                    changeFromWithin.current = true;
                    onShorthandChange(fallbackValue.current as BorderDeclarationMap);
                } else {
                    const { width, style: sideStyle } = getSideValueForChange(side);
                    const changedWidth = normalizeEmptyWidth(
                        normalizeDoubleWidth(width, sideStyle, DEFAULT_WIDTH, DEFAULT_DOUBLE_WIDTH)
                    );
                    const { width: widthOrigin, style: styleOrigin } = getSideOrigins(
                        side,
                        openedDeclarationList.current,
                        borderType
                    );

                    changeFromWithin.current = true;

                    onPreservingChange(
                        prop,
                        { width: changedWidth, style: sideStyle, fill: value },
                        { width: widthOrigin, style: styleOrigin, fill: getFullDeclarationsExpression(declarations) },
                        {
                            ...(linked
                                ? EMPTY_BORDER_LONGHANDS_CHANGE_VALUE
                                : EMPTY_BORDER_EDGE_LONGHANDS_CHANGE_VALUES[side]),
                            ...EMPTY_IMAGE_BORDER_CHANGE_VALUE,
                        } as BorderDeclarationMap
                    );
                }
            } else {
                const widths = getSideWidth(declarationMapValue.current, BorderType.Image) as FullEdgesValue;
                SIDES.forEach((widthSide) => {
                    const widthSideValue: string | undefined = widths[widthSide];
                    widths[widthSide] =
                        !widthSideValue || DEFAULT_WIDTHS.includes(widthSideValue)
                            ? widthSide !== side
                                ? DEFAULT_WIDTH.value + DEFAULT_WIDTH.unit
                                : BorderInitialConfig.width
                            : widthSideValue;
                });
                const widthOrigins = getWidthOrigins(openedDeclarationList.current);
                if (linked) {
                    widths.top = widths.right = widths.bottom = widths.left = widths[side];
                    widthOrigins.top =
                        widthOrigins.right =
                        widthOrigins.bottom =
                        widthOrigins.left =
                            widthOrigins[side];
                }

                let sideStyle = getSideStyle(side, declarationMapValue.current);
                const sideStyleOrigin = getPartSideOrigin('style', side, openedDeclarationList.current);
                if (sideStyle === DEFAULT_STYLE) {
                    sideStyle = BorderInitialConfig.style;
                }

                notifyCornersReset();
                changeFromWithin.current = true;

                const borderDecl = getBorderChangeDeclaration(
                    'border',
                    createBorderAST(
                        {
                            width: BorderInitialConfig.width,
                            style: sideStyle,
                            fill:
                                declarationMapValue.current['border-color'] &&
                                declarationMapValue.current['border-color'] !== DEFAULT_COLOR
                                    ? declarationMapValue.current['border-color']
                                    : '',
                        },
                        { style: sideStyleOrigin }
                    )
                );

                const clearDecls = getBorderChangeDeclarationsFromMap(
                    EMPTY_BORDER_LONGHANDS_CHANGE_VALUE as BorderDeclarationMap
                );

                const borderImageASTs = createBorderImageASTs({ 'border-image-source': value }, widths, widthOrigins);
                const borderWidthDecl = getBorderChangeDeclaration('border-width', borderImageASTs['border-width']);
                const borderImageDecl = getBorderChangeDeclaration('border-image', borderImageASTs['border-image']);

                const emptyCornersDecl = getEmptyCornersDecl(DEFAULT_EMPTY_CORNERS);
                const resetCornersDecls = BorderVisualizerConfig.resetCorners
                    ? getBorderChangeDeclarationsFromMap(emptyCornersDecl)
                    : [];

                onShorthandDeclarationsChange([
                    borderDecl,
                    ...clearDecls,
                    borderWidthDecl,
                    borderImageDecl,
                    ...resetCornersDecls,
                ]);
            }
        },
        [
            borderType,
            linked,
            noResizing,
            onChange,
            getSideValueForChange,
            getBorderChangeDeclarationsFromMap,
            getBorderChangeDeclaration,
            onShorthandChange,
            onShorthandDeclarationsChange,
            onPreservingChange,
            notifyCornersReset,
            normalizeEmptyWidth,
            DEFAULT_WIDTH,
            DEFAULT_DOUBLE_WIDTH,
            DEFAULT_WIDTHS,
            DEFAULT_EMPTY_CORNERS,
            BorderInitialConfig,
        ]
    );

    const changeStyle = useCallback(
        (side: BorderEdges, value: string) => {
            if (noResizing) {
                const attributeName = linked ? 'border-style' : (`border-${side}-style` as BorderStyles);
                return onChange?.([
                    createNonCommittedDeclarationNode({ name: attributeName, value: createCssValueAST(value) }),
                ]);
            }

            const { width, fill } = getSideValueForChange(side);
            const changedWidth = normalizeEmptyWidth(
                normalizeDoubleWidth(
                    width,
                    value || DEFAULT_WIDTH.value + DEFAULT_WIDTH.unit,
                    DEFAULT_WIDTH,
                    DEFAULT_DOUBLE_WIDTH
                )
            );

            changeFromWithin.current = true;

            onPreservingChange(
                linked ? 'border' : `border-${side}`,
                { width: changedWidth, style: `${value}`, fill },
                { ...getSideOrigins(side, openedDeclarationList.current, borderType), style: null },
                (linked
                    ? EMPTY_BORDER_LONGHANDS_CHANGE_VALUE
                    : EMPTY_BORDER_EDGE_LONGHANDS_CHANGE_VALUES[side]) as BorderDeclarationMap
            );

            dispatchState({ type: BorderVisualizerStateActionType.ChangeStyle });
        },
        [
            borderType,
            dispatchState,
            getSideValueForChange,
            linked,
            noResizing,
            onChange,
            onPreservingChange,
            normalizeEmptyWidth,
            DEFAULT_WIDTH,
            DEFAULT_DOUBLE_WIDTH,
        ]
    );

    const sideBorderPreviewStyle = useCallback(
        (side: BorderEdges) => {
            const sideValueStr = () => {
                const sideValue = getSideValues(side, declarationMapValue.current, borderType, panelHost);
                const width = Math.min(parseFloat(sideValue.width), SOLID_WIDTH_THRESHOLD);
                return stringifyBorder({
                    width: `${width}px`,
                    style: borderType !== BorderType.Solid ? 'solid' : sideValue.style,
                    fill: borderType !== BorderType.Solid ? '' : sideValue.fill,
                });
            };
            const result: FullDeclarationMap = {
                [`border${side.charAt(0).toUpperCase()}${side.slice(1)}`]: sideValueStr(),
            };
            if (borderType !== BorderType.Solid) {
                result.borderImage = `${declarationMapValue.current['border-image-source']} 1`;
            }
            return result;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [borderType, panelHost?.dimensionUnits?.defaults]
    );

    const setFallbackValue = (sideValues: BorderSide, side: BorderEdges) => {
        fallbackValue.current['border-image'] = stringifyBorderImage(declarationMapValue.current) as string;
        const borderSideColor = declarationMapValue.current[`border-${side}-color` as BorderColors];
        if (!linked) {
            fallbackValue.current[`border-${side}`] = borderSideColor
                ? stringifyBorder({
                      width: sideValues.width,
                      style: sideValues.style,
                      fill: borderSideColor,
                  })
                : '';
            fallbackValue.current = {
                ...fallbackValue.current,
                ...EMPTY_BORDER_EDGE_LONGHANDS_CHANGE_VALUES[side],
            };
        } else {
            fallbackValue.current.border = borderSideColor
                ? stringifyBorder({
                      width: sideValues.width,
                      style: sideValues.style,
                      fill: borderSideColor,
                  })
                : '';
            fallbackValue.current = {
                ...fallbackValue.current,
                ...EMPTY_BORDER_LONGHANDS_CHANGE_VALUE,
            };
        }
    };

    return {
        declarationMapValue: declarationMapValue.current,
        updateDeclarationMapValue,
        borderType,
        selected,
        editing,
        stylePicker,
        linked,
        dispatchState,
        toggleLinked,
        selectBorder,
        handleBlur,
        openStylePicker,
        closeStylePicker,
        changeWidth,
        changeFill,
        changeStyle,
        sideBorderPreviewStyle,
        setFallbackValue,
    };
};
