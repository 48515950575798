import { CompositeBlock, ToggleSwitch } from '@wix/stylable-panel-components';
import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import React, { useCallback, useMemo } from 'react';
import { useTranslate } from '../../hooks';
import { PanelEventList } from '../../hosts/bi';
import { DISPLAY_HIDDEN, DISPLAY_INITIAL, TextVisualizerProps } from './use-text-visualizer';
import { classes } from './text-visualizer.st.css';

export type ShowToggleProps = {
    props: TextVisualizerProps;
    elementShown: boolean;
    glyphMode: boolean;
    changeFromBlock: (prop: string, valueFunction: (value: string) => string, value: string) => void;
};

export function ShownToggle({ props, elementShown, glyphMode, changeFromBlock }: ShowToggleProps) {
    const { selectorState, panelHost } = props;

    const translate = useTranslate(panelHost);
    const titleKey = useMemo(
        () =>
            !glyphMode
                ? StylablePanelTranslationKeys.controller.text.showLabel
                : StylablePanelTranslationKeys.controller.separators.showLabel,
        [glyphMode]
    );

    const onRenderShowElementBlock = useCallback(
        (toggle: boolean) => {
            // the 'toggle' string is sent to name the specific action of changeFromBlock
            changeFromBlock('display', () => (toggle ? DISPLAY_HIDDEN : DISPLAY_INITIAL), 'toggle');

            const { COMPONENT_PART_TOGGLE } = PanelEventList;
            panelHost?.reportBI?.(COMPONENT_PART_TOGGLE, { toggle_name: 'text', toggle: !toggle });
        },
        [changeFromBlock, panelHost]
    );

    return (
        <CompositeBlock className={classes.controllerBlock} title={translate(titleKey)} singleLine divider>
            <ToggleSwitch
                className={classes.shownToggle}
                value={elementShown}
                disabled={selectorState !== undefined}
                onChange={() => onRenderShowElementBlock(elementShown)}
            />
        </CompositeBlock>
    );
}
