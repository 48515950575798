import React, { FC } from 'react';
import { usePanelContext } from './configurable-panel';
import type { WhenProps } from './configurable-panel-utils';
import { generateVisualizerProps } from './utils/generate-visualizer-props';

export interface CpControllerCommonProps extends WhenProps {
    editTargetSelector?: string;
}
export interface CpControllerProps extends CpControllerCommonProps {
    controller: any; // TODO change to something like ControllerComponentClass | VisualizerComponentClass;
}

// TODO consider deprecating this because we can use generateVisualizerProps directly instead.
export const CpController: FC<CpControllerProps> = ({ controller, editTargetSelector }) => {
    const context = usePanelContext();
    const Controller = controller;

    return (
        <Controller
            {...generateVisualizerProps({
                astValue: false /* TBD: should this be overridable?*/,
                context,
                editTargetSelector,
                key: 'CpController',
            })}
        />
    );
};
