import type {
    CSSAstNode,
    ParseShorthandAPI as ParseShorthandAPIGeneric,
    EvaluatedAst as EvaluatedAstGeneric,
    OpenedShorthandValue as OpenedShorthandValueGeneric,
    SimpleOpenedShorthand as SimpleOpenedShorthandGeneric,
    ParsedCompoundAst as ParsedCompoundAstGeneric,
    ParsedCompound as ParsedCompoundGeneric,
    LayeredParsedCompound as LayeredParsedCompoundGeneric,
} from '@wix/shorthands-opener';

export interface ProjectedNode<T extends string = string> {
    kind: T;
    astNodeId: string;
}

export interface CSSNativeVar {
    kind: 'native';
    sourceName: string;
    targetName: string;
}

export interface CSSBuildVar {
    kind: 'build';
    id: string;
    value: DeclarationValue;
    definitionFile: string;
    code: string;
    sourceName: string;
}

export interface CSSValueExpression {
    kind: 'expression';
    id: string;
    code: string;
    value: DeclarationTargetValue;
}

export interface CSSValueFunction {
    kind: 'function';
    id: string;
    code: string;
    displayName: string;
    arguments: Record<string, DeclarationValue>;
    value: DeclarationTargetValue;
}

export type DeclarationExpresionItem = CSSBuildVar | CSSNativeVar | CSSValueExpression | CSSValueFunction;

export type OriginNode = DeclarationExpresionItem;
export type ParseShorthandAPI = ParseShorthandAPIGeneric<OriginNode>;
export type EvaluatedAst = EvaluatedAstGeneric<OriginNode>;
export type OpenedShorthandValue = OpenedShorthandValueGeneric<OriginNode>;
export type SimpleOpenedShorthand<PROPS extends string = string> = SimpleOpenedShorthandGeneric<OriginNode, PROPS>;
export type ParsedCompoundAst<T> = ParsedCompoundAstGeneric<OriginNode, T>;
export type ParsedCompound<M> = ParsedCompoundGeneric<OriginNode, M>;
export type LayeredParsedCompound<M> = LayeredParsedCompoundGeneric<OriginNode, M>;

export type DeclarationValueItem = CSSAstNode<OriginNode>;
export type DeclarationValue = DeclarationValueItem[]; // 0 length array means delete
export type DeclarationTargetValue = Array<CSSNativeVar | string>;

export interface BaseDeclarationNode<PROPS extends string = string> {
    name: PROPS;
    value: DeclarationValue;
    important?: boolean;
}

export interface DeclarationNode<PROPS extends string = string>
    extends BaseDeclarationNode<PROPS>,
        ProjectedNode<'declaration'> {}

// A virtual declaration node representing an opened shorthand property, linking to the original declaration.
export interface OpenedShortHandDeclarationNode<PROPS extends string = string> extends BaseDeclarationNode<PROPS> {
    kind: 'opened-shorthand';
    originalDecl: BaseDeclarationNode<string>;
}

// A newly created declaration node that hasn't yet been committed to the source file.
export interface NonCommittedDeclarationNode<PROPS extends string = string> extends BaseDeclarationNode<PROPS> {
    kind: 'non-committed-declaration';
}

export type OpenedDeclaration<PROPS extends string = string> =
    // TODO: Change DeclarationNode to BaseDeclarationNode + Remove NonCommittedDeclarationNode from type according to controller-api and adjust usage in WCS
    | DeclarationNode<PROPS>
    // | BaseDeclarationNode<PROPS>
    | NonCommittedDeclarationNode<PROPS>
    | OpenedShortHandDeclarationNode<PROPS>;
export type OpenedDeclarationArray<PROPS extends string> = OpenedDeclaration<PROPS>[];

export function isNativeVar(v: any): v is CSSNativeVar {
    return v && v.kind === 'native';
}

export function isBuildVar(v: any): v is CSSBuildVar {
    return v && v.kind === 'build';
}

export function isValueExpression(v: any): v is CSSValueExpression {
    return v && v.kind === 'expression';
}

export function isValueFunction(v: any): v is CSSValueFunction {
    return v && v.kind === 'function';
}

export function isDeclarationExpressionItem(item: any): item is DeclarationExpresionItem {
    return (
        item &&
        (item.kind === 'native' || item.kind === 'build' || item.kind === 'expression' || item.kind === 'function')
    );
}

export function isDeclaration(decl: any): decl is DeclarationNode {
    return decl && decl.kind === 'declaration';
}

export function isOpenedShortHand(node: any): node is OpenedShortHandDeclarationNode {
    return node && node.kind === 'opened-shorthand';
}

export function isNonCommittedDeclaration(node: any): node is NonCommittedDeclarationNode {
    return node && node.kind === 'non-committed-declaration';
}

export const isFullExpressionDeclaration = (declaration?: OpenedDeclaration) =>
    declaration && declaration.value.length === 1 && isDeclarationExpressionItem(declaration.value[0]);

export const isFullExpressionsDeclarationArray = <PROPS extends string>(declarations?: OpenedDeclarationArray<PROPS>) =>
    declarations && declarations.length === 1 && isFullExpressionDeclaration(declarations[0]);

export const getVariableName = (v: DeclarationExpresionItem) =>
    isValueFunction(v) ? v.displayName : isValueExpression(v) ? v.code : v.sourceName;

export const getVariableExpression = (v: DeclarationExpresionItem) =>
    isNativeVar(v) ? `var(${v.sourceName})` : v.code;

export const getFullExpression = (declaration?: OpenedDeclaration) =>
    isFullExpressionDeclaration(declaration) ? (declaration!.value[0] as DeclarationExpresionItem) : null;

export const getFullDeclarationsExpression = <PROPS extends string>(declarations?: OpenedDeclarationArray<PROPS>) =>
    isFullExpressionsDeclarationArray(declarations) ? (declarations![0].value[0] as DeclarationExpresionItem) : null;

export type CreationOptions<P extends { kind: string }, OPTIONAL extends keyof Omit<P, 'kind'> | never = never> = Omit<
    P,
    'kind' | OPTIONAL
> &
    Partial<Pick<P, OPTIONAL>>;

// All creation functions should have the kind defined after spreads to enforce the wanted kind

export function cssNativeVar(options: CreationOptions<CSSNativeVar, 'targetName'>): CSSNativeVar {
    return {
        targetName: options.targetName || options.sourceName,
        sourceName: options.sourceName,
        kind: 'native',
    };
}

export function cssBuildVar(options: CreationOptions<CSSBuildVar, 'id'>): CSSBuildVar {
    return {
        id: options.definitionFile + '#' + options.sourceName,
        ...options,
        // Keep last to enforce the 'build' kind after the spread
        kind: 'build',
    };
}

export function createDeclarationNode<PROPS extends string>(
    opts: CreationOptions<DeclarationNode<PROPS>, 'important'>
): DeclarationNode<PROPS> {
    return {
        important: false,
        ...opts,
        // Keep last to enforce the 'declaration' kind after the spread
        kind: 'declaration',
    };
}

export function createOpenedShorthandDeclarationNode<PROPS extends string>(
    opts: CreationOptions<OpenedShortHandDeclarationNode<PROPS>, 'important'>
): OpenedShortHandDeclarationNode<PROPS> {
    return {
        important: false,
        ...opts,
        // Keep last to enforce the 'opened-shorthand' kind after the spread
        kind: 'opened-shorthand',
    };
}

export function createNonCommittedDeclarationNode<PROPS extends string>(
    opts: CreationOptions<NonCommittedDeclarationNode<PROPS>, 'important'>
): NonCommittedDeclarationNode<PROPS> {
    return {
        important: false,
        ...opts,
        // Keep last to enforce the 'non-committed-declaration' kind after the spread
        kind: 'non-committed-declaration',
    };
}
