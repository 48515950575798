import type { DataType } from './data-types-types';

import {
    DataTypeType,
    DEFAULT_DIMENSION,
    LINE_WIDTH_KEYWORDS,
    COLOR_SPACE_FUNCTIONS,
    COLOR_KEYWORDS,
    INITIAL_BACKGROUND_COLOR,
    OUTLINE_COLOR_INVERT_KEYWORD,
    BORDER_IMAGE_REPEAT_MULTIPLE_KEYWORDS,
    GRADIENT_FUNCTIONS,
    IMAGE_FUNCTIONS,
    IMAGE_SOURCE_NONE_KEYWORD,
    BG_SIZE_KEYWORDS,
    REPEAT_STYLE_SINGLE_KEYWORDS,
    REPEAT_STYLE_MULTIPLE_KEYWORDS,
    INITIAL_BACKGROUND_ORIGIN,
    INITIAL_BACKGROUND_CLIP,
    FONT_STYLE_KEYWORDS,
    FONT_WEIGHT_KEYWORDS,
    FONT_WEIGHT_NUMBER_RANGE_MIN,
    FONT_WEIGHT_NUMBER_RANGE_MAX,
    FONT_SIZE_KEYWORDS,
    LINE_HEIGHT_KEYWORD,
    FONT_FAMILY_GENERIC_KEYWORDS,
    CONTENT_DISTRIBUTION_KEYWORDS,
    CONTENT_POSITION_KEYWORDS,
    ALIGN_CONTENT_KEYWORDS,
    JUSTIFY_CONTENT_KEYWORDS,
    SELF_POSITION_KEYWORDS,
    ALIGN_ITEMS_KEYWORDS,
    JUSTIFY_ITEMS_KEYWORDS,
    FLEX_BASIS_KEYWORDS,
    GRID_LINE_AUTO_KEYWORD,
    GRID_TEMPLATE_AXIS_NONE_KEYWORD,
    INITIAL_GRID_TEMPLATE_AREAS,
    TEXT_DECORATION_LINE_NONE_KEYWORD,
    LIST_STYLE_TYPE_KEYWORDS,
} from './data-types-consts';
import {
    unorderedListPredicate,
    functionPredicate,
    hexColorPredicate,
    stringPredicate,
    dimensionPredicate,
    curlyBracesPredicate,
    asteriskPredicate,
    createDataType,
    createUnorderedListDataType,
} from './data-types-utils';
import {
    numberPredicate,
    lengthPredicate,
    lengthPercentagePredicate,
    calcPredicate,
    autoKeywordPredicate,
    fontStylePredicate,
    fontFamilyStringPredicate,
    textDecorationLinePredicate,
    borderImageSlicePredicate,
    gridLinePredicate,
    trackSizePredicate,
    trackListPredicate,
    explicitTrackListPredicate,
    autoTrackListPredicate,
    gridTemplateAreasStringPredicate,
    gridAutoFlowPredicate,
} from './data-types-predicates';
import { stateMachineDataTypeMatch, bgPositionStateMachine } from './data-types-state-machines';

export const ALWAYS_DATA_TYPE: DataType = {
    dataType: DataTypeType.Unknown,
    predicate: () => true,
    initial: DEFAULT_DIMENSION,
};

// <universal>
// syntax: inherit | initial | unset | revert
export const universalDataType = createUnorderedListDataType(DataTypeType.Universal);

// <length>
export const lengthDataType = createDataType(DataTypeType.Length, [lengthPredicate, calcPredicate]);

// <length-percentage>
export const lengthPercentageDataType = createDataType(DataTypeType.LengthPercentage, [
    lengthPercentagePredicate,
    calcPredicate,
]);

// <width>
export const widthDataType = createDataType(DataTypeType.Width, [
    lengthPercentageDataType.predicate,
    autoKeywordPredicate,
]);

// <line-style>
// syntax: none | hidden | dotted | dashed | solid | double | groove | ridge | inset | outset
export const lineStyleDataType = createUnorderedListDataType(DataTypeType.LineStyle);

// <'outline-style'>
// syntax: auto | <'border-style'>
export const outlineStyleDataType = createDataType(DataTypeType.OutlineStyle, [
    autoKeywordPredicate,
    lineStyleDataType.predicate,
]);

// <line-width>
// syntax: <length> | thin | medium | thick
export const lineWidthDataType = createDataType(DataTypeType.LineWidth, [
    lengthDataType.predicate,
    unorderedListPredicate(LINE_WIDTH_KEYWORDS),
]);

// <color>
// syntax: <rgb()> | <rgba()> | <hsl()> | <hsla()> | <hex-color> | <named-color> | currentcolor | <deprecated-system-color>
export const colorDataType = createDataType(DataTypeType.Color, [
    functionPredicate(COLOR_SPACE_FUNCTIONS),
    hexColorPredicate(),
    unorderedListPredicate(COLOR_KEYWORDS),
]);

export const backgroundColorDataType: DataType = {
    ...colorDataType,
    initial: INITIAL_BACKGROUND_COLOR,
};

// <'outline-color'>
// syntax: <color> | invert
export const outlineColorDataType = createDataType(DataTypeType.OutlineColor, [
    colorDataType.predicate,
    unorderedListPredicate(OUTLINE_COLOR_INVERT_KEYWORD),
]);

// <border-image-slice>
// syntax: <number-percentage>{1,4} && fill?
export const borderImageSliceDataType = createDataType(DataTypeType.BorderImageSlice, [borderImageSlicePredicate]);

// <border-image-outset>
// syntax: [ <length> | <number> ]{1,4}
export const borderImageOutsetDataType = createDataType(DataTypeType.BorderImageOutset, [
    curlyBracesPredicate([lengthDataType.predicate, numberPredicate], 1, 4),
]);

// <border-image-repeat>
// syntax: [ stretch | repeat | round | space ]{1,2}
export const borderImageRepeatDataType = createDataType(DataTypeType.BorderImageRepeat, [
    curlyBracesPredicate([unorderedListPredicate(BORDER_IMAGE_REPEAT_MULTIPLE_KEYWORDS)], 1, 2),
]);

// <border-image-width>
// syntax: [ <length-percentage> | <number> | auto ]{1,4}
export const borderImageWidthDataType = createDataType(DataTypeType.BorderImageWidth, [
    curlyBracesPredicate([lengthPercentageDataType.predicate, numberPredicate, autoKeywordPredicate], 1, 4),
]);

// <gradient>
// syntax: <linear-gradient()> | <repeating-linear-gradient()> | <radial-gradient()> | <repeating-radial-gradient()> | <conic-gradient()>
export const gradientDataType = createDataType(DataTypeType.Gradient, [functionPredicate(GRADIENT_FUNCTIONS)]);

// <image>
// syntax: <url> | <image()> | <image-set()> | <element()> | <paint()> | <cross-fade()> | <gradient>
export const imageDataType = createDataType(DataTypeType.Image, [
    functionPredicate(IMAGE_FUNCTIONS),
    gradientDataType.predicate,
]);

// <image-source>
// syntax: none | <image>
export const imageSourceDataType = createDataType(DataTypeType.ImageSource, [
    unorderedListPredicate(IMAGE_SOURCE_NONE_KEYWORD),
    imageDataType.predicate,
]);

// <bg-position>
/*
syntax: [
  [ left | center | right | top | bottom | <length-percentage> ] |
  [ left | center | right | <length-percentage> ] [ top | center | bottom | <length-percentage> ] |
  [ center | [ left | right ] <length-percentage>? ]
    && [ center | [ top | bottom] <length-percentage>? ]
]
*/
export const bgPositionDataType = createDataType(DataTypeType.BgPosition, [
    (ast, index, items) => {
        if (ast.type !== 'text' || index === undefined || !items) {
            return false;
        }

        return stateMachineDataTypeMatch(items, index, bgPositionStateMachine(lengthPercentageDataType.predicate));
    },
]);

// <bg-size>
// syntax: [ <length-percentage> | auto ]{1,2} | cover | contain
export const bgSizeDataType = createDataType(DataTypeType.BgSize, [
    curlyBracesPredicate([lengthPercentageDataType.predicate, autoKeywordPredicate], 1, 2),
    unorderedListPredicate(BG_SIZE_KEYWORDS),
]);

// <repeat-style>
// syntax: repeat-x | repeat-y | [ repeat | space | round | no-repeat ]{1,2}
export const repeatStyleDataType = createDataType(DataTypeType.RepeatStyle, [
    unorderedListPredicate(REPEAT_STYLE_SINGLE_KEYWORDS),
    curlyBracesPredicate([unorderedListPredicate(REPEAT_STYLE_MULTIPLE_KEYWORDS)], 1, 2),
]);

// <attachment>
// syntax: scroll | fixed | local
export const attachmentDataType = createUnorderedListDataType(DataTypeType.Attachment);

// <box>
// syntax: border-box | padding-box | content-box
export const boxDataType = createUnorderedListDataType(DataTypeType.Box);

export const backgroundOriginDataType: DataType = {
    ...boxDataType,
    initial: INITIAL_BACKGROUND_ORIGIN,
};

export const backgroundClipDataType: DataType = {
    ...boxDataType,
    initial: INITIAL_BACKGROUND_CLIP,
};

// <font>
// syntax: caption | icon | menu | message-box | small-caption | status-bar
export const fontSingleValueDataType = createUnorderedListDataType(DataTypeType.Font);

// <font-style>
// syntax: normal | italic | oblique <angle>?
export const fontStyleDataType = createDataType(DataTypeType.FontStyle, [
    unorderedListPredicate(FONT_STYLE_KEYWORDS),
    fontStylePredicate,
]);

// <font-variant>
// syntax: normal | small-caps
export const fontVariantDataType = createUnorderedListDataType(DataTypeType.FontVariant);

// <font-weight>
// syntax: <font-weight-absolute> | <font-weight-relative> | [1,1000]
export const fontWeightDataType = createDataType(DataTypeType.FontWeight, [
    unorderedListPredicate(FONT_WEIGHT_KEYWORDS),
    dimensionPredicate({
        min: FONT_WEIGHT_NUMBER_RANGE_MIN,
        max: FONT_WEIGHT_NUMBER_RANGE_MAX,
    }),
]);

// <font-stretch>
// syntax: normal | ultra-condensed | extra-condensed | condensed | semi-condensed | semi-expanded | expanded | extra-expanded | ultra-expanded
export const fontStretchDataType = createUnorderedListDataType(DataTypeType.FontStretch);

// <font-size>
// syntax: <length-percentage> | <absolute-size> | <relative-size>
export const fontSizeDataType = createDataType(DataTypeType.FontSize, [
    lengthPercentageDataType.predicate,
    unorderedListPredicate(FONT_SIZE_KEYWORDS),
]);

// <line-height>
// syntax: <number> | <length-percentage> | normal
export const lineHeightDataType = createDataType(DataTypeType.LineHeight, [
    numberPredicate,
    lengthPercentageDataType.predicate,
    unorderedListPredicate(LINE_HEIGHT_KEYWORD),
]);

// <font-family>
// syntax: <generic-family> | <string>
export const fontFamilyDataType = createDataType(DataTypeType.FontFamily, [
    unorderedListPredicate(FONT_FAMILY_GENERIC_KEYWORDS),
    fontFamilyStringPredicate,
]);

// <align-content>
// syntax: normal | baseline | <content-distribution> | <content-position>
export const alignContentDataType = createDataType(DataTypeType.AlignContent, [
    unorderedListPredicate(ALIGN_CONTENT_KEYWORDS),
    unorderedListPredicate(CONTENT_DISTRIBUTION_KEYWORDS),
    unorderedListPredicate(CONTENT_POSITION_KEYWORDS),
]);

// <justify-content>
// syntax: normal | <content-distribution> | <content-position> | left | right
export const justifyContentDataType = createDataType(DataTypeType.JustifyContent, [
    unorderedListPredicate(JUSTIFY_CONTENT_KEYWORDS),
    unorderedListPredicate(CONTENT_DISTRIBUTION_KEYWORDS),
    unorderedListPredicate(CONTENT_POSITION_KEYWORDS),
]);

// <align-items>
// syntax: normal | stretch | baseline | <self-position>
export const alignItemsDataType = createDataType(DataTypeType.AlignItems, [
    unorderedListPredicate(ALIGN_ITEMS_KEYWORDS),
    unorderedListPredicate(SELF_POSITION_KEYWORDS),
]);

// justify-items
// syntax: normal | stretch | baseline | <self-position> | legacy | left | right | center
export const justifyItemsDataType = createDataType(DataTypeType.JustifyItems, [
    alignItemsDataType.predicate,
    unorderedListPredicate(JUSTIFY_ITEMS_KEYWORDS),
]);

// <'flex'>
// syntax: initial | auto | none
export const flexSingleValueDataType = createUnorderedListDataType(DataTypeType.Flex);

// <flex-grow>
// syntax: <number>
export const flexGrowDataType = createDataType(DataTypeType.FlexGrow, [numberPredicate]);

// <flex-shrink>
// syntax: <number>
export const flexShrinkDataType = createDataType(DataTypeType.FlexShrink, [numberPredicate]);

// <flex-basis>
// syntax: <width> | content | fill | max-content | min-content | fit-content
export const flexBasisDataType = createDataType(DataTypeType.FlexBasis, [
    widthDataType.predicate,
    unorderedListPredicate(FLEX_BASIS_KEYWORDS),
]);

// <flex-direction>
// syntax: row | row-reverse | column | column-reverse
export const flexDirectionDataType = createUnorderedListDataType(DataTypeType.FlexDirection);

// <flex-wrap>
// syntax: nowrap | wrap | wrap-reverse
export const flexWrapDataType = createUnorderedListDataType(DataTypeType.FlexWrap);

// <grid-line>
// syntax: auto | <custom-ident> | [ <integer> && <custom-ident>? ] | [ span && [ <integer> || <custom-ident> ] ]
export const gridLineDataType = createDataType(DataTypeType.GridLine, [
    unorderedListPredicate(GRID_LINE_AUTO_KEYWORD),
    gridLinePredicate,
]);

// <grid-line-end>
// syntax: <grid-line> [ / <grid-line> ]?
export const gridLineEndDataType = createDataType(DataTypeType.GridLineEnd, [gridLineDataType.predicate]);

// <grid-template-areas>
// syntax: none | <string>+
export const gridTemplateAreasDataType = createDataType(DataTypeType.GridTemplateAreas, [
    unorderedListPredicate(INITIAL_GRID_TEMPLATE_AREAS),
    gridTemplateAreasStringPredicate,
]);

// <grid-template-rows>
// syntax: none | <track-list> | <auto-track-list>
export const gridTemplateRowsDataType = createDataType(DataTypeType.GridTemplateRows, [
    unorderedListPredicate(GRID_TEMPLATE_AXIS_NONE_KEYWORD),
    autoTrackListPredicate,
    trackListPredicate,
]);

// <grid-template-columns>
// syntax: none | <track-list> | <auto-track-list>
export const gridTemplateColumnsDataType = createDataType(DataTypeType.GridTemplateColumns, [
    gridTemplateRowsDataType.predicate,
]);

// <explicit-track-list>
// syntax: [ <line-names>? <track-size> ]+ <line-names>?
export const explicitTrackListDataType = createDataType(DataTypeType.ExplicitTrackList, [explicitTrackListPredicate]);

// <grid-template>
// syntax: none
export const gridTemplateSingleValueDataType = createUnorderedListDataType(DataTypeType.GridTemplate);

// <grid-auto-flow>
// syntax: auto-flow && dense?
export const gridAutoFlowDataType = createDataType(DataTypeType.GridAutoFlow, [gridAutoFlowPredicate]);

// <grid-auto-rows>
// syntax: <track-size>+
export const gridAutoRowsDataType = createDataType(DataTypeType.GridAutoRows, [asteriskPredicate(trackSizePredicate)]);

// <grid-auto-columns>
// syntax: <track-size>+
export const gridAutoColumnsDataType = createDataType(DataTypeType.GridAutoColumns, [gridAutoRowsDataType.predicate]);

// <grid>
// syntax: none
export const gridSingleValueDataType = createUnorderedListDataType(DataTypeType.Grid);

// <overflow>
// syntax: visible | hidden | clip | scroll | auto
export const overflowDataType = createUnorderedListDataType(DataTypeType.Overflow);

// <text-decoration-line>
// syntax: none | [ underline || overline || line-through ]
export const textDecorationLineDataType = createDataType(DataTypeType.TextDecorationLine, [
    unorderedListPredicate(TEXT_DECORATION_LINE_NONE_KEYWORD),
    textDecorationLinePredicate,
]);

// <text-decoration-style>
// syntax: solid | double | dotted | dashed | wavy
export const textDecorationStyleDataType = createUnorderedListDataType(DataTypeType.TextDecorationStyle);

// <list-style>
// syntax: none
export const listStyleSingleValueDataType = createUnorderedListDataType(DataTypeType.ListStyle);

// <list-style-position>
// syntax: inside | outside
export const listStylePositionDataType = createUnorderedListDataType(DataTypeType.ListStylePosition);

// <list-style-type>
// syntax: <counter-style> | <string> | none
export const listStyleTypeDataType = createDataType(DataTypeType.ListStyleType, [
    unorderedListPredicate(LIST_STYLE_TYPE_KEYWORDS),
    stringPredicate(),
]);

// <inset>
// syntax: inset
export const insetDataType = createUnorderedListDataType(DataTypeType.Inset);

// <shadow>
// syntax: none
export const shadowSingleValueDataType = createUnorderedListDataType(DataTypeType.Shadow);
