import type { DimensionProps, SliderConfig, UnitRef, UnitRefPartialRecord } from '@wix/stylable-panel-common';
import keycode from 'keycode';
import type { Option } from '../option-list/option-list';
import {
    DEFAULT_SLIDER_MAX,
    DEFAULT_SLIDER_MIN,
    DEFAULT_STEP,
    MAX_FLOAT_VALUE_LENGTH,
    MAX_VALUE,
    MIN_VALUE,
    UNITLESS_SYMBOL,
} from './consts';
import { exponentValue } from './patterns';
import { CssUnit, DimensionInputState, DimensionRef, InputError, INPUT_UNITS_MAP, ParsedDimension } from './types';

export const keyInput = (key: number) => (c: string) => key === keycode(c);

// validation
export const isNumber = (n: number | string | undefined) => n !== undefined && !isNaN(+n);

export const isFloat = (n: number) => n % 1 !== 0;

export const validateNum = (n: number | undefined) => (isNumber(n) ? n : undefined);

export const unitFilter = (s: string) => isNaN(Number(s)) && s !== '.' && s !== '-';

export const valueFilter = (s: string) => !unitFilter(s);

export const sanitizeNegativeFloat = (nodes: string[]): string[] => {
    if (nodes[0] === '-.') {
        nodes[0] = '-0.';
    }
    return nodes;
};

export const stringExtract = (nodes: string[], matcher: (s: string) => boolean) =>
    nodes.filter((s: string) => matcher(s)).join('');

export const validateNodes = (nodes: string[]) => {
    const tokens = nodes.map((i) =>
        isNumber(Number(i)) ? 'digit' : i === '-' ? 'minusSymbol' : i === '.' ? 'floatPoint' : 'non-digit'
    );
    const lastDigit = tokens.lastIndexOf('digit');
    const firstNonDigit = tokens.indexOf('non-digit');
    return !(firstNonDigit > -1 && firstNonDigit < lastDigit);
};

export const breakError =
    (value: number, unit: CssUnit) =>
    (error: InputError): ParsedDimension =>
        Object.assign({}, { value, unit, error });

export const debug = (state: DimensionProps | DimensionInputState | Record<string, string>) =>
    JSON.stringify(state, null, 2);

// value
export const compileValue = (value: string) =>
    isNaN(Number(value)) || !!`${value}`.match(exponentValue) ? undefined : Number(value);

export const trimFloatValue = (value: number) =>
    Math.min(MAX_VALUE, Math.max(MIN_VALUE, +value.toFixed(MAX_FLOAT_VALUE_LENGTH)));

// units
export const getSymbolRef = (symbol: string): DimensionRef => {
    const refs = Object.entries(INPUT_UNITS_MAP).find((ref) => ref[1] === symbol) || [];

    return refs.length
        ? {
              name: refs[0] as UnitRef,
              symbol: refs[1],
          }
        : {
              name: INPUT_UNITS_MAP.unitless as UnitRef,
              symbol: UNITLESS_SYMBOL,
          };
};

export const getRefSymbol = (ref: UnitRef): CssUnit => INPUT_UNITS_MAP[ref] || UNITLESS_SYMBOL;

// dropdown
export const getDropdownOptions = (units: UnitRefPartialRecord, fallbackName = '') =>
    Object.entries(units).map(([optionName, { displaySymbol }]) => {
        const displayName = displaySymbol ?? INPUT_UNITS_MAP[optionName as UnitRef];
        const dropdownOption: Option = {
            id: optionName,
            displayName,
        };

        if (!displayName) {
            dropdownOption.fallbackName = fallbackName;
        }

        return dropdownOption;
    });

// slider
export const getSliderConfig = ({
    min,
    max,
    step,
    sliderMin,
    sliderMax,
    sliderStep,
}: DimensionProps): SliderConfig => ({
    sliderMin: isNumber(sliderMin) ? sliderMin : isNumber(min) ? min : DEFAULT_SLIDER_MIN,
    sliderMax: isNumber(sliderMax) ? sliderMax : isNumber(max) ? max : DEFAULT_SLIDER_MAX,
    sliderStep: isNumber(sliderStep) ? sliderStep : step || DEFAULT_STEP,
});
