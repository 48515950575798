import React, { useCallback } from 'react';
import { CompositeBlock, ToggleSwitch } from '@wix/stylable-panel-components';
import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import { useTranslate, useVisualizerChange } from '../../hooks';
import { PanelEventList } from '../../hosts/bi';
import type { IconVisualizerProps } from './icon-types';
import { ICON_DISPLAY_HIDDEN, ICON_DISPLAY_SHOWN } from './icon-visualizer';
import { classes } from './icon-visualizer.st.css';

export type ShowToggleProps = {
    props: IconVisualizerProps;
    elementShown: boolean;
};

export function ShowToggle({ props, elementShown }: ShowToggleProps) {
    const { selectorState, panelHost } = props;

    const translate = useTranslate(panelHost);
    const handleDisplayChange = useVisualizerChange('display', props);

    const handleChange = useCallback(
        (isToggled: boolean) => {
            handleDisplayChange && handleDisplayChange(isToggled ? ICON_DISPLAY_HIDDEN : ICON_DISPLAY_SHOWN);

            if (panelHost) {
                const { reportBI } = panelHost;
                if (!reportBI) {
                    return;
                }
                const { COMPONENT_PART_TOGGLE } = PanelEventList;
                reportBI?.(COMPONENT_PART_TOGGLE, { toggle_name: 'icon', toggle: !isToggled });
            }
        },
        [handleDisplayChange, panelHost]
    );

    return (
        <CompositeBlock
            className={classes.controllerBlock}
            title={translate(StylablePanelTranslationKeys.controller.icon.showLabel)}
            singleLine
            divider
        >
            <ToggleSwitch
                className={classes.shownToggle}
                value={elementShown}
                disabled={selectorState !== undefined}
                onChange={() => handleChange(elementShown)}
            />
        </CompositeBlock>
    );
}
