import { DEFAULT_PLANE } from '@wix/stylable-panel-common';
import { LinkButtonDisabled, LinkButtonEnabled } from '@wix/stylable-panel-common-react';
import { Button, CompositeBlock, Icon, OptimisticWrapper, Text, Tooltip } from '@wix/stylable-panel-components';
import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { OpenedDeclarationArray } from '../../declaration-types';
import { useTranslate } from '../../hooks';
import type { VisualizerFC } from '../../types';
import { compareVisualizerValues, createDeclarationVisualizer, visualizerOptimisticValueResolver } from '../../utils';
import { OUTSIDE_PROPS_LIST } from '../border-visualizer/border-utils';
import { CornerInput } from './corner-input';
import { CornerPreview } from './corner-preview';
import { CORNER_PROPS_LIST } from './corner-utils';
import type { CornerName, CornerProps, CornerVisualizerProps } from './corner-visualizer-types';
import { classes, style } from './corner-visualizer.st.css';
import { useCornerVisualizer } from './use-corner-visualizer';

type CornerVisualizer = VisualizerFC<CornerProps, CornerVisualizerProps>;
type CornerVisualizerValue = OpenedDeclarationArray<CornerProps>;

export const CornerVisualizerInner: CornerVisualizer = (props: CornerVisualizerProps) => {
    const { className, value, panelHost, plane = DEFAULT_PLANE } = props;
    const [inputHover, setInputHover] = useState('');

    const translate = useTranslate(panelHost);

    const {
        changeFromWithin,
        setChangeFromWithin,
        focused,
        setFocused,
        linked,
        shouldLink,
        disabled,
        openedDeclarationList,
        outsideValues,
        getCornerValue,
        toggleLink,
        toggleLinkClick,
        getDisabled,
    } = useCornerVisualizer(props);

    function usePrevious<T>(value: T) {
        const ref = useRef<T>();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevValue = usePrevious(value);

    useEffect(() => {
        setChangeFromWithin(false);
    }, [setChangeFromWithin]);

    useEffect(() => {
        if (
            linked !== shouldLink &&
            !changeFromWithin &&
            (!prevValue || compareVisualizerValues(value, prevValue, props))
        ) {
            toggleLink();
        }
    }, [changeFromWithin, linked, prevValue, props, shouldLink, toggleLink, value]);

    const getCornerInput = useCallback(
        (corner: CornerName) => (
            <CornerInput
                corner={corner}
                focused={focused}
                outsideValues={outsideValues}
                handleChangeFromWithin={setChangeFromWithin}
                linked={linked}
                openedDeclarationList={openedDeclarationList}
                handleChangeHover={setInputHover}
                handleChangeFocus={setFocused}
                props={props}
            />
        ),
        [focused, linked, openedDeclarationList, outsideValues, props, setChangeFromWithin, setFocused]
    );

    const getCornerPreview = useCallback(
        (corner: CornerName) => (
            <CornerPreview corner={corner} cornerValue={getCornerValue(corner)} disabled={getDisabled(corner)} />
        ),
        [getCornerValue, getDisabled]
    );

    const renderController = useCallback(() => {
        return (
            <div className={style(classes.input, { focused: focused as CornerName, linked, inputHover })}>
                <CompositeBlock
                    className={classes.controllerBlock}
                    title={translate(StylablePanelTranslationKeys.controller.corners.sectionLabel)}
                    information={translate(StylablePanelTranslationKeys.controller.corners.sectionTooltip)}
                />
                <div className={style(classes.controllerContainer, 'controllerContainer')}>
                    <div className={classes.topControls}>
                        {getCornerInput('topLeft')}
                        {getCornerInput('topRight')}
                    </div>
                    <div className={classes.controllerPreviewStage}>
                        <div className={classes.controllerPreviewContent}>
                            {getCornerPreview('topLeft')}
                            {getCornerPreview('topRight')}
                        </div>
                        <div className={classes.midControls}>
                            <Tooltip
                                className={classes.tooltip}
                                text={translate(
                                    linked
                                        ? StylablePanelTranslationKeys.controller.corners.unlockTooltip
                                        : StylablePanelTranslationKeys.controller.corners.lockTooltip
                                )}
                            >
                                <Button
                                    className={classes.linkButton}
                                    onClick={toggleLinkClick}
                                    isChecked={linked}
                                    isToggleButton
                                >
                                    <Icon>{linked ? <LinkButtonEnabled /> : <LinkButtonDisabled />}</Icon>
                                </Button>
                            </Tooltip>
                        </div>
                        <div className={classes.controllerPreviewContent}>
                            {getCornerPreview('bottomLeft')}
                            {getCornerPreview('bottomRight')}
                        </div>
                    </div>
                    <div className={classes.bottomControls}>
                        {getCornerInput('bottomLeft')}
                        {getCornerInput('bottomRight')}
                    </div>
                </div>
            </div>
        );
    }, [focused, getCornerInput, getCornerPreview, inputHover, linked, toggleLinkClick, translate]);

    return (
        <div className={style(classes.root, { plane, disabled }, className)} data-common-controller-highlight-parts>
            <Text className={classes.title}>{translate(StylablePanelTranslationKeys.controller.corners.title)}</Text>
            {renderController()}
        </div>
    );
};

CornerVisualizerInner.INPUT_PROPS = CORNER_PROPS_LIST;
CornerVisualizerInner.OUTSIDE_PROPS = OUTSIDE_PROPS_LIST;

export const CornerVisualizer = OptimisticWrapper<CornerVisualizer, CornerVisualizerValue>(
    CornerVisualizerInner,
    {},
    true,
    visualizerOptimisticValueResolver
);

export const CornersDeclarationVisualizer = createDeclarationVisualizer<CornerProps>(
    'border-radius',
    CornerVisualizer,
    'Corners'
);
