import { Information } from '@wix/stylable-panel-common-react';
import React from 'react';
import { classes, style } from './information-tooltip.st.css';
import { Tooltip } from './tooltip';

export interface InformationTooltipProps {
    className?: string;
    horizontalAdjust?: number;
    style?: React.CSSProperties;
    text?: string;
    verticalAdjust?: number;
}

export const InformationTooltip = ({
    text,
    verticalAdjust,
    horizontalAdjust,
    className,
    style: propStyle,
}: InformationTooltipProps) => (
    <Tooltip
        className={style(classes.root, className)}
        style={propStyle}
        text={text}
        verticalAdjust={-3 + (verticalAdjust || 0)}
        horizontalAdjust={horizontalAdjust}
    >
        <Information />
    </Tooltip>
);
