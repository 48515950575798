import type {
    ParsedCompoundValue,
    ParsedCompound,
    CompoundParser,
    ParseMap,
    CompoundParserData,
} from './compound-types';
import { ShorthandPart, createShorthandOpener } from '../shorthands';

const setParsedCompoundValue = <V, M>(
    parseMap: ParseMap<M>,
    node: ParsedCompoundValue<V, M[keyof M]>,
    prop: string
) => {
    if (!Array.isArray(node)) {
        node.parsedValue = parseMap[prop](node.value.text, prop);
        return;
    }
    for (const innerNode of node) {
        setParsedCompoundValue(parseMap, innerNode, prop);
    }
};

export const createCompoundParser =
    <V, M>({ prop, singleKeywordPart, parts, shorthandOpener }: CompoundParserData<V, M>): CompoundParser<V, M> =>
    (compound, api) => {
        const parseMap: ParseMap<M> = {};
        if (singleKeywordPart) {
            parseMap[singleKeywordPart.prop] = singleKeywordPart.parser;
        }
        for (const part of parts) {
            parseMap[part.prop] = part.parser;
        }
        const openCompoundAsShorthand = createShorthandOpener<V, string>({
            prop,
            singleKeywordPart,
            parts: parts as ShorthandPart<V>[],
            shorthandOpener,
        });
        const opened = openCompoundAsShorthand(compound, api);
        const parsed = { ...opened } as ParsedCompound<V, M>;
        const props = Object.keys(opened) as Array<keyof M>;
        for (const prop of props) {
            setParsedCompoundValue(parseMap, parsed[prop], prop as string);
        }
        return parsed;
    };
