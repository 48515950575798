import React from 'react';
import { Text } from '../text/text';
import { Tooltip, TooltipAttachTo } from '../tooltip/tooltip';
import { classes, style } from './label-with-action.st.css';

export interface LabelWithActionProps {
    text?: string;
    actionText?: string;
    actionTooltipText?: string;
    actionTooltipAttachSide?: TooltipAttachTo;
    actionDataAid?: string;
    onActionClick?: () => void;
    className?: string;
    style?: React.CSSProperties;
}

export const LabelWithAction: React.FunctionComponent<LabelWithActionProps> = ({
    text,
    actionText,
    actionTooltipText,
    actionTooltipAttachSide,
    actionDataAid,
    onActionClick,
    className,
    style: propStyle,
}) => (
    <div className={style(classes.root, className)} style={propStyle}>
        <Text className={classes.label}>{text}</Text>
        {actionText ? (
            <Tooltip text={actionTooltipText} className={classes.actionTooltip} attachTo={actionTooltipAttachSide}>
                <Text className={classes.actionButton} onClick={onActionClick} data-aid={actionDataAid}>
                    {actionText}
                </Text>
            </Tooltip>
        ) : null}
    </div>
);
