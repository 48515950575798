import type { CSSCodeAst, MethodCall } from '@wix/shorthands-opener';

import type { OpenedShorthandValue, DeclarationValue } from '../declaration-types';
import type { BackgroundShorthandLayer } from '../controllers/background-visualizer/background-types';

const FORMATTER_NAME_REPLACEMENT = 'url';

// TODO: Remove hardcoded const and only use what's specified in panelHost
export const FORMATTERS = ['wixMediaUrl'];

export const formattersPreOpen = (value: DeclarationValue, formatters?: string[]): DeclarationValue =>
    formatters
        ? value.map((item) => {
              const node = item as CSSCodeAst;
              return node.type === 'call' && formatters.includes(node.name)
                  ? { ...node, name: FORMATTER_NAME_REPLACEMENT }
                  : item;
          })
        : value;

export const formattersPostOpen = (
    layer: BackgroundShorthandLayer,
    prop: keyof BackgroundShorthandLayer,
    formatters?: string[]
): OpenedShorthandValue => {
    if (formatters && prop === 'background-image') {
        const imageValue = layer[prop].value;
        if ((imageValue as MethodCall).name === FORMATTER_NAME_REPLACEMENT) {
            const foundFormatter = formatters.find((formatter) => imageValue.text.startsWith(formatter));
            if (foundFormatter) {
                return {
                    ...layer[prop],
                    value: { ...imageValue, name: foundFormatter } as MethodCall,
                };
            }
        }
    }
    return layer[prop];
};
