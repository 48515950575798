import React, { useMemo, useRef } from 'react';
import { PopupPanel } from '@wix/stylable-panel-components';
import { InfoIcon, RichText, SortByDragList, TextButton } from '@wix/wix-base-ui';
import UnlinkPropertiesSmall from 'wix-ui-icons-common/classic-editor/UnlinkPropertiesSmall';

import type { Var } from '@wix/stylable-panel-drivers';
import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';

import { classes } from './advanced-colors-picker.st.css';

const texts = StylablePanelTranslationKeys.picker.advancedSettings;

type Props = {
    colors?: Var[];
    onItemClick: (color: string) => void;
    selectedColor: string | null;
    onClose?: () => void;
    translations: Record<string, string>;
    onEditColorDefaults?: () => void;
    getLinkedThemeColorFromColorDefault?: (colorName: string | null) => string | undefined;
    openColorDefaultsHelp?: () => void;
    disableAdvancedColors?: boolean;
};

export const AdvancedColorsPicker = ({
    colors = [],
    selectedColor,
    onItemClick,
    onClose,
    translations,
    onEditColorDefaults,
    getLinkedThemeColorFromColorDefault,
    openColorDefaultsHelp,
    disableAdvancedColors,
}: Props) => {
    const selectedCompRef = useRef<HTMLDivElement | null>(null);

    const items = useMemo(
        () => [
            {
                id: 'none',
                label: 'None',
                contentBefore: <UnlinkPropertiesSmall />,
            },
            ...colors
                .filter(({ name }) => !!texts.labels[name as keyof typeof texts.labels])
                .map(({ value, name }) => ({
                    id: name,
                    label: translations[texts.labels[name as keyof typeof texts.labels]],
                    selected: disableAdvancedColors ? false : selectedColor === name,
                    contentBefore: <div className={classes.colorSwatch} style={{ backgroundColor: value }} />,
                    contentAfter: (
                        <div ref={selectedColor === name ? selectedCompRef : null} style={{ height: '100%' }} />
                    ),
                })),
        ],
        [colors, translations, selectedColor, disableAdvancedColors]
    );

    const handleClick = (colorName: string) => {
        let newValue = colorName;
        if (newValue === 'none') {
            const linkedTo = getLinkedThemeColorFromColorDefault?.(selectedColor);
            // if previous selected value is not 'color default' (is not linked to a theme color), don't do anything
            if (!linkedTo) {
                return;
            }
            // if component was linked to a 'color default', then link it to the same 'theme colors' as this 'color default' points to
            newValue = linkedTo;
        }
        onItemClick?.(newValue);
    };

    return (
        <PopupPanel title={translations[texts.title]} className={classes.panel} onClose={onClose}>
            <div className={classes.container} data-hook="advanced-settings-panel">
                <RichText type="T02" className={classes.description}>
                    <p>{translations[texts.description]}</p>

                    <TextButton size="small" onClick={openColorDefaultsHelp} shouldTranslate={false}>
                        {translations[texts.learnMore]}
                    </TextButton>
                </RichText>
                <div className={classes.itemsContainer} data-scrollable>
                    <SortByDragList
                        removeContextMenu
                        dynamicWidth
                        disableEditing
                        selectable
                        draggable={false}
                        value={items}
                        selectItem={({ id }: { id: string }) => handleClick?.(id)}
                    />

                    <div className={classes.footer}>
                        <TextButton onClick={onEditColorDefaults} shouldTranslate={false}>
                            {translations[texts.editDefaults]}
                        </TextButton>
                        <InfoIcon shouldTranslate={false} text={translations[texts.editDefaultsTooltip]} />
                    </div>
                </div>
            </div>
        </PopupPanel>
    );
};
