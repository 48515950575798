import type {
    ShorthandOpener,
    SimpleShorthandOpener,
    GetShorthandOpener,
    ShorthandCloser,
    GetShorthandCloser,
} from './shorthand-types';
import type { ShorthandsTypeMap, ShorthandsSpecialReturnTypeMap } from './shorthand-css-data';

import {
    openBackgroundShorthand,
    closeBackgroundShorthand,
    openBorderRadiusShorthand,
    closeBorderRadiusShorthand,
    openBorderShorthand,
    closeBorderShorthand,
    openBorderTopShorthand,
    closeBorderTopShorthand,
    openBorderRightShorthand,
    closeBorderRightShorthand,
    openBorderBottomShorthand,
    closeBorderBottomShorthand,
    openBorderLeftShorthand,
    closeBorderLeftShorthand,
    openBorderStyleShorthand,
    closeBorderStyleShorthand,
    openBorderWidthShorthand,
    closeBorderWidthShorthand,
    openBorderColorShorthand,
    closeBorderColorShorthand,
    openOutlineShorthand,
    closeOutlineShorthand,
    openBorderImageShorthand,
    closeBorderImageShorthand,
    openFontShorthand,
    closeFontShorthand,
    openPlaceContentShorthand,
    closePlaceContentShorthand,
    openPlaceItemsShorthand,
    closePlaceItemsShorthand,
    openFlexShorthand,
    closeFlexShorthand,
    openFlexFlowShorthand,
    closeFlexFlowShorthand,
    openGapShorthand,
    closeGapShorthand,
    openGridRowShorthand,
    closeGridRowShorthand,
    openGridColumnShorthand,
    closeGridColumnShorthand,
    openGridGapShorthand,
    closeGridGapShorthand,
    openGridTemplateShorthand,
    closeGridTemplateShorthand,
    openGridShorthand,
    closeGridShorthand,
    openMarginShorthand,
    closeMarginShorthand,
    openPaddingShorthand,
    closePaddingShorthand,
    openOverflowShorthand,
    closeOverflowShorthand,
    openTextDecorationShorthand,
    closeTextDecorationShorthand,
    openListStyleShorthand,
    closeListStyleShorthand,
} from './openers';

type ShorthandMapToOpener<T extends Record<string, string>> = {
    [K in keyof T]: GetShorthandOpener<T[K]>;
};
type ShorthandOpenersMap = ShorthandMapToOpener<ShorthandsTypeMap>;

type ShorthandMapToCloser<T extends Record<string, string>> = {
    [K in keyof T]: GetShorthandCloser<T[K]>;
};
type ShorthandClosesrMap = ShorthandMapToCloser<ShorthandsTypeMap>;

const shorthandOpenersMap: ShorthandOpenersMap = {
    background: openBackgroundShorthand as unknown as GetShorthandOpener<ShorthandsTypeMap['background']>,
    'border-radius': openBorderRadiusShorthand as unknown as GetShorthandOpener<ShorthandsTypeMap['border-radius']>,
    border: openBorderShorthand,
    'border-top': openBorderTopShorthand,
    'border-right': openBorderRightShorthand,
    'border-bottom': openBorderBottomShorthand,
    'border-left': openBorderLeftShorthand,
    'border-style': openBorderStyleShorthand,
    'border-width': openBorderWidthShorthand,
    'border-color': openBorderColorShorthand,
    outline: openOutlineShorthand,
    'border-image': openBorderImageShorthand as GetShorthandOpener<ShorthandsTypeMap['border-image']>,
    font: openFontShorthand as GetShorthandOpener<ShorthandsTypeMap['font']>,
    'place-content': openPlaceContentShorthand,
    'place-items': openPlaceItemsShorthand,
    flex: openFlexShorthand,
    'flex-flow': openFlexFlowShorthand,
    margin: openMarginShorthand,
    padding: openPaddingShorthand,
    overflow: openOverflowShorthand,
    gap: openGapShorthand,
    'grid-gap': openGridGapShorthand,
    'grid-row': openGridRowShorthand,
    'grid-column': openGridColumnShorthand,
    'grid-template': openGridTemplateShorthand,
    grid: openGridShorthand,
    'text-decoration': openTextDecorationShorthand,
    'list-style': openListStyleShorthand,
};

const shorthandClosersMap: ShorthandClosesrMap = {
    background: closeBackgroundShorthand as unknown as GetShorthandCloser<ShorthandsTypeMap['background']>,
    'border-radius': closeBorderRadiusShorthand as unknown as GetShorthandCloser<ShorthandsTypeMap['border-radius']>,
    border: closeBorderShorthand,
    'border-top': closeBorderTopShorthand,
    'border-right': closeBorderRightShorthand,
    'border-bottom': closeBorderBottomShorthand,
    'border-left': closeBorderLeftShorthand,
    'border-style': closeBorderStyleShorthand,
    'border-width': closeBorderWidthShorthand,
    'border-color': closeBorderColorShorthand,
    outline: closeOutlineShorthand,
    'border-image': closeBorderImageShorthand,
    font: closeFontShorthand,
    'place-content': closePlaceContentShorthand,
    'place-items': closePlaceItemsShorthand,
    flex: closeFlexShorthand,
    'flex-flow': closeFlexFlowShorthand,
    margin: closeMarginShorthand,
    padding: closePaddingShorthand,
    overflow: closeOverflowShorthand,
    gap: closeGapShorthand,
    'grid-gap': closeGridGapShorthand,
    'grid-row': closeGridRowShorthand,
    'grid-column': closeGridColumnShorthand,
    'grid-template': closeGridTemplateShorthand,
    grid: closeGridShorthand,
    'text-decoration': closeTextDecorationShorthand,
    'list-style': closeListStyleShorthand,
};

export type MappedShorthandOpener<
    V,
    T extends keyof ShorthandsTypeMap
> = T extends keyof ShorthandsSpecialReturnTypeMap<V>
    ? ShorthandOpener<V, ShorthandsTypeMap[T], ShorthandsSpecialReturnTypeMap<V>[T]>
    : SimpleShorthandOpener<V, ShorthandsTypeMap[T]>;

export const getShorthandOpener = <V, T extends keyof ShorthandsTypeMap>(prop: T): MappedShorthandOpener<V, T> => {
    const getOpener = shorthandOpenersMap[prop] as GetShorthandOpener<ShorthandsTypeMap[T]>;
    return getOpener<V>() as MappedShorthandOpener<V, T>;
};

export const getShorthandCloser = <V, T extends keyof ShorthandsTypeMap>(
    prop: T
): ShorthandCloser<V, ShorthandsTypeMap[T]> => {
    const getCloser = shorthandClosersMap[prop] as GetShorthandCloser<ShorthandsTypeMap[T]>;
    return getCloser<V>();
};
