import { createContext } from 'react';

import type { SiteVarsDriver } from '@wix/stylable-panel-drivers';

import type { StylablePanelHost } from '../../types';

export interface FillPickerContext {
    siteVarsDriver?: SiteVarsDriver;
    panelHost?: StylablePanelHost;
}

export const FillPickerContext = createContext<FillPickerContext>({} as FillPickerContext);
