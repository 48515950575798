import { CompositeBlock } from '@wix/stylable-panel-components';
import React, { useCallback } from 'react';
import type { OpenedDeclarationArray } from '../../declaration-types';
import {
    BoldVisualizer,
    DirectionVisualizer,
    ItalicVisualizer,
    StrikethroughVisualizer,
    TextAlignVisualizer,
    TextTransformVisualizer,
    TEXT_DECORATION_NONE,
    TEXT_DECORATION_STRIKETHROUGH,
    TEXT_DECORATION_UNDERLINE,
    UnderlineVisualizer,
} from '../../generated-visualizers';
import { useTranslate } from '../../hooks';
import { createDeclarationMapFromVisualizerValue, createVisualizerValueFromDeclarationMap } from '../../utils';
import type { Stroke, TextDeclarationMap, TextVisualizerProps } from './use-text-visualizer';
import {
    ColorIcon,
    ColorPickerTextType,
    getIconConfiguration,
    getIconRows,
    IconConfigurationMap,
    IconInRowProps,
    IconKey,
    MAX_ROW_ICONS,
} from './text-visualizer-icons';
import { classes, style } from './text-visualizer.st.css';
import { ID_FUNCTION } from './text-visualizer';

const TEXT_TRANSFORM_DEFAULT = 'none';
const TEXT_ALIGN_DEFAULT = 'left';

export type IconRowsBlockProps = {
    noResizing?: boolean;
    props: TextVisualizerProps;
    declarationMapValue: TextDeclarationMap;
    openColorPicker: (colorPickerType: ColorPickerTextType) => void;
    changeFromBlock: (prop: string, valueFunction: (value: string) => string, value: string) => void;
    stroke: Stroke;
};

export function IconRowsBlock({
    noResizing = false,
    props,
    declarationMapValue,
    openColorPicker,
    changeFromBlock,
    stroke,
}: IconRowsBlockProps) {
    const { noHighlight, drivers, panelHost } = props;
    const iconConfiguration = getIconConfiguration({
        noHighlight,
        noResizing,
    });
    const translate = useTranslate(panelHost);
    const {
        'font-weight': fontWeight,
        'font-style': fontStyle,
        'text-decoration-line': textDecoration,
        color,
        'background-color': backgroundColor,
        'text-transform': transformValue = TEXT_TRANSFORM_DEFAULT,
        'text-align': alignValue = TEXT_ALIGN_DEFAULT,
        direction,
    } = declarationMapValue;

    const handleBoldChange = useCallback(
        (change: OpenedDeclarationArray<'font-weight'>) => {
            const { 'font-weight': fontWeight } = createDeclarationMapFromVisualizerValue(change, {
                value: [],
                drivers,
            });
            if (fontWeight) {
                changeFromBlock('font-weight', ID_FUNCTION, fontWeight);
            }
        },
        [changeFromBlock, drivers]
    );

    const handleStyleChange = useCallback(
        (change: OpenedDeclarationArray<'font-style'>) => {
            const { 'font-style': fontStyle } = createDeclarationMapFromVisualizerValue(change, {
                value: [],
                drivers,
            });
            if (fontStyle) {
                changeFromBlock('font-style', ID_FUNCTION, fontStyle);
            }
        },
        [changeFromBlock, drivers]
    );

    const handleTextTransformChange = useCallback(
        (change: OpenedDeclarationArray<'text-transform'>) => {
            const { 'text-transform': textTransform } = createDeclarationMapFromVisualizerValue(change, {
                value: [],
                drivers,
            });
            if (textTransform) {
                changeFromBlock('text-transform', ID_FUNCTION, textTransform);
            }
        },
        [changeFromBlock, drivers]
    );

    const handleDirectionChange = useCallback(
        (change: OpenedDeclarationArray<'direction'>) => {
            const { direction } = createDeclarationMapFromVisualizerValue(change, {
                value: [],
                drivers,
            });
            if (direction) {
                changeFromBlock('direction', ID_FUNCTION, direction);
            }
        },
        [changeFromBlock, drivers]
    );

    const handleTextAlignChange = useCallback(
        (change: OpenedDeclarationArray<'text-align'>) => {
            const { 'text-align': textAlign } = createDeclarationMapFromVisualizerValue(change, {
                value: [],
                drivers,
            });
            if (textAlign) {
                changeFromBlock('text-align', ID_FUNCTION, textAlign);
            }
        },
        [changeFromBlock, drivers]
    );

    const handleUnderlineChange = useCallback(
        (change: OpenedDeclarationArray<'text-decoration-line'>) => {
            const { 'text-decoration-line': textDecorationNew } = createDeclarationMapFromVisualizerValue(change, {
                value: [],
                drivers,
            });
            if (textDecorationNew) {
                changeFromBlock(
                    'text-decoration-line',
                    (value) =>
                        textDecoration?.includes(TEXT_DECORATION_STRIKETHROUGH)
                            ? value === TEXT_DECORATION_NONE
                                ? TEXT_DECORATION_STRIKETHROUGH
                                : `${TEXT_DECORATION_UNDERLINE} ${TEXT_DECORATION_STRIKETHROUGH}`
                            : value,
                    textDecorationNew
                );
            }
        },
        [changeFromBlock, drivers, textDecoration]
    );

    const handleLinethroughChange = useCallback(
        (change: OpenedDeclarationArray<'text-decoration-line'>) => {
            const { 'text-decoration-line': textDecorationNew } = createDeclarationMapFromVisualizerValue(change, {
                value: [],
                drivers,
            });
            if (textDecorationNew) {
                changeFromBlock(
                    'text-decoration-line',
                    (value) =>
                        textDecoration?.includes(TEXT_DECORATION_UNDERLINE)
                            ? value === TEXT_DECORATION_NONE
                                ? TEXT_DECORATION_UNDERLINE
                                : `${TEXT_DECORATION_UNDERLINE} ${TEXT_DECORATION_STRIKETHROUGH}`
                            : value,
                    textDecorationNew
                );
            }
        },
        [changeFromBlock, drivers, textDecoration]
    );

    const getIconRenderMap = useCallback(
        (
            iconConfiguration: IconConfigurationMap
        ): Record<IconKey, (iconKey: IconKey) => JSX.Element | JSX.Element[]> => {
            const iconRowProps = (iconKey: IconKey) =>
                ({
                    key: `icon_${iconKey}`,
                    iconKey,
                    iconConfiguration,
                    translate,
                } as IconInRowProps);

            return {
                [IconKey.Bold]: (iconKey) => (
                    <BoldVisualizer
                        key={`icon_${iconKey}`}
                        drivers={drivers}
                        panelHost={panelHost}
                        className={classes.boldVisualizer}
                        value={createVisualizerValueFromDeclarationMap({
                            'font-weight': fontWeight,
                        })}
                        onChange={handleBoldChange}
                    />
                ),
                [IconKey.Italic]: (iconKey) => (
                    <ItalicVisualizer
                        key={`icon_${iconKey}`}
                        drivers={drivers}
                        panelHost={panelHost}
                        className={classes.italicVisualizer}
                        value={createVisualizerValueFromDeclarationMap({
                            'font-style': fontStyle,
                        })}
                        onChange={handleStyleChange}
                    />
                ),
                [IconKey.Underline]: (iconKey) => (
                    <UnderlineVisualizer
                        key={`icon_${iconKey}`}
                        drivers={drivers}
                        panelHost={panelHost}
                        className={classes.underlineVisualizer}
                        value={createVisualizerValueFromDeclarationMap({
                            'text-decoration-line': textDecoration,
                        })}
                        onChange={handleUnderlineChange}
                    />
                ),
                [IconKey.Strikethrough]: (iconKey) => (
                    <StrikethroughVisualizer
                        key={`icon_${iconKey}`}
                        drivers={drivers}
                        panelHost={panelHost}
                        className={classes.strikethroughVisualizer}
                        value={createVisualizerValueFromDeclarationMap({
                            'text-decoration-line': textDecoration,
                        })}
                        onChange={handleLinethroughChange}
                    />
                ),
                [IconKey.TextColor]: (iconKey) => (
                    <ColorIcon
                        {...iconRowProps(iconKey)}
                        type={ColorPickerTextType.Text}
                        color={color}
                        openColorPicker={openColorPicker}
                        iconClassName={classes.textColorIcon}
                    />
                ),
                [IconKey.BackgroundColor]: (iconKey) => (
                    <ColorIcon
                        {...iconRowProps(iconKey)}
                        type={ColorPickerTextType.Background}
                        color={backgroundColor}
                        openColorPicker={openColorPicker}
                        iconClassName={classes.backgroundColorIcon}
                    />
                ),
                [IconKey.StrokeColor]: (iconKey) => (
                    <ColorIcon
                        {...iconRowProps(iconKey)}
                        type={ColorPickerTextType.Stroke}
                        color={stroke.color}
                        openColorPicker={openColorPicker}
                        iconClassName={classes.strokeColorIcon}
                    />
                ),
                [IconKey.Transform]: (iconKey) => (
                    <TextTransformVisualizer
                        key={`icon_${iconKey}`}
                        drivers={drivers}
                        panelHost={panelHost}
                        className={classes.transformVisualizer}
                        value={createVisualizerValueFromDeclarationMap({
                            'text-transform': transformValue,
                        })}
                        onChange={handleTextTransformChange}
                    />
                ),
                [IconKey.Direction]: (iconKey) => (
                    <DirectionVisualizer
                        key={`icon_${iconKey}`}
                        drivers={drivers}
                        panelHost={panelHost}
                        className={classes.directionVisualizer}
                        value={createVisualizerValueFromDeclarationMap({
                            direction,
                        })}
                        onChange={handleDirectionChange}
                    />
                ),
                [IconKey.Alignment]: (iconKey) => (
                    <TextAlignVisualizer
                        key={`icon_${iconKey}`}
                        drivers={drivers}
                        panelHost={panelHost}
                        className={classes.alignmentVisualizer}
                        value={createVisualizerValueFromDeclarationMap({
                            'text-align': alignValue,
                        })}
                        onChange={handleTextAlignChange}
                    />
                ),
            };
        },
        [
            translate,
            drivers,
            panelHost,
            fontWeight,
            handleBoldChange,
            fontStyle,
            handleStyleChange,
            textDecoration,
            handleUnderlineChange,
            handleLinethroughChange,
            color,
            openColorPicker,
            backgroundColor,
            stroke.color,
            transformValue,
            handleTextTransformChange,
            direction,
            handleDirectionChange,
            alignValue,
            handleTextAlignChange,
        ]
    );

    const iconRenderMap = getIconRenderMap(iconConfiguration);
    const iconRows = getIconRows(iconConfiguration, MAX_ROW_ICONS);

    return (
        <>
            {iconRows.map((iconRow, index) => (
                <CompositeBlock
                    key={`icon-row-${index}`}
                    className={style(classes.controllerBlock, classes.toggleIcons)}
                    iconRow
                    divider
                >
                    {iconRow.map((iconKey) => iconRenderMap[iconKey](iconKey))}
                </CompositeBlock>
            ))}
        </>
    );
}
