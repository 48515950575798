import chroma from 'chroma-js';
import type { StylesheetDriver, Var } from './stylable-stylesheet';
import type { EvalDeclarationValue } from './types';

export type SiteVarPatternBuilder = (firstIndex?: string, secondIndex?: string) => string;
export type UserVarPatternBuilder = (index?: string) => string;

export const DEFAULT_SITE_VAR_PATTERN_BUILDER: SiteVarPatternBuilder = (
    firstIndex = '(\\d+)',
    secondIndex = '(\\d+)'
) => `site_${firstIndex}_${secondIndex}`;
export const DEFAULT_USER_VAR_PATTERN_BUILDER: UserVarPatternBuilder = (index = '(\\d+)') =>
    `${COLOR_VAR_CATEGORY_USER}_${index}`;
export const DEFAULT_LOAD_SITE_COLORS: () => void = () => {
    /**/
};
export const DEFAULT_WRAP_SITE_COLOR: (value: string) => string = (value: string) => value;
export const DEFAULT_EVAL_DECLARATION_VALUE: EvalDeclarationValue = (value: string | undefined) => value || '';

export const SITE_COLORS_VARS = 5;
export const SITE_COLORS_VAR_SHADES = 5;

export const COLOR_VAR_CATEGORY_USER = 'user';

export interface SiteVarsDriver {
    siteColors: Var[];
    newSiteColors: Var[];
    siteColorsMap: Record<string, string>;
    userColors: Var[];
    userColorsMap: Record<string, string>;
    varCategories: Record<string, { patternBuilder?: UserVarPatternBuilder; values: Var[] }>;

    getCategoryValues: (categoryName: string) => Var[];
    clearCategory: (categoryName: string) => void;
    addValue: (categoryName: string, value: string) => void;
    removeUserValue: (categoryName: string, index: number) => boolean;
    findSiteColor: (color: chroma.Color) => number;
    findSiteColor__new: (
        color: chroma.Color,
        isNewPalette?: boolean,
        visibleThemeColors?: string[]
    ) => string | undefined;
    findUserColor: (color: chroma.Color) => number;
    findUserColor__new: (color: chroma.Color) => string | undefined;
    addUserColor: (value: string) => void;

    /* Relevant only for Stylable */
    sheet: StylesheetDriver;
    loadSiteColors?: () => void;
    addCategory?: (categoryName: string, patternBuilder: UserVarPatternBuilder) => void;
    wrapSiteColor?: (value: string) => string;
    evalDeclarationValue?: EvalDeclarationValue;
}

export class StylableSiteVars implements SiteVarsDriver {
    public siteColors: Var[] = [];
    public newSiteColors: Var[] = [];
    public siteColorsMap: Record<string, string> = {};
    public varCategories: Record<string, { patternBuilder?: UserVarPatternBuilder; values: Var[] }>;

    constructor(
        public sheet: StylesheetDriver,
        private siteVarPatternBuilder: SiteVarPatternBuilder = DEFAULT_SITE_VAR_PATTERN_BUILDER,
        userVarPatternBuilder: UserVarPatternBuilder = DEFAULT_USER_VAR_PATTERN_BUILDER
    ) {
        this.loadSiteColors();

        this.varCategories = {};
        this.addCategory(COLOR_VAR_CATEGORY_USER, userVarPatternBuilder);
    }

    get userColors() {
        return this.getCategoryValues(COLOR_VAR_CATEGORY_USER);
    }

    get userColorsMap() {
        // @TODO optimize this
        return this.getCategoryValues(COLOR_VAR_CATEGORY_USER).reduce((acc, { name, value }) => {
            acc[name] = value;
            return acc;
        }, {} as Record<string, string>);
    }

    public loadSiteColors() {
        this.newSiteColors = this.sheet.getNewPalleteVars();
        const siteVarPattern = new RegExp(this.siteVarPatternBuilder('(\\d+)', '(\\d+)'));

        this.siteColors = [];
        const siteColors = this.sheet.getGroupVars(siteVarPattern, 2) as Var[][];
        if (
            siteColors.length === SITE_COLORS_VARS &&
            siteColors.every((palette) => palette.length === SITE_COLORS_VAR_SHADES)
        ) {
            for (let i = 0; i < SITE_COLORS_VAR_SHADES; i++) {
                for (let j = 0; j < SITE_COLORS_VARS; j++) {
                    this.siteColors.push(siteColors[i][j]);
                }
            }
        }

        this.siteColorsMap = [...this.siteColors, ...this.newSiteColors].reduce((acc, { name, value }) => {
            acc[name] = value;
            return acc;
        }, {} as Record<string, string>);
    }

    public addCategory(categoryName: string, patternBuilder: UserVarPatternBuilder) {
        this.varCategories[categoryName] = {
            patternBuilder,
            values: this.sheet.getGroupVars(new RegExp(patternBuilder('(\\d+)')), 1) as Var[],
        };
    }

    public getCategoryValues(categoryName: string) {
        return this.varCategories[categoryName].values;
    }

    public clearCategory(categoryName: string) {
        const category = this.varCategories[categoryName];
        if (!category) {
            return;
        }

        category.values.forEach((value) => this.sheet.deleteVar(value.name));
        this.varCategories[categoryName].values = [];
    }

    public addValue(categoryName: string, value: string) {
        const category = this.varCategories[categoryName];
        if (!category) {
            return;
        }

        const { patternBuilder = DEFAULT_USER_VAR_PATTERN_BUILDER, values } = category;

        let newVarIndex = 0;
        if (values.length !== 0) {
            const varPattern = new RegExp(patternBuilder('(\\d+)'));
            const lastVarName = values[values.length - 1].name;
            const nameMatch = lastVarName.match(varPattern);

            if (!nameMatch || nameMatch[1] === undefined) {
                return;
            }

            newVarIndex = parseInt(nameMatch[1], 10) + 1;
        }

        const newVarName = patternBuilder(`${newVarIndex}`);
        this.sheet.setVar(newVarName, value);
        this.varCategories[categoryName].values.push({ name: newVarName, value });
    }

    public removeUserValue(categoryName: string, index: number) {
        const category = this.varCategories[categoryName];
        if (!category) {
            return false;
        }

        const value = category.values[index];
        if (value) {
            this.sheet.deleteVar(value.name);
            category.values.splice(index, 1);
            return true;
        }
        return false;
    }

    // rename these function when colorpicker is updated in editor
    public findSiteColor__new(color: chroma.Color, isNewPalette?: boolean, _visibleColors?: string[]) {
        return this.findColorInVarList__new(isNewPalette ? this.newSiteColors : this.siteColors, color);
    }

    private findColorInVarList__new(varList: Var[], color: chroma.Color) {
        return varList.find(
            (varObj) => color.hex('rgb').toLowerCase() === chroma(varObj.value).hex('rgb').toLowerCase()
        )?.name;
    }

    public findUserColor__new(color: chroma.Color) {
        return this.findColorInVarList__new(this.getCategoryValues(COLOR_VAR_CATEGORY_USER), color);
    }

    public findSiteColor(color: chroma.Color) {
        return this.findColorInVarList(this.siteColors, color);
    }

    public findUserColor(color: chroma.Color) {
        return this.findColorInVarList(this.getCategoryValues(COLOR_VAR_CATEGORY_USER), color);
    }

    public setSiteColors(columns: string[][]) {
        columns.forEach((column, columnIndex) => {
            column.forEach((value, colorIndex) => {
                const name = this.siteVarPatternBuilder(`${columnIndex + 1}`, `${colorIndex + 1}`);
                this.sheet.setVar(name, value);
                this.siteColors[SITE_COLORS_VAR_SHADES * colorIndex + columnIndex] = { name, value };
            });
        });
    }

    public addUserColor(value: string) {
        this.addValue(COLOR_VAR_CATEGORY_USER, value);
    }

    public wrapSiteColor(value: string) {
        if (value.startsWith('value(')) {
            return value;
        }

        try {
            const valueColor = chroma(value);
            const foundSiteColor = this.siteColors.find((varObj) =>
                valueColor.rgba().every((val, index) => val === chroma(varObj.value).rgba()[index])
            );
            if (foundSiteColor) {
                return `value(${foundSiteColor.name})`;
            }
        } catch {
            //
        }

        return value;
    }

    public evalDeclarationValue(value: string | undefined) {
        return this.sheet.evalDeclarationValue(value);
    }

    private findColorInVarList(varList: Var[], color: chroma.Color) {
        return varList.findIndex((varObj) => color.hex('rgb') === chroma(varObj.value).hex('rgb'));
    }
}
