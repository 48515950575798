import type {
    CSSAstNode,
    EvaluatedAst,
    OpenedShorthand,
    SimpleShorthandOpener,
    SimpleShorthandOpenerInner,
    ShorthandPart,
    GetSimpleShorthandOpener,
    ShorthandCloser,
} from '../shorthand-types';

import { SlashNode, createCssValueAST, valueTextNode } from '../../tokenizers';
import { codeToEvaluatedAst } from '../shorthands-ast-evaluation';
import { GridAxis, GridTemplates, Grids, GRID_KEYWORD_VALUE_MAP } from '../shorthand-css-data';
import {
    DataTypeType,
    PredicateIndexMatch,
    asteriskPredicate,
    findPredicateIndexMatch,
    seperatorPredicate,
    trackSizePredicate,
    gridAutoFlowPredicate,
    gridTemplateAreasDataType,
    gridTemplateRowsDataType,
    gridTemplateColumnsDataType,
    gridAutoFlowDataType,
    gridAutoRowsDataType,
    gridAutoColumnsDataType,
    gridSingleValueDataType,
    GRID_AUTO_FLOW_KEYWORD,
    GRID_AUTO_FLOW_ROW_KEYWORD,
    GRID_AUTO_FLOW_COLUMN_KEYWORD,
    GRID_AUTO_FLOW_DENSE_KEYWORD,
} from '../../css-data-types';
import {
    matchDataType,
    isOpenedInitial,
    singleKeywordShorthandOpener,
    createShorthandOpener,
    getOpenedNode,
    fixAstNodesPositions,
} from '../shorthand-parser-utils';
import {
    getGridTemplateShorthandParts,
    openGridTemplateShorthandSyntax,
    closeGridTemplateShorthand,
} from './grid-template-shorthand';
import { NoMandatoryPartMatchError, CannotCloseGridError } from '../shorthand-parser-errors';

interface AutoFlow {
    axis: GridAxis;
    dense: boolean;
}

type OpenedGrids<V> = OpenedShorthand<V, Grids>;
type GridSingleKeywordShorthandOpener<V> = SimpleShorthandOpenerInner<V, Grids>;
type GridShorthandCloser<V> = ShorthandCloser<V, Grids, OpenedGrids<V>>;

const getGridShorthandParts = <V>() =>
    [
        ...getGridTemplateShorthandParts<V>(),
        { prop: 'grid-auto-flow', dataType: gridAutoFlowDataType, multipleItems: true },
        { prop: 'grid-auto-rows', dataType: gridAutoRowsDataType, multipleItems: true },
        { prop: 'grid-auto-columns', dataType: gridAutoColumnsDataType, multipleItems: true },
    ] as ShorthandPart<V, Grids>[];

const isSeperator = <V>(astNodes: EvaluatedAst<V>[], index: number) => seperatorPredicate()(astNodes[index].value);

const getTrackSizeLength = <V>(astNodes: EvaluatedAst<V>[], index: number) =>
    astNodes.length > index ? Number(asteriskPredicate(trackSizePredicate)(astNodes[index].value, index, astNodes)) : 0;

const getAutoFlowText = (axis: GridAxis, dense = false) =>
    (axis === 'row' ? GRID_AUTO_FLOW_ROW_KEYWORD : GRID_AUTO_FLOW_COLUMN_KEYWORD) +
    (dense ? ` ${GRID_AUTO_FLOW_DENSE_KEYWORD}` : '');

const openGridShorthandAutoFlow = <V>(
    axis: GridAxis,
    astNodes: EvaluatedAst<V>[],
    autoFlowMatch: PredicateIndexMatch,
    trackSizeLength: number,
    rowsLength?: number
) => {
    const { index: autoFlowIndex, length: autoFlowLength } = autoFlowMatch;

    const opened: Partial<OpenedGrids<V>> = {};
    if (axis === 'row') {
        opened['grid-template-columns'] = astNodes.slice(autoFlowLength + trackSizeLength + 1);
        if (trackSizeLength > 0) {
            opened['grid-auto-rows'] = astNodes.slice(autoFlowLength, autoFlowLength + trackSizeLength);
        }
    } else {
        opened['grid-template-rows'] = astNodes.slice(0, rowsLength);
        if (trackSizeLength > 0) {
            opened['grid-auto-columns'] = astNodes.slice(autoFlowIndex + autoFlowLength);
        }
    }
    opened['grid-auto-flow'] = codeToEvaluatedAst(createCssValueAST(getAutoFlowText(axis, autoFlowLength === 2)));
    return opened;
};

const openGridShorthandTemplateRowsSyntax = <V>(astNodes: EvaluatedAst<V>[], autoFlowMatch: PredicateIndexMatch) => {
    const { index: autoFlowIndex, length: autoFlowLength } = autoFlowMatch;

    if (autoFlowIndex <= 1 || !isSeperator(astNodes, autoFlowIndex - 1)) {
        throw new NoMandatoryPartMatchError('grid', 'grid-auto-flow');
    }

    const rowsMatch = matchDataType(gridTemplateRowsDataType, astNodes, 0);
    if (!rowsMatch || Number(rowsMatch) !== autoFlowIndex - 1) {
        throw new NoMandatoryPartMatchError('grid', 'grid-template-rows');
    }

    const trackSizeLength = getTrackSizeLength(astNodes, autoFlowIndex + autoFlowLength);
    if (trackSizeLength !== astNodes.length - autoFlowIndex - autoFlowLength) {
        throw new NoMandatoryPartMatchError('grid', 'grid-auto-flow');
    }

    return openGridShorthandAutoFlow('column', astNodes, autoFlowMatch, trackSizeLength, Number(rowsMatch));
};

const openGridShorthandTemplateColumnsSyntax = <V>(astNodes: EvaluatedAst<V>[], autoFlowMatch: PredicateIndexMatch) => {
    const { length: autoFlowLength } = autoFlowMatch;

    const trackSizeLength = getTrackSizeLength(astNodes, autoFlowLength);
    if (!isSeperator(astNodes, autoFlowLength + trackSizeLength)) {
        throw new NoMandatoryPartMatchError('grid', 'grid-auto-flow');
    }

    const columnsMatch = matchDataType(
        gridTemplateColumnsDataType,
        astNodes,
        autoFlowLength + trackSizeLength,
        DataTypeType.GridTemplateRows
    );
    if (!columnsMatch || Number(columnsMatch) !== astNodes.length - autoFlowLength - trackSizeLength) {
        throw new NoMandatoryPartMatchError('grid', 'grid-template-columns');
    }

    return openGridShorthandAutoFlow('row', astNodes, autoFlowMatch, trackSizeLength);
};

// grid
export const openGridShorthand: GetSimpleShorthandOpener<Grids> = <V>() =>
    createShorthandOpener({
        prop: 'grid',
        singleKeywordPart: {
            prop: 'grid',
            dataType: gridSingleValueDataType,
            partOpener: singleKeywordShorthandOpener(
                GRID_KEYWORD_VALUE_MAP,
                true
            ) as GridSingleKeywordShorthandOpener<V>,
        },
        parts: getGridShorthandParts(),
        shorthandOpener: (astNodes, api, parts) => {
            const autoFlowMatch = findPredicateIndexMatch(astNodes, gridAutoFlowPredicate);

            return (
                autoFlowMatch
                    ? autoFlowMatch.index > 0
                        ? openGridShorthandTemplateRowsSyntax(astNodes, autoFlowMatch)
                        : openGridShorthandTemplateColumnsSyntax(astNodes, autoFlowMatch)
                    : openGridTemplateShorthandSyntax<V>()(astNodes, api, parts as ShorthandPart<V, GridTemplates>[])
            ) as OpenedGrids<V>;
        },
    }) as SimpleShorthandOpener<V, Grids>;

const getParsedOpenedAutoFlow = <V>(opened: OpenedGrids<V>): AutoFlow => {
    const openedAutoFlow = opened['grid-auto-flow'];
    const openedAutoArray = (Array.isArray(openedAutoFlow) ? openedAutoFlow : [openedAutoFlow]);
    if (!isOpenedInitial(opened, 'grid-template-areas', gridTemplateAreasDataType) || openedAutoArray.length < 1) {
        throw new CannotCloseGridError();
    }

    const parsedAutoFlow = openedAutoArray.reduce(
        (autoFlow, { value }) => {
            switch (value.text) {
                case GRID_AUTO_FLOW_ROW_KEYWORD:
                    if (autoFlow.axis === undefined) {
                        autoFlow.axis = 'row';
                        return autoFlow;
                    }
                    break;
                case GRID_AUTO_FLOW_COLUMN_KEYWORD:
                    if (autoFlow.axis === undefined) {
                        autoFlow.axis = 'column';
                        return autoFlow;
                    }
                    break;
                case GRID_AUTO_FLOW_DENSE_KEYWORD:
                    if (autoFlow.dense === undefined) {
                        autoFlow.dense = true;
                        return autoFlow;
                    }
                    break;
            }
            throw new CannotCloseGridError();
        },
        {} as Partial<AutoFlow>
    );
    if (parsedAutoFlow.axis === undefined && parsedAutoFlow.dense === undefined) {
        throw new CannotCloseGridError();
    }

    return { axis: parsedAutoFlow.axis ?? 'row', dense: !!parsedAutoFlow.dense };
};

const closeGridShorthandAutoFlowSyntax =
    <V>(autoFlow: AutoFlow, isAutoRowsInitial: boolean, isAutoColumnsInitial: boolean): GridShorthandCloser<V> =>
    (opened, api, detachExpression) => {
        const { axis, dense } = autoFlow;

        if (
            (axis === 'row' &&
                (!isOpenedInitial(opened, 'grid-template-rows', gridTemplateRowsDataType) || !isAutoColumnsInitial)) ||
            (axis === 'column' &&
                (!isOpenedInitial(opened, 'grid-template-columns', gridTemplateColumnsDataType) || !isAutoRowsInitial))
        ) {
            throw new CannotCloseGridError();
        }

        return [valueTextNode(GRID_AUTO_FLOW_KEYWORD) as CSSAstNode<V>]
            .concat(dense ? [valueTextNode(GRID_AUTO_FLOW_DENSE_KEYWORD)] : [])
            .concat(
                getOpenedNode(opened[axis === 'row' ? 'grid-auto-rows' : 'grid-auto-columns'], api, detachExpression)
            );
    };

export const closeGridShorthand =
    <V>(): GridShorthandCloser<V> =>
    (opened, api, detachExpression) => {
        const isAutoRowsInitial = isOpenedInitial(opened, 'grid-auto-rows', gridAutoRowsDataType);
        const isAutoColumnsInitial = isOpenedInitial(opened, 'grid-auto-columns', gridAutoColumnsDataType);
        if (
            isOpenedInitial(opened, 'grid-auto-flow', gridAutoFlowDataType) &&
            isAutoRowsInitial &&
            isAutoColumnsInitial
        ) {
            return closeGridTemplateShorthand<V>()(opened, api, detachExpression);
        }

        const autoFlow = getParsedOpenedAutoFlow(opened);

        const closedAutoFlowAst = closeGridShorthandAutoFlowSyntax<V>(
            autoFlow,
            isAutoRowsInitial,
            isAutoColumnsInitial
        )(opened, api, detachExpression);
        const closedTemplateAxisAst = getOpenedNode(
            opened[autoFlow.axis === 'row' ? 'grid-template-columns' : 'grid-template-rows'],
            api,
            detachExpression
        );
        const slashNode = { ...valueTextNode('/'), type: '/' } as SlashNode;
        return fixAstNodesPositions(
            autoFlow.axis === 'row'
                ? closedAutoFlowAst.concat(slashNode).concat(closedTemplateAxisAst)
                : closedTemplateAxisAst.concat(slashNode).concat(closedAutoFlowAst),
            api
        );
    };
