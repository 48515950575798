const cs = require('@wix/color-picker-langs/src/color-picker/color-picker_cs.json');
const da = require('@wix/color-picker-langs/src/color-picker/color-picker_da.json');
const de = require('@wix/color-picker-langs/src/color-picker/color-picker_de.json');
const en = require('@wix/color-picker-langs/src/color-picker/color-picker_en.json');
const es = require('@wix/color-picker-langs/src/color-picker/color-picker_es.json');
const fr = require('@wix/color-picker-langs/src/color-picker/color-picker_fr.json');
const hi = require('@wix/color-picker-langs/src/color-picker/color-picker_hi.json');
const id = require('@wix/color-picker-langs/src/color-picker/color-picker_id.json');
const it = require('@wix/color-picker-langs/src/color-picker/color-picker_it.json');
const ja = require('@wix/color-picker-langs/src/color-picker/color-picker_ja.json');
const ko = require('@wix/color-picker-langs/src/color-picker/color-picker_ko.json');
const nl = require('@wix/color-picker-langs/src/color-picker/color-picker_nl.json');
const no = require('@wix/color-picker-langs/src/color-picker/color-picker_no.json');
const pl = require('@wix/color-picker-langs/src/color-picker/color-picker_pl.json');
const pt = require('@wix/color-picker-langs/src/color-picker/color-picker_pt.json');
const ru = require('@wix/color-picker-langs/src/color-picker/color-picker_ru.json');
const sv = require('@wix/color-picker-langs/src/color-picker/color-picker_sv.json');
const th = require('@wix/color-picker-langs/src/color-picker/color-picker_th.json');
const tr = require('@wix/color-picker-langs/src/color-picker/color-picker_tr.json');
const uk = require('@wix/color-picker-langs/src/color-picker/color-picker_uk.json');
const vi = require('@wix/color-picker-langs/src/color-picker/color-picker_vi.json');
const zh = require('@wix/color-picker-langs/src/color-picker/color-picker_zh.json');

export const langs: Record<string, Record<string, string>> = {
  cs,
  da,
  de,
  en,
  es,
  fr,
  hi,
  id,
  it,
  ja,
  ko,
  nl,
  no,
  pl,
  pt,
  ru,
  sv,
  th,
  tr,
  uk,
  vi,
  zh,
};
