import { KEYBOARD_QUICK_CHANGE, MOUSE_QUICK_CHANGE, StylePanelPlane } from '@wix/stylable-panel-common';
import { BIParams, getTranslate, PanelEventList, reportBI, VisualizerType } from '@wix/stylable-panel-controllers';
import {
    ArchetypeList,
    BlockVariantSet,
    ComponentEditSession,
    DeclarationMap,
    getFinalStateName,
    stripStates,
    StylablePanelTranslationKeys,
} from '@wix/stylable-panel-drivers';
import keycode from 'keycode';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ResetStateOverridesDriver } from '../drivers/reset-state-overrides';
import { ConfigurablePanel, ConfigurablePanelContext, CustomizationPanel, StylePanel, StylePanelPage } from '../panels';
import StateListDialog, { StateListProps } from '../panels/state-list-dialog/state-list-dialog';
import { setupArchetypeList, setupCategoryConfig, unifiedControllersSetup } from '../panels/customization-panel/setup';
import type { CategoryConfig, StylablePanelHost, StylablePanelInternalHost } from '../types';
import { classes, style } from './stylable/editor-panel.st.css';

// TODO pass these as context rather than props:
const staticArchetypeList = setupArchetypeList();
const staticCategoryConfig = setupCategoryConfig();
const customizationPanelControllers = unifiedControllersSetup();

export interface EditorPanelEvents {
    onElementSelect?: (selector: string) => void;
    onForceState?: (stateOverrideSelector: string | null) => void;
    onStateSelect?: (stateSelector: string) => void;
    onRemoveOverrides?: () => void;
    onPageChange?: (page: StylePanelPage) => void;
}

export interface StylePanelView {
    page?: StylePanelPage;
    section?: string;
    selector?: string;
}

/* @deprecated */
interface StylePanelPropdOldConfig {
    archetypeList?: ArchetypeList;
    categoryConfig?: CategoryConfig;
    controllers?: Record<string, VisualizerType>;
    initialView?: StylePanelView;
}

export interface StylePanelExternalProps extends StylePanelPropdOldConfig {
    panelHost?: StylablePanelHost;
    plane?: StylePanelPlane;
    customPanel?: FC;
}

export interface EditorPanelProps extends EditorPanelEvents, StylePanelExternalProps {
    editSession: ComponentEditSession;
    className?: string;
}

const useMountEffect = (cb: () => void) => {
    const willMount = useRef(true);

    if (willMount.current) cb();

    willMount.current = false;
};

export function useForceUpdate() {
    const [, forceUpdate] = useState(false);
    return () => forceUpdate((isUpdated) => !isUpdated);
}

export const EditorPanel = ({
    editSession,
    panelHost,
    controllers,
    categoryConfig,
    archetypeList,
    initialView,
    onElementSelect,
    onStateSelect,
    onForceState,
    onRemoveOverrides,
    onPageChange,
    plane,
    className,
    customPanel,
}: EditorPanelProps) => {
    const forceUpdate = useForceUpdate();
    const translate = getTranslate(panelHost);
    const [userBlockVariants, setUserBlockVariants] = useState<Record<string, BlockVariantSet>>({});

    const clearStateStyleOverrides = useCallback(
        (selector: string) => {
            editSession.changeDriver.changeSelectors({
                selector,
                values: undefined,
                revertible: false,
            });
        },
        [editSession.changeDriver]
    );

    const getStates = useCallback(
        (selector: string) => {
            const {
                selectorConfiguration,
                computed: { states },
            } = editSession;

            const statesOrderSet = new Set<string>(selectorConfiguration.getStatesOrder(selector));
            states.forEach((state) => statesOrderSet.add(state));
            return [...statesOrderSet].filter(
                (state) => states.includes(state) && !selectorConfiguration.isStateHidden(selector, state)
            );
        },
        [editSession]
    );

    const handleStateOverrides = useCallback(
        (shouldForceUpate = true) => {
            resetStateOverridesDriver.current.handleStateOverrides(panelHost?.customTranslations);
            if (shouldForceUpate) {
                forceUpdate();
            }
        },
        [forceUpdate, panelHost?.customTranslations]
    );

    const openStateOverridesDialog = useCallback(
        (fixedStates: string[]) => {
            const { selector, selectorConfiguration } = editSession;

            const customTranslations = panelHost?.customTranslations?.dialog?.stateOverrides;
            const { title, applyButtonLabel } = StylablePanelTranslationKeys.dialog.stateOverrides;

            const statesListDialogProps: StateListProps = {
                title: customTranslations?.title || translate(title),
                applyLabel: customTranslations?.applyButtonLabel || translate(applyButtonLabel),
                onSubmit: (stateList: string[]) =>
                    resetStateOverridesDriver.current.onStateListSubmit(selector, stateList),
                panelHost,
                selector,
                usedStates: getStates(selector),
                fixedStates,
                selectorConfiguration,
            };

            panelHost?.onOpenPanel && panelHost.onOpenPanel(StateListDialog.panelName, statesListDialogProps);
        },
        [editSession, getStates, panelHost, translate]
    );

    const internalReportBI = useCallback(
        (event: PanelEventList, reportedParams?: BIParams) => {
            const { selector, variant } = editSession;
            const part = editSession.getSelectorWithoutStates().replace(`.${variant}`, 'root');
            const finalState = getFinalStateName(selector);
            const state = finalState ? finalState : 'regular';
            const params = Object.assign({}, reportedParams, { part, state });
            reportBI(event, params, panelHost);
        },
        [editSession, panelHost]
    );

    const onElementSelectMiddleware = useCallback(
        (selector: string) => {
            onElementSelect && onElementSelect(selector);
            internalReportBI(PanelEventList.CLICK_ON_ELEMENT_IN_TREE);
        },
        [onElementSelect, internalReportBI]
    );

    const onStateSelectMiddleware = useCallback(
        (selector: string) => {
            onStateSelect && onStateSelect(selector);
            internalReportBI(PanelEventList.STATE_CLICK);
        },
        [onStateSelect, internalReportBI]
    );

    const createResetStateOverridesDriverInstance = useMemo(
        () => new ResetStateOverridesDriver(editSession, clearStateStyleOverrides, openStateOverridesDialog, translate),
        [clearStateStyleOverrides, editSession, openStateOverridesDialog, translate]
    );

    const resetStateOverridesDriver = useRef<ResetStateOverridesDriver>(createResetStateOverridesDriverInstance);

    useMountEffect(() => {
        editSession.changeDriver.setPostChangeHook(handleStateOverrides);

        document.addEventListener('mousedown', () => editSession.changeDriver.startBlockingCommits(MOUSE_QUICK_CHANGE));
        document.addEventListener('mouseup', () =>
            editSession.changeDriver.releaseBlockingCommits(true, MOUSE_QUICK_CHANGE)
        );
        document.addEventListener('keydown', (event) => keydown(event, KEYBOARD_QUICK_CHANGE));
        document.addEventListener('keyup', (event) => keyup(event, KEYBOARD_QUICK_CHANGE));

        return () => editSession.changeDriver.setPostChangeHook(undefined);
    });

    useEffect(() => {
        if (initialView) {
            const { page, selector } = initialView;

            if (page !== undefined) {
                editSession.page = page;
            }

            if (selector !== undefined) {
                const variantSelector = editSession.selectorConfiguration.selectorToVariant(selector);
                onElementSelectMiddleware?.(stripStates(variantSelector));
                onStateSelectMiddleware?.(variantSelector);
            }
        }
    }, [editSession, initialView, onElementSelectMiddleware, onStateSelectMiddleware]);

    useEffect(() => {
        resetStateOverridesDriver.current = createResetStateOverridesDriverInstance;
        handleStateOverrides(false);
    }, [createResetStateOverridesDriverInstance, handleStateOverrides]);

    useEffect(() => {
        editSession.changeDriver.setPostChangeHook(handleStateOverrides);
    }, [editSession.changeDriver, handleStateOverrides]);

    useEffect(() => {
        return function cleanup() {
            onRemoveOverrides?.();
        };
    }, [onRemoveOverrides]);

    const fixPanelHost = (panelHost?: StylablePanelInternalHost): StylablePanelInternalHost => {
        const { changeDriver } = editSession;

        const internalHost: StylablePanelInternalHost = {
            onRevertQuickChange: changeDriver.onRevertQuickChange,
            blockCommits: changeDriver.startBlockingCommits,
            unblockCommits: changeDriver.releaseBlockingCommits,
        };

        return { ...internalHost, ...(panelHost || {}) };
    };

    const getArchetypeList = () => archetypeList || staticArchetypeList;

    const getCategoryConfig = () => categoryConfig || staticCategoryConfig;

    const getCustomizationPanelControllers = () => controllers || customizationPanelControllers;

    const onBackButtonClick = () => {
        onPageChange && onPageChange(StylePanelPage.PresetPanel);
    };

    const onDeleteBlockVariant = (category: string, index: number) => {
        const newUserBlockVariants = { ...userBlockVariants };
        if (!newUserBlockVariants[category] || newUserBlockVariants[category].length <= index) {
            // nothing to delete
            return;
        }

        newUserBlockVariants[category].splice(index, 1);
        setUserBlockVariants(newUserBlockVariants);
    };

    const onSaveBlockVariant = (category: string, variant: DeclarationMap) => {
        const newUserBlockVariants = { ...userBlockVariants };
        if (!newUserBlockVariants[category]) {
            newUserBlockVariants[category] = [];
        }
        newUserBlockVariants[category].push(variant);
        setUserBlockVariants(newUserBlockVariants);
    };

    const keydown = (e: KeyboardEvent, id: string) => {
        if (e.keyCode === keycode('up') || e.keyCode === keycode('down')) {
            editSession.changeDriver.startBlockingCommits(id);
        }
    };

    const keyup = (e: KeyboardEvent, id: string) => {
        if (e.keyCode === keycode('up') || e.keyCode === keycode('down')) {
            editSession.changeDriver.releaseBlockingCommits(true, id);
        }
    };

    if (customPanel) {
        const configurablePanelContext: ConfigurablePanelContext = {
            panelHost: fixPanelHost(panelHost),
            sheetPath: editSession.stylesheetPath,
            activeTargetSelector: editSession.selector,
            selectedElement: editSession.selectedElement,
            siteSheetPath: editSession.siteStylesheetPath,
            panelLayoutConfiguration: {
                plane: plane || StylePanelPlane.Vertical,
            },
            stylableDriver: editSession.stylableDriver,
            aggregationPaths: editSession.aggregationPaths,
            onForceState,
            onElementSelect: onElementSelectMiddleware,
            onStateSelect: onStateSelectMiddleware,
            changeRuleDeclarations: editSession.changeDriver.changeSelectors,
            stateOverridesConfig: resetStateOverridesDriver.current.stateOverridesConfig,
        };
        return (
            <div className={style(classes.root, className)}>
                <ConfigurablePanel
                    className={classes.customizationPanel}
                    CustomPanel={customPanel}
                    context={configurablePanelContext}
                    isCustomCssEditingActive={panelHost?.isCustomCssEditingActive}
                />
            </div>
        );
    } else {
        return (
            <div className={style(classes.root, className)}>
                <header className={classes.header}>
                    <nav className={classes.headerLefNav}>
                        <span onClick={onBackButtonClick} className={style(classes.navButton, classes.backButton)} />
                    </nav>
                    <div className={classes.headerTitle}>{editSession.componentName} Design</div>
                    <nav className={classes.headerRightNav}>
                        <span className={style(classes.navButton, classes.helpButton)} />
                        <span className={style(classes.navButton, classes.closeButton)} />
                    </nav>
                </header>
                <StylePanel
                    className={classes.stylePanel}
                    page={editSession.page}
                    panelHost={fixPanelHost(panelHost)}
                    onPageChange={onPageChange}
                >
                    <CustomizationPanel
                        className={classes.customizationPanel}
                        page={StylePanelPage.CustomizationPanel}
                        selector={editSession.selectedElement}
                        stateSelector={editSession.selector}
                        elements={editSession.elements}
                        controllerPartTypes={editSession.controllerPartTypes}
                        selectorConfiguration={editSession.selectorConfiguration}
                        states={getStates(editSession.getSelectorWithoutStates())}
                        siteSheetPath={editSession.siteStylesheetPath}
                        sheetPath={editSession.stylesheetPath}
                        aggregationPaths={editSession.aggregationPaths}
                        stylableDriver={editSession.stylableDriver}
                        archetypeList={getArchetypeList()}
                        categoryConfiguration={getCategoryConfig()}
                        controllers={getCustomizationPanelControllers()}
                        panelHost={fixPanelHost(panelHost)}
                        onForceState={onForceState}
                        onElementSelect={onElementSelectMiddleware}
                        onStateSelect={onStateSelectMiddleware}
                        onSaveBlockVariant={onSaveBlockVariant}
                        onDeleteBlockVariant={onDeleteBlockVariant}
                        changeRuleDeclarations={editSession.changeDriver.changeSelectors}
                        revertRule={editSession.changeDriver.revertRule}
                        userBlockVariants={userBlockVariants}
                        plane={plane}
                        initialView={initialView}
                        reportBI={(event, params) => internalReportBI(event, params)}
                        handleStateOverrides={handleStateOverrides}
                        stateOverridesConfig={resetStateOverridesDriver.current.stateOverridesConfig}
                    />
                </StylePanel>
            </div>
        );
    }
};
