import { IconProps, SmallDownArrow } from '@wix/stylable-panel-common-react';
import { Button, Icon, Tooltip } from '@wix/stylable-panel-components';
import React, { forwardRef } from 'react';
import type { TranslateFunc } from '../../types';
import { classes, style } from './popup-icon.st.css';

interface PopupIconProps {
    iconKey: string;
    MainIcon: React.FC;
    openPopup: () => void;
    shouldOpenPopup: boolean;
    tooltipKey: string;
    translate: TranslateFunc;
    children?: React.ReactNode;
    className?: string;
    SelectedIcon?: React.ComponentType<IconProps>;
}

export const PopupIcon = forwardRef(
    (
        {
            children,
            className,
            iconKey,
            MainIcon,
            openPopup,
            SelectedIcon,
            shouldOpenPopup,
            tooltipKey,
            translate,
        }: PopupIconProps,
        popup: React.ForwardedRef<HTMLDivElement>
    ) => {
        const InnerIcon = SelectedIcon || MainIcon;

        const IconButton = () => (
            <Tooltip key={`popup_${iconKey}`} className={classes.iconTooltip} text={translate(tooltipKey)}>
                <Button
                    className={classes.popupIconButton}
                    onClick={openPopup}
                    isChecked={shouldOpenPopup}
                    isToggleButton
                >
                    <span className={classes.innerContent}>
                        <Icon>
                            <InnerIcon className={classes.innerIcon} />
                        </Icon>
                        <Icon>
                            <SmallDownArrow className={classes.downArrow} />
                        </Icon>
                    </span>
                </Button>
            </Tooltip>
        );

        return (
            <div className={style(classes.root, className)}>
                {shouldOpenPopup ? (
                    <div className={classes.popupWrapper}>
                        {IconButton()}
                        <div key="popup" className={classes.popup} ref={popup}>
                            {children}
                        </div>
                    </div>
                ) : (
                    IconButton()
                )}
            </div>
        );
    }
);
