import {  FLEX_CHILD_SPACING, getDimensionInputDefaults } from '@wix/stylable-panel-common';
import { CompositeBlock, OptimisticDimensionInput } from '@wix/stylable-panel-components';
import { getTranslate } from '@wix/stylable-panel-controllers';
import type { SelectorSet } from '@wix/stylable-panel-drivers';
import React from 'react';
import type { DynamicPartInputProps } from '../types';
import { classes, style } from './flex-child-spacing.st.css';

export const PARAM_FIRST = 'first';
export const PARAM_LAST = 'last';
export const spacingTitleKey = 'spacing_title_key';

export interface FlexChildSpacingProps extends DynamicPartInputProps {
    maintainExisting?: boolean;
}



// TODO: React to direction and justify changes
const FlexChildSpacing: React.FC<FlexChildSpacingProps> = ({
    className,
    controllerData,
    onChangeSelector,
    panelHost,
    values,
    maintainExisting = false,
    value: { 'flex-direction': direction = 'row' },
}) => {
    const { value: defaultValue, unit: defaultUnit } = getDimensionInputDefaults(panelHost).flexChildSpacing.spaceBetweenTextAndIcon;
    const isVertical = () => direction === 'column' || direction === 'column-reverse';

    const isReverse = () => direction === 'row-reverse' || direction === 'column-reverse';

    const elementMargins = () =>
        Object.keys(values).map((selector) => {
            const {
                decls: {
                    'margin-top': top = '0',
                    'margin-bottom': bottom = '0',
                    'margin-left': left = '0',
                    'margin-right': right = '0',
                },
            } = values[selector];
            return { top, bottom, left, right };
        });

    const getSliderValue = () => {
        const selectors = Object.keys(values);
        const isChildVertical = isVertical();
        const isChildReverse = isReverse();
        const childMargins = elementMargins();
        const aggregatedMargin = childMargins.reduce((currAggregatedMargin, { top, bottom, left, right }, index) => {
            const { params } = values[selectors[index]];
            return (
                currAggregatedMargin +
                (params === (isChildReverse ? PARAM_LAST : PARAM_FIRST)
                    ? defaultValue
                    : parseFloat(isChildVertical ? top : left)) +
                (params === (isChildReverse ? PARAM_FIRST : PARAM_LAST)
                    ? defaultValue
                    : parseFloat(isChildVertical ? bottom : right))
            );
        }, 0);
        return `${(2 * aggregatedMargin) / childMargins.length}`;
    };

    const handleSliderChange = (value?: string) => {
        if (!value || !onChangeSelector) {
            return;
        }
        const isChildVertical = isVertical();
        const isChildReverse = isReverse();
        const childMargins = elementMargins();
        const changeSet = Object.keys(values).reduce((currChangeSet, selector, index) => {
            const { params } = values[selector];
            const margins = childMargins[index];
            const spacing = parseInt(value, 10) / 2;
            const top = isChildVertical
                ? params === (isChildReverse ? PARAM_LAST : PARAM_FIRST)
                    ? '0'
                    : spacing
                : maintainExisting
                ? parseInt(margins.top, 10)
                : '0';
            const bottom = isChildVertical
                ? params === (isChildReverse ? PARAM_FIRST : PARAM_LAST)
                    ? '0'
                    : spacing
                : maintainExisting
                ? parseInt(margins.bottom, 10)
                : '0';
            const left = !isChildVertical
                ? params === (isChildReverse ? PARAM_LAST : PARAM_FIRST)
                    ? '0'
                    : spacing
                : maintainExisting
                ? parseInt(margins.left, 10)
                : '0';
            const right = !isChildVertical
                ? params === (isChildReverse ? PARAM_FIRST : PARAM_LAST)
                    ? '0'
                    : spacing
                : maintainExisting
                ? parseInt(margins.right, 10)
                : '0';
            currChangeSet[selector] = {
                margin: `${top}px ${right}px ${bottom}px ${left}px`,
            };
            return currChangeSet;
        }, {} as SelectorSet);
        onChangeSelector(changeSet);
    };

    const getTitle = () => {
        const translate = getTranslate(panelHost);
        const translationKeys = controllerData?.translationKeys || {};
        return translationKeys[spacingTitleKey] ? translate(translationKeys[spacingTitleKey]) : 'Spacing';
    };

    return (
        <div className={style(classes.root, className)}>
            <CompositeBlock className={classes.controllerBlock} title={getTitle()}>
                <OptimisticDimensionInput
                    className={classes.inputElement}
                    config={{ units: FLEX_CHILD_SPACING }}
                    onChange={handleSliderChange}
                    value={getSliderValue() + defaultUnit}
                    isSlider
                />
            </CompositeBlock>
        </div>
    );
};

export default FlexChildSpacing;
