import type * as postcss from 'postcss';

export interface EditorNotification {
    title: string; // Translation key
    message: string; // Translation key
    type: 'warning' | 'error' | 'info' | 'success' | 'tracking';
    link?: {
        caption?: string; // Translation key
        onNotificationLinkClick?: () => void;
    };
}

export type PaletteColumn = string[];

export interface Palette {
    columns: PaletteColumn[];
    previewIndices: number[];
}

export enum StateOverridesStates {
    REGULAR_VIEW_DISABLED_NO_VALID_TARGETS = 'REGULAR_VIEW_DISABLED_NO_VALID_TARGETS',
    REGULAR_VIEW_ENABLED_SINGLE_VALID_TARGET = 'REGULAR_VIEW_ENABLED_SINGLE_VALID_TARGET',
    REGULAR_VIEW_ENABLED_MULTIPLE_VALID_TARGETS = 'REGULAR_VIEW_ENABLED_MULTIPLE_VALID_TARGETS',
    STATE_VIEW_DISABLED_NO_VALID_TARGETS = 'STATE_VIEW_DISABLED_NO_VALID_TARGETS',
    STATE_VIEW_ENABLED_CURRENT_STATE_IS_VALID_TARGET = 'STATE_VIEW_ENABLED_CURRENT_STATE_IS_VALID_TARGET',
    ERROR = 'ERROR',
}

export interface OverridesMap {
    selector: string;
    rules: postcss.Rule[];
    state: StateOverridesStates;
    langKey: string;
}

export interface StateOverridesConfig {
    action?: StateOverridesStates;
    content?: string;
    callback?: () => void;
    disabled?: boolean;
}
