import type { FullDeclarationMap } from '@wix/stylable-panel-drivers';

const DEFAULT_BACKGROUND_POSITION = '50% 50%';

export function stringifyBackgroundImage(value?: string | FullDeclarationMap) {
    if (!value) {
        return undefined;
    }

    if (typeof value === 'string') {
        return value;
    }

    let positionSizeString = '';
    if (value['background-position'] !== undefined || value['background-size'] !== undefined) {
        positionSizeString = value['background-position'] || DEFAULT_BACKGROUND_POSITION;
        if (positionSizeString === '0%') {
            positionSizeString = '0% 0%';
        }
        let sizeString = value['background-size'];
        if (sizeString) {
            if (sizeString === 'auto') {
                sizeString = 'auto auto';
            }
            positionSizeString += `/${sizeString}`;
        }
    }

    let originClipString = '';
    if (value['background-origin'] !== undefined || value['background-clip'] !== undefined) {
        originClipString = `${value['background-origin'] || 'padding-box'}`;
        if (value['background-origin'] !== value['background-clip']) {
            originClipString += ` ${value['background-clip'] || 'border-box'}`;
        }
    }

    let stringifiedBackground = '';
    let notFirst = false;
    if (value['background-repeat']) {
        stringifiedBackground += value['background-repeat'];
        notFirst = true;
    }
    if (originClipString) {
        stringifiedBackground += `${notFirst ? ' ' : ''}${originClipString}`;
        notFirst = true;
    }
    if (positionSizeString) {
        stringifiedBackground += `${notFirst ? ' ' : ''}${positionSizeString}`;
        notFirst = true;
    }
    if (value['background-attachment']) {
        stringifiedBackground += `${notFirst ? ' ' : ''}${value['background-attachment']}`;
        notFirst = true;
    }
    if (value['background-image']) {
        stringifiedBackground += `${notFirst ? ' ' : ''}${value['background-image']}`;
    }

    return stringifiedBackground;
}
