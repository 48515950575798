import React from 'react';
import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import { WarningBanner } from '@wix/stylable-panel-components';
import { getTranslate } from '@wix/stylable-panel-controllers';

import type { StylablePanelHost } from '../../types';
import { style, classes } from './style-panel.st.css';

export enum StylePanelPage {
    PresetPanel = 0,
    CustomizationPanel,
}

export interface StylePanelChildProps {
    page?: StylePanelPage;
    className?: string;
}

export interface StylePanelProps {
    page: StylePanelPage;
    panelHost?: StylablePanelHost;
    onPageChange?: (page: StylePanelPage) => void;
    className?: string;
    children?: React.ReactNode;
}

/**
 * @deprecated
 */
export class StylePanel extends React.Component<StylePanelProps> {
    public render() {
        const { page, className, panelHost } = this.props;

        const classNames = [`presetPanelWrapper`, `customizationPanelWrapper`];
        const translate = getTranslate(panelHost);

        return (
            <div className={style(classes.root, className)}>
                {panelHost?.isCustomCssEditingActive && <WarningBanner text={translate(StylablePanelTranslationKeys.elementree.warningBanner)} />}
                 <div className={style(classes.panelContent, classNames[page])}>{this.getPanelContent()}</div>
                {page === 0 && <div className={style(classes.panelFooter, { page })}>{this.getPanelFooter()}</div>}
            </div>
        );
    }

    private getPanelButton(label: string, pageId: number, action?: string, callback?: any) {
        const onClick = () => (typeof callback === 'function' ? callback() : this.setPage(pageId));
        return (
            <span className={classes.panelButton} onClick={onClick}>
                <span className={style(classes.panelButtonLabel, action)}>{label}</span>
            </span>
        );
    }

    private findPageChild(page: StylePanelPage) {
        return React.Children.toArray(this.props.children).find(
            (child: React.ReactNode) =>
                !!child && React.isValidElement<StylePanelChildProps>(child) && child.props.page === page
        );
    }

    private getPanelContent() {
        switch (this.props.page) {
            case StylePanelPage.PresetPanel:
                return this.findPageChild(StylePanelPage.PresetPanel);
            case StylePanelPage.CustomizationPanel:
                return this.findPageChild(StylePanelPage.CustomizationPanel);
        }
        return false;
    }

    private getPanelFooter() {
        switch (this.props.page) {
            case StylePanelPage.PresetPanel:
                return this.getPresetFooter();
        }
        return false;
    }

    private getPresetFooter() {
        const translate = getTranslate(this.props.panelHost);
        return (
            <span className={classes.panelControls}>
                {this.getPanelButton(
                    translate(StylablePanelTranslationKeys.customizeDesign),
                    StylePanelPage.CustomizationPanel,
                    'next'
                )}
            </span>
        );
    }

    private setPage(page: StylePanelPage) {
        const { onPageChange } = this.props;

        onPageChange && onPageChange(page);
    }
}
