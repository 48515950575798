import type { BorderRadiuses, OpenedBorderRadiusShorthand } from '../shorthand-css-data';
import type {
    CSSAstNode,
    EvaluatedAst,
    OpenedShorthand,
    SimpleOpenedShorthand,
    ShorthandOpener,
    ShorthandCloser,
} from '../shorthand-types';

import { ALWAYS_DATA_TYPE, INTERNAL_VALUE_SEPERATOR } from '../../css-data-types';
import { valueTextNode } from '../../tokenizers';
import {
    edgesShorthandOpener,
    getShorthandLayers,
    getOpenedLayer,
    createShorthandOpener,
    edgesShorthandCloser,
    fixAstNodesPositions,
} from '../shorthand-parser-utils';

// border-radius
export const openBorderRadiusShorthand = <V>(): ShorthandOpener<V, BorderRadiuses, OpenedBorderRadiusShorthand<V>> => {
    const singleBorderRadiusShorthandOpenerInner = edgesShorthandOpener<V, BorderRadiuses>('border-radius', true);

    const borderRadiusShorthandOpener = createShorthandOpener<V, BorderRadiuses>({
        prop: 'border-radius',
        parts: [
            {
                prop: 'border-radius',
                dataType: ALWAYS_DATA_TYPE,
                openedProps: [
                    'border-top-left-radius',
                    'border-top-right-radius',
                    'border-bottom-right-radius',
                    'border-bottom-left-radius',
                ],
            },
        ],
        shorthandOpener: (astNodes, api) => {
            const [firstLayer, secondLayer] = getShorthandLayers(astNodes, INTERNAL_VALUE_SEPERATOR);
            const firstOpened = singleBorderRadiusShorthandOpenerInner(firstLayer, api);
            const secondOpened =
                secondLayer && secondLayer.length > 0
                    ? singleBorderRadiusShorthandOpenerInner(secondLayer, api)
                    : undefined;

            const opened: OpenedShorthand<V> = {};
            const props = Object.keys(firstOpened) as BorderRadiuses[];
            for (const prop of props) {
                opened[prop] = [firstOpened[prop]];
                if (secondOpened) {
                    (opened[prop] as EvaluatedAst<V>[]).push(secondOpened[prop]);
                }
            }
            return opened;
        },
    });

    return (shortHand, api) => {
        const openedShorthand = borderRadiusShorthandOpener(shortHand, api);

        const layers: SimpleOpenedShorthand<V, BorderRadiuses>[] = [];
        let currLayer: SimpleOpenedShorthand<V, BorderRadiuses> | undefined;
        for (
            let i = 0;
            (currLayer = getOpenedLayer(openedShorthand, i) as SimpleOpenedShorthand<V, BorderRadiuses> | undefined) !==
            undefined;
            i++
        ) {
            layers.push(currLayer);
        }

        return layers;
    };
};

export const closeBorderRadiusShorthand =
    <V>(): ShorthandCloser<V, BorderRadiuses, SimpleOpenedShorthand<V, BorderRadiuses>[]> =>
    (opened, api, detachExpression) => {
        if (!opened[0]) {
            return [];
        }
        const borderRadiusLayerCloser = edgesShorthandCloser<V, BorderRadiuses>('border-radius', true);
        let closedLayers: CSSAstNode<V>[] = [];
        closedLayers = closedLayers.concat(borderRadiusLayerCloser(opened[0], api, detachExpression));
        if (opened[1]) {
            closedLayers = closedLayers.concat(
                valueTextNode(INTERNAL_VALUE_SEPERATOR),
                borderRadiusLayerCloser(opened[1], api, detachExpression)
            );
        }
        return fixAstNodesPositions(closedLayers, api);
    };
