import React from 'react';
import {classes} from './warningBanner.st.css';

type WarningProps = {
text: string;
}

export const WarningBanner: React.FC<WarningProps> = ({text}) => {
    return (
    <div className={classes.warningContainer}>
        <span className={classes.warningText}>
         {text}
        </span>
    </div>)

}