import type { ListStyles } from '../shorthand-css-data';
import type { SimpleShorthandOpener, GetSimpleShorthandOpener, GetShorthandCloser } from '../shorthand-types';

import {
    imageSourceDataType,
    listStyleSingleValueDataType,
    listStylePositionDataType,
    listStyleTypeDataType,
} from '../../css-data-types';
import {
    unorderedListShorthandOpener,
    createShorthandOpener,
    shorthandCloserTemplate,
    createShorthandCloser,
} from '../shorthand-parser-utils';

// list-style
export const openListStyleShorthand: GetSimpleShorthandOpener<ListStyles> = <V>() =>
    createShorthandOpener({
        prop: 'list-style',
        singleKeywordPart: { prop: 'list-style', dataType: listStyleSingleValueDataType },
        parts: [
            { prop: 'list-style-type', dataType: listStyleTypeDataType },
            { prop: 'list-style-image', dataType: imageSourceDataType },
            { prop: 'list-style-position', dataType: listStylePositionDataType },
        ],
        shorthandOpener: (astNodes, api, parts) => unorderedListShorthandOpener(parts)(astNodes, api),
    }) as SimpleShorthandOpener<V, ListStyles>;

export const closeListStyleShorthand: GetShorthandCloser<ListStyles> = <V>() =>
    createShorthandCloser<V, ListStyles>(
        shorthandCloserTemplate<ListStyles>`${'list-style-type'} ${'list-style-image'} ${'list-style-position'}`
    );
