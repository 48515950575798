import React, { useEffect, useState } from 'react';

interface InvokeBeforeFirstRenderProps {
    children: React.ReactElement | React.ReactElement[];
    callback?: Function;
}

export const InvokeBeforeFirstRender: React.FC<InvokeBeforeFirstRenderProps> = ({ callback, children }) => {
    const [rendered, setRendered] = useState(false);

    useEffect(() => {
        if (!rendered) {
            callback?.();
            setRendered(true);
        }
    }, [callback, rendered]);

    return rendered ? <>{children}</> : null;
};
