import type { BorderRadiuses } from '@wix/shorthands-opener';
import type { CornerName, CornerProps } from './corner-visualizer-types';

export function getCornerProp(corner: CornerName): BorderRadiuses {
    switch (corner) {
        case 'topLeft':
            return 'border-top-left-radius';
        case 'topRight':
            return 'border-top-right-radius';
        case 'bottomRight':
            return 'border-bottom-right-radius';
        case 'bottomLeft':
            return 'border-bottom-left-radius';
    }
}

export function getCornerTitle(corner: CornerName) {
    switch (corner) {
        case 'topLeft':
            return 'Top Left';
        case 'topRight':
            return 'Top Right';
        case 'bottomRight':
            return 'Bottom Right';
        case 'bottomLeft':
            return 'Bottom Left';
    }
}

export const CORNER_PROPS_LIST: CornerProps[] = [
    'border-radius',
    'border-top-left-radius',
    'border-top-right-radius',
    'border-bottom-right-radius',
    'border-bottom-left-radius',
];
