import { DEFAULT_PLANE } from '@wix/stylable-panel-common';
import { CompositeBlock, DropDown } from '@wix/stylable-panel-components';
import { getTranslate } from '@wix/stylable-panel-controllers';
import type { DynamicPartInputSelectorValues, SelectorSet } from '@wix/stylable-panel-drivers';
import React from 'react';
import type { DynamicPartInputProps } from '../types';
import { classes, style } from './child-display-dropdown.st.css';

export const DISPLAY_HIDDEN = 'none';
export const DISPLAY_SHOWN = 'initial';

export const OPTION_ID_ALL = 'all';
export const OPTION_ID_ONLY_PREFIX = 'only_';

const getElementDisplays = (values: DynamicPartInputSelectorValues) =>
    Object.keys(values).map((selector) => values[selector].decls.display !== DISPLAY_HIDDEN);

const allAreDisplayed = (displays: boolean[]) => displays.every((display) => !!display);

export const AllElementsDisplayed = (values: DynamicPartInputSelectorValues) =>
    Object.keys(values).length > 1 && allAreDisplayed(getElementDisplays(values));

export const displayTitleKey = 'display_title_key';
export const displayOptionKey = (option: string) => `display_option_${option}_key`;

const ChildDisplayDropdown: React.FC<DynamicPartInputProps> = ({
    values,
    controllerData,
    panelHost,
    onChangeSelector,
    plane = DEFAULT_PLANE,
    className,
}) => {
    const translate = getTranslate(panelHost);

    const getDropDownValue = () => {
        const elementDisplays = getElementDisplays(values);

        if (allAreDisplayed(elementDisplays)) {
            return OPTION_ID_ALL;
        }

        const firstDisplayedElementIndex = elementDisplays.findIndex((display) => !!display);
        if (!~firstDisplayedElementIndex) {
            return '';
        }

        return `${OPTION_ID_ONLY_PREFIX}${firstDisplayedElementIndex}`;
    };

    const getTranslationKeys = () => controllerData?.translationKeys || {};

    const getDropDownOptions = () => {
        const elementNames = getElementNames();
        const translationKeys = getTranslationKeys();

        return [
            {
                id: OPTION_ID_ALL,
                displayName: translationKeys[displayOptionKey('all')]
                    ? translate(translationKeys[displayOptionKey('all')])
                    : elementNames.join(' & '),
            },
            ...elementNames.map((name, index) => ({
                id: `${OPTION_ID_ONLY_PREFIX}${index}`,
                displayName: translationKeys[displayOptionKey(name)]
                    ? translate(translationKeys[displayOptionKey(name)])
                    : `${name} Only`,
            })),
        ];
    };

    const handleDropDownSelect = (id: string) => {
        if (!onChangeSelector) {
            return;
        }

        let shouldDisplay: (index: number) => boolean = () => false;

        if (id === OPTION_ID_ALL) {
            shouldDisplay = () => true;
        } else if (id.startsWith(OPTION_ID_ONLY_PREFIX)) {
            const displayedIndex = parseInt(id.replace(OPTION_ID_ONLY_PREFIX, ''), 10);
            shouldDisplay = (index) => index === displayedIndex;
        }

        const changeSet = Object.keys(values).reduce((currChangeSet, selector, index) => {
            currChangeSet[selector] = {
                display: shouldDisplay(index) ? DISPLAY_SHOWN : DISPLAY_HIDDEN,
            };
            return currChangeSet;
        }, {} as SelectorSet);

        onChangeSelector(changeSet);
    };

    const getElementNames = () => Object.keys(values).map((selector) => selector.split('::').pop() || '');

    const getTitle = () => {
        const translationKeys = getTranslationKeys();
        return translationKeys[displayTitleKey] ? translate(translationKeys[displayTitleKey]) : 'Show';
    };

    return Object.keys(values).length !== 1 ? (
        <div className={style(classes.root, { plane }, className)}>
            <CompositeBlock className={classes.controllerBlock} title={getTitle()}>
                <DropDown
                    className={classes.dropDown}
                    value={getDropDownValue()}
                    options={getDropDownOptions()}
                    onSelect={handleDropDownSelect}
                />
            </CompositeBlock>
        </div>
    ) : null;
};

export default ChildDisplayDropdown;
