import {
    BoxShadowMap,
    TextShadowMap,
    Dimension,
    createCssValueAST,
    NONE_KEYWORD,
    DEFAULT_DIMENSION,
} from '@wix/shorthands-opener';

import type {
    OriginNode,
    ParseShorthandAPI,
    ParsedCompoundAst,
    ParsedCompound,
    LayeredParsedCompound,
    DeclarationValue,
} from '../../declaration-types';
import { getCompoundParser } from '../../utils/visualizer-utils';

export type ShadowProps = 'box-shadow' | 'text-shadow';
export type ShadowMap = BoxShadowMap | TextShadowMap;
// Currently, offset-x and offset-y aren't edited directly in ShadowInput. That's why we cannot support preserving them as variables at this time.
export interface ShadowOrigins {
    'blur-radius'?: OriginNode;
    'spread-radius'?: OriginNode;
    color?: OriginNode;
}
export interface ShadowLayer {
    layer: ShadowMap;
    origins: ShadowOrigins;
}
type ShadowValueTypes = boolean | Dimension | string;

export const EMPTY_COLOR = 'currentcolor';
export const ORIGIN_PROPS: Array<keyof ShadowOrigins> = ['blur-radius', 'spread-radius', 'color'];

export const parseShadow = (prop: ShadowProps, value: string, shorthandApi: ParseShorthandAPI): ShadowMap[] => {
    const parser = getCompoundParser(prop);
    const parsedShadowCompound = parser(createCssValueAST(value), shorthandApi);
    return parseShadowFromCompound(prop, parsedShadowCompound);
};

const stringifyDimension = (value: Dimension) => {
    if (value.unit === 'spx') {
        return `${value.raw} `;
    }
    return `${value.number === 0 ? '0' : `${value.number}${value.unit}`} `;
};
const stringifyOptionalDimension = (value?: Dimension) =>
    !value || value.number === 0 ? '' : stringifyDimension(value);

export const stringifyShadowLayer = (shadow: ShadowMap, origins?: ShadowOrigins): string => {
    const forceSpread = !!origins && !!origins['spread-radius'];
    const forceBlur = forceSpread || (!!origins && !!origins['blur-radius']);

    return [
        (shadow as BoxShadowMap).inset ? 'inset ' : '',
        stringifyDimension(shadow['offset-x']),
        stringifyDimension(shadow['offset-y']),
        forceBlur
            ? stringifyDimension(shadow['blur-radius'] ?? DEFAULT_DIMENSION)
            : stringifyOptionalDimension(shadow['blur-radius']),
        forceSpread
            ? stringifyDimension((shadow as BoxShadowMap)['spread-radius'] ?? DEFAULT_DIMENSION)
            : stringifyOptionalDimension((shadow as BoxShadowMap)['spread-radius']),
        shadow.color,
    ]
        .join('')
        .trim();
};
export const stringifyShadow = (shadows: ShadowMap[]): string =>
    shadows.map((shadow) => stringifyShadowLayer(shadow)).join(', ');

export const createShadowAST = ({ layer, origins }: ShadowLayer) => {
    const ast: DeclarationValue = createCssValueAST(stringifyShadowLayer(layer, origins));
    const { 'blur-radius': blurRadius, 'spread-radius': spreadRadius, color } = origins;
    if (blurRadius) {
        ast[ast.length - 3] = blurRadius;
    }
    if (spreadRadius) {
        ast[ast.length - 2] = spreadRadius;
    }
    if (color) {
        ast[ast.length - 1] = color;
    }
    return ast;
};

const sanitizeParsedShadowCompoundToLayers = (
    prop: ShadowProps,
    parsedShadowCompound: ParsedCompound<ShadowMap>
): LayeredParsedCompound<BoxShadowMap> => {
    const parsedBoxShadowCompound = parsedShadowCompound as unknown as ParsedCompound<BoxShadowMap>;
    return (
        !Array.isArray(parsedShadowCompound['offset-x'])
            ? {
                  'offset-x': [parsedShadowCompound['offset-x']],
                  'offset-y': [parsedShadowCompound['offset-y']],
                  'blur-radius': [parsedShadowCompound['blur-radius']],
                  color: [parsedShadowCompound['color']],
                  ...(prop === 'box-shadow'
                      ? {
                            inset: [parsedBoxShadowCompound['inset']],
                            'spread-radius': [parsedBoxShadowCompound['spread-radius']],
                        }
                      : {}),
              }
            : parsedShadowCompound
    ) as LayeredParsedCompound<BoxShadowMap>;
};

export const parseShadowFromCompound = (
    prop: ShadowProps,
    parsedShadowCompound: ParsedCompound<ShadowMap>
): ShadowMap[] => {
    const parsedCompound = parsedShadowCompound as unknown as ParsedCompound<Record<string, string>>;
    const propValue = parsedCompound[prop]
        ? (parsedCompound[prop] as ParsedCompoundAst<string>).parsedValue
        : undefined;
    if (propValue === NONE_KEYWORD) {
        return [];
    }

    const shadows: ShadowMap[] = [];
    const parsedShadowCompoundLayers = sanitizeParsedShadowCompoundToLayers(prop, parsedShadowCompound);
    for (const [key, layers] of Object.entries(parsedShadowCompoundLayers)) {
        for (let i = 0; i < layers.length; i++) {
            shadows[i] = shadows[i] ?? {};
            (shadows[i][key as keyof ShadowMap] as ShadowValueTypes) = layers[i].parsedValue as ShadowValueTypes;
        }
    }

    return shadows;
};
