import type { TranslateFunc, Translation } from '@wix/stylable-panel-controllers';
import {
    ComponentEditSession,
    getFinalStateName,
    hasState,
    StylablePanelTranslationKeys,
} from '@wix/stylable-panel-drivers';
import { OverridesMap, StateOverridesConfig, StateOverridesStates } from '../editor-panel/types';

const EMPTY_STATE_OVERRIDES_CONFIG: StateOverridesConfig = {
    action: StateOverridesStates.ERROR,
    content: '',
    callback: () => false,
    disabled: false,
};

export class ResetStateOverridesDriver {
    public stateOverridesConfig: StateOverridesConfig = EMPTY_STATE_OVERRIDES_CONFIG;

    constructor(
        private editSession: ComponentEditSession,
        private clearStateStyleOverrides: (selector: string) => void,
        private openStateOverridesDialog: (fixedStates: string[]) => void,
        private translate: TranslateFunc = (key: string) => key
    ) {}

    public handleStateOverrides(customTranslations?: Translation) {
        const { selector } = this.editSession;

        const overridesMap = this.getStateOverridesMap();
        const availableOverrides = overridesMap.filter((override) => override.rules.length);

        const stateView = hasState(selector);
        const action = this.getStateOverridesAction(stateView, availableOverrides.length);
        const currentStateHasOverride =
            stateView && !!availableOverrides.filter((override) => override.selector === selector).length;
        const disabled = availableOverrides.length === 0 || (stateView && !currentStateHasOverride);

        this.stateOverridesConfig = {
            action,
            content: this.getStateOverridesContent(action, availableOverrides, customTranslations),
            callback: () => !disabled && this.getStateOverridesCallback(action, availableOverrides),
            disabled,
        };
    }

    public onStateListSubmit(selector: string, states: string[]) {
        states.forEach((state) =>
            this.clearStateStyleOverrides(
                this.editSession.selectorConfiguration.getStateOverrideTransformed(selector, state)
            )
        );
    }

    private getStateOverridesMap(): OverridesMap[] {
        const { computed, selector, stylableDriver, stylesheetPath, selectorConfiguration } = this.editSession;

        const baseSelector = this.editSession.getSelectorWithoutStates();
        const availableStates = computed.states.filter(
            (state) => !selectorConfiguration.isStateHidden(selector, state)
        );

        return availableStates.map((state: string) => {
            const transformedSelector = selectorConfiguration.getStateOverrideTransformed(baseSelector, state);
            return {
                selector: transformedSelector,
                langKey: selectorConfiguration.getStateName(baseSelector, state),
                rules: stylableDriver.getStylesheet(stylesheetPath)?.getVariantStyleRules(transformedSelector),
                state,
            };
        }) as OverridesMap[];
    }

    private getStateOverridesAction(isState: boolean, count: number): StateOverridesStates {
        const {
            REGULAR_VIEW_DISABLED_NO_VALID_TARGETS,
            REGULAR_VIEW_ENABLED_SINGLE_VALID_TARGET,
            REGULAR_VIEW_ENABLED_MULTIPLE_VALID_TARGETS,
            STATE_VIEW_DISABLED_NO_VALID_TARGETS,
            STATE_VIEW_ENABLED_CURRENT_STATE_IS_VALID_TARGET,
            ERROR,
        } = StateOverridesStates;

        if (isState) {
            if (count === 0) {
                return STATE_VIEW_DISABLED_NO_VALID_TARGETS;
            }
            if (count > 0) {
                return STATE_VIEW_ENABLED_CURRENT_STATE_IS_VALID_TARGET;
            }
            return ERROR;
        } else {
            if (count === 0) {
                return REGULAR_VIEW_DISABLED_NO_VALID_TARGETS;
            }
            if (count === 1) {
                return REGULAR_VIEW_ENABLED_SINGLE_VALID_TARGET;
            }
            if (count > 1) {
                return REGULAR_VIEW_ENABLED_MULTIPLE_VALID_TARGETS;
            }
        }
        return ERROR;
    }

    private getStateOverridesContent(
        action: StateOverridesStates,
        overridesMap: OverridesMap[],
        customTranslations?: Translation
    ) {
        const customOverrideTranslations = customTranslations?.states?.overrides;

        const { applyRegularDesign, stateApplyToSpecificState, applyToOtherStates } =
            StylablePanelTranslationKeys.states.overrides;

        const {
            REGULAR_VIEW_DISABLED_NO_VALID_TARGETS,
            REGULAR_VIEW_ENABLED_SINGLE_VALID_TARGET,
            REGULAR_VIEW_ENABLED_MULTIPLE_VALID_TARGETS,
            STATE_VIEW_DISABLED_NO_VALID_TARGETS,
            STATE_VIEW_ENABLED_CURRENT_STATE_IS_VALID_TARGET,
            ERROR,
        } = StateOverridesStates;

        switch (action) {
            case STATE_VIEW_DISABLED_NO_VALID_TARGETS:
            case STATE_VIEW_ENABLED_CURRENT_STATE_IS_VALID_TARGET:
                return customOverrideTranslations?.applyRegularDesign || this.translate(applyRegularDesign);
            case REGULAR_VIEW_DISABLED_NO_VALID_TARGETS:
            case REGULAR_VIEW_ENABLED_MULTIPLE_VALID_TARGETS:
                return customOverrideTranslations?.applyToOtherStates || this.translate(applyToOtherStates);
            case REGULAR_VIEW_ENABLED_SINGLE_VALID_TARGET: {
                return (
                    customOverrideTranslations?.stateApplyToSpecificState ||
                    this.translate(stateApplyToSpecificState, {
                        stateName: this.translate(overridesMap[0].langKey),
                    })
                );
            }
            case ERROR:
            default: {
                return ERROR;
            }
        }
    }

    private getStateOverridesCallback(action: StateOverridesStates, overridesMap: OverridesMap[]) {
        const { selector } = this.editSession;
        const {
            REGULAR_VIEW_ENABLED_SINGLE_VALID_TARGET,
            REGULAR_VIEW_ENABLED_MULTIPLE_VALID_TARGETS,
            STATE_VIEW_ENABLED_CURRENT_STATE_IS_VALID_TARGET,
            ERROR,
        } = StateOverridesStates;

        switch (action) {
            case STATE_VIEW_ENABLED_CURRENT_STATE_IS_VALID_TARGET: {
                this.clearStateStyleOverrides(selector);
                break;
            }
            case REGULAR_VIEW_ENABLED_SINGLE_VALID_TARGET: {
                const targetStateSelector = overridesMap.length && overridesMap[0];
                if (targetStateSelector && targetStateSelector.selector) {
                    this.clearStateStyleOverrides(targetStateSelector.selector);
                }
                break;
            }
            case REGULAR_VIEW_ENABLED_MULTIPLE_VALID_TARGETS: {
                const { states } = this.editSession.computed;
                const usedStates = overridesMap.map((override) => getFinalStateName(override.selector));
                const fixedStates = states.filter((s) => usedStates.indexOf(s) === -1);
                this.openStateOverridesDialog(fixedStates);
                break;
            }
            case ERROR:
            default: {
                return;
            }
        }
    }
}
