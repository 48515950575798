import type { Paddings, Margins } from '@wix/shorthands-opener';

import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';

import type { VisualizerFC } from '../types';
import type { PaddingProps, MarginProps } from '../visualizer-factories/edge-visualizer-factory/edge-types';
import { EdgeVisualizerFactory } from '../visualizer-factories/edge-visualizer-factory/edge-visualizer-factory';
import { createDeclarationVisualizer } from '../utils/visualizer-wrapper';

const PADDING_PROPS_LIST: Paddings[] = ['padding-top', 'padding-right', 'padding-bottom', 'padding-left'];
const MARGIN_PROPS_LIST: Margins[] = ['margin-top', 'margin-right', 'margin-bottom', 'margin-left'];

export const PaddingVisualizer = EdgeVisualizerFactory('padding', {
    inputProps: PADDING_PROPS_LIST,
    title: StylablePanelTranslationKeys.controller.padding.title,
    sectionTooltip: StylablePanelTranslationKeys.controller.padding.sectionTooltip,
}) as VisualizerFC<PaddingProps>;

export const MarginVisualizer = EdgeVisualizerFactory('margin', {
    inputProps: MARGIN_PROPS_LIST,
    title: StylablePanelTranslationKeys.controller.margin.title,
    sectionTooltip: StylablePanelTranslationKeys.controller.margin.sectionTooltip,
}) as VisualizerFC<MarginProps>;

export const PaddingInlineVisualizer = EdgeVisualizerFactory('padding', {
    inputProps: PADDING_PROPS_LIST,
    title: StylablePanelTranslationKeys.controller.padding.title,
    sectionTooltip: StylablePanelTranslationKeys.controller.padding.sectionTooltip,
    layout: 'inline',
}) as VisualizerFC<PaddingProps>;

export const MarginInlineVisualizer = EdgeVisualizerFactory('margin', {
    inputProps: MARGIN_PROPS_LIST,
    title: StylablePanelTranslationKeys.controller.margin.title,
    sectionTooltip: StylablePanelTranslationKeys.controller.margin.sectionTooltip,
    layout: 'inline',
}) as VisualizerFC<MarginProps>;

export const PaddingDeclarationVisualizer = createDeclarationVisualizer<PaddingProps>('padding', PaddingVisualizer);

export const MarginDeclarationVisualizer = createDeclarationVisualizer<MarginProps>('margin', MarginVisualizer);

export const PaddingDeclarationInlineVisualizer = createDeclarationVisualizer<PaddingProps>(
    'padding',
    PaddingInlineVisualizer
);

export const MarginDeclarationInlineVisualizer = createDeclarationVisualizer<MarginProps>(
    'margin',
    MarginInlineVisualizer
);
