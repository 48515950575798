import type { ValueInputComponentClass } from './types';

export class ValueInputDriver {
    private inputs: Record<string, ValueInputComponentClass>;

    constructor(private defaultInput: ValueInputComponentClass) {
        this.inputs = {};
    }

    // TODO: Register suggestions?

    public registerInput(propNames: string | string[], Input: ValueInputComponentClass) {
        const propList = typeof propNames === 'string' ? [propNames] : propNames;
        propList.forEach((propName) => (this.inputs[propName] = Input));
    }

    public getInput(propName: string) {
        return this.inputs[propName] || this.defaultInput;
    }
}
