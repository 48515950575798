import { DIMENSION_ID } from '@wix/stylable-panel-common';
import { CompositeBlock } from '@wix/stylable-panel-components';
import React, { useCallback, useMemo } from 'react';
import type { OpenedDeclarationArray } from '../../declaration-types';
import {
    FontSizeVisualizer,
    getDimensionConfig,
    LetterSpacingVisualizer,
    LineHeightVisualizer,
} from '../../generated-visualizers';
import { createDeclarationMapFromVisualizerValue, createVisualizerValueFromDeclarationMap } from '../../utils';
import type { TextVisualizerProps } from './use-text-visualizer';
import { classes, style } from './text-visualizer.st.css';
import { ID_FUNCTION } from './text-visualizer';

export type SliderBlockProps = {
    title: string;
    value: string;
    prop: 'letter-spacing' | 'font-size' | 'line-height';
    informationTooltip?: string;
    props: TextVisualizerProps;
    changeFromBlock: (prop: string, valueFunction: (value: string) => string, value?: string) => void;
};

export function SliderBlock({ title, value, prop, informationTooltip, props, changeFromBlock }: SliderBlockProps) {
    const { drivers, panelHost } = props;
    const SliderVisualizer = useMemo(
        () =>
            prop === DIMENSION_ID.LETTER_SPACING
                ? LetterSpacingVisualizer
                : prop === DIMENSION_ID.FONT_SIZE
                ? FontSizeVisualizer
                : prop === DIMENSION_ID.LINE_HEIGHT
                ? LineHeightVisualizer
                : undefined,
        [prop]
    );

    const dimensionConfig = useMemo(
        () =>
            getDimensionConfig({
                id: prop,
                dimensionUnits: panelHost?.dimensionUnits,
                dimensionKeywords: panelHost?.dimensionKeywords,
            }),
        [panelHost?.dimensionUnits, panelHost?.dimensionKeywords, prop]
    );

    const sliderValue = useMemo(
        () =>
            createVisualizerValueFromDeclarationMap({
                [prop]: value,
            }) as OpenedDeclarationArray<'letter-spacing'> &
                OpenedDeclarationArray<'font-size'> &
                OpenedDeclarationArray<'line-height'>,
        [prop, value]
    );

    const handleOnChange = useCallback(
        (
            value:
                | OpenedDeclarationArray<'letter-spacing'>
                | OpenedDeclarationArray<'font-size'>
                | OpenedDeclarationArray<'line-height'>
        ) => {
            const { [prop]: sliderValue } = createDeclarationMapFromVisualizerValue(value, {
                value: [],
                drivers,
            });
            changeFromBlock(prop, ID_FUNCTION, sliderValue);
        },
        [changeFromBlock, drivers, prop]
    );

    return SliderVisualizer ? (
        <CompositeBlock
            key={prop}
            // TODO!: get rid of this ternary
            className={classes.controllerBlock}
            title={title}
            information={informationTooltip}
            divider
        >
            <SliderVisualizer
                className={style(classes.inputElement, { prop })}
                drivers={drivers}
                value={sliderValue}
                config={dimensionConfig}
                onChange={handleOnChange}
                panelHost={panelHost}
            />
        </CompositeBlock>
    ) : null;
}
