import type { GridGaps } from '../shorthand-css-data';
import type {
    SimpleShorthandOpener,
    ShorthandPart,
    GetSimpleShorthandOpener,
    GetShorthandCloser,
} from '../shorthand-types';

import { lengthPercentageDataType } from '../../css-data-types';
import {
    splitSimpleShorthandOpener,
    unorderedListShorthandOpener,
    createShorthandOpener,
    createShorthandCloserTemplateFromParts,
    createShorthandCloser,
} from '../shorthand-parser-utils';

const getGridGapShorthandParts = <V>() =>
    [
        {
            prop: 'grid-row-gap',
            dataType: lengthPercentageDataType,
            partOpener: splitSimpleShorthandOpener(['grid-row-gap', 'grid-column-gap']),
        },
        {
            prop: 'grid-column-gap',
            dataType: lengthPercentageDataType,
        },
    ] as ShorthandPart<V, GridGaps>[];

// grid-gap
export const openGridGapShorthand: GetSimpleShorthandOpener<GridGaps> = <V>() =>
    createShorthandOpener({
        prop: 'grid-gap',
        parts: getGridGapShorthandParts(),
        shorthandOpener: (astNodes, api, parts) => unorderedListShorthandOpener(parts)(astNodes, api),
    }) as SimpleShorthandOpener<V, GridGaps>;

export const closeGridGapShorthand: GetShorthandCloser<GridGaps> = <V>() =>
    createShorthandCloser<V, GridGaps>(createShorthandCloserTemplateFromParts(getGridGapShorthandParts()));
