import React from 'react';
import { ControlledTabs, OpenSections, TabsProps } from './controlled-tabs';

export const Tabs: React.FC<TabsProps> = (props) => {
    const { sections, initialTab } = props;
    const [selectedTab, setSelectedTab] = React.useState<string | undefined>(
        initialTab !== undefined && sections.length > initialTab ? sections[initialTab].id : undefined
    );
    const [openSections, setOpenSections] = React.useState<OpenSections | undefined>(undefined);

    return (
        <ControlledTabs
            openSections={openSections}
            selectedTab={selectedTab}
            onOpenSections={setOpenSections}
            onSelectedTab={setSelectedTab}
            {...props}
        />
    );
};
