import { DimensionProps, UnitRef } from '@wix/stylable-panel-common';
import { Alert } from '@wix/stylable-panel-common-react';
import React, { DependencyList, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DropDown } from '../drop-down/drop-down';
import { Slider } from '../slider/slider';
import { Tooltip } from '../tooltip/tooltip';
import { debounce } from '../utils/debounce';
import {
    DEFAULT_INPUT_VALUE,
    DEFAULT_PLACEHOLDER,
    DEFAULT_SHIFT_STEP,
    DEFAULT_STEP,
    MAX_INPUT_LENGTH,
    MAX_VALUE,
    MAX_VALUE_LENGTH,
    MIN_VALUE,
    TOOLTIP_OFFSET,
    UNITLESS_CONFIG,
    UNITLESS_SYMBOL,
    ZERO_STR,
} from './consts';
import { classes, style } from './dimension-input.st.css';
import { exponentValue, splitNumDigit } from './patterns';
import {
    CssUnit,
    DimensionInputProps,
    DimensionRef,
    InputContext,
    InputError,
    InternalDimensionProps,
    MODIFIER_KEYS,
    ParsedDimension,
} from './types';
import {
    breakError,
    compileValue,
    debug,
    getDropdownOptions,
    getRefSymbol,
    getSliderConfig,
    getSymbolRef,
    isFloat,
    isNumber,
    keyInput,
    sanitizeNegativeFloat,
    stringExtract,
    trimFloatValue,
    unitFilter,
    validateNodes,
    validateNum,
    valueFilter,
} from './utils';

const useUpdateEffect = (callback: () => void, dependencies: DependencyList) => {
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        } else {
            return callback();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callback, ...dependencies]);
};

function useForceUpdate() {
    const flip = (isUpdated: boolean) => !isUpdated;
    const [, forceUpdate] = useState(false);
    return () => forceUpdate(flip);
}

function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

// TODO: Remove this function and implement useEffect inside the component itself.. this one not returning cleanup()
const useMountEffect = (cb: () => void) => {
    const willMount = useRef(true);

    if (willMount.current) cb();

    willMount.current = false;
};

export const DimensionInput: React.FC<DimensionInputProps> = React.memo((props) => {
    const {
        className,
        forceFocus,
        hidePlaceholder,
        inputDataAid,
        isBoundRange,
        isDisabled,
        isInputCyclic,
        isRequired,
        isSlider,
        isSliderCyclicKnob,
        keepRange,
        noFloat,
        noValueLengthLimit,
        onBlur,
        onChange,
        onFocus,
        opacitySliderColor,
        tooltipContent,
        translate,
        translationKeys,
        trimUnit,
        debug: isDebug,
        value: propValue,
        config = {},
        showUnitErrors = true,
        isDebounced = false,
        debounceWait = 300,
    } = props;

    const _value = useRef<number>(DEFAULT_INPUT_VALUE);
    const _unit = useRef<CssUnit>('');
    const _config = useRef<InternalDimensionProps>(UNITLESS_CONFIG);
    const _editContext = useRef<InputContext>(InputContext.ABORT);
    const _interaction = useRef<boolean>(false);
    const _invalidUnit = useRef<boolean>(false);

    const forceUpdate = useForceUpdate();

    const [editValue, setEditValue] = useState(
        propValue && config?.keywords?.includes(propValue) ? propValue : DEFAULT_INPUT_VALUE.toString()
    );
    const [inputContext, setInputContext] = useState(InputContext.ABORT);
    const [inputError, setInputError] = useState(InputError.NONE);

    const parse = useCallback(
        (sample: string, applyUnit = false): ParsedDimension => {
            const error = breakError(_value.current, _unit.current);
            const { INVALID, EMPTY } = InputError;

            if (sample.length && !!sample.match(exponentValue)) {
                return error(INVALID);
            }

            const nodes = sample.match(splitNumDigit) || [];
            if (!nodes.length) {
                return error(EMPTY);
            }

            const fixedNodes = sanitizeNegativeFloat(nodes);
            if (!validateNodes(fixedNodes)) {
                return error(INVALID);
            }

            let matched = stringExtract(nodes, valueFilter);
            if (!matched.length) {
                return error(INVALID);
            }

            const compiledMatched = Number(matched).toString();
            if (compiledMatched !== matched || compiledMatched.length > MAX_INPUT_LENGTH) {
                matched = matched.slice(0, MAX_INPUT_LENGTH);
            }

            const { symbol } = getSymbolRef(stringExtract(nodes, unitFilter));

            let compiled = Number(compileValue(matched));
            compiled = isNumber(compiled) ? compiled : DEFAULT_INPUT_VALUE;
            const { name } = getSymbolRef(symbol);

            const unitConfigNoFloat = !applyUnit && _config.current?.noFloat;
            const mainConfigNoFloat = applyUnit && symbol !== _unit.current && config?.units?.[name]?.noFloat;

            const truncFloat = isFloat(compiled) && (noFloat || unitConfigNoFloat || mainConfigNoFloat);

            return {
                value: truncFloat ? Math.trunc(compiled) : compiled,
                unit: applyUnit ? symbol : _unit.current,
            };
        },
        [config?.units, noFloat]
    );

    const range = useCallback(
        (value: number, reverse?: boolean): number => {
            if (!isInputCyclic && !keepRange) {
                return value;
            }

            let { min, max } = _config.current;
            min = min ?? MIN_VALUE;
            max = max ?? MAX_VALUE;

            const validMin = isNumber(min);
            const validMax = isNumber(max);

            if (!validMin && !validMax) {
                return value;
            }

            const exceedMin = validMin && value < min;
            const exceedMax = validMax && value > max;

            const maxOrMin = reverse ? min : max;
            const minOrMax = reverse ? max : min;

            if (exceedMax && validMin) {
                if (isInputCyclic) {
                    return minOrMax;
                }
                if (keepRange) {
                    return maxOrMin;
                }
            }

            if (exceedMin && validMax) {
                if (isInputCyclic) {
                    return maxOrMin;
                }
                if (keepRange) {
                    return minOrMax;
                }
            }

            if (exceedMax || exceedMin) {
                return _value.current;
            }

            return value;
        },
        [isInputCyclic, keepRange]
    );

    const getConfig = useCallback(
        (unitRef: UnitRef): DimensionProps => {
            if (!config?.units) {
                return UNITLESS_CONFIG;
            }

            const unitRefs = Object.keys(config?.units) as UnitRef[];
            const ref = config?.units[unitRef];

            if (ref) {
                _invalidUnit.current = false;
                if (inputError === InputError.INVALID_UNIT) {
                    setInputError(InputError.NONE);
                }
                return ref;
            }

            if (!unitRefs.length) {
                return UNITLESS_CONFIG;
            }

            if (showUnitErrors) {
                _invalidUnit.current = true;
            }
            return config?.units[unitRefs[0]] as DimensionProps;
        },
        [config?.units, showUnitErrors, inputError]
    );

    const setConfig = useCallback(
        (unit: CssUnit) => {
            const { name, symbol } = getSymbolRef(unit);
            const { units } = config;

            const innerConfig = getConfig(name);
            const { displaySymbol, min, max, step, shiftStep, noFloat, formatter } = innerConfig;
            const sliderConfig = isSlider ? getSliderConfig(innerConfig) : {};

            const targetConfig = {
                symbol,
                displaySymbol,
                min: validateNum(min),
                max: validateNum(max) || MAX_VALUE,
                step: step ?? DEFAULT_STEP,
                shiftStep: shiftStep ?? DEFAULT_SHIFT_STEP,
                noFloat,
                formatter,
            };

            let enforcedConfig = {};
            if (units && propValue === ZERO_STR) {
                const symbolRef = getRefSymbol(Object.keys(units)[0] as UnitRef);
                const firstRefValue = Object.values(units)[0];
                _unit.current = symbolRef;
                enforcedConfig = {
                    ...firstRefValue,
                    symbol: symbolRef,
                };
            } else if (targetConfig.symbol) {
                _unit.current = targetConfig.symbol;
            } else if (units?.unitless) {
                _unit.current = UNITLESS_SYMBOL;
            }

            _config.current = {
                ...targetConfig,
                ...sliderConfig,
                ...enforcedConfig,
            };
        },
        [config, getConfig, isSlider, propValue]
    );

    const sanitizeUnit = useCallback(
        (parsedUnit: CssUnit) => {
            const { name: parsedName } = getSymbolRef(parsedUnit);

            let name = parsedName;
            let unit = parsedUnit;
            if (propValue === ZERO_STR && !config?.units?.[name]) {
                name = (config?.units ? Object.keys(config.units)[0] : '') as UnitRef;
                unit = getRefSymbol(name);
            }

            return { name, symbol: unit } as DimensionRef;
        },
        [propValue, config?.units]
    );

    useMountEffect(() => {
        if (propValue && !config?.keywords?.includes(propValue)) {
            const { value, unit: parsedUnit, error } = parse(propValue, true);
            const { name, symbol: unit } = sanitizeUnit(parsedUnit);

            setConfig(unit);

            if (!config?.units?.[name]) {
                setInputError(InputError.INVALID_UNIT);
                setInputContext(InputContext.INTERACTIVE_UI);
            }

            setEditValue(
                (error ?? (_value.current === value && _unit.current === unit) ? range(value) : value).toString()
            );
            _value.current = value;
            _unit.current = unit;
        }
    });

    const prevPropValue = usePrevious(propValue);

    useUpdateEffect(() => {
        const { value, unit: parsedUnit } = parse(propValue, true);
        const { symbol: unit } = sanitizeUnit(parsedUnit);

        if (propValue && propValue !== prevPropValue) {
            if (config?.keywords?.includes(propValue)) {
                setConfig('');
                _value.current = DEFAULT_INPUT_VALUE;
                _unit.current = '';
                setEditValue(propValue);
                return;
            }

            setEditValue(value.toString());
            _value.current = value;
            _unit.current = unit;
            setConfig(unit);
        }
    }, [sanitizeUnit, config?.keywords, config?.units, parse, propValue, setConfig, editValue]);

    const sanitize = useCallback(
        (value: string) => {
            const { MAX_LENGTH, EMPTY, RANGE } = InputError;

            const ranged = range(Number(editValue)).toString();
            const valueLength = ranged.length;
            if (noValueLengthLimit && valueLength > MAX_VALUE_LENGTH) {
                return {
                    editValue: editValue.slice(0, MAX_VALUE_LENGTH - 1),
                    inputError: MAX_LENGTH,
                };
            } else if (editValue.length > MAX_INPUT_LENGTH) {
                return {
                    editValue: editValue.slice(0, MAX_INPUT_LENGTH),
                    inputError: MAX_LENGTH,
                };
            } else if (Number(value) > MAX_VALUE) {
                return {
                    editValue: value,
                    inputError: RANGE,
                };
            } else if (!value.length || (value.length && !value.trim().length)) {
                return {
                    editValue: '',
                    inputError: EMPTY,
                };
            }

            return { editValue: value, inputError };
        },
        [editValue, inputError, noValueLengthLimit, range]
    );

    const getRangeProps = useCallback(
        (parsedValue: number) => {
            let { min, max } = _config.current;
            min = min ?? MIN_VALUE;
            max = max ?? MAX_VALUE;

            const maxRange = isNumber(max) && max < parsedValue;
            const minRange = isNumber(min) && min > parsedValue;

            const freeRangeCyclic = !keepRange && isInputCyclic;
            const ranged = range(parsedValue).toString();
            const hasRange = maxRange || minRange;

            return {
                hasRange,
                maxRange,
                minRange,
                freeRangeCyclic,
                ranged,
            };
        },
        [isInputCyclic, keepRange, range]
    );

    const updateValue = useCallback(
        (value: number) => {
            const { formatter } = _config.current;
            const formattedValue = formatter ? formatter(value) : `${value}${_unit.current}`;
            onChange?.(formattedValue);
        },
        [onChange]
    );

    const setValue = useCallback(
        (value: number) => {
            setEditValue(value.toString());
            setInputError(InputError.NONE);

            if (_value.current !== value) {
                _value.current = value;
                updateValue(value);
            }
        },
        [updateValue]
    );

    const onStep = useCallback(
        (keyUp: boolean, shiftKey: boolean) => {
            const { step, shiftStep } = _config.current;
            let { min, max } = _config.current;

            min = min ?? MIN_VALUE;
            max = max ?? MAX_VALUE;

            const stepValue = shiftKey ? shiftStep ?? DEFAULT_SHIFT_STEP : step ?? DEFAULT_STEP;

            const value = Number(editValue === '' ? _value.current : editValue);
            let newValue = Number((value + stepValue * (keyUp ? 1 : -1)).toFixed(2));

            if (isInputCyclic && isNumber(min) && isNumber(max)) {
                if (keyUp && value + stepValue > max) {
                    setInputError(InputError.NONE);
                    newValue = min + stepValue - (max - value);
                } else if (!keyUp && value - stepValue < min) {
                    setInputError(InputError.NONE);
                    newValue = max + (value - stepValue);
                }
            }

            if (newValue !== value) {
                const { hasRange } = getRangeProps(newValue);
                if (hasRange) {
                    newValue = Math.max(min, Math.min(max, newValue));
                }
                _value.current = newValue;
                setEditValue(newValue.toString());
                setInputError(InputError.NONE);
                updateValue(newValue);
            }
        },
        [editValue, getRangeProps, isInputCyclic, updateValue]
    );

    const handleFocus = useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
            event.preventDefault();
            if (_invalidUnit.current) {
                onChange?.();
                _invalidUnit.current = false;
            }
            event.currentTarget.select();
            setInputContext(InputContext.FOCUS);

            onFocus?.();
        },
        [onChange, onFocus]
    );

    const handleBlur = useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
            const { value: targetValue } = event.currentTarget;
            const { keywords } = config;
            const { ABORT, INTERACTIVE_UI } = InputContext;
            const newInputContext = _interaction.current ? INTERACTIVE_UI : ABORT;
            if (keywords?.includes(targetValue)) {
                setInputContext(newInputContext);
                setInputError(InputError.NONE);

                onBlur?.();
                return;
            }

            if (targetValue === '' || !isNumber(targetValue)) {
                setEditValue(propValue && keywords?.includes(propValue) ? propValue : _value.current.toString());
                setInputContext(newInputContext);
                setInputError(InputError.NONE);

                onBlur?.();
                return;
            }

            const { value, error } = parse(targetValue);

            const shouldEnforceValue = !noValueLengthLimit && value.toString().length > MAX_VALUE_LENGTH;

            const enforced = shouldEnforceValue
                ? isFloat(value)
                    ? trimFloatValue(value)
                    : value < 0
                    ? range(MIN_VALUE)
                    : range(MAX_VALUE)
                : value;

            const ranged = range(enforced, isInputCyclic);

            if (value !== ranged || error === InputError.INVALID) {
                if (propValue && keywords?.includes(propValue)) {
                    setEditValue(propValue);
                    setInputContext(newInputContext);
                    setInputError(InputError.NONE);
                    return;
                }

                setEditValue(ranged.toString());
                setInputContext(newInputContext);
                setInputError(InputError.NONE);
            } else {
                setEditValue(_value.current.toString());
                setInputContext(newInputContext);
                setInputError(InputError.NONE);
            }

            if (ranged !== _value.current) {
                updateValue(ranged);
            }

            onBlur?.();
        },
        [config, isInputCyclic, noValueLengthLimit, onBlur, updateValue, parse, propValue, range]
    );

    const debouncedOnChange = useMemo(
        () =>
            isDebounced
                ? debounce((value?: string) => onChange?.(value), debounceWait)
                : (value?: string) => onChange?.(value),
        [debounceWait, isDebounced, onChange]
    );

    const debouncedUpdateValue = useMemo(
        () => (isDebounced ? debounce(updateValue, debounceWait) : updateValue),
        [debounceWait, isDebounced, updateValue]
    );

    const handleChange = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            event.preventDefault();

            const { value } = event.currentTarget;

            if (value === editValue) {
                return;
            }

            if (value === '') {
                setEditValue(value);
                return;
            }

            if (!isNumber(value)) {
                setEditValue(value);
                setInputError(
                    config?.keywords?.some((word) => word.startsWith(value)) ? InputError.NONE : InputError.INVALID
                );

                if (config?.keywords?.includes(value)) {
                    setConfig('');
                    debouncedOnChange(value);
                }
                return;
            }

            const sanitized = sanitize(value);
            if (sanitized.editValue !== value && sanitized.inputError) {
                setEditValue(sanitized.editValue);
                setInputError(sanitized.inputError);
                return;
            }

            const { value: parsedValue, unit: parsedUnit, error: parsedError } = parse(value);

            if (parsedError === InputError.INVALID) {
                setEditValue(value);
                setInputError(parsedError);
                return;
            }

            const { min, max } = _config.current;
            const { hasRange, maxRange, minRange, freeRangeCyclic, ranged } = getRangeProps(parsedValue);

            const isRangeKeyChange = hasRange && _editContext.current === InputContext.KEY_CHANGE;

            let editValueInner;
            let inputErrorInner;
            if (!isRangeKeyChange) {
                editValueInner = value.toString();
                inputErrorInner = hasRange ? InputError.RANGE : InputError.NONE;
            } else if (freeRangeCyclic && maxRange) {
                editValueInner = min?.toString() ?? '';
                inputErrorInner = InputError.NONE;
            } else if (freeRangeCyclic && minRange) {
                editValueInner = max?.toString() ?? '';
                inputErrorInner = InputError.NONE;
            } else if (!freeRangeCyclic && ranged !== editValue) {
                if (isBoundRange) {
                    editValueInner = value.toString();
                    inputErrorInner = hasRange ? InputError.INVALID : InputError.NONE;
                } else {
                    inputErrorInner = InputError.NONE;
                }
            } else if (!keepRange && !isInputCyclic) {
                editValueInner = parsedValue.toString();
                inputErrorInner = hasRange ? InputError.INVALID : InputError.NONE;
            } else {
                editValueInner = value.toString();
                inputErrorInner = hasRange ? InputError.RANGE : InputError.NONE;
            }

            if (editValueInner) {
                setEditValue(editValueInner);
            }

            setInputError(inputErrorInner);

            if (inputErrorInner === InputError.NONE) {
                _value.current = parsedValue;
                _unit.current = parsedUnit;
                debouncedUpdateValue(parsedValue);
            }
        },
        [
            config?.keywords,
            editValue,
            getRangeProps,
            isBoundRange,
            isInputCyclic,
            keepRange,
            debouncedOnChange,
            debouncedUpdateValue,
            parse,
            sanitize,
            setConfig,
        ]
    );

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            const { keyCode, shiftKey } = event;
            const { UP, DOWN, ESC } = MODIFIER_KEYS;
            const { KEY_CHANGE, ABORT } = InputContext;

            const isKey = keyInput(keyCode);
            const keyUp = isKey(UP);
            const keyDown = isKey(DOWN);

            if (keyUp || keyDown) {
                event.preventDefault();
            }

            if (isKey(ESC)) {
                setEditValue(_value.current.toString());
                setInputContext(ABORT);
                return;
            }

            if ((keyUp || keyDown) && isNumber(editValue)) {
                _editContext.current = KEY_CHANGE;
                onStep(keyUp, shiftKey);
                return;
            }
        },
        [editValue, onStep]
    );

    const handleKeyUp = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            const { keyCode } = event;
            const { UP, DOWN } = MODIFIER_KEYS;
            const isKey = keyInput(keyCode);

            if (isKey(DOWN) || isKey(UP)) {
                _editContext.current = InputContext.EDIT_TIME;

                if (isNumber(editValue)) {
                    event.currentTarget.select();
                }
            }
        },
        [editValue]
    );

    const handleDropDownSelect = useCallback(
        (ref: UnitRef) => {
            const oldUnit = _unit.current;
            const unit = getRefSymbol(ref);
            setConfig(unit);

            const ranged = range(_value.current);

            if (ranged !== _value.current) {
                setValue(ranged);
            } else if (oldUnit !== unit) {
                _unit.current = unit;
                updateValue(ranged);
            }

            _interaction.current = false;

            forceUpdate();
        },
        [forceUpdate, updateValue, range, setConfig, setValue]
    );

    const renderSlider = useCallback(() => {
        const { sliderMin, sliderMax, sliderStep } = _config.current;
        const validRange = isSlider && isNumber(sliderMin) && isNumber(sliderMax);

        // TODO: Implement throttle here.
        return validRange ? (
            <Slider
                className={classes.slider}
                value={propValue ? parse(propValue).value : _value.current}
                min={sliderMin}
                max={sliderMax}
                step={sliderStep}
                knob={isSliderCyclicKnob}
                disabled={isDisabled || !isNumber(editValue) || editValue === '' || _invalidUnit.current}
                color={opacitySliderColor}
                onChange={setValue}
                throttle
            />
        ) : null;
    }, [editValue, isDisabled, isSlider, isSliderCyclicKnob, opacitySliderColor, parse, propValue, setValue]);

    const renderUnitSelector = useCallback(() => {
        const { symbol, displaySymbol } = _config.current;
        const { name } = getSymbolRef(_unit.current);
        const dimensionSymbol = displaySymbol || symbol || '';
        const fallbackName =
            translate && translationKeys?.unitDropdown?.unitlessLabel
                ? translate(translationKeys.unitDropdown.unitlessLabel)
                : undefined;

        return (
            <div
                className={classes.unitSelectorWrapper}
                onMouseEnter={() => (_interaction.current = true)}
                onMouseLeave={() => (_interaction.current = false)}
            >
                <DropDown
                    className={style(classes.unitSelector, { isDisabled: !!isDisabled })}
                    value={name}
                    options={getDropdownOptions(config?.units ?? {}, fallbackName)}
                    onSelect={(newUnit) => handleDropDownSelect(newUnit as UnitRef)}
                    disabled={isDisabled || _invalidUnit.current}
                    hideArrow={name !== UnitRef.CUSTOM}
                    noToggleOnEnter
                />
                <span className={classes.dimensionSymbol}>{!trimUnit && !_invalidUnit.current && dimensionSymbol}</span>
            </div>
        );
    }, [
        isDisabled,
        config?.units,
        translate,
        translationKeys?.unitDropdown?.unitlessLabel,
        trimUnit,
        handleDropDownSelect,
    ]);

    const renderSingleUnit = useCallback(() => {
        const { symbol, displaySymbol } = _config.current;
        const { name } = getSymbolRef(_config.current.symbol!);
        const dimensionSymbol = displaySymbol || symbol || '';
        const isFocused = inputContext === InputContext.FOCUS;

        return (
            <span
                className={style(classes.symbol, {
                    unit: dimensionSymbol,
                    ref: name,
                    hideUnit: isFocused || _invalidUnit.current,
                })}
                data-symbol={trimUnit || _invalidUnit.current ? '' : dimensionSymbol}
            >
                <span className={classes.dimensionSymbol}>{!trimUnit && !_invalidUnit.current && dimensionSymbol}</span>
            </span>
        );
    }, [inputContext, trimUnit]);

    const renderInput = useCallback(() => {
        const { name } = getSymbolRef(_config.current.symbol!);
        const { max, min } = _config.current;
        const unitRefs = Object.keys(config?.units ?? {});

        const rangeProps = !isBoundRange &&
            keepRange &&
            !isInputCyclic && {
                min,
                max,
            };

        const isFocused = inputContext === InputContext.FOCUS;

        const styleState = {
            symbol: name,
            invalidCss: _invalidUnit.current,
            focused: isFocused,
            inputError: inputError !== InputError.NONE,
        };

        const autoFocusProp = forceFocus ? { autoFocus: true } : {};

        const getTooltipContent = (): string => {
            if (isFocused) {
                return '';
            }

            return _invalidUnit.current && translate && translationKeys?.tooltip?.invalidUnit
                ? translate(translationKeys.tooltip.invalidUnit)
                : tooltipContent ?? '';
        };

        return (
            <span
                className={classes.inputElementWrapper}
                data-highlight-input-display={!isFocused && !isDisabled}
                data-aid={inputDataAid}
            >
                <span className={classes.tooltipWrapper} data-tooltip-hidden={isFocused}>
                    <Tooltip className={classes.tooltip} text={getTooltipContent()} verticalAdjust={TOOLTIP_OFFSET}>
                        <input
                            className={style(classes.inputElement, styleState)}
                            value={editValue}
                            onFocus={handleFocus}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            onKeyUp={handleKeyUp}
                            onBlur={(event) => {
                                !_interaction.current ? handleBlur(event) : event.currentTarget.select();
                            }}
                            onWheel={() => {
                                setInputContext(InputContext.ABORT);
                            }}
                            type={config?.keywords?.length ? 'text' : 'number'}
                            key={`editInput_${forceFocus ? 'forced' : ''}`}
                            placeholder={
                                hidePlaceholder
                                    ? DEFAULT_PLACEHOLDER
                                    : propValue && config?.keywords?.includes(propValue)
                                    ? propValue
                                    : _value.current.toString()
                            }
                            disabled={!!isDisabled}
                            required={!!isRequired}
                            {...rangeProps}
                            {...autoFocusProp}
                        />
                        {_invalidUnit.current ? <Alert className={classes.alert} /> : null}
                    </Tooltip>
                    {unitRefs.length <= 1 || config.disableUnitSelector ? renderSingleUnit() : renderUnitSelector()}
                </span>
            </span>
        );
    }, [
        config?.disableUnitSelector,
        config?.units,
        config?.keywords,
        isBoundRange,
        keepRange,
        isInputCyclic,
        inputContext,
        inputError,
        forceFocus,
        isDisabled,
        inputDataAid,
        editValue,
        handleFocus,
        handleChange,
        handleKeyDown,
        handleKeyUp,
        hidePlaceholder,
        propValue,
        isRequired,
        renderSingleUnit,
        renderUnitSelector,
        translate,
        translationKeys,
        tooltipContent,
        handleBlur,
    ]);

    return (
        <span
            className={style(
                classes.root,
                {
                    context: inputContext,
                    disabled: !!isDisabled,
                    required: !!isRequired,
                },
                className
            )}
        >
            {renderSlider()}
            {renderInput()}
            {isDebug && (
                <div className={classes.debug}>
                    <pre>
                        {debug({
                            storedValue: _value.current.toString(),
                            editValue,
                            inputContext,
                            inputError,
                            ..._config.current,
                        })}
                    </pre>
                </div>
            )}
        </span>
    );
});
