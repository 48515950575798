import { DeclarationMap, DEFAULT_WRAP_SITE_COLOR, parseGradient } from '@wix/stylable-panel-drivers';
import { useCallback, useMemo } from 'react';
import { EMPTY_IMAGE_BORDER_CHANGE_VALUE, getSideWidth } from '../controllers/border-visualizer/border-utils';
import type {
    CornerDeclarationMap,
    CornerVisualizerProps,
} from '../controllers/corner-visualizer/corner-visualizer-types';
import { controllerToVisualizerChange } from '../utils';
import { getDimensionInputDefaults } from '@wix/stylable-panel-common';

export function useBorderOutsideChange(
    props: CornerVisualizerProps
): ((borderImageSource: string, outsideValues: DeclarationMap) => void) | undefined {
    const { onChange, panelHost } = props;

    const BorderInitialConfig = useMemo(
        () => getDimensionInputDefaults(panelHost).borderInitialConfig,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [panelHost?.dimensionUnits?.defaults]
    );

    const getResetBorderImageValue = useCallback(
        (borderImageSource: string, outsideValues: DeclarationMap): DeclarationMap => {
            const { siteVarsDriver } = props;
            const wrapSiteColor = siteVarsDriver?.wrapSiteColor?.bind(siteVarsDriver) ?? DEFAULT_WRAP_SITE_COLOR;

            const widths = getSideWidth(outsideValues);

            let resetColor = BorderInitialConfig.fill;
            const parsedGradient = parseGradient(borderImageSource);
            if (parsedGradient && parsedGradient.colorStops.length > 0) {
                resetColor = wrapSiteColor(parsedGradient.colorStops[0].color);
            }
            return {
                'border-top': `${widths.top} ${BorderInitialConfig.style} ${resetColor}`,
                'border-right': `${widths.right} ${BorderInitialConfig.style} ${resetColor}`,
                'border-bottom': `${widths.bottom} ${BorderInitialConfig.style} ${resetColor}`,
                'border-left': `${widths.left} ${BorderInitialConfig.style} ${resetColor}`,
                ...EMPTY_IMAGE_BORDER_CHANGE_VALUE,
            };
        },
        [props, BorderInitialConfig]
    );

    return onChange
        ? (borderImageSource: string, outsideValues: DeclarationMap) => {
              onChange(
                  controllerToVisualizerChange(
                      getResetBorderImageValue(borderImageSource, outsideValues) as CornerDeclarationMap,
                      props
                  )
              );
          }
        : undefined;
}
