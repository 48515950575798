(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"), require("lodash"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-dom", "lodash"], factory);
	else if(typeof exports === 'object')
		exports["StylablePanel"] = factory(require("react"), require("react-dom"), require("lodash"));
	else
		root["StylablePanel"] = factory(root["React"], root["ReactDOM"], root["_"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__90359__, __WEBPACK_EXTERNAL_MODULE__24318__, __WEBPACK_EXTERNAL_MODULE__17607__) => {
return 