import {
    CSSCodeAst,
    ShorthandCloser,
    Backgrounds,
    OpenedBackgroundShorthand as OpenedBackgroundShorthandGeneric,
    createCssValueAST,
    valueTextNode,
    getOpenedNode,
    getOpenedNodeValue,
    shorthandCloserTemplate,
    createShorthandCloser,
    fixAstNodesPositions,
    INITIAL_BACKGROUND_COLOR,
    INITIAL_IMAGE_SOURCE,
} from '@wix/shorthands-opener';

import type { EvalDeclarationValue } from '@wix/stylable-panel-drivers';

import type { BackgroundDeclaration, BackgroundDeclarations, BackgroundShorthandLayer } from './background-types';
import type {
    OriginNode,
    ParseShorthandAPI,
    OpenedShorthandValue,
    SimpleOpenedShorthand,
    DeclarationValue,
} from '../../declaration-types';
import {
    getShorthandOpener,
    evalOpenedDeclarationValue,
    sanitizeOpenedDeclaration,
    splitShorthandLayers,
} from '../../utils';
import { formattersPreOpen, formattersPostOpen } from '../../utils/formatter-utils';

export type OpenedBackgroundShorthand = OpenedBackgroundShorthandGeneric<OriginNode>;
type BackgroundShorthandCloser = ShorthandCloser<OriginNode, Backgrounds, OpenedBackgroundShorthand>;

// TODO: Extract to @wix/shorthands-opener? safeOpenBackgroundShorthand?
export const openBackgroundShorthand = (
    shorthandValue: BackgroundDeclaration,
    shorthandApi: ParseShorthandAPI,
    evalDeclarationValue?: EvalDeclarationValue,
    stringifyExpression?: (v: OriginNode) => string,
    wrapShorthandLayers: (layers: BackgroundDeclarations) => BackgroundDeclarations = (layers) => layers,
    formatters?: string[]
): OpenedBackgroundShorthand => {
    const opener = getShorthandOpener('background');
    const shorthandLayers = wrapShorthandLayers(
        splitShorthandLayers(sanitizeOpenedDeclaration(shorthandValue), shorthandApi)
    );
    const openedLayers = shorthandLayers.map((layer) => {
        try {
            return opener(
                formattersPreOpen(
                    evalDeclarationValue
                        ? evalOpenedDeclarationValue(layer.value, evalDeclarationValue, stringifyExpression)
                        : layer.value,
                    formatters
                ),
                shorthandApi
            );
        } catch (e) {
            console.warn(e);
            return opener(createCssValueAST(INITIAL_BACKGROUND_COLOR), shorthandApi);
        }
    });

    const getOpenedLayer = ({ layers: [layer] }: OpenedBackgroundShorthand) =>
        (Object.keys(layer) as Array<keyof BackgroundShorthandLayer>).reduce((openedLayer, prop) => {
            (openedLayer[prop] as OpenedShorthandValue) = formattersPostOpen(layer, prop, formatters);
            return openedLayer;
        }, {} as BackgroundShorthandLayer);

    return {
        color: openedLayers[openedLayers.length - 1].color,
        layers: openedLayers.map(getOpenedLayer),
    };
};

// TODO: Allow custom closers from @wix/shorthands-opener
export const closeBackgroundShorthand: BackgroundShorthandCloser = (opened, api, detachExpression) => {
    const backgroundLayerCloser = createShorthandCloser<OriginNode, Backgrounds>(
        shorthandCloserTemplate<Backgrounds>`${'background-repeat'} ${'background-origin'} ${'background-clip'} ${'background-position'}/${'background-size'} ${'background-attachment'} ${'background-image'}`
    );
    let nonColorLayers: DeclarationValue = [];
    opened.layers.forEach((layer, index) => {
        if (index !== opened.layers.length - 1 || layer['background-image'].value.text !== INITIAL_IMAGE_SOURCE) {
            const closedLayer = backgroundLayerCloser(
                layer as unknown as SimpleOpenedShorthand<Backgrounds>,
                api,
                detachExpression
            );

            nonColorLayers = nonColorLayers.concat(closedLayer);
        }
        nonColorLayers.push({ ...valueTextNode(','), type: ',' });
    });
    nonColorLayers.pop();
    const colorOpenedNode = getOpenedNode(opened.color, api, detachExpression);
    if (getOpenedNodeValue(colorOpenedNode, api) !== INITIAL_BACKGROUND_COLOR) {
        nonColorLayers = nonColorLayers.concat(colorOpenedNode);
    } else if (
        nonColorLayers[nonColorLayers.length - 1] &&
        (nonColorLayers[nonColorLayers.length - 1] as CSSCodeAst).type === ','
    ) {
        nonColorLayers.pop();
    }
    return fixAstNodesPositions(nonColorLayers, api);
};
