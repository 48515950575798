import React from 'react';

export interface IconProps {
    className?: string;
    style?: React.CSSProperties;
}

export interface DragHandleProps extends IconProps {
    onMouseDown?: React.MouseEventHandler<SVGElement>;
    'data-key'?: string;
}

export const DragHandle: React.FC<DragHandleProps> = ({ className, style, onMouseDown, 'data-key': dataKey }) => (
    <svg className={className} style={style} onMouseDown={onMouseDown} data-key={dataKey} version="1.1">
        <g stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd">
            <path d="M6,9 L8,9 L8,11 L6,11 L6,9 Z M3,9 L5,9 L5,11 L3,11 L3,9 Z M0,9 L2,9 L2,11 L0,11 L0,9 Z M6,6 L8,6 L8,8 L6,8 L6,6 Z M3,6 L5,6 L5,8 L3,8 L3,6 Z M0,6 L2,6 L2,8 L0,8 L0,6 Z M6,3 L8,3 L8,5 L6,5 L6,3 Z M3,3 L5,3 L5,5 L3,5 L3,3 Z M0,3 L2,3 L2,5 L0,5 L0,3 Z M6,0 L8,0 L8,2 L6,2 L6,0 Z M3,0 L5,0 L5,2 L3,2 L3,0 Z M0,0 L2,0 L2,2 L0,2 L0,0 Z" />
        </g>
    </svg>
);

export const Add: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="31" height="31" viewBox="0 0 31 31">
        <circle cx="15.5" cy="15.5" r="14.5" />
        <path d="M16 15h4v1h-4v4h-1v-4h-4v-1h4v-4h1z" fill="currentColor" />
    </svg>
);

export const AddSkin: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="22" height="22" viewBox="0 0 22 22">
        <circle cx="11" cy="11" r="10.5" />
        <path d="M4 0L4 8M0 4L8 4" transform="translate(7 7)" stroke="currentColor" />
    </svg>
);

export const BorderIcon: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20">
        <path
            d="M8 5h4V3H8v2zm0 12h4v-2H8v2zm7-5h2V8h-2v4zm-1 3v2c1.654 0 3-1 3-3h-2c0 .672-.448 1-1 1zM3 6h2c0-.656.448-1 1-1V3C4.346 3 3 4 3 6zm2 8H3c0 2 1.346 3 3 3v-2c-.552 0-1-.313-1-1zm-2-2h2V8H3v4zm12.004-6H17c0-2-1-3-3-3v1.997c.555 0 1.004.449 1.004 1.003z"
            fill="currentColor"
        />
    </svg>
);

export const CornerIcon: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20">
        <path
            d="M15 14a1 1 0 0 1-1 1h-3v2h3a3 3 0 0 0 3-3v-3h-2v3zM3 6v3h2V6c0-.55.45-1 1-1h3V3H6a3 3 0 0 0-3 3zm11-3h-3v2h3a1 1 0 0 1 1 1v3h2V6a3 3 0 0 0-3-3zM5 14v-3H3v3a3 3 0 0 0 3 3h3v-2H6c-.55 0-1-.45-1-1z"
            fill="currentColor"
        />
    </svg>
);

export const GradientKnob: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="18" height="18">
        <defs>
            <filter
                id="vpsdutwcua"
                width="155.6%"
                height="155.6%"
                x="-27.8%"
                y="-22.2%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5" />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.16862746 0 0 0 0 0.3372549 0 0 0 0 0.44705883 0 0 0 0.43 0"
                />
            </filter>
            <path
                id="mshlt9g9fb"
                d="M9 0c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9zm0 5C6.79 5 5 6.79 5 9s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <use fill="#000" filter="url(#vpsdutwcua)" href="#mshlt9g9fb" />
            <use fill="#FFF" href="#mshlt9g9fb" />
        </g>
    </svg>
);

export const GradientKnobOver: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="18" height="18" viewBox="3 2 18 18">
        <defs>
            <filter
                id="6gc64tprka"
                width="155.6%"
                height="155.6%"
                x="-27.8%"
                y="-22.2%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5" />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.16862746 0 0 0 0 0.3372549 0 0 0 0 0.44705883 0 0 0 0.43 0"
                />
            </filter>
            <filter id="xgclosqb6c" width="200%" height="200%" x="-50%" y="-40%" filterUnits="objectBoundingBox">
                <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5" />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.16862746 0 0 0 0 0.3372549 0 0 0 0 0.44705883 0 0 0 0.43 0"
                />
            </filter>
            <filter
                id="meqwjeyy7e"
                width="171.4%"
                height="171.4%"
                x="-35.7%"
                y="-28.6%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5" />
                <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.168627451 0 0 0 0 0.337254902 0 0 0 0 0.447058824 0 0 0 0.43 0"
                />
            </filter>
            <path
                id="j3t6f6jpjb"
                d="M9 0c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9zm0 5C6.79 5 5 6.79 5 9s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"
            />
            <path
                id="1hr4ncni8d"
                d="M7 2c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5zm0 3c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2z"
            />
            <linearGradient id="qy6q5zerlg" x1="50%" x2="50%" y1="100%" y2="0%">
                <stop offset="0%" stopColor="#00D245" />
                <stop offset="100%" stopColor="#FFF" stopOpacity="0" />
            </linearGradient>
            <circle id="7kjb3kiegf" cx="7" cy="7" r="7" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g transform="translate(-232 -189) translate(235 191)">
                        <use fill="#FFF" href="#j3t6f6jpjb" />
                    </g>
                    <g>
                        <g transform="translate(-232 -189) translate(235 191) translate(2 2)">
                            <use fill="#000" filter="url(#xgclosqb6c)" href="#1hr4ncni8d" />
                            <use fill="#FFF" href="#1hr4ncni8d" />
                        </g>
                        <g transform="translate(-232 -189) translate(235 191) translate(2 2)">
                            <use fill="#000" filter="url(#meqwjeyy7e)" href="#7kjb3kiegf" />
                            <circle
                                cx="7"
                                cy="7"
                                r="6"
                                fill="url(#qy6q5zerlg)"
                                fillOpacity="0"
                                stroke="#B1DDF8"
                                strokeWidth="2"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const BackArrow: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="5" height="7">
        <path
            d="M4.264 5.617l-.644.766L.223 3.524 3.678.617l.644.766-2.545 2.141z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
);

export const ForwardArrow: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={{ transform: 'rotate(180deg)', ...style }} width="5" height="7">
        <path
            d="M4.264 5.617l-.644.766L.223 3.524 3.678.617l.644.766-2.545 2.141z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
);

export const DownArrow: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} viewBox="135.1 -517.4 1024 1024" width="12" height="12">
        <path
            fill="currentColor"
            d="M137.3-195c0 17.4 7.4 34.7 19.8 47.1l441.4 409.1c27.3 24.8 71.9 24.8 99.2 0l438.9-429c27.3-24.8 27.3-66.9 0-91.7-27.3-24.8-71.9-24.8-99.2 0L648.1 122.3 256.3-242.2c-27.3-24.8-71.9-24.8-99.2 0-12.4 14.9-19.8 29.8-19.8 47.2z"
        />
    </svg>
);

export const SmallDownArrow: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="9px" height="7px" viewBox="0 0 11 7">
        <g>
            <path
                d="M10.073 1.69L9.345.926 5.073 5.4.802.927l-.729.762 5 5.238z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </g>
    </svg>
);

export const MoreStates: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="24" height="24" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M13.75,9 L17.25,12.5 L13.75,16 L13,15.251 L15.751,12.5 L13,9.751 L13.75,9 Z M9.75,9 L13.25,12.5 L9.75,16 L9,15.251 L11.751,12.5 L9,9.751 L9.75,9 Z"
        />
    </svg>
);

export const MoreActions: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="24" height="24" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M9,11 L9,13 L7,13 L7,11 L9,11 Z M13,11 L13,13 L11,13 L11,11 L13,11 Z M17,11 L17,13 L15,13 L15,11 L17,11 Z"
        />
    </svg>
);

export const Checkmark: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} viewBox="0 0 41 32">
        <path d="M0.169 17.815c0.169 1.098 0.76 2.111 1.689 2.871l14.269 10.385c1.942 1.435 4.644 1.013 6.079-0.844l18.069-23.303c1.435-1.858 1.098-4.559-0.844-5.995s-4.644-1.098-6.164 0.844l-15.367 19.842-10.723-7.852c-1.942-1.435-4.644-1.013-6.164 0.844-0.76 0.929-1.013 2.111-0.844 3.208z" />
    </svg>
);

export const ArrowTree: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="18" height="18" viewBox="0 0 18 18">
        <polygon fillRule="evenodd" points="9 7 13 11 5 11" transform="rotate(90 9 9)" />
    </svg>
);

export const Close: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="21" height="21" viewBox="0 0 25 25">
        <path
            d="M11.793 12.5L8.146 8.854 7.793 8.5l.707-.707.354.353 3.646 3.647 3.646-3.647.354-.353.707.707-.353.354-3.647 3.646 3.647 3.646.353.354-.707.707-.354-.353-3.646-3.647-3.646 3.647-.354.353-.707-.707.353-.354 3.647-3.646z"
            fill="currentColor"
        />
    </svg>
);

export const Preview: React.FC<IconProps> = ({ className, style }) => (
    <svg
        className={className}
        style={style}
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 28 21"
        width="25"
        height="11"
        fill="currentColor"
    >
        <path
            d="M27.888,10.836 C27.716,11.252 23.563,21.011 14.016,21.011 C4.469,21.011 0.316,11.252 0.144,10.836 C0.144,10.836 0.009,10.510 0.009,10.510 C0.009,10.510 0.144,10.184 0.144,10.184 C0.316,9.769 4.469,0.011 14.016,0.011 C23.563,0.011 27.716,9.769 27.888,10.184 C27.888,10.184 28.024,10.510 28.024,10.510 C28.024,10.510 27.888,10.836 27.888,10.836 ZM14.016,1.685 C6.469,1.685 2.588,8.876 1.804,10.509 C2.591,12.142 6.488,19.337 14.016,19.337 C21.563,19.337 25.445,12.146 26.229,10.511 C25.442,8.879 21.544,1.685 14.016,1.685 ZM14.016,15.532 C11.304,15.532 9.097,13.279 9.097,10.510 C9.097,7.741 11.304,5.489 14.016,5.489 C16.729,5.489 18.935,7.741 18.935,10.510 C18.935,13.279 16.729,15.532 14.016,15.532 ZM14.016,7.162 C12.208,7.162 10.737,8.664 10.737,10.510 C10.737,12.357 12.208,13.858 14.016,13.858 C15.825,13.858 17.296,12.357 17.296,10.510 C17.296,8.664 15.825,7.162 14.016,7.162 Z"
            fillRule="evenodd"
        />
    </svg>
);

export const Refresh: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="12" height="12" viewBox="0 0 12 12">
        <path
            fill="white"
            fillRule="evenodd"
            d="M11.14 12V9.14S9.29 11.9 5.81 11.9C2.61 11.9.01 9.43.01 6h.86c0 2.57 2.22 5.04 4.94 5.04 1.8 0 3.81-.99 4.69-2.54l-2.79-.04v-.75H12V12h-.86zM6.2.96c-1.8 0-3.82.99-4.69 2.54l2.78.04v.75H0V0h.86v2.86S2.72.1 6.2.1C9.39.1 12 2.57 12 6h-.86C11.14 3.43 8.92.96 6.2.96z"
        />
    </svg>
);

export const SelectionBadge: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} viewBox="-1 -1 24 24">
        <g fill="none" fillRule="evenodd">
            <path
                fill="#0C9EEB"
                stroke="#FFF"
                strokeWidth="2"
                d="M11 0c6.033 0 10.923 4.89 10.923 10.923S17.033 21.848 11 21.848.077 16.956.077 10.923C.077 4.891 4.967 0 11 0"
            />
            <path
                fill="#FFF"
                d="M7.009 11.279a.828.828 0 0 0 .334.551l2.78 2.027a.865.865 0 0 0 1.19-.164l3.513-4.53a.822.822 0 0 0-.164-1.169.865.865 0 0 0-1.196.16l-3 3.87-2.101-1.53a.865.865 0 0 0-1.195.169.819.819 0 0 0-.161.616"
            />
        </g>
    </svg>
);

export const ImageSvg: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} viewBox="0 0 72 72" version="1.1">
        <path
            d="M36.1799302,57 C47.678518,57 57,47.5865598 57,36.0461162 C57,27.9422937 52.4005649,20.8889668 45.6818408,17.4223316 C44.2892507,17.1602079 42.8537963,16.9841249 41.3854461,17.0011329 C23.8928393,17.2072301 20.8165808,27.8802645 20.8165808,41.8198397 C20.8165808,53.2542332 15,57 15,57 L36.1799302,57 Z"
            fill="#0099EF"
        ></path>
        <path
            d="M24,20.1181048 C31.8709986,24.8684091 37.3353259,35.3319967 37.3353259,47.4054422 C37.3353259,50.73868 36.9131721,53.0854517 36.1448127,57 L36.3992886,57 C47.7767283,57 57,47.7929825 57,36.5034252 C57,25.2148466 47.7767283,16 36.3992886,16 C31.7437606,16 27.4502197,17.5335243 24,20.1181048 Z"
            fill="#CAEDFF"
        ></path>
        <path
            d="M53,23 C53.027,23.038 53.054,23.076 53.081,23.114 C53.054,23.076 53.027,23.038 53,23"
            fill="#4EB7F6"
        ></path>
        <path
            d="M55,26 C55.016,26.03 55.031,26.062 55.047,26.092 C55.031,26.062 55.016,26.03 55,26"
            fill="#4EB7F6"
        ></path>
        <path
            d="M57,31 C57.02,31.088 57.042,31.175 57.061,31.263 C57.042,31.175 57.02,31.088 57,31"
            fill="#4EB7F6"
        ></path>
        <path
            d="M55,27 C55.037,27.08 55.073,27.16 55.109,27.241 C55.073,27.16 55.037,27.08 55,27"
            fill="#4EB7F6"
        ></path>
        <path
            d="M56,30 C56.03,30.101 56.058,30.202 56.086,30.303 C56.058,30.202 56.03,30.101 56,30"
            fill="#4EB7F6"
        ></path>
        <path
            d="M54,25 C54.02,25.032 54.041,25.062 54.06,25.094 C54.041,25.062 54.02,25.031 54,25"
            fill="#4EB7F6"
        ></path>
        <path
            d="M57,33 C57.013,33.09 57.022,33.181 57.034,33.272 C57.022,33.181 57.013,33.09 57,33"
            fill="#4EB7F6"
        ></path>
        <path
            d="M51,21 C51.019,21.02 51.039,21.041 51.058,21.061 C51.039,21.041 51.019,21.02 51,21"
            fill="#4EB7F6"
        ></path>
        <path
            d="M57,34 C57.01,34.122 57.02,34.244 57.028,34.367 C57.02,34.244 57.01,34.122 57,34"
            fill="#4EB7F6"
        ></path>
        <path
            d="M36,57 C36.086,57.001 36.172,57 36.258,57 C36.172,57 36.087,57.001 36,57 L36,57 Z"
            fill="#D3EDFF"
        ></path>
        <path
            d="M54,25 C54.238,25.383 54.464,25.774 54.677,26.173 C54.464,25.774 54.238,25.383 54,25"
            fill="#D3EDFF"
        ></path>
        <path
            d="M46,17 C48.092,18.076 49.979,19.495 51.587,21.182 C49.979,19.494 48.093,18.076 46,17"
            fill="#D3EDFF"
        ></path>
        <path
            d="M51,21 C51.623,21.659 52.203,22.358 52.737,23.094 C52.204,22.358 51.623,21.659 51,21"
            fill="#D3EDFF"
        ></path>
        <path
            d="M53,24 C53.243,24.339 53.475,24.685 53.698,25.039 C53.475,24.685 53.242,24.339 53,24"
            fill="#D3EDFF"
        ></path>
        <path
            d="M56,30 C56.097,30.345 56.185,30.693 56.264,31.044 C56.185,30.693 56.097,30.345 56,30"
            fill="#D3EDFF"
        ></path>
        <path
            d="M57,34 C57.028,34.446 57.048,34.894 57.048,35.346 C57.048,34.894 57.028,34.446 57,34"
            fill="#D3EDFF"
        ></path>
        <path
            d="M57,33 C57.047,33.355 57.086,33.712 57.115,34.072 C57.086,33.712 57.047,33.355 57,33"
            fill="#D3EDFF"
        ></path>
        <path
            d="M55,26 C55.186,26.353 55.361,26.713 55.528,27.078 C55.362,26.713 55.186,26.353 55,26"
            fill="#D3EDFF"
        ></path>
        <path
            d="M55,27 C55.351,27.794 55.653,28.615 55.903,29.457 C55.653,28.615 55.35,27.795 55,27"
            fill="#D3EDFF"
        ></path>
        <path
            d="M57,31 C57.09,31.425 57.167,31.855 57.231,32.289 C57.167,31.855 57.09,31.425 57,31"
            fill="#D3EDFF"
        ></path>
        <path
            d="M36.4943237,55.1854248 C43.1113281,52.5917969 46.927002,46.1784234 47,38 C46.927002,29.2079708 42.4722293,22 34.4200154,22 C32.5024315,22 30.67699,22.4198978 29,23.1619499 C34.0995649,28.7642439 37.3358075,37.0774218 37.3358075,46.3670365 C37.3358075,49.0051116 36.4943237,55.1854248 36.4943237,55.1854248 Z"
            fill="#53CCF9"
        ></path>
        <path
            d="M47,35.5 C47,44.0600733 41.8507904,51 35.4995073,51 C29.1482243,51 24,44.0600733 24,35.5 C24,26.9399267 29.1482243,20 35.4995073,20 C41.8507904,20 47,26.9399267 47,35.5"
            fill="#CAEDFF"
        ></path>
        <path
            d="M53.9674852,25.1551681 C50.3004443,19.0667172 43.668945,15 36.0966655,15 C26.2935361,15 16,22.6926965 16,33.7536012 C21.9129034,33.7536012 32.9370454,33.7161991 39.6185861,33.7536012 C43.8500952,37.6171342 45.5985451,46.2125347 45.5985451,52.4283548 C45.5985451,53.299722 45.5394962,54.1569371 45.4384123,55 C52.2920959,51.5347485 57,44.3818549 57,36.1129644 C57,32.100834 55.8920812,28.3495072 53.9674852,25.1551681"
            fill="#00B7F9"
        ></path>
        <path
            d="M29,38 C29,38.5526992 28.5518423,39 28,39 C27.4473008,39 27,38.5526992 27,38 C27,37.4473008 27.4473008,37 28,37 C28.5518423,37 29,37.4473008 29,38"
            fill="#0099EF"
        ></path>
        <path
            d="M37,38 C37,38.5526992 36.5518423,39 36,39 C35.4473008,39 35,38.5526992 35,38 C35,37.4473008 35.4473008,37 36,37 C36.5518423,37 37,37.4473008 37,38"
            fill="#0099EF"
        ></path>
        <path
            d="M29,44 C29.626,44.8395203 30.1736309,46 32.501,46 C34.8283691,46 35.377,44.8395203 36.003,44"
            fill="#0099EF"
        ></path>
    </svg>
);

export const More: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="12px" height="4px" viewBox="0 0 17 5">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M 14.81 4.38C 13.8 4.38 12.97 3.54 12.97 2.5 12.97 1.46 13.8 0.63 14.81 0.63 15.83 0.63 16.66 1.46 16.66 2.5 16.66 3.54 15.83 4.38 14.81 4.38ZM 8.67 4.38C 7.65 4.38 6.83 3.54 6.83 2.5 6.83 1.46 7.65 0.63 8.67 0.63 9.69 0.63 10.51 1.46 10.51 2.5 10.51 3.54 9.69 4.38 8.67 4.38ZM 2.53 4.38C 1.51 4.38 0.69 3.54 0.69 2.5 0.69 1.46 1.51 0.63 2.53 0.63 3.55 0.63 4.37 1.46 4.37 2.5 4.37 3.54 3.55 4.38 2.53 4.38Z"
        />
    </svg>
);

export const Remove: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M16 16C16 17.104 15.104 18 14 18H9C7.896 18 7 17.104 7 16V7H16V16ZM10 5.5C10 5.229 10.24 5 10.525 5H12.392C12.693 5 13 5.252 13 5.5V6H10V5.5ZM14 6V5.5C14 4.701 13.248 4 12.392 4H10.525C9.684 4 9 4.673 9 5.5V6H5V7H6V16C6 17.657 7.343 19 9 19H14C15.657 19 17 17.657 17 16V7H18V6H14ZM13 15H14V9H13V15ZM11 15H12V9H11V15ZM9 15H10V9H9V15Z"
            fill="currentColor"
        />
    </svg>
);

export const DeleteIndicator: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="14" height="14" viewBox="0 0 14 14">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero" transform="translate(-289 -191) translate(289 191) translate(1 1)">
                <circle cx="6" cy="6" r="6.5" fill="#FFF" stroke="#EE5951" />
                <g fill="currentColor" transform="rotate(45 1.61 7.818)">
                    <rect width="1" height="6.857" x="3" rx=".5" />
                    <path
                        d="M3.429 0c.236 0 .428.192.428.429v6c0 .236-.192.428-.428.428-.237 0-.429-.192-.429-.428v-6C3 .192 3.192 0 3.429 0z"
                        transform="rotate(90 3.429 3.429)"
                    />
                </g>
            </g>
            <path d="M0 0H14V14H0z" transform="translate(-289 -191) translate(289 191)" />
        </g>
    </svg>
);

export const FillIcon: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20">
        <path
            d="M9.896 14C8.042 14 7.3 12.881 7.3 11.5c0-1.38.679-2.5 2.596-2.5v5zM10.5 3S5 7.121 5 11.709C5 14.771 7.517 17 10.523 17 13.53 17 16 14.771 16 11.709 16 7.121 10.5 3 10.5 3z"
            fill="currentColor"
        />
    </svg>
);

export const FillScale: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="72" height="72" viewBox="0 0 72 72">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <g transform="translate(-55 -320) translate(55 320)">
                    <rect width="72" height="72" fill="currentColor" rx="8" />
                    <rect width="36" height="36" x="18" y="18" fill="#4EB7F5" rx="2.92" />
                    <path
                        fill="#D3EDFF"
                        d="M42.894 35l.13.01c.301.043.572.21.746.46L49 43l-13.001-.001L36 43H24l6.74-5.6c.239-.195.548-.282.853-.24.305.041.58.208.757.46l2.472 3.644 7.358-6.014c.202-.166.455-.254.714-.25zM28 29c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const FillScaleSelected: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="72" height="72" viewBox="0 0 72 72">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <g transform="translate(-55 -510) translate(55 510)">
                    <rect width="72" height="72" fill="#3899EC" rx="8" />
                    <rect width="36" height="36" x="18" y="18" fill="#D3EDFF" rx="2.92" />
                    <path
                        fill="#7FCCF7"
                        d="M42.894 35l.13.01c.301.043.572.21.746.46L49 43H24l6.7-5.63c.239-.195.548-.282.853-.24.305.041.58.208.757.46l2.49 3.66 7.38-6c.235-.194.541-.28.843-.24zM28 29c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const FitScale: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="72" height="72" viewBox="0 0 72 72">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <g transform="translate(-154 -320) translate(154 320)">
                    <rect width="72" height="72" fill="currentColor" rx="8" />
                    <rect width="36" height="36" x="18" y="18" fill="#4EB7F5" opacity=".4" rx="3" />
                    <g>
                        <path fill="#4EB7F5" d="M0 0H36V23H0z" transform="translate(18 25)" />
                        <path
                            fill="#D3EDFF"
                            d="M23.873 10.997l.11.01c.259.036.487.183.627.403L29 18H15.27v-.001L8 18l5.65-4.9c.197-.173.46-.25.719-.214.259.036.49.184.631.404l2.064 3.188 6.206-5.258c.167-.148.383-.226.603-.223zM11.5 7c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5S10 9.328 10 8.5 10.672 7 11.5 7z"
                            transform="translate(18 25)"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const FitScaleSelected: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="72" height="72" viewBox="0 0 72 72">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <g transform="translate(-154 -510) translate(154 510)">
                    <rect width="72" height="72" fill="#3899EC" rx="8" />
                    <rect width="36" height="36" x="18" y="18" fill="#7FCCF7" rx="3" />
                    <path fill="#D3EDFF" d="M18 25H54V48H18z" />
                    <path
                        fill="#7FCCF7"
                        d="M41.873 35.997l.11.01c.259.036.487.183.627.403L47 43H26l5.65-4.9c.197-.173.46-.25.719-.214.259.036.49.184.631.404l2.07 3.21 6.2-5.28c.195-.172.456-.25.714-.214zM29.5 32c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const IconIcon: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="20px" height="20px" viewBox="0 0 20 20">
        <path
            d="M17,6 L17,17 L6,17 L6.00011738,11.9775891 C6.16476026,11.9924223 6.3314952,12 6.5,12 C9.53756612,12 12,9.53756612 12,6.5 C12,6.3314952 11.9924223,6.16476026 11.9775891,6.00011738 L17,6 Z"
            fill="currentColor"
        />
        <circle id="Oval-Copy-2" fill="currentColor" cx="6.5" cy="6.5" r="3.5" />
    </svg>
);

export const LayoutIcon: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20">
        <path
            d="M14 3a3 3 0 0 1 3 3v1H3V6c0-1.657 1.421-3 3.078-3H14zM8 17V9h9v5a3 3 0 0 1-3 3H8zm-5-3V9h3v8a3 3 0 0 1-3-3z"
            fill="currentColor"
        />
    </svg>
);

export const ShadowIcon: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="#3899ec">
        <path
            d="M13 11V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2zm2-6v7.5c0 1.375-1.125 2.5-2.5 2.5H6v2h7a4 4 0 0 0 4-4V5h-2z"
            fill="currentColor"
        />
    </svg>
);

export const TextIcon: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="14px" height="14px" viewBox="0 0 14 14" version="1.1">
        <defs>
            <path
                d="M15,5.05 L7,5.05 C5.35,5.05 4,6.4 4,8.05 L4,10.05 L6,10.05 L6,8.05 C6,7.498 6.447,7.05 7,7.05 L10,7.05 L10,16.05 L8,16.05 L8,18.05 L14,18.05 L14,16.05 L12,16.05 L12,7.05 L15,7.05 C15.553,7.05 16,7.498 16,8.05 L16,10.05 L18,10.05 L18,8.05 C18,6.393 16.657,5.05 15,5.05"
                id="text-icon-path"
            />
        </defs>
        <g transform="translate(-4.000000, -4.000000)">
            <mask id="text-icon-mask" fill="white">
                <use xlinkHref="#text-icon-path" />
            </mask>
            <use xlinkHref="#text-icon-path" />
            <g mask="url(#text-icon-mask)" fill="currentColor">
                <g transform="translate(1.000000, 1.000000)">
                    <rect x="0" y="0" width="20" height="20" />
                </g>
            </g>
        </g>
    </svg>
);

export const TileScale: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="72" height="72" viewBox="0 0 72 72">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <g transform="translate(-253 -320) translate(253 320)">
                    <rect width="72" height="72" fill="currentColor" rx="8" />
                    <rect width="36" height="36" x="18" y="18" fill="#4EB7F5" rx="3" />
                    <path
                        fill="#D3EDFF"
                        d="M9.794 24.997l.094.005c.156.02.297.103.392.228L13 29H0l3.48-2.8c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.292 1.83 3.838-3.02c.1-.077.22-.12.344-.123zm16 0l.094.005c.156.02.297.103.392.228L29 29H16l3.48-2.8c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.292 1.83 3.838-3.02c.1-.077.22-.12.344-.123zM2 22c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm16 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-8.206-8.003l.094.005c.156.02.297.103.392.228L13 18H4.52v-.001L0 18l3.48-2.8c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.293 1.829L9.45 14.12c.1-.077.22-.12.344-.123zm16 0l.094.005c.156.02.297.103.392.228L29 18h-8.48v-.001L16 18l3.48-2.8c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.293 1.829 3.837-3.019c.1-.077.22-.12.344-.123zM2 11c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm16 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zM9.794 2.997l.094.005c.156.02.297.103.392.228L13 7l-6.781-.001L6.22 7H0l3.48-2.8c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.292 1.829L9.45 3.12c.1-.077.22-.12.344-.123zm16 0l.094.005c.156.02.297.103.392.228L29 7l-6.781-.001.001.001H16l3.48-2.8c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.292 1.829L25.45 3.12c.1-.077.22-.12.344-.123zM2 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm16 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
                        transform="translate(22 22)"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const TileScaleSelected: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="72" height="72" viewBox="0 0 72 72">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <g transform="translate(-253 -510) translate(253 510)">
                    <rect width="72" height="72" fill="#3899EC" rx="8" />
                    <rect width="36" height="36" x="18" y="18" fill="#D3EDFF" rx="3" />
                    <path
                        fill="#7FCCF7"
                        d="M31.794 46.997l.094.005c.156.02.297.103.392.228L35 51H22l3.48-2.82c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.29 1.83 3.84-3c.124-.096.282-.138.438-.118zm16 0l.094.005c.156.02.297.103.392.228L51 51H38l3.48-2.82c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.29 1.83 3.84-3c.124-.096.282-.138.438-.118zM24 44c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm16 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-8.206-8.003l.094.005c.156.02.297.103.392.228L35 40H22l3.48-2.82c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.29 1.83 3.84-3c.124-.096.282-.138.438-.118zm16 0l.094.005c.156.02.297.103.392.228L51 40H38l3.48-2.82c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.29 1.83 3.84-3c.124-.096.282-.138.438-.118zM24 33c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm16 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-8.206-8.003l.094.005c.156.02.297.103.392.228L35 29H22l3.48-2.82c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.29 1.83 3.84-3c.124-.096.282-.138.438-.118zm16 0l.094.005c.156.02.297.103.392.228L51 29H38l3.48-2.82c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.29 1.83 3.84-3c.124-.096.282-.138.438-.118zM24 22c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm16 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const TabArrow: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="12" height="12" viewBox="0 0 12 12">
        <path d="M6.052 8.98L11 3.992H1L5.95 8.98a.073.073 0 0 0 .102 0" fill="currentColor" />
    </svg>
);

export const CustomScale: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="72" height="72" viewBox="0 0 72 72">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <g transform="translate(-352 -320) translate(352 320)">
                    <rect width="72" height="72" fill="currentColor" rx="8" />
                    <rect width="36" height="36" x="18" y="18" fill="#4EB7F5" rx="3" />
                    <path
                        fill="#D3EDFF"
                        d="M54 33.92V46H26l11.76-10c.408-.36.95-.529 1.491-.464.54.065 1.028.358 1.339.804L44.35 42 54 33.92zM33 25c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z"
                    />
                    <path
                        fill="#4EB7F5"
                        d="M51 19c1.105 0 2 .895 2 2v30c0 1.105-.895 2-2 2H21c-1.105 0-2-.895-2-2V21c0-1.105.895-2 2-2h30m0-1H21c-1.657 0-3 1.343-3 3v30c0 1.657 1.343 3 3 3h30c1.657 0 3-1.343 3-3V21c0-1.657-1.343-3-3-3z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const CustomScaleSelected: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="72" height="72" viewBox="0 0 72 72">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <g transform="translate(-352 -510) translate(352 510)">
                    <rect width="72" height="72" fill="#3899EC" rx="8" />
                    <rect width="36" height="36" x="18" y="18" fill="#D3EDFF" rx="3" />
                    <path
                        fill="#7FCCF7"
                        d="M54 33.92V46H26l11.76-10c.408-.36.95-.529 1.491-.464.54.065 1.028.358 1.339.804L44.35 42 54 33.92zM33 25c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z"
                    />
                    <path
                        fill="#D3EDFF"
                        d="M51 19c1.105 0 2 .895 2 2v30c0 1.105-.895 2-2 2H21c-1.105 0-2-.895-2-2V21c0-1.105.895-2 2-2h30m0-1H21c-1.657 0-3 1.343-3 3v30c0 1.657 1.343 3 3 3h30c1.657 0 3-1.343 3-3V21c0-1.657-1.343-3-3-3z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const Duplicate: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M17 14.5C17 14.776 16.776 15 16.5 15H10.5C10.224 15 10 14.776 10 14.5V6.5C10 6.224 10.224 6 10.5 6H13V9.5C13 9.776 13.224 10 13.5 10H17V14.5ZM14 17.5C14 17.776 13.776 18 13.5 18H7.5C7.224 18 7 17.776 7 17.5V9.5C7 9.224 7.224 9 7.5 9H9V14.5C9 15.328 9.672 16 10.5 16H14V17.5ZM14 6.5L16.5 9H14V6.5ZM14 5H10.5C9.672 5 9 5.672 9 6.5V8H7.5C6.672 8 6 8.672 6 9.5V17.5C6 18.328 6.672 19 7.5 19H13.5C14.328 19 15 18.328 15 17.5V16H16.5C17.328 16 18 15.328 18 14.5V9L14 5Z"
            fill="currentColor"
        />
    </svg>
);

export const MoveUp: React.FC<IconProps> = ({ className, style }) => (
    <svg
        className={className}
        style={{ top: '5px', left: '-3px', marginRight: '5px', ...style }}
        width="22px"
        height="22px"
        viewBox="0 0 22 22"
        version="1.1"
    >
        <defs>
            <path
                id="move-up-path-1"
                d="M1,1 L4,1 L4,5 L8,5 L8,7 L9,7 L9,4 L5,0 L1,0 C0.44771525,1.01453063e-16 -6.76353751e-17,0.44771525 0,1 L0,12 C6.76353751e-17,12.5522847 0.44771525,13 1,13 L8,13 L8,12 L1,12 L1,1 Z M5,1.5 L7.5,4 L5,4 L5,1.5 Z M8,9.5 L11.5,6 L15,9.5 L14.5,10 L12,7.5 L12,13 L11,13 L11,7.5 L8.5,10 L8,9.5 Z"
            />
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(4.000000, 4.000000)">
                <mask id="move-up-mask" fill="white" />
                <path
                    id="move-up-path-2"
                    fill="currentColor"
                    fillRule="nonzero"
                    d="M1,1 L4,1 L4,5 L8,5 L8,7 L9,7 L9,4 L5,0 L1,0 C0.44771525,1.01453063e-16 -6.76353751e-17,0.44771525 0,1 L0,12 C6.76353751e-17,12.5522847 0.44771525,13 1,13 L8,13 L8,12 L1,12 L1,1 Z M5,1.5 L7.5,4 L5,4 L5,1.5 Z M8,9.5 L11.5,6 L15,9.5 L14.5,10 L12,7.5 L12,13 L11,13 L11,7.5 L8.5,10 L8,9.5 Z"
                />
                <g mask="url(#move-up-mask)" fill="currentColor">
                    <g transform="translate(-5.000000, -5.000000)">
                        <rect x="0" y="0" width="22" height="22" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const MoveDown: React.FC<IconProps> = ({ className, style }) => (
    <svg
        className={className}
        style={{ top: '5px', left: '-3px', marginRight: '5px', ...style }}
        width="22px"
        height="22px"
        viewBox="0 0 22 22"
        version="1.1"
    >
        <defs>
            <path
                id="move-down-path-1"
                d="M1,1 L4,1 L4,5 L8,5 L8,7 L9,7 L9,4 L5,0 L1,0 C0.44771525,1.01453063e-16 -6.76353751e-17,0.44771525 0,1 L0,12 C6.76353751e-17,12.5522847 0.44771525,13 1,13 L8,13 L8,12 L1,12 L1,1 Z M5,1.5 L7.5,4 L5,4 L5,1.5 Z M15,9.5 L11.5,13 L8,9.5 L8.5,9 L11,11.5 L11,6 L12,6 L12,11.5 L14.5,9 L15,9.5 Z"
            />
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(4.000000, 4.000000)">
                <mask id="move-down-mask" fill="white" />
                <path
                    id="move-down-path-2"
                    fill="currentColor"
                    fillRule="nonzero"
                    d="M1,1 L4,1 L4,5 L8,5 L8,7 L9,7 L9,4 L5,0 L1,0 C0.44771525,1.01453063e-16 -6.76353751e-17,0.44771525 0,1 L0,12 C6.76353751e-17,12.5522847 0.44771525,13 1,13 L8,13 L8,12 L1,12 L1,1 Z M5,1.5 L7.5,4 L5,4 L5,1.5 Z M15,9.5 L11.5,13 L8,9.5 L8.5,9 L11,11.5 L11,6 L12,6 L12,11.5 L14.5,9 L15,9.5 Z"
                />
                <g mask="url(#move-down-mask)" fill="currentColor">
                    <g transform="translate(-5.000000, -5.000000)">
                        <rect x="0" y="0" width="22" height="22" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const ShowLayer: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M11.5 14.572C10.402 14.572 9.51 13.642 9.51 12.5C9.51 11.357 10.402 10.427 11.5 10.427C12.598 10.427 13.49 11.357 13.49 12.5C13.49 13.642 12.598 14.572 11.5 14.572ZM11.5 9.391C9.854 9.391 8.515 10.785 8.515 12.5C8.515 14.214 9.854 15.608 11.5 15.608C13.146 15.608 14.485 14.214 14.485 12.5C14.485 10.785 13.146 9.391 11.5 9.391ZM11.5 17.964C6.932 17.964 4.567 13.51 4.089 12.499C4.565 11.488 6.92 7.036 11.5 7.036C16.068 7.036 18.433 11.489 18.911 12.5C18.435 13.512 16.08 17.964 11.5 17.964ZM19.918 12.298C19.813 12.041 17.293 6 11.5 6C5.707 6 3.187 12.041 3.082 12.298L3 12.5L3.082 12.701C3.187 12.958 5.707 19 11.5 19C17.293 19 19.813 12.958 19.918 12.701L20 12.5L19.918 12.298Z"
            fill="currentColor"
        />
    </svg>
);

export interface HideLayerProps extends IconProps {
    onClick?: () => void;
}

export const HideLayer: React.FC<HideLayerProps> = ({ className, style, onClick }) => (
    <svg className={className} style={style} onClick={onClick} width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M13.986 14.214C14.3 13.722 14.485 13.133 14.485 12.5C14.485 10.786 13.146 9.39103 11.5 9.39103C10.848 9.39103 10.25 9.61603 9.759 9.98603L7.863 8.09103C8.867 7.46703 10.076 7.03603 11.5 7.03603C16.068 7.03603 18.433 11.49 18.911 12.5C18.63 13.097 17.689 14.887 16.03 16.258L13.986 14.214ZM10.494 10.722C10.79 10.539 11.132 10.427 11.5 10.427C12.597 10.427 13.49 11.357 13.49 12.5C13.49 12.854 13.396 13.182 13.246 13.473L10.494 10.722ZM12.815 14.043C12.464 14.368 12.006 14.572 11.5 14.572C10.402 14.572 9.509 13.643 9.509 12.5C9.509 12.003 9.685 11.552 9.967 11.195L12.815 14.043ZM4.089 12.499C4.386 11.867 5.419 9.89003 7.269 8.49703L9.251 10.479C8.799 11.024 8.514 11.725 8.514 12.5C8.514 14.214 9.853 15.609 11.5 15.609C12.288 15.609 13 15.283 13.534 14.762L15.461 16.689C14.395 17.427 13.084 17.964 11.5 17.964C6.931 17.964 4.567 13.51 4.089 12.499ZM20 12.5L19.918 12.298C19.813 12.041 17.293 6.00003 11.5 6.00003C9.743 6.00003 8.292 6.56003 7.113 7.34103L5 5.22803L4.5 5.72803L6.534 7.76203C4.228 9.56703 3.149 12.133 3.082 12.298L3 12.5L3.082 12.702C3.186 12.959 5.707 19 11.5 19C13.421 19 14.974 18.331 16.207 17.434L18.5 19.728L19 19.228L16.769 16.996C18.868 15.213 19.853 12.86 19.918 12.702L20 12.5Z"
            fill="currentColor"
        />
    </svg>
);

export const Solid: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="16" height="16" viewBox="0 0 20 20">
        <g fill="none" fillRule="evenodd">
            <path
                fill="#162D3D"
                d="M3 0h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm.333 3.333v13.334h13.334V3.333H3.333z"
            />
            <path fill="#162D3D" d="M0 0h10v10H0z" transform="translate(5 5)" />
        </g>
    </svg>
);

export const Gradient: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="16" height="16" viewBox="0 0 20 20">
        <defs>
            <linearGradient id="gradient-id" x1="50%" x2="50%" y1="100%" y2="0%">
                <stop offset="0%" stopColor="white" />
                <stop offset="100%" stopColor="#162D3D" />
            </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#162D3D"
                d="M3 0h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm.333 3.333v13.334h13.334V3.333H3.333z"
            />
            <path fill="url(#gradient-id)" d="M0 0h10v10H0z" transform="translate(5 5)" />
        </g>
    </svg>
);

export const Image: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="16" height="16" viewBox="0 0 20 20">
        <g fill="none" fillRule="evenodd">
            <rect width="20" height="20" fill="#162D3D" rx="3" />
            <path fill="#FFF" d="M5 3a2 2 0 1 1 0 4 2 2 0 0 1 0-4zM8 17H3l4.5-6 2.318 3.09L13 9l4 8H8z" />
        </g>
    </svg>
);

export const Pattern: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="16" height="16" viewBox="0 0 20 20">
        <g fill="none" fillRule="evenodd">
            <path
                fill="#162D3D"
                d="M3 0h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm0 3v14h14V3H3z"
            />
            <path fill="#162D3D" d="M4 4h12v12H4z" />
            <path fill="#FFF" d="M4 9l7 7H8.667L4 11.333zM16 11L9 4h2.333L16 8.667zM5.1 4L16 14.9V16h-1.1L4 5.1V4z" />
        </g>
    </svg>
);

export const Angle: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="22" height="21" viewBox="1 1 22 21">
        <g fill="none" fillRule="evenodd" transform="translate(4 4)">
            <path
                fill="currentColor"
                d="M14 12H0L8.59 0l.83.474-3.13 4.399C8.25 6.255 9.5 8.527 9.5 11H14v1M5.72 5.678L1.94 11H8.5c0-2.146-1.08-4.125-2.78-5.322"
            />
        </g>
    </svg>
);

export const Flip: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="12" height="12" viewBox="0 0 12 12">
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                d="M2.379 0L3 .653 2.69.98 1.241 2.499 2.689 4.02 3 4.347 2.379 5l-.311-.327L.311 2.827 0 2.5l.31-.327L2.069.327 2.378 0z"
                transform="translate(-91 -267) translate(91 267) translate(0 7)"
            />
            <path
                stroke="currentColor"
                strokeLinecap="square"
                d="M11.45 2.5L1.55 2.5"
                transform="translate(-91 -267) translate(91 267) translate(0 7)"
            />
            <path
                stroke="currentColor"
                strokeLinecap="square"
                d="M10.45 2.5L0.55 2.5"
                transform="translate(-91 -267) translate(91 267)"
            />
            <path
                fill="currentColor"
                d="M9.621 0l.311.327 1.757 1.846L12 2.5l-.31.327-.311.326-1.447 1.52-.31.327L9 4.347l.31-.327L10.758 2.5 9.311.979 9 .654 9.621 0z"
                transform="translate(-91 -267) translate(91 267)"
            />
            <path d="M0 0H12V12H0z" transform="translate(-91 -267) translate(91 267)" />
        </g>
    </svg>
);

export const Focal: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="14" height="14" viewBox="0 0 14 14">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g transform="translate(-150 -265) translate(150 265)">
                        <circle cx="7" cy="7" r="6.5" stroke="#FFF" />
                        <circle cx="7" cy="7" r="1" fill="#FFF" />
                    </g>
                    <path d="M0 0H14V14H0z" transform="translate(-150 -265) translate(150 265)" />
                </g>
            </g>
        </g>
    </svg>
);

export const Position: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
            <path
                id="position-id"
                fill="currentColor"
                d="M16.714 6h-3v.857h3c.237 0 .429.192.429.429v3H18v-3C18 6.576 17.423 6 16.714 6zm.429 10.714a.429.429 0 0 1-.429.429h-3V18h3c.71 0 1.286-.577 1.286-1.286v-3h-.857v3zm-10.286 0v-3H6v3C6 17.424 6.577 18 7.286 18h3v-.857h-3a.429.429 0 0 1-.429-.429zM6 7.286v3h.857v-3c0-.237.192-.429.429-.429h3V6h-3C6.576 6 6 6.577 6 7.286z"
            />
            <path fill="currentColor" d="M11 11h2v2h-2z" />
        </g>
    </svg>
);

export const TopLeftArrow: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="24" height="24" preserveAspectRatio="xMidYMid" viewBox="0 0 24 24">
        <path
            id="top-left-arrow-path"
            fillRule="evenodd"
            d="M14.969 16v-1h-1v-1h-1v-1h-1v-1h-1v-1.001h-1V14h-1v-4-1h5v1h-3v.938h1v.999h1v1h1v1.001h1v1h1V16h-1z"
        />
    </svg>
);

export const TopRightArrow: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="24" height="24" preserveAspectRatio="xMidYMid" viewBox="0 0 24 24">
        <path
            id="top-right-arrow-path"
            fillRule="evenodd"
            d="M9.969 16v-1h1v-1h1v-1h1v-1h1v-1.001h1V14h1v-4-1h-1-4v1h3v.938h-1v.999h-1v1h-1v1.001h-1v1h-1V16h1z"
        />
    </svg>
);

export const BottomLeftArrow: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="24" height="24" preserveAspectRatio="xMidYMid" viewBox="0 0 24 24">
        <path
            id="bottom-left-arrow-path"
            fillRule="evenodd"
            d="M14.969 9v1h-1v1h-1v1h-1v1h-1v1.001h-1V11h-1v5h5v-1h-3v-.938h1v-.999h1v-1h1v-1.001h1v-1h1V9h-1z"
        />
    </svg>
);

export const BottomRightArrow: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="24" height="24" preserveAspectRatio="xMidYMid" viewBox="0 0 24 24">
        <path
            id="bottom-right-arrow-path"
            fillRule="evenodd"
            d="M9.969 9v1h1v1h1v1h1v1h1v1.001h1V11h1v5h-1-4v-1h3v-.938h-1v-.999h-1v-1h-1v-1.001h-1v-1h-1V9h1z"
        />
    </svg>
);

export const Information: React.FC<IconProps> = ({ className, style }) => (
    <svg
        className={className}
        style={style}
        preserveAspectRatio="xMidYMid"
        viewBox="1.5 1.5 18 18"
        width="18"
        height="18"
    >
        {/* <circle cx="10.5" cy="10.5" r="8" /> */}
        <path
            fillRule="evenodd"
            fill="currentColor"
            d="M10.5 19.5a9 9 0 0 1-9-9 9 9 0 0 1 9-9 9 9 0 0 1 9 9 9 9 0 0 1-9 9zm-8-9c0 4.411 3.589 8 8 8s8-3.589 8-8-3.589-8-8-8-8 3.589-8 8zm10 5h-4l1-2v-3h-1l1-2h2v5l1 2zm-3-10h2v2h-2v-2z"
        />
    </svg>
);

export const Alert: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="18" height="18" viewBox="0 0 18 18">
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 7.92L9.4 8.92H9L9.01 4.92H10V7.92ZM10 11.92H9L9.01 10.89H10V11.92ZM9.5 1C5.36 1 2 4.359 2 8.5C2 12.65 5.36 16 9.5 16C13.64 16 17 12.65 17 8.5C17 4.359 13.64 1 9.5 1Z"
        />
    </svg>
);

export const LinkButtonEnabled: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20">
        <path
            fill="currentColor"
            d="M7,8 L7,6 C7,4.34314575 8.34314575,3 10,3 C11.6568542,3 13,4.34314575 13,6 L13,8 L14,8 C15.1045695,8 16,8.8954305 16,10 L16,15 C16,16.1045695 15.1045695,17 14,17 L6,17 C4.8954305,17 4,16.1045695 4,15 L4,10 C4,8.8954305 4.8954305,8 6,8 L7,8 Z M8,8 L12,8 L12,6 C12,4.8954305 11.1045695,4 10,4 C8.8954305,4 8,4.8954305 8,6 L8,8 Z M7,9 L6,9 C5.44771525,9 5,9.44771525 5,10 L5,15 C5,15.5522847 5.44771525,16 6,16 L14,16 C14.5522847,16 15,15.5522847 15,15 L15,10 C15,9.44771525 14.5522847,9 14,9 L13,9 L7,9 Z"
        />
    </svg>
);

export const LinkButtonDisabled: React.FC<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20">
        <path
            fill="currentColor"
            d="M7,8 L7,6 C7,4.34314575 8.34314575,3 10,3 C11.3062188,3 12.4174579,3.83480763 12.8292943,5 L11.7324356,5 C11.3866262,4.40219863 10.7402824,4 10,4 C8.8954305,4 8,4.8954305 8,6 L8,8 L14,8 C15.1045695,8 16,8.8954305 16,10 L16,15 C16,16.1045695 15.1045695,17 14,17 L6,17 C4.8954305,17 4,16.1045695 4,15 L4,10 C4,8.8954305 4.8954305,8 6,8 L7,8 Z M6,9 C5.44771525,9 5,9.44771525 5,10 L5,15 C5,15.5522847 5.44771525,16 6,16 L14,16 C14.5522847,16 15,15.5522847 15,15 L15,10 C15,9.44771525 14.5522847,9 14,9 L6,9 Z"
        />
    </svg>
);
