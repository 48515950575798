import React from 'react';
import type { FC } from 'react';
import type { WhenProps } from './configurable-panel-utils';
import type { ReactElement } from 'react';
import { shouldFilterByWhenConditions } from './configurable-panel-utils';
import { usePanelContext } from './configurable-panel';

type SingleChildType = ReactElement<any>;
type ChildType = SingleChildType | Array<SingleChildType | undefined>;

export interface WhenCompProps extends WhenProps {
    children?: ChildType;
}
export const When: FC<WhenCompProps> = (props) => {
    const panelContext = usePanelContext();
    if (
        !props.children ||
        shouldFilterByWhenConditions({
            context: panelContext,
            whenCondition: props.whenCondition,
            whenTargetSelector: props.whenTargetSelector,
            whenSelectedElement: props.whenSelectedElement,
        })
    ) {
        return null;
    }

    return <>{props.children}</>;
};
