import type { Margins, Paddings } from '@wix/shorthands-opener';

import type { GenericDeclarationMap } from '@wix/stylable-panel-drivers';

import type { DeclarationVisualizerProps } from '../../types';

export type EdgeMainProp = 'padding' | 'margin';
export type Edges = 'top' | 'right' | 'bottom' | 'left';

export enum SelectedEdge {
    None = -1,
    Top = 0,
    Right = 1,
    Bottom = 2,
    Left = 3,
    All = 4,
}

export type PaddingProps = Paddings | 'padding';
export type MarginProps = Margins | 'margin';
export type EdgeProps = PaddingProps | MarginProps | EdgeMainProp;

export type PaddingVisualizerProps = DeclarationVisualizerProps<PaddingProps>;
export type MarginVisualizerProps = DeclarationVisualizerProps<MarginProps>;
export type EdgeVisualizerProps = DeclarationVisualizerProps<EdgeProps>;

export type EdgeDeclarationMap = GenericDeclarationMap<EdgeProps>;
