import { DIMENSION_ID, DIMENSION_INPUT_DEFAULTS, IMAGE_PATTERN_SCALE, IMAGE_SCALE } from '@wix/stylable-panel-common';
import {
    CustomScale,
    CustomScaleSelected,
    FillScale,
    FillScaleSelected,
    FitScale,
    FitScaleSelected,
    Position,
    TileScale,
    TileScaleSelected,
} from '@wix/stylable-panel-common-react';
import { Button, CompositeBlock, DimensionInput, Text } from '@wix/stylable-panel-components';
import {
    BackgroundImage,
    DEFAULT_BACKGROUND_IMAGE_REPEAT,
    DEFAULT_BACKGROUND_SIZE,
    FullDeclarationMap,
    parseBackgroundImage,
    StylablePanelTranslationKeys,
} from '@wix/stylable-panel-drivers';
import React, { useEffect, useRef, useState } from 'react';
import { getDimensionConfig } from '../../../generated-visualizers';
import type { BIParams, PanelEventList } from '../../../hosts/bi';
import { getTranslate } from '../../../hosts/translate';
import { ImagePicker, ImagePickerProps } from '../../../pickers/image-picker/image-picker';
import type { StylablePanelHost } from '../../../types';
import { stringifyBackgroundImage } from '../../../utils';
import { extractURL } from '../../../utils/url-utils';
import { Action, BackgroundControl, Point } from '../background-control/background-control';
import {
    DisplayModePicker,
    DisplayModePickerBox,
    DisplayModePickerContent,
} from '../display-mode-picker/display-mode-picker';
import { classes, style } from './image-fill.st.css';

export const IMAGE_VALUE_TYPE = 'image';

export interface ImageFillProps {
    id?: string;
    value?: string;
    panelHost?: StylablePanelHost;
    onChange?: (value: string) => void;
    reportBI?: (event: PanelEventList, params?: BIParams) => void;
    className?: string;
    style?: React.CSSProperties;
    onFallback?: (value: string | undefined) => void;
}
export interface ImageFillState {
    openedAction: string;
    backgroundImage: BackgroundImage;
}

const DEFAULT_SCALE = DIMENSION_INPUT_DEFAULTS.imageFill;

const RENDER_AS_BACKGROUND_THRESHOLD = 100;

const BACKGROUND_SIZE_CUSTOM = 'custom';
const BACKGROUND_SIZE_CONTAIN = 'contain';
const BACKGROUND_SIZE_COVER = 'cover';
const BACKGROUND_REPEAT_REPEAT = 'repeat';

const reduceSize = (sizeProp?: string) => {
    if (!sizeProp) {
        return DEFAULT_BACKGROUND_SIZE;
    }
    const size = sizeProp.trim();
    if (size === BACKGROUND_SIZE_CONTAIN || size === BACKGROUND_SIZE_COVER) {
        return size;
    } else if (sizeProp.match(/\d+[%(px)]( +\d+[%(px)])?/)) {
        return BACKGROUND_SIZE_CUSTOM;
    }
    return BACKGROUND_SIZE_COVER; // default
};

export const ImageFill = ({
    className,
    id,
    onFallback,
    onChange,
    panelHost,
    reportBI,
    style: propStyle,
    value,
}: ImageFillProps) => {
    const componentId = id || 'imageFill';
    const translate = getTranslate(panelHost);
    const actions = [
        {
            key: 'focal',
            label: translate(StylablePanelTranslationKeys.picker.image.actions.labels.focal),
            tooltip: translate(StylablePanelTranslationKeys.picker.image.actions.tooltips.focal),
            icon: <Position />,
        },
    ];

    const [openedAction, setOpenedAction] = useState<string>('');
    const [backgroundImage, setBackgroundImage] = useState<BackgroundImage>(parseBackgroundImage(value));

    useEffect(() => {
        // this is a workaround to get a formatted url after find load and image change
        // TODO: find a better fix
        if (
            backgroundImage['background-image']?.includes('wixMediaUrl') ||
            backgroundImage.background?.includes('wixMediaUrl')
        ) {
            setBackgroundImage(parseBackgroundImage(value));
        }
    }, [backgroundImage, value]);

    const fallbackPosition = useRef<Point>();
    const fallbackSize = useRef<string>();

    const saveChanges = () => {
        panelHost?.unblockCommits && panelHost.unblockCommits(true, componentId);

        closeAction();
    };

    const cancelChanges = () => {
        const changes: FullDeclarationMap = {};

        if (fallbackSize.current) {
            changes['background-size'] = fallbackSize.current;
        }

        if (fallbackPosition.current) {
            const { x, y } = fallbackPosition.current;
            changes['background-position'] = `${x} ${y}`;
        }

        if (Object.keys(changes)) {
            fireChange(changes);
        }

        panelHost?.onRevertQuickChange && panelHost.onRevertQuickChange();
        panelHost?.unblockCommits && panelHost.unblockCommits(true, componentId);

        closeAction();
    };

    const closeAction = () => {
        onFallback && onFallback(undefined);

        setOpenedAction('');
    };

    const hasImage = (): boolean => {
        return !(
            !backgroundImage['background-image'] ||
            backgroundImage['background-image']!.match(/url\((("")|(''))?\)/) !== null
        );
    };

    const clickAction = (action: Action) => {
        const { key } = action;
        panelHost?.blockCommits && panelHost.blockCommits(componentId);

        fallbackPosition.current = calcPosition();
        fallbackSize.current = backgroundImage['background-size'];

        onFallback && onFallback(stringifyBackgroundImage({ ...backgroundImage }));

        setOpenedAction(key);
    };

    const openImagePicker = () => {
        if (!panelHost?.onOpenPanel) {
            return;
        }

        const imagePickerProps: ImagePickerProps = {
            className: classes.imagePicker,
            title: 'Image Picker',
            value: backgroundImage['background-image'] || '',
            onChange: handleChangeImage,
        };

        panelHost.onOpenPanel(ImagePicker.panelName, imagePickerProps);
    };

    const calcPosition = (): { x: string; y: string } | undefined => {
        let x = '50%';
        let y = '50%'; // TODO remove
        const position = backgroundImage && backgroundImage['background-position'];
        if (position) {
            const positions: string[] = position.split(' ');
            if (positions.length === 2 && positions[0].includes('%') && positions[1].includes('%')) {
                x = parseInt(positions[0], 10) + '%';
                y = parseInt(positions[1], 10) + '%';
            }
        }

        // default:
        return { x, y };
    };

    const handleMouseDown = (point: Point) => {
        if (!openedAction) {
            return;
        }

        handleChangeBackgroundPosition(point.x, point.y);
    };

    const handleChangeBackgroundPosition = (x: string, y: string) => {
        fireChange({ 'background-position': `${x} ${y}` });
    };

    const getSelectedMode = () => (isPattern() ? 'repeat' : getReducedSize());

    const renderDisplayModePicker = () => {
        const selected = getSelectedMode();
        const sizeSelectorTranslationKeys = StylablePanelTranslationKeys.picker.image.sizeSelector;
        const displayModePickerContent: DisplayModePickerContent = {
            title: translate(StylablePanelTranslationKeys.picker.image.sizeSelector.label),
            content: [
                {
                    id: BACKGROUND_SIZE_COVER,
                    label: translate(sizeSelectorTranslationKeys.coverOptionLabel),
                    selected: selected === BACKGROUND_SIZE_COVER,
                    backgroundIcon:
                        selected === BACKGROUND_SIZE_COVER ? (
                            <FillScaleSelected />
                        ) : (
                            <FillScale className={classes.fillScale} />
                        ),
                },
                {
                    id: BACKGROUND_SIZE_CONTAIN,
                    label: translate(sizeSelectorTranslationKeys.containOptionLabel),
                    selected: selected === BACKGROUND_SIZE_CONTAIN,
                    backgroundIcon:
                        selected === BACKGROUND_SIZE_CONTAIN ? (
                            <FitScaleSelected />
                        ) : (
                            <FitScale className={classes.fitScale} />
                        ),
                },
                {
                    id: BACKGROUND_REPEAT_REPEAT,
                    label: translate(sizeSelectorTranslationKeys.tileOptionLabel),
                    selected: selected === BACKGROUND_REPEAT_REPEAT,
                    backgroundIcon:
                        selected === BACKGROUND_REPEAT_REPEAT ? (
                            <TileScaleSelected />
                        ) : (
                            <TileScale className={classes.tileScale} />
                        ),
                },
                {
                    id: BACKGROUND_SIZE_CUSTOM,
                    label: translate(sizeSelectorTranslationKeys.customOptionLabel),
                    selected: selected === BACKGROUND_SIZE_CUSTOM,
                    backgroundIcon:
                        selected === BACKGROUND_SIZE_CUSTOM ? (
                            <CustomScaleSelected />
                        ) : (
                            <CustomScale className={classes.customScale} />
                        ),
                },
            ],
        };

        const getDisplayModePickerTitle = () => {
            const selected = displayModePickerContent.content.find(
                (displayModePickerBox) => displayModePickerBox.id === getSelectedMode()
            );
            return selected?.label || '';
        };

        const handleDisplayChange = (value: string) => {
            let newSize: string | undefined;

            switch (value) {
                case BACKGROUND_SIZE_COVER:
                case BACKGROUND_SIZE_CONTAIN:
                    newSize = value;
                    break;
                case BACKGROUND_SIZE_CUSTOM:
                    newSize = DEFAULT_SCALE.customScale;
                    break;
                case BACKGROUND_REPEAT_REPEAT:
                    fireChange({ 'background-size': DEFAULT_SCALE.tileScale, 'background-repeat': value });
                    return;
            }

            if (newSize) {
                fireChange({ 'background-size': newSize, 'background-repeat': DEFAULT_BACKGROUND_IMAGE_REPEAT });
            }
        };

        return (
            <DisplayModePicker
                className={classes.displayModePicker}
                displayModePickerTitle={getDisplayModePickerTitle()}
                displayModePickerContent={displayModePickerContent}
                handleDisplayModePickerBoxClick={({ id }: DisplayModePickerBox) => handleDisplayChange(id)}
                isActionOpened={!!openedAction}
            />
        );
    };

    const handleCustomSizeChange = (value?: string) => {
        if (!value) {
            return;
        }
        fireChange({ 'background-size': value });
    };

    const handleChangeImage = (value: string) => {
        if (!value) {
            return;
        }
        fireChange({ 'background-image': value });
    };

    const fireChange = (overrides: FullDeclarationMap) => {
        if (!onChange) {
            return;
        }
        const newBackgroundImage = { ...backgroundImage, ...overrides };
        onChange(stringifyBackgroundImage(newBackgroundImage)!);
        setBackgroundImage(newBackgroundImage);
    };

    const calcCustomSize = (): string => {
        if (getReducedSize() === BACKGROUND_SIZE_CUSTOM) {
            const originalSize: string[] = backgroundImage['background-size']!.split(' ');
            return originalSize[0]; // keep only first
        }
        return DEFAULT_SCALE.customScale;
    };

    const getReducedSize = () => {
        return reduceSize(backgroundImage['background-size']);
    };

    const isPattern = (): boolean => {
        return backgroundImage['background-repeat'] !== DEFAULT_BACKGROUND_IMAGE_REPEAT;
    };

    const renderAdvancedControls = () => {
        if (!hasImage()) {
            return;
        }

        const size: string = calcCustomSize();
        let scale;

        const { dimensionUnits, dimensionKeywords } = panelHost || {};
        const imagePatternScaleDimensionConfig = getDimensionConfig({
            id: DIMENSION_ID.IMAGE_PATTERN_SCALE,
            dimensionUnits,
            customUnits: IMAGE_PATTERN_SCALE,
            dimensionKeywords,
        });
        const imageScaleDimensionConfig = getDimensionConfig({
            id: DIMENSION_ID.IMAGE_SCALE,
            dimensionUnits,
            customUnits: IMAGE_SCALE,
            dimensionKeywords,
        });

        if (isPattern()) {
            scale = (
                <DimensionInput
                    key="scaleInput"
                    className={classes.scaleDimension}
                    value={size}
                    config={imagePatternScaleDimensionConfig}
                    keepRange={true}
                    isSlider={true}
                    onChange={handleCustomSizeChange}
                    showUnitErrors={panelHost?.showDimensionErrors}
                    translate={translate}
                    translationKeys={StylablePanelTranslationKeys.component.dimensionInput}
                />
            );
        } else if (getReducedSize() === BACKGROUND_SIZE_CUSTOM) {
            scale = (
                <DimensionInput
                    key="scaleInput"
                    className={classes.scaleDimension}
                    value={size}
                    config={imageScaleDimensionConfig}
                    isSlider={true}
                    onChange={handleCustomSizeChange}
                    showUnitErrors={panelHost?.showDimensionErrors}
                    translate={translate}
                    translationKeys={StylablePanelTranslationKeys.component.dimensionInput}
                />
            );
        }

        let scaleComp;
        if (scale) {
            scaleComp = (
                <CompositeBlock
                    className={classes.scaleBlock}
                    title={translate(StylablePanelTranslationKeys.picker.image.scaleLabel)}
                    information={translate(StylablePanelTranslationKeys.picker.image.scaleInformationTooltip)}
                    divider={!!openedAction}
                >
                    {scale}
                </CompositeBlock>
            );
        }

        return <div className={classes.advancedControl}>{scaleComp}</div>;
    };

    const renderChangeImage = () => {
        return (
            <Button
                className={classes.changeImageButton}
                data-aid="st_image_fill_change_image"
                onClick={openImagePicker}
            >
                <Text className={classes.changeImageButtonText}>
                    {translate(StylablePanelTranslationKeys.picker.image.changeImageButtonLabel)}
                </Text>
            </Button>
        );
    };

    const shouldRenderAsBackgroundImage = (): boolean => {
        const image = backgroundImage['background-image'];
        const previewProps = image?.startsWith('url(') ? { src: extractURL(true)(image) } : {};
        return (
            previewProps.src === undefined ||
            (!isPattern() &&
                getReducedSize() === BACKGROUND_SIZE_CUSTOM &&
                parseInt(backgroundImage['background-size']!, 10) < RENDER_AS_BACKGROUND_THRESHOLD)
        );
    };

    const getBackgroundControlPreviewProps = () => {
        const image = backgroundImage['background-image'];

        return shouldRenderAsBackgroundImage()
            ? {
                  previewStyle: {
                      backgroundImage: image,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: backgroundImage['background-size'],
                      backgroundPosition: backgroundImage['background-position'],
                  },
              }
            : {
                  customPreviewComponent: 'img',
                  previewProps: image?.startsWith('url(') ? { src: extractURL(true)(image) } : {},
              };
    };

    const getPointValue = (): Point | undefined => {
        const positionMarker = calcPosition();

        return positionMarker
            ? {
                  x: positionMarker.x,
                  y: positionMarker.y,
              }
            : undefined;
    };

    return (
        <div
            className={style(
                classes.root,
                { patternMode: shouldRenderAsBackgroundImage(), openedAction: !!openedAction },
                className
            )}
            style={propStyle}
            data-aid="st_image_fill"
        >
            <BackgroundControl
                className={classes.preview}
                {...getBackgroundControlPreviewProps()}
                onClickAction={clickAction}
                actions={actions}
                openedAction={openedAction}
                value={getPointValue()}
                onChange={handleMouseDown}
                reportBI={reportBI}
                displayModePicker={renderDisplayModePicker}
                renderActionButtons={hasImage()}
            >
                {!openedAction && <div className={classes.imagePreviewContent}>{renderChangeImage()}</div>}
            </BackgroundControl>
            {renderAdvancedControls()}
            {openedAction && (
                <div className={classes.actionButtonsContainer}>
                    <Button className={classes.cancelActionButton} onClick={cancelChanges}>
                        <Text className={classes.cancelActionButtonText}>
                            {translate(StylablePanelTranslationKeys.picker.image.actions.cancel)}
                        </Text>
                    </Button>
                    <Button className={classes.saveActionButton} onClick={saveChanges}>
                        <Text className={classes.saveActionButtonText}>
                            {translate(StylablePanelTranslationKeys.picker.image.actions.save)}
                        </Text>
                    </Button>
                </div>
            )}
        </div>
    );
};
