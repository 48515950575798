import type { StylableDriver } from './stylable-driver';
import type { StylesheetDriver } from './stylable-stylesheet';
import { StylableShorthands } from './stylable-shorthands';
import type { ArchetypeList, BlockVariantSet } from './types';
import { removeDuplicates } from './utils/general-utils';

const DEFAULT_ARCHETYPE_NAME = 'default';

/**
 * @deprecated this will be removed when switching to config 2.0
 */
export class ArchetypeResolver {
    private stylesheet: StylesheetDriver;
    constructor(
        public sheetPath: string,
        public archetypeList: ArchetypeList,
        public stylableDriver: StylableDriver,
        public globalFilterFunction: (name: string) => boolean = () => true
    ) {
        const stylesheet = stylableDriver.getStylesheet(sheetPath);
        if (!stylesheet) {
            throw new Error(`stylesheet ${stylesheet} not found`);
        } else {
            this.stylesheet = stylesheet;
        }
    }

    public getPropNames(archetypeName: string): string[] {
        let propList: string[] = [];

        let source = this.archetypeList[archetypeName];
        if (source === undefined) {
            source = this.archetypeList[DEFAULT_ARCHETYPE_NAME];
        }

        if (source) {
            source.props.forEach((prop) => {
                if (Array.isArray(prop)) {
                    propList = propList.concat(prop);
                } else {
                    propList.push(prop);
                }
            });
        }

        return propList;
    }

    public getPrefferedSectionForSelector(selector: string) {
        const deepestExtendName = this.getArchetypeId(selector);
        if (deepestExtendName && this.archetypeList[deepestExtendName]) {
            return this.archetypeList[deepestExtendName].preferred;
        }
        return undefined;
    }

    public getPropNamesFromSelector(selector: string) {
        let retVal: string[] = [];
        const deepestExtendName = this.getArchetypeId(selector);
        if (deepestExtendName) {
            retVal = this.getPropNames(deepestExtendName);
        }

        const usedProps = this.stylesheet.collectSelectorUsedProps(selector);
        const shorthands = new StylableShorthands(this.stylesheet.evalDeclarationValue.bind(this.stylesheet));
        const expandedUsedProps = usedProps.reduce(
            (currentExpandedUsedProps, prop) =>
                currentExpandedUsedProps.concat(prop).concat(shorthands.getShorthandProps(prop)),
            [] as string[]
        );
        retVal = retVal.concat(expandedUsedProps).filter(this.globalFilterFunction);
        retVal = removeDuplicates(retVal);

        const selectorArchetype = this.archetypeList[deepestExtendName];
        if (selectorArchetype && selectorArchetype.propBlacklist) {
            retVal = retVal.filter(
                (prop) =>
                    !selectorArchetype.propBlacklist!.some((blacklistedPropQuery) => !!prop.match(blacklistedPropQuery))
            );
        }

        return retVal;
    }

    public getBlockVariantsForElement(selector: string, sectionId: string): BlockVariantSet | undefined {
        const deepestExtendName = this.getArchetypeId(selector);
        if (
            deepestExtendName &&
            this.archetypeList[deepestExtendName] &&
            this.archetypeList[deepestExtendName].blockVariantSets
        ) {
            return this.archetypeList[deepestExtendName].blockVariantSets![sectionId];
        }
        return undefined;
    }

    private getArchetypeId(selector: string) {
        return this.stylableDriver.getArchetypeName(this.sheetPath, selector) || DEFAULT_ARCHETYPE_NAME;
    }
}
