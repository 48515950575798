import type { CSSCodeAst } from '../tokenizers';
import type { CSSAstNode, ParseShorthandAPI, EvaluatedAst, AstEvaluator } from './shorthand-types';

const createEvaluateAst =
    <V, T>(
        handleExpression: (node: V, api: ParseShorthandAPI<V>) => T[],
        handleNonExpression: (node: CSSCodeAst) => T
    ): AstEvaluator<V, T> =>
    (ast, api) => {
        const res: T[] = [];
        for (const node of ast) {
            if (api.isExpression(node)) {
                res.push(...handleExpression(node, api));
            } else {
                res.push(handleNonExpression(node));
            }
        }
        return res;
    };

const evaluateExpression = <V>(node: V, api: ParseShorthandAPI<V>) => evaluateAstInner(api.getValue(node), api);

const evaluateAstInner = <V>(ast: CSSCodeAst[], api: ParseShorthandAPI<V>): CSSCodeAst[] =>
    createEvaluateAst<V, CSSCodeAst>(
        (node, api) => evaluateExpression(node, api),
        (node) => node
    )(ast, api);

const evaluateCodeNode = <V>(value: CSSCodeAst): EvaluatedAst<V> => ({ value });
export const codeToEvaluatedAst = <V>(ast: CSSCodeAst[]): EvaluatedAst<V>[] =>
    ast.map((value) => evaluateCodeNode<V>(value));

// TODO: Always have origin to values that don't come from an initial value (that are specified directly)
export const evaluateAst = <V>(ast: CSSAstNode<V>[], api: ParseShorthandAPI<V>): EvaluatedAst<V>[] =>
    createEvaluateAst<V, EvaluatedAst<V>>(
        (origin, api) => evaluateExpression(origin, api).map((value) => ({ value, origin })),
        evaluateCodeNode
    )(ast, api);
