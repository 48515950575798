import type {
    OpenedShorthand,
    SimpleShorthandOpener,
    SimpleShorthandOpenerInner,
    ShorthandPart,
    GetSimpleShorthandOpener,
    GetShorthandCloser,
} from '../shorthand-types';

import { Flexes, FLEX_KEYWORD_VALUE_MAP } from '../shorthand-css-data';
import { flexSingleValueDataType, flexGrowDataType, flexShrinkDataType, flexBasisDataType } from '../../css-data-types';
import {
    matchDataType,
    singleKeywordShorthandOpener,
    createShorthandOpener,
    createShorthandCloserTemplateFromParts,
    createShorthandCloser,
} from '../shorthand-parser-utils';
import { NoMandatoryPartMatchError } from '../shorthand-parser-errors';

const getFlexShorthandParts = <V>() =>
    [
        { prop: 'flex-grow', dataType: flexGrowDataType, mandatory: true },
        { prop: 'flex-shrink', dataType: flexShrinkDataType },
        { prop: 'flex-basis', dataType: flexBasisDataType },
    ] as ShorthandPart<V, Flexes>[];

// flex
export const openFlexShorthand: GetSimpleShorthandOpener<Flexes> = <V>() =>
    createShorthandOpener({
        prop: 'flex',
        singleKeywordPart: {
            prop: 'flex',
            dataType: flexSingleValueDataType,
            partOpener: singleKeywordShorthandOpener(FLEX_KEYWORD_VALUE_MAP) as SimpleShorthandOpenerInner<V, Flexes>,
        },
        parts: getFlexShorthandParts(),
        shorthandOpener: (astNodes) => {
            const opened: OpenedShorthand<V> = {};

            if (matchDataType(flexGrowDataType, astNodes, 0)) {
                opened['flex-grow'] = astNodes[0];

                if (astNodes.length > 1) {
                    if (matchDataType(flexShrinkDataType, astNodes, 1)) {
                        opened['flex-shrink'] = astNodes[1];

                        if (astNodes.length > 2) {
                            if (matchDataType(flexBasisDataType, astNodes, 2)) {
                                opened['flex-basis'] = astNodes[2];
                            } else {
                                throw new NoMandatoryPartMatchError('flex', 'flex-basis');
                            }
                        }
                    } else if (matchDataType(flexBasisDataType, astNodes, 1)) {
                        if (astNodes.length < 3) {
                            opened['flex-basis'] = astNodes[1];
                        } else {
                            throw new NoMandatoryPartMatchError('flex', 'flex-shrink');
                        }
                    } else {
                        throw new NoMandatoryPartMatchError('flex', `${'flex-shrink'}, ${'flex-basis'}`);
                    }
                }
            } else {
                throw new NoMandatoryPartMatchError('flex', 'flex-grow');
            }

            return opened;
        },
    }) as SimpleShorthandOpener<V, Flexes>;

export const closeFlexShorthand: GetShorthandCloser<Flexes> = <V>() =>
    createShorthandCloser<V, Flexes>(createShorthandCloserTemplateFromParts(getFlexShorthandParts()));
