import type { GridAxis, GridAxisLonghands } from '../shorthand-css-data';
import { gridLineDataType, gridLineEndDataType } from '../../css-data-types';
import type {
    SimpleShorthandOpener,
    ShorthandPart,
    GetSimpleShorthandOpener,
    GetShorthandCloser,
} from '../shorthand-types';
import {
    splitShorthandOpener,
    unorderedListShorthandOpener,
    createShorthandOpener,
    createShorthandCloser,
    shorthandCloserTemplate,
} from '../shorthand-parser-utils';

const getGridAxisShorthandParts = <V, A extends GridAxis>(axis: A) =>
    [
        {
            prop: `grid-${axis}-start`,
            dataType: gridLineDataType,
            partOpener: splitShorthandOpener([`grid-${axis}-start`, `grid-${axis}-end`]),
            multipleItems: true,
        },
        {
            prop: `grid-${axis}-end`,
            dataType: gridLineEndDataType,
            multipleItems: true,
            multipleSplit: true,
        },
    ] as ShorthandPart<V, GridAxisLonghands<A>>[];

const createGridAxisShorthandOpener: <A extends GridAxis>(axis: A) => GetSimpleShorthandOpener<GridAxisLonghands<A>> =
    (axis) =>
    <V>() =>
        createShorthandOpener({
            prop: `grid-${axis}`,
            parts: getGridAxisShorthandParts(axis),
            shorthandOpener: (astNodes, api, parts) => unorderedListShorthandOpener(parts)(astNodes, api),
        }) as SimpleShorthandOpener<V>;

const createGridAxisShorthandCloser: <A extends GridAxis>(axis: A) => GetShorthandCloser<GridAxisLonghands<A>> =
    (axis) =>
    <V>() =>
        createShorthandCloser<V>(shorthandCloserTemplate`${`grid-${axis}-start`} / ${`grid-${axis}-end`}`);

// grid-row
export const openGridRowShorthand = createGridAxisShorthandOpener('row');
export const closeGridRowShorthand = createGridAxisShorthandCloser('row');

// grid-column
export const openGridColumnShorthand = createGridAxisShorthandOpener('column');
export const closeGridColumnShorthand = createGridAxisShorthandCloser('column');
