import type { DeclarationVisualizerProps } from '../types';
import { createDeclarationMapFromVisualizerValue } from '../utils';
import { useVisualizerChange } from './use-visualizer-change';

export function useDeclarationMapValue<PROP extends string>(
    mainProp: PROP,
    props: DeclarationVisualizerProps<PROP>
): [string | undefined, ((value: string) => void) | undefined] {
    const declarationMapValue = createDeclarationMapFromVisualizerValue(props.value, props);

    const handleChange = useVisualizerChange(mainProp, props);

    return [declarationMapValue[mainProp], handleChange];
}
