import type { BoxShadowMap, TextShadowMap } from '../compound-css-data';
import type { GetCompoundParser } from '../compound-types';
import {
    lengthDataType,
    colorDataType,
    insetDataType,
    shadowSingleValueDataType,
    INSET_KEYWORD,
} from '../../css-data-types';
import { unorderedListShorthandOpener, layersShorthandOpener } from '../../shorthands';
import { createCompoundParser } from '../compound-parser-utils';
import {
    identityCompoundValueParser,
    keywordsCompoundValueParser,
    dimensionCompoundValueParser,
} from '../compound-value-parsers';

// box-shadow
export const parseBoxShadowCompound: GetCompoundParser<BoxShadowMap> = <V>() =>
    createCompoundParser<V, BoxShadowMap>({
        prop: 'box-shadow',
        singleKeywordPart: {
            prop: 'box-shadow',
            dataType: shadowSingleValueDataType,
            parser: identityCompoundValueParser,
        },
        parts: [
            { prop: 'inset', dataType: insetDataType, parser: keywordsCompoundValueParser(INSET_KEYWORD) },
            { prop: 'offset-x', dataType: lengthDataType, parser: dimensionCompoundValueParser, mandatory: true },
            { prop: 'offset-y', dataType: lengthDataType, parser: dimensionCompoundValueParser, mandatory: true },
            { prop: 'blur-radius', dataType: lengthDataType, parser: dimensionCompoundValueParser },
            { prop: 'spread-radius', dataType: lengthDataType, parser: dimensionCompoundValueParser },
            { prop: 'color', dataType: colorDataType, parser: identityCompoundValueParser },
        ],
        shorthandOpener: (astNodes, api, parts) =>
            layersShorthandOpener('box-shadow', unorderedListShorthandOpener(parts), parts)(astNodes, api),
    });

// text-shadow
export const parseTextShadowCompound: GetCompoundParser<TextShadowMap> = <V>() =>
    createCompoundParser<V, TextShadowMap>({
        prop: 'text-shadow',
        singleKeywordPart: {
            prop: 'text-shadow',
            dataType: shadowSingleValueDataType,
            parser: identityCompoundValueParser,
        },
        parts: [
            { prop: 'offset-x', dataType: lengthDataType, parser: dimensionCompoundValueParser, mandatory: true },
            { prop: 'offset-y', dataType: lengthDataType, parser: dimensionCompoundValueParser, mandatory: true },
            { prop: 'blur-radius', dataType: lengthDataType, parser: dimensionCompoundValueParser },
            { prop: 'color', dataType: colorDataType, parser: (text) => text },
        ],
        shorthandOpener: (astNodes, api, parts) =>
            layersShorthandOpener('text-shadow', unorderedListShorthandOpener(parts), parts)(astNodes, api),
    });
