import type {
    AstItem,
    PredicateMatch,
    ParsedPredicateMatch,
    DataTypePredicate,
    ParsedDataTypePredicate,
} from './data-types-types';

import { CSSCodeAst, MethodCall, valueTextNode } from '../tokenizers';
import {
    AUTO_KEYWORD,
    LENGTH_UNITS_MAP,
    CALC_FUNCTION,
    PERCENTAGE_UNIT,
    LENGTH_PERCENTAGE_UNITS,
    ANGLE_UNITS,
    FLEX_UNIT,
    FLEX_NUMBER_RANGE_MIN,
    FONT_STYLE_OBLIQUE_KEYWORD,
    TEXT_DECORATION_LINE_KEYWORDS,
    BORDER_IMAGE_SLICE_FILL_KEYWORD,
    GRID_LINE_SPAN_KEYWORD,
    BREADTH_KEYWORDS,
    LINE_NAMES_EXCLUDE_KEYWORDS,
    MINMAX_FUNCTION,
    REPEAT_FUNCTION,
    TRACK_SIZE_FUNCTIONS,
    INITIAL_TRACK_SIZE,
    AUTO_REPEAT_FIRST_ARGUMENT_KEYWORDS,
    GRID_AUTO_FLOW_KEYWORD,
    GRID_AUTO_FLOW_DENSE_KEYWORD,
    SPX_FORMATTER,
} from './data-types-consts';
import {
    unorderedListPredicate,
    functionPredicate,
    dimensionPredicate,
    customIdentPredicate,
    typePredicate,
    predicateUnion,
} from './data-types-utils';

// <number>
export const numberPredicate = dimensionPredicate();

// <length>
export const lengthPredicate = (ast: CSSCodeAst) => {
    return dimensionPredicate({ units: LENGTH_UNITS_MAP })(ast) || functionPredicate(SPX_FORMATTER)(ast);
};

// <percentage>
export const percentagePredicate = dimensionPredicate({ units: PERCENTAGE_UNIT });

// <length-percentage>
export const lengthPercentagePredicate = dimensionPredicate({ units: LENGTH_PERCENTAGE_UNITS });

// <calc()>
export const calcPredicate = functionPredicate(CALC_FUNCTION);

// <angle>
export const anglePredicate = dimensionPredicate({ units: ANGLE_UNITS });

// <flex>
const flexPredicate = dimensionPredicate({ units: FLEX_UNIT, min: FLEX_NUMBER_RANGE_MIN });

// 'auto'
export const autoKeywordPredicate = unorderedListPredicate(AUTO_KEYWORD);

// <font-style>
// syntax: normal | italic | oblique <angle>?
export const fontStylePredicate: DataTypePredicate = (ast, index, items) => {
    if (index === undefined || !items) {
        return false;
    }

    const obliquePredicate = unorderedListPredicate(FONT_STYLE_OBLIQUE_KEYWORD);

    let matchAmount = 0;
    if (obliquePredicate(ast)) {
        matchAmount++;
        const next = items[index + 1];
        if (next && anglePredicate(next.value)) {
            matchAmount++;
        }
    }

    return matchAmount;
};

// <font-family>
// syntax: <generic-family> | <string>
export const fontFamilyStringPredicate = typePredicate(['text', 'string', ',']);

// <grid-line>
// syntax: <custom-ident> | [ <integer> && <custom-ident>? ] | [ span && [ <integer> || <custom-ident> ] ]
export const gridLinePredicate: DataTypePredicate = (ast, index, items) => {
    const startIndex = index ?? 0;
    const astItems = items ?? [{ value: ast }];

    const nonZeroIntegerPredicate = dimensionPredicate({ nonZero: true, integer: true });
    const gridLineCustomIdentPredicate = customIdentPredicate(GRID_LINE_SPAN_KEYWORD);
    const spanPredicate = unorderedListPredicate(GRID_LINE_SPAN_KEYWORD);

    let hasSpan = false;
    let hasCustomIdent = false;
    let hasInteger = false;

    let item: AstItem | undefined;
    let i = startIndex;
    while ((item = astItems[i++])) {
        if (gridLineCustomIdentPredicate(item.value)) {
            if (hasCustomIdent) {
                break;
            }
            hasCustomIdent = true;
        } else if (nonZeroIntegerPredicate(item.value)) {
            if (hasInteger) {
                break;
            }
            hasInteger = true;
        } else if (spanPredicate(item.value)) {
            if (hasSpan) {
                break;
            }
            hasSpan = true;
        } else {
            break;
        }
    }

    return !hasSpan || hasCustomIdent || hasInteger ? +hasSpan + +hasCustomIdent + +hasInteger : false;
};

// <track-breadth>
// syntax: <length-percentage> | <flex> | min-content | max-content | auto
const trackBreadthPredicate = predicateUnion([
    lengthPercentagePredicate,
    flexPredicate,
    unorderedListPredicate(BREADTH_KEYWORDS),
]);

// <line-names>
// syntax: '[' <custom-ident>* ']'
interface ParseLineNamesMatch extends ParsedPredicateMatch {
    names: string[];
}

const INVALID_LINE_NAMES_MATCH: ParseLineNamesMatch = { names: [], match: false };
const parseLineNames: ParsedDataTypePredicate<ParseLineNamesMatch> = (ast, index, items) => {
    const startIndex = index ?? 0;
    const astItems = items ?? [{ value: ast }];

    const lineNamesCustomIdentPredicate = customIdentPredicate(LINE_NAMES_EXCLUDE_KEYWORDS);

    const names: string[] = [];
    let matchAmount = 0;
    let hasClosingBracket = false;
    for (let i = startIndex; i < astItems.length; i++) {
        const value = astItems[i].value;
        let currText = value.text;

        const isStart = i === startIndex;
        const hasOpeningBracket = currText.startsWith('[');
        hasClosingBracket = currText.endsWith(']');
        if (value.type !== 'text' || (isStart && !hasOpeningBracket) || (!isStart && hasOpeningBracket)) {
            return { ...INVALID_LINE_NAMES_MATCH };
        }

        currText = currText.slice(hasOpeningBracket ? 1 : 0, currText.length - (hasClosingBracket ? 1 : 0));
        if (
            currText !== '' &&
            (currText.includes('[') ||
                currText.includes(']') ||
                !lineNamesCustomIdentPredicate(valueTextNode(currText)))
        ) {
            return { ...INVALID_LINE_NAMES_MATCH };
        }

        if (currText !== '') {
            names.push(currText);
        }
        matchAmount++;
        if (hasClosingBracket) {
            break;
        }
    }

    return hasClosingBracket ? { names, match: matchAmount } : { ...INVALID_LINE_NAMES_MATCH };
};

export const lineNamesPredicate: DataTypePredicate = (ast, index, items) => parseLineNames(ast, index, items).match;

// <track-size>
// syntax: <track-breadth> | minmax( <inflexible-breadth> , <track-breadth> ) | fit-content( [ <length> | <percentage> ] )
export const trackSizePredicate = predicateUnion([trackBreadthPredicate, functionPredicate(TRACK_SIZE_FUNCTIONS)]);

// <track-repeat> / <fixed-repeat> / <auto-repeat>
const repeatPredicate = functionPredicate(REPEAT_FUNCTION);

// <auto-repeat>
// syntax: repeat( [ auto-fill | auto-fit ] , [ <line-names>? <fixed-size> ]+ <line-names>? )
const autoRepeatPredicate: DataTypePredicate = (ast) => {
    if (repeatPredicate(ast)) {
        const firstArgPredicate = unorderedListPredicate(AUTO_REPEAT_FIRST_ARGUMENT_KEYWORDS);
        const firstArg = (ast as MethodCall).args[0];
        if (firstArg) {
            return firstArgPredicate(firstArg);
        }
    }

    return false;
};

// <fixed-size>
// syntax: <fixed-breadth> | minmax( <fixed-breadth> , <track-breadth> ) | minmax( <inflexible-breadth> , <fixed-breadth> )
const fixedSizePredicate = predicateUnion([lengthPercentagePredicate, functionPredicate(MINMAX_FUNCTION)]);

const singleTrackPredicate =
    (trackPredicates: DataTypePredicate[], asterisk = false): DataTypePredicate =>
    (ast, index, items) => {
        const startIndex = index ?? 0;
        const astItems = items ?? [{ value: ast }];

        let item: AstItem | undefined;
        let i = startIndex;
        let hasPrevNames = false;
        let isValid = asterisk;
        let matchAmount = 0;
        while ((item = astItems[i])) {
            const lineNamesMatch = parseLineNames(item.value, i, astItems).match;
            if (lineNamesMatch) {
                if (hasPrevNames) {
                    break;
                }
                i += Number(lineNamesMatch);
                matchAmount += Number(lineNamesMatch);
                hasPrevNames = true;
            } else if (trackPredicates.some((predicate) => predicate(item!.value))) {
                isValid = true;
                hasPrevNames = false;
                i++;
                matchAmount++;
            } else {
                break;
            }
        }

        return isValid ? matchAmount : false;
    };

// <track-list>
// syntax: [ <line-names>? [ <track-size> | <track-repeat> ] ]+ <line-names>?
export const trackListPredicate = singleTrackPredicate([trackSizePredicate, repeatPredicate]);

// <explicit-track-list>
// syntax: [ <line-names>? <track-size> ]+ <line-names>?
export const explicitTrackListPredicate = singleTrackPredicate([trackSizePredicate]);

// <track-list-with-strings>
// syntax: [ <line-names>? <string> <track-size>? <line-names>? ]+
export interface ParsedTrackWithString {
    string: string;
    trackSize: string;
    lineNamesBefore?: string[];
    lineNamesAfter?: string[];
}

export interface ParseTrackListWithStringsMatch extends ParsedPredicateMatch {
    tracks: ParsedTrackWithString[];
}

const EMPTY_TRACK: ParsedTrackWithString = { string: '', trackSize: INITIAL_TRACK_SIZE };
const INVALID_TRACK_MATCH: ParseTrackListWithStringsMatch = { tracks: [], match: false };
export const parseTrackListWithStrings: ParsedDataTypePredicate<ParseTrackListWithStringsMatch> = (
    ast,
    index,
    items
) => {
    const startIndex = index ?? 0;
    const astItems = items ?? [{ value: ast }];

    const tracks: ParsedTrackWithString[] = [{ ...EMPTY_TRACK }];
    let item: AstItem | undefined;
    let i = startIndex;
    let isValid = false;
    let matchAmount = 0;
    while ((item = astItems[i])) {
        if (item.value.type === 'string') {
            tracks[tracks.length - 1].string = item.value.text;
            isValid = true;
        } else {
            const hasString = tracks[tracks.length - 1].string === '';
            const lineNames = parseLineNames(item.value, i, astItems);
            if (lineNames.match) {
                if (hasString) {
                    if (tracks[tracks.length - 1].lineNamesBefore) {
                        return { ...INVALID_TRACK_MATCH };
                    }
                    tracks[tracks.length - 1].lineNamesBefore = lineNames.names;
                    isValid = false;
                } else {
                    tracks[tracks.length - 1].lineNamesAfter = lineNames.names;
                    tracks.push({ ...EMPTY_TRACK });
                }
                i += Number(lineNames.match) - 1;
                matchAmount += Number(lineNames.match) - 1;
            } else if (trackSizePredicate(item.value, i, astItems)) {
                if (hasString) {
                    return { ...INVALID_TRACK_MATCH };
                }
                tracks[tracks.length - 1].trackSize = item.value.text;
            } else {
                break;
            }
        }
        i++;
        matchAmount++;
    }

    if (isValid && tracks[tracks.length - 1].string === '') {
        tracks.pop();
    }

    return isValid ? { tracks, match: matchAmount } : { ...INVALID_TRACK_MATCH };
};

export const trackListWithStringsPredicate: DataTypePredicate = (ast, index, items) =>
    parseTrackListWithStrings(ast, index, items).match;

export const getTrackListAreasText = ({ tracks }: ParseTrackListWithStringsMatch) =>
    tracks.map(({ string }) => string).join(' ');

export const getTrackListRowsText = ({ tracks }: ParseTrackListWithStringsMatch) =>
    tracks
        .map(({ trackSize, lineNamesBefore, lineNamesAfter }, index) => {
            const beforeString = index === 0 ? (lineNamesBefore ? `[${lineNamesBefore.join(' ')}] ` : '') : '';

            let afterList = lineNamesAfter ?? [];
            const nextTrack = tracks[index + 1];
            // Splicing in the named lines defined before/after each size
            if (nextTrack && nextTrack.lineNamesBefore) {
                afterList = afterList.concat(nextTrack.lineNamesBefore);
            }
            const afterString = afterList.length > 0 ? ` [${afterList.join(' ')}]` : '';

            return `${beforeString}${trackSize}${afterString}`;
        })
        .join(' ');

// <auto-track-list>
// syntax: [ <line-names>? [ <fixed-size> | <fixed-repeat> ] ]* <line-names>? <auto-repeat> [ <line-names>? [ <fixed-size> | <fixed-repeat> ] ]* <line-names>?
export const autoTrackListPredicate: DataTypePredicate = (ast, index, items) => {
    const startIndex = index ?? 0;
    const astItems = items ?? [{ value: ast }];

    let item: AstItem | undefined;
    let i = startIndex;
    let autoRepeatIndex = -1;
    while ((item = astItems[i])) {
        if (autoRepeatPredicate(item.value)) {
            autoRepeatIndex = i;
            break;
        }
        i++;
    }

    if (autoRepeatIndex === -1) {
        return false;
    }

    const prefixSuffixTrackListPredicate = singleTrackPredicate([fixedSizePredicate, repeatPredicate], true);
    let matchAmount = 1;

    if (autoRepeatIndex > startIndex) {
        const prefixMatch = prefixSuffixTrackListPredicate(
            astItems[startIndex].value,
            0,
            astItems.slice(startIndex, autoRepeatIndex)
        );
        if (!prefixMatch || Number(prefixMatch) !== autoRepeatIndex - startIndex) {
            return false;
        }
        matchAmount += autoRepeatIndex - startIndex;
    }

    if (astItems[autoRepeatIndex + 1]) {
        matchAmount += Number(
            prefixSuffixTrackListPredicate(astItems[autoRepeatIndex + 1].value, 0, astItems.slice(autoRepeatIndex + 1))
        );
    }

    return matchAmount;
};

// <grid-template-areas>
// syntax: none | <string>+
export const gridTemplateAreasStringPredicate = typePredicate(['string']);

// <grid-auto-flow>
// syntax: auto-flow && dense?
export const gridAutoFlowPredicate: DataTypePredicate = (ast, index, items) => {
    const startIndex = index ?? 0;
    const astItems = items ?? [{ value: ast }];

    const autoFlowPredicate = unorderedListPredicate(GRID_AUTO_FLOW_KEYWORD);
    const densePredicate = unorderedListPredicate(GRID_AUTO_FLOW_DENSE_KEYWORD);

    let hasAutoFlow = false;
    let hasDense = false;

    let item: AstItem | undefined;
    let i = startIndex;
    while ((item = astItems[i++])) {
        if (autoFlowPredicate(item.value)) {
            if (hasAutoFlow) {
                break;
            }
            hasAutoFlow = true;
        } else if (densePredicate(item.value)) {
            if (hasDense) {
                break;
            }
            hasDense = true;
        } else {
            break;
        }
    }

    return hasAutoFlow ? +hasAutoFlow + +hasDense : false;
};

// <text-decoration-line>
// syntax: none | [ underline || overline || line-through ]
export const textDecorationLinePredicate: DataTypePredicate = (ast, index, items) => {
    const startIndex = index ?? 0;
    const astItems = items ?? [{ value: ast }];

    const currKeywords = new Map(TEXT_DECORATION_LINE_KEYWORDS);
    let currPredicate = unorderedListPredicate(currKeywords);
    let item: AstItem | undefined;
    let i = startIndex;
    let matchAmount = 0;
    while (currKeywords.size > 0 && (item = astItems[i++])) {
        if (currPredicate(item.value, i - 1, astItems)) {
            matchAmount++;
            currKeywords.delete(item.value.text);
            currPredicate = unorderedListPredicate(currKeywords);
        } else {
            break;
        }
    }

    return matchAmount;
};

// <border-image-slice>
// syntax: <number-percentage>{1,4} && fill?
export const borderImageSlicePredicate: DataTypePredicate = (ast, index, items) => {
    const startIndex = index ?? 0;
    const astItems = items ?? [{ value: ast }];

    const numberPercentagePredicates = [numberPredicate, percentagePredicate];
    const fillPredicate = unorderedListPredicate(BORDER_IMAGE_SLICE_FILL_KEYWORD);

    let item: AstItem | undefined;
    let i = startIndex;
    let matchAmount = 0;
    let matchedFill = false;
    let match: PredicateMatch | undefined;
    while ((item = astItems[i++])) {
        match = undefined;
        const fillMatch = fillPredicate(item.value, i - 1, astItems);
        if (fillMatch) {
            if (matchedFill) {
                break;
            }
            match = fillMatch;
            matchedFill = true;
        } else {
            for (const predicate of numberPercentagePredicates) {
                const numberPercentageMatch = predicate(item.value, i - 1, astItems);
                if (numberPercentageMatch) {
                    match = numberPercentageMatch;
                    break;
                }
            }
        }
        if (!match || ++matchAmount === (matchedFill ? 5 : 4)) {
            break;
        }
    }

    if (matchAmount === 4 && match && (item = astItems[i])) {
        return fillPredicate(item.value, i - 1, astItems) ? 5 : 4;
    }

    return matchAmount >= 1 ? (!matchedFill || matchAmount > 1 ? matchAmount : false) : false;
};
