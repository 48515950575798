import { DownArrow } from '@wix/stylable-panel-common-react';
import {
    getChildFromTree,
    Text,
    TreeDisplay,
    TreeDisplayItems,
    useClickOutside,
} from '@wix/stylable-panel-components';
import React, { MouseEventHandler, useRef, useState } from 'react';
import { classes, style } from './drop-down-tree.st.css';

export interface DropDownTreeProps {
    className?: string;
    items: TreeDisplayItems[];
    selector: string;
    onSelect?: (selector: string) => void;
    onHighlight?: (selector: string | null) => void;
}

export const DropDownTree = (props: DropDownTreeProps) => {
    const treeRef = useRef<HTMLDivElement>(null);
    const { items, selector, onHighlight } = props;
    useClickOutside(treeRef, () => {
        closeDropdown();
    });
    const [dropDownOpen, setDropDownOpen] = useState(false);

    const getSelectedItemDisplayName = (items: TreeDisplayItems[]) => {
        const selectorItem = getChildFromTree(items, selector);
        return selectorItem ? selectorItem.dataItem.name : selector;
    };

    const onSelect = (selector: string) => {
        props.onSelect?.(selector);
        closeDropdown();
    };

    const openDropdown: MouseEventHandler = (e) => {
        setDropDownOpen(true);
        e.stopPropagation();
    };

    const closeDropdown = () => {
        setDropDownOpen(false);
    };

    return (
        <div className={style(classes.root, props.className)}>
            <span className={classes.dropDown} onClick={openDropdown}>
                <Text className={classes.displayName}>{getSelectedItemDisplayName(items)}</Text>
                <DownArrow className={classes.downArrow} />
            </span>
            {dropDownOpen && (
                <TreeDisplay
                    className={classes.tree}
                    items={items}
                    onHover={onHighlight}
                    onSelect={onSelect}
                    ref={treeRef}
                    value={selector}
                    flatTree
                />
            )}
        </div>
    );
};
