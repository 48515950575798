import type { EvaluatedAst } from './shorthand-types';
import {
    INITIAL_FLEX_GROW,
    AUTO_FLEX_GROW,
    INITIAL_FLEX_SHRINK,
    NONE_FLEX_SHRINK,
    AUTO_FLEX_BASIS,
    INITIAL_GRID_TEMPLATE_AREAS,
    INITIAL_GRID_TEMPLATE_AXIS,
    INITIAL_GRID_AUTO_FLOW,
    INITIAL_GRID_AUTO_AXIS,
} from '../css-data-types';

export type Margins = 'margin-top' | 'margin-right' | 'margin-bottom' | 'margin-left';

export type Paddings = 'padding-top' | 'padding-right' | 'padding-bottom' | 'padding-left';

export type BorderRadiuses =
    | 'border-top-left-radius'
    | 'border-top-right-radius'
    | 'border-bottom-right-radius'
    | 'border-bottom-left-radius';
export type OpenedBorderRadiusShorthand<V> = Array<Record<BorderRadiuses, EvaluatedAst<V>>>;

export type BorderStyles = 'border-top-style' | 'border-right-style' | 'border-bottom-style' | 'border-left-style';
export type BorderWidths = 'border-top-width' | 'border-right-width' | 'border-bottom-width' | 'border-left-width';
export type BorderColors = 'border-top-color' | 'border-right-color' | 'border-bottom-color' | 'border-left-color';
export type BordersShallow = 'border-style' | 'border-width' | 'border-color';
export type Borders = BorderStyles | BorderWidths | BorderColors;
export type BordersTop = 'border-top-style' | 'border-top-width' | 'border-top-color';
export type BordersRight = 'border-right-style' | 'border-right-width' | 'border-right-color';
export type BordersBottom = 'border-bottom-style' | 'border-bottom-width' | 'border-bottom-color';
export type BordersLeft = 'border-left-style' | 'border-left-width' | 'border-left-color';

export type Outlines = 'outline-style' | 'outline-width' | 'outline-color';

export type BorderImages =
    | 'border-image-source'
    | 'border-image-slice'
    | 'border-image-width'
    | 'border-image-outset'
    | 'border-image-repeat';
export type OpenedBorderImageShorthand<V> = {
    'border-image-source': EvaluatedAst<V>;
    'border-image-slice': EvaluatedAst<V>[];
    'border-image-width': EvaluatedAst<V>[];
    'border-image-outset': EvaluatedAst<V>[];
    'border-image-repeat': EvaluatedAst<V>[];
};

export type Backgrounds =
    | 'background-attachment'
    | 'background-clip'
    | 'background-color'
    | 'background-image'
    | 'background-origin'
    | 'background-position'
    | 'background-repeat'
    | 'background-size';
export type BackgroundLayer<V> = {
    'background-attachment': EvaluatedAst<V>;
    'background-clip': EvaluatedAst<V>;
    'background-image': EvaluatedAst<V>;
    'background-origin': EvaluatedAst<V>;
    'background-position': EvaluatedAst<V>[];
    'background-repeat': EvaluatedAst<V>[];
    'background-size': EvaluatedAst<V>[];
};
export interface OpenedBackgroundShorthand<V> {
    layers: BackgroundLayer<V>[];
    color: EvaluatedAst<V>;
}

export type FontPrefixes = 'font-style' | 'font-variant' | 'font-weight' | 'font-stretch';
export type FontSuffixes = 'font-size' | 'line-height' | 'font-family';
export type Fonts = FontPrefixes | FontSuffixes | 'font';
export type OpenedFontShorthand<V> = {
    'font-style': EvaluatedAst<V>[];
    'font-variant': EvaluatedAst<V>;
    'font-weight': EvaluatedAst<V>;
    'font-stretch': EvaluatedAst<V>;
    'font-size': EvaluatedAst<V>;
    'line-height': EvaluatedAst<V>;
    'font-family': EvaluatedAst<V>[];
    font: EvaluatedAst<V>;
};

export type PlaceContents = 'align-content' | 'justify-content';
export type PlaceItems = 'align-items' | 'justify-items';

export type Flexes = 'flex-grow' | 'flex-shrink' | 'flex-basis';

export type FlexFlows = 'flex-direction' | 'flex-wrap' | 'align-items';

export type Gaps = 'row-gap' | 'column-gap';
export type GridGaps = 'grid-row-gap' | 'grid-column-gap';
export type GridRows = 'grid-row-start' | 'grid-row-end';
export type GridColumns = 'grid-column-start' | 'grid-column-end';
export type GridAxis = 'row' | 'column';
export type GridAxisLonghands<A extends GridAxis> = A extends 'row' ? GridRows : GridColumns;

export type GridTemplates = 'grid-template-areas' | 'grid-template-rows' | 'grid-template-columns';
export type Grids = GridTemplates | 'grid-auto-flow' | 'grid-auto-rows' | 'grid-auto-columns';

export type Overflows = 'overflow-x' | 'overflow-y';

export type TextDecorations = 'text-decoration-color' | 'text-decoration-line' | 'text-decoration-style';

export type ListStyles = 'list-style-type' | 'list-style-image' | 'list-style-position';

export type ShorthandsTypeMap = {
    background: Backgrounds;
    'border-radius': BorderRadiuses;
    border: Borders;
    'border-top': BordersTop;
    'border-right': BordersRight;
    'border-bottom': BordersBottom;
    'border-left': BordersLeft;
    'border-style': BorderStyles;
    'border-width': BorderWidths;
    'border-color': BorderColors;
    outline: Outlines;
    'border-image': BorderImages;
    font: Fonts;
    'place-content': PlaceContents;
    'place-items': PlaceItems;
    flex: Flexes;
    'flex-flow': FlexFlows;
    gap: Gaps;
    'grid-gap': GridGaps;
    'grid-row': GridRows;
    'grid-column': GridColumns;
    'grid-template': GridTemplates;
    grid: Grids;
    margin: Margins;
    padding: Paddings;
    overflow: Overflows;
    'text-decoration': TextDecorations;
    'list-style': ListStyles;
};

export type ShorthandsSpecialReturnTypeMap<V> = {
    background: OpenedBackgroundShorthand<V>;
    'border-radius': OpenedBorderRadiusShorthand<V>;
    font: OpenedFontShorthand<V>;
    'border-image': OpenedBorderImageShorthand<V>;
};

export type CssEdge = 'top' | 'right' | 'bottom' | 'left';
export type CssCorner = 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left';
export const CSS_PROPERTY_DELIMITER = '-';
export const EDGE_SHORTHAND_EDGES: CssEdge[] = ['top', 'right', 'bottom', 'left'];
export const CORNER_SHORTHAND_CORNERS: CssCorner[] = ['top-left', 'top-right', 'bottom-right', 'bottom-left'];
export const EDGE_SHORTHAND_INDICES_BY_LENGTH: number[][] = [
    [0, 0, 0, 0],
    [0, 1, 0, 1],
    [0, 1, 2, 1],
    [0, 1, 2, 3],
];

export type FlexKeyword = 'initial' | 'auto' | 'none';
export const FLEX_KEYWORD_VALUE_MAP: Record<FlexKeyword, Record<Flexes, string>> = {
    initial: {
        'flex-grow': INITIAL_FLEX_GROW,
        'flex-shrink': INITIAL_FLEX_SHRINK,
        'flex-basis': AUTO_FLEX_BASIS,
    },
    auto: {
        'flex-grow': AUTO_FLEX_GROW,
        'flex-shrink': INITIAL_FLEX_SHRINK,
        'flex-basis': AUTO_FLEX_BASIS,
    },
    none: {
        'flex-grow': INITIAL_FLEX_GROW,
        'flex-shrink': NONE_FLEX_SHRINK,
        'flex-basis': AUTO_FLEX_BASIS,
    },
};

export type GridKeyword = 'none';
export const GRID_TEMPLATE_KEYWORD_VALUE_MAP: Record<GridKeyword, Record<GridTemplates, string>> = {
    none: {
        'grid-template-areas': INITIAL_GRID_TEMPLATE_AREAS,
        'grid-template-rows': INITIAL_GRID_TEMPLATE_AXIS,
        'grid-template-columns': INITIAL_GRID_TEMPLATE_AXIS,
    },
};

export const GRID_KEYWORD_VALUE_MAP: Record<GridKeyword, Record<Grids, string>> = {
    none: {
        ...GRID_TEMPLATE_KEYWORD_VALUE_MAP.none,
        'grid-auto-flow': INITIAL_GRID_AUTO_FLOW,
        'grid-auto-rows': INITIAL_GRID_AUTO_AXIS,
        'grid-auto-columns': INITIAL_GRID_AUTO_AXIS,
    },
};
