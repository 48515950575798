import { isInRect } from '@wix/stylable-panel-common-react';
import { Option, OptionList } from '@wix/stylable-panel-components';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDeclarationMapValue, useTranslate } from '../../hooks';
import type { DeclarationVisualizerProps, VisualizerFC } from '../../types';
import { classes, style } from './icon-keyword-visualizer.st.css';
import { PopupIcon } from './popup-icon';

type IconKeywordFactoryProps = {
    options: Option[];
    tooltipKey: string;
    MainIcon: React.FunctionComponent;
};

export interface IconKeywordVisualizerProps<MAIN extends string> extends DeclarationVisualizerProps<MAIN> {}
export type IconKeywordVisualizer<MAIN extends string> = VisualizerFC<MAIN, IconKeywordVisualizerProps<MAIN>>;

export function IconKeywordVisualizerFactory<MAIN extends string>(
    main: MAIN,
    iconKeywordProps: IconKeywordFactoryProps
) {
    const { options, tooltipKey, MainIcon } = iconKeywordProps;

    const IconKeywordVisualizer: IconKeywordVisualizer<MAIN> = (props) => {
        const { panelHost, className } = props;
        const translate = useTranslate(panelHost);
        const popupIconRef = useRef<HTMLDivElement>(null);
        const [shouldOpenPopup, setShouldOpenPopup] = useState(false);

        const [value, handleChange] = useDeclarationMapValue(main, props);

        const popupIconIcon = useMemo(() => {
            const valueOption = options.find((option) => option.id === value);
            return valueOption?.icon;
        }, [value]);

        const handleDocumentMouseDown = useCallback((event: MouseEvent) => {
            if (!popupIconRef.current) {
                return;
            }

            const popupIconRect = popupIconRef.current.getBoundingClientRect();
            if (!isInRect(event.clientX, event.clientY, popupIconRect)) {
                setShouldOpenPopup(false);
            }
        }, []);

        const openPopup = useCallback(() => {
            document.addEventListener('mousedown', handleDocumentMouseDown);
            setShouldOpenPopup(true);
        }, [handleDocumentMouseDown]);

        const closePopup = useCallback(() => {
            document.removeEventListener('mousedown', handleDocumentMouseDown);
            setShouldOpenPopup(false);
        }, [handleDocumentMouseDown]);

        const onPopupIconChange = useCallback(
            (value: string) => {
                handleChange?.(value);
                closePopup();
            },
            [closePopup, handleChange]
        );

        return value ? (
            <PopupIcon
                className={style(classes.root, className)}
                iconKey={main}
                translate={translate}
                shouldOpenPopup={shouldOpenPopup}
                openPopup={openPopup}
                SelectedIcon={popupIconIcon}
                tooltipKey={tooltipKey}
                MainIcon={MainIcon}
                ref={popupIconRef}
            >
                <OptionList
                    className={classes.optionList}
                    value={value}
                    options={options}
                    onSelect={onPopupIconChange}
                    onClose={closePopup}
                />
            </PopupIcon>
        ) : null;
    };

    IconKeywordVisualizer.INPUT_PROPS = [main];

    return IconKeywordVisualizer;
}
