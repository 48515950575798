import { MarginProps, MarginVisualizer } from '@wix/stylable-panel-controllers';
import React, { FC } from 'react';
import { usePanelContext } from '../configurable-panel';
import type { CpControllerCommonProps } from '../cp-controller';
import { generateVisualizerProps } from '../utils/generate-visualizer-props';

export type CpMarginVisualizerProps = CpControllerCommonProps;
export const CpMarginVisualizer: FC<CpMarginVisualizerProps> = ({ editTargetSelector }) => {
    const context = usePanelContext();
    return (
        <MarginVisualizer
            {...generateVisualizerProps<MarginProps>({ context, editTargetSelector, key: 'CpMarginVisualizer' })}
        />
    );
};
