import React, { useMemo } from 'react';

import {
    DropDown,
    KeywordVisualizerComponentProps,
    KeywordVisualizerFactoryProps,
} from '@wix/stylable-panel-components';

import type { DeclarationVisualizerProps, VisualizerFC } from '../../types';
import { useTextFromSingleProp } from '../../hooks';

import { style, classes } from './keyword-visualizer.st.css';

export interface KeywordsVisualizerProps<MAIN extends string>
    extends DeclarationVisualizerProps<MAIN>,
        KeywordVisualizerComponentProps {}

export type KeywordDropdownVisualizer<MAIN extends string> = VisualizerFC<MAIN, KeywordsVisualizerProps<MAIN>>;

export function KeywordVisualizerFactory<MAIN extends string>(
    main: MAIN,
    keywordDropdownProps: KeywordVisualizerFactoryProps,
    defaultOptionId = ''
) {
    const KeywordVisualizer: KeywordDropdownVisualizer<MAIN> = (props) => {
        const { className, disabled } = props;

        const [value, handleChange] = useTextFromSingleProp(main, props);

        const hasOption = useMemo(() => keywordDropdownProps.options.some((option) => option.id === value), [value]);
        const dropDownValue = useMemo(() => (value && hasOption ? value : defaultOptionId), [hasOption, value]);

        return (
            <DropDown
                {...keywordDropdownProps}
                className={style(classes.root, className)}
                value={dropDownValue}
                onSelect={handleChange}
                disabled={disabled}
            />
        );
    };

    KeywordVisualizer.INPUT_PROPS = [main];

    return KeywordVisualizer;
}
