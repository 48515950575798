import React, { useMemo } from 'react';

import { DIMENSION_ID } from '@wix/stylable-panel-common';

import type { DeclarationIO } from '../../types';
import type { SetPropEdgeOptions } from './edge-driver';
import type { GetPropOptionASTNode, GetProp, SetProp } from '../../visualizer-driver-types';
import type { DimensionVisualizer } from '../dimension-visualizer-factory/dimension-visualizer-factory';
import { EdgeMainProp, Edges, SelectedEdge, EdgeProps, EdgeVisualizerProps } from './edge-types';
import { edgeToSelectedBorder } from './edge-utils';
import {
    getDimensionConfig,
    MarginBottomInputVisualizer,
    MarginLeftInputVisualizer,
    MarginRightInputVisualizer,
    MarginTopInputVisualizer,
    PaddingBottomInputVisualizer,
    PaddingLeftInputVisualizer,
    PaddingRightInputVisualizer,
    PaddingTopInputVisualizer,
} from '../../generated-visualizers/dimension-visualizers';
import { getVisualizerWrapper } from '../../utils';

import { classes, style } from './edge-visualizer.st.css';

type EdgeInputVisualizerType = DimensionVisualizer<EdgeProps>;

const edgeInputVisualizerMap: Record<EdgeMainProp, Record<Edges, EdgeInputVisualizerType>> = {
    padding: {
        top: PaddingTopInputVisualizer as EdgeInputVisualizerType,
        right: PaddingRightInputVisualizer as EdgeInputVisualizerType,
        bottom: PaddingBottomInputVisualizer as EdgeInputVisualizerType,
        left: PaddingLeftInputVisualizer as EdgeInputVisualizerType,
    },
    margin: {
        top: MarginTopInputVisualizer as EdgeInputVisualizerType,
        right: MarginRightInputVisualizer as EdgeInputVisualizerType,
        bottom: MarginBottomInputVisualizer as EdgeInputVisualizerType,
        left: MarginLeftInputVisualizer as EdgeInputVisualizerType,
    },
};

export interface EdgeInputProps {
    main: EdgeMainProp;
    edge: Edges;
    selectedState: SelectedEdge;
    editingState: SelectedEdge;
    linkedState: boolean;
    handleSelect: (select: SelectedEdge) => void;
    handleBlur: () => void;
    getProp: GetProp<EdgeProps>;
    setProp?: SetProp<EdgeProps, SetPropEdgeOptions>;
    beforeChange?: () => void;
    isDebounced?: boolean;
    debounceWait?: number;
    props: EdgeVisualizerProps;
}

export function EdgeInput({
    main,
    edge,
    selectedState,
    editingState,
    linkedState,
    getProp,
    setProp,
    handleSelect,
    handleBlur,
    beforeChange,
    isDebounced,
    debounceWait,
    props,
}: EdgeInputProps) {
    const { drivers, panelHost, propertyWrappers, DefaultPropertyWrapper, onDelete } = props;

    const edgeProp = useMemo(() => `${main}-${edge}` as EdgeProps, [main, edge]);
    const curreEdgeSelect = useMemo(() => edgeToSelectedBorder(edge), [edge]);

    const dimensionConfig = useMemo(
        () =>
            getDimensionConfig({
                id: main === 'padding' ? DIMENSION_ID.PADDING : DIMENSION_ID.MARGIN,
                dimensionUnits: panelHost?.dimensionUnits,
                dimensionKeywords: panelHost?.dimensionKeywords,
            }),
        [main, panelHost?.dimensionUnits, panelHost?.dimensionKeywords]
    );

    const EdgeInputVisualizer = useMemo(() => edgeInputVisualizerMap[main][edge], [main, edge]);
    const EdgeInputWrapper = useMemo(
        () => getVisualizerWrapper(propertyWrappers, edgeProp, DefaultPropertyWrapper),
        [edgeProp, propertyWrappers, DefaultPropertyWrapper]
    );

    const wrapperProps = useMemo(
        () =>
            ({
                value: getProp<GetPropOptionASTNode>(edgeProp),
                onChange: setProp?.(edgeProp, { linked: linkedState, beforeChange }),
                onDelete,
            } as DeclarationIO<EdgeProps>),
        [linkedState, beforeChange, edgeProp, getProp, setProp, onDelete]
    );

    return (
        <span
            className={style(classes.edgeControls, {
                edge,
                selected:
                    selectedState === curreEdgeSelect ||
                    (selectedState === SelectedEdge.All && curreEdgeSelect === SelectedEdge.Top),
            })}
            onClick={() => handleSelect(curreEdgeSelect)}
        >
            <EdgeInputWrapper title={edge} mainProp={edgeProp} claims={new Set([edgeProp])} {...wrapperProps}>
                <span className={classes.miniControllerWrapper}>
                    <EdgeInputVisualizer
                        className={classes.inputElement}
                        {...wrapperProps}
                        drivers={drivers}
                        config={dimensionConfig}
                        onBlur={() => handleBlur()}
                        onFocus={() => handleSelect(curreEdgeSelect)}
                        forceFocus={editingState === curreEdgeSelect}
                        panelHost={panelHost}
                        isDebounced={isDebounced}
                        debounceWait={debounceWait}
                    />
                </span>
            </EdgeInputWrapper>
        </span>
    );
}
