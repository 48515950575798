import React from 'react';
import { Text } from '../text/text';
import { classes, style } from './radio-button.st.css';

export interface RadioButtonProps {
    checked: boolean;
    id: string;
    label: string;
    className?: string;
    onChange?: () => void;
}

export const RadioButton: React.FC<RadioButtonProps> = ({ checked, className, onChange, label }) => {
    const onClick = (event: React.MouseEvent) => {
        event.preventDefault();
        onChange?.();
    };

    return (
        <label className={style(classes.root, className)} onClick={onClick}>
            <Text className={style(classes.label)}>{label}</Text>
            <input className={style(classes.input)} type="radio" defaultChecked={checked} />
        </label>
    );
};
