import { createCssValueAST, CSSCodeAst } from "../tokenizers";
import { getShorthandOpener } from "./shorthand-mapper";
import type { ShorthandsTypeMap } from "./shorthand-css-data";
import type { OpenedShorthand, ParseShorthandAPI } from "./shorthand-types";


type CSSCodeAstMap = Map<CSSCodeAst, CSSCodeAst[]>;
export type OpenPropAst<T extends string = string> = (
    ast: CSSCodeAst[],
    map?: CSSCodeAstMap,
    shallow?: boolean
) => OpenedShorthand<CSSCodeAst, T>;

const createSimpleApi = (map: CSSCodeAstMap): ParseShorthandAPI<CSSCodeAst> =>
    ({
        isExpression: (item) => map.has(item),
        getValue: (item) => map.get(item) || [],
        toString: (item) => item.text,
    } as ParseShorthandAPI<CSSCodeAst>);

const createOpenPropAst =
    <T extends keyof ShorthandsTypeMap>(prop: T): OpenPropAst<ShorthandsTypeMap[T]> =>
    (ast, map = new Map(), shallow = false) =>
        getShorthandOpener<CSSCodeAst, T>(prop)(ast, createSimpleApi(map), shallow) as unknown as OpenedShorthand<
            CSSCodeAst,
            ShorthandsTypeMap[T]
        >;

export const openShorthand = (prop: keyof ShorthandsTypeMap, value: string) => {
    const openPropAst = createOpenPropAst(prop);
    const ast = createCssValueAST(value);
    const openedShorthandRes = openPropAst(ast);
    const openedShorthandArray = (
        Array.isArray(openedShorthandRes) ? openedShorthandRes : [openedShorthandRes]
    ) as Array<typeof openedShorthandRes>;
    return openedShorthandArray;
};
