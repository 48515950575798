import type { IconProps as IconSVGProps } from '@wix/stylable-panel-common-react';
import React, { ReactElement } from 'react';
import { classes, style } from './icon.st.css';

interface IconProps {
    className?: string;
    children: ReactElement<IconSVGProps>;
}

export const Icon: React.FC<IconProps> = ({ children, className }) => (
    <span className={style(classes.root, className)}>{children}</span>
);
