import { TextProps, TextVisualizer } from '@wix/stylable-panel-controllers';
import React, { FC } from 'react';
import { usePanelContext } from '../configurable-panel';
import type { CpControllerCommonProps } from '../cp-controller';
import { generateVisualizerProps } from '../utils/generate-visualizer-props';

export type CpTextVisualizerCustomProps = {
    hideShownToggle?: boolean;
    glyphMode?: boolean;
    noResizing?: boolean;
    // TODO should add disableShownToggle, see https://jira.wixpress.com/browse/STYL-1021
};
export type CpTextVisualizerProps = CpControllerCommonProps & CpTextVisualizerCustomProps;
export const CpTextVisualizer: FC<CpTextVisualizerProps> = ({
    hideShownToggle,
    glyphMode,
    noResizing,
    editTargetSelector,
}) => {
    const controllerData = {
        hideShownToggle: hideShownToggle,
        glyphMode: glyphMode,
        noResizing: noResizing,
    };
    const context = usePanelContext();

    return (
        <TextVisualizer
            {...generateVisualizerProps<TextProps>({
                context,
                controllerData,
                editTargetSelector,
                key: 'CpTextVisualizer',
            })}
        />
    );
};
