import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import chroma from 'chroma-js';

import { BoxShadowMap, createCssValueAST, NONE_KEYWORD } from '@wix/shorthands-opener';

import {
    Coordinates,
    DEFAULT_PLANE,
    DIMENSION_ID,
    DIMENSION_INPUT_DEFAULTS,
    getCoordinatesFromMouseEvent,
    getDimensionInputDefaults,
} from '@wix/stylable-panel-common';
import { OptimisticWrapper, Tooltip } from '@wix/stylable-panel-components';
import {
    DEFAULT_LOAD_SITE_COLORS,
    DEFAULT_WRAP_SITE_COLOR,
    StylableComments,
    StylablePanelTranslationKeys,
} from '@wix/stylable-panel-drivers';

import type { DeclarationVisualizerProps, VisualizerFC } from '../../types';
import { DeclarationValue, OpenedDeclarationArray, getFullDeclarationsExpression } from '../../declaration-types';
import {
    ShadowProps,
    ShadowMap,
    ShadowOrigins,
    ShadowLayer,
    parseShadow,
    stringifyShadowLayer,
    stringifyShadow,
    createShadowAST,
    EMPTY_COLOR,
    ORIGIN_PROPS,
} from './shadow-utils';
import { calcShadowLayers } from './calc-shadow-layers';
import { useShadowPicker, useDeclarationMapValue, useTranslate } from '../../hooks';
import { LayersController, LAYER_HIDDEN_PROP } from '../../controllers';
import { getDimensionConfig, OpacityVisualizer } from '../../generated-visualizers';
import { PanelEventList } from '../../hosts/bi';
import {
    valueCommaNode,
    wrapDeclarationValue,
    visualizerOptimisticValueResolver,
    getPropValueDeclaration,
    isFullExpressionVisualizer,
    createDeclarationMapFromVisualizerValue,
    createVisualizerValueFromDeclarationMap,
    createShorthandOpenerApi,
    joinDeclarationValues,
} from '../../utils';

import { classes, style } from './shadows-visualizer.st.css';

const DEFAULT_COLOR = 'black';
const DEFAULT_OPACITY = DIMENSION_INPUT_DEFAULTS.shadowsVisualizerFactory.opacity;
const MAX_PREVIEW_SHADOW_SIZE = 3;

export { ShadowProps };
export type ShadowVisualizerProps = DeclarationVisualizerProps<ShadowProps>;

type ShadowVisualizerValue = OpenedDeclarationArray<ShadowProps>;

export type ShadowsVisualizer = VisualizerFC<ShadowProps>;

const getShadowLayerPreview = (shadow: ShadowMap) => {
    const previewShadow: ShadowMap = { ...shadow };

    const {
        'offset-x': { number: offsetX },
        'offset-y': { number: offsetY },
    } = previewShadow;

    // TODO: Set the units to 'px' if we take the MAX_PREVIEW_SHADOW_SIZE?

    if (offsetX > 0) {
        previewShadow['offset-x'].number = Math.min(offsetX, MAX_PREVIEW_SHADOW_SIZE);
    } else {
        previewShadow['offset-x'].number = Math.max(offsetX, -1 * MAX_PREVIEW_SHADOW_SIZE);
    }

    if (offsetY > 0) {
        previewShadow['offset-y'].number = Math.min(offsetY, MAX_PREVIEW_SHADOW_SIZE);
    } else {
        previewShadow['offset-y'].number = Math.max(offsetY, -1 * MAX_PREVIEW_SHADOW_SIZE);
    }

    if ((previewShadow as BoxShadowMap)['spread-radius']) {
        (previewShadow as BoxShadowMap)['spread-radius'].number = Math.min(
            (previewShadow as BoxShadowMap)['spread-radius'].number,
            MAX_PREVIEW_SHADOW_SIZE
        );
    }

    return stringifyShadowLayer(previewShadow);
};

export function ShadowsVisualizerFactory(propName: ShadowProps, supportComments = true) {
    const ShadowsVisualizer: ShadowsVisualizer = (props) => {
        const { value, drivers, plane = DEFAULT_PLANE, panelHost, siteVarsDriver, onChange, className, selectorState } = props;

        const translate = useTranslate(panelHost);
        const openShadowPickerPanel = useShadowPicker(propName, {
            className: classes.shadowInput,
            title: translate(StylablePanelTranslationKeys.controller.shadows.shadowPickerTitle),
            drivers,
            siteVarsDriver,
            panelHost,
        });

        const disabled = isFullExpressionVisualizer(propName, props);

        const shorthandApi = useMemo(() => createShorthandOpenerApi(drivers.variables), [drivers.variables]);
        const [textValue, handleChange] = useDeclarationMapValue(propName, props);
        const shadowLayers = useRef<ShadowLayer[]>([]);

        const [shouldOpenShadowPicker, setShouldOpenShadowPicker] = useState(false);
        const commentDriver = useRef<StylableComments | null>(supportComments ? new StylableComments('') : null);
        const fallbackCoordinates = useRef<Coordinates>({ x: window.innerWidth / 2, y: window.innerHeight / 2 });

        const DEFAULT_SHADOW = useMemo(
            () => getDimensionInputDefaults(panelHost).shadowsVisualizerFactory.newShadowDefault,
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [panelHost?.dimensionUnits?.defaults]
        );

        const shadows = useMemo(() => {
            shadowLayers.current = calcShadowLayers(
                propName,
                value,
                shorthandApi,
                panelHost,
                drivers.variables.getVariableValue,
                siteVarsDriver
            );

            const hasValue = textValue !== undefined && textValue !== NONE_KEYWORD;
            commentDriver.current = supportComments ? new StylableComments(hasValue ? textValue : '') : null;

            return !!commentDriver.current && hasValue && !commentDriver.current.indicesValid
                ? []
                : shadowLayers.current;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            shadowLayers,
            shorthandApi,
            textValue,
            value,
            drivers.variables.getVariableValue,
            siteVarsDriver,
            panelHost?.dimensionUnits?.defaults,
        ]);

        const prepareShadowsForChange = useCallback(
            (shadows: ShadowLayer[], appendToStart?: string) => {
                const loadSiteColors = siteVarsDriver?.loadSiteColors?.bind(siteVarsDriver) ?? DEFAULT_LOAD_SITE_COLORS;
                const wrapSiteColor = siteVarsDriver?.wrapSiteColor?.bind(siteVarsDriver) ?? DEFAULT_WRAP_SITE_COLOR;

                if (shadows.length === 0) {
                    return [];
                }

                loadSiteColors();
                for (const shadow of shadows) {
                    shadow.layer.color = wrapSiteColor(shadow.layer.color);
                }

                let shadowsForChange: string | DeclarationValue = [];
                if (commentDriver.current) {
                    const shadowLayers = [
                        commentDriver.current.setLayerComments(stringifyShadow(shadows.map(({ layer }) => layer))),
                    ];
                    if (appendToStart) {
                        shadowLayers.unshift(appendToStart);
                    }
                    shadowsForChange = shadowLayers.join(', ');
                } else {
                    const shadowLayers = shadows.map(createShadowAST);
                    if (appendToStart) {
                        shadowLayers.unshift(createCssValueAST(appendToStart));
                    }
                    shadowsForChange = joinDeclarationValues(shadowLayers, () => [valueCommaNode()]);
                }

                return [wrapDeclarationValue(propName, shadowsForChange, getPropValueDeclaration(value, propName))];
            },
            [value, siteVarsDriver]
        );

        const changeShadowLayer = useCallback(
            (layerValue: string, index: number, prop?: string, declarations?: OpenedDeclarationArray<string>) => {
                if (!onChange) {
                    return;
                }

                const origin = getFullDeclarationsExpression(declarations);
                const shadowsCopy = [...shadowLayers.current];

                shadowsCopy[index].layer = parseShadow(propName, layerValue, shorthandApi)[0];
                if (shadowsCopy[index].layer.color === EMPTY_COLOR) {
                    shadowsCopy[index].layer.color = DEFAULT_COLOR;
                }
                if (prop && ORIGIN_PROPS.includes(prop as keyof ShadowOrigins)) {
                    shadowsCopy[index].origins[prop as keyof ShadowOrigins] = origin ?? undefined;
                }

                onChange(prepareShadowsForChange(shadowsCopy));
            },
            [shadowLayers, prepareShadowsForChange, shorthandApi, onChange]
        );

        const openShadowPicker = useCallback(
            (value: string, index: number, coordinates: Coordinates) =>
                openShadowPickerPanel?.(
                    {
                        value,
                        onChange: (changeValue, prop, declarations) =>
                            changeShadowLayer(changeValue, index, prop, declarations),
                    },
                    coordinates
                ),
            [openShadowPickerPanel, changeShadowLayer]
        );

        useEffect(() => {
            if (shouldOpenShadowPicker) {
                openShadowPicker(DEFAULT_SHADOW, 0, fallbackCoordinates.current);
                setShouldOpenShadowPicker(false);
            }
        }, [openShadowPicker, shouldOpenShadowPicker, DEFAULT_SHADOW]);

        const addNewShadowLayer = useCallback(() => {
            if (!handleChange || !onChange) {
                return;
            }

            const appendToExisting = shadows.length && (shadows.length > 1 || shadows[0].layer.color !== EMPTY_COLOR);

            if (shadows.length && !appendToExisting) {
                setShouldOpenShadowPicker(true);
            }

            if (appendToExisting) {
                onChange(prepareShadowsForChange(shadows, DEFAULT_SHADOW));
            } else {
                handleChange(DEFAULT_SHADOW);
            }

            if (!panelHost) {
                return;
            }
            const { reportBI } = panelHost;
            const { ADD_LAYER_CLICK } = PanelEventList;
            reportBI?.(ADD_LAYER_CLICK, { type: 'shadow', state: selectorState ?? 'regular' });
        }, [handleChange, onChange, shadows, panelHost, selectorState, prepareShadowsForChange, DEFAULT_SHADOW]);

        const replaceShadowLayers = useCallback(
            (srcIndex: number, dstIndex: number) => {
                if (!onChange) {
                    return;
                }

                const shadowsCopy = [...shadows];

                const srcLayer = { ...shadowsCopy[srcIndex] };
                shadowsCopy[srcIndex] = shadowsCopy[dstIndex];
                shadowsCopy[dstIndex] = srcLayer;

                commentDriver.current?.replaceLayers(srcIndex, dstIndex);

                onChange(prepareShadowsForChange(shadowsCopy));
            },
            [shadows, prepareShadowsForChange, onChange]
        );

        const duplicateShadowLayer = useCallback(
            (index: number) => {
                if (!onChange) {
                    return;
                }

                const shadowsCopy = [...shadows];

                shadowsCopy.splice(index, 0, shadowsCopy[index]);

                commentDriver.current?.duplicateLayer(index);

                onChange(prepareShadowsForChange(shadowsCopy));
            },
            [shadows, prepareShadowsForChange, onChange]
        );

        // TODO: 'box-shadow: none' when we delete to no shadow
        const removeShadowLayer = useCallback(
            (index: number) => {
                if (!onChange) {
                    return;
                }

                const shadowsCopy = [...shadows];

                shadowsCopy.splice(index, 1);

                commentDriver.current?.removeLayer(index);

                onChange(prepareShadowsForChange(shadowsCopy));
            },
            [shadows, prepareShadowsForChange, onChange]
        );

        const showHideShadowLayer = useCallback(
            (index: number) => {
                if (!onChange) {
                    return;
                }

                commentDriver.current?.toggleCommentLayer(index);

                onChange(prepareShadowsForChange(shadows));
            },
            [shadows, prepareShadowsForChange, onChange]
        );
        const changeShadowLayerColorAlpha = useCallback(
            (value: string, index: number) => {
                if (!onChange) {
                    return;
                }

                const shadowsCopy = [...shadowLayers.current];

                try {
                    const color = chroma(shadowsCopy[index].layer.color);
                    const numberValue = parseFloat(value);
                    shadowsCopy[index].layer.color = color.alpha(numberValue / 100).css();
                    delete shadowsCopy[index].origins.color;

                    onChange(prepareShadowsForChange(shadowsCopy));
                } catch {
                    //
                }
            },
            [shadowLayers, prepareShadowsForChange, onChange]
        );

        const handleShadowBoxClick = useCallback(
            (event: React.MouseEvent, index: number, layerValue: string) => {
                const coordinates = getCoordinatesFromMouseEvent(event);
                fallbackCoordinates.current = coordinates;
                return openShadowPicker(layerValue, index, coordinates);
            },
            [openShadowPicker]
        );

        const renderShadowLayer = useCallback(
            (shadow: ShadowMap, origins: ShadowOrigins, index: number) => {
                const { value: defaultOpacityValue, unit: defaultOpacityUnit } = DEFAULT_OPACITY;
                const hidden = !!commentDriver.current?.isCommented(index);
                const layerValue = stringifyShadowLayer(shadow, origins);
                const colorValue = shadow.color;
                let opacityValue = defaultOpacityValue;
                try {
                    const color = chroma(colorValue);
                    opacityValue = Math.floor(color.alpha() * 100);
                } catch {
                    //
                }

                const isBoxShadow = propName === 'box-shadow';
                const noColor = colorValue === EMPTY_COLOR;
                const opacity = opacityValue + defaultOpacityUnit;
                const dimensionConfig = getDimensionConfig({
                    id: DIMENSION_ID.OPACITY,
                    dimensionUnits: panelHost?.dimensionUnits,
                    dimensionKeywords: panelHost?.dimensionKeywords,
                });

                return (
                    <span
                        key={`shadow_layer_${index}`}
                        className={style(classes.shadowLayer, { hidden })}
                        {...{ [LAYER_HIDDEN_PROP]: hidden }}
                    >
                        <Tooltip
                            text={
                                !hidden
                                    ? translate(StylablePanelTranslationKeys.controller.shadows.layerThumbnailTooltip)
                                    : undefined
                            }
                            verticalAdjust={-1}
                            horizontalAdjust={-5}
                        >
                            <span
                                className={classes.shadowBox}
                                onClick={(event) => (hidden ? null : handleShadowBoxClick(event, index, layerValue))}
                            >
                                <div
                                    className={classes.shadowPreview}
                                    style={{
                                        [isBoxShadow ? 'boxShadow' : 'textShadow']: getShadowLayerPreview(shadow),
                                    }}
                                >
                                    {isBoxShadow ? ' ' : 'Aa'}
                                </div>
                            </span>
                        </Tooltip>

                        <OpacityVisualizer
                            drivers={drivers}
                            className={classes.inputElementOpacity}
                            value={createVisualizerValueFromDeclarationMap({ opacity })}
                            config={dimensionConfig}
                            onChange={(value) => {
                                const { opacity } = createDeclarationMapFromVisualizerValue(value, {
                                    value: [],
                                    drivers,
                                });
                                if (opacity) {
                                    changeShadowLayerColorAlpha(opacity, index);
                                }
                            }}
                            isDisabled={hidden || noColor}
                            panelHost={panelHost}
                        />
                    </span>
                );
            },
            [changeShadowLayerColorAlpha, drivers, handleShadowBoxClick, panelHost, translate]
        );

        return (
            <LayersController
                className={style(classes.root, { disabled }, className)}
                title={translate(StylablePanelTranslationKeys.controller.shadows.title)}
                addLayerLabel={translate(StylablePanelTranslationKeys.controller.shadows.addLayerButtonLabel)}
                plane={plane}
                panelHost={panelHost}
                onAdd={addNewShadowLayer}
                onReplace={replaceShadowLayers}
                onDuplicate={duplicateShadowLayer}
                onRemove={removeShadowLayer}
                onHide={supportComments ? (_newHidden, index) => showHideShadowLayer(index) : undefined}
            >
                {shadows.map(({ layer, origins }, index) => renderShadowLayer(layer, origins, index))}
            </LayersController>
        );
    };

    ShadowsVisualizer.BLOCK_VARIANT_CONTROLLER = false;
    ShadowsVisualizer.INPUT_PROPS = [propName];
    return OptimisticWrapper<ShadowsVisualizer, ShadowVisualizerValue>(
        ShadowsVisualizer,
        {},
        true,
        visualizerOptimisticValueResolver
    );
}
