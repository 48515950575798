import { useMemo } from 'react';

import type { Coordinates } from '@wix/stylable-panel-common';

import type { ShadowProps } from '../visualizer-factories/shadows-visualizer-factory/shadow-utils';
import {
    FillPicker,
    FillPickerStaticProps,
    FillPickerDynamicProps,
    FillPickerProps,
} from '../pickers/fill-picker/fill-picker';
import {
    ColorPicker,
    ColorPickerStaticProps,
    ColorPickerDynamicProps,
    ColorPickerProps,
} from '../pickers/color-picker/color-picker';
import {
    SingleShadowInput,
    TextShadowLayerInput,
    ShadowPickerStaticProps,
    ShadowPickerDynamicProps,
    ShadowPickerProps,
} from '../inputs';

export function useFillPicker({ title, drivers, panelHost, siteVarsDriver, border, className }: FillPickerStaticProps) {
    return useMemo(
        () =>
            panelHost?.onOpenPanel
                ? ({ value, tab, onChange, onAstChange, onClose }: FillPickerDynamicProps) => {
                      panelHost.onOpenPanel?.(FillPicker.panelName, {
                          // key: `fill_picker_${index}`,
                          className,
                          title,
                          siteVarsDriver,
                          value,
                          tab,
                          drivers,
                          panelHost,
                          onChange,
                          onAstChange,
                          onClose,
                          border,
                      } as FillPickerProps);
                  }
                : undefined,
        [title, drivers, panelHost, siteVarsDriver, border, className]
    );
}

export function useColorPicker({ title: staticTitle, panelHost, siteVarsDriver, className }: ColorPickerStaticProps) {
    return useMemo(
        () =>
            panelHost?.onOpenPanel
                ? ({ title, currentColor, onChange, onHover, onReset, onBlur }: ColorPickerDynamicProps) => {
                      panelHost.onOpenPanel?.(ColorPicker.panelName, {
                          // key: `color_picker_${index}`,
                          className,
                          title: title || staticTitle,
                          siteVarsDriver,
                          currentColor,
                          panelHost,
                          onChange,
                          onHover,
                          onReset,
                          onBlur,
                      } as ColorPickerProps);
                  }
                : undefined,
        [staticTitle, panelHost, siteVarsDriver, className]
    );
}

export function useShadowPicker(
    propName: ShadowProps,
    { title, drivers, panelHost, siteVarsDriver, plane, className }: ShadowPickerStaticProps
) {
    const panelName = useMemo(
        () => (propName === 'box-shadow' ? SingleShadowInput.panelName : TextShadowLayerInput.panelName),
        [propName]
    );
    return useMemo(
        () =>
            panelHost?.onOpenPanel
                ? (
                      { value, outsideValues, onChange, onOutsideChange }: ShadowPickerDynamicProps,
                      coordinates: Coordinates
                  ) => {
                      panelHost.onOpenPanel?.(
                          panelName,
                          {
                              // key: `shadow_picker_${index}`,
                              className,
                              title,
                              value,
                              outsideValues,
                              drivers,
                              siteVarsDriver,
                              panelHost,
                              plane,
                              onChange,
                              onOutsideChange,
                          } as ShadowPickerProps,
                          coordinates
                      );
                  }
                : undefined,
        [panelName, title, drivers, panelHost, siteVarsDriver, plane, className]
    );
}
