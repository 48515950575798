import {
    DEFAULT_PLANE,
    SelectorConfiguration,
    SelectorConfigurationDisplay,
    StylePanelPlane,
} from '@wix/stylable-panel-common';
import { DownArrow, isInRect } from '@wix/stylable-panel-common-react';
import { getChildFromTree, Text, TreeDisplay, TreeDisplayItems } from '@wix/stylable-panel-components';
import { BIParams, getTranslate, PanelEventList, TranslateFunc } from '@wix/stylable-panel-controllers';
import {
    ElementTree,
    SelectorConfigurationDriver,
    StylablePanelTranslationKeys,
    StylesheetDriver,
} from '@wix/stylable-panel-drivers';
import keycode from 'keycode';
import React from 'react';
import type { StylablePanelHost } from '../../types';
import { classes, style } from './elementree.st.css';

const NATIVE_PSEUDO_ELEMENTS = ['before', 'after'];

export interface ElementreeProps {
    selector: string;
    elements: ElementTree;
    selectorConfiguration?: SelectorConfigurationDriver;
    styleSheet: StylesheetDriver | null;
    panelHost?: StylablePanelHost;
    plane?: StylePanelPlane;
    hideOnSingleItem?: boolean;
    handleStateOverrides?: () => void;
    reportBI?: (event: PanelEventList, params?: BIParams) => void;
    onSelect?: (selector: string) => void;
    className?: string;
    disableAddPseudoElementsToNode?: boolean; // TODO should remove with experiment
}

export interface ElementreeState {
    dropDownOpen: boolean;
}

function addPseudoElementsToNode(styleSheet: StylesheetDriver | null, id: string, childrenCurrent: TreeDisplayItems[]) {
    // Add native pseudo-elements:
    if (styleSheet) {
        NATIVE_PSEUDO_ELEMENTS.forEach((pseudoElement) => {
            let matchFound = false; // prevent adding same pseudo element twice
            const selectorWithPseudo = id + '::' + pseudoElement;
            styleSheet.walkRules((rule) => {
                if (rule.selectors && !matchFound) {
                    if (rule.selectors.find((sel) => sel === selectorWithPseudo)) {
                        childrenCurrent.push({
                            id: selectorWithPseudo,
                            dataItem: {
                                name: pseudoElement,
                            },
                        });
                        matchFound = true;
                    }
                }
            });
        });
    }
}

/**
 * @deprecated This will be replaced by cp-elements.ts
 */
export default class Elementree extends React.Component<ElementreeProps, ElementreeState> {
    private treeDisplayRef = React.createRef<HTMLDivElement>();
    public state: ElementreeState = { dropDownOpen: false };

    public render() {
        const { panelHost, plane = DEFAULT_PLANE, hideOnSingleItem, className } = this.props;

        const translate = getTranslate(panelHost);
        const nodes: TreeDisplayItems[] = [];
        this.generateTreeFromSelectorConfiguration(translate, nodes);
        const nodesDeepCount = this.countNodes(nodes);
        if (hideOnSingleItem && nodesDeepCount <= 1) {
            return null;
        }

        return (
            <div className={style(classes.root, { plane }, className)} data-aid="st_component_part_selector">
                {this.renderDropDown(nodes)}
            </div>
        );
    }

    public componentWillUnmount() {
        this.cleanupEventListeners();
    }

    private countNodes = (tree: TreeDisplayItems[], counter = 0): number => {
        if (!tree.length) return counter;
        for (let i = 0; i < tree.length; i++) {
            counter++;
            if (tree[i].children?.length) {
                return this.countNodes(tree[i].children!, counter);
            }
        }
        return counter;
    };

    private renderTreeDisplay(items: TreeDisplayItems[], className: string, flatTree = false) {
        const { selector, panelHost } = this.props;
        const onHighlight = panelHost ? panelHost.onHighlight : undefined;

        return (
            <TreeDisplay
                className={className}
                items={items}
                value={selector}
                flatTree={flatTree}
                onHover={onHighlight}
                onSelect={(value: string) => this.onSelect(value)}
                ref={this.treeDisplayRef}
            />
        );
    }

    private renderDropDown(items: TreeDisplayItems[]) {
        const { dropDownOpen } = this.state;

        const translate = getTranslate(this.props.panelHost);

        return (
            <div className={classes.dropDownRoot}>
                {translate(StylablePanelTranslationKeys.elementree.dropDownLabel)}
                <div className={classes.dropDownWrapper}>
                    <span className={classes.dropDown} onClick={() => this.openDropdown()}>
                        <Text className={classes.dropDownText}>{this.getSelectedItemDisplayName(items)}</Text>
                        <DownArrow className={classes.downArrow} />
                    </span>
                    {dropDownOpen ? this.renderTreeDisplay(items, classes.dropDownTree, true) : null}
                </div>
            </div>
        );
    }

    private getSelectedItemDisplayName(items: TreeDisplayItems[]) {
        const { selector } = this.props;
        const selectorItem = getChildFromTree(items, selector);
        return selectorItem ? selectorItem.dataItem.name : selector;
    }

    private openDropdown() {
        document.addEventListener('click', this.handleDocumentClick);
        document.addEventListener('keydown', this.handleKeyDown);
        this.setState({ dropDownOpen: true });
    }

    private handleDocumentClick = (event: MouseEvent) => {
        if (
            this.treeDisplayRef.current &&
            !isInRect(event.clientX, event.clientY, this.treeDisplayRef.current.getBoundingClientRect())
        ) {
            this.closeDropdown();
        }
    };

    private handleKeyDown = (event: KeyboardEvent) => {
        if (event.keyCode === keycode('esc')) {
            this.closeDropdown();
        }
    };

    private closeDropdown = () => {
        this.cleanupEventListeners();
        this.setState({ dropDownOpen: false });
    };

    private cleanupEventListeners() {
        document.removeEventListener('click', this.handleDocumentClick);
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    private generateTreeFromSelectorConfiguration = (translate: TranslateFunc, items: TreeDisplayItems[]) => {
        const { elements, selectorConfiguration, styleSheet } = this.props;
        const walkTree = (rootOrigin: ElementTree, rootTarget: TreeDisplayItems[], parentName: string) =>
            Object.keys(rootOrigin).forEach((node) => {
                let replaceSelfWithChildren = false;
                const id = parentName ? `${parentName}::${node}` : node;
                const override: SelectorConfiguration | undefined =
                    selectorConfiguration && selectorConfiguration.getSelectorConfiguration(id);

                // Hide element if requested:
                if (override !== undefined) {
                    if (override.foldInto) {
                        return;
                    }

                    if (override.display) {
                        switch (override.display) {
                            case SelectorConfigurationDisplay.Hidden:
                                return;
                            case SelectorConfigurationDisplay.ReplaceSelfWithOwnChildren:
                                replaceSelfWithChildren = true;
                                break;
                        }
                    }

                    //Deprecated:
                    if (override.hide) {
                        return;
                    }
                }
                const displayName = override ? (override.nameKey ? translate(override.nameKey) : node) : node;

                const childrenCurrent: TreeDisplayItems[] = [];
                if (!replaceSelfWithChildren) {
                    // Default:
                    rootTarget.push({
                        id,
                        dataItem: {
                            name: displayName,
                        },
                        children: childrenCurrent,
                    });

                    if (!this.props.disableAddPseudoElementsToNode) {
                        addPseudoElementsToNode(styleSheet, id, childrenCurrent);
                    }

                    walkTree(rootOrigin[node], childrenCurrent, id);
                } else {
                    walkTree(rootOrigin[node], rootTarget, id);
                }
            });
        walkTree(elements, items, '');
    };

    private onSelect = (targetSelector: string) => {
        const { handleStateOverrides, onSelect, panelHost, selector } = this.props;
        if (targetSelector !== selector) {
            onSelect && onSelect(targetSelector);
            handleStateOverrides && handleStateOverrides();
        }
        panelHost?.onHighlight && panelHost?.onHighlight(null);
        this.closeDropdown();
    };
}
