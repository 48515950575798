import React from 'react';
import { classes, style } from './button.st.css';

type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    isChecked?: boolean;
    isOpen?: boolean;
    isSelected?: boolean;
    isToggleButton?: boolean;
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
    { children, className, isChecked = false, isOpen = false, isSelected = false, isToggleButton = false, ...props },
    ref
) {
    return (
        <button
            className={style(
                classes.root,
                { checked: isChecked, open: isOpen, selected: isSelected, toggleButton: isToggleButton },
                className
            )}
            ref={ref}
            {...props}
        >
            {children}
        </button>
    );
});
