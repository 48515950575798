export class ShorthandParserError extends Error {
    constructor(message: string, prop?: string) {
        super(`Shorthand Parser Error! ${prop ? '[' + prop + '] ' : ''}${message}`);
    }
}

export class NoDataTypeMatchError extends ShorthandParserError {
    constructor(value: string) {
        super(`No data-type match: "${value}".`);
    }
}

export class NoMandatoryPartMatchError extends ShorthandParserError {
    constructor(prop: string, partProp: string) {
        super(`No mandatory match on shorthand part: "${partProp}".`, prop);
    }
}

export class InvalidIntersectionValueError extends ShorthandParserError {
    constructor(prop: string, value: string) {
        super(`Invalid value for intersection: "${value}"`, prop);
    }
}

export class InvalidEdgesInputLengthError extends ShorthandParserError {
    constructor(prop: string, length: number) {
        super(`Invalid edges input length: "${length}".`, prop);
    }
}

export class CannotCloseBorderError extends ShorthandParserError {
    constructor() {
        super(`Cannot close border object.`, 'border');
    }
}

export class CannotCloseGridTemplateError extends ShorthandParserError {
    constructor() {
        super(`Cannot close grid-template object.`, 'grid-template');
    }
}

export class CannotCloseGridError extends ShorthandParserError {
    constructor() {
        super(`Cannot close grid object.`, 'grid');
    }
}
