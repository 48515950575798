import { parse as parseSelector, SelectorNode, SelectorNodeType } from 'css-selector-tokenizer';
import { nativePseudoElementsSet } from '../utils/native-pseudo-elements-set';

type specificityArray = [number, number, number];
type Options = { stylableMode: boolean };
/*
    Here we assume that stylable pseudo-elements have specificity of class,
    it's true for most cases but it dose not support @custom-selector and mapped selectors (-st-global)
*/
const categorizeSelectorNodes = (nodes: SelectorNodeType[], options: Options): string[] => {
    return nodes
        .map((node) => {
            switch (node.type) {
                case 'universal':
                case 'spacing':
                case 'operator':
                case 'comment':
                case 'invalid':
                    return '';
                case 'element':
                    return 'element';
                case 'pseudo-element':
                    if (options.stylableMode && !nativePseudoElementsSet.has(node.name)) {
                        /* 
                            this is a very simplified approach
                            it dose not take into account custom selectors 
                        */
                        return 'class';
                    }
                    return 'element';
                case 'class':
                case 'pseudo-class':
                case 'attribute':
                    return 'class';
                case 'id':
                    return 'id';
                case 'nested-pseudo-class':
                    if (node.nodes.length > 1) {
                        throw Error(`Too many selectors inside nested pseudo-class ${node.name}`);
                    }
                    return categorizeSelectorNodes(node.nodes[0].nodes, options);
            }
        })
        .reduce<string[]>(
            // flatten
            (previous, current) => (current ? previous.concat(current) : previous),
            []
        );
};
const countNodeTypes = (nodes: SelectorNodeType[], options: Options) =>
    categorizeSelectorNodes(nodes, options).reduce<{ [key: string]: number }>(
        (previous, current) => ({
            ...previous,
            [current]: (previous[current] || 0) + 1,
        }),
        {}
    );

const specificityFromSelectorNodes = (nodes: SelectorNodeType[], options: Options): specificityArray => {
    const counted = countNodeTypes(nodes, options);

    return [counted.id || 0, counted.class || 0, counted.element || 0];
};

export const calculateSpecificity = (selector: string | SelectorNode, options: Options) => {
    const parsed = typeof selector === 'string' ? parseSelector(selector) : selector;

    if (parsed.nodes.length > 1) throw Error('Too many selectors.');

    const selectorNode = parsed.nodes[0];
    if (selectorNode.type !== 'selector') throw Error('Not a selector.');

    return specificityFromSelectorNodes(selectorNode.nodes, options);
};

export const matchSpecificity = (a: specificityArray, b: specificityArray) => {
    const length: specificityArray['length'] = 3;
    for (let i = 0; i < length; i++) {
        if (a[i] < b[i]) {
            return -1;
        } else if (a[i] > b[i]) {
            return 1;
        }
    }
    return 0;
};
