import type { CategoryConfig } from '../../../types';

import { fillBlockVariants } from './category-config/fill';
import { textBlockVariants } from './category-config/text';
import { borderBlockVariants } from './category-config/border';
import { cornerBlockVariants } from './category-config/corners';
import { shadowBlockVariants } from './category-config/shadow';
import { layoutBlockVariants } from './category-config/layout';
// import { spacingBlockVariants } from './category-config/spacing';
// import { fxBlockVariants } from './category-config/fx';
import { iconBlockVariants } from './category-config/icon';

export function setupCategoryConfig(): CategoryConfig {
    return {
        fill: fillBlockVariants,
        text: textBlockVariants,
        borders: borderBlockVariants,
        corners: cornerBlockVariants,
        shadow: shadowBlockVariants,
        layout: layoutBlockVariants,
        // spacing: spacingBlockVariants,
        // fx: fxBlockVariants,
        icon: iconBlockVariants,
    };
}
