import { DEFAULT_PLANE } from '@wix/stylable-panel-common';
import { TabArrow } from '@wix/stylable-panel-common-react';
import React from 'react';
import { Icon } from '../icon/icon';
import { Scrollbars } from '../scrollbars';
import { Tooltip, TooltipAttachTo } from '../tooltip/tooltip';
import { TabButton, TabIcon } from './tab-button';
import { classes, style } from './tabs.st.css';

export interface SectionDesc {
    id: string;
    customButton?: TabIcon;
    highlight?: boolean;
}

export type Sections = SectionDesc[];
export type TabContent = JSX.Element | JSX.Element[] | undefined;

export enum TabsPlane {
    Horizontal = 'horizontal',
    Vertical = 'vertical',
    VerticalCollapse = 'verticalCollapse',
}

export const DEFAULT_TABS_PLANE: TabsPlane = TabsPlane.Horizontal;

export interface TabsProps {
    content: (id: string) => TabContent;
    sections: Sections;
    className?: string;
    'data-aid'?: string;
    initialTab?: number;
    noScroll?: boolean;
    noSingleTab?: boolean;
    onSelectTab?: (index: number, isOpen: boolean | undefined) => void;
    plane?: TabsPlane;
    preferredTab?: string;
    sectionIdToTitlesFunction?: (id: string) => string;
    tooltips?: boolean;
}

export type OpenSections = Record<string, boolean>;

export interface ControlledTabsProps extends TabsProps {
    selectedTab: string | undefined;
    openSections?: OpenSections;
    onOpenSections?: (openSections: OpenSections) => void;
    onSelectedTab?: (tab: string) => void;
}

export class ControlledTabs extends React.Component<ControlledTabsProps> {
    public static defaultProps: Partial<TabsProps> = {
        sectionIdToTitlesFunction: (id: string) => id,
        plane: DEFAULT_TABS_PLANE,
    };

    public render() {
        const { className, sections, selectedTab, plane = DEFAULT_PLANE } = this.props;

        if (sections.length < 1) {
            return <div />;
        }

        const isSingleTab = this.isSingleTab();
        const isVerticalCollapse = plane === TabsPlane.VerticalCollapse;

        return (
            <div
                className={style(
                    classes.root,
                    {
                        selected: !!selectedTab,
                        plane,
                    },
                    className
                )}
                data-aid={this.props['data-aid']}
            >
                {isVerticalCollapse || !isSingleTab ? (
                    <nav className={classes.tabBar}>{this.renderTabSections()}</nav>
                ) : null}
                {!isVerticalCollapse ? this.renderTabContentContainer() : null}
            </div>
        );
    }

    public componentDidMount() {
        this.initTabs();
    }

    public componentDidUpdate() {
        this.initTabs();
    }

    private initTabs() {
        if (this.shouldOpenBestGuessTab()) {
            this.openBestGuessTab();
        }
    }

    private openBestGuessTab() {
        const { preferredTab, sections, selectedTab, onOpenSections, onSelectedTab } = this.props;

        if (sections.length > 0 && !sections.some((section) => section.id === selectedTab)) {
            // try preferred section first:
            const id = sections.some((section) => section.id === preferredTab) ? preferredTab : sections[0].id;
            const openSections = this.getOpenSections(this.props, id);

            id && onSelectedTab?.(id);
            openSections && onOpenSections?.(openSections);
        }
    }

    private renderTabSections() {
        const { plane, sections, selectedTab, tooltips } = this.props;

        const hasSelection = selectedTab !== undefined;
        const isVerticalCollapse = plane === TabsPlane.VerticalCollapse;
        const showTooltips = tooltips && hasSelection;

        let sectionRenderFunction = this.renderTabButton;
        if (isVerticalCollapse) {
            sectionRenderFunction = this.renderVerticalCollapseTabSections;
        } else if (showTooltips) {
            sectionRenderFunction = this.renderTabButtonTooltipWrapper;
        }

        return sections.map(sectionRenderFunction);
    }

    private renderTabButton = (section: SectionDesc, index: number) => {
        const { openSections, plane, selectedTab, sectionIdToTitlesFunction = (id: string) => id } = this.props;

        const hasSelection = selectedTab !== undefined;
        const isVertical = plane === TabsPlane.Vertical;
        const isVerticalCollapse = plane === TabsPlane.VerticalCollapse;
        const showTabTitle = !isVertical || (isVertical && !hasSelection);

        return (
            <TabButton
                key={`tab_button_${section.id}`}
                className={classes.tabButton}
                id={section.id}
                title={showTabTitle ? sectionIdToTitlesFunction(section.id) : undefined}
                noIcon={!isVertical}
                selected={section.id === selectedTab}
                open={openSections?.[section.id]}
                onClick={() => this.onTabButtonClick(index, section.id)}
                icon={section.customButton}
                openIndicator={
                    isVerticalCollapse ? (
                        <Icon>
                            <TabArrow className={classes.tabArrow} />
                        </Icon>
                    ) : undefined
                }
            />
        );
    };

    private renderTabButtonTooltipWrapper = (section: SectionDesc, index: number) => {
        const { plane, selectedTab, sectionIdToTitlesFunction = (id: string) => id } = this.props;

        const hasSelection = selectedTab !== undefined;
        const isHorizontal = plane === TabsPlane.Horizontal;

        return (
            <Tooltip
                key={section.id}
                className={classes.tabButtonWrapper}
                text={hasSelection ? sectionIdToTitlesFunction(section.id) : undefined}
                attachTo={isHorizontal ? TooltipAttachTo.Top : TooltipAttachTo.Right}
                verticalAdjust={isHorizontal ? 10 : undefined}
            >
                {this.renderTabButton(section, index)}
            </Tooltip>
        );
    };

    private renderVerticalCollapseTabSections = (section: SectionDesc, index: number) => {
        const { openSections } = this.props;
        const open = openSections?.[section.id];

        return (
            <div key={section.id} className={style(classes.sectionWrapper, { open })}>
                {this.renderTabButton(section, index)}
                {open && this.renderContent(section.id)}
            </div>
        );
    };

    private renderTabContentContainer() {
        const { noScroll, selectedTab } = this.props;

        return (
            <div className={classes.tabContentContainer}>
                {selectedTab ? (
                    <div className={classes.tabContent}>
                        {!noScroll ? <Scrollbars universal>{this.renderContent()}</Scrollbars> : this.renderContent()}
                    </div>
                ) : null}
            </div>
        );
    }

    private renderContent(id = this.props.selectedTab) {
        const { content } = this.props;
        return id && content(id);
    }

    private getOpenSections(props: TabsProps, selectedTab?: string) {
        const { sections, plane } = props;

        if (plane !== TabsPlane.VerticalCollapse) {
            return;
        }

        return sections.reduce<OpenSections>((openSections, section) => {
            openSections[section.id] = !!selectedTab && selectedTab === section.id;
            return openSections;
        }, {});
    }

    private onTabButtonClick(index: number, id: string) {
        const { onSelectTab, openSections, onOpenSections, onSelectedTab } = this.props;
        onSelectedTab?.(id);

        const isOpen = openSections ? !openSections[id] : undefined;
        if (openSections) {
            openSections[id] = !!isOpen;
            onOpenSections?.(openSections);
            this.forceUpdate();
        }

        onSelectTab?.(index, isOpen);
    }

    private isSingleTab() {
        const { sections, noSingleTab } = this.props;
        return !!noSingleTab && sections.length === 1;
    }

    private shouldOpenBestGuessTab() {
        const { preferredTab, selectedTab, plane = DEFAULT_PLANE } = this.props;
        return (
            plane !== TabsPlane.Vertical ||
            preferredTab !== undefined ||
            this.isSingleTab() ||
            selectedTab !== undefined
        );
    }
}
