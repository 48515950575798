import type { PlaceItems } from '../shorthand-css-data';
import type {
    SimpleShorthandOpener,
    ShorthandPart,
    GetSimpleShorthandOpener,
    GetShorthandCloser,
} from '../shorthand-types';

import { alignItemsDataType, justifyItemsDataType } from '../../css-data-types';
import {
    intersectionBeforeOpenerCheck,
    splitSimpleShorthandOpener,
    unorderedListShorthandOpener,
    createShorthandOpener,
    createShorthandCloserTemplateFromParts,
    createShorthandCloser,
} from '../shorthand-parser-utils';

const getPlaceItemsShorthandParts = <V>() =>
    [
        {
            prop: 'align-items',
            dataType: alignItemsDataType,
            beforeOpenerCheck: intersectionBeforeOpenerCheck('place-items', [alignItemsDataType, justifyItemsDataType]),
            partOpener: splitSimpleShorthandOpener(['align-items', 'justify-items']),
        },
        {
            prop: 'justify-items',
            dataType: justifyItemsDataType,
            beforeOpenerCheck: intersectionBeforeOpenerCheck('place-items', [alignItemsDataType, justifyItemsDataType]),
        },
    ] as ShorthandPart<V, PlaceItems>[];

// place-items
export const openPlaceItemsShorthand: GetSimpleShorthandOpener<PlaceItems> = <V>() =>
    createShorthandOpener({
        prop: 'place-items',
        parts: getPlaceItemsShorthandParts(),
        shorthandOpener: (astNodes, api, parts) => unorderedListShorthandOpener(parts)(astNodes, api),
    }) as SimpleShorthandOpener<V, PlaceItems>;

export const closePlaceItemsShorthand: GetShorthandCloser<PlaceItems> = <V>() =>
    createShorthandCloser<V, PlaceItems>(createShorthandCloserTemplateFromParts(getPlaceItemsShorthandParts()));
