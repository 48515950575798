import {
    VisualizerFC,
    VisualizerType,
    BackgroundVisualizer,
    BorderVisualizer,
    CornerVisualizer,
    IconVisualizer,
    MarginVisualizer,
    PaddingVisualizer,
    ShadowsVisualizerFactory,
    TextVisualizer,
} from '@wix/stylable-panel-controllers';
import LayoutController from './layout-controller-setup';

export function setup(): Record<string, VisualizerType> {
    return {
        /******************************** Fill ********************************/
        background: BackgroundVisualizer as VisualizerFC,
        /******************************** Text ********************************/
        text: TextVisualizer as VisualizerFC,
        /******************************* Borders ******************************/
        border: BorderVisualizer as VisualizerFC,
        /******************************* Corners ******************************/
        'border-radius': CornerVisualizer as VisualizerFC,
        /******************************* Layout *******************************/
        layout: LayoutController,
        /******************************* Padding ******************************/
        padding: PaddingVisualizer as VisualizerFC,
        /******************************* Spacing ******************************/
        margin: MarginVisualizer as VisualizerFC,
        /******************************* Shadow *******************************/
        'box-shadow': ShadowsVisualizerFactory('box-shadow') as VisualizerFC,
        /********************************* Icon *******************************/
        icon: IconVisualizer as VisualizerFC,
    };
}
