import type * as postcss from 'postcss';

import { expandShorthandCssProp, getShorthandComputedProps } from './utils/css-utils/property-parser';
import type { DeclarationMap, FullDeclarationMap, EvalOverrides, EvalDeclarationValue } from './types';
import { DEFAULT_EVAL_DECLARATION_VALUE } from './stylable-site-vars';

const EMPTY_BORDER_IMAGE = {
    'border-image-source': 'none',
    'border-image-slice': '100%',
    'border-image-width': '1',
    'border-image-outset': '0',
    'border-image-repeat': 'stretch',
};

export interface BoxShadow {
    inset: boolean;
    offsetX: number;
    offsetY: number;
    blurRadius: number;
    spreadRadius: number;
    color: string;
}

export interface CalculatedBackgroundLayers {
    images: Array<Record<string, string>>;
    solid?: string;
}

export class StylableShorthands {
    constructor(private evalDeclarationValue: EvalDeclarationValue = DEFAULT_EVAL_DECLARATION_VALUE) {}

    public expandDeclaration(
        { prop, value }: postcss.Declaration,
        overrides?: EvalOverrides,
        includeInitialValues = true,
        disableBackgroundShorthand = false
    ): FullDeclarationMap {
        const evaluatedValue = this.evalDeclarationValue(value, overrides);

        if (prop === 'border-image') {
            return this.expandBorderImage(evaluatedValue);
        }
        try {
            const expanded = expandShorthandCssProp(
                prop,
                evaluatedValue,
                includeInitialValues,
                disableBackgroundShorthand
            ) as FullDeclarationMap;
            if (prop !== 'border') {
                return expanded;
            }
            return { ...expanded, ...{ 'border-image-source': 'none' } };
        } catch {
            return { [prop]: evaluatedValue };
        }
    }

    public getShorthandProps(propName: string): string[] {
        return getShorthandComputedProps(propName);
    }

    private expandBorderImage(value: string) {
        let result: FullDeclarationMap = { ...EMPTY_BORDER_IMAGE };
        value = value.trim();

        if (value === 'none') {
            return result;
        }

        const sourcePrefixes = [
            'url',
            'image',
            'image-set',
            'element',
            'cross-fade',
            'linear-gradient',
            'repeating-linear-gradient',
            'radial-gradient',
            'repeating-radial-gradient',
        ];
        if (!sourcePrefixes.some((prefix) => value.startsWith(prefix + '('))) {
            return result;
        }

        const firstParens = value.indexOf('(') + 1;
        const endOfSource = findEndOfClosure(value.slice(firstParens));
        const afterSourceIndex = firstParens + endOfSource + 1;
        result['border-image-source'] = value.slice(0, firstParens + endOfSource + 1);

        if (value.charAt(afterSourceIndex) !== ' ') {
            if (value.charAt(afterSourceIndex) !== '') {
                result = { ...EMPTY_BORDER_IMAGE };
            }
            return result;
        }

        const afterSource = value.slice(afterSourceIndex + 1);
        // const imageRegex = /^(?<slice>((\s?fill\s?)|(\s?\d+[%\w]*\s?)){1,4})?(?<widthOutset>(\s?\/\s?(\d+[%\w]*\s?){1,4})(\s?\/\s?(\d+[%\w]*\s?){1,4})?)?(?<repeat>((\s?stretch\s?)|(\s?repeat\s?)|(\s?round\s?)|(\s?space\s?)){1,2})?$/;
        const imageRegex =
            /^(((\s?fill\s?)|(\s?\d+[%\w]*\s?)){1,4})?((\s?\/\s?(\d+[%\w]*\s?){1,4})(\s?\/\s?(\d+[%\w]*\s?){1,4})?)?(((\s?stretch\s?)|(\s?repeat\s?)|(\s?round\s?)|(\s?space\s?)){1,2})?$/;
        const imageMatch = afterSource.match(imageRegex);
        if (!imageMatch) {
            result = { ...EMPTY_BORDER_IMAGE };
            return result;
        }
        const sliceValue = imageMatch[1];
        const widthOutsetValue = imageMatch[5];
        const repeatValue = imageMatch[10];
        if (sliceValue) {
            result['border-image-slice'] = sliceValue.trim();
        }
        if (widthOutsetValue) {
            const widthOutsetArr = widthOutsetValue.trim().split('/').slice(1);
            if (widthOutsetArr[0]) {
                result['border-image-width'] = widthOutsetArr[0].trim();
            }
            if (widthOutsetArr[1]) {
                result['border-image-outset'] = widthOutsetArr[1].trim();
            }
        }
        if (repeatValue) {
            result['border-image-repeat'] = repeatValue.trim();
        }

        return result;
    }
}

export function splitLayers(value: string, seperator = ',') {
    const layers: string[] = [];
    let prevIndex = 0;
    let closureLevel = 0;
    for (let i = 0; i < value.length; i++) {
        switch (value.charAt(i)) {
            case '(':
                closureLevel++;
                break;
            case ')':
                if (closureLevel === 0) {
                    throw new Error('Invalid value: ' + value);
                }
                closureLevel--;
                break;
            case seperator:
                if (closureLevel === 0) {
                    layers.push(value.slice(prevIndex, i).trim());
                    prevIndex = i + 1;
                }
                break;
        }
    }
    layers.push(value.slice(prevIndex).trim());
    return layers;
}

export function findEndOfClosure(value: string) {
    let closureLevel = 1;
    for (let i = 0; i < value.length; i++) {
        const currChar = value.charAt(i);
        switch (currChar) {
            case '(':
                closureLevel++;
                break;
            case ')':
                closureLevel--;
                break;
        }
        if (closureLevel === 0) {
            return i;
        }
    }

    return value.length - 1;
}

export function stringifyTopRightBottomLeft(value?: string | FullDeclarationMap) {
    if (!value) {
        return undefined;
    }

    if (typeof value === 'string') {
        return value;
    }

    const { top, right, bottom, left } = value;

    if (top === right && right === bottom && bottom === left) {
        return top;
    }

    if (top === bottom && right === left) {
        return `${top} ${right}`;
    }

    if (right === left) {
        return `${top} ${right} ${bottom}`;
    }

    return `${top} ${right} ${bottom} ${left}`;
}

export function stringifyBorderImage(value?: string | DeclarationMap) {
    if (!value) {
        return undefined;
    }

    if (typeof value === 'string') {
        return value;
    }

    if (value['border-image-source'] === undefined || value['border-image-source'] === 'none') {
        return 'none';
    }

    let stringifiedBorderImage = `${value['border-image-source']}`;
    stringifiedBorderImage += ` ${value['border-image-slice'] || EMPTY_BORDER_IMAGE['border-image-slice']}`;
    stringifiedBorderImage += ` / ${value['border-image-width'] || EMPTY_BORDER_IMAGE['border-image-width']}`;
    stringifiedBorderImage += ` ${value['border-image-repeat'] || EMPTY_BORDER_IMAGE['border-image-repeat']}`;

    return stringifiedBorderImage;
}
