import React from 'react';
import { Text } from '../text/text';
import { InformationTooltip } from '../tooltip/information-tooltip';
import { classes, style } from './composite-block.st.css';

export interface CompositeBlockProps {
    title?: string;
    information?: string;
    singleLine?: boolean;
    iconRow?: boolean;
    divider?: boolean;
    children?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
}

export const CompositeBlock: React.FunctionComponent<CompositeBlockProps> = ({
    title,
    information,
    singleLine,
    iconRow,
    divider,
    children,
    className,
    style: propStyle,
}) => (
    <div
        className={style(
            classes.root,
            {
                singleLine: !!singleLine,
                iconRow: !!iconRow,
                divider: !!divider,
            },
            className
        )}
        style={propStyle}
    >
        {title && (
            <span className={classes.title}>
                <Text className={classes.label}>{title}</Text>
                {information && <InformationTooltip className={classes.infoTooltip} text={information} />}
            </span>
        )}
        {children}
    </div>
);
