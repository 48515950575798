import type { ComponentEditSession } from './editor-edit-session';
import type { StylesheetDriver } from './stylable-stylesheet';
import type { DeclarationMap, SelectorSet } from './types';
import { uniteCallbacks } from './utils/callback-utils';

export interface SimpleChangeAction {
    selector: string;
    declarations?: DeclarationMap;
}

export interface ChangeAction {
    values: DeclarationMap | undefined;
    selector?: string;
    revertible?: boolean;
    changeCallback?: () => void;
}

export enum PartialChangeActionType {
    MODIFY = 'modify',
    ADD = 'add',
    REMOVE = 'remove',
}
export enum PartialChangeActionTarget {
    DECL = 'decl',
    RULE = 'rule',
}

export interface PartialChangeAction {
    type: PartialChangeActionType;
    target: PartialChangeActionTarget;
}

export interface PartialChange {
    stylesheetPath: string; // TODO: Maybe this needs to be defined once for every set of changes, not for every change like now. becuase 'batch' updates a specific stylesheet.
    selector: string;
    action: PartialChangeAction;
    declProp?: string;
    newValue?: string;
}

const warnOnceFlags = {
    applyDeprecated: false,
    modifyRule: false,
};
const DEFAULT_BLOCKING_ID = 'DEFAULT_BLOCKING_ID';

export class StylableChangeSelectors {
    private editSession?: ComponentEditSession;

    private aggregatedQuickChanges: ChangeAction[] = [];
    private blocked: Set<string> = new Set();
    private shouldCommitOnRelease = false;

    private onStylesheetCommit?: () => void;
    private postCommitHook?: () => void;
    private postChangeHook?: () => void;
    private partialChangeHook?: (partialChanges: PartialChange[]) => boolean;
    private aggregatedPartialChanges: PartialChange[] = [];

    constructor(
        private onQuickChange?: (selector: string, declarationMap: DeclarationMap) => void,
        public onRevertQuickChange?: () => void
    ) {}

    public openSession(editSession: ComponentEditSession) {
        this.editSession = editSession;
        this.aggregatedQuickChanges = [];
        this.shouldCommitOnRelease = false;
    }

    public setOnStylesheetCommit(onStylesheetCommit?: () => void) {
        this.onStylesheetCommit = onStylesheetCommit;
    }

    public setPostCommitHook(postCommitHook?: () => void) {
        this.postCommitHook = postCommitHook;
    }

    public setPostChangeHook(postChangeHook?: () => void) {
        this.postChangeHook = postChangeHook;
    }

    public setOnPartialChange(partialChangeHook?: (partialChanges: PartialChange[]) => boolean) {
        this.partialChangeHook = partialChangeHook;
    }

    // TODO this can probably be removed everywhere (every revertRule)
    public revertRule = (_changeCallback?: () => void) => {
        this.onRevertQuickChange && this.onRevertQuickChange();
        this.aggregatedQuickChanges = [];
    };

    public startBlockingCommits = (id = DEFAULT_BLOCKING_ID) => {
        this.blocked.add(id);
    };

    private areSomeCommitsBlocked = () => {
        return !!this.blocked.size;
    };

    public releaseBlockingCommits = (commitIfNeeded = true, id = DEFAULT_BLOCKING_ID) => {
        this.blocked.delete(id);
        if (!this.areSomeCommitsBlocked()) {
            if (commitIfNeeded) {
                if (this.shouldCommitOnRelease) {
                    this.commit();
                }
            } else {
                this.shouldCommitOnRelease = false;
            }
        }
    };

    public changeSelectors = (changeRequest: ChangeAction | ChangeAction[]) => {
        if (!this.editSession) {
            return;
        }

        const { stylableDriver, stylesheetPath, selector: sessionSelector } = this.editSession;
        const sheet = stylableDriver.getStylesheet(stylesheetPath);
        if (!sheet) {
            return;
        }

        const changeActions = Array.isArray(changeRequest) ? changeRequest : [changeRequest];
        changeActions.forEach((changeAction) => {
            const { revertible = false, values, selector = sessionSelector } = changeAction;

            this.callQuickChange(sheet, selector, values);

            // Add rule only if doesn't exist
            if (sheet.queryStyleRuleWithScopeFilter(selector).length === 0) {
                this.requestStylesheetChange(
                    [
                        {
                            stylesheetPath,
                            selector,
                            action: { target: PartialChangeActionTarget.RULE, type: PartialChangeActionType.ADD },
                        },
                    ],
                    true
                );
            }

            if (!revertible) {
                this.aggregatedQuickChanges.push(changeAction);
            }
        });

        if (!this.areSomeCommitsBlocked()) {
            this.commit();
        } else {
            this.shouldCommitOnRelease = true;
        }

        this.postChangeHook && this.postChangeHook();
    };

    private commit() {
        if (!this.editSession) {
            return;
        }

        const { stylableDriver, stylesheetPath, selector: sessionSelector } = this.editSession;
        const sheet = stylableDriver.getStylesheet(stylesheetPath);
        if (sheet && this.aggregatedQuickChanges.length) {
            const valuesPerSelector = this.getValuesPerSelector(sessionSelector);

            stylableDriver.batch(
                stylesheetPath,
                () => {
                    Object.keys(valuesPerSelector).forEach((selector) => {
                        this.commitChangeToStylesheet(valuesPerSelector[selector], selector);
                    });
                },
                this.buildSingleAggregatedCallback()
            );
            this.aggregatedQuickChanges = [];
        }
        this.shouldCommitOnRelease = false;

        this.postCommitHook && this.postCommitHook();
    }

    private commitChangeToStylesheet(values: DeclarationMap, selector: string) {
        if (!this.editSession) {
            return;
        }

        const { stylableDriver, stylesheetPath } = this.editSession;
        const sheet = stylableDriver.getStylesheet(stylesheetPath);
        if (!sheet) {
            return;
        }

        const props = Object.keys(values);
        const changes: PartialChange[] = [];
        if (props.length) {
            props.forEach((prop) => {
                // Meant to move next upserted declaration to bottom
                changes.push(
                    {
                        stylesheetPath,
                        selector,
                        action: { target: PartialChangeActionTarget.DECL, type: PartialChangeActionType.REMOVE },
                        declProp: prop,
                    },
                    {
                        stylesheetPath,
                        selector,
                        action: { target: PartialChangeActionTarget.DECL, type: PartialChangeActionType.MODIFY },
                        declProp: prop,
                        newValue: values[prop],
                    }
                );
            });
        } else {
            changes.push({
                stylesheetPath,
                selector,
                action: { target: PartialChangeActionTarget.RULE, type: PartialChangeActionType.REMOVE },
            });
        }
        this.requestStylesheetChange(changes);
    }

    private callQuickChange(siteStylesheet: StylesheetDriver, selector: string, values?: DeclarationMap) {
        if (!this.onQuickChange || values === undefined) {
            return;
        }

        const evalValuesMap = Object.keys(values).reduce((currValuesMap, prop) => {
            const newPropName = siteStylesheet.evalDeclarationProp(prop);
            currValuesMap[newPropName] = siteStylesheet.evalDeclarationValue(values[prop]);
            return currValuesMap;
        }, {} as DeclarationMap);

        this.onQuickChange(selector, evalValuesMap);
    }

    private getValuesPerSelector(sessionSelector: string) {
        return this.aggregatedQuickChanges.reduce((valuesPerSelector, change) => {
            const selector = change.selector || sessionSelector;
            if (change.values === undefined) {
                valuesPerSelector[selector] = {};
            } else {
                if (!valuesPerSelector[selector]) {
                    valuesPerSelector[selector] = {};
                }
                Object.assign(valuesPerSelector[selector], change.values);
            }
            return valuesPerSelector;
        }, {} as SelectorSet);
    }

    private buildSingleAggregatedCallback() {
        const callbacks: Array<() => void> = this.aggregatedQuickChanges
            .map((change) => change.changeCallback)
            .filter((cb) => cb !== undefined) as Array<() => void>;

        const { onStylesheetCommit } = this;
        if (onStylesheetCommit) {
            callbacks.push(() => onStylesheetCommit());
        }

        return uniteCallbacks(callbacks);
    }

    private requestStylesheetChange(requestedChange: PartialChange[], aggregate = false) {
        let preventDefault = false;
        if (requestedChange.length === 0) {
            return;
        }
        if (this.partialChangeHook) {
            this.aggregatedPartialChanges = this.aggregatedPartialChanges.concat(requestedChange);
            if (aggregate) {
                preventDefault = true;
            } else {
                const result = this.partialChangeHook(this.aggregatedPartialChanges);
                preventDefault = result;
                this.aggregatedPartialChanges = [];
            }
        }

        if (!preventDefault) {
            this.applyStylesheetChange(requestedChange);
        }
    }

    /**
     * deprecated: apply changes on their stylesheets
     * @param requestedChange - array of changes
     */
    public applyChangesToStylesheets(requestedChange: PartialChange[]) {
        if (!warnOnceFlags.applyDeprecated) {
            console.warn(
                '"applyChangesToStylesheets" in stylable-change-selectors is deprecated, use "applyStylesheetChange" instead'
            );
            warnOnceFlags.applyDeprecated = true;
        }
        this.applyStylesheetChange(requestedChange);
    }

    /**
     * apply changes on their stylesheets
     * @param requestedChange - array of changes
     */
    public applyStylesheetChange(requestedChange: PartialChange[]) {
        if (!this.editSession) return;

        const { stylableDriver } = this.editSession;
        if (requestedChange.length === 0) {
            return;
        }
        const batchPaths: string[] = [];
        requestedChange.forEach((change) => {
            batchPaths.push(change.stylesheetPath);
        });
        stylableDriver.batch(batchPaths, () => {
            requestedChange.forEach((change) => {
                const { stylesheetPath, selector, action, declProp, newValue } = change;
                const sheet = this.editSession!.stylableDriver.getStylesheet(stylesheetPath);
                if (!sheet) return;

                switch (action.target) {
                    case PartialChangeActionTarget.DECL:
                        if (declProp) {
                            switch (action.type) {
                                case PartialChangeActionType.REMOVE:
                                    sheet.upsertDeclarationToRule(selector, { prop: declProp, value: undefined });
                                    break;
                                case PartialChangeActionType.ADD:
                                case PartialChangeActionType.MODIFY:
                                    sheet.upsertDeclarationToRule(selector, { prop: declProp, value: newValue });
                                    break;
                            }
                        } else {
                            console.warn(
                                'applyStylesheetChange on a declaration should specify declProp, but it was undefined'
                            );
                        }
                        break;
                    case PartialChangeActionTarget.RULE:
                        switch (action.type) {
                            case PartialChangeActionType.REMOVE: {
                                const rules = sheet.getVariantStyleRules(selector);
                                rules.forEach((rule) => sheet.removeRule(rule));
                                break;
                            }
                            case PartialChangeActionType.ADD:
                                sheet.upsertStyleRule(selector, true);
                                break;
                            case PartialChangeActionType.MODIFY:
                                if (!warnOnceFlags.modifyRule) {
                                    console.warn(
                                        'applyStylesheetChange does not currently support the action "MODIFY for target RULE". If you really really want this feature please contact the StylablePanel team'
                                    );
                                    warnOnceFlags.modifyRule = true;
                                }
                                break;
                        }
                        break;
                }
            });
        });
    }
}
