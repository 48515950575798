import type { BorderImages, OpenedBorderImageShorthand } from '../shorthand-css-data';
import type { ShorthandOpener, GetShorthandCloser } from '../shorthand-types';

import {
    imageSourceDataType,
    borderImageSliceDataType,
    borderImageWidthDataType,
    borderImageOutsetDataType,
    borderImageRepeatDataType,
} from '../../css-data-types';
import {
    unorderedListShorthandOpener,
    createShorthandOpener,
    shorthandCloserTemplate,
    createShorthandCloser,
} from '../shorthand-parser-utils';

// border-image
export const openBorderImageShorthand = <V>(): ShorthandOpener<V, BorderImages, OpenedBorderImageShorthand<V>> =>
    createShorthandOpener({
        prop: 'border-image',
        parts: [
            { prop: 'border-image-source', dataType: imageSourceDataType },
            { prop: 'border-image-slice', dataType: borderImageSliceDataType, multipleItems: true },
            { prop: 'border-image-width', dataType: borderImageWidthDataType, multipleItems: true },
            { prop: 'border-image-outset', dataType: borderImageOutsetDataType, multipleItems: true },
            { prop: 'border-image-repeat', dataType: borderImageRepeatDataType, multipleItems: true },
        ],
        shorthandOpener: (astNodes, api, parts) => unorderedListShorthandOpener(parts)(astNodes, api),
    }) as ShorthandOpener<V, BorderImages, OpenedBorderImageShorthand<V>>;

export const closeBorderImageShorthand: GetShorthandCloser<BorderImages> = <V>() =>
    createShorthandCloser<V, BorderImages>(
        shorthandCloserTemplate<BorderImages>`${'border-image-source'} ${'border-image-slice'} / ${'border-image-width'} / ${'border-image-outset'} ${'border-image-repeat'}`
    );
