import { createSubsetAst } from '@stylable/core';
import type { Declaration, Rule } from 'postcss';
import type { StylesheetDriver } from './stylable-stylesheet';

const buildMixin = (mixins: string[]) => mixins.join(', ') || undefined;

export const parseMixinValue = (value?: string) => (value ? value.split(',').map((name) => name.trim()) : []);

export function removeMixin(name: string, mixins?: string) {
    if (!mixins) {
        return undefined;
    }

    const mixinList = parseMixinValue(mixins);

    const nameIndex = mixinList.findIndex((mixin) => mixin === name);
    if (nameIndex !== -1) {
        mixinList.splice(nameIndex, 1);
    }

    return buildMixin(mixinList);
}

export function applyMixin(name: string, mixins?: string) {
    if (!mixins) {
        return `${name}`;
    }

    const mixinList = parseMixinValue(removeMixin(name, mixins));
    mixinList.push(name);

    return buildMixin(mixinList);
}

// TODO: this function actually finds the first rule that contains a class in the first chunk with specific name
// remove usage of createSubsetAst and find the rule via walk
export function getMixinDeclarations(sheet: StylesheetDriver, name: string) {
    const mixinSubset = createSubsetAst(sheet.AST, `.${name}`);
    if (!mixinSubset.nodes || mixinSubset.nodes.length === 0) {
        return [];
    }
    return ((mixinSubset.nodes[0] as Rule).nodes as Declaration[]) || []; // TODO: eval all values here or in controller?
}
