import React, { useMemo } from 'react';
import type { CornerName } from './corner-visualizer-types';
import { classes, style } from './corner-visualizer.st.css';

export type CornerPreviewProps = {
    corner: CornerName;
    cornerValue: string;
    disabled: boolean;
};

export const CornerPreview: React.FC<CornerPreviewProps> = ({ corner, cornerValue, disabled }) => {
    const verticalEdge = useMemo(() => (corner == 'topLeft' || corner === 'topRight' ? 'Top' : 'Bottom'), [corner]);
    const horizontalEdge = useMemo(
        () => (corner === 'topLeft' || corner === 'bottomLeft' ? 'Left' : 'Right'),
        [corner]
    );
    const inlineStyle = useMemo(
        () => ({ [`border${verticalEdge}${horizontalEdge}Radius`]: cornerValue }),
        [cornerValue, horizontalEdge, verticalEdge]
    );

    return (
        <div
            className={style(
                classes.borderCorner,
                { corner, disabled },
                classes[`border${verticalEdge}`],
                classes[`border${horizontalEdge}`],
                classes.controllerPreviewContent
            )}
            style={inlineStyle}
        />
    );
};
