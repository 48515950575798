import { getDimensionInputDefaults } from '@wix/stylable-panel-common';
import {
    filterComments,
    GenericDeclarationMap,
    getMixinDeclarations,
    parseMixinValue,
    StylablePanelTranslationKeys,
} from '@wix/stylable-panel-drivers';
import type { BoxShadowMap } from '@wix/shorthands-opener';
import chroma from 'chroma-js';
import { useCallback, useMemo, useRef, useState } from 'react';
import { CUSTOM_FONT_THEME_ID, FontTheme } from '../../components';
import { useTranslate, useVisualizerChange } from '../../hooks';
import type { DeclarationVisualizerProps } from '../../types';
import {
    controllerToVisualizerChange,
    createDeclarationMapFromVisualizerValue,
    createShorthandOpenerApi,
} from '../../utils';
import { parseShadow, stringifyShadow } from '../../visualizer-factories/shadows-visualizer-factory/shadow-utils';

export const AUTOMATIC_LINE_HEIGHT = 'unset';
export const DISPLAY_HIDDEN = 'none';
export const DISPLAY_INITIAL = 'initial';
export const TEXT_SHADOW_NONE = 'none';
export interface Stroke {
    color?: string;
    value: { 'text-shadow': string };
}

export type TextProps =
    | 'font'
    | 'font-family'
    | 'font-weight'
    | 'font-size'
    | 'color'
    | 'font-style'
    | 'text-decoration-line'
    | 'text-transform'
    | 'text-align'
    | 'direction'
    | 'letter-spacing'
    | 'line-height'
    | 'text-shadow'
    | 'display'
    | 'background-color'
    | '-st-mixin';

export type TextDeclarationMap = GenericDeclarationMap<TextProps>;
export type TextVisualizerProps = DeclarationVisualizerProps<TextProps> & {
    noShownToggle?: boolean;
    noHighlight?: boolean;
};

export const useTextVisualizer = (props: TextVisualizerProps) => {
    const { drivers, controllerData, panelHost, noShownToggle, siteVarsDriver, onChange } = props;

    const availableFontThemes = useRef<FontTheme[]>(panelHost?.getFontThemes?.() || []);
    const declarationMapValue = createDeclarationMapFromVisualizerValue(props.value, props);

    const [isCustomTheme, setIsCustomTheme] = useState(false);
    const shorthandApi = useMemo(() => createShorthandOpenerApi(drivers.variables), [drivers.variables]);

    const getMixinDeclaration = useCallback(
        (name: string) => {
            const localMixinDeclarations = siteVarsDriver?.sheet
                ? getMixinDeclarations(siteVarsDriver.sheet, name)
                : [];
            if (localMixinDeclarations.length > 0) {
                return localMixinDeclarations;
            }

            return siteVarsDriver?.sheet ? getMixinDeclarations(siteVarsDriver?.sheet, name) : [];
        },
        [siteVarsDriver?.sheet]
    );

    const getStateFontTheme = (declarationMapValue: TextDeclarationMap) => {
        const availableFontThemeNames = availableFontThemes.current.map((fontTheme) => fontTheme.cssClass);
        const mixinList = parseMixinValue(declarationMapValue['-st-mixin']).reverse();
        const theme = mixinList.find((mixin) => availableFontThemeNames.includes(mixin));
        const mixinDeclarations = theme ? getMixinDeclaration(theme) : [];
        if (
            mixinDeclarations.length &&
            mixinDeclarations.some(
                (decl) =>
                    declarationMapValue[decl.prop as TextProps] !== undefined &&
                    declarationMapValue[decl.prop as TextProps] !== decl.value
            ) &&
            !isCustomTheme
        ) {
            setIsCustomTheme(true);
        }
        return theme && mixinDeclarations.length > 0 ? theme : CUSTOM_FONT_THEME_ID;
        // TODO: Translation layer between editor theme name and local theme name/class?
    };

    const extractStroke = useCallback((): Stroke => {
        const { 'text-shadow': shadowValue } = declarationMapValue;
        const valueWithoutComments = filterComments(shadowValue || '');

        if (!valueWithoutComments || shadowValue === TEXT_SHADOW_NONE) {
            return {
                color: undefined,
                /* to support the commenting and uncommenting (show/hide) the text shadow 
                   we need to pass shadowValue in case it is a comment as well
                */
                value: { 'text-shadow': shadowValue || TEXT_SHADOW_NONE },
            };
        }

        const parsedShadow = props.siteVarsDriver?.sheet
            ? parseShadow(
                  'text-shadow',
                  props.siteVarsDriver.sheet.evalDeclarationValue(valueWithoutComments),
                  shorthandApi
              )
            : [];

        const strokeCandidates: Record<
            string,
            {
                left?: number;
                right?: number;
                top?: number;
                bottom?: number;
            }
        > = {};
        parsedShadow.forEach((shadow, index) => {
            if (
                shadow['blur-radius'].number ||
                (shadow as BoxShadowMap)['spread-radius']?.number ||
                (shadow as BoxShadowMap).inset
            ) {
                return;
            }

            try {
                const color = chroma(shadow.color).hex();
                if (!strokeCandidates[color]) {
                    strokeCandidates[color] = {};
                }

                const {
                    'offset-x': { number: offsetX },
                    'offset-y': { number: offsetY },
                } = shadow;

                if (offsetX === -1 && offsetY === 0) {
                    strokeCandidates[color].left = index;
                } else if (offsetX === 1 && offsetY === 0) {
                    strokeCandidates[color].right = index;
                } else if (offsetX === 0 && offsetY === -1) {
                    strokeCandidates[color].top = index;
                } else if (offsetX === 0 && offsetY === 1) {
                    strokeCandidates[color].bottom = index;
                }
            } catch {
                //
            }
        });

        const strokeColor = Object.keys(strokeCandidates).find(
            (color) =>
                strokeCandidates[color].left !== undefined &&
                strokeCandidates[color].right !== undefined &&
                strokeCandidates[color].top !== undefined &&
                strokeCandidates[color].bottom !== undefined
        );

        if (!strokeColor) {
            return {
                color: undefined,
                value: { 'text-shadow': shadowValue as string },
            };
        }

        const newShadow = parsedShadow.filter(
            (_shadow, index) =>
                index !== strokeCandidates[strokeColor].left &&
                index !== strokeCandidates[strokeColor].right &&
                index !== strokeCandidates[strokeColor].top &&
                index !== strokeCandidates[strokeColor].bottom
        );

        return {
            color: strokeColor,
            value: { 'text-shadow': stringifyShadow(newShadow) || TEXT_SHADOW_NONE },
        };
    }, [declarationMapValue, props.siteVarsDriver?.sheet, shorthandApi]);

    const stroke = useMemo(() => extractStroke(), [extractStroke]);
    const { value } = useMemo(() => stroke, [stroke]);

    const [fontTheme, setFontTheme] = useState(getStateFontTheme(declarationMapValue));
    const [textShadowVisualizerShown, setTextShadowVisualizerShown] = useState(
        !!value && value['text-shadow'] !== TEXT_SHADOW_NONE
    );

    const handleFontThemeChange = useCallback(
        (newFontTheme: string) => {
            const fixTextThemeBehaviorExperiment = panelHost?.experiments?.fixTextThemeBehavior;
            setFontTheme(newFontTheme);
            if (fixTextThemeBehaviorExperiment) {
                const themeDeclsToRemoveFromStylesheet = getMixinDeclaration(newFontTheme).reduce((decls, cur) => {
                    decls[cur.prop] = undefined;
                    return decls;
                }, {} as GenericDeclarationMap);
                const declarationsToChange = controllerToVisualizerChange(
                    { ...themeDeclsToRemoveFromStylesheet, '-st-mixin': newFontTheme } as GenericDeclarationMap,
                    props
                );
                onChange?.(declarationsToChange);
            }
        },
        [getMixinDeclaration, onChange, panelHost?.experiments?.fixTextThemeBehavior, props]
    );

    const handleTextShadowVisualizerShownChange = (isShown: boolean) => {
        setTextShadowVisualizerShown(isShown);
    };

    const { display } = declarationMapValue;
    const translate = useTranslate(panelHost);

    const changeCustomLineHeight = useVisualizerChange('line-height', props);

    const hideShownToggle = useMemo(
        () => (controllerData && !!controllerData.hideShownToggle) || !!noShownToggle,
        [controllerData, noShownToggle]
    );
    const glyphMode = useMemo(() => !!controllerData && !!controllerData.glyphMode, [controllerData]);
    const elementShown = useMemo(() => display !== DISPLAY_HIDDEN, [display]);
    const titleKey = useMemo(
        () =>
            !glyphMode
                ? StylablePanelTranslationKeys.controller.text.title
                : StylablePanelTranslationKeys.controller.separators.title,
        [glyphMode]
    );

    const changeFromBlock = useCallback(
        (prop: string, valueFunction: (value: string) => string, value?: string) => {
            if (!onChange) {
                return;
            }
            const fixTextThemeBehaviorExperiment = panelHost?.experiments?.fixTextThemeBehavior;
            const changeValue: GenericDeclarationMap = {};

            const hasFontTheme = fontTheme !== CUSTOM_FONT_THEME_ID;
            const mixinDeclarations = hasFontTheme ? getMixinDeclaration(fontTheme) : [];
            const shouldBreakTheme = hasFontTheme && !!~mixinDeclarations.map((decl) => decl.prop).indexOf(prop);
            if (shouldBreakTheme) {
                setIsCustomTheme(true);
                changeValue['-st-mixin'] = declarationMapValue['-st-mixin'];
                mixinDeclarations.forEach((decl) =>
                    fixTextThemeBehaviorExperiment
                        ? // Needed decls to be nested under -st-mixin to override its values
                          declarationMapValue[decl.prop as TextProps] === decl.value
                            ? undefined
                            : (changeValue[decl.prop] = declarationMapValue[decl.prop as TextProps])
                        : (changeValue[decl.prop] = decl.value)
                );
            }

            changeValue[prop] = value !== undefined ? valueFunction(value) : undefined;
            onChange(controllerToVisualizerChange(changeValue, props));
        },
        [
            onChange,
            panelHost?.experiments?.fixTextThemeBehavior,
            fontTheme,
            getMixinDeclaration,
            props,
            declarationMapValue,
        ]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const DEFAULT_TEXT_ATTRIBUTES = useMemo(() => getDimensionInputDefaults(panelHost).textVisualizer, [panelHost?.dimensionUnits?.defaults]);

    const handleCustomLineHeightChange = useCallback(
        (isCustom: boolean) => {
            const declarationValue = isCustom ? DEFAULT_TEXT_ATTRIBUTES.lineHeight : AUTOMATIC_LINE_HEIGHT;
            changeCustomLineHeight && changeCustomLineHeight(declarationValue);
        },
        [changeCustomLineHeight, DEFAULT_TEXT_ATTRIBUTES]
    );

    return {
        hideShownToggle,
        stroke,
        elementShown,
        titleKey,
        glyphMode,
        getMixinDeclaration,
        getStateFontTheme,
        extractStroke,
        handleFontThemeChange,
        handleTextShadowVisualizerShownChange,
        changeFromBlock,
        translate,
        handleCustomLineHeightChange,
        // State
        fontTheme,
        textShadowVisualizerShown,
        setTextShadowVisualizerShown,
        DEFAULT_TEXT_ATTRIBUTES
    };
};
