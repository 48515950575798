import { getDimensionInputDefaults } from '@wix/stylable-panel-common';
import { DeclarationMap, StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import { useCallback, useMemo, useState } from 'react';
import { getVariableExpression } from '../../declaration-types';
import { useTranslate } from '../../hooks';
import {
    getOpenedDeclarationList,
    getShorthandControllerValue,
    hasShorthandOverride,
    isFullExpressionVisualizer,
} from '../../utils';
import { CORNER_PROPS_LIST, getCornerProp } from './corner-utils';
import type { CornerName, CornerProps, CornerVisualizerProps } from './corner-visualizer-types';

const OUTSIDE_PROPS_LIST: CornerProps[] = ['border-image-source', 'border-image-width'];
const ALL_PROPS_LIST = CORNER_PROPS_LIST.concat(OUTSIDE_PROPS_LIST);

export const useCornerVisualizer = (props: CornerVisualizerProps) => {
    const { value, panelHost } = props;

    const DEFAULT_RADIUS = useMemo(
        () => getDimensionInputDefaults(panelHost).cornerVisualizer.radius,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [panelHost?.dimensionUnits?.defaults]
    );

    const openedDeclarationList = useMemo(
        () => getOpenedDeclarationList('border-radius', ALL_PROPS_LIST, props),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props, panelHost?.dimensionUnits]
    );
    const declarationMapExpressionValue = useMemo(
        () => getShorthandControllerValue(openedDeclarationList, props, getVariableExpression),
        [openedDeclarationList, props]
    );

    const shouldLink = useMemo((): boolean => {
        const {
            [`border-top-left-radius`]: topLeft,
            [`border-top-right-radius`]: topRight,
            [`border-bottom-right-radius`]: bottomRight,
            [`border-bottom-left-radius`]: bottomLeft,
        } = declarationMapExpressionValue;

        return topLeft === topRight && topRight === bottomRight && bottomRight === bottomLeft;
    }, [declarationMapExpressionValue]);

    const [changeFromWithin, setChangeFromWithin] = useState(false);
    const [focused, setFocused] = useState('');
    const [linked, setLinked] = useState(shouldLink);

    const declarationMapValue = useMemo(
        () => getShorthandControllerValue(openedDeclarationList, props),
        [openedDeclarationList, props]
    );

    const translate = useTranslate(panelHost);
    const disabled = useMemo(() => isFullExpressionVisualizer('border-radius', props), [props]);

    const outsideValues = useMemo(() => {
        const outsideValues: DeclarationMap = {};

        OUTSIDE_PROPS_LIST.forEach((prop) => {
            outsideValues[prop] = declarationMapValue[prop];
        });

        return outsideValues;
    }, [declarationMapValue]);

    const getCornerValue = useCallback(
        (corner: CornerName) => declarationMapValue[getCornerProp(corner)] || DEFAULT_RADIUS,
        [declarationMapValue, DEFAULT_RADIUS]
    );

    const toggleLink = useCallback(() => {
        setLinked(!linked);
        setFocused('');
    }, [linked]);

    const toggleLinkClick = useCallback(() => {
        setChangeFromWithin(true);
        toggleLink();

        const message = linked
            ? StylablePanelTranslationKeys.notifications.tracking.unlocked
            : StylablePanelTranslationKeys.notifications.tracking.locked;
        panelHost?.onEditorNotify?.({
            type: 'tracking',
            title: translate(message),
            message,
        });
    }, [linked, panelHost, toggleLink, translate]);

    const getDisabled = useCallback(
        (corner: CornerName) =>
            hasShorthandOverride<'border-radius', CornerProps>(value, 'border-radius', getCornerProp(corner)),
        [value]
    );

    return {
        changeFromWithin,
        setChangeFromWithin,
        focused,
        setFocused,
        linked,
        setLinked,
        shouldLink,
        disabled,
        openedDeclarationList,
        outsideValues,
        getCornerValue,
        toggleLink,
        toggleLinkClick,
        getDisabled,
    };
};
