import type { PlaceContents } from '../shorthand-css-data';
import type {
    SimpleShorthandOpener,
    ShorthandPart,
    GetSimpleShorthandOpener,
    GetShorthandCloser,
} from '../shorthand-types';

import { alignContentDataType, justifyContentDataType } from '../../css-data-types';
import {
    intersectionBeforeOpenerCheck,
    splitSimpleShorthandOpener,
    unorderedListShorthandOpener,
    createShorthandOpener,
    createShorthandCloserTemplateFromParts,
    createShorthandCloser,
} from '../shorthand-parser-utils';

const getPlaceContentShorthandParts = <V>() =>
    [
        {
            prop: 'align-content',
            dataType: alignContentDataType,
            beforeOpenerCheck: intersectionBeforeOpenerCheck('place-content', [
                alignContentDataType,
                justifyContentDataType,
            ]),
            partOpener: splitSimpleShorthandOpener(['align-content', 'justify-content']),
        },
        {
            prop: 'justify-content',
            dataType: justifyContentDataType,
            beforeOpenerCheck: intersectionBeforeOpenerCheck('place-content', [
                alignContentDataType,
                justifyContentDataType,
            ]),
        },
    ] as ShorthandPart<V, PlaceContents>[];

// place-content
export const openPlaceContentShorthand: GetSimpleShorthandOpener<PlaceContents> = <V>() =>
    createShorthandOpener({
        prop: 'place-content',
        parts: getPlaceContentShorthandParts(),
        shorthandOpener: (astNodes, api, parts) => unorderedListShorthandOpener(parts)(astNodes, api),
    }) as SimpleShorthandOpener<V, PlaceContents>;

export const closePlaceContentShorthand: GetShorthandCloser<PlaceContents> = <V>() =>
    createShorthandCloser<V, PlaceContents>(createShorthandCloserTemplateFromParts(getPlaceContentShorthandParts()));
