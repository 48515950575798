import type { TextDecorations } from '../shorthand-css-data';
import type { SimpleShorthandOpener, GetSimpleShorthandOpener, GetShorthandCloser } from '../shorthand-types';

import { colorDataType, textDecorationLineDataType, textDecorationStyleDataType } from '../../css-data-types';
import {
    unorderedListShorthandOpener,
    createShorthandOpener,
    shorthandCloserTemplate,
    createShorthandCloser,
} from '../shorthand-parser-utils';

// text-decoration
export const openTextDecorationShorthand: GetSimpleShorthandOpener<TextDecorations> = <V>() =>
    createShorthandOpener({
        prop: 'text-decoration',
        parts: [
            { prop: 'text-decoration-color', dataType: colorDataType },
            { prop: 'text-decoration-line', dataType: textDecorationLineDataType },
            { prop: 'text-decoration-style', dataType: textDecorationStyleDataType },
        ],
        shorthandOpener: (astNodes, api, parts) => unorderedListShorthandOpener(parts)(astNodes, api),
    }) as SimpleShorthandOpener<V, TextDecorations>;

export const closeTextDecorationShorthand: GetShorthandCloser<TextDecorations> = <V>() =>
    createShorthandCloser<V, TextDecorations>(
        shorthandCloserTemplate<TextDecorations>`${'text-decoration-color'} ${'text-decoration-line'} ${'text-decoration-style'}`
    );
