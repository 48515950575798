import {
    FillPicker,
    ColorPicker,
    SingleShadowInput,
    TextShadowLayerInput,
    ImagePicker,
} from '@wix/stylable-panel-controllers';

import { StateListDialog } from '../../../panels/state-list-dialog/state-list-dialog';

export function panelsSetup(): Record<string, React.ComponentClass<any>> {
    return {
        [FillPicker.panelName]: FillPicker,
        [ColorPicker.panelName]: ColorPicker,
        [(SingleShadowInput as any).panelName]: SingleShadowInput,
        [(TextShadowLayerInput as any).panelName]: TextShadowLayerInput,
        [ImagePicker.panelName]: ImagePicker,
        [StateListDialog.panelName]: StateListDialog,
    };
}
