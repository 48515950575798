import { Text, TreeDisplayItems } from '@wix/stylable-panel-components';
import { getTranslate, TranslateFunc } from '@wix/stylable-panel-controllers';
import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import React, { FC } from 'react';
import { usePanelContext } from './configurable-panel';
import type { WhenProps } from './configurable-panel-utils';
import { filterElementChildrenByConditions } from './configurable-panel-utils';
import { classes, style } from './cp-elements.st.css';
import { DropDownTree } from './drop-down-tree';

export interface CpElementProps extends WhenProps {
    selector: string;
    labelKey: string;
    key?: string;
    children?: React.ReactNode;
}

export const CpElement = (props: CpElementProps) => {
    return (
        <div>
            <span>{props.selector}</span>
            <span>{props.labelKey}</span>
            {props.children}
        </div>
    );
};

export interface CpElementsProps {
    children?: React.ReactNode;
    className?: string;
}

/**
 * trasnforms element tree as from a react component of elements to match needed data for tree
 * @param node - node to scan recursively
 */
function transformChildrenToTreeNodes(node: React.ReactNode, translate: TranslateFunc) {
    const currentNode: TreeDisplayItems[] = [];
    function addElementToCurrent(element: React.ReactElement<CpElementProps>) {
        const children = element.props.children
            ? transformChildrenToTreeNodes(element.props.children, translate)
            : undefined;
        currentNode.push({
            id: element.props.selector,
            dataItem: { name: translate(element.props.labelKey) },
            children,
        });
    }

    if (Array.isArray(node)) {
        node.forEach((element) => {
            addElementToCurrent(element);
        });
    } else if (React.isValidElement(node)) {
        addElementToCurrent(node as React.ReactElement<CpElementProps>);
    }
    return currentNode;
}

export const CpElements: FC<CpElementsProps> = (props) => {
    const panelContext = usePanelContext();
    const translate = getTranslate(panelContext.panelHost);

    const onSelect = (targetSelector: string) => {
        const { onElementSelect, /*, reportBI, handleStateOverrides,*/ selectedElement } = panelContext;
        // TODO should be moved to onElementSelect in a a higher level
        //  see https://jira.wixpress.com/browse/STYL-945
        // const { CLICK_ON_ELEMENT_IN_TREE } = PanelEventList;
        // reportBI && reportBI(CLICK_ON_ELEMENT_IN_TREE);
        if (targetSelector !== selectedElement) {
            onElementSelect && onElementSelect(targetSelector);
            // handleStateOverrides && handleStateOverrides(); // TODO need to figure out what this is and implement it in a different way
        }
        onHighlight(null); // TODO should be called from a higher level and NOT by this component
    };

    const onHighlight = (selector: string | null) => {
        const { panelHost } = panelContext;
        panelHost.onHighlight?.(selector);
    };

    if (!props.children) {
        return null;
    }
    const filteredChildren = filterElementChildrenByConditions(props.children, panelContext, true);
    const nodes: TreeDisplayItems[] = transformChildrenToTreeNodes(filteredChildren, translate);

    return (
        <div className={style(classes.root, props.className)} data-aid="st_component_part_selector">
            <div className={classes.dropDownRoot}>
                <Text className={classes.label}>
                    {translate(StylablePanelTranslationKeys.elementree.dropDownLabel)}
                </Text>
                <DropDownTree
                    className={classes.dropDownTree}
                    items={nodes}
                    selector={panelContext.selectedElement}
                    onSelect={onSelect}
                    onHighlight={onHighlight}
                />
            </div>
        </div>
    );
};
