import type { ShorthandsTypeMap } from '@wix/shorthands-opener';
import { DEFAULT_PLANE } from '@wix/stylable-panel-common';
import { Text } from '@wix/stylable-panel-components';
import type { DeclarationMap, GenericDeclarationMap } from '@wix/stylable-panel-drivers';
import React, { useMemo } from 'react';
import type { CustomInputProps } from '../../drivers';
import { useShorthandValue, useTranslate } from '../../hooks';
import { classes, style } from '../../meta/common-controller.st.css';
import type { DeclarationVisualizerProps, VisualizerFC } from '../../types';
import { controllerToVisualizerChange, PropsToShorthandFunction } from '../../utils';

export type CommonVisualizer<MAIN extends string> = VisualizerFC<MAIN, DeclarationVisualizerProps<MAIN>>;

export function CommonVisualizerFactory<MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    baseProp: MAIN,
    inputProps: PROPS[],
    InputComp: React.ComponentClass<CustomInputProps>,
    propsToShorthand: PropsToShorthandFunction,
    titleKey?: string,
    outsideProps?: PROPS[],
    mainController = false
) {
    type CommonDeclarationMap = GenericDeclarationMap<PROPS>;

    const CommonVisualizer: CommonVisualizer<PROPS> = (props) => {
        const { panelHost, siteVarsDriver, className, plane = DEFAULT_PLANE, onChange } = props;

        const translate = useTranslate(panelHost);

        const [declarationMapValue, handleChange] = useShorthandValue(baseProp, props, inputProps, outsideProps);

        const inputValue = useMemo(() => propsToShorthand(declarationMapValue), [declarationMapValue]);

        const outsideValues = useMemo(() => {
            if (!outsideProps || outsideProps.length === 0) {
                return {};
            }

            return outsideProps.reduce((outsideValues, prop) => {
                outsideValues[prop] = declarationMapValue[prop];
                return outsideValues;
            }, {} as DeclarationMap);
        }, [declarationMapValue]);

        return (
            <div
                className={style(classes.root, { mainController, plane }, className)}
                data-common-controller-highlight-parts
            >
                {titleKey && <Text className={classes.title}>{translate(titleKey)}</Text>}
                <InputComp
                    className={classes.input}
                    value={inputValue}
                    outsideValues={outsideValues}
                    siteVarsDriver={siteVarsDriver}
                    panelHost={panelHost}
                    plane={plane}
                    onChange={handleChange}
                    onOutsideChange={(value: DeclarationMap) => {
                        onChange?.(controllerToVisualizerChange(value as CommonDeclarationMap, props));
                    }}
                />
            </div>
        );
    };

    CommonVisualizer.INPUT_PROPS = inputProps;
    CommonVisualizer.OUTSIDE_PROPS = outsideProps;

    return CommonVisualizer;
}
