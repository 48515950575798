import React, { useCallback, useEffect, useState } from 'react';

import {
    areRecordsEqual,
    ControllerData,
    CopyController,
    StylePanelPlane,
    DEFAULT_PLANE,
} from '@wix/stylable-panel-common';
import { aggregateSelectorDeclarations, FullDeclarationMap } from '@wix/stylable-panel-drivers';
import {
    applyState,
    ArchetypeList,
    ArchetypeResolver,
    BlockVariantSet,
    ChangeAction,
    DeclarationMap,
    DynamicPartControllerMatch,
    DynamicPartControllerSelectorValues,
    getFinalStateName,
    removeDuplicates,
    SelectorSet,
    stripNativePseudoFromSelector,
    stripStates,
    StylableDriver,
    StylableSiteVars,
    StylesheetDriver,
} from '@wix/stylable-panel-drivers';
import { Sections, Tabs, TabsPlane } from '@wix/stylable-panel-components';
import {
    ControllerComponentClass,
    VisualizerType,
    DeclarationVisualizerDrivers,
    ExtendedGlobalHost,
    BIParams,
    createDeclarationVisualizerDrivers,
    ControllerDynamicPart,
    BasicController,
    getTranslate,
    PanelEventList,
} from '@wix/stylable-panel-controllers';
import { controllerNoStateResizing } from '@wix/stylable-panel-controllers/dist/utils/controller-data-utils';

import type { CategoryConfig } from '../../types';
import type { BlockVariantProps, DynamicPartControllerProps } from './controllers/types';
import OptionalController from './optional-controller';
import { RenderedVisualizer } from './visualizer-renderer';

import { classes, style } from './category-editing-panel.st.css';
import { fixSpxValues } from '@wix/shorthands-opener';

export interface CategoryEditingPanelProps {
    siteSheetPath: string;
    sheetPath: string;
    aggregationPaths: string[];
    stylableDriver: StylableDriver;
    controllerPartTypes: Record<string, string[]>;
    copyControllers?: Record<string, CopyController[]>;
    controllerData?: Record<string, ControllerData>;
    inheritedStateSelectors?: string[];
    archetypeList: ArchetypeList;
    selector: string;
    categoryConfiguration: CategoryConfig;
    controllers?: Record<string, VisualizerType>;
    saveBlockVariant?: (category: string, variant: DeclarationMap) => void;
    deleteBlockVariant?: (category: string, index: number) => void;
    changeRuleDeclarations?: (changeRequest: ChangeAction | ChangeAction[]) => void;
    revertRule?: (changedCallback: () => void) => void;
    userBlockVariants?: Record<string, BlockVariantSet>;
    reportBI?: (event: PanelEventList, params?: BIParams) => void;
    panelHost?: ExtendedGlobalHost;
    plane?: StylePanelPlane;
    initialSection?: string;
    className?: string;
}

export interface InnerCategoryEditingPanelProps extends CategoryEditingPanelProps {
    archetypeResolver: ArchetypeResolver;
}

export interface ActiveProp {
    prop?: string;
    id?: string;
    originalIndex: number;
}

export type SelectorListMap = Record<string, string[]>;

export interface SectionsData {
    propsBySections: SelectorListMap;
    optionalBySections: SelectorListMap;
    rawEmptySections: string[];
    sections: Sections;
}

export interface CategoryEditingPanelState extends SectionsData {
    activePropName: ActiveProp;
}

function propCategoryFilter(
    propName: string,
    categoryId: string,
    categoryConfig: CategoryConfig,
    filterType: 'props' | 'optional' = 'props'
) {
    const config = categoryConfig[categoryId];
    let matchingProp;

    if (config) {
        const list = (config as any)[filterType];
        if (list) {
            matchingProp = list.find((prop: string | RegExp) => {
                if (prop instanceof RegExp) {
                    if (propName.match(prop)) {
                        return true;
                    }
                } else if (prop === propName) {
                    return true;
                }
                return false;
            });
        } else {
            return false;
        }
    }

    return !!matchingProp;
}

export interface CopyControllersInfo {
    declarations: Record<string, FullDeclarationMap>;
    propNames: SelectorListMap;
    categories: SelectorListMap;
    controllers: SelectorListMap;
}

/**
 * @deprecated This will be replaced by XX
 */
export class InnerCategoryEditingPanel extends React.Component<
    InnerCategoryEditingPanelProps,
    CategoryEditingPanelState
> {
    public static stylesheet = style;
    private variantStylesheet: StylesheetDriver | null = null;
    private siteVarsDriver: StylableSiteVars | null = null;
    private visualizerDrivers: DeclarationVisualizerDrivers;
    private selectorDeclarations!: FullDeclarationMap;
    private copyControllersInfo: CopyControllersInfo | null = null;
    private unmounting = false;
    // private savedRule: postcss.Rule | undefined;
    // private shouldRestore: boolean = false;
    private dirtySelectorDeclarations = false;

    constructor(props: InnerCategoryEditingPanelProps, context: any) {
        super(props, context);

        const { stylableDriver, sheetPath, siteSheetPath } = props;

        this.variantStylesheet = stylableDriver.getStylesheet(sheetPath);
        this.siteVarsDriver = stylableDriver.getSiteVarsDriver(siteSheetPath);
        this.visualizerDrivers = createDeclarationVisualizerDrivers();

        this.updateSelectorDeclarationsFromContext();
        this.state = {
            ...this.initSections(),
            activePropName: { originalIndex: 0 },
        };
    }

    public render() {
        const { plane = DEFAULT_PLANE, className } = this.props;

        return <div className={style(classes.root, { plane }, className)}>{this.renderPropList()}</div>;
    }

    public componentDidUpdate(prevProps: InnerCategoryEditingPanelProps, _prevState: CategoryEditingPanelState) {
        // TODO optimize
        if (
            prevProps.selector !== this.props.selector ||
            prevProps.archetypeResolver !== this.props.archetypeResolver
        ) {
            this.setState({ ...this.initSections() });
            this.dirtySelectorDeclarations = false;
        } else if (this.dirtySelectorDeclarations) {
            this.setState({
                sections: this.getSectionsWithHighlights(this.state.sections, this.state.propsBySections),
            });
            this.dirtySelectorDeclarations = false;
        }
    }

    public componentWillUnmount() {
        this.unmounting = true;
    }

    private getSectionsDataByPropNames(
        propNamesFromSelector: string[],
        extendSectionsFunction?: (sectionId: string, propNames: string[]) => string[]
    ): SectionsData {
        const { categoryConfiguration } = this.props;

        const categoryIds = Object.keys(categoryConfiguration);
        let sections: Array<{ id: string }> = categoryIds.map((id) => ({ id }));
        const propsBySections: SelectorListMap = {};
        const optionalBySections: SelectorListMap = {};
        const rawEmptySections: string[] = [];

        sections.forEach((section) => {
            propsBySections[section.id] = [];
            optionalBySections[section.id] = [];

            const propNames = propNamesFromSelector.filter((propName) =>
                propCategoryFilter(propName, section.id, categoryConfiguration)
            );
            const optionalNames = propNamesFromSelector.filter((propName) =>
                propCategoryFilter(propName, section.id, categoryConfiguration, 'optional')
            );

            const promotedNames: string[] = [];
            optionalNames.forEach((propName) => {
                if (this.selectorDeclarations[propName] !== undefined) {
                    promotedNames.push(propName);
                }
            });

            promotedNames.forEach((prop) => {
                optionalNames.splice(optionalNames.indexOf(prop), 1);
                propNames.push(prop);
            });

            if (propNames.length === 0 && optionalNames.length === 0) {
                rawEmptySections.push(section.id);
            }

            const allPropNames = extendSectionsFunction ? extendSectionsFunction(section.id, propNames) : propNames;

            propsBySections[section.id] = allPropNames;
            optionalBySections[section.id] = optionalNames;
        });

        sections = sections.filter(
            (section) => propsBySections[section.id].length > 0 || optionalBySections[section.id].length > 0
        ); // filter empty sections

        return {
            propsBySections,
            optionalBySections,
            rawEmptySections,
            sections,
        };
    }

    private initSections() {
        const { archetypeResolver, selector } = this.props;

        const propNamesFromSelector = archetypeResolver.getPropNamesFromSelector(selector);
        const { propsBySections, optionalBySections, rawEmptySections, sections } = this.getSectionsDataByPropNames(
            propNamesFromSelector,
            this.initCopyControllersSections.bind(this)
        );

        const sectionsWithHighlights = this.getSectionsWithHighlights(sections, propsBySections);

        return {
            propsBySections,
            optionalBySections,
            rawEmptySections,
            sections: sectionsWithHighlights,
        };
    }

    private getSectionsWithHighlights(sections: Array<{ id: string }>, propsBySections: SelectorListMap) {
        const sectionsWithHighlights: Sections = sections.map((section) => {
            let highlight = false;
            const id = section.id;
            if (
                Object.keys(this.selectorDeclarations).find((propName) => propsBySections[id].indexOf(propName) !== -1)
            ) {
                highlight = true;
            }
            return {
                id: section.id,
                highlight,
            };
        });
        return sectionsWithHighlights;
    }

    private renderPropList() {
        this.updateSelectorDeclarationsFromContext();

        const { controllers, selector, categoryConfiguration, archetypeResolver, panelHost, plane, initialSection } =
            this.props;

        const contentFunction = (id: string) => {
            this.updateSelectorDeclarationsFromContext();

            const { propsBySections, activePropName, optionalBySections, rawEmptySections } = this.state;

            let sectionProps: JSX.Element[] = [];
            const propsForSection = [...propsBySections[id]];

            if (propsForSection) {
                if (this.variantStylesheet && this.siteVarsDriver && controllers) {
                    const sheetDriver = this.variantStylesheet;
                    const siteVarsDriver = this.siteVarsDriver;
                    // first add all block variant controllers
                    Object.keys(controllers).forEach((controllerKey) => {
                        const Controller = controllers[controllerKey];
                        if (Controller.BLOCK_VARIANT_CONTROLLER) {
                            this.addControllerIfNeeded(
                                Controller,
                                propsForSection,
                                sectionProps,
                                controllerKey,
                                selector,
                                this.selectorDeclarations,
                                sheetDriver,
                                siteVarsDriver,
                                false,
                                id
                            );
                        }
                    });
                    // then add all non-block variant controllers
                    Object.keys(controllers).forEach((controllerKey) => {
                        const Controller = controllers[controllerKey];
                        if (!Controller.BLOCK_VARIANT_CONTROLLER) {
                            // Add original selector controller
                            if (!~rawEmptySections.indexOf(id)) {
                                const removeOriginalProp =
                                    !this.copyControllersInfo || !this.copyControllersInfo.controllers[controllerKey];
                                this.addControllerIfNeeded(
                                    Controller,
                                    propsForSection,
                                    sectionProps,
                                    controllerKey,
                                    selector,
                                    this.selectorDeclarations,
                                    sheetDriver,
                                    siteVarsDriver,
                                    removeOriginalProp,
                                    id,
                                    !Controller.IS_CONTROLLER
                                );
                            }

                            if (this.copyControllersInfo) {
                                const {
                                    declarations: copyControllersDeclarations,
                                    controllers: copyControllersControllers,
                                } = this.copyControllersInfo;

                                // Add all copy controllers
                                const copyControllersControllerSelectors =
                                    copyControllersControllers[controllerKey] || [];
                                copyControllersControllerSelectors.forEach((copySelector, index) => {
                                    this.addControllerIfNeeded(
                                        Controller,
                                        propsForSection,
                                        sectionProps,
                                        controllerKey,
                                        copySelector,
                                        copyControllersDeclarations[copySelector],
                                        sheetDriver,
                                        siteVarsDriver,
                                        index === copyControllersControllerSelectors.length - 1,
                                        id,
                                        !Controller.IS_CONTROLLER
                                    );
                                });
                            }
                        }
                    });
                }

                sectionProps = sectionProps.concat(
                    propsForSection.map((propName) => (
                        <BasicController
                            ref={(controller: BasicController) => {
                                if (!!controller && activePropName.id === id && activePropName.prop === propName) {
                                    controller.setFocus();
                                }
                            }}
                            key={propName}
                            className={classes.propField}
                            value={this.selectorDeclarations[propName]}
                            title={propName}
                            onChange={(value) => this.onControllerChange(selector, { [propName]: value })}
                        />
                    ))
                );
            }

            const optionalProps =
                optionalBySections[id] &&
                optionalBySections[id].map((propName) => (
                    <OptionalController
                        className={classes.optionalField}
                        title={propName}
                        key={propName}
                        onClick={() => {
                            this.optionalClick(id, propName);
                        }}
                    />
                ));

            return (
                <div className={classes.sectionContainer} key={id} data-aid={`st_categoryeditingpanel_section_${id}`}>
                    {sectionProps}
                    {optionalProps}
                </div>
            );
        };

        const getSectionTitle = (id: string) => {
            const sectionCategory = categoryConfiguration[id];
            if (!sectionCategory) {
                return id;
            }

            const sectionTitleKey = sectionCategory.titleKey;
            if (!sectionTitleKey) {
                return id;
            }

            const translate = getTranslate(panelHost);
            return translate(sectionTitleKey);
        };

        const preferredSection =
            plane !== StylePanelPlane.Vertical
                ? initialSection || archetypeResolver.getPrefferedSectionForSelector(selector)
                : initialSection;
        return (
            <Tabs
                className={classes.tabComponent}
                sections={this.state.sections}
                content={contentFunction}
                sectionIdToTitlesFunction={getSectionTitle}
                preferredTab={preferredSection}
                plane={plane as unknown as TabsPlane}
                tooltips
                noSingleTab
                onSelectTab={this.onSelectTab}
            />
        );
    }

    private onSelectTab = (tabIndex: number, isOpen: boolean | undefined) => {
        // TODO should move this logic higher and then it will also run for new config (cp-sections)
        //  see https://jira.wixpress.com/browse/STYL-945
        const { reportBI } = this.props;
        const { CLICK_ON_TAB_IN_PANEL } = PanelEventList;
        const action = isOpen !== undefined ? (isOpen ? 'expand' : 'collapse') : undefined;
        reportBI && reportBI(CLICK_ON_TAB_IN_PANEL, {
            tab: this.state.sections[tabIndex].id,
            action,
        });
    }

    private addControllerIfNeeded(
        Controller: VisualizerType,
        propsForSection: any[],
        sectionProps: JSX.Element[],
        controllerKey: string,
        selector: string,
        propsSource: FullDeclarationMap,
        sheetDriver: StylesheetDriver,
        siteVarsDriver: StylableSiteVars,
        removeOriginalProp: boolean,
        sectionId: string,
        isVisualizer = false
    ) {
        const propsForController: string[] = [];
        Controller.INPUT_PROPS.forEach((prop) => {
            const currPropIndex = propsForSection.indexOf(prop);
            if (currPropIndex !== -1) {
                propsForController.push(prop);
                if (removeOriginalProp) {
                    propsForSection.splice(currPropIndex, 1);
                }
            }
        });

        if (Object.keys(propsForController).length === 0) {
            return;
        }

        if (Controller.OUTSIDE_PROPS) {
            const selectorDeclarationKeys = Object.keys(propsSource);
            Controller.OUTSIDE_PROPS.forEach((prop) => {
                if (~selectorDeclarationKeys.indexOf(prop)) {
                    propsForController.push(prop);
                }
            });
        }

        const {
            archetypeResolver,
            categoryConfiguration,
            userBlockVariants,
            panelHost,
            plane = DEFAULT_PLANE,
            controllerData,
        } = this.props;

        const controllerValues = this.getControllerValues(propsForController, propsSource);

        if (Controller.IGNORE_ON_EMPTY_VALUE && Object.keys(controllerValues).length === 0) {
            return;
        }

        let dynamicPartControllerProps: Partial<DynamicPartControllerProps> = {};
        if (Controller.DYNAMIC_PARTS) {
            dynamicPartControllerProps = this.handleDynamicParts(Controller.DYNAMIC_PARTS, selector);
        }

        const variantSets =
            archetypeResolver.getBlockVariantsForElement(selector, sectionId) ||
            categoryConfiguration[sectionId].blockVariantSet;
        const blockVariantProps: BlockVariantProps = !isVisualizer
            ? {
                  variantSets,
                  userVariantSets:
                      userBlockVariants && userBlockVariants[sectionId] ? userBlockVariants[sectionId] : [],
                  saveBlockVariant: this.saveBlockVariant(sectionId, Controller as ControllerComponentClass),
                  deleteBlockVariant: (index: number) => this.deleteBlockVariant(sectionId, index),
              }
            : {};

        const { changeSelector, selectorState } = this.getChangeSelectorState(selector);
        let controllerDataForController = controllerData ? controllerData[controllerKey] : undefined;
        // take noStateResizing if exists and noResizing is not specified:
        if (controllerDataForController?.noStateResizing && controllerDataForController?.noResizing === undefined) {
            controllerDataForController = {
                ...controllerDataForController,
                noResizing: controllerNoStateResizing({
                    selectorState,
                    controllerData: controllerDataForController,
                }),
            };
        }

        sectionProps.push(
            RenderedVisualizer({
                key: `${selector}_${controllerKey}`,
                Visualizer: Controller,
                value: controllerValues,
                onControllerChange: this.onControllerChange.bind(this, changeSelector),
                visualizerDrivers: this.visualizerDrivers,
                sheetDriver,
                siteVarsDriver,
                selectorState,
                controllerData: controllerDataForController,
                panelHost,
                plane,
                revertRule: this.revertRule.bind(this),
                dynamicPartControllerProps,
                blockVariantProps,
                isVisualizer: isVisualizer && !!this.visualizerDrivers,
                className: classes.controller,
            })
        );
    }

    private getControllerValues(propsForController: string[], propsSource: FullDeclarationMap) {
        const controllerValues: DeclarationMap = {};
        Object.keys(propsSource).forEach((prop) => {
            if (~propsForController.indexOf(prop)) {
                controllerValues[prop] = propsSource[prop];
            }
        });
        return controllerValues;
    }

    private handleDynamicParts(
        dynamicParts: ControllerDynamicPart[],
        selector: string
    ): Partial<DynamicPartControllerProps> {
        const { stylableDriver, controllerPartTypes } = this.props;

        const selectorValues: DynamicPartControllerSelectorValues = {};

        Object.keys(controllerPartTypes).forEach((partTypeSelector) => {
            const selectorControllerPartTypes = controllerPartTypes[partTypeSelector];

            const controllersMatch = selectorControllerPartTypes.reduce((controllersMatchList, partType) => {
                for (const dynamicPart of dynamicParts) {
                    if (!partType.startsWith(dynamicPart.name)) {
                        continue;
                    }
                    if (dynamicPart.selectorFilter && !dynamicPart.selectorFilter(partTypeSelector, selector)) {
                        continue;
                    }

                    const paramsMatch = partType.match(new RegExp(`${dynamicPart.name}\\((.*)\\)`));

                    controllersMatchList.push({
                        name: dynamicPart.name,
                        params: paramsMatch ? paramsMatch[1] : undefined,
                    });

                    break;
                }

                return controllersMatchList;
            }, [] as DynamicPartControllerMatch[]);

            if (controllersMatch.length > 0) {
                selectorValues[partTypeSelector] = {
                    controllersMatch,
                    decls: stylableDriver.aggregateSelectorDeclarations(this.getCascadePaths(), partTypeSelector),
                };
            }
        });

        return Object.keys(selectorValues).length > 0
            ? {
                  selectorValues,
                  onChangeSelector: this.onControllerSelectorChange,
              }
            : {};
    }

    private getCascadePaths = () => {
        return this.props.aggregationPaths;
    };

    private saveBlockVariant(sectionId: string, Controller: ControllerComponentClass) {
        const { stylableDriver, sheetPath, selector, saveBlockVariant } = this.props;

        return () => {
            const nonExpanded = aggregateSelectorDeclarations(stylableDriver, sheetPath, selector, () => true, true);

            const filteredProps: string[] = [];
            Controller.INPUT_PROPS.forEach((prop) => {
                if (nonExpanded[prop] !== undefined) {
                    filteredProps.push(prop);
                }
            });

            return (
                saveBlockVariant && saveBlockVariant(sectionId, this.getControllerValues(filteredProps, nonExpanded))
            );
        };
    }

    private deleteBlockVariant(sectionId: string, index: number) {
        const { deleteBlockVariant } = this.props;

        deleteBlockVariant && deleteBlockVariant(sectionId, index);
    }

    private updateSelectorDeclarationsFromContext() {
        const { stylableDriver, selector } = this.props;

        const previousSelectorDeclarations = this.selectorDeclarations;

        this.selectorDeclarations = stylableDriver.aggregateSelectorDeclarations(this.getCascadePaths(), selector);
        if (!areRecordsEqual(previousSelectorDeclarations, this.selectorDeclarations)) {
            this.dirtySelectorDeclarations = true;
        }
        this.setCopyControllersInfo();
    }

    private onControllerChange = (selector: string, values: DeclarationMap, revertible = false) => {
        const { changeRuleDeclarations } = this.props;
        const activePropName = this.state.activePropName;
        const props = Object.keys(values);
        if (props.length === 1 && props[0] === activePropName.prop && activePropName.id) {
            if (values[props[0]] === '') {
                let { propsBySections, optionalBySections } = this.state;
                optionalBySections = Object.assign({}, optionalBySections);
                optionalBySections[activePropName.id].splice(activePropName.originalIndex, 0, props[0]);

                propsBySections = Object.assign({}, propsBySections);
                propsBySections[activePropName.id] = propsBySections[activePropName.id].filter(
                    (prop) => prop !== props[0]
                );
                this.setState({ propsBySections, optionalBySections });
            }

            this.setState({ activePropName: { prop: undefined, id: undefined, originalIndex: 0 } });
        }
        const valuesWithValidSpx = fixSpxValues(values);
        changeRuleDeclarations &&
            changeRuleDeclarations({
                selector,
                values: valuesWithValidSpx,
                revertible,
                changeCallback: this.updateOnMetaChange,
            });
    };

    private onControllerSelectorChange = (changeSet: SelectorSet) => {
        const { changeRuleDeclarations } = this.props;
        if (!changeRuleDeclarations) {
            return;
        }

        const selectors = Object.keys(changeSet);
        const changeRequest = selectors.map((selector, index) => ({
            selector,
            values: changeSet[selector],
            changeCallback: index === selectors.length - 1 ? this.updateOnMetaChange : undefined,
        }));

        changeRuleDeclarations(changeRequest);
    };

    private updateOnMetaChange = () => {
        if (!this.unmounting) {
            this.setState({ ...this.initSections() }); // re-render for every change to re-draw tabs
        }
    };

    private optionalClick(sectionId: string, propName: string) {
        let originalIndex: number;
        const newPropsId = this.state.propsBySections[sectionId].slice(0);
        let newOptionalId = this.state.optionalBySections[sectionId].slice(0);

        newPropsId.push(propName);
        newOptionalId = newOptionalId.filter((prop, index) => {
            const res = prop !== propName;
            if (!res) {
                originalIndex = index;
            }
            return res;
        });

        const newProps = Object.assign({}, this.state.propsBySections);
        (newProps as any)[sectionId] = newPropsId;

        const newOptional = Object.assign({}, this.state.optionalBySections);
        (newOptional as any)[sectionId] = newOptionalId;
        this.setState({
            propsBySections: newProps,
            optionalBySections: newOptional,
            activePropName: { prop: propName, id: sectionId, originalIndex: originalIndex! },
        });
    }

    private revertRule = () => {
        const { revertRule } = this.props;

        revertRule && revertRule(this.updateOnMetaChange);
    };

    private setCopyControllersInfo() {
        const { categoryConfiguration, controllers: propControllers, copyControllers, archetypeResolver } = this.props;

        this.copyControllersInfo = null;

        if (!propControllers || !copyControllers || Object.keys(copyControllers).length === 0) {
            return;
        }

        const declarations: Record<string, FullDeclarationMap> = {};
        const propNames: SelectorListMap = {};
        const categories: SelectorListMap = {};
        const controllers: SelectorListMap = {};

        Object.keys(copyControllers).forEach((copySelector) => {
            propNames[copySelector] = archetypeResolver.getPropNamesFromSelector(copySelector);

            let copyControllerSections: SectionsData | undefined;
            if (copyControllers[copySelector].some(({ categoryId, controllerKey }) => !categoryId || !controllerKey)) {
                copyControllerSections = this.getSectionsDataByPropNames(propNames[copySelector]);
            }

            const hasControllerInCategory = ({ categoryId, controllerKey }: CopyController) => {
                if (!copyControllerSections || !controllerKey) {
                    return () => true;
                }

                const { propsBySections } = copyControllerSections;
                const categoryMatch = (sectionId: string) =>
                    propsBySections[sectionId].some(
                        (prop) => !!~propControllers[controllerKey].INPUT_PROPS.indexOf(prop)
                    );

                return categoryId
                    ? categoryMatch(categoryId)
                    : Object.keys(propsBySections).some((sectionId) => categoryMatch(sectionId));
            };

            copyControllers[copySelector].forEach(({ categoryId, controllerKey }) => {
                if (
                    (categoryId && !categoryConfiguration[categoryId]) ||
                    (controllerKey && !propControllers[controllerKey])
                ) {
                    return;
                }

                let copySelectorCategories: string[] = [];
                if (categoryId) {
                    copySelectorCategories = [categoryId];
                } else if (copyControllerSections) {
                    copySelectorCategories = copyControllerSections.sections
                        .map((section) => section.id)
                        .filter((sectionId) => hasControllerInCategory({ categoryId: sectionId, controllerKey }));
                }
                copySelectorCategories.forEach((copySelectorCategoryId) => {
                    if (!categories[copySelectorCategoryId]) {
                        categories[copySelectorCategoryId] = [];
                    }
                    categories[copySelectorCategoryId].push(copySelector);
                });

                let copySelectorControllers: string[] = [];
                if (controllerKey) {
                    copySelectorControllers = [controllerKey];
                } else {
                    copySelectorControllers = Object.keys(propControllers).filter((controllerId) =>
                        hasControllerInCategory({ categoryId, controllerKey: controllerId })
                    );
                }
                copySelectorControllers.forEach((copySelectorControllerKey) => {
                    if (!controllers[copySelectorControllerKey]) {
                        controllers[copySelectorControllerKey] = [];
                    }
                    controllers[copySelectorControllerKey].push(copySelector);
                });
            });

            const { changeSelector } = this.getChangeSelectorState(copySelector);
            declarations[copySelector] = this.props.stylableDriver.aggregateSelectorDeclarations(
                this.getCascadePaths(),
                changeSelector
            );
        });

        this.copyControllersInfo = {
            declarations,
            propNames,
            categories,
            controllers,
        };
    }

    private initCopyControllersSections(sectionId: string, propNames: string[]) {
        let allPropNames = [...propNames];

        if (!this.copyControllersInfo) {
            return allPropNames;
        }

        const { propNames: copyControllersPropNames, categories: copyControllersCategories } = this.copyControllersInfo;

        let propNamesFromCopySelectors: string[] = [];
        const currentSectionCopyControllersSelectors = copyControllersCategories[sectionId] || [];
        currentSectionCopyControllersSelectors.forEach((copySelector) => {
            propNamesFromCopySelectors = removeDuplicates(
                propNamesFromCopySelectors.concat(copyControllersPropNames[copySelector])
            );
        });

        if (propNamesFromCopySelectors.length > 0) {
            const copyPropNames = propNamesFromCopySelectors.filter((propName) =>
                propCategoryFilter(propName, sectionId, this.props.categoryConfiguration)
            );
            allPropNames = removeDuplicates(allPropNames.concat(copyPropNames));
        }

        return allPropNames;
    }

    private getChangeSelectorState(selector: string) {
        const { selector: propSelector, inheritedStateSelectors } = this.props;

        let changeSelector = `${selector}`;
        const selectorState = getFinalStateName(propSelector) || undefined;
        if (
            selectorState &&
            selector !== propSelector &&
            inheritedStateSelectors &&
            !!~inheritedStateSelectors.indexOf(selector)
        ) {
            const primeSelector = stripNativePseudoFromSelector(stripStates(propSelector));
            changeSelector = applyState(primeSelector, selectorState, selector);
        }

        return { changeSelector, selectorState };
    }
}

const stPropsFilterFunction = (name: string) => {
    return !name.startsWith('-st');
};

/**
 * @deprecated This will be replaced by XX
 */
export const CategoryEditingPanel = (props: CategoryEditingPanelProps) => {
    const createArchetypeResolver = useCallback(() => {
        return new ArchetypeResolver(props.sheetPath, props.archetypeList, props.stylableDriver, stPropsFilterFunction);
    }, [props.sheetPath, props.archetypeList, props.stylableDriver]);
    const [archetypeResolver, setArchetypeResolver] = useState<ArchetypeResolver>(createArchetypeResolver());

    useEffect(() => {
        setArchetypeResolver(createArchetypeResolver());
    }, [props.sheetPath, props.archetypeList, props.stylableDriver, createArchetypeResolver]);
    return <InnerCategoryEditingPanel {...props} archetypeResolver={archetypeResolver} />;
};
