import type { IconProps } from '@wix/stylable-panel-controllers';
import { IconVisualizer } from '@wix/stylable-panel-controllers';
import React, { FC } from 'react';
import { usePanelContext } from '../configurable-panel';
import type { CpControllerCommonProps } from '../cp-controller';
import { generateVisualizerProps } from '../utils/generate-visualizer-props';

export type CpIconVisualizerCustomProps = {
    hideShownToggle?: boolean;
    noResizing?: boolean;
    noRotation?: boolean;
    noColor?: boolean;
    // TODO should pass disableShownToggle instead of calculating it inside the visualizer, see https://jira.wixpress.com/browse/STYL-1021
};
export type CpIconVisualizerProps = CpControllerCommonProps & CpIconVisualizerCustomProps;
export const CpIconVisualizer: FC<CpIconVisualizerProps> = ({ hideShownToggle, noResizing, editTargetSelector, noRotation, noColor }) => {
    const controllerData = { hideShownToggle, noResizing, noRotation, noColor };
    const context = usePanelContext();

    return (
        <IconVisualizer
            {...generateVisualizerProps<IconProps>({
                context,
                controllerData,
                editTargetSelector,
                key: 'CpIconVisualizer',
            })}
        />
    );
};
