import type { Option } from '@wix/stylable-panel-components';
import { getTranslate, TranslateFunc } from '@wix/stylable-panel-controllers';
import React, { FC, ReactElement } from 'react';
import { usePanelContext } from './configurable-panel';
import { filterElementChildrenByConditions, WhenProps } from './configurable-panel-utils';
import { classes, style } from './cp-state-picker.st.css';
import { StateDropDown, StateRendererProps } from './state-drop-down';

type SingleStateType = ReactElement<CpStateProps>;
type StateType = SingleStateType | Array<SingleStateType | undefined>;

export interface CpStateProps extends WhenProps {
    targetSelector: string;
    labelKey: string;
}

export const CpState = (_props: CpStateProps) => {
    return <div>cpState</div>;
};

export interface CpStatePickerProps {
    children?: StateType;
    className?: string;
    renderComponent?: FC<StateRendererProps>;
    titleKeys?: Record<string, string> | string;
}

function transformChildrenToOptions(node: StateType | undefined, translate: TranslateFunc) {
    const result: Option[] = [];
    function addStateToOptions(state?: React.ReactElement<CpStateProps>) {
        if (state) {
            result.push({
                id: state.props.targetSelector,
                displayName: translate(state.props.labelKey),
            });
        }
    }
    if (!node) {
        return [];
    }

    if (Array.isArray(node)) {
        node.forEach((element) => {
            addStateToOptions(element);
        });
    } else {
        addStateToOptions(node);
    }
    return result;
}

export const CpStatePicker: FC<CpStatePickerProps> = ({ children, className, renderComponent, titleKeys }) => {
    const panelContext = usePanelContext();
    const { panelHost, onForceState } = panelContext;
    const translate = getTranslate(panelHost);

    if (!children) {
        return null;
    }

    const onSelect = (selector: string) => {
        const { onStateSelect /*, reportBI, handleStateOverrides*/ } = panelContext;
        onStateSelect?.(selector);

        // TODO should move these higher in PartStateSelector so they need not be called from this level:
        //  see https://jira.wixpress.com/browse/STYL-945
        // const { STATE_CLICK } = PanelEventList;
        // handleStateOverrides && handleStateOverrides();
        // reportBI && reportBI(STATE_CLICK);
    };
    const callForceState = (selector: string | null) => {
        onForceState?.(selector);
    };

    const getTitle = () => {
        if (typeof titleKeys === 'string') {
            return translate(titleKeys);
        }

        const title = titleKeys?.[panelContext.selectedElement] || '';
        return translate(title);
    };

    const Comp: FC<StateRendererProps> = renderComponent || StateDropDown;
    const filteredChildren = filterElementChildrenByConditions(children, panelContext, false);
    const options: Option[] = transformChildrenToOptions(filteredChildren as SingleStateType[], translate);
    return (
        <div className={style(classes.root, className)} data-aid="st_part_state_selector">
            <Comp
                className={classes.stateRenderer}
                title={getTitle()}
                state={panelContext.activeTargetSelector}
                options={options}
                onSelect={onSelect}
                onForceState={callForceState}
            />
        </div>
    );
};
