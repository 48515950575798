import { style, classes } from './state-tabs.st.css';
import React, { FC } from 'react';
import { TabsItemSelection } from '@wix/stylable-panel-components';
import type { StateRendererProps } from './state-drop-down';
import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import { RESET_STATE_OVERRIDES_ACTION_ID } from '../customization-panel/part-state-selector';
import { getTranslate } from '@wix/stylable-panel-controllers';
import { usePanelContext } from './configurable-panel';

export const StateTabs: FC<StateRendererProps> = ({ className, state, options, onSelect, onForceState }) => {
    const { stateOverridesConfig, panelHost } = usePanelContext();

    const getStateOverridesData = () => {
        const { content, disabled } = stateOverridesConfig || { content: '', disabled: true };
        const disabledTooltipText = stateOverridesConfig ? stateOverridesConfig.disabled : false;

        return {
            stateOverridesContent: content,
            stateOverridesDisabled: disabled,
            disabledTooltipText,
        };
    };

    const stateOverridesCallback = () => {
        const { callback } = stateOverridesConfig!;
        callback?.();
    };

    const { stateOverridesContent, stateOverridesDisabled } = getStateOverridesData();
    const translate = getTranslate(panelHost);
    return (
        <TabsItemSelection
            className={style(classes.root, className)}
            items={options}
            selectedItemId={state}
            actions={[
                {
                    id: RESET_STATE_OVERRIDES_ACTION_ID,
                    displayName: stateOverridesContent!,
                    disabled: stateOverridesDisabled,
                    callback: () => stateOverridesCallback(),
                },
            ]}
            onSelectItem={onSelect}
            onHoverItem={onForceState}
            translations={{
                contextMenuTooltip: translate(StylablePanelTranslationKeys.states.viewMoreTooltip),
            }}
        />
    );
};
