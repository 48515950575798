import { dirname } from 'path';

export interface File {
    content: string;
    mtime?: Date;
}
export interface MinimalFSSetup {
    files: { [absolutePath: string]: File };
    trimWS?: boolean;
}
export interface MinimalFS {
    statSync: (fullpath: string) => { mtime: Date };
    readFileSync: (fullpath: string, encoding: 'utf8') => string;
    readlinkSync(path: string): string;
}

export interface FileWithVersion extends File {
    v: number;
}

export interface MinimalFSWithWrite extends MinimalFS {
    writeFileSync: (path: string, content: string) => void;
    removeFileSync: (path: string) => void;
}

export interface MinimalFSWithWriteSetup extends MinimalFSSetup {
    files: { [absolutePath: string]: FileWithVersion };
}

export function createMinimalFS({ files /*, trimWS*/ }: MinimalFSWithWriteSetup) {
    const creationDate = new Date();
    const filesMap = new Map<string, { content: string; mtime: Date }>(
        Object.entries(files).map(([filePath, { content, mtime = creationDate }]) => [filePath, { content, mtime }])
    );

    let directoryPaths: Set<string>;
    const setDirectoryPaths = () => {
        directoryPaths = new Set<string>();
        for (const filePath of filesMap.keys()) {
            for (const directoryPath of getParentPaths(dirname(filePath))) {
                directoryPaths.add(directoryPath);
            }
        }
    };

    setDirectoryPaths();

    const fs: MinimalFSWithWrite = {
        readFileSync(path: string) {
            if (!files[path]) {
                throw new Error('Cannot find file: ' + path);
            }
            // if (trimWS) {
            //     return deindent(files[path].content).trim();
            // }
            return files[path].content;
        },
        statSync(path: string) {
            const isDirectory = directoryPaths.has(path);
            const fileEntry = filesMap.get(path);

            if (!fileEntry && !isDirectory) {
                throw new Error(`ENOENT: no such file or directory, stat ${path}`);
            }

            return {
                isDirectory() {
                    return isDirectory;
                },
                isFile() {
                    return !!fileEntry;
                },
                mtime: fileEntry ? fileEntry.mtime : new Date(),
            };
        },
        readlinkSync() {
            throw new Error(`not implemented`);
        },
        writeFileSync(path: string, content: string) {
            const fileEntry = filesMap.get(path);

            if (fileEntry) {
                files[path].content = content;
                const mtime = (files[path].mtime = new Date(++files[path].v));

                filesMap.set(path, { content, mtime });
            } else {
                const v = 1;
                const mtime = new Date(v);
                files[path] = { content, mtime, v };

                filesMap.set(path, { content, mtime });
                setDirectoryPaths();
            }
        },
        removeFileSync(path: string) {
            delete files[path];

            filesMap.delete(path);
            setDirectoryPaths();
        },
    };

    const requireModule = function require(id: string): any {
        const _module = {
            id,
            exports: {},
        };
        try {
            if (!id.match(/\.js$/)) {
                id += '.js';
            }
            // eslint-disable-next-line @typescript-eslint/no-implied-eval
            const fn = new Function('module', 'exports', 'require', files[id].content);
            fn(_module, _module.exports, requireModule);
        } catch (e) {
            throw new Error('Cannot require file: ' + id);
        }
        return _module.exports;
    };

    function resolvePath(_ctx: string, path: string) {
        return path;
    }

    return {
        fs,
        requireModule,
        resolvePath,
    };
}

function getParentPaths(initialDirectoryPath: string) {
    const parentPaths: string[] = [];

    let currentPath = initialDirectoryPath;
    let lastPath: string | undefined;

    while (currentPath !== lastPath) {
        parentPaths.push(currentPath);
        lastPath = currentPath;
        currentPath = dirname(currentPath);
    }

    return parentPaths;
}
