import { DEFAULT_PLANE } from '@wix/stylable-panel-common';
import { OptimisticWrapper, Text } from '@wix/stylable-panel-components';
import {
    CommonControllerFactory,
    ControllerComponent,
    ControllerDynamicPart,
    CustomInput,
    DynamicPartSelectorFilter,
    getTranslate,
} from '@wix/stylable-panel-controllers';
import {
    DeclarationMap,
    DynamicPartInputSelectorValues,
    getDynamicPartInputSelectorValues,
    removeDuplicates,
} from '@wix/stylable-panel-drivers';
import React from 'react';
import type { DynamicPartControllerProps, DynamicPartInputProps } from '../types';
import { classes, style } from './layout-controller.st.css';

export interface LayoutPropFilter {
    prop: string;
    shouldShow: (value: string | undefined) => boolean;
}

export interface LayoutPropOptions {
    index: number;
    Comp: CustomInput;
    filter?: LayoutPropFilter;
    condition?: string;
    ReplacementComp?: CustomInput;
}
export type LayoutPropMap = Record<string, LayoutPropOptions>;
export type LayoutDisplayMap = Record<string, LayoutPropMap>;

export interface DynamicPartOptions {
    index: number;
    Comp: React.ComponentType<DynamicPartInputProps>;
    filter?: LayoutPropFilter;
    selectorFilter?: DynamicPartSelectorFilter;
    condition?: string;
}
export type DynamicPartMap = Record<string, DynamicPartOptions>;
export type DynamicPartDisplayMap = Record<string, DynamicPartMap>;

export interface LayoutCondition {
    dynamicPart: string;
    isCondition: (values: DynamicPartInputSelectorValues) => boolean;
}
export type LayoutConditionMap = Record<string, LayoutCondition>;

function uniqueValuesPerDisplay(displayMap: Record<string, Record<string, unknown>>) {
    const values: string[] = [];
    Object.keys(displayMap).forEach((display) =>
        Object.keys(displayMap[display]).forEach((prop) => {
            if (!~values.indexOf(prop)) {
                values.push(prop);
            }
        })
    );
    return values;
}

export const propTitleKey = (prop: string) => `${prop}_title_key`;

// TODO: Handle LayoutPropOptions index
// TOOD: Refactor to generic controller factory that will receive the 'display' prop replacement
export function LayoutControllerFactory(
    titleKey: string,
    layoutDisplayMap: LayoutDisplayMap = {},
    dynamicPartMap: DynamicPartDisplayMap = {},
    conditionMap: LayoutConditionMap = {}
): React.ComponentClass<DynamicPartControllerProps> {
    const sortedDynamicPartMapByDisplay: Record<string, ControllerDynamicPart[]> = {};
    Object.keys(dynamicPartMap).forEach((display) => {
        const displayDynamicPartMap = dynamicPartMap[display];
        const sortedDynamicPartNames = [...Object.keys(displayDynamicPartMap)];
        sortedDynamicPartNames.sort(
            (a: string, b: string) => displayDynamicPartMap[a].index - displayDynamicPartMap[b].index
        );
        sortedDynamicPartMapByDisplay[display] = sortedDynamicPartNames.map((name) => ({
            name,
            selectorFilter: displayDynamicPartMap[name].selectorFilter,
        }));
    });

    const dynamicParts = removeDuplicates(
        Object.keys(sortedDynamicPartMapByDisplay).reduce(
            (currDynamicParts, display) => currDynamicParts.concat(sortedDynamicPartMapByDisplay[display]),
            [] as ControllerDynamicPart[]
        )
    );

    class LayoutControllerInner extends React.Component<DynamicPartControllerProps> implements ControllerComponent {
        public static BLOCK_VARIANT_CONTROLLER = false;
        public static IS_CONTROLLER = true;
        public static IGNORE_ON_EMPTY_VALUE = true;
        public static INPUT_PROPS: string[] = uniqueValuesPerDisplay(layoutDisplayMap);
        public static OUTSIDE_PROPS: string[] = ['display'];
        public static DYNAMIC_PARTS: ControllerDynamicPart[] = dynamicParts;

        public render() {
            const {
                value: { display },
                selectorState,
                plane = DEFAULT_PLANE,
                panelHost,
                className,
            } = this.props;

            if (!!selectorState || !display || !layoutDisplayMap[display]) {
                return null;
            }

            const translate = getTranslate(panelHost);

            return (
                <div className={style(classes.root, { plane }, className)}>
                    <Text className={classes.title}>{translate(titleKey)}</Text>
                    {this.spliceDynamicPartInputs(this.renderDisplayLayoutControllers())}
                </div>
            );
        }

        private spliceDynamicPartInputs(displayLayoutControllers: JSX.Element[] | null) {
            const { value, selectorValues } = this.props;
            const display = value.display;
            if (!displayLayoutControllers || !display || !selectorValues) {
                return displayLayoutControllers;
            }

            let indexShift = 0;
            const sortedDynamicPartMap = sortedDynamicPartMapByDisplay[display];
            if (!sortedDynamicPartMap) {
                return displayLayoutControllers;
            }

            sortedDynamicPartMap.forEach((dynamicPart) => {
                const dynamicPartInputSelectorValues = getDynamicPartInputSelectorValues(
                    selectorValues,
                    dynamicPart.name
                );
                if (Object.keys(dynamicPartInputSelectorValues).length === 0) {
                    return;
                }

                const { index, Comp, filter, condition } = dynamicPartMap[display][dynamicPart.name];

                if (condition && conditionMap[condition]) {
                    const { dynamicPart: conditionDynamicPart, isCondition } = conditionMap[condition];
                    if (!isCondition(getDynamicPartInputSelectorValues(selectorValues, conditionDynamicPart))) {
                        return;
                    }
                }

                if (filter && !filter.shouldShow(value[filter.prop])) {
                    return;
                }

                displayLayoutControllers.splice(
                    index + indexShift,
                    0,
                    this.renderDynamicPartInput(dynamicPart.name, dynamicPartInputSelectorValues, Comp)
                );

                indexShift++;
            });

            return displayLayoutControllers;
        }

        private renderDisplayLayoutControllers() {
            const { value, selectorValues, controllerData } = this.props;
            if (!value.display) {
                return null;
            }

            const { className, ...restOfProps } = this.props;

            const displayInputs = layoutDisplayMap[value.display];
            if (!displayInputs) {
                return null;
            }

            return Object.keys(displayInputs).reduce((controllers, prop) => {
                const { Comp, filter, condition, ReplacementComp } = displayInputs[prop];
                let ActualComp = Comp;

                if (condition && conditionMap[condition] && selectorValues) {
                    const { dynamicPart, isCondition } = conditionMap[condition];
                    const conditionMet = isCondition(getDynamicPartInputSelectorValues(selectorValues, dynamicPart));
                    if (!conditionMet) {
                        if (ReplacementComp) {
                            ActualComp = ReplacementComp;
                        } else {
                            return controllers;
                        }
                    }
                }

                if (filter && !filter.shouldShow(value[filter.prop])) {
                    return controllers;
                }

                const controllerTitleKey =
                    controllerData && controllerData.translationKeys
                        ? controllerData.translationKeys[propTitleKey(prop)] || prop
                        : prop;

                const LayoutPropController = CommonControllerFactory(
                    prop,
                    [prop],
                    ActualComp,
                    (propValue: DeclarationMap) => propValue[prop],
                    controllerTitleKey
                );

                controllers.push(
                    <LayoutPropController key={prop} className={classes.layoutPropController} {...restOfProps} />
                );

                return controllers;
            }, [] as JSX.Element[]);
        }

        private renderDynamicPartInput(
            name: string,
            values: DynamicPartInputSelectorValues,
            DynamicPartInput: React.ComponentType<DynamicPartInputProps>
        ) {
            const { className, ...restOfProps } = this.props;

            return (
                <DynamicPartInput key={name} className={classes.dynamicPartInput} values={values} {...restOfProps} />
            );
        }
    }

    return OptimisticWrapper(LayoutControllerInner, {});
}
