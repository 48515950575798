import type { StateRendererProps } from './state-drop-down';
import React, { FC } from 'react';
import { usePanelContext } from './configurable-panel';
import { StylePanelPlane } from '@wix/stylable-panel-common';
import { StateTabs } from './state-tabs';
import { StateDropDown } from './state-drop-down';

/**
 * A "Layout aware" state component
 */
export const StateComponentWithLayout: FC<StateRendererProps> = (props) => {
    const { panelLayoutConfiguration } = usePanelContext();
    if (panelLayoutConfiguration.plane === StylePanelPlane.Vertical) {
        return <StateTabs {...props} />;
    } else {
        return <StateDropDown {...props} />;
    }
};
