import chroma from 'chroma-js';
import {
    parse as parseGradientAST,
    ColorStop as GradientColorStop,
    GradientNode,
    ShapeNode,
    LinearGradientNode,
    RadialGradientNode,
    DirectionalNode,
} from 'gradient-parser';

import { findEndOfClosure } from './stylable-shorthands';
import { expandShorthandCssProp } from './utils/css-utils/property-parser';

export interface ColorStop {
    color: string;
    length: string;
    overOtherStop?: boolean;
}

export interface RepeatData {
    lengths: string[];
    repeats: number;
    offset: number;
    size: number;
}

export interface Gradient {
    type: 'linear-gradient' | 'repeating-linear-gradient' | 'radial-gradient' | 'repeating-radial-gradient';
    colorStops: ColorStop[];
    orientation?: string;
    repeatData?: RepeatData;
    steps: number;
    position?: string;
    size?: string;
}

const GRADIENT_MATCHER = /\S+-gradient/;

// TODO: Type return value
export function expandGradient(value: string, prop: string, fallbackProp: string): any {
    const gradientMatch = value.match(GRADIENT_MATCHER);
    if (gradientMatch && gradientMatch.index !== undefined) {
        const restOfValue = value.slice(gradientMatch.index + gradientMatch[0].length + 1);
        const gradientValue = `${gradientMatch[0]}(${restOfValue.slice(0, findEndOfClosure(restOfValue))})`;
        const valueWithoutGradient = value.replace(gradientValue, '').trim();
        let restOfProp = {};
        if (valueWithoutGradient) {
            try {
                restOfProp = expandShorthandCssProp(fallbackProp, valueWithoutGradient);
            } catch {
                //
            }
        }
        try {
            return {
                ...restOfProp,
                [prop]: parseGradientAST(gradientValue)[0],
            };
        } catch {
            //
        }
    }

    try {
        return expandShorthandCssProp(fallbackProp, value);
    } catch {
        //
    }

    return { [fallbackProp]: value };
}

export function divideColorStops(astColorStops: GradientColorStop[]) {
    if (!astColorStops || astColorStops.length < 2) {
        return [];
    }

    const colorStops: ColorStop[] = [];

    for (let i = 0; i < astColorStops.length; i++) {
        const stop = astColorStops[i];
        let length = '';
        if (stop.length) {
            length = `${stop.length.value}${stop.length.type}`;
        } else {
            if (i === 0) {
                length = '0%';
            } else if (i === astColorStops.length - 1) {
                length = '100%';
            }
        }
        const normalizedColor = normalizeGradientColorStop(stop);
        const newColor = chroma(normalizedColor);
        const color = newColor.alpha() < 1 ? normalizedColor : newColor.hex('rgb');
        colorStops.push({ color, length });
    }

    let rangeStart = -1;
    let rangeEnd = -1;
    for (let i = 0; i < colorStops.length; i++) {
        const length = colorStops[i].length;
        if (length) {
            if (rangeStart === -1) {
                rangeStart = i;
            } else {
                rangeEnd = i;
                if (rangeEnd > rangeStart + 1) {
                    const rangeStartLength = parseFloat(colorStops[rangeStart].length);
                    const rangeEndLength = parseFloat(colorStops[rangeEnd].length);
                    const rangeLength = rangeEnd - rangeStart;
                    const fraction = (rangeEndLength - rangeStartLength) / rangeLength;
                    for (let j = 1; j < rangeLength; j++) {
                        colorStops[rangeStart + j].length = `${(rangeStartLength + fraction * j).toFixed(2)}%`;
                    }
                }
                rangeStart = rangeEnd = -1;
            }
        }
    }

    return colorStops;
}

export function verifiedExpandGradient(value: string): Record<string, string | GradientNode> | null {
    if (!value) {
        return null;
    }

    const expandedGradient = expandGradient(value, 'background-image', 'background');

    if (expandedGradient.background !== undefined || expandedGradient['background-image'] === value) {
        return null;
    }

    const gradientAST = expandedGradient['background-image'] as GradientNode;
    if (!gradientAST || !gradientAST.colorStops || gradientAST.colorStops.length < 2) {
        return null;
    }

    return expandedGradient;
}

export function gradientFromAST(gradientAST: GradientNode): Gradient {
    const gradient = {
        type: gradientAST.type,
        colorStops: divideColorStops(gradientAST.colorStops),
        orientation: normalizeGradientOrientation(gradientAST.type, gradientAST.orientation),
        steps: -1,
    };
    return (gradientAST as any).position && (gradientAST as any).size
        ? { ...gradient, position: (gradientAST as any).position, size: (gradientAST as any).size }
        : gradient;
}

export function parseGradient(value: string) {
    const expanded = verifiedExpandGradient(value);
    if (expanded) {
        const backgroundImage: Object = expanded['background-image'];
        const position = expanded['background-position'];
        const size = expanded['background-size'];
        const gradient = position && size ? { ...backgroundImage, position, size } : backgroundImage;
        return gradientFromAST(gradient as GradientNode);
    }
    return null;
}

export function solidGradient(value: string) {
    return `linear-gradient(${value}, ${value})`;
}
export function parseSolidGradient(value?: string) {
    if (!value || !value.startsWith('linear-gradient')) {
        return null;
    }

    const expanded = verifiedExpandGradient(value);
    if (!expanded) {
        return null;
    }

    const gradientAST = expanded['background-image'] as GradientNode;
    const colorStops = gradientAST.colorStops.map((stop) => normalizeGradientColorStop(stop));
    if (gradientAST.type === 'linear-gradient' && colorStops.every((stop) => stop === colorStops[0])) {
        return colorStops[0];
    }

    return null;
}

// TODO: isStepsGradient

export function isStripesGradient(gradient: Gradient) {
    return (
        gradient.type === 'repeating-linear-gradient' &&
        gradient.colorStops.length === 4 &&
        gradient.colorStops[0].length === '0%' &&
        gradient.colorStops[1].length.endsWith('px') &&
        gradient.colorStops[1].length.endsWith('px') &&
        gradient.colorStops[2].length.endsWith('px') &&
        gradient.colorStops[1].length === gradient.colorStops[2].length &&
        parseFloat(gradient.colorStops[2].length) <= parseFloat(gradient.colorStops[3].length) &&
        gradient.colorStops[0].color === gradient.colorStops[1].color &&
        gradient.colorStops[2].color === gradient.colorStops[3].color
    );
}

export function normalizeGradientColorStop(colorStop: GradientColorStop) {
    switch (colorStop.type) {
        case 'hex':
            return `#${colorStop.value}`;
        case 'rgb':
        case 'rgba':
            return `${colorStop.type}(${colorStop.value.join(', ')})`;
    }

    if (colorStop.value === 'transparent') {
        return 'rgba(255, 255, 255, 0)';
    }

    return colorStop.value;
}

export function normalizeGradientOrientation(
    type: string,
    orientation?: LinearGradientNode['orientation'] | RadialGradientNode['orientation']
) {
    if (!orientation) {
        if (~type.indexOf('linear')) {
            return '180deg';
        }
        return 'ellipse at 50% 50%';
    }

    if (Array.isArray(orientation) && orientation.length !== undefined) {
        const shape = (orientation[0] as ShapeNode).value;
        const atValue = orientation[0].at!.value;
        let orientationStr = '';
        if (shape) {
            orientationStr = `${shape} `;
        }
        orientationStr += 'at ';
        const { x, y } = atValue;
        if (x.type === 'position-keyword') {
            const { value } = x;
            switch (value) {
                case 'top':
                    orientationStr += '50% 0%';
                    break;
                case 'bottom':
                    orientationStr += '50% 100%';
                    break;
                case 'left':
                    orientationStr += '0% 50%';
                    break;
                case 'right':
                    orientationStr += '100% 50%';
                    break;
                case 'center':
                    orientationStr += '50% 50%';
                    break;
                default:
                    orientationStr += value;
            }
        } else {
            orientationStr += `${x.value}${x.type}`;
        }
        if (y) {
            orientationStr += ` ${y.value}${y.type}`;
        }
        return orientationStr;
    }

    if ((orientation as DirectionalNode).type === 'directional') {
        switch ((orientation as DirectionalNode).value) {
            case 'top':
                return '0deg';
            case 'right':
                return '90deg';
            case 'bottom':
                return '180deg';
            case 'left':
                return '270deg';
            default:
                return `to ${(orientation as DirectionalNode).value}`;
        }
    }
    return `${(orientation as DirectionalNode).value}deg`;
}

export function stringifyGradient(
    gradient: Gradient,
    useRepeat = true,
    typeOverride?: string,
    orientationOverride?: string
) {
    if (gradient.colorStops.length === 0) {
        return 'white';
    }
    let orientation = orientationOverride !== undefined ? orientationOverride : gradient.orientation;
    orientation = orientation ? orientation + ', ' : '';
    const colors = gradient.colorStops.map((stop, index) => {
        let length = stop.length;
        if (useRepeat && gradient.repeatData) {
            let lengthsIndex = index;
            if (gradient.repeatData.repeats === -1) {
                lengthsIndex = index < gradient.colorStops.length / 2 ? 0 : 1;
            }
            length = gradient.repeatData.lengths[lengthsIndex];
        }
        return `${stop.color}${length ? ' ' + length : ''}`;
    });
    if (useRepeat && gradient.repeatData && gradient.repeatData.repeats === -1) {
        colors.splice(0, 1);
        colors.splice(colors.length - 1, 1);
    }
    let positionSizeString = '';
    if (gradient.position && gradient.size) {
        positionSizeString = `${gradient.position}/${gradient.size} `;
    }
    return `${positionSizeString}${typeOverride || gradient.type}(${orientation}${colors.join(', ')})`;
}
