import { useCallback, useMemo } from 'react';
import type { ShorthandsTypeMap } from '@wix/shorthands-opener';
import type { GenericDeclarationMap } from '@wix/stylable-panel-drivers';

import type { DeclarationVisualizerProps, OpenedDeclarationList } from '../types';
import type { OpenedDeclarationArray } from '../declaration-types';
import { getOpenedDeclarationList, getSimpleShorthandChange, getShorthandControllerValue } from '../utils';

export function useShorthandValue<MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    baseProp: MAIN,
    visualizerProps: DeclarationVisualizerProps<PROPS>,
    inputProps: PROPS[],
    outsideProps?: PROPS[]
): [GenericDeclarationMap<PROPS>, (value: string) => void] {
    const { onChange } = visualizerProps;

    const allProps = useMemo(() => inputProps.concat(outsideProps ?? []), [inputProps, outsideProps]);

    const openedDeclarationList = useMemo(
        () => getOpenedDeclarationList(baseProp, allProps, visualizerProps),
        [allProps, baseProp, visualizerProps]
    );

    const declarationMapValue = useMemo(
        () => getShorthandControllerValue(openedDeclarationList, visualizerProps),
        [openedDeclarationList, visualizerProps]
    );

    const createCompleteChangeFromPartial = useCallback(
        (value: GenericDeclarationMap) => {
            const newChange: GenericDeclarationMap = Object.assign({}, value);

            inputProps.forEach((prop: string) => {
                if (!Object.prototype.hasOwnProperty.call(newChange, prop)) {
                    newChange[prop] = undefined;
                }
            });

            return newChange;
        },
        [inputProps]
    );

    const handleInputCompChange = useCallback(
        (value: string) => {
            onChange?.(
                getSimpleShorthandChange<MAIN, PROPS>(
                    baseProp,
                    createCompleteChangeFromPartial({
                        [baseProp]: value,
                    }),
                    openedDeclarationList as OpenedDeclarationList<MAIN | PROPS>,
                    visualizerProps as DeclarationVisualizerProps<MAIN | PROPS>
                ) as OpenedDeclarationArray<PROPS>
            );
        },
        [baseProp, createCompleteChangeFromPartial, onChange, openedDeclarationList, visualizerProps]
    );

    return [declarationMapValue, handleInputCompChange];
}
