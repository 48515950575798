import { ensureStylableImports, Imported } from '@stylable/core';
import { EditorComponentConfig, PresetSectionLayouts, StylePanelPage } from '@wix/stylable-panel-common';
import { SelectorConfigurationDriver, stripStates } from './selector-configuration-driver';
import type { PartialChange, SimpleChangeAction, StylableChangeSelectors } from './stylable-change-selectors';
import type { StylableDriver } from './stylable-driver';
import { StylablePanelTranslationKeys } from './stylable-panel-translation-keys';
import type { DeclarationMap, ElementTree } from './types';
import { elementTreeSelectors } from './utils/controller-part-utils';

const START_PAGE = StylePanelPage.CustomizationPanel;

type ControllerPartTypes = Record<string, string[]>;

export interface Store {
    states: string[];
    usedStates: string[];
}

export class ComponentEditSession {
    public elements: ElementTree;
    public selector: string; // TODO: change name to targetSelector when the old config is removed
    public selectedElement: string;
    public overrideStateSelector: string;
    public controllerPartTypes: ControllerPartTypes;
    public defaultPreviewProps: Record<string, string>;
    public componentName: string;
    public presetSectionLayouts: PresetSectionLayouts;
    public selectorConfiguration: SelectorConfigurationDriver;
    public page: StylePanelPage;
    public computed!: Store;

    constructor(
        public siteStylesheetPath: string,
        public stylesheetPath: string,
        public variant: string,
        public componentConfig: EditorComponentConfig,
        public stylableDriver: StylableDriver,
        public changeDriver: StylableChangeSelectors,
        public presetStylesheetPaths?: string[],
        public aggregationPaths?: string[]
    ) {
        this.selector = `.${variant}`;
        this.selectedElement = `.${variant}`;
        this.overrideStateSelector = `.${variant}`;

        this.defaultPreviewProps = componentConfig.previewProps || {};
        this.componentName = this.componentConfig.id;
        this.elements = stylableDriver.getPseudoElementsDeep(this.stylesheetPath, this.selector);

        this.controllerPartTypes = elementTreeSelectors(this.elements).reduce<ControllerPartTypes>(
            (controllerPartTypes, selector) => {
                const controllerPartType = stylableDriver.getControllerPartType(this.stylesheetPath, selector);
                if (controllerPartType) {
                    controllerPartTypes[selector] = controllerPartType
                        .replace(/^"(.+(?="$))"$/, '$1') // remove surrounding quotes
                        .split(',')
                        .map((partType) => partType.trim());
                }
                return controllerPartTypes;
            },
            {}
        );

        this.selectorConfiguration = new SelectorConfigurationDriver(
            componentConfig.selectorConfiguration || {},
            this.selector,
            {
                hover: { nameKey: StylablePanelTranslationKeys.states.native.hoverLabel },
                focus: { nameKey: StylablePanelTranslationKeys.states.native.focusLabel },
                disabled: { nameKey: StylablePanelTranslationKeys.states.native.disabledLabel },
                active: { nameKey: StylablePanelTranslationKeys.states.native.activeLabel },
            }
        );

        if (this.componentConfig.initialElement) {
            this.selector = this.selectorConfiguration.selectorToVariant(this.componentConfig.initialElement);
            this.selectedElement = this.getSelectorWithoutStates();
        }

        const stylesheet = this.getStylesheet();
        stylesheet && stylableDriver.stylable.transform(stylesheet.getMeta());
        this.updateComputed();

        this.presetSectionLayouts = componentConfig.presetSectionLayouts || {
            static: {},
            theme: {},
            user: {},
        };

        this.changeDriver.openSession(this);
        this.page = START_PAGE;
    }

    public getSourceCSS() {
        const sheetDriver = this.getStylesheet();
        return sheetDriver?.source || '';
    }

    /**
     *
     * @param selector to change to
     * @return true if state was updated
     */
    public setSelector(selector: string): boolean {
        const oldSelector = this.selector;
        const oldOverrideStateSelector = this.overrideStateSelector;
        this.selector = selector;
        this.selectedElement = this.getSelectorWithoutStates();
        this.updateComputed();

        if (oldOverrideStateSelector === this.overrideStateSelector) {
            const stateMatch = oldSelector.match(/[^:]:([^:]+)/)?.[1];
            if (stateMatch) {
                this.selector = this.selectedElement.replace(
                    this.overrideStateSelector,
                    `${this.overrideStateSelector}:${stateMatch}`
                );
            }
        }
        return oldOverrideStateSelector !== this.overrideStateSelector;
    }

    public updateComputed() {
        this.updateOverrideStateSelector();
        const sheetDriver = this.getStylesheet();
        if (!sheetDriver) {
            this.computed = { states: [], usedStates: [] };
            return;
        }

        this.computed = {
            states: sheetDriver.getVariantStates(this.overrideStateSelector),
            usedStates: sheetDriver.getVariantUsedStates(this.overrideStateSelector),
        };
    }

    public getSelectorWithoutStates() {
        return stripStates(this.selector);
    }

    public getStylesheet() {
        return this.stylableDriver.getStylesheet(this.stylesheetPath);
    }

    public getSelectorDeclarations(selector: string) {
        return this.stylableDriver.aggregateSelectorDeclarations(
            [this.stylesheetPath, ...(this.aggregationPaths || [])],
            this.selectorConfiguration.selectorToVariant(selector)
        );
    }

    public setSelectorDeclarations(selectorOrActions: string | SimpleChangeAction[], declarations?: DeclarationMap) {
        const simpleChangeActions: SimpleChangeAction[] =
            typeof selectorOrActions === 'string' ? [{ selector: selectorOrActions, declarations }] : selectorOrActions;

        this.changeDriver.changeSelectors(
            simpleChangeActions.map((simpleChangeAction) => ({
                values: simpleChangeAction.declarations,
                selector: this.selectorConfiguration.selectorToVariant(simpleChangeAction.selector),
            }))
        );
    }

    private getStylableImport = (metaImports: Imported[]) =>
        metaImports.find(
            ({ defaultExport, named }) =>
                defaultExport === this.componentName || Object.values(named).includes(this.componentName)
        );

    private getNamedImports = (varNames: string[], format: (varName: string, componentName: string) => string) =>
        varNames
            .map((varName) => format(varName, this.componentName))
            .reduce<Record<string, string>>((acc, name) => {
                acc[name] = name;
                return acc;
            }, {});

    public ensureImportedCssVars(
        varNames: string[],
        format: (varName: string, componentName: string) => string = (varName) => `--${varName}`
    ): void {
        const stylesheet = this.getStylesheet();

        if (!stylesheet) {
            throw new Error(`missing stylesheet: ${this.stylesheetPath}`);
        }

        const ast = stylesheet.AST;
        const meta = stylesheet.getMeta();

        const stylableImport = this.getStylableImport(meta.imports);

        if (!stylableImport) {
            throw new Error(`could not find component import: ${this.componentName}`);
        }

        const named = this.getNamedImports(varNames, format);

        const { diagnostics } = ensureStylableImports(ast, [{ request: stylableImport.request, named }], {
            newImport: 'none',
        });

        if (diagnostics.reports.length) {
            const reports = diagnostics.reports.map((report) => report.message).join('\n');
            throw new Error(`could not ensure imports:\n${reports}`);
        }
    }

    public applyChangesToStylesheets(requestedChange: PartialChange[]) {
        this.changeDriver.applyStylesheetChange(requestedChange);
    }

    private updateOverrideStateSelector() {
        this.overrideStateSelector = this.selectorConfiguration.getPrimeElement(this.selectedElement);
    }
}
