import React, { useEffect, useRef, useState } from 'react';
import { DropDown } from '../drop-down/drop-down';
import { optionsFromStrings } from '../option-list/option-list';
import { classes, style } from './theme-selector.st.css';

export type Themes = Record<string, string[] | { classes: string[]; css: string }>;

export interface ThemeSelectorProps {
    themes: Themes;
    children: React.ReactChild[];
    className?: string;
    setPlane?: (theme: string) => void;
}

let current = '';

function useTheme<T extends Themes>(
    themes: T,
    ref: React.MutableRefObject<HTMLDivElement | null>,
    styleRef: React.MutableRefObject<HTMLStyleElement | null>
): (theme: keyof T) => void {
    const [theme, setTheme] = useState<keyof T>(Object.keys(themes)[0]);

    useEffect(() => {
        const sref = styleRef.current;
        const el = ref.current;

        if (!el) {
            throw new Error('missing ref element');
        }

        let classes = themes[theme] as string[] | { classes: string[]; css: string };

        if (!Array.isArray(classes)) {
            if (!sref) {
                throw new Error('when using inline theme styleRef must be provided');
            }
            sref.textContent = classes.css;
            classes = classes.classes;
        }

        classes.forEach((className) => el.classList.add(className));

        return () => {
            if (sref) {
                sref.textContent = '';
            }
            if (Array.isArray(classes)) {
                classes.forEach((className) => el.classList.remove(className));
            }
        };
    }, [themes, theme, ref, styleRef]);

    current = theme as string;

    return setTheme;
}

export const ThemeSelector = ({ themes, children, className, setPlane }: ThemeSelectorProps) => {
    if (!themes) {
        throw new Error('missing theme definition');
    }

    const ref = useRef<HTMLDivElement>(null);
    const styleRef = useRef<HTMLStyleElement>(null);
    const setTheme = useTheme(themes, ref, styleRef);

    return (
        <div ref={ref}>
            <DropDown
                className={style(classes.root, className)}
                value={current}
                options={optionsFromStrings(Object.keys(themes))}
                onSelect={(theme: string) => {
                    setTheme(theme);
                    setPlane?.(theme);
                }}
            />
            <style ref={styleRef} />
            {children}
        </div>
    );
};

export default ThemeSelector;
