import React, { useReducer, useRef, useCallback, useMemo } from 'react';

import { DEFAULT_PLANE, getDimensionInputDefaults } from '@wix/stylable-panel-common';
import { LinkButtonDisabled, LinkButtonEnabled } from '@wix/stylable-panel-common-react';
import { Button, CompositeBlock, Icon, OptimisticWrapper, Tooltip } from '@wix/stylable-panel-components';
import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';

import type { VisualizerFC } from '../../types';
import type { GetPropOptionString, GetPropOptionLatest } from '../../visualizer-driver-types';
import { EdgeMainProp, Edges, SelectedEdge, EdgeProps } from './edge-types';
import { EdgeDriverFactory } from './edge-driver';
import { OpenedDeclarationArray, isDeclarationExpressionItem, getVariableExpression } from '../../declaration-types';
import { edgeToSelectedBorder, selectedBorderToEdge } from './edge-utils';
import { EdgeInput } from './edge-input';
import {
    getAstNodeText,
    hasShorthandOverride,
    isFullExpressionVisualizer,
    visualizerOptimisticValueResolver,
} from '../../utils';
import { useTranslate } from '../../hooks';

import { classes, style } from './edge-visualizer.st.css';

interface EdgeValues {
    edge: Edges;
    main: string;
}

interface EdgePolygonPreview {
    select: SelectedEdge;
    svgPath: string;
    innerPath?: string;
    viewBox: string;
}

interface EdgeSVGPreview {
    width: number;
    height: number;
    edges: Record<Edges, EdgePolygonPreview>;
}

const EDGES: Edges[] = ['top', 'right', 'bottom', 'left'];

const VIEW_BOXES = {
    topBottom: '0 0 132 18',
    verticalTopBottom: '0 0 104 18',
    leftRight: '0 0 18 98',
};

const ARROW_INNER_PATHS = {
    down: 'M53.5757576,11 L53.5757576,12 L51.2121212,12 L51.2121212,11 L53.5757576,11 Z M55.1515152,9 L55.1515152,10 L49.6363636,10 L49.6363636,9 L55.1515152,9 Z M56.7272727,7 L56.7272727,8 L48.0606061,8 L48.0606061,7 L56.7272727,7 Z',
    left: 'M7.58333333,50.6136364 L6.58333333,50.6136364 L6.58333333,48.3863636 L7.58333333,48.3863636 L7.58333333,50.6136364 Z M9.58333333,52.0984848 L8.58333333,52.0984848 L8.58333333,46.9015152 L9.58333333,46.9015152 L9.58333333,52.0984848 Z M11.5833333,53.5833333 L10.5833333,53.5833333 L10.5833333,45.4166667 L11.5833333,45.4166667 L11.5833333,53.5833333 Z',
    up: 'M51.1515152,8 L51.1515152,7 L53.5151515,7 L53.5151515,8 L51.1515152,8 Z M49.5757576,10 L49.5757576,9 L55.0909091,9 L55.0909091,10 L49.5757576,10 Z M48,12 L48,11 L56.6666667,11 L56.6666667,12 L48,12 Z',
    right: 'M10.5833333,48.3863636 L11.5833333,48.3863636 L11.5833333,50.6136364 L10.5833333,50.6136364 L10.5833333,48.3863636 Z M8.58333333,46.9015152 L9.58333333,46.9015152 L9.58333333,52.0984848 L8.58333333,52.0984848 L8.58333333,46.9015152 Z M6.58333333,45.4166667 L7.58333333,45.4166667 L7.58333333,53.5833333 L6.58333333,53.5833333 L6.58333333,45.4166667 Z',
};

const getVerticalSVGShapePreviewProps = (main: EdgeMainProp): EdgeSVGPreview => ({
    width: 128,
    height: 120,
    edges: {
        top: {
            select: SelectedEdge.Top,
            svgPath:
                'M98.3939322,0.5 C99.796548,0.5 101.066378,1.06852236 101.985554,1.98769865 C102.904731,2.90687495 103.473253,4.17670518 103.473253,5.57932092 C103.473253,6.70698592 103.097995,7.80258849 102.406625,8.69345023 L102.406625,8.69345023 L97.223567,15.3720475 C96.181724,16.7145079 94.5778406,17.5 92.8785358,17.5 L92.8785358,17.5 L11.1214642,17.5 C9.42215944,17.5 7.81827599,16.7145079 6.77643302,15.3720475 L6.77643302,15.3720475 L1.59337546,8.69345023 C0.733432393,7.58537584 0.404035954,6.23364197 0.566643877,4.94394092 C0.729251801,3.65423987 1.38386409,2.42657166 2.49193848,1.5666286 C3.38280022,0.875257711 4.47840279,0.5 5.60606778,0.5 L5.60606778,0.5 Z',
            innerPath: main === 'padding' ? ARROW_INNER_PATHS.down : ARROW_INNER_PATHS.up,
            viewBox: VIEW_BOXES.verticalTopBottom,
        },
        right: {
            select: SelectedEdge.Right,
            svgPath:
                'M17.5,5.27264182 C17.5,4.00728544 16.9975077,2.79371877 16.1029967,1.89874575 C14.2396453,0.0344320871 11.2177758,0.0336519813 9.35346211,1.89700333 L2.11191681,9.13481073 C1.0798525,10.1663423 0.5,11.5657192 0.5,13.0249019 L0.5,84.9750981 C0.5,86.4342808 1.0798525,87.8336577 2.11191681,88.8651893 L9.35346211,96.1029967 C10.2484351,96.9975077 11.4620018,97.5 12.7273582,97.5 C15.3632155,97.5 17.5,95.3632155 17.5,92.7273582 L17.5,5.27264182 Z',
            innerPath: main === 'padding' ? ARROW_INNER_PATHS.left : ARROW_INNER_PATHS.right,
            viewBox: VIEW_BOXES.leftRight,
        },
        bottom: {
            select: SelectedEdge.Bottom,
            svgPath:
                'M92.8517889,0.5 C94.5510937,0.5 96.1549772,1.28549215 97.1968201,2.62795255 L97.1968201,2.62795255 L102.379878,9.30654977 C103.239821,10.4146242 103.569217,11.766358 103.406609,13.0560591 C103.244001,14.3457601 102.589389,15.5734283 101.481315,16.4333714 C100.590453,17.1247423 99.4948504,17.5 98.3671854,17.5 L98.3671854,17.5 L5.57932092,17.5 C4.17670518,17.5 2.90687495,16.9314776 1.98769865,16.0123013 C1.06852236,15.093125 0.5,13.8232948 0.5,12.4206791 C0.5,11.2930141 0.875257711,10.1974115 1.5666286,9.30654977 L1.5666286,9.30654977 L6.74968617,2.62795255 C7.79152913,1.28549215 9.39541258,0.5 11.0947173,0.5 L11.0947173,0.5 Z',
            innerPath: main === 'padding' ? ARROW_INNER_PATHS.up : ARROW_INNER_PATHS.down,
            viewBox: VIEW_BOXES.verticalTopBottom,
        },
        left: {
            select: SelectedEdge.Left,
            svgPath:
                'M0.5,5.27264182 L0.5,92.7273582 C0.5,95.3632155 2.63678453,97.5 5.27264182,97.5 C6.53799819,97.5 7.75156487,96.9975077 8.64653789,96.1029967 L15.8880832,88.8651893 C16.9201475,87.8336577 17.5,86.4342808 17.5,84.9750981 L17.5,13.0249019 C17.5,11.5657192 16.9201475,10.1663423 15.8880832,9.13481073 L8.64653789,1.89700333 C6.78222423,0.0336519813 3.76035468,0.0344320871 1.89700333,1.89874575 C1.00249227,2.79371877 0.5,4.00728544 0.5,5.27264182 Z',
            innerPath: main === 'padding' ? ARROW_INNER_PATHS.right : ARROW_INNER_PATHS.left,
            viewBox: VIEW_BOXES.leftRight,
        },
    },
});

export interface EdgeVisualizerState {
    selected: SelectedEdge;
    editing: SelectedEdge;
    linked: boolean;
}

enum EdgeVisualizerStateActionType {
    ToggleLinked = 'toggleLinked',
    HandleSelect = 'handleSelect',
    HandleBlur = 'handleBlur',
    Calc = 'calc',
}

interface EdgeVisualizerStateAction {
    type: EdgeVisualizerStateActionType;
    select?: SelectedEdge;
}

type EdgeVisualizerLayout = 'box' | 'inline';

type EdgeVisualizerFactoryProps = {
    inputProps: EdgeProps[];
    title: string;
    sectionTooltip: string;
    layout?: EdgeVisualizerLayout;
};

type EdgeVisualizerValue = OpenedDeclarationArray<EdgeProps>;
export type EdgeVisualizer = VisualizerFC<EdgeProps>;

export function EdgeVisualizerFactory(main: EdgeMainProp, edgeVisualizerProps: EdgeVisualizerFactoryProps) {
    const { inputProps, title, sectionTooltip, layout = 'box' } = edgeVisualizerProps;
    const useEdgeDriver = EdgeDriverFactory(main, inputProps);

    const EdgeVisualizer: EdgeVisualizer = (props) => {
        const { value, panelHost, plane = DEFAULT_PLANE, className } = props;

        const { useVisualizerUpdate, getProp, setProp } = useEdgeDriver(props);
        const translate = useTranslate(panelHost);

        const changeFromWithin = useRef(false);

        const defaultEdges = useMemo(
            () => getDimensionInputDefaults(panelHost).edgeVisualizerFactory.edges,
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [panelHost?.dimensionUnits?.defaults]
        );

        const DEFAULT_VALUES: string[] = useMemo(
            () => [defaultEdges.value.toString(), defaultEdges.value + defaultEdges.unit],
            [defaultEdges]
        );

        const getEdgeExpression = useCallback(
            (edge: Edges, prev = false) =>
                getProp<GetPropOptionString>(
                    `${main}-${edge}`,
                    {
                        type: 'string',
                        stringifyExpression: getVariableExpression,
                        prev,
                    },
                    // @ts-expect-error TODO: fix type
                    defaultEdges
                ),
            [getProp, defaultEdges]
        );

        const calcVisualizerState = (state: EdgeVisualizerState): EdgeVisualizerState => {
            let selected: SelectedEdge | undefined;
            let linked: boolean | undefined = state.linked || false;

            if (!changeFromWithin.current) {
                const defaultEdgeValue = defaultEdges.value + defaultEdges.unit;
                const edgeValuesPerEdge = EDGES.map((edge) => {
                    const edgeValue = getEdgeExpression(edge);
                    return {
                        edge,
                        main: !edgeValue || DEFAULT_VALUES.includes(edgeValue) ? defaultEdgeValue : edgeValue,
                    } as EdgeValues;
                });
                const nonEmptyEdgeValues = edgeValuesPerEdge.filter(
                    (edgeValues) => !~DEFAULT_VALUES.indexOf(edgeValues.main)
                );

                if (nonEmptyEdgeValues.length && nonEmptyEdgeValues.length < 4) {
                    linked = false;
                }
                if (nonEmptyEdgeValues.length === 1) {
                    selected = edgeToSelectedBorder(nonEmptyEdgeValues[0].edge);
                } else if (!nonEmptyEdgeValues.length) {
                    selected = SelectedEdge.All;
                    linked = true;
                } else if (
                    nonEmptyEdgeValues.length === 4 &&
                    nonEmptyEdgeValues.map((val) => val.main === nonEmptyEdgeValues[0].main).indexOf(false) === -1
                ) {
                    selected = SelectedEdge.All;
                    linked = true;
                } else if (
                    nonEmptyEdgeValues.length === 4 &&
                    nonEmptyEdgeValues.map((val) => val.main === nonEmptyEdgeValues[0].main).indexOf(false) > -1
                ) {
                    linked = false;
                }
            }

            return {
                selected: selected !== undefined ? selected : state.selected,
                editing: state.editing,
                linked: linked !== undefined ? linked : state.linked,
            };
        };

        const [{ selected, editing, linked }, dispatchState] = useReducer(
            (state: EdgeVisualizerState, action: EdgeVisualizerStateAction): EdgeVisualizerState => {
                switch (action.type) {
                    case EdgeVisualizerStateActionType.ToggleLinked:
                        return {
                            linked: !state.linked,
                            selected: state.linked ? SelectedEdge.None : SelectedEdge.All,
                            editing: SelectedEdge.None,
                        };
                    case EdgeVisualizerStateActionType.HandleSelect:
                        return {
                            ...state,
                            editing: action.select ?? state.editing,
                            selected:
                                action.select !== undefined
                                    ? state.linked
                                        ? SelectedEdge.All
                                        : action.select
                                    : state.selected,
                        };
                    case EdgeVisualizerStateActionType.HandleBlur:
                        return {
                            ...state,
                            editing: SelectedEdge.None,
                            selected: state.linked ? SelectedEdge.All : SelectedEdge.None,
                        };
                    case EdgeVisualizerStateActionType.Calc:
                        return calcVisualizerState(state);
                }
            },
            {
                selected: SelectedEdge.None,
                editing: SelectedEdge.None,
                linked: false,
            } as EdgeVisualizerState,
            calcVisualizerState
        );

        const haveEdgeExpressionsChanged = useCallback(
            () => EDGES.some((edge) => getEdgeExpression(edge, true) !== getEdgeExpression(edge)),
            [getEdgeExpression]
        );

        // TODO: Revise and re-work the setState mechanism on updates
        useVisualizerUpdate(
            useCallback(() => {
                let shouldSetState = false;
                if (editing === SelectedEdge.None && !changeFromWithin.current) {
                    if (haveEdgeExpressionsChanged()) {
                        shouldSetState = true;
                    }
                } else {
                    changeFromWithin.current = false;
                }
                if (editing !== SelectedEdge.None && editing !== SelectedEdge.All) {
                    const edge = selectedBorderToEdge(editing);
                    if (edge) {
                        const edgeProp: EdgeProps = `${main}-${edge}`;
                        const edgeOldNode = getProp<GetPropOptionLatest>(edgeProp, { type: 'latest', prev: true });
                        const edgeNewNode = getProp<GetPropOptionLatest>(edgeProp, { type: 'latest' });

                        // Check if value switches between truthy and falsey or vice versa
                        if (!!edgeOldNode !== !!edgeNewNode) {
                            shouldSetState = true;
                        }

                        if (edgeOldNode && edgeNewNode) {
                            const oldIsExpression = !!isDeclarationExpressionItem(edgeOldNode);
                            const newIsExpression = !!isDeclarationExpressionItem(edgeNewNode);

                            // Check if value switches between text and variable expression or vice versa
                            if (oldIsExpression !== newIsExpression) {
                                shouldSetState = true;
                                // Check if text value switches between number and keyword or vice versa
                            } else if (!oldIsExpression && !newIsExpression) {
                                const oldTextValue = getAstNodeText(edgeOldNode, getVariableExpression) ?? '';
                                const newTextValue = getAstNodeText(edgeNewNode, getVariableExpression) ?? '';
                                const oldIsNumber = !isNaN(parseInt(oldTextValue, 10));
                                const newIsNumber = !isNaN(parseInt(newTextValue, 10));
                                if (oldIsNumber !== newIsNumber) {
                                    shouldSetState = true;
                                }
                            }
                        }
                    }
                }
                if (shouldSetState) {
                    dispatchState({ type: EdgeVisualizerStateActionType.Calc });
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [editing, haveEdgeExpressionsChanged, getProp, panelHost?.dimensionUnits])
        );

        const toggleLinked = useCallback(() => {
            dispatchState({ type: EdgeVisualizerStateActionType.ToggleLinked });

            const message = linked
                ? StylablePanelTranslationKeys.notifications.tracking.unlocked
                : StylablePanelTranslationKeys.notifications.tracking.locked;
            panelHost?.onEditorNotify?.({
                type: 'tracking',
                title: translate(message),
                message,
            });
        }, [linked, panelHost, translate]);
        const handleSelect = (select: SelectedEdge) =>
            dispatchState({ type: EdgeVisualizerStateActionType.HandleSelect, select });
        const handleBlur = () => dispatchState({ type: EdgeVisualizerStateActionType.HandleBlur });

        const getEdgeInput = useCallback(
            (edge: Edges) => (
                <EdgeInput
                    main={main}
                    edge={edge}
                    selectedState={selected}
                    editingState={editing}
                    linkedState={linked}
                    getProp={getProp}
                    setProp={setProp}
                    handleSelect={handleSelect}
                    handleBlur={handleBlur}
                    beforeChange={() => {
                        changeFromWithin.current = true;
                    }}
                    isDebounced={layout === 'inline'}
                    props={props}
                />
            ),
            [selected, editing, linked, getProp, setProp, props]
        );

        const getLinkButton = useCallback(
            () => (
                <Tooltip
                    text={translate(
                        linked
                            ? StylablePanelTranslationKeys.controller.edge.unlockTooltip
                            : StylablePanelTranslationKeys.controller.edge.lockTooltip
                    )}
                >
                    <Button
                        className={style(
                            classes.linkButton,
                            { linked },
                            layout === 'inline' ? classes.inlineLinkButton : undefined
                        )}
                        onClick={toggleLinked}
                        isChecked={linked}
                        isToggleButton
                    >
                        <Icon>{linked ? <LinkButtonEnabled /> : <LinkButtonDisabled />}</Icon>
                    </Button>
                </Tooltip>
            ),
            [linked, toggleLinked, translate]
        );

        const renderBorderView = useCallback(
            (edge: Edges) => {
                const shapePreviewEdge = getVerticalSVGShapePreviewProps(main).edges[edge];

                const isSelected = selected === SelectedEdge.All || selected === shapePreviewEdge.select;
                const disabled = hasShorthandOverride(value, main, `${main}-${edge}` as EdgeProps);

                return (
                    <span
                        key={`selection_${edge}`}
                        className={style(classes.selection, { selected: isSelected, edge, disabled })}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={classes.svgSelection}
                            viewBox={shapePreviewEdge.viewBox}
                        >
                            <g className={style(classes.svgSelectionBorder, { selected: isSelected })}>
                                <path
                                    className={classes.svgSelectionBorderPath}
                                    d={shapePreviewEdge.svgPath}
                                    onClick={() => handleSelect(shapePreviewEdge.select)}
                                />
                                {shapePreviewEdge.innerPath && (
                                    <path
                                        className={classes.svgSelectionBorderInnerPath}
                                        fillRule="nonzero"
                                        d={shapePreviewEdge.innerPath}
                                    />
                                )}
                            </g>
                        </svg>
                    </span>
                );
            },
            [selected, value]
        );

        const renderSolidPreview = useCallback(() => {
            const { edges } = getVerticalSVGShapePreviewProps(main);

            return (Object.keys(edges) as Edges[]).map((edge) => renderBorderView(edge));
        }, [renderBorderView]);

        const renderBoxPreview = useCallback(
            () => (
                <div className={style(classes.boxWrapper)}>
                    <div className={classes.topControl}>
                        <div className={classes.verticalControlContentWrapper}>{getEdgeInput('top')}</div>
                    </div>

                    <div className={classes.borderPreviewContainer}>
                        <div className={classes.leftControl}>
                            <div className={classes.controlContainer}>
                                <div className={classes.controlContent}>{getEdgeInput('left')}</div>
                            </div>
                        </div>

                        <div className={classes.borderShapePreview}>
                            {renderSolidPreview()}
                            {getLinkButton()}
                        </div>

                        <div className={classes.rightControl}>
                            <div className={classes.controlContainer}>
                                <div className={classes.controlContent}>{getEdgeInput('right')}</div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.bottomControl}>
                        <div className={classes.verticalControlContentWrapper}>{getEdgeInput('bottom')}</div>
                    </div>
                </div>
            ),
            [getEdgeInput, getLinkButton, renderSolidPreview]
        );

        const renderInlinePreview = useCallback(
            () => (
                <div className={style(classes.inlineWrapper, { linked })}>
                    <div className={classes.inlineInput}>{getEdgeInput('top')}</div>
                    <div className={classes.inlineInput}>{getEdgeInput('right')}</div>
                    <div className={classes.inlineInput}>{getEdgeInput('bottom')}</div>
                    <div className={classes.inlineInput}>{getEdgeInput('left')}</div>
                    <div className={classes.inlineLinkButtonWrapper}>{getLinkButton()}</div>
                </div>
            ),
            [getEdgeInput, getLinkButton, linked]
        );

        const disabled = isFullExpressionVisualizer(main, props);

        return (
            <div className={style(classes.root, { plane, disabled }, className)} data-box-controller-highlight-parts>
                <CompositeBlock
                    className={style(classes.controllerBlock)}
                    title={translate(title)}
                    information={translate(sectionTooltip)}
                />
                {layout === 'box' ? renderBoxPreview() : renderInlinePreview()}
            </div>
        );
    };

    EdgeVisualizer.BLOCK_VARIANT_CONTROLLER = false;
    EdgeVisualizer.INPUT_PROPS = [main, ...inputProps] as Array<EdgeMainProp | EdgeProps>;

    return OptimisticWrapper<EdgeVisualizer, EdgeVisualizerValue>(
        EdgeVisualizer,
        {},
        true,
        visualizerOptimisticValueResolver
    );
}
