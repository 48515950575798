import React, { FC, MouseEventHandler } from 'react';
import { classes } from './save-theme.st.css';

export interface SaveCustomThemeProps {
    onSave: MouseEventHandler<HTMLButtonElement>;
    buttonText: string;
}

export const SaveCustomTheme: FC<SaveCustomThemeProps> = ({ onSave, buttonText }) => (
    <button className={classes.root} onClick={onSave}>
        {buttonText}
    </button>
);
