import type { StylesheetDriver } from '../../stylable-stylesheet';
import type { DeclarationMap } from '../../types';

/*
turn a kebab cased string into a camel cased one
 */
function kebabToCamelCase(s: string): string {
    return s.replace(/(-\w)/g, (m) => m[1].toUpperCase());
}

export function createReactStyleFromCssStyle(
    originalStyle: Record<string, string | undefined>,
    sheetDriver?: StylesheetDriver
) {
    const camelCasedVariantStyle: Record<string, string> = {};
    Object.keys(originalStyle).forEach((value) => {
        if (originalStyle[value] !== undefined) {
            let newVal = originalStyle[value];
            if (sheetDriver) {
                newVal = sheetDriver.evalDeclarationValue(newVal);
            }

            camelCasedVariantStyle[kebabToCamelCase(value)] = newVal!;
        }
    });
    return camelCasedVariantStyle;
}

export interface DeclarationMapByThemeKey {
    [key: string]: Record<string, string>;
}
export function createThemeFromDeclarationMap(declarations: DeclarationMap): DeclarationMapByThemeKey {
    const fontDecls = Object.keys(declarations).reduce((acc, key) => {
        if (key === '-st-mixin') return acc;
        const camelCasedKey = kebabToCamelCase(key);
        acc[camelCasedKey] = declarations[key]!;
        return acc;
    }, {} as Record<string, string>);
    // TODO: Take the '-st-mixin' const from stylable library
    return declarations['-st-mixin'] ? { [declarations['-st-mixin']]: fontDecls } : {};
}
