import { DEFAULT_PLANE, DIMENSION_ID, getDimensionInputDefaults } from '@wix/stylable-panel-common';
import { DownArrow, LinkButtonDisabled, LinkButtonEnabled } from '@wix/stylable-panel-common-react';
import { BackgroundBox, Button, CompositeBlock, Icon, Text, Tooltip } from '@wix/stylable-panel-components';
import {
    GenericDeclarationMap,
    stringifyBorderImage,
    stringifyTopRightBottomLeft,
    StylablePanelTranslationKeys
} from '@wix/stylable-panel-drivers';
import chroma from 'chroma-js';
import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import {
    BorderWidthDoubleVisualizer,
    BorderWidthSolidVisualizer,
    getDimensionConfig
} from '../../generated-visualizers';
import { useFillPicker, useTranslate } from '../../hooks';
import { BorderStylePicker, FillPickerTabs } from '../../pickers';
import type { VisualizerFC } from '../../types';
import {
    createDeclarationMapFromVisualizerValue,
    createDeclarationVisualizer,
    createVisualizerValueFromDeclarationMap,
    getDeclarationChangeTextValue,
    isFullExpressionVisualizer
} from '../../utils';
import {
    BorderEdges,
    BorderProps,
    BorderSide,
    BorderVisualizerStateActionType,
    BORDER_PROPS_LIST,
    DEFAULT_COLOR,
    DEFAULT_STYLE,
    getSideStyle,
    getSideValues,
    getSideWidth,
    OUTSIDE_PROPS_LIST,
    SelectedBorder
} from './border-utils';
import {
    BorderType,
    getSideControlConfiguration,
    getSideControls,
    SideControlKey
} from './border-visualizer-side-controls';
import { classes, style } from './border-visualizer.st.css';
import { useBorderVisualizer } from './use-border-visualizer';

const DEFAULT_SELECTED_BORDER = SelectedBorder.Top;

interface BorderPreviewPolygon {
    select: SelectedBorder;
    svgPath: string;
    viewBox: string;
}

const IMAGE_WIDTH_THRESHOLD = 14;
const LIGHT_THRESHOLD = 25;

// TODO: Extract to color utils?
function isLightFill(color: string) {
    try {
        return chroma.deltaE('white', color) < LIGHT_THRESHOLD;
    } catch {
        //
    }
    return false;
}

interface BorderPreviewSVG {
    width: number;
    height: number;
    sides: Record<BorderEdges, BorderPreviewPolygon>;
}

const viewBoxes = {
    verticalTopBottom: '0 0 104 18',
    leftRight: '0 0 18 98'
};

const BORDER_PREVIEW_SVG: BorderPreviewSVG = {
    width: 128,
    height: 120,
    sides: {
        top: {
            select: SelectedBorder.Top,
            svgPath:
                'M98.3939322,0.5 C99.796548,0.5 101.066378,1.06852236 101.985554,1.98769865 C102.904731,2.90687495 103.473253,4.17670518 103.473253,5.57932092 C103.473253,6.70698592 103.097995,7.80258849 102.406625,8.69345023 L102.406625,8.69345023 L97.223567,15.3720475 C96.181724,16.7145079 94.5778406,17.5 92.8785358,17.5 L92.8785358,17.5 L11.1214642,17.5 C9.42215944,17.5 7.81827599,16.7145079 6.77643302,15.3720475 L6.77643302,15.3720475 L1.59337546,8.69345023 C0.733432393,7.58537584 0.404035954,6.23364197 0.566643877,4.94394092 C0.729251801,3.65423987 1.38386409,2.42657166 2.49193848,1.5666286 C3.38280022,0.875257711 4.47840279,0.5 5.60606778,0.5 L5.60606778,0.5 Z',
            viewBox: viewBoxes.verticalTopBottom
        },
        right: {
            select: SelectedBorder.Right,
            svgPath:
                'M17.5,5.27264182 C17.5,4.00728544 16.9975077,2.79371877 16.1029967,1.89874575 C14.2396453,0.0344320871 11.2177758,0.0336519813 9.35346211,1.89700333 L2.11191681,9.13481073 C1.0798525,10.1663423 0.5,11.5657192 0.5,13.0249019 L0.5,84.9750981 C0.5,86.4342808 1.0798525,87.8336577 2.11191681,88.8651893 L9.35346211,96.1029967 C10.2484351,96.9975077 11.4620018,97.5 12.7273582,97.5 C15.3632155,97.5 17.5,95.3632155 17.5,92.7273582 L17.5,5.27264182 Z',
            viewBox: viewBoxes.leftRight
        },
        bottom: {
            select: SelectedBorder.Bottom,
            svgPath:
                'M92.8517889,0.5 C94.5510937,0.5 96.1549772,1.28549215 97.1968201,2.62795255 L97.1968201,2.62795255 L102.379878,9.30654977 C103.239821,10.4146242 103.569217,11.766358 103.406609,13.0560591 C103.244001,14.3457601 102.589389,15.5734283 101.481315,16.4333714 C100.590453,17.1247423 99.4948504,17.5 98.3671854,17.5 L98.3671854,17.5 L5.57932092,17.5 C4.17670518,17.5 2.90687495,16.9314776 1.98769865,16.0123013 C1.06852236,15.093125 0.5,13.8232948 0.5,12.4206791 C0.5,11.2930141 0.875257711,10.1974115 1.5666286,9.30654977 L1.5666286,9.30654977 L6.74968617,2.62795255 C7.79152913,1.28549215 9.39541258,0.5 11.0947173,0.5 L11.0947173,0.5 Z',
            viewBox: viewBoxes.verticalTopBottom
        },
        left: {
            select: SelectedBorder.Left,
            svgPath:
                'M0.5,5.27264182 L0.5,92.7273582 C0.5,95.3632155 2.63678453,97.5 5.27264182,97.5 C6.53799819,97.5 7.75156487,96.9975077 8.64653789,96.1029967 L15.8880832,88.8651893 C16.9201475,87.8336577 17.5,86.4342808 17.5,84.9750981 L17.5,13.0249019 C17.5,11.5657192 16.9201475,10.1663423 15.8880832,9.13481073 L8.64653789,1.89700333 C6.78222423,0.0336519813 3.76035468,0.0344320871 1.89700333,1.89874575 C1.00249227,2.79371877 0.5,4.00728544 0.5,5.27264182 Z',
            viewBox: viewBoxes.leftRight
        }
    }
};

export const BorderVisualizer: VisualizerFC<BorderProps> = (props) => {
    const { drivers, panelHost, siteVarsDriver, plane = DEFAULT_PLANE, className } = props;

    const translate = useTranslate(panelHost);
    const noResizing = props.controllerData?.noResizing;

    const getDimensionConfigForSide = useCallback(
        (side: BorderEdges, declarationMapValue?: GenericDeclarationMap<BorderProps>) => {
            const sideStyle = getSideStyle(side, declarationMapValue);
            const isDoubleStyle = sideStyle === 'double';

            return getDimensionConfig({
                id: isDoubleStyle ? DIMENSION_ID.WIDTH_DOUBLE : DIMENSION_ID.WIDTH,
                dimensionUnits: panelHost?.dimensionUnits,
                dimensionKeywords: panelHost?.dimensionKeywords
            });
        },
        [panelHost?.dimensionKeywords, panelHost?.dimensionUnits]
    );

    const BorderInitialConfig = useMemo(
        () => getDimensionInputDefaults(panelHost).borderInitialConfig,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [panelHost?.dimensionUnits?.defaults]
    );

    const {
        declarationMapValue,
        updateDeclarationMapValue,
        borderType,
        selected,
        editing,
        stylePicker,
        linked,
        dispatchState,
        toggleLinked,
        selectBorder,
        handleBlur,
        openStylePicker,
        closeStylePicker,
        changeWidth,
        changeFill,
        changeStyle,
        sideBorderPreviewStyle,
        setFallbackValue
    } = useBorderVisualizer(props, noResizing, getDimensionConfigForSide);

    useEffect(() => {
        updateDeclarationMapValue();
    }, [updateDeclarationMapValue, panelHost?.dimensionUnits]);

    const openFillPickerPanel = useFillPicker({
        className: classes.fillPicker,
        title: translate(StylablePanelTranslationKeys.controller.borders.fillPickerTitle),
        drivers,
        panelHost,
        siteVarsDriver,
        border: true
    });

    const openFillPicker = useCallback(
        (sideValues: BorderSide, side: BorderEdges) => {
            if (!openFillPickerPanel) {
                return;
            }
            setFallbackValue(sideValues, side);

            openFillPickerPanel({
                value: sideValues.fill,
                tab: borderType as any as FillPickerTabs,
                onChange: (changeValue, tab) => changeFill(tab, side, changeValue),
                onAstChange: (value, tab) => {
                    const textValue = getDeclarationChangeTextValue(value);
                    const declarations = Array.isArray(value) ? value : undefined;
                    changeFill(tab, side, textValue, declarations);
                }
            });
            dispatchState({ type: BorderVisualizerStateActionType.OpenFillPicker });
        },
        [borderType, changeFill, dispatchState, openFillPickerPanel, setFallbackValue]
    );

    const renderWidthInput = useCallback(
        (width: string, side: BorderEdges, select: SelectedBorder) => {
            const isEditing = editing === select;
            const sideStyle = getSideStyle(side, declarationMapValue);
            const isDoubleStyle = sideStyle === 'double';
            const SideVisualizer = isDoubleStyle ? BorderWidthDoubleVisualizer : BorderWidthSolidVisualizer;

            const dimensionConfig = getDimensionConfigForSide(side, declarationMapValue);

            return (
                <SideVisualizer
                    key={`${SideControlKey.Width}_${side}`}
                    className={classes.inputElement}
                    drivers={drivers}
                    value={createVisualizerValueFromDeclarationMap({ width })}
                    config={dimensionConfig}
                    tooltipContent={
                        !isEditing ? translate(StylablePanelTranslationKeys.controller.borders.widthTooltip) : undefined
                    }
                    onChange={(value) => {
                        const { width } = createDeclarationMapFromVisualizerValue(value, { value: [], drivers });
                        changeWidth(side, width);
                    }}
                    onBlur={() => handleBlur()}
                    panelHost={panelHost}
                />
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [changeWidth, declarationMapValue, drivers, editing, getDimensionConfigForSide, handleBlur, panelHost, translate, panelHost?.dimensionUnits]
    );

    const renderFillInput = useCallback(
        (sideValues: BorderSide, side: BorderEdges) => (
            <span key={`${SideControlKey.Fill}_${side}`} className={classes.miniControllerWrapper}>
                <Tooltip
                    text={translate(StylablePanelTranslationKeys.controller.borders.fillTooltip)}
                    verticalAdjust={-9}
                >
                    <BackgroundBox
                        className={classes.colorBox}
                        value={sideValues.fill}
                        noColor={sideValues.fill === DEFAULT_COLOR || sideValues.fill === 'transparent'}
                        showNoColorDiagonal
                        onClick={() => openFillPicker(sideValues, side)}
                        data-aid="st_bordercontroller_fillbox"
                    />
                </Tooltip>
            </span>
        ),
        [openFillPicker, translate]
    );

    const renderStyleInput = useCallback(
        (sideStyle: string, side: BorderEdges, select: SelectedBorder) => (
            <Tooltip
                key={`${SideControlKey.Style}_${side}`}
                text={translate(StylablePanelTranslationKeys.controller.borders.styleTooltip)}
            >
                <span className={classes.miniControllerWrapper}>
                    <span className={classes.styleBox} onClick={() => openStylePicker(select)}>
                        <span
                            className={classes.styleIndicator}
                            style={{
                                borderTopStyle:
                                    sideStyle !== DEFAULT_STYLE ? sideStyle : (BorderInitialConfig.style as any)
                            }}
                        />
                        <DownArrow className={classes.styleArrow} />
                    </span>
                </span>
            </Tooltip>
        ),
        [openStylePicker, translate, BorderInitialConfig]
    );

    const getSideControlRenderMap = useCallback(
        (
            sideValues: BorderSide,
            side: BorderEdges,
            select: SelectedBorder
        ): Record<SideControlKey, () => JSX.Element> => ({
            [SideControlKey.Width]: () => renderWidthInput(sideValues.width, side, select),
            [SideControlKey.Fill]: () => renderFillInput(sideValues, side),
            [SideControlKey.Style]: () => renderStyleInput(sideValues.style, side, select)
        }),
        [renderWidthInput, renderFillInput, renderStyleInput]
    );

    const renderSideControls = useCallback(
        (side: BorderEdges, select: SelectedBorder, noResizing = false) => {
            const isSelected =
                selected === select || (selected === SelectedBorder.All && select === DEFAULT_SELECTED_BORDER);
            const nonSolid = borderType !== BorderType.Solid;
            const image = borderType === BorderType.Image || borderType === BorderType.Pattern;
            const sideValues = getSideValues(side, declarationMapValue, borderType, panelHost);
            const sideControlConfiguration = getSideControlConfiguration({
                selected: isSelected,
                borderType,
                noResizing
            });
            const sideControlRenderMap = getSideControlRenderMap(sideValues, side, select);
            const sideControls = getSideControls(sideControlConfiguration);
            return (
                <span
                    className={style(classes.sideControls, { side, nonSolid, selected: !image && isSelected, image })}
                >
                    {sideControls.map((sideControl) => sideControlRenderMap[sideControl]())}
                    {stylePicker === select ? (
                        <BorderStylePicker
                            className={classes.stylePicker}
                            value={sideValues.style}
                            onChange={(value) => changeStyle(side, value)}
                            onClose={() => closeStylePicker()}
                            onBlur={() => closeStylePicker()}
                        />
                    ) : null}
                </span>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            borderType,
            changeStyle,
            closeStylePicker,
            declarationMapValue,
            getSideControlRenderMap,
            selected,
            stylePicker,
            panelHost?.dimensionUnits?.defaults
        ]
    );

    const renderBorderView = useCallback(
        (side: BorderEdges) => {
            const borderPreviewSide = BORDER_PREVIEW_SVG.sides[side];
            const isSelected = linked || selected === borderPreviewSide.select;
            return (
                <span
                    key={`selection_${side}`}
                    className={style(classes.selection, {
                        selected: isSelected,
                        light: isLightFill(getSideValues(side, declarationMapValue, borderType, panelHost).fill),
                        side
                    })}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={classes.svgSelection}
                        viewBox={borderPreviewSide.viewBox}
                    >
                        <g className={style(classes.svgSelectionBorder, { selected: isSelected })}>
                            <path
                                className={classes.svgSelectionBorderPath}
                                d={borderPreviewSide.svgPath}
                                onClick={() => selectBorder(borderPreviewSide.select)}
                            />
                        </g>
                    </svg>
                </span>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [borderType, declarationMapValue, linked, selectBorder, selected, panelHost?.dimensionUnits?.defaults]
    );

    const renderSolidPreview = useCallback(() => {
        const { sides } = BORDER_PREVIEW_SVG;
        return (Object.keys(sides) as BorderEdges[]).map((side) => (
            <Fragment key={side}>
                {renderBorderView(side)}
                <div
                    key={`border_side_preview_${side}`}
                    className={style(classes.borderSidePreview, { side })}
                    style={sideBorderPreviewStyle(side)}
                    onClick={() => selectBorder(sides[side].select)}
                />
            </Fragment>
        ));
    }, [renderBorderView, sideBorderPreviewStyle, selectBorder]);

    const renderSolid = useCallback(
        (noResizing = false) => (
            <>
                <div className={classes.topControl}>
                    <div className={classes.verticalControlContentWrapper}>
                        {renderSideControls('top', SelectedBorder.Top, noResizing)}
                    </div>
                </div>
                <div className={classes.borderPreviewContainer}>
                    <div className={classes.leftControl}>
                        <div className={style(classes.controlContainer)}>
                            <div className={style(classes.controlContent)}>
                                {renderSideControls('left', SelectedBorder.Left, noResizing)}
                            </div>
                        </div>
                    </div>
                    <div className={classes.borderShapePreview}>
                        {renderSolidPreview()}
                        <Tooltip
                            text={translate(
                                linked
                                    ? StylablePanelTranslationKeys.controller.borders.unlockTooltip
                                    : StylablePanelTranslationKeys.controller.borders.lockTooltip
                            )}
                        >
                            <Button
                                className={style(classes.linkButton, {
                                    borderType: 'solid',
                                    linked
                                })}
                                onClick={toggleLinked}
                                isChecked={linked}
                                isToggleButton
                            >
                                <Icon>{linked ? <LinkButtonEnabled /> : <LinkButtonDisabled />}</Icon>
                            </Button>
                        </Tooltip>
                    </div>
                    <div className={classes.rightControl}>
                        <div className={classes.controlContainer}>
                            <div className={classes.controlContent}>
                                {renderSideControls('right', SelectedBorder.Right, noResizing)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.bottomControl}>
                    <div className={classes.verticalControlContentWrapper}>
                        {renderSideControls('bottom', SelectedBorder.Bottom, noResizing)}
                    </div>
                </div>
            </>
        ),
        [linked, renderSideControls, renderSolidPreview, toggleLinked, translate]
    );

    const renderImagePreview = useCallback(() => {
        const { width, height } = BORDER_PREVIEW_SVG;
        const widths = getSideWidth(declarationMapValue, BorderType.Image) as any;
        widths.top = `${Math.min(parseFloat(widths.top), IMAGE_WIDTH_THRESHOLD)}px`;
        widths.right = `${Math.min(parseFloat(widths.right), IMAGE_WIDTH_THRESHOLD)}px`;
        widths.bottom = `${Math.min(parseFloat(widths.bottom), IMAGE_WIDTH_THRESHOLD)}px`;
        widths.left = `${Math.min(parseFloat(widths.left), IMAGE_WIDTH_THRESHOLD)}px`;
        return (
            <div
                className={classes.imageWrapper}
                style={{
                    width: `${width}px`,
                    height: `${height}px`
                }}
            >
                <div
                    className={classes.borderImagePreview}
                    style={{
                        borderImage: stringifyBorderImage({
                            'border-image-source': declarationMapValue['border-image-source'],
                            'border-image-slice': declarationMapValue['border-image-slice'],
                            'border-image-width': stringifyTopRightBottomLeft(widths),
                            'border-image-repeat': declarationMapValue['border-image-repeat']
                        })
                    }}
                >
                    <div className={classes.imageInner} />
                </div>
            </div>
        );
    }, [declarationMapValue]);

    const renderImage = useCallback(
        (noResizing = false) => (
            <>
                <div className={classes.topControl}>{renderSideControls('top', SelectedBorder.Top, noResizing)}</div>
                <div className={classes.borderPreviewContainer}>
                    <div className={classes.leftControl}>
                        <div className={classes.controlContainer}>
                            <div className={classes.controlContent} />
                        </div>
                    </div>
                    <div className={classes.borderShapePreview}>
                        {renderImagePreview()}
                        <Button
                            className={style(classes.linkButton, {
                                borderType: 'image',
                                linked: true
                            })}
                            isChecked
                            isToggleButton
                        >
                            <Icon>
                                <LinkButtonEnabled />
                            </Icon>
                        </Button>
                    </div>
                    <div className={classes.rightControl}>
                        <div className={classes.controlContainer}>
                            <div className={classes.controlContent} />
                        </div>
                    </div>
                </div>
            </>
        ),
        [renderSideControls, renderImagePreview]
    );

    const disabled = isFullExpressionVisualizer('border', props);
    const solid = useMemo(() => borderType === BorderType.Solid || borderType === BorderType.Gradient, [borderType]);
    return (
        <div className={style(classes.root, { plane, disabled }, className)} data-box-controller-highlight-parts>
            <Text className={classes.title}>{translate(StylablePanelTranslationKeys.controller.borders.title)}</Text>
            <CompositeBlock
                className={style(classes.controllerBlock)}
                title={translate(StylablePanelTranslationKeys.controller.borders.sectionLabel)}
                information={translate(StylablePanelTranslationKeys.controller.borders.sectionTooltip)}
            />
            <div className={classes.boxWrapper}>{solid ? renderSolid(noResizing) : renderImage(noResizing)}</div>
        </div>
    );
};

BorderVisualizer.BLOCK_VARIANT_CONTROLLER = false;
BorderVisualizer.INPUT_PROPS = BORDER_PROPS_LIST;
BorderVisualizer.OUTSIDE_PROPS = OUTSIDE_PROPS_LIST;

export const BorderDeclarationVisualizer = createDeclarationVisualizer<BorderProps>('border', BorderVisualizer);
