import React from 'react';
import { Text } from '../text/text';
import { classes, style } from './drawer.st.css';

export interface DrawerProps {
    drawerKey: string;
    children?: React.ReactNode;
    className?: string;
    onOpenClick?: () => void;
    open?: boolean;
    style?: React.CSSProperties;
    title?: string;
}

export const Drawer = ({ children, className, drawerKey, onOpenClick, open, style: propStyle, title }: DrawerProps) => (
    <>
        {[
            <div
                key={drawerKey}
                className={style(classes.root, { open: !!open }, className)}
                onClick={onOpenClick}
                style={propStyle}
            >
                <Text className={classes.drawerTitle}>{title}</Text>
            </div>,
            ...React.Children.toArray(open ? children : null),
        ]}
    </>
);
