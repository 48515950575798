import React, { createRef } from 'react';
import chroma from 'chroma-js';

import { ColorPickerInput, IconButton, TextLabel, Tooltip } from '@wix/wix-base-ui';
import LinkPropertiesSmall from 'wix-ui-icons-common/classic-editor/LinkPropertiesSmall';
import UnlinkPropertiesSmall from 'wix-ui-icons-common/classic-editor/UnlinkPropertiesSmall';
import {
    COLOR_ADDER_HSB,
    COLOR_ADDER_HSB_HUE,
    COLOR_ADDER_RGB,
    DIMENSION_ID,
    DIMENSION_INPUT_DEFAULTS,
    PERCENTS_RANGE,
} from '@wix/stylable-panel-common';
import { isClickInElement, isInRect } from '@wix/stylable-panel-common-react';
import {
    ColorAdder,
    ColorAdderStrings,
    CompositeBlock,
    DimensionInput,
    isWhite,
    LabelWithAction,
    PalettePicker,
    PalettePickerStrings,
    TooltipAttachTo,
    TranslateDriver,
} from '@wix/stylable-panel-components';
import {
    COLOR_VAR_CATEGORY_USER,
    DEFAULT_EVAL_DECLARATION_VALUE,
    SiteVarsDriver,
    StylablePanelTranslationKeys,
    Var,
} from '@wix/stylable-panel-drivers';

import type { DeclarationVisualizerDrivers, StylablePanelHost } from '../../types';
import { OpenedDeclarationArray, OriginNode, isDeclarationExpressionItem } from '../../declaration-types';
import { getDimensionConfig } from '../../generated-visualizers';
import { PanelEventList } from '../../hosts/bi';
import { getTranslate } from '../../hosts/translate';
import {
    getPropValueDeclaration,
    createDeclarationMapFromVisualizerValue,
    createVisualizerValueFromDeclarationMap,
} from '../../utils/visualizer-utils';
import { FillPickerContext } from '../fill-picker/fill-picker-context';
import { CustomSkinsPicker, MySkinsPicker } from '../my-skins-picker/my-skins-picker';

import { classes, style } from './color-picker.st.css';
import { AdvancedColorsPicker } from '../advanced-colors-picker/advanced-colors-picker';

import { langs } from './texts';

export const COLOR_VALUE_TYPE = 'color';

const PALETTE_PICKER_HORIZONTAL_ADJUSTMENT = 6;
const PALETTE_PICKER_HEADER_HEIGHT_VERTICAL_ADJUSTMENT = 48;

// Temporary variable to not show selected advanced color.
// Since colorpicker finds the selected highlighted color by hex,
// some advanced colors will be higher in the list and will get incorrectly selected.
const disableAdvancedColors = true;

export enum ColorCategory {
    None = -1,
    Site = 0,
    User = 1,
}

export interface ColorPickerStrings {
    themeSelectionTitle: string;
    doneLinkLabel: string;
    changeLinkLabel: string;
    changeLinkTooltip: string;
    myColorsTitle: string;
    myColorsInformationTooltip: string;
    addColorLabel: string;
}

const ColorPickerStringsFallbacks: ColorPickerStrings = {
    themeSelectionTitle: 'Theme Colors',
    doneLinkLabel: 'Done',
    changeLinkLabel: 'Change colors',
    changeLinkTooltip: "Change your site's color theme",
    myColorsTitle: 'Custom Colors',
    myColorsInformationTooltip: 'Add colors so you can easily find and reuse them later.',
    addColorLabel: '+ Add Color',
};

const DEFAULT_OPACITY = DIMENSION_INPUT_DEFAULTS.colorPicker.opacity;

export interface ColorPickerTranslations {
    colorPicker: ColorPickerStrings;
    colorAdder: ColorAdderStrings;
    palettePicker: PalettePickerStrings;
}

export interface GetColorPickerAPIOptions {
    currentColor?: string;
    onChange?: (value: string) => void;
    onAstChange?: (value: string, declarations: OpenedDeclarationArray<string>) => void;
    drivers?: DeclarationVisualizerDrivers;
}

export interface ASTPickerAPI<PROPS extends string = string> {
    astValue?: OpenedDeclarationArray<PROPS>;
    onAstChange?: (
        declarations: OpenedDeclarationArray<PROPS>,
        stringifyExpression?: (v: OriginNode) => string
    ) => void;
}

export interface ColorPickerAPI extends ASTPickerAPI {
    currentColor?: string;
    onChange?: (value: string) => void;
}

export const getColorPickerAPI = ({
    currentColor,
    onChange,
    onAstChange,
    drivers,
}: GetColorPickerAPIOptions): ColorPickerAPI => ({
    currentColor,
    onChange,
    astValue: currentColor ? createVisualizerValueFromDeclarationMap({ [COLOR_VALUE_TYPE]: currentColor }) : undefined,
    onAstChange: (value, stringifyExpression) => {
        if (onChange) {
            const changeValue = createDeclarationMapFromVisualizerValue(
                value,
                drivers ? { value: [], drivers } : undefined,
                stringifyExpression
            )[COLOR_VALUE_TYPE];
            if (changeValue) {
                if (onAstChange && value.some((decl) => decl.value.some(isDeclarationExpressionItem))) {
                    const astChangeValue = getPropValueDeclaration(value, COLOR_VALUE_TYPE);
                    if (astChangeValue) {
                        onAstChange(changeValue, [astChangeValue]);
                    }
                } else {
                    onChange(changeValue);
                }
            }
        }
    },
});

export interface ColorPickerStaticProps {
    title?: string;
    panelHost?: StylablePanelHost;
    siteVarsDriver?: SiteVarsDriver;
    className?: string;
}

export interface ColorPickerDynamicProps extends ColorPickerAPI {
    id?: string;
    title?: string;
    onReset?: () => void;
    onHover?: (value: string | null) => void;
    onBlur?: () => void;
    customSkinsPicker?: CustomSkinsPicker;
    minimalMode?: boolean;
    noOpacity?: boolean;
    noAddColor?: boolean;
}

export interface ColorPickerProps extends ColorPickerStaticProps, ColorPickerDynamicProps {}

export type CustomColorPicker = React.ComponentClass<ColorPickerProps>;

export interface ColorPickerState {
    selectedCategory: ColorCategory;
    hoveredCategory: ColorCategory;
    selectedColor: string | null;
    hoveredColor: string | null;
    alpha: number;
    adderShown: boolean;
    palettePickerShown: boolean;
    advancedSettingsShown: boolean;
    paletteIndex: number;
}

// TODO: When moving from no color to color - opacity should reset to 100
export class ColorPicker extends React.Component<ColorPickerProps, ColorPickerState> {
    public static panelName = 'color';
    public static contextType = FillPickerContext;
    context: React.ContextType<typeof FillPickerContext>;

    private componentId = this.props.id || 'colorPicker';
    private fallbackColor: string | null = null;
    private translations!: ColorPickerTranslations;
    private translateDriver: TranslateDriver<ColorPickerStrings>;
    private newPaletteTranslations: Record<string, string>;

    private colorPicker = createRef<HTMLDivElement>();
    private colorAdder = createRef<ColorAdder>();
    private palettePicker = createRef<PalettePicker>();

    constructor(props: ColorPickerProps, context: FillPickerContext) {
        super(props);

        this.context = context;
        const color = this.setSelectedColor();

        this.state = {
            selectedCategory: color.selectedCategory,
            hoveredCategory: ColorCategory.None,
            selectedColor: color.selectedColor,
            hoveredColor: null,
            alpha: color.alpha,
            adderShown: false,
            palettePickerShown: false,
            advancedSettingsShown: false,
            paletteIndex: this.getSelectedPaletteIndex(),
        };

        const { panelHost } = this.getContextProps();
        this.setTranslations(panelHost);
        this.translateDriver = new TranslateDriver<ColorPickerStrings>(
            ColorPickerStringsFallbacks,
            this.translations.colorPicker
        );
        this.newPaletteTranslations = langs[panelHost?.languageCode ?? 'en'];

        document.addEventListener('mousedown', this.handleDocumentMouseDown);
    }

    public adderOpen() {
        return this.state.adderShown;
    }
    public palettePickerOpen() {
        return this.state.palettePickerShown;
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleDocumentMouseDown);
    }

    public render() {
        const { minimalMode, noOpacity, noAddColor, className } = this.props;
        const { panelHost } = this.getContextProps();
        const { alpha, adderShown, palettePickerShown, paletteIndex } = this.state;
        const { translate } = this.translateDriver;

        const displayedColor = this.getDisplayedColor();
        const displayedColorHex = displayedColor ? displayedColor.hex('rgb').toUpperCase() : '';

        const palettes = panelHost && panelHost.getPalettes ? panelHost.getPalettes() : [];
        const onSiteColorChange = panelHost?.onSiteColorChange;

        const changeColorActionTooltipText = palettePickerShown
            ? undefined
            : onSiteColorChange
            ? translate('changeLinkTooltip')
            : undefined;

        const { dimensionUnits, dimensionKeywords } = panelHost || {};
        const percentsRangeDimensionConfig = getDimensionConfig({
            id: DIMENSION_ID.COLOR_PICKER_PERCENTS_RANGE,
            dimensionUnits,
            customUnits: PERCENTS_RANGE,
            dimensionKeywords,
        });
        const colorAdderUnits = {
            [DIMENSION_ID.COLOR_ADDER_RGB]: getDimensionConfig({
                id: DIMENSION_ID.COLOR_ADDER_RGB,
                dimensionUnits,
                customUnits: COLOR_ADDER_RGB,
            }).units,
            [DIMENSION_ID.COLOR_ADDER_HSB]: getDimensionConfig({
                id: DIMENSION_ID.COLOR_ADDER_HSB,
                dimensionUnits,
                customUnits: COLOR_ADDER_HSB,
            }).units,
            [DIMENSION_ID.COLOR_ADDER_HSB_HUE]: getDimensionConfig({
                id: DIMENSION_ID.COLOR_ADDER_HSB,
                dimensionUnits,
                customUnits: COLOR_ADDER_HSB_HUE,
            }).units,
        };

        const opacity = Math.round(alpha * 100) + DEFAULT_OPACITY.unit;
        // @TODO
        const isConnectedToColorDefaults = false;

        return [
            <div
                key="color_picker"
                className={style(classes.root, { adder: adderShown, minimalMode: !!minimalMode }, className)}
                ref={this.colorPicker}
                data-aid="st_colorpicker"
            >
                {!minimalMode ? (
                    <header>
                        <LabelWithAction
                            className={classes.siteColorsTitle}
                            text={translate('themeSelectionTitle')}
                            actionText={palettePickerShown ? translate('doneLinkLabel') : translate('changeLinkLabel')}
                            actionTooltipAttachSide={TooltipAttachTo.Right}
                            actionTooltipText={changeColorActionTooltipText}
                            onActionClick={this.handleChangeSiteColorsClick}
                            actionDataAid="st_colorpicker_changesitecolorsbutton"
                        />
                    </header>
                ) : null}
                {panelHost?.experiments?.isNewPalette ? this.renderNewColorPalette() : this.renderSiteColorDisplay()}

                {panelHost?.experiments?.isNewPalette && (
                    <div className={classes.advancedSettingsButton}>
                        <TextLabel
                            shouldTranslate={false}
                            value={
                                this.newPaletteTranslations[
                                    StylablePanelTranslationKeys.picker.color.advancedSettingsBtnLabel
                                ]
                            }
                        />
                        <Tooltip
                            shouldTranslate={false}
                            alignment="RIGHT"
                            marginRight={10}
                            content={
                                isConnectedToColorDefaults
                                    ? this.newPaletteTranslations[
                                          StylablePanelTranslationKeys.picker.color.advancedSettingsBtnTooltipConnected
                                      ]
                                    : this.newPaletteTranslations[
                                          StylablePanelTranslationKeys.picker.color
                                              .advancedSettingsBtnTooltipNotConnected
                                      ]
                            }
                        >
                            <IconButton
                                onClick={() => this.setState({ advancedSettingsShown: true })}
                                priority="secondary"
                                dataHook="open-advanced-settings-btn"
                            >
                                {isConnectedToColorDefaults ? <LinkPropertiesSmall /> : <UnlinkPropertiesSmall />}
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
                {!noOpacity ? (
                    <CompositeBlock className={classes.editColor}>
                        <DimensionInput
                            className={classes.inputElementOpacity}
                            value={opacity}
                            config={percentsRangeDimensionConfig}
                            isSlider={true}
                            keepRange={true}
                            opacitySliderColor={displayedColorHex}
                            onChange={
                                displayedColor ? (value) => this.setColorAlpha(displayedColor, value) : () => null
                            }
                            showUnitErrors={panelHost?.showDimensionErrors}
                            translate={getTranslate(panelHost)}
                            translationKeys={StylablePanelTranslationKeys.component.dimensionInput}
                        />
                    </CompositeBlock>
                ) : null}
                {this.renderSkinsPicker(this.getColorCSSValue(displayedColorHex))}
                {!noAddColor ? (
                    <footer className={classes.footer}>
                        <LabelWithAction
                            className={classes.footerLabelWithAction}
                            text={displayedColorHex}
                            actionText={translate('addColorLabel')}
                            onActionClick={() => this.setState({ adderShown: true, palettePickerShown: false })}
                            actionDataAid="st_colorpicker_addcolorbutton"
                        />
                    </footer>
                ) : null}
                {adderShown ? (
                    <ColorAdder
                        className={classes.colorAdder}
                        initialColor={displayedColorHex}
                        onAdd={this.handleUserColorAdd}
                        onCancel={() => this.setState({ adderShown: false })}
                        translations={this.translations.colorAdder}
                        units={colorAdderUnits}
                        ref={this.colorAdder}
                        data-aid="st_colorpicker_coloradder"
                        showDimensionErrors={panelHost?.showDimensionErrors}
                        translate={getTranslate(panelHost)}
                        translationKeys={StylablePanelTranslationKeys.component.dimensionInput}
                    />
                ) : null}
                {this.state.advancedSettingsShown && (
                    <AdvancedColorsPicker
                        colors={this.getContextProps().siteVarsDriver?.newSiteColors}
                        onItemClick={this.setSiteColor.bind(this)}
                        selectedColor={this.state.selectedColor}
                        onClose={() => this.setState({ advancedSettingsShown: false })}
                        translations={this.newPaletteTranslations}
                        onEditColorDefaults={panelHost?.onSiteColorChange}
                        openColorDefaultsHelp={panelHost?.openColorDefaultsHelp}
                        getLinkedThemeColorFromColorDefault={panelHost?.getLinkedThemeColorFromColorDefault}
                        disableAdvancedColors={disableAdvancedColors}
                    />
                )}
            </div>,
            palettePickerShown ? (
                <PalettePicker
                    key="palette_picker"
                    className={classes.palettePicker}
                    style={this.getPalettePickerStyle()}
                    palettes={palettes}
                    selectedPalette={paletteIndex}
                    // onHover={index => this.handlePaletteChange(index !== -1 ? index : paletteIndex, false)}
                    onSelect={(index) => this.handlePaletteChange(index, true)}
                    translations={this.translations.palettePicker}
                    ref={this.palettePicker}
                    data-aid="st_colorpicker_palettepicker"
                />
            ) : null,
        ];
    }

    private getContextProps = () => {
        const { siteVarsDriver: contextSiteVarsDriver, panelHost: contextPanelHost } = this.context;
        const { siteVarsDriver: propsSiteVarsDriver, panelHost: propsPanelHost } = this.props;
        return {
            siteVarsDriver: contextSiteVarsDriver ?? propsSiteVarsDriver,
            panelHost: contextPanelHost ?? propsPanelHost,
        };
    };

    private setSelectedColor() {
        const { currentColor } = this.props;
        const { siteVarsDriver, panelHost } = this.getContextProps();
        const evalDeclarationValue =
            siteVarsDriver?.evalDeclarationValue?.bind(siteVarsDriver) ?? DEFAULT_EVAL_DECLARATION_VALUE;

        let alpha = DEFAULT_OPACITY.value / 100;
        let selectedCategory = ColorCategory.None;
        let selectedColor = null;

        const evalCurrentColor = evalDeclarationValue(currentColor);

        if (evalCurrentColor) {
            this.fallbackColor = evalCurrentColor;

            try {
                const currColorObj = chroma(evalCurrentColor);
                alpha = currColorObj.alpha();

                const siteColor = siteVarsDriver?.findSiteColor__new(
                    currColorObj,
                    panelHost?.experiments?.isNewPalette,
                    panelHost?.visibleThemeColors
                );
                if (siteColor) {
                    selectedCategory = ColorCategory.Site;
                    selectedColor = siteColor;
                } else {
                    const userColor = siteVarsDriver?.findUserColor__new(currColorObj);
                    if (userColor) {
                        selectedCategory = ColorCategory.User;
                        selectedColor = userColor;
                    }
                }
            } catch {
                //
            }
        }

        return { selectedCategory, selectedColor, alpha };
    }

    private getSelectedPaletteIndex() {
        const { siteVarsDriver, panelHost } = this.getContextProps();

        const palettes = panelHost && panelHost.getPalettes ? panelHost.getPalettes() : [];
        if (!siteVarsDriver || palettes.length === 0) {
            return -1;
        }

        const colors = panelHost?.experiments?.isNewPalette ? siteVarsDriver.newSiteColors : siteVarsDriver.siteColors;
        const siteValues = [...colors]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(({ value }) => value)
            .join(',');

        let paletteIndex = -1;
        for (let i = 0; i < palettes.length; i++) {
            const currentPalette = palettes[i].columns
                .reduce((flatColumns, column) => flatColumns.concat(column), [] as string[])
                .join(',');
            if (currentPalette === siteValues) {
                paletteIndex = i;
                break;
            }
        }
        return paletteIndex;
    }

    // @ts-ignore
    private renderSiteColorDisplay() {
        const { minimalMode } = this.props;
        const { siteVarsDriver } = this.getContextProps();
        const { selectedCategory, selectedColor } = this.state;

        return (
            <div className={classes.paletteDisplayer}>
                {siteVarsDriver?.siteColors.map(({ name, value }, index) => [
                    <input
                        key={`site_color_${index}_radio`}
                        className={classes.hiddenRadio}
                        type="radio"
                        name={COLOR_VALUE_TYPE}
                        value={`value(${name})`}
                        checked={selectedCategory === ColorCategory.Site && selectedColor === name}
                        onChange={() => null}
                    />,
                    <div
                        className={style(classes.siteColorItem, {
                            minimal: !!minimalMode,
                            white: isWhite(chroma(value)),
                        })}
                        key={`site_color_${index}_item`}
                        style={{ backgroundColor: value }}
                        onClick={() => this.setSiteColor(name)}
                        onMouseEnter={() => this.hoverSiteColor(name)}
                        onMouseLeave={this.getFallbackColor}
                        data-aid="st_colorpicker_sitecoloritem"
                    />,
                ])}
            </div>
        );
    }

    private renderNewColorPalette() {
        const { siteVarsDriver, panelHost } = this.getContextProps();
        const { selectedCategory, selectedColor } = this.state;

        const themeColors = siteVarsDriver?.newSiteColors.filter(({ name }) =>
            panelHost?.visibleThemeColors?.includes(name)
        );

        const linkedColor = panelHost?.getLinkedThemeColorFromColorDefault?.(selectedColor);
        const labels = StylablePanelTranslationKeys.picker.advancedSettings.labels;

        return (
            <div className={classes.newPaletteDisplayer}>
                {themeColors?.map(({ name, value }) => (
                    <Tooltip
                        key={name}
                        content={this.newPaletteTranslations[
                            StylablePanelTranslationKeys.picker.color.colorPickerInputConnectedTooltip
                        ].replace(
                            '<%= role_name %>',
                            this.newPaletteTranslations[labels[selectedColor as keyof typeof labels]]
                        )}
                        disabled={disableAdvancedColors || linkedColor !== name}
                    >
                        <div
                            className={classes.colorControlWrapper}
                            onMouseEnter={() => this.hoverSiteColor(name)}
                            onMouseLeave={this.getFallbackColor}
                        >
                            <ColorPickerInput
                                value={value}
                                onClick={() => this.setSiteColor(name)}
                                selected={
                                    selectedCategory === ColorCategory.Site &&
                                    (selectedColor === name || linkedColor === name)
                                }
                            />
                        </div>
                    </Tooltip>
                ))}
            </div>
        );
    }

    private handleRemoveCustomColor = (index: number, color: Var) => {
        const { selectedCategory, hoveredCategory, selectedColor, hoveredColor } = this.state;
        const { siteVarsDriver, panelHost } = this.getContextProps();

        panelHost && panelHost.unblockCommits && panelHost.unblockCommits(true, this.componentId);

        const removed = siteVarsDriver?.removeUserValue(COLOR_VAR_CATEGORY_USER, index) ?? false;
        if (panelHost && panelHost.onUserColorRemove) {
            panelHost.onUserColorRemove(index);
        }

        if (removed) {
            if (hoveredCategory === ColorCategory.User && hoveredColor === color.name) {
                this.setState({ hoveredColor: null });
            }

            if (selectedCategory === ColorCategory.User && selectedColor === color.name) {
                this.setState({ selectedColor: null });
            }
        }
    };

    private renderSkinsPicker = (currentColor: string) => {
        const { onChange, onHover, onReset, customSkinsPicker: CustomSkinsPickerComp } = this.props;
        const { siteVarsDriver } = this.getContextProps();
        const { selectedCategory, selectedColor } = this.state;
        const { translate } = this.translateDriver;

        if (CustomSkinsPickerComp) {
            return (
                <CustomSkinsPickerComp
                    value={currentColor}
                    valueType={COLOR_VALUE_TYPE}
                    onItemClick={(_index, { value }) => onChange && onChange(this.getColorCSSValue(value))}
                    onItemEnter={(_index, { value }) => onHover && onHover(this.getColorCSSValue(value))}
                    onItemLeave={() => onHover && onHover(this.fallbackColor)}
                />
            );
        }

        return (
            <MySkinsPicker
                className={classes.myColors}
                title={translate('myColorsTitle')}
                information={translate('myColorsInformationTooltip')}
                values={siteVarsDriver?.getCategoryValues(COLOR_VAR_CATEGORY_USER)}
                radioGroupName={COLOR_VALUE_TYPE}
                itemDataAid="st_colorpicker_usercoloritem"
                isChecked={(_, color) => selectedCategory === ColorCategory.User && selectedColor === color.name}
                isWhite={(color) => isWhite(chroma(color))}
                onItemClick={(_, { name }) => this.setUserColor(name)}
                onItemEnter={(_, { name }) => this.hoverUserColor(name)}
                onItemLeave={() => this.getFallbackColor()}
                onAdd={() => this.setState({ adderShown: true, palettePickerShown: false })}
                onItemRemove={this.handleRemoveCustomColor}
                onReset={
                    onReset
                        ? () => {
                              onReset();
                              this.fallbackColor = null;
                              this.setState({
                                  selectedCategory: ColorCategory.None,
                                  selectedColor: null,
                              });
                          }
                        : undefined
                }
            />
        );
    };

    private setSiteColor(selectedColor: string) {
        const { selectedCategory, selectedColor: stateSelectedColor } = this.state;
        const { panelHost } = this.getContextProps();

        if (selectedCategory === ColorCategory.Site && stateSelectedColor === selectedColor) {
            return;
        }
        panelHost && panelHost.unblockCommits && panelHost.unblockCommits(true, this.componentId);
        this.setState({ selectedCategory: ColorCategory.Site, selectedColor });

        this.dispatchSiteColor(selectedColor, this.props.onChange);

        panelHost && panelHost.blockCommits && panelHost.blockCommits(this.componentId);
    }

    private hoverSiteColor(hoveredColor: string) {
        const { hoveredCategory, hoveredColor: stateHoveredColor } = this.state;
        const { panelHost } = this.getContextProps();

        if (hoveredCategory === ColorCategory.Site && stateHoveredColor === hoveredColor) {
            return;
        }
        panelHost && panelHost.blockCommits && panelHost.blockCommits(this.componentId);
        this.setState({ hoveredCategory: ColorCategory.Site, hoveredColor });

        this.dispatchSiteColor(hoveredColor, this.props.onHover);
    }

    private setUserColor(selectedColor: string, closeAdder = false) {
        const { onChange } = this.props;
        const { siteVarsDriver, panelHost } = this.getContextProps();
        const { selectedCategory, selectedColor: stateSelectedColor, adderShown } = this.state;

        if (selectedCategory === ColorCategory.User && stateSelectedColor === selectedColor) {
            return;
        }
        panelHost && panelHost.unblockCommits && panelHost.unblockCommits(true, this.componentId);
        this.setState({
            selectedCategory: ColorCategory.User,
            selectedColor,
            adderShown: closeAdder ? false : adderShown,
        });

        if (!onChange || !siteVarsDriver) {
            return;
        }

        const colorVar = siteVarsDriver.userColorsMap[selectedColor];
        onChange(this.getColorCSSValue(colorVar));

        panelHost && panelHost.blockCommits && panelHost.blockCommits(this.componentId);
    }

    private hoverUserColor(hoveredColor: string) {
        const { onHover } = this.props;
        const { siteVarsDriver, panelHost } = this.getContextProps();
        const { hoveredCategory, hoveredColor: stateHoveredColor } = this.state;

        if (hoveredCategory === ColorCategory.User && stateHoveredColor === hoveredColor) {
            return;
        }
        panelHost && panelHost.blockCommits && panelHost.blockCommits(this.componentId);
        this.setState({ hoveredCategory: ColorCategory.User, hoveredColor });

        if (!onHover || !siteVarsDriver) {
            return;
        }

        const colorVar = siteVarsDriver.userColorsMap[hoveredColor];
        onHover(this.getColorCSSValue(colorVar));
    }

    private setColorAlpha(color: chroma.Color, alpha?: string) {
        const { onChange } = this.props;
        const { selectedCategory, selectedColor } = this.state;

        if (!onChange) return;

        const newAlpha = parseFloat(alpha || DEFAULT_OPACITY.value.toString()) / 100;
        const newColor = color.alpha(newAlpha);
        if (newAlpha === 1) {
            if (selectedCategory !== ColorCategory.None && selectedColor) {
                onChange(this.getValue(selectedCategory, selectedColor)!);
            } else {
                onChange(newColor.hex('rgb').toUpperCase());
            }
        } else {
            onChange(newColor.css());
        }

        this.setState({ alpha: newAlpha });
    }

    private handleUserColorAdd = (value: string) => {
        const { siteVarsDriver, panelHost } = this.getContextProps();

        siteVarsDriver?.addUserColor(value);
        panelHost?.onUserColorAdd?.(value);
        if (siteVarsDriver) {
            const { userColors } = siteVarsDriver;
            this.setUserColor(userColors[userColors.length - 1]?.name, true);
        }
    };

    private handlePaletteChange(paletteIndex: number, select: boolean) {
        const { onChange /*, onHover*/ } = this.props;
        const { panelHost } = this.getContextProps();
        const { selectedCategory, selectedColor } = this.state;
        const { PALETTE_SELECT } = PanelEventList;
        const isSiteColorSelected = selectedCategory === ColorCategory.Site && selectedColor;

        // if (isSiteColorSelected) {
        //     this.dispatchSiteColor(selectedColor, select ? onChange : onHover);
        // }
        if (isSiteColorSelected && select) {
            this.dispatchSiteColor(selectedColor, onChange);
        }

        if (panelHost && panelHost.onPaletteChange) {
            panelHost.onPaletteChange(paletteIndex, !select);
            panelHost.reportBI?.(PALETTE_SELECT, { value: paletteIndex });

            if (select) {
                this.setState({ paletteIndex });
            }
        }
    }

    private dispatchSiteColor(colorName: string, callback?: (value: string) => void) {
        const { siteVarsDriver } = this.getContextProps();

        if (!callback || !siteVarsDriver) {
            return;
        }

        if (this.state.alpha === 1) {
            callback(`value(${colorName})`);
        } else {
            callback(this.getColorCSSValue(siteVarsDriver.siteColorsMap[colorName]));
        }
    }

    private getValue(category: ColorCategory, colorName: string) {
        const { siteVarsDriver } = this.getContextProps();

        if (!siteVarsDriver) {
            return null;
        }

        switch (category) {
            case ColorCategory.Site:
                return `value(${colorName})`;
            case ColorCategory.User:
                return siteVarsDriver.userColorsMap[colorName] ?? null;
        }
        return null;
    }

    private getDisplayedColor() {
        const { alpha, selectedCategory, hoveredCategory, selectedColor, hoveredColor } = this.state;

        if (hoveredCategory !== ColorCategory.None && hoveredColor) {
            return this.getColor(hoveredCategory, hoveredColor);
        } else if (selectedCategory !== ColorCategory.None && selectedColor) {
            return this.getColor(selectedCategory, selectedColor);
        } else if (this.fallbackColor) {
            try {
                return chroma(this.fallbackColor).alpha(alpha);
            } catch {
                //
            }
        }
        return null;
    }

    private getColor(category: ColorCategory, color: string) {
        const { siteVarsDriver } = this.getContextProps();
        const { alpha } = this.state;
        if (!siteVarsDriver) {
            return null;
        }
        if (category === ColorCategory.Site) {
            return chroma(siteVarsDriver.siteColorsMap[color]).alpha(alpha);
        } else {
            const userColor = siteVarsDriver.userColorsMap[color];
            return userColor ? chroma(userColor).alpha(alpha) : null;
        }
    }

    private getFallbackColor = () => {
        const { onHover } = this.props;
        const { siteVarsDriver, panelHost } = this.getContextProps();
        const { alpha, selectedCategory, selectedColor } = this.state;

        this.setState({ hoveredCategory: ColorCategory.None, hoveredColor: null });

        if (!onHover) return;

        let fallbackColor: string | null = this.fallbackColor;
        if (selectedCategory !== ColorCategory.None && selectedColor) {
            if (alpha === 1) {
                fallbackColor = this.getValue(selectedCategory, selectedColor);
            } else {
                fallbackColor = siteVarsDriver
                    ? this.getColorCSSValue(
                          (selectedCategory === ColorCategory.Site
                              ? siteVarsDriver.siteColorsMap
                              : siteVarsDriver.userColorsMap)[selectedColor]
                      )
                    : null;
            }
        }

        onHover(fallbackColor);
        panelHost && panelHost.unblockCommits && panelHost.unblockCommits(false, this.componentId);
        panelHost && panelHost.onRevertQuickChange && panelHost.onRevertQuickChange();
    };

    private getColorCSSValue = (value: string) => {
        const { alpha } = this.state;

        return alpha === 1 ? value : chroma(value).alpha(alpha).css();
    };

    private getPalettePickerStyle(): React.CSSProperties {
        const colorPickerRect = this.colorPicker.current?.getBoundingClientRect();
        return colorPickerRect
            ? {
                  position: 'fixed',
                  height: `${colorPickerRect.height + PALETTE_PICKER_HEADER_HEIGHT_VERTICAL_ADJUSTMENT}px`,
                  top: `${colorPickerRect.top - PALETTE_PICKER_HEADER_HEIGHT_VERTICAL_ADJUSTMENT}px`,
                  left: `${colorPickerRect.right + PALETTE_PICKER_HORIZONTAL_ADJUSTMENT}px`,
                  zIndex: 1,
              }
            : {};
    }

    private handleDocumentMouseDown = (event: MouseEvent) => {
        if (this.colorAdder.current && this.adderOpen()) {
            if (!isClickInElement(event, this.colorAdder.current)) {
                this.setState({ adderShown: false });
            }
            return;
        }

        const { onBlur } = this.props;

        if (
            (this.colorPicker.current &&
                isInRect(event.clientX, event.clientY, this.colorPicker.current?.getBoundingClientRect())) ||
            (this.palettePickerOpen() &&
                this.palettePicker.current &&
                isClickInElement(event, this.palettePicker.current))
        ) {
            return;
        }

        onBlur?.();
    };

    private setTranslations(panelHost?: StylablePanelHost) {
        const translate = getTranslate(panelHost);

        const {
            themeSelection: { title: themeSelectionTitle, doneLinkLabel, changeLinkLabel, changeLinkTooltip },
            myColorsTitle,
            myColorsInformationTooltip,
            addColorLabel,
            colorAdder: { hexTabLabel, rgbTabLabel, hsbTabLabel, cancelButtonLabel, addButtonLabel },
            palettePicker: { title },
        } = StylablePanelTranslationKeys.picker.color;

        this.translations = {
            colorPicker: {
                themeSelectionTitle: translate(themeSelectionTitle),
                doneLinkLabel: translate(doneLinkLabel),
                changeLinkLabel: translate(changeLinkLabel),
                changeLinkTooltip: translate(changeLinkTooltip),
                myColorsTitle: translate(myColorsTitle),
                myColorsInformationTooltip: translate(myColorsInformationTooltip),
                addColorLabel: translate(addColorLabel),
            },
            colorAdder: {
                hexTabLabel: translate(hexTabLabel),
                rgbTabLabel: translate(rgbTabLabel),
                hsbTabLabel: translate(hsbTabLabel),
                cancelButtonLabel: translate(cancelButtonLabel),
                addButtonLabel: translate(addButtonLabel),
            },
            palettePicker: {
                title: translate(title),
            },
        };
    }

    private handleChangeSiteColorsClick = () => {
        const { palettePickerShown: prevPalettePickerShown } = this.state;
        const { panelHost } = this.getContextProps();
        const { COLOR_PICKER_CHANGE_COLORS_CLICK } = PanelEventList;

        if (panelHost?.onSiteColorChange) {
            panelHost.onSiteColorChange();
            panelHost?.reportBI?.(COLOR_PICKER_CHANGE_COLORS_CLICK);
        } else {
            this.setState({ palettePickerShown: !prevPalettePickerShown, adderShown: false });
            if (!prevPalettePickerShown) {
                panelHost?.reportBI?.(COLOR_PICKER_CHANGE_COLORS_CLICK);
            }
        }
    };
}
