import { DimensionProps, UnitRef, UnitRefPartialRecord } from '@wix/stylable-panel-common';

export enum InputError {
    NONE = 'none',
    EMPTY = 'empty',
    RANGE = 'range',
    INVALID = 'invalid',
    INVALID_UNIT = 'invalid_unit',
    MAX_LENGTH = 'max_length',
}

export enum InputContext {
    ABORT = 'abort',
    FOCUS = 'focus',
    KEY_CHANGE = 'key_change',
    INTERACTIVE_UI = 'interactive',
    EDIT_TIME = 'edit',
}

export enum RangeContext {
    FREE_RANGE_CYCLIC_MAX = 'FREE_RANGE_CYCLIC_MAX',
    FREE_RANGE_CYCLIC_MIN = 'FREE_RANGE_CYCLIC_MIN',
    NO_CYCLIC_RANGE_DIFFER = 'NO_CYCLIC_RANGE_DIFFER',
    NO_RANGE_NO_CYCLIC = 'NO_RANGE_NO_CYCLIC',
    DEFAULT = 'DEFAULT',
}

export enum MODIFIER_KEYS {
    COMMAND = 'command',
    CTRL = 'ctrl',
    UP = 'up',
    DOWN = 'down',
    PLUS = '+',
    EQ = '=',
    ESC = 'esc',
}

export type CssUnit =
    | '' // unitless
    | '%'
    | 'ch'
    | 'cm'
    | 'deg'
    | 'em'
    | 'ex'
    | 'grad'
    | 'in'
    | 'mm'
    | 'ms'
    | 'pc'
    | 'pt'
    | 'px'
    | 'rad'
    | 'rem'
    | 's'
    | 'turn'
    | 'vh'
    | 'vmax'
    | 'vmin'
    | 'vw'
    | 'spx';

export const INPUT_UNITS_MAP: Record<UnitRef, CssUnit> = {
    [UnitRef.CUSTOM]: '',
    [UnitRef.CENTIMETERS]: 'cm',
    [UnitRef.CHARACTER]: 'ch',
    [UnitRef.DEGREES]: 'deg',
    [UnitRef.ELEMENTS]: 'em',
    [UnitRef.GRADIANS]: 'grad',
    [UnitRef.INCHES]: 'in',
    [UnitRef.MILLIMETERS]: 'mm',
    [UnitRef.MILLISECONDS]: 'ms',
    [UnitRef.PERCENTS]: '%',
    [UnitRef.PICAS]: 'pc',
    [UnitRef.PIXELS]: 'px',
    [UnitRef.POINTS]: 'pt',
    [UnitRef.RADIANS]: 'rad',
    [UnitRef.ROOT_ELEMENTS]: 'rem',
    [UnitRef.SECONDS]: 's',
    [UnitRef.TURNS]: 'turn',
    [UnitRef.VIEW_HEIGHT]: 'vh',
    [UnitRef.VIEW_MAX]: 'vmax',
    [UnitRef.VIEW_MIN]: 'vmin',
    [UnitRef.VIEW_WIDTH]: 'vw',
    [UnitRef.X_HEIGHT]: 'ex',
    [UnitRef.SPX]: 'spx'
};

export interface ParsedDimension {
    value: number;
    unit: CssUnit;
    error?: InputError;
}

export interface PreviewProps extends ParsedDimension {
    dimension: string;
}

export interface DimensionInputConfig {
    units?: UnitRefPartialRecord;
    keywords?: string[];
    disableUnitSelector?: boolean;
}

export interface DimensionVisualizerFactoryProps {
    useDisplaySymbol?: boolean;
    trimUnit?: boolean;
    keepRange?: boolean;
    isInputCyclic?: boolean;
    isSliderCyclicKnob?: boolean;
    isRequired?: boolean;
    isSlider?: boolean;
    noFloat?: boolean;
    noValueLengthLimit?: boolean;
    hidePlaceholder?: boolean;
    debug?: boolean;
    inputDataAid?: string;
    isBoundRange?: boolean;
    // responsiveTypography?: boolean; // experimental
    // localeOutput?: boolean; // experimental
}

export interface DimensionVisualizerComponentProps {
    config?: DimensionInputConfig;
    isDisabled?: boolean;
    opacitySliderColor?: string;
    onBlur?: () => void;
    onFocus?: () => void;
    forceFocus?: boolean;
    tooltipContent?: string;
    showUnitErrors?: boolean;
    isDebounced?: boolean;
    debounceWait?: number;
}

export interface DimensionInputProps extends DimensionVisualizerFactoryProps, DimensionVisualizerComponentProps {
    value: string;
    onChange?: (value?: string) => void;
    className?: string;
    translate?: (value: string) => string;
    translationKeys?: Record<string, Record<string, string>>;
}

export interface DimensionInputState {
    editValue: string;
    inputError: InputError;
    inputContext: InputContext;
}

export interface InternalDimensionProps extends DimensionProps {
    symbol?: CssUnit;
}

export interface DimensionRef {
    name: UnitRef;
    symbol: CssUnit;
}
