import type { ExtendedGlobalHost } from '../types';

export enum PanelEventList {
    CLICK_ON_ELEMENT_IN_TREE = 'CLICK_ON_ELEMENT_IN_TREE',
    CLICK_ON_TAB_IN_PANEL = 'CLICK_ON_TAB_IN_PANEL',
    STATE_CLICK = 'STATE_CLICK',
    ADD_LAYER_CLICK = 'ADD_LAYER_CLICK',
    FILL_PICKER_TAB_CLICK = 'FILL_PICKER_TAB_CLICK',
    MOVE_FOCAL_POINT_CLICK = 'MOVE_FOCAL_POINT_CLICK',
    COMPONENT_PART_TOGGLE = 'COMPONENT_PART_TOGGLE',
    TEXT_DROPDOWN_SELECT = 'TEXT_DROPDOWN_SELECT',
    STYLABLE_PANEL_CLOSE = 'STYLABLE_PANEL_CLOSE',
    STYLABLE_SETTINGS_CHANGED = 'STYLABLE_SETTINGS_CHANGED',
    COLOR_PICKER_CHANGE_COLORS_CLICK = 'COLOR_PICKER_CHANGE_COLORS_CLICK',
    PALETTE_SELECT = 'PALETTE_SELECT',
    LAYER_ACTION_CLICK = 'LAYER_ACTION_CLICK',
    COMPONENT_EDITED = 'COMPONENT_EDITED',
}

export interface BIState {
    tab: number;
}

export interface InterntalBIState extends BIState {
    state?: string;
}

export interface BIEvent {
    id: string;
    params?: BIParams;
}

export interface BIParams {
    [prop: string]: string | number | boolean | undefined;
}

export const measureTransaction = (label: string, callback: () => void, globalHost?: ExtendedGlobalHost) =>
    globalHost && globalHost.measureTransaction ? globalHost.measureTransaction(label, callback) : callback();

export const reportBI = (event: PanelEventList, biParams: BIParams, globalHost?: ExtendedGlobalHost) => {
    return globalHost && globalHost.reportBI ? globalHost.reportBI(event, biParams) : () => false;
};
