import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import { OptimisticWrapper } from '../optimistic-wrapper/optimistic-wrapper';
import { classes, style } from './slider.st.css';

const DEFAULT_MIN = 0;
const DEFAULT_MAX = 100;

export interface SliderProps {
    mode?: string; // todo: remove optional
    value?: number;
    min?: number;
    max?: number;
    step?: number;
    knob?: boolean;
    vertical?: boolean;
    disabled?: boolean;
    color?: string;
    onChange?: (value: number) => void;
    className?: string;
    style?: React.CSSProperties;
    throttle?: boolean;
}

export interface SliderState {
    railActive: boolean;
}

export enum SliderModes {
    HORIZONTAL = 'horizontal',
    ROUNDED = 'rounded',
    VERTICAL = 'vertical',
}

const useMountEffect = (cb: () => void) => {
    const willMount = useRef(true);

    if (willMount.current) cb();

    willMount.current = false;
};

export const SliderInner: FC<SliderProps> = ({
    min: min = DEFAULT_MIN,
    max: max = DEFAULT_MAX,
    // throttle = false,
    style: propStyle,
    value: propValue,
    onChange,
    knob,
    step,
    vertical,
    disabled,
    color,
    className,
}) => {
    const [railActive, setRailsActive] = useState<boolean>(false);
    const handle = useRef<HTMLSpanElement>(null);
    const slider = useRef<HTMLSpanElement>(null);

    const snapValue = useCallback(
        (value: number) => {
            if (!step) {
                return value;
            }

            let highSnap = min + step;
            while (highSnap < value) {
                highSnap += step;
            }
            if (highSnap < value) {
                highSnap = value;
            }
            const lowSnap = highSnap - step;
            if (highSnap > max) {
                highSnap = max;
            }

            const distanceToHigh = Math.abs(value - highSnap);
            const distanceToLow = Math.abs(value - lowSnap);

            if (distanceToLow <= distanceToHigh) {
                return lowSnap;
            }
            return highSnap;
        },
        [max, min, step]
    );

    const getProgressLocation = () => {
        const value = propValue !== undefined ? propValue : min;
        const snappedValue = propValue && propValue > max ? max : snapValue(value);

        if (snappedValue <= min || min >= max) {
            return knob ? '0deg' : '0%';
        }
        if (snappedValue >= max) {
            return knob ? '360deg' : '100%';
        }

        const relativeValue = Math.abs(snappedValue - min) / Math.abs(max - min);

        return knob ? relativeValue * 360 + 'deg' : relativeValue * 100 + '%';
    };

    const getRootStyle = () => (knob ? { transform: `rotate(${getProgressLocation()})` } : {});

    const getSliderRect = () => slider.current?.getBoundingClientRect();

    const changeFromDirectionalClick = (event: React.MouseEvent<HTMLSpanElement> | MouseEvent) => {
        const rootRect = getSliderRect();
        if (!onChange || !rootRect) {
            return;
        }

        const clickCoord = vertical ? event.clientY : event.clientX;

        const minCoord = vertical ? rootRect.bottom : rootRect.left;
        const maxCoord = vertical ? rootRect.top : rootRect.right;

        if (vertical) {
            if (clickCoord > minCoord) {
                return onChange(min);
            }
            if (clickCoord < maxCoord) {
                return onChange(max);
            }
        } else {
            if (clickCoord < minCoord) {
                return onChange(min);
            }
            if (clickCoord > maxCoord) {
                return onChange(max);
            }
        }

        const clickDiff = Math.abs(clickCoord - minCoord);
        const rootSpan = Math.abs(max - min);
        const rootSize = vertical ? rootRect.height : rootRect.width;

        onChange(Number(snapValue(min + (clickDiff / rootSize) * rootSpan).toFixed(2)));
    };

    const changeFromKnobClick = (event: React.MouseEvent<HTMLSpanElement> | MouseEvent) => {
        const rootRect = getSliderRect();

        if (!onChange || !rootRect) {
            return;
        }

        const clickXDiff = event.clientX - (rootRect.left + rootRect.width / 2);
        const clickYDiff = rootRect.top + rootRect.height / 2 - event.clientY;

        const diffAngle = 90 - Math.atan(clickYDiff / clickXDiff) * (180 / Math.PI) + (clickXDiff >= 0 ? 0 : 180);
        const rootSpan = Math.abs(max - min);

        const changedVal = Number(snapValue(Math.round(min + (diffAngle / 360) * rootSpan)).toFixed(2));
        onChange(changedVal);
    };

    const changeValue = (event: React.MouseEvent<HTMLSpanElement> | MouseEvent) => {
        knob ? changeFromKnobClick(event) : changeFromDirectionalClick(event);
    };

    const removeEventListeners = () => {
        document.removeEventListener('mousemove', changeValue);
        document.removeEventListener('mouseup', removeEventListeners);
        setRailsActive(false);
    };

    const addEventListeners = () => {
        document.addEventListener('mousemove', changeValue);
        document.addEventListener('mouseup', removeEventListeners);
        setRailsActive(true);
    };

    const handleRailMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        if (!disabled) {
            changeValue(event);
            addEventListeners();
        }
    };

    const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => onChange?.(parseFloat(value));

    const renderHiddenInput = () => {
        return (
            <input
                className={classes.hiddenInput}
                type="range"
                value={propValue}
                min={min}
                max={max}
                onChange={handleChange}
            />
        );
    };

    const getProgressRailStyle = () => {
        return { [vertical ? 'height' : 'width']: getProgressLocation() };
    };

    const getHandleStyle = () => {
        const location = getProgressLocation();
        return knob
            ? {
                  left: '50%',
                  transform: `rotate(-${location})`,
              }
            : {
                  [vertical ? 'bottom' : 'left']: location,
              };
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        if (!disabled) {
            event.stopPropagation();
            addEventListeners();
        }
    };

    useMountEffect(() => () => removeEventListeners());
    return (
        <span
            className={style(
                classes.root,
                {
                    mode: knob ? SliderModes.ROUNDED : vertical ? SliderModes.VERTICAL : SliderModes.HORIZONTAL,
                    active: !!railActive,
                    disabled: !!disabled,
                    gradient: color !== undefined,
                },
                className
            )}
            style={{ ...getRootStyle(), ...propStyle }}
            onMouseDown={handleRailMouseDown}
            ref={slider}
        >
            {renderHiddenInput()}
            <span
                className={style(classes.rail, { active: !!railActive })}
                style={color !== undefined ? colorSliderStyle(color) : undefined}
            >
                <span className={style(classes.axis, { showAxis: !!knob })}></span>
            </span>
            <span className={classes.progressRail} style={getProgressRailStyle()} />
            <span
                className={classes.handle}
                style={getHandleStyle()}
                onMouseDown={handleMouseDown}
                ref={handle}
                data-aid="st_slider_handle"
            >
                <span className={classes.innerHandle}></span>
            </span>
        </span>
    );
};

function colorSliderStyle(color: string) {
    const CHECKERED_GRADIENT =
        'linear-gradient(45deg, #ccc 26%, transparent 26%, transparent 74%, #ccc 74%, #ccc 100%)';
    const CHECKERED_BACKGROUND_SIZE = '8px 8px';

    return {
        background: [
            `linear-gradient(to right, transparent, ${color || 'transparent'})`,
            `${CHECKERED_GRADIENT} -1px 3px / ${CHECKERED_BACKGROUND_SIZE}`,
            `${CHECKERED_GRADIENT} 3px 7px / ${CHECKERED_BACKGROUND_SIZE}`,
            'white',
        ].join(', '),
    };
}

export const Slider = OptimisticWrapper(SliderInner, {});
