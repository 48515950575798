import React from 'react';
import { DropDown } from '@wix/stylable-panel-components';
import type { DeclarationVisualizerProps } from '../../types';
import { style, classes } from './font-family-visualizer.st.css';
import { useFontFamilyVisualizer } from './use-font-family-visualizer';

export const EMPTY_FONT_FAMILY = 'Default';

export interface FontFamilyVisualizerProps extends DeclarationVisualizerProps<FontFamilyProps> {
    disabled?: boolean;
    noCloseOnSelect?: boolean;
}

export type FontFamilyProps = 'font-family';

export const FontFamilyVisualizer: React.FC<FontFamilyVisualizerProps> = (props) => {
    const { className, disabled, noCloseOnSelect } = props;

    const { fontOptions, stringValue, handleSelect, emptyFontFamilyOption } = useFontFamilyVisualizer(props);

    return (
        <DropDown
            className={style(classes.root, className)}
            value={stringValue}
            options={[emptyFontFamilyOption].concat(fontOptions)}
            onSelect={handleSelect}
            disabled={disabled}
            noCloseOnSelect={noCloseOnSelect}
            data-aid="st_font_family_selector"
        />
    );
};
