import {
    CSSCodeAst,
    CSSSeparatorTokens,
    TextNode,
    CommaNode,
    MethodCall,
    ShorthandsTypeMap,
    CompoundsTypeMap,
    OpenedBorderRadiusShorthand,
    createCssValueAST,
    valueTextNode,
    getTokensText,
    colorDataType,
    evaluateAst,
    getShorthandLayers,
    getShorthandOpener as getShorthandOpenerGeneric,
    getShorthandCloser as getShorthandCloserGeneric,
    getCompoundParser as getCompoundParserGeneric,
    UNIVERSAL_KEYWORDS,
    DEFAULT_LAYER_SEPERATOR,
} from '@wix/shorthands-opener';

import {
    DeclarationMap,
    GenericDeclarationMap,
    EvalDeclarationValue,
    DEFAULT_WRAP_SITE_COLOR,
    DEFAULT_EVAL_DECLARATION_VALUE,
} from '@wix/stylable-panel-drivers';

import type {
    DeclarationChangeValue,
    OpenedDeclarationList,
    ControllerVariablesDriver,
    DeclarationVisualizerDrivers,
    DeclarationVisualizerProps,
} from '../types';
import {
    OriginNode,
    ParseShorthandAPI,
    EvaluatedAst,
    OpenedShorthandValue,
    SimpleOpenedShorthand,
    DeclarationValueItem,
    DeclarationValue,
    BaseDeclarationNode,
    DeclarationNode,
    OpenedShortHandDeclarationNode,
    OpenedDeclaration,
    OpenedDeclarationArray,
    isDeclarationExpressionItem,
    isDeclaration,
    isOpenedShortHand,
    isNonCommittedDeclaration,
    isFullExpressionDeclaration,
    getVariableExpression,
    createDeclarationNode,
    createOpenedShorthandDeclarationNode,
    createNonCommittedDeclarationNode,
} from '../declaration-types';

export const getShorthandOpener = <MAIN extends keyof ShorthandsTypeMap>(main: MAIN) =>
    getShorthandOpenerGeneric<OriginNode, MAIN>(main);
export const getShorthandCloser = <MAIN extends keyof ShorthandsTypeMap>(main: MAIN) =>
    getShorthandCloserGeneric<OriginNode, MAIN>(main);
export const getCompoundParser = <MAIN extends keyof CompoundsTypeMap>(main: MAIN) =>
    getCompoundParserGeneric<OriginNode, MAIN>(main);

// TODO: Extract to @wix/shorthands-opener
export const spaceNode = () =>
    ({
        type: 'space',
        value: ' ',
        end: -1,
        start: -1,
    } as CSSSeparatorTokens);

// TODO: Extract to @wix/shorthands-opener
export const valueCommaNode = () =>
    ({
        type: ',',
        text: ',',
        start: -1,
        end: -1,
        before: [],
        after: [],
    } as CommaNode);

// TODO: Extract to @wix/shorthands-opener
const getFullText = (ast: CSSCodeAst, changeText?: string): string => {
    return getTokensText(ast.before) + (changeText ?? ast.text) + getTokensText(ast.after);
};

export const flatMap = <T, U>(array: T[], callback: (value: T) => U[]): U[] =>
    ([] as U[]).concat(...array.map(callback));

export const flattenDeclarationShorthand = (value: OpenedShorthandValue): DeclarationValue =>
    Array.isArray(value) ? flatMap(value, flattenDeclarationShorthand) : [value.origin || value.value];

export const ensureSpace = (val: DeclarationValue) => {
    if (!isDeclarationExpressionItem(val[0]) && val[0].before.length === 0) {
        val[0].before.push(spaceNode());
    }
    return val;
};

export const duplicatingEnsureSpace = (val: DeclarationValue) =>
    !isDeclarationExpressionItem(val[0]) && val[0].before.length === 0
        ? [{ ...val[0], before: [spaceNode()] }, ...val.slice(1)]
        : val;

export const flattenAndEnsureSpace = (value: OpenedShorthandValue) => ensureSpace(flattenDeclarationShorthand(value));

export const createDeclarationValue = (value?: string | DeclarationValue) =>
    value ? (typeof value === 'string' ? [valueTextNode(value)] : value) : [];

export const wrapDeclarationValue = <PROPS extends string>(
    prop: PROPS,
    newValue?: string | DeclarationValue,
    existingValueNode?: OpenedDeclaration<PROPS>
): OpenedDeclaration<PROPS> =>
    existingValueNode
        ? {
              ...existingValueNode,
              value: createDeclarationValue(newValue),
          }
        : createNonCommittedDeclarationNode({
              name: prop,
              value: createDeclarationValue(newValue),
          });

export const getDeclarationValue = <PROPS extends string>(
    declarationList: OpenedDeclarationList<PROPS>,
    prop: PROPS,
    defaultValue?: string
): OpenedDeclarationArray<PROPS> => {
    const value = declarationList[prop] as OpenedDeclarationArray<PROPS>;
    return value.length === 0 && defaultValue !== undefined ? [wrapDeclarationValue(prop, defaultValue)] : value;
};

export function visualizerOptimisticValueResolver<PROPS extends string>(
    targetValue: OpenedDeclarationArray<PROPS> | undefined,
    sourceValue: OpenedDeclarationArray<PROPS>
) {
    if (Array.isArray(sourceValue) && Array.isArray(targetValue)) {
        const sourceValueCopy = [...sourceValue];
        return targetValue
            .reduce((value, targetDecl) => {
                const foundSourceDeclIndex = sourceValueCopy.findIndex(
                    (sourceDecl) => sourceDecl.name === targetDecl.name
                );
                if (foundSourceDeclIndex !== -1) {
                    const foundSourceDecl = sourceValueCopy.splice(foundSourceDeclIndex, 1)[0];
                    if (foundSourceDecl.kind === targetDecl.kind) {
                        value.push(foundSourceDecl);
                    } else {
                        value.push(targetDecl);
                    }
                } else {
                    value.push(targetDecl);
                }
                return value;
            }, [] as OpenedDeclarationArray<PROPS>)
            .concat(sourceValueCopy);
    }
    return sourceValue;
}

export const getDeclarationText = <PROPS extends string>(
    declaration?: OpenedDeclaration<PROPS>,
    stringifyExpression?: (v: OriginNode) => string,
    forceCallText = false
): string | undefined => {
    if (!declaration) {
        return undefined;
    }

    const { value, name } = declaration;
    if (value.length === 0) {
        return undefined;
    }

    try {
        let hasText = false;
        return value
            .map((item) => {
                if (!item) {
                    throw new Error('declaration value item is undefined');
                }

                let text = '';

                if (isDeclarationExpressionItem(item)) {
                    // TODO: Should we throw, return an empty string or use getVariableExpression?
                    if (!stringifyExpression) {
                        throw new Error('stringifyExpression is undefined');
                    }
                    text = (hasText ? ' ' : '') + stringifyExpression(item);
                } else {
                    if (item.type !== 'call' || (!forceCallText && !item.args.some(isDeclarationExpressionItem))) {
                        text = getFullText(item);
                    } else {
                        const argsText =
                            getDeclarationText(
                                createNonCommittedDeclarationNode({ name, value: item.args }),
                                stringifyExpression,
                                forceCallText
                            ) ?? '';
                        text = getFullText(item, `${item.name}(${argsText})`);
                    }
                }

                if (!hasText && text !== '') {
                    hasText = true;
                }
                return text;
            })
            .join('');
    } catch (e) {
        console.warn(e);
        return undefined;
    }
};

export const getAstNodeText = (node: CSSCodeAst, stringifyExpression?: (v: OriginNode) => string) =>
    getDeclarationText(createNonCommittedDeclarationNode({ name: '', value: [node] }), stringifyExpression)?.trim();

export const evalOpenedDeclarationValue = (
    value: DeclarationValue,
    evalDeclarationValue: EvalDeclarationValue = DEFAULT_EVAL_DECLARATION_VALUE,
    stringifyExpression?: (v: OriginNode) => string
): DeclarationValue =>
    value.map((item) => {
        const node = item as CSSCodeAst;
        switch (node.type) {
            case 'text':
                return {
                    ...node,
                    text: evalDeclarationValue(node.text),
                } as TextNode;
            case 'call':
                return node.name !== 'value'
                    ? ({
                          ...node,
                          text: evalDeclarationValue(getAstNodeText(node, stringifyExpression)),
                          args: evalOpenedDeclarationValue(node.args),
                      } as MethodCall)
                    : // Convert Stylable variable usages to text nodes
                      ({
                          type: 'text',
                          start: node.start,
                          end: node.end,
                          before: node.before,
                          after: node.after,
                          text: evalDeclarationValue(node.text),
                      } as TextNode);
        }
        return node;
    });

export const wrapOpenedDeclarationsSiteColors = <PROPS extends string>(
    value: OpenedDeclarationArray<PROPS>,
    wrapSiteColor: (value: string) => string = DEFAULT_WRAP_SITE_COLOR
): OpenedDeclarationArray<PROPS> => {
    const wrapDeclarationValueSiteColors = (value: DeclarationValue): DeclarationValue => {
        return value.map((item) => {
            if (!isDeclarationExpressionItem(item)) {
                if (item.type === 'call') {
                    const wrappedArgs = wrapDeclarationValueSiteColors(item.args) as CSSCodeAst[];
                    let wrappedText = '';
                    try {
                        wrappedText =
                            getDeclarationText(
                                createNonCommittedDeclarationNode({
                                    name: '',
                                    value: [{ ...item, args: wrappedArgs }],
                                }),
                                undefined,
                                true
                            )?.trim() ?? '';
                    } catch (e) {
                        console.warn(e);
                    }
                    return {
                        ...item,
                        text: wrappedText,
                        args: wrappedArgs,
                    };
                }
                if (colorDataType.predicate(item)) {
                    return { ...item, text: wrapSiteColor(item.text) };
                }
                return item;
            }
            return item;
        });
    };
    return value.map((decl) => ({ ...decl, value: wrapDeclarationValueSiteColors(decl.value) }));
};

export const getDeclarationChangeTextValue = (
    value: DeclarationChangeValue,
    stringifyExpression = getVariableExpression
) =>
    value === null || typeof value === 'string'
        ? value
        : value.length > 0
        ? value.map((decl) => getDeclarationText(decl, stringifyExpression) ?? '').join('') || null
        : null;

export const getOpenedDeclarationList = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    main: MAIN | MAIN[],
    shorthandProps: PROPS[],
    props: DeclarationVisualizerProps<PROPS>
): OpenedDeclarationList<PROPS> => {
    const {
        value,
        drivers: { variables },
    } = props;

    return openDeclarationList(main, shorthandProps, createShorthandOpenerApi(variables), value);
};

export const getPropValueDeclaration = <PROPS extends string>(value: OpenedDeclarationArray<PROPS>, prop: PROPS) =>
    [...value].reverse().find((node) => node.name === prop);

export const isFullExpressionVisualizer = <PROPS extends string>(
    main: PROPS,
    props: DeclarationVisualizerProps<PROPS>
) => {
    const mainDeclaration = getPropValueDeclaration(props.value, main);
    return isDeclaration(mainDeclaration) && isFullExpressionDeclaration(mainDeclaration);
};

export const hasShorthandOverride = <MAIN extends string, PROPS extends string>(
    value: OpenedDeclarationArray<MAIN | PROPS>,
    mainProp: MAIN,
    childPropName: PROPS
) => value.findIndex((d) => d.name === mainProp) > value.findIndex((d) => d.name === childPropName);

export const getLatestOpenedNode = <PROPS extends string>(
    prop: PROPS,
    openedDeclarationList: OpenedDeclarationList<PROPS>
): DeclarationValueItem | undefined => {
    const declarations = openedDeclarationList[prop];
    const values = declarations && declarations[declarations.length - 1]?.value;
    return values && values[values.length - 1];
};

export const createDeclarationMapFromVisualizerValue = <PROPS extends string>(
    value: OpenedDeclarationArray<PROPS>,
    props?: DeclarationVisualizerProps<PROPS>,
    stringifyExpression = props?.drivers.variables.getVariableValue
): GenericDeclarationMap<PROPS> =>
    value.reduce((declarationMap, node) => {
        declarationMap[node.name] = getDeclarationText(node, stringifyExpression)?.trim();
        return declarationMap;
    }, {} as GenericDeclarationMap<PROPS>);

export const getTextFromSinglePropVisualizer = <PROP extends string>(
    prop: PROP,
    props: DeclarationVisualizerProps<PROP>
): string | undefined => {
    const {
        value,
        drivers: { variables },
    } = props;
    const lastValue = getPropValueDeclaration(value, prop);
    let textValue = getDeclarationText(lastValue, variables.getVariableValue);
    if (!textValue) {
        return undefined;
    }

    if (textValue.includes(' ') && isOpenedShortHand(lastValue) && lastValue.value.some(isDeclarationExpressionItem)) {
        const original = lastValue.originalDecl as DeclarationNode<keyof ShorthandsTypeMap>;
        const originalText = getDeclarationText(original, variables.getVariableValue);
        const openedValue = openDeclarationList(original.name, [prop], createShorthandOpenerApi(variables), [
            createDeclarationNode({
                ...original,
                value: originalText ? createCssValueAST(originalText) : [],
            }),
        ])[prop];
        textValue = getDeclarationText(openedValue[openedValue.length - 1]);
    }

    return textValue?.trim();
};

export const getShorthandControllerValue = <PROPS extends string>(
    declarationList: OpenedDeclarationList<PROPS>,
    props: DeclarationVisualizerProps<PROPS>,
    stringifyExpression?: (v: OriginNode) => string
): GenericDeclarationMap<PROPS> =>
    (Object.keys(declarationList) as PROPS[]).reduce((value, prop) => {
        return {
            ...value,
            ...createDeclarationMapFromVisualizerValue(declarationList[prop], props, stringifyExpression),
        };
    }, {} as GenericDeclarationMap<PROPS>);

export const wrapExistingDeclaration = <PROPS extends string>(
    prop: PROPS,
    newValue: string | DeclarationValue | undefined,
    props: DeclarationVisualizerProps<PROPS>,
    mainList?: string[],
    declarationList?: OpenedDeclarationList<PROPS>
) =>
    wrapDeclarationValue(
        prop,
        newValue,
        getPropValueDeclaration(
            !declarationList || mainList?.includes(prop) ? props.value : declarationList[prop],
            prop
        )
    );

export const controllerToVisualizerChange = <PROPS extends string>(
    controllerValue: GenericDeclarationMap<PROPS>,
    props: DeclarationVisualizerProps<PROPS>,
    main?: string | string[],
    declarationList?: OpenedDeclarationList<PROPS>
): OpenedDeclarationArray<PROPS> => {
    const mainList = main ? (Array.isArray(main) ? main : [main]) : undefined;
    return (Object.keys(controllerValue) as PROPS[]).map((prop) =>
        wrapExistingDeclaration(prop, controllerValue[prop], props, mainList, declarationList)
    );
};

export const wrapChangeWithLonghandsClear = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    main: MAIN | MAIN[],
    changeValue: OpenedDeclarationArray<MAIN | PROPS>,
    propList: Array<MAIN | PROPS>,
    value: OpenedDeclarationArray<MAIN | PROPS>,
    clearLonghands = false
): OpenedDeclarationArray<MAIN | PROPS> => {
    const mainList = Array.isArray(main) ? main : [main];
    const visualizerChange = [...changeValue];

    if (clearLonghands && changeValue.length === 1 && mainList.includes(changeValue[0].name as MAIN)) {
        const mainProp = changeValue[0].name as MAIN;
        const shorthandProps = propList.concat(mainProp);
        let foundShorthand = isNonCommittedDeclaration(changeValue[0]) ? true : false;
        for (let i = value.length - 1; i >= 0; i--) {
            const currNode = value[i];
            if (!foundShorthand) {
                if (currNode.name === mainProp) {
                    foundShorthand = true;
                }
                continue;
            }
            if (shorthandProps.includes(currNode.name) && !isNonCommittedDeclaration(currNode)) {
                const valueToPush = wrapDeclarationValue(currNode.name, undefined, currNode);
                visualizerChange.push(valueToPush);
            }
        }
    }

    return visualizerChange;
};

export const getSimpleShorthandDeclarationsChange = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    main: MAIN | MAIN[],
    changeValue: OpenedDeclarationArray<MAIN | PROPS>,
    declarationList: OpenedDeclarationList<MAIN | PROPS>,
    props: DeclarationVisualizerProps<MAIN | PROPS>,
    clearLonghands = false
): OpenedDeclarationArray<MAIN | PROPS> =>
    getSimpleChildChanges<MAIN, PROPS>(
        wrapChangeWithLonghandsClear<MAIN, PROPS>(
            main,
            changeValue,
            Object.keys(declarationList) as Array<MAIN | PROPS>,
            props.value,
            clearLonghands
        ),
        createShorthandOpenerApi(props.drivers.variables)
    );

export const getSimpleShorthandChange = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    main: MAIN | MAIN[],
    changeValue: GenericDeclarationMap<MAIN | PROPS>,
    declarationList: OpenedDeclarationList<MAIN | PROPS>,
    props: DeclarationVisualizerProps<MAIN | PROPS>,
    clearLonghands = false
): OpenedDeclarationArray<MAIN | PROPS> =>
    getSimpleShorthandDeclarationsChange<MAIN, PROPS>(
        main,
        controllerToVisualizerChange<MAIN | PROPS>(changeValue, props, main, declarationList),
        declarationList,
        props,
        clearLonghands
    );

/**
 * @deprecated Replace with getSimpleShorthandChange
 */
export const getShorthandChange = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    main: MAIN | MAIN[],
    changeValue: GenericDeclarationMap<MAIN | PROPS>,
    declarationList: OpenedDeclarationList<MAIN | PROPS>,
    props: DeclarationVisualizerProps<MAIN | PROPS>,
    clearLonghands = false
): OpenedDeclarationArray<MAIN | PROPS> =>
    getSimpleShorthandChange<MAIN, PROPS>(main, changeValue, declarationList, props, clearLonghands);

export const createVisualizerValueFromDeclarationMap = <PROPS extends string = string>(
    value: DeclarationMap,
    astValue = false
): OpenedDeclarationArray<PROPS> =>
    Object.keys(value).reduce((visualizerValue, prop) => {
        const newValue = value[prop];
        visualizerValue.push({
            name: prop,
            value: newValue ? (astValue ? createCssValueAST(newValue) : [valueTextNode(newValue)]) : [],
        } as DeclarationNode<PROPS>);
        return visualizerValue;
    }, [] as OpenedDeclarationArray<PROPS>);

export const createDeclarationVisualizerDrivers = (): DeclarationVisualizerDrivers => ({
    variables: {
        getVariableAstValue: () => [valueTextNode('')],
        getVariableValue: () => '',
    },
});

export const createShorthandOpenerApi = ({ getVariableAstValue }: ControllerVariablesDriver) =>
    ({
        isExpression: isDeclarationExpressionItem,
        getValue: getVariableAstValue,
        toString: getVariableExpression,
    } as ParseShorthandAPI);

export const getSimpleOpenedShorthand = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    main: MAIN,
    shorthandValue: DeclarationValue,
    shorthandApi: ParseShorthandAPI
): SimpleOpenedShorthand<PROPS> => {
    if (main === 'background') {
        throw new Error('background prop unsupported');
    }

    const opened = getShorthandOpener(main)(shorthandValue, shorthandApi);

    if (main === 'border-radius') {
        const openedBorderRadius = opened as OpenedBorderRadiusShorthand<OriginNode>;
        if (openedBorderRadius.length > 1) {
            throw new Error('border-radius prop with multiple corners is unsupported');
        }
        return openedBorderRadius[0] as unknown as SimpleOpenedShorthand<PROPS>;
    }

    return opened as unknown as SimpleOpenedShorthand<PROPS>;
};

export const closeSimpleShorthand = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    main: MAIN,
    opened: SimpleOpenedShorthand<PROPS>,
    shorthandApi: ParseShorthandAPI
) => {
    if (main === 'background') {
        throw new Error('background prop unsupported');
    }

    return getShorthandCloser(main)(
        (main === 'border-radius' ? [opened] : opened) as unknown as SimpleOpenedShorthand<ShorthandsTypeMap[MAIN]>,
        shorthandApi
    );
};

const declarationValueIsValidWithExpressions = (value: DeclarationValue): boolean =>
    value.length > 0
        ? value.some(
              (item) =>
                  isDeclarationExpressionItem(item) ||
                  (item.type === 'call' &&
                      (item.args.length === 0 || declarationValueIsValidWithExpressions(item.args)))
          )
        : true;

export const sanitizeOpenedDeclaration = <PROPS extends string>(
    declaration: OpenedDeclaration<PROPS>,
    textModifier: (value: string) => string = (value) => value
) => {
    const { name, value } = declaration;

    if (!declarationValueIsValidWithExpressions(value)) {
        return {
            ...declaration,
            value: createCssValueAST(
                textModifier(
                    getDeclarationText({
                        name,
                        value,
                    } as OpenedDeclaration<PROPS>) || ''
                )
            ),
        } as OpenedDeclaration<PROPS>;
    }

    return declaration;
};

// TODO: Extract to @wix/shorthands-opener? (with values: OpenedDeclarationArray<PROPS>)
export const joinDeclarationValues = (values: DeclarationValue[], seperator: () => DeclarationValue) => {
    const joinedLayers = values.reduce(
        (joinedLayers, value) => joinedLayers.concat(value).concat(seperator()),
        [] as DeclarationValue
    );
    joinedLayers.pop();
    return joinedLayers;
};

// TODO: Extract to @wix/shorthands-opener partially
export const splitShorthandLayers = <PROPS extends string>(
    declaration: OpenedDeclaration<PROPS>,
    shorthandApi: ParseShorthandAPI,
    seperator = DEFAULT_LAYER_SEPERATOR
): OpenedDeclarationArray<PROPS> =>
    getShorthandLayers(evaluateAst(declaration.value, shorthandApi), seperator).map((layerValue) => ({
        ...declaration,
        value: flattenDeclarationShorthand(layerValue),
    }));

const getSimpleOpenedShorthandNodes = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    originalDecl: DeclarationNode<MAIN | PROPS>,
    shorthandValue: DeclarationValue,
    shorthandApi: ParseShorthandAPI
) => {
    const main = originalDecl.name as MAIN;
    const opened = getSimpleOpenedShorthand<MAIN, PROPS>(main, shorthandValue, shorthandApi);

    return (Object.keys(opened) as PROPS[]).reduce((openedNodes, prop) => {
        openedNodes[prop] = createOpenedShorthandDeclarationNode({
            name: prop,
            value: flattenDeclarationShorthand(opened[prop]),
            originalDecl,
            important: originalDecl.important,
        });
        return openedNodes;
    }, {} as Record<PROPS, OpenedShortHandDeclarationNode<PROPS>>);
};

export const openDeclarationList = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    main: MAIN | MAIN[],
    props: PROPS[],
    shorthandApi: ParseShorthandAPI,
    declarations: OpenedDeclarationArray<MAIN | PROPS>
): OpenedDeclarationList<PROPS> => {
    const mainList = Array.isArray(main) ? main : [main];
    return declarations.reduce(
        (acc, current) => {
            const prop = current.name;
            const sanitizedCurrent = sanitizeOpenedDeclaration(current);
            if (!mainList.includes(prop as MAIN)) {
                if (!props.includes(prop as PROPS)) {
                    return acc;
                }
                acc[prop as PROPS] = acc[prop as PROPS] || [];
                acc[prop as PROPS].push(sanitizedCurrent as OpenedDeclaration<PROPS>);
            } else if (current.value.length > 0 && isDeclaration(current)) {
                try {
                    const openedNodes = getSimpleOpenedShorthandNodes<MAIN, PROPS>(
                        current,
                        sanitizedCurrent.value,
                        shorthandApi
                    );
                    for (const prop of Object.keys(openedNodes) as PROPS[]) {
                        acc[prop] = acc[prop] || [];
                        acc[prop].push(openedNodes[prop]);
                    }
                } catch (e) {
                    console.warn(e);
                }
            }
            return acc;
        },
        props.reduce((acc, prop) => {
            acc[prop] = [];
            return acc;
        }, {} as OpenedDeclarationList<PROPS>)
    );
};

// Fix @wix/shorthands-opener no space before last expression bug
// TODO: Extract to @wix/shorthands-opener
const fixLastExpressionSpace = (value: DeclarationValue): DeclarationValue => {
    const newValue = [...value];
    if (isDeclarationExpressionItem(newValue[newValue.length - 1])) {
        newValue.reverse();
        const lastNonExpressionIndex = newValue.findIndex((item) => !isDeclarationExpressionItem(item));
        if (lastNonExpressionIndex !== -1) {
            (newValue[lastNonExpressionIndex] as CSSCodeAst).after = [spaceNode()];
        }
        newValue.reverse();
    }
    return newValue;
};

export const applyOpenedShorthandChangeTemplate = <
    MAIN extends keyof ShorthandsTypeMap,
    PROPS extends string,
    SHORTHAND = SimpleOpenedShorthand<PROPS>
>(
    changeDecls: OpenedShortHandDeclarationNode<MAIN | PROPS>[],
    shorthandApi: ParseShorthandAPI,
    openShorthand: (decl: OpenedDeclaration<MAIN | PROPS>, shorthandApi: ParseShorthandAPI) => SHORTHAND,
    changeShorthand: (opened: SHORTHAND, prop: PROPS, newValueAst: EvaluatedAst) => void,
    closeShorthand: (opened: SHORTHAND, shorthandApi: ParseShorthandAPI) => DeclarationValue
): OpenedDeclarationArray<MAIN | PROPS> => {
    const originalDecl = changeDecls[0].originalDecl as OpenedDeclaration<MAIN | PROPS>;

    if (changeDecls.some((decl) => decl.value.length === 0)) {
        return [originalDecl];
    }

    if (
        originalDecl.value.length === 1 &&
        !isDeclarationExpressionItem(originalDecl.value[0]) &&
        UNIVERSAL_KEYWORDS.has(getFullText(originalDecl.value[0]))
    ) {
        return changeDecls.map(({ name, value, important }) =>
            createNonCommittedDeclarationNode({ name, value, important })
        );
    }

    const opened = openShorthand(originalDecl, shorthandApi);

    changeDecls.forEach((decl) => {
        const newValue = decl.value.map((value) => ({ value } as EvaluatedAst));
        changeShorthand(opened, decl.name as PROPS, (newValue.length === 1 ? newValue[0] : newValue) as EvaluatedAst);
    });

    const closedValue = fixLastExpressionSpace(closeShorthand(opened, shorthandApi));

    return [
        {
            ...originalDecl,
            value: closedValue,
        },
    ];
};

const applySimpleOpenedShorthandChange = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    changeDecl: OpenedShortHandDeclarationNode<MAIN | PROPS>,
    shorthandApi: ParseShorthandAPI
) => {
    const main = changeDecl.originalDecl.name as MAIN;
    return applyOpenedShorthandChangeTemplate<MAIN, PROPS>(
        [changeDecl],
        shorthandApi,
        (decl) => getSimpleOpenedShorthand<MAIN, PROPS>(main, decl.value, shorthandApi),
        (opened, prop, newValueAst) => {
            opened[prop] = newValueAst;
        },
        (opened) => closeSimpleShorthand(main, opened, shorthandApi)
    )[0];
};

const getShorthandGroups = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    declarations: OpenedDeclarationArray<PROPS | MAIN>
): OpenedDeclarationArray<PROPS | MAIN>[] => {
    if (
        declarations.every(
            (decl) =>
                decl.value.length > 0 &&
                isOpenedShortHand(decl) &&
                decl.originalDecl === (declarations[0] as OpenedShortHandDeclarationNode<MAIN | PROPS>).originalDecl
        )
    ) {
        return [declarations];
    }
    // TODO: Expand to actually split into groups
    return declarations.map((decl) => [decl]);
};

export const getChildChangesTemplate = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    declarations: OpenedDeclarationArray<PROPS | MAIN>,
    shorthandApi: ParseShorthandAPI,
    processOpenedShorthand: (
        decls: OpenedShortHandDeclarationNode<MAIN | PROPS>[],
        shorthandApi: ParseShorthandAPI
    ) => OpenedDeclarationArray<MAIN | PROPS> = (decls) => decls
): OpenedDeclarationArray<PROPS | MAIN> =>
    getShorthandGroups<MAIN, PROPS>(declarations).reduce((acc, group) => {
        if (group[0].value.length > 0 && isOpenedShortHand(group[0])) {
            const shorthandGroup = group as OpenedShortHandDeclarationNode<MAIN | PROPS>[];
            acc = acc.concat(processOpenedShorthand(shorthandGroup, shorthandApi));
        } else {
            acc.push(group[0]);
        }
        return acc;
    }, [] as OpenedDeclarationArray<PROPS | MAIN>);

export const getSimpleChildChanges = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    declarations: OpenedDeclarationArray<PROPS | MAIN>,
    shorthandApi: ParseShorthandAPI
) =>
    getChildChangesTemplate<MAIN, PROPS>(declarations, shorthandApi, (decls) =>
        decls.map((decl) => applySimpleOpenedShorthandChange<MAIN, PROPS>(decl, shorthandApi))
    );

/**
 * @deprecated Replace with getSimpleChildChanges
 */
export const getChildChanges = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    declarations: OpenedDeclarationArray<PROPS | MAIN>,
    shorthandApi: ParseShorthandAPI
): OpenedDeclarationArray<PROPS | MAIN> => getSimpleChildChanges<MAIN, PROPS>(declarations, shorthandApi);

export const compareVisualizerValues = <PROPS extends string>(
    value1: OpenedDeclarationArray<PROPS>,
    value2: OpenedDeclarationArray<PROPS>,
    {
        drivers: {
            variables: { getVariableValue },
        },
    }: DeclarationVisualizerProps<PROPS>
): boolean => {
    if (value1.length !== value2.length) {
        return true;
    }

    for (let i = 0; i < value1.length; i++) {
        if ((value1[i] as BaseDeclarationNode).name !== (value2[i] as BaseDeclarationNode).name) {
            return true;
        }

        if (getDeclarationText(value1[i], getVariableValue) !== getDeclarationText(value2[i], getVariableValue)) {
            return true;
        }
    }

    return false;
};
