import React from 'react';
import { classes, style } from './toggle-switch.st.css';

export interface ToggleSwitchProps {
    onChange: (value: boolean) => void;
    value: boolean;
    className?: string;
    disabled?: boolean;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ className, disabled, onChange, value: toggled }) => (
    <span
        className={style(
            classes.root,
            {
                toggled,
                disabled,
            },
            className
        )}
        onClick={() => !disabled && onChange(!toggled)}
    >
        <div className={classes.knob} />
    </span>
);
