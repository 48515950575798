import React from 'react';

import type { DeclarationMap, StylableSiteVars, StylesheetDriver } from '@wix/stylable-panel-drivers';
import type { ControllerData, StylePanelPlane } from '@wix/stylable-panel-common';
import {
    ControllerComponentClass,
    DeclarationVisualizerDrivers,
    ExtendedGlobalHost,
    VisualizerComponentClass,
    VisualizerType,
    createDeclarationMapFromVisualizerValue,
    createVisualizerValueFromDeclarationMap,
} from '@wix/stylable-panel-controllers';

import type { BlockVariantProps, DynamicPartControllerProps } from './controllers/types';

export interface RenderedVisualizerProps {
    key: string;
    Visualizer: VisualizerType;
    value: DeclarationMap;
    onControllerChange: (values: DeclarationMap, revertible?: boolean) => void;
    visualizerDrivers: DeclarationVisualizerDrivers;
    sheetDriver: StylesheetDriver;
    siteVarsDriver: StylableSiteVars;
    selectorState?: string;
    controllerData?: ControllerData;
    panelHost?: ExtendedGlobalHost;
    plane: StylePanelPlane;
    revertRule: () => void;
    dynamicPartControllerProps: Partial<DynamicPartControllerProps>;
    blockVariantProps?: BlockVariantProps; // TODO deprecate
    isVisualizer?: boolean;
    className?: string;
}

/*
This is wrapper which can render either a controller or a visualizer. This needs to work as long as we support controllers
 */
export const RenderedVisualizer = ({
    isVisualizer,
    Visualizer,
    key,
    sheetDriver,
    siteVarsDriver,
    value,
    selectorState,
    controllerData,
    plane,
    panelHost,
    onControllerChange,
    revertRule,
    dynamicPartControllerProps,
    blockVariantProps,
    visualizerDrivers,
    className,
}: RenderedVisualizerProps): JSX.Element => {
    if (isVisualizer === false || Visualizer.IS_CONTROLLER) {
        // Render controller if needed
        const ControllerComponent = Visualizer as ControllerComponentClass;

        // TODO Deprecate the use of this. We don't want controllers to be configured this way. We leave this here in order to support current LayoutController
        const explicitDynamicPartControllerProps = {
            selectorValues: dynamicPartControllerProps.selectorValues,
            onChangeSelector: dynamicPartControllerProps.onChangeSelector,
        };

        // TODO can remove together with all block variants:
        const explicitBlockVariantProps = blockVariantProps
            ? {
                  variantSets: blockVariantProps.variantSets,
                  userVariantSets: blockVariantProps.userVariantSets,
                  saveBlockVariant: blockVariantProps.saveBlockVariant,
                  deleteBlockVariant: blockVariantProps.deleteBlockVariant,
              }
            : {};

        return (
            <ControllerComponent
                className={className}
                key={key}
                sheetDriver={sheetDriver}
                siteVarsDriver={siteVarsDriver}
                value={value}
                selectorState={selectorState}
                controllerData={controllerData}
                plane={plane}
                panelHost={panelHost}
                onChange={onControllerChange}
                onRevert={revertRule}
                {...explicitDynamicPartControllerProps}
                {...explicitBlockVariantProps}
            />
        );
    }
    const VisualizerComponent = Visualizer as VisualizerComponentClass;
    return (
        <VisualizerComponent
            className={className}
            key={key}
            siteVarsDriver={siteVarsDriver}
            value={createVisualizerValueFromDeclarationMap(value, VisualizerComponent.AST_VALUE)}
            selectorState={selectorState} // TODO Can remove once we pass 'disabled' to IconVisualizer and TextVisualizer see https://jira.wixpress.com/browse/STYL-1021
            controllerData={controllerData}
            plane={plane}
            panelHost={panelHost}
            drivers={visualizerDrivers}
            onChange={(declaration) => onControllerChange(createDeclarationMapFromVisualizerValue(declaration))}
        />
    );
};
