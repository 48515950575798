import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import type { SingleCategoryConfig } from '../../../../types';

export const cornerBlockVariants: SingleCategoryConfig = {
    titleKey: StylablePanelTranslationKeys.controller.corners.title,
    blockVariantSet: [
        { 'border-radius': '0px' },
        { 'border-radius': '10px' },
        { 'border-radius': '5px' },
        { 'border-radius': '100px' },
        { 'border-radius': '10px 0' },
        { 'border-radius': '10px 20px 30px 40px' },
        { 'border-radius': '40px 30px 20px 10px' },
        { 'border-radius': '0 10px 10px 10px' },
        { 'border-radius': '10px 0 10px 10px' },
        { 'border-radius': '10px 10px 0 10px' },
        { 'border-radius': '10px 10px 10px 0' },
        { 'border-radius': '200% 0 0 0' },
        { 'border-radius': '0 200% 0 0' },
        { 'border-radius': '0 0 200% 0' },
        { 'border-radius': '0 0 0 200%' },
        // {'border-radius': '255px 15px 225px 15px/15px 225px 15px 255px'},
    ],
    props: [
        /border-.*(radius)/, // borders with radius
    ],
};

export default cornerBlockVariants;
