import {
    Token,
    Descriptors,
    tokenize,
    isStringDelimiter,
    isWhitespace,
    getJSCommentStartType,
    getMultilineCommentStartType,
    isCommentEnd,
    createToken,
    getText,
    getUnclosedComment,
} from '@tokey/core';

export type { Descriptors };
export type Delimiters = '(' | ')' | ',' | '/';
export type SeparatorTokens = 'line-comment' | 'multi-comment' | 'space';
export type CSSValueCodeToken = Token<Descriptors | Delimiters>;
export type CSSSeparatorTokens = Token<SeparatorTokens>;
export type CSSCodeAst = StringNode | MethodCall | TextNode | CommaNode | SlashNode;
export interface ASTNode<Types = Descriptors> {
    type: Types;
    text: string;
    start: number;
    end: number;
    before: CSSSeparatorTokens[];
    after: CSSSeparatorTokens[];
}

export interface MethodCall extends ASTNode<'call'> {
    name: string;
    args: CSSCodeAst[];
}
/* eslint-disable @typescript-eslint/no-empty-interface */
export interface StringNode extends ASTNode<'string'> {}
export interface TextNode extends ASTNode<'text'> {}
export interface CommaNode extends ASTNode<','> {}
export interface SlashNode extends ASTNode<'/'> {}

export const DEFAULT_POSITION = -1;
export const URL_CALL_TOKEN = 'url';

export const isSeparatorToken = (token: CSSValueCodeToken): token is CSSSeparatorTokens => {
    const { type } = token;
    return type === 'line-comment' || type === 'multi-comment' || type === 'space';
};

export const isCSSCodeAst = (ast: unknown): ast is CSSCodeAst => {
    const node = ast as CSSCodeAst;
    return (
        node.text !== undefined &&
        node.type !== undefined &&
        (node.type === 'string' ||
            node.type === 'call' ||
            node.type === 'text' ||
            node.type === ',' ||
            node.type === '/')
    );
};

export function createCssValueAST(source: string, parseLineComments = false): CSSCodeAst[] {
    return parseDeclValueTokens(
        source,
        tokenize<CSSValueCodeToken>(source, {
            isDelimiter,
            isStringDelimiter,
            isWhitespace,
            shouldAddToken,
            createToken,
            getCommentStartType: parseLineComments ? getJSCommentStartType : getMultilineCommentStartType,
            isCommentEnd,
            getUnclosedComment,
        })
    ).ast;
}

const isDelimiter = (char: string) => char === '(' || char === ')' || char === ',' || char === '/';

const shouldAddToken = () => true;

function parseDeclValueTokens(
    source: string,
    tokens: CSSValueCodeToken[],
    startAtIdx = 0
): { ast: CSSCodeAst[]; stoppedAtIdx: number } {
    const ast: CSSCodeAst[] = [];
    let before: CSSSeparatorTokens[] = [];
    for (let i = startAtIdx; i < tokens.length; i++) {
        const token = tokens[i];
        if (token.type === ')') {
            const lastAst = ast[ast.length - 1];
            if (lastAst && before.length) {
                lastAst.after = before;
                before = [];
            }
            return {
                ast,
                stoppedAtIdx: i,
            };
        } else if (isSeparatorToken(token)) {
            before.push(token);
        } else if (token.type === 'text' && tokens[i + 1]?.type === '(') {
            const res = parseDeclValueTokens(source, tokens, i + 2);
            const methodText = getText(tokens, i, res.stoppedAtIdx + 1, source);
            i = res.stoppedAtIdx;
            res.ast = getUrlTokensAst(token, res.ast);
            ast.push({
                type: 'call',
                text: methodText,
                start: token.start,
                end: token.start + methodText.length,
                before,
                after: [],
                name: token.value,
                args: res.ast,
            });
            before = [];
        } else {
            ast.push({
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                type: token.type as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                text: token.value,
                start: token.start,
                end: token.end,
                before,
                after: [],
            });
            before = [];
        }
    }

    return {
        ast,
        stoppedAtIdx: tokens.length,
    };
}

function getUrlTokensAst(token: CSSValueCodeToken, ast: CSSCodeAst[]): CSSCodeAst[] {
    if (token.value !== URL_CALL_TOKEN || ast.length === 0) {
        return ast;
    }

    const pushFixedTextNodeToFixedAst = () => {
        if (fixedTextNode) {
            fixedTextNode.end = fixedTextNode.start + fixedTextNode.text.length;
            fixedAst.push(fixedTextNode);
            fixedTextNode = null;
        }
    };

    let fixedTextNode: TextNode | null = null;
    const fixedAst: CSSCodeAst[] = [];
    for (const node of ast) {
        if (node.type === '/' || node.type === 'text') {
            if (!fixedTextNode) {
                fixedTextNode = {
                    type: 'text',
                    text: '',
                    before: node.before,
                    after: [],
                    start: node.start,
                    end: DEFAULT_POSITION,
                };
            }
            fixedTextNode.text += node.text;
        } else {
            pushFixedTextNodeToFixedAst();
            fixedAst.push(node);
        }
    }

    pushFixedTextNodeToFixedAst();

    return fixedAst;
}

export interface NodePositions {
    beforeStart: number;
    beforeEnd: number;
    start: number;
    end: number;
}

export const getNodePositions = (text: string, pos?: number, spaceBefore?: string): NodePositions => ({
    beforeStart: !spaceBefore || pos === undefined ? DEFAULT_POSITION : pos - spaceBefore.length,
    beforeEnd: !spaceBefore || pos === undefined ? DEFAULT_POSITION : pos,
    start: pos === undefined ? DEFAULT_POSITION : pos,
    end: pos === undefined ? DEFAULT_POSITION : pos + text.length,
});

export const valueTextNode = (text: string, pos?: number, spaceBefore?: string): TextNode => {
    const { start, end, beforeEnd, beforeStart } = getNodePositions(text, pos, spaceBefore);
    return {
        type: 'text',
        start,
        end,
        before: spaceBefore
            ? [
                  {
                      type: 'space',
                      start: beforeStart,
                      end: beforeEnd,
                      value: spaceBefore,
                  },
              ]
            : [],
        after: [],
        text,
    };
};

export const getTokensText = (tokens: CSSSeparatorTokens[]): string => {
    return tokens.map((token) => token.value).join('');
};

export const getFullText = (ast: CSSCodeAst): string => {
    return getTokensText(ast.before) + ast.text + getTokensText(ast.after);
};
