import React from 'react';

import type { BoxShadowMap, Dimension } from '@wix/shorthands-opener';

import {
    DEGREES_RANGE,
    DIMENSION_ID,
    getDimensionInputDefaults,
    SINGLE_SHADOW_BLUR,
    SINGLE_SHADOW_DISTANCE,
    SINGLE_SHADOW_SPREAD,
} from '@wix/stylable-panel-common';
import {
    BackgroundBox,
    CompositeBlock,
    DimensionInput,
    DimensionInputConfig,
    OptimisticWrapper,
    parseNumberUnit,
    RadioButtonProps,
    RadioGroup,
} from '@wix/stylable-panel-components';
import { DEFAULT_EVAL_DECLARATION_VALUE, StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';

import type { CustomInputDynamicProps, CustomInputStaticProps } from '../../../drivers/types';
import type { OpenedDeclarationArray } from '../../../declaration-types';
import type { TranslateFunc } from '../../../types';
import { createDeclarationVisualizerDrivers, createShorthandOpenerApi } from '../../../utils';
import { parseShadow, EMPTY_COLOR } from '../../../visualizer-factories/shadows-visualizer-factory/shadow-utils';
import { getDimensionConfig } from '../../../generated-visualizers';
import { getTranslate } from '../../../hosts/translate';
import { ColorPicker, ColorPickerProps, getColorPickerAPI } from '../../../pickers/color-picker/color-picker';

import { classes, style } from './single-shadow-input.st.css';

export const DEFAULT_SHADOW_INPUT_COLOR = 'rgba(0,0,0,0.2)';

export enum ShadowTypes {
    OUTER_SHADOW = 'outerShadow',
    INNER_SHADOW = 'innerShadow',
}

export interface Shadow {
    angle: number;
    distance: string;
    spread: string;
    blur: string;
    color: string;
    inset: boolean;
}

// Math functions:
function toDegrees(angle: number): number {
    return angle * (180 / Math.PI);
}

function toRadians(degrees: number): number {
    return (degrees * Math.PI) / 180;
}

function round(x: number, i: number) {
    i = Math.pow(10, i);
    // default
    return Math.round(x * i) / i;
}

const { innerShadowToggleSectionLabel, outerShadowToggleLabel, innerShadowToggleLabel } =
    StylablePanelTranslationKeys.picker.shadow;
const { OUTER_SHADOW, INNER_SHADOW } = ShadowTypes;

export interface ShadowPickerStaticProps extends CustomInputStaticProps {}

export interface ShadowPickerDynamicProps extends CustomInputDynamicProps {
    onChange: (value: string, prop?: string, declarations?: OpenedDeclarationArray<string>) => void;
}

export interface ShadowPickerProps extends ShadowPickerStaticProps, ShadowPickerDynamicProps {}

type ShadowInputState = Shadow & { declValue: string };

const getToggleInsetControls = (inset: boolean, onChange: () => void, translate: TranslateFunc): RadioButtonProps[] => [
    {
        id: OUTER_SHADOW,
        label: translate(outerShadowToggleLabel),
        checked: !inset,
        onChange,
    },
    {
        id: INNER_SHADOW,
        label: translate(innerShadowToggleLabel),
        checked: inset,
        onChange,
    },
];

function ShadowInput(textShadow?: boolean): React.ComponentClass<ShadowPickerProps> & { panelName: string } {
    class ShadowInputInner extends React.Component<ShadowPickerProps, ShadowInputState> {
        private DEFAULT_SHADOW_ATTRIBUTES;
        private defaultShadowValues: Shadow;

        constructor(props: ShadowPickerProps) {
            super(props);
            const DEFAULT_SHADOW_ATTRIBUTES = getDimensionInputDefaults(props.panelHost).singleShadowInput;
            this.DEFAULT_SHADOW_ATTRIBUTES = DEFAULT_SHADOW_ATTRIBUTES;
            this.defaultShadowValues = {
                angle: DEFAULT_SHADOW_ATTRIBUTES.angle.value,
                distance: DEFAULT_SHADOW_ATTRIBUTES.distance,
                spread: textShadow ? '' : DEFAULT_SHADOW_ATTRIBUTES.spread,
                blur: DEFAULT_SHADOW_ATTRIBUTES.blur,
                color: DEFAULT_SHADOW_INPUT_COLOR,
                inset: false,
            };
            this.state = { ...this.defaultShadowValues, declValue: '' };
        }
        public static panelName: string = textShadow ? 'textShadow' : 'boxShadow';

        private value = this.props.value;
        private shorthandApi = createShorthandOpenerApi(
            (this.props.drivers ?? createDeclarationVisualizerDrivers()).variables
        );

        public render() {
            return (
                <div className={style(classes.root, this.props.className)}>
                    {this.renderColorGroup()}
                    <div className={classes.longDivider} />
                    {!textShadow ? this.renderInsetController() : null}
                    {!textShadow ? <div className={classes.longDivider} /> : null}
                    {this.renderDirectionController()}
                    <div className={classes.longDivider} />
                    {this.renderDistanceController()}
                    <div className={classes.longDivider} />
                    {this.renderBlurController()}
                    {!textShadow ? <div className={classes.longDivider} /> : null}
                    {!textShadow ? this.renderSizeController() : null}
                </div>
            );
        }

        // eslint-disable-next-line react/no-deprecated
        public componentWillMount() {
            this.setValues();
        }

        public componentDidUpdate() {
            this.setValues();
        }

        private getValueWithValidUnit(dimension?: Dimension) {
            return dimension ? `${dimension.number}${dimension.unit}` : undefined;
        }

        private hasValueChanged(newDeclValue: string) {
            return this.state.declValue !== newDeclValue;
        }

        private setValues(): string | undefined {
            if (!this.value || this.value === '' || !this.shorthandApi) {
                this.setState({ ...this.defaultShadowValues });
                return;
            }

            const { siteVarsDriver } = this.props;
            const evalDeclarationValue =
                siteVarsDriver?.evalDeclarationValue?.bind(siteVarsDriver) ?? DEFAULT_EVAL_DECLARATION_VALUE;

            const declValue = evalDeclarationValue(this.value);

            if (!this.hasValueChanged(declValue)) return;
            this.setState({ declValue });

            const parts = parseShadow(!textShadow ? 'box-shadow' : 'text-shadow', declValue, this.shorthandApi);
            const shadowLayer = parts[0];
            const x = shadowLayer['offset-x'].number;
            const y = shadowLayer['offset-y'].number;
            const unit = shadowLayer['offset-x'].unit;

            const distance = Math.round(Math.sqrt(x * x + y * y));
            // TODO: Use unit from x, y if they have the same unit. What do we do if they don't?
            this.setState({ distance: `${distance}${unit}` });
            if (distance !== 0) {
                this.setState({ angle: Math.atan2(y, x) });
            }

            this.setState({
                blur: this.getValueWithValidUnit(shadowLayer['blur-radius']) ?? this.DEFAULT_SHADOW_ATTRIBUTES.blur,
            });
            this.setState({
                spread: textShadow
                    ? ''
                    : this.getValueWithValidUnit((shadowLayer as BoxShadowMap)['spread-radius']) ??
                      this.DEFAULT_SHADOW_ATTRIBUTES.spread,
            });
            this.setState({ color: shadowLayer.color });
            this.setState({ inset: !!(shadowLayer as BoxShadowMap).inset });
            return;
        }

        private getDistanceDimensionConfig() {
            const { panelHost } = this.props;
            return getDimensionConfig({
                id: DIMENSION_ID.SINGLE_SHADOW_DISTANCE,
                dimensionUnits: panelHost?.dimensionUnits,
                customUnits: SINGLE_SHADOW_DISTANCE,
                dimensionKeywords: panelHost?.dimensionKeywords,
            });
        }

        private getSpreadDimensionConfig() {
            const { panelHost } = this.props;
            return getDimensionConfig({
                id: DIMENSION_ID.SINGLE_SHADOW_SPREAD,
                dimensionUnits: panelHost?.dimensionUnits,
                customUnits: SINGLE_SHADOW_SPREAD,
                dimensionKeywords: panelHost?.dimensionKeywords,
            });
        }

        private parseDimension(value: string, dimensionConfig: DimensionInputConfig) {
            const parseFormattedValue = dimensionConfig.units?.spx?.parseFormattedValue;
            let dimension;
            try {
                if (!parseFormattedValue) {
                    throw new Error('No parser found');
                }
                dimension = parseFormattedValue(value) ?? {};
            } catch {
                dimension = parseNumberUnit(value);
            }
            return dimension;
        }

        private getFormattedValue(
            numericValue: number,
            unit: string | undefined,
            dimensionConfig: DimensionInputConfig
        ) {
            const spxFormatter = dimensionConfig.units?.spx?.formatter;
            const formattedValue =
                spxFormatter && unit === 'spx' ? spxFormatter(`${numericValue}spx`) : numericValue + 'px';
            return formattedValue;
        }

        private getCssStringValue() {
            const distanceDimensionConfig = this.getDistanceDimensionConfig();
            const spreadDimensionConfig = this.getSpreadDimensionConfig();

            const { value = 0, unit } = this.parseDimension(this.state.distance, distanceDimensionConfig);

            const newX = round(value * round(Math.cos(this.state.angle), 5), 2);
            const newY = round(value * round(Math.sin(this.state.angle), 5), 2);

            const x = this.getFormattedValue(newX, unit, distanceDimensionConfig);
            const y = this.getFormattedValue(newY, unit, distanceDimensionConfig);

            const { value: spreadNumericValue = 0, unit: spreadUnit = '' } =
                !textShadow && this.state.spread ? this.parseDimension(this.state.spread, spreadDimensionConfig) : {};

            const spreadValue = this.getFormattedValue(spreadNumericValue, spreadUnit, spreadDimensionConfig);

            const hasBlur = !isNaN(parseFloat(this.state.blur));
            const hasSpread = !textShadow && spreadValue;

            let blurString = '';
            if (hasSpread) {
                blurString = hasBlur ? this.state.blur + ' ' : '0 ';
            } else if (hasBlur) {
                blurString = this.state.blur + ' ';
            }

            const spreadString = hasSpread ? spreadValue + ' ' : '';
            const colorString = this.state.color !== '' && this.state.color !== undefined ? this.state.color : '';
            return `${this.state.inset ? 'inset ' : ''}${x} ${y} ${blurString}${spreadString}${colorString}`.trim();
        }

        private renderColorGroup() {
            const translate = getTranslate(this.props.panelHost);
            const color = this.state.color;
            const noColor = !color || color === EMPTY_COLOR;

            return (
                <CompositeBlock
                    className={classes.colorGroup}
                    title={translate(StylablePanelTranslationKeys.picker.shadow.colorLabel)}
                    singleLine
                >
                    <BackgroundBox
                        className={classes.colorBox}
                        value={color}
                        noColor={noColor}
                        showNoColorDiagonal
                        onClick={() => this.openColorPicker(!noColor ? color : EMPTY_COLOR)}
                    />
                </CompositeBlock>
            );
        }

        private renderInsetController() {
            const translate = getTranslate(this.props.panelHost);

            return (
                <CompositeBlock className={classes.group} title={translate(innerShadowToggleSectionLabel)}>
                    <RadioGroup
                        className={classes.insetGroup}
                        values={getToggleInsetControls(this.state.inset, this.changeInset, translate)}
                    />
                </CompositeBlock>
            );
        }

        private renderDirectionController() {
            const { panelHost } = this.props;
            const translate = getTranslate(panelHost);
            const angle = this.getKnobAngle() + this.DEFAULT_SHADOW_ATTRIBUTES.angle.unit;

            const dimensionConfig = getDimensionConfig({
                id: DIMENSION_ID.SINGLE_SHADOW_DEGREES_RANGE,
                dimensionUnits: panelHost?.dimensionUnits,
                customUnits: DEGREES_RANGE,
                dimensionKeywords: panelHost?.dimensionKeywords,
            });

            return (
                <CompositeBlock
                    className={classes.group}
                    title={translate(StylablePanelTranslationKeys.picker.shadow.directionInputLabel)}
                >
                    <DimensionInput
                        className={classes.inputElementAngle}
                        value={angle}
                        config={dimensionConfig}
                        useDisplaySymbol={true}
                        isSlider={true}
                        isSliderCyclicKnob={true}
                        isInputCyclic={true}
                        onChange={this.changeAngle}
                        showUnitErrors={panelHost?.showDimensionErrors}
                        translate={translate}
                        translationKeys={StylablePanelTranslationKeys.component.dimensionInput}
                    />
                </CompositeBlock>
            );
        }

        private renderDistanceController() {
            const { panelHost } = this.props;
            const translate = getTranslate(panelHost);

            const dimensionConfig = this.getDistanceDimensionConfig();

            return (
                <CompositeBlock
                    className={classes.group}
                    title={translate(StylablePanelTranslationKeys.picker.shadow.distanceInputLabel)}
                >
                    <DimensionInput
                        className={classes.inputElementDistance}
                        value={this.state.distance}
                        config={dimensionConfig}
                        isSlider={true}
                        keepRange={true}
                        onChange={this.changeDistance}
                        showUnitErrors={panelHost?.showDimensionErrors}
                        translate={translate}
                        translationKeys={StylablePanelTranslationKeys.component.dimensionInput}
                    />
                </CompositeBlock>
            );
        }

        private renderBlurController() {
            const { panelHost } = this.props;
            const translate = getTranslate(panelHost);

            const dimensionConfig = getDimensionConfig({
                id: DIMENSION_ID.SINGLE_SHADOW_BLUR,
                dimensionUnits: panelHost?.dimensionUnits,
                customUnits: SINGLE_SHADOW_BLUR,
                dimensionKeywords: panelHost?.dimensionKeywords,
            });

            return (
                <CompositeBlock
                    className={classes.group}
                    title={translate(StylablePanelTranslationKeys.picker.shadow.blurInputLabel)}
                >
                    <DimensionInput
                        className={classes.inputElementBlur}
                        value={this.state.blur}
                        config={dimensionConfig}
                        isSlider={true}
                        keepRange={true}
                        onChange={this.changeBlur}
                        showUnitErrors={panelHost?.showDimensionErrors}
                        translate={translate}
                        translationKeys={StylablePanelTranslationKeys.component.dimensionInput}
                    />
                </CompositeBlock>
            );
        }

        private renderSizeController() {
            const { panelHost } = this.props;
            const translate = getTranslate(panelHost);

            const dimensionConfig = getDimensionConfig({
                id: DIMENSION_ID.SINGLE_SHADOW_SPREAD,
                dimensionUnits: panelHost?.dimensionUnits,
                customUnits: SINGLE_SHADOW_SPREAD,
                dimensionKeywords: panelHost?.dimensionKeywords,
            });

            return (
                <CompositeBlock
                    className={classes.group}
                    title={translate(StylablePanelTranslationKeys.picker.shadow.sizeInputLabel)}
                >
                    <DimensionInput
                        className={classes.inputElementSpread}
                        value={this.state.spread}
                        config={dimensionConfig}
                        isSlider={true}
                        keepRange={true}
                        onChange={this.changeSpread}
                        showUnitErrors={panelHost?.showDimensionErrors}
                        translate={translate}
                        translationKeys={StylablePanelTranslationKeys.component.dimensionInput}
                    />
                </CompositeBlock>
            );
        }

        private openColorPicker(currentColor: string) {
            const { drivers, siteVarsDriver, panelHost } = this.props;

            if (!panelHost?.onOpenPanel) {
                return;
            }

            const translate = getTranslate(panelHost);

            const colorPickerProps: ColorPickerProps = {
                className: classes.colorPicker,
                title: translate(StylablePanelTranslationKeys.controller.shadows.colorPickerTitle),
                ...getColorPickerAPI({
                    currentColor,
                    onChange: this.changeColor,
                    onAstChange: this.changeColor,
                    drivers,
                }),
                siteVarsDriver,
                panelHost,
                // onClose: () => this.setState({colorPickerIndex: -1}),
                // onBlur: () => this.setState({colorPickerIndex: -1}),
                // TODO: Handle hover out with no selection
                onHover: (value: string | null) => this.changeColor(value || currentColor),
            };

            panelHost.onOpenPanel(ColorPicker.panelName, colorPickerProps);
        }

        private callChange(prop?: string, declarations?: OpenedDeclarationArray<string>) {
            const changeValue = this.getCssStringValue();

            this.value = changeValue;
            this.props.onChange(changeValue, prop, declarations);
        }

        private changeColor = (value: string, declarations?: OpenedDeclarationArray<string>) => {
            const { siteVarsDriver } = this.props;
            const evalDeclarationValue =
                siteVarsDriver?.evalDeclarationValue?.bind(siteVarsDriver) ?? DEFAULT_EVAL_DECLARATION_VALUE;

            this.setState({ color: evalDeclarationValue(value) }, () => {
                this.callChange('color', declarations);
                this.forceUpdate();
            });
        };

        private changeInset = () => {
            this.setState({ inset: !this.state.inset }, () => {
                this.callChange();
                this.forceUpdate();
            });
        };

        private changeAngle = (value?: string) => {
            const number = parseInt(value || '', 10);
            if (isNaN(number)) {
                return;
            }

            this.setState({ angle: toRadians((number + 90) % 360) }, this.callChange);
        };

        private changeDistance = (value?: string) => {
            const dimensionConfig = this.getDistanceDimensionConfig();
            let isSpxFormatter;
            try {
                if (value) {
                    const { unit } = dimensionConfig.units?.spx?.parseFormattedValue?.(value) ?? {};
                    isSpxFormatter = unit === 'spx';
                }
            } catch {
                isSpxFormatter = false;
            }

            if (!isSpxFormatter && (!value || parseNumberUnit(value).value === undefined)) {
                return;
            }
            this.setState({ distance: value! }, this.callChange);
        };

        private changeBlur = (value?: string) => {
            this.setState({ blur: value || this.DEFAULT_SHADOW_ATTRIBUTES.blur }, () => this.callChange('blur-radius'));
        };

        private changeSpread = (value?: string) => {
            this.setState({ spread: value || this.DEFAULT_SHADOW_ATTRIBUTES.spread }, () =>
                this.callChange('spread-radius')
            );
        };

        private getKnobAngle() {
            return ((toDegrees(this.state.angle) + 270) % 360).toFixed(0);
        }
    }

    return ShadowInputInner;
}

export const SingleShadowInputInner = ShadowInput(false);
export const TextShadowLayerInputInner = ShadowInput(true);

export const SingleShadowInput = OptimisticWrapper(SingleShadowInputInner, {
    onMouseDown: (context) => {
        context.setActive();
    },
    onMouseUp: (context) => {
        context.setIdle();
    },
    onChange: (context, ...args) => {
        context.onChange(...args);
    },
});

export const TextShadowLayerInput = OptimisticWrapper(TextShadowLayerInputInner, {});
