import { CornerProps, CornerVisualizer } from '@wix/stylable-panel-controllers';
import React, { FC } from 'react';
import { usePanelContext } from '../configurable-panel';
import type { CpControllerCommonProps } from '../cp-controller';
import { generateVisualizerProps } from '../utils/generate-visualizer-props';

export type CpCornerVisualizerProps = CpControllerCommonProps;
export const CpCornerVisualizer: FC<CpCornerVisualizerProps> = ({ editTargetSelector }) => {
    const context = usePanelContext();
    return (
        <CornerVisualizer
            {...generateVisualizerProps<CornerProps>({ context, editTargetSelector, key: 'CpCornerVisualizer' })}
        />
    );
};
