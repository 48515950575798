import { ClassSymbol, createDefaultResolver, CSSResolve, ElementSymbol, Stylable, StylableMeta } from '@stylable/core';
import { applyStylableForceStateSelectors } from '@stylable/webpack-extensions/dist/stylable-forcestates-plugin';
import type * as postcss from 'postcss';
import safeParse from 'postcss-safe-parser';
import { createElementsTree } from './create-elements-tree';
import { updateNodeRevision } from './mutable-ast';
import { aggregateSelectorDeclarations, FilterFunc } from './stylable-aggregation';
import { StylableSiteVars } from './stylable-site-vars';
import { StylesheetDriver } from './stylable-stylesheet';
import type { FullDeclarationMap, StylablePanelDriversExperiments, TransformationPlugins } from './types';
import { nativePseudoElementsSet } from './utils/native-pseudo-elements-set';

import { createMinimalFS, MinimalFSWithWrite, FileWithVersion } from './memory-minimal-fs';
import type { ComponentEditSession } from './editor-edit-session';

export interface ISymbol {
    from: string;
    name: string;
}

interface UpdateSingleState {
    callbacks: Array<() => void>;
    lock: number;
}

export type JsModuleDefinition = ((...args: string[]) => string) | object;
export type InlineModules = Record<string, JsModuleDefinition>;

type UpdateState = Record<string, UpdateSingleState>;

export type UsageMapping = Record<string, boolean> | ((namespace: string) => boolean);

export interface BuildConfig {
    entries?: string[];
    emitBuild?: boolean;
    usageMapping?: UsageMapping;
}

export type BuildHook = (css: string, stylesheets: string[]) => void;
export type SelectionHook = (editingSession: ComponentEditSession) => void;

export const ARCHETYPE_PROPNAME = '-archetype';
export const CONTROLLER_PART_TYPE_PROPNAME = '-controller-part-type';
export const USAGE_MAPPING_ALLOW_ALL: UsageMapping = () => true;

const PROJECT_ROOT = '/';

function wrapRequireWithInlineModules(requireModule: (id: string) => any, inlineModules: InlineModules) {
    return (id: string) => {
        const inlineModule: any = inlineModules[id];
        return inlineModule ? inlineModule : requireModule(id);
    };
}

export class StylableDriver {
    public stylable: Stylable;

    private siteVarsDriver: StylableSiteVars | undefined;
    private updateState: UpdateState = {};
    private batching = false; // if true - updateFromAst is blocked
    private jsModules: InlineModules;
    private scope: string | undefined;
    private transformationPlugins: TransformationPlugins = [];
    private aggregationCache = new Map<string, FullDeclarationMap>();

    private fsSource: Record<string, FileWithVersion> = {};
    private fs: MinimalFSWithWrite;
    private entries: string[] = [];
    private buildHooks: BuildHook[] = [];
    private fileHooks: BuildHook[] = [];
    public selectionHooks: SelectionHook[] = [];

    constructor(
        buildHook?: BuildHook,
        alias: Record<string, string> = {},
        private usageMapping: UsageMapping = {}, // TODO: Deprecate
        private experiments: StylablePanelDriversExperiments = {}
    ) {
        const { fs, requireModule } = createMinimalFS({ files: this.fsSource });
        this.fs = fs;

        buildHook && this.registerBuildHook(buildHook);

        this.jsModules = Object.create(null);
        const requireModuleWithInlineModules = wrapRequireWithInlineModules(requireModule, this.jsModules);
        const resolveOptions = {
            unsafeCache: true,
            symlinks: false,
            alias,
        };
        const defaultResolver = createDefaultResolver(this.fs, resolveOptions);
        const resolveModule = (directoryPath: string, request: string) =>
            this.jsModules[request] ? request : defaultResolver(directoryPath, request);

        this.stylable = Stylable.create({
            projectRoot: PROJECT_ROOT,
            fileSystem: this.fs,
            requireModule: requireModuleWithInlineModules,
            resolveOptions,
            resolveModule,
            cssParser: safeParse,
        });
    }

    public readFile(path: string) {
        // this assumes memory fs uses the object it was passed as the files.
        // should be changed to fs.exist ? fs.readFileSync : null
        return (this.fsSource[path] && this.fsSource[path].content) || null;
    }

    public removeFile(path: string) {
        this.fs.removeFileSync(path);
    }
    public setEntries(entries: string[]) {
        this.entries = entries;
    }
    public aggregateSelectorDeclarations(
        sheetPath: string | string[],
        selector: string,
        filter?: FilterFunc,
        useRawDecl?: boolean,
        // We don't open background shorthands since they are fully opened by the background-driver
        disableBackgroundShorthand = true
    ) {
        const sheetPaths = Array.isArray(sheetPath) ? sheetPath : [sheetPath];

        if (filter === undefined && useRawDecl === undefined) {
            const cacheKey = sheetPaths.join(':;') + ':;' + selector;
            let decls = this.aggregationCache.get(cacheKey);
            if (!decls) {
                decls = aggregateSelectorDeclarations(
                    this,
                    sheetPaths,
                    selector,
                    undefined,
                    undefined,
                    disableBackgroundShorthand
                );
                this.aggregationCache.set(cacheKey, decls);
            }
            return decls;
        } else {
            return aggregateSelectorDeclarations(
                this,
                sheetPaths,
                selector,
                filter,
                useRawDecl,
                disableBackgroundShorthand
            );
        }
    }

    public writeFile(path: string, content: string) {
        if (content === this.readFile(path)) {
            return;
        }
        this.fs.writeFileSync(path, content);
        if (this.fileHooks.length) {
            this.fileHooks.forEach((hook) => hook(content, [path]));
        }
        this.stylable.process(path);
    }
    public buildFromRawCSS({ entries, emitBuild = true }: BuildConfig = {}) {
        const entriesArray = entries || this.entries;
        const css = entriesArray.map((path) => this.readFile(path)).join('\n');
        if (this.buildHooks.length && emitBuild !== false) {
            this.buildHooks.forEach((hook) => hook(css, entriesArray));
        }
        return css;
    }
    /*
    sets a single event listener which will be fire when
    - output (built) css changes - if fileChangeHook is false (default)
    OR
    - a source file changes - if fileChangeHook is true
     */
    //TODO change function names to be more generic and deprecate
    /**@deprecated - probably not in use anywhere*/
    public setBuildHook(buildHook: BuildHook, fileChangeHook = false) {
        if (fileChangeHook) {
            this.fileHooks = [buildHook];
        } else {
            this.buildHooks = [buildHook];
        }
    }
    /*
    Registers an event listener which will be fire when
    - output (built) css changes - if fileChangeHook is false (default)
    OR
    - a source file changes - if fileChangeHook is true
     */
    public registerBuildHook(buildHook: BuildHook, fileChangeHook = false) {
        let hookArray;
        if (fileChangeHook) {
            hookArray = this.fileHooks;
        } else {
            hookArray = this.buildHooks;
        }
        hookArray.push(buildHook);
    }

    public unregisterBuildHook(buildHook: BuildHook, fileChangeHook = false) {
        let hookArray;
        if (fileChangeHook) {
            hookArray = this.fileHooks;
        } else {
            hookArray = this.buildHooks;
        }
        const hookIndex = hookArray.indexOf(buildHook);
        !!~hookIndex && hookArray.splice(hookIndex, 1);
    }

    public registerSelectionHook(selectionHook: SelectionHook) {
        this.selectionHooks.push(selectionHook);
    }

    public unregisterSelectionHook(selectionHook: SelectionHook) {
        const hookIndex = this.selectionHooks.indexOf(selectionHook);
        if (hookIndex >= 0) {
            this.selectionHooks.splice(hookIndex, 1);
        }
    }

    public buildCSS({ entries, emitBuild = true }: BuildConfig = {}) {
        const entriesArray = entries || this.entries;
        const css = entriesArray
            .map((entry) => {
                const { meta } = this.stylable.transform(this.getStylesheetMeta(entry));
                this.applyEditorTransformations(meta);
                return meta.outputAst!.toString();
            })
            .join('\n');
        if (this.buildHooks.length && emitBuild !== false) {
            this.buildHooks.forEach((hook) => hook(css, entriesArray));
        }
        return css;
    }
    public applyEditorTransformations(meta: StylableMeta) {
        applyStylableForceStateSelectors(meta.outputAst!, USAGE_MAPPING_ALLOW_ALL || this.usageMapping);
        return meta;
    }

    // TODO: protected?
    public getStylesheetMeta(path: string) {
        return this.stylable.process(path);
    }

    public getTransformer() {
        return this.stylable.createTransformer();
    }

    public setScope(scope?: string) {
        this.scope = scope || undefined;
    }
    public getScope() {
        return this.scope;
    }

    public getStylesheet(path: string) {
        const meta = this.getStylesheetMeta(path);
        // TODO: When does this return null?
        return meta
            ? new StylesheetDriver(
                  this.getStylesheetMeta.bind(this, path),
                  this.updateFileFromAST.bind(this, path),
                  this.getTransformer.bind(this),
                  this.getScope(),
                  this.transformationPlugins,
                  this.experiments
              )
            : null;
    }

    public reloadSiteColors() {
        if (this.siteVarsDriver) {
            this.siteVarsDriver.loadSiteColors();
            this.aggregationCache.clear();
        }
    }

    public getSiteVarsDriver(path: string) {
        if (!this.siteVarsDriver) {
            const sheet = this.getStylesheet(path);
            if (!sheet) {
                return null;
            }

            this.siteVarsDriver = new StylableSiteVars(sheet);
        }

        return this.siteVarsDriver;
    }

    public getSelectorTypePath(sheetPath: string, selector: string) {
        const resolved = this.getElementResolved(sheetPath, selector);
        if (!resolved) {
            return '';
        }
        if (resolved.length === 1) {
            return resolved[0].meta.source;
        }
        const root = [...resolved].reverse().find((resolve) => resolve.symbol.name === 'root') || null;
        return root ? root.meta.source : '';
    }

    public getPseudoElements(sheetPath: string, selector: string) {
        const resolved = this.getElementResolved(sheetPath, selector);
        if (!resolved) {
            return [];
        }
        const extendedElements = resolved.filter((resolve) => resolve.symbol.name === 'root');

        const res = new Set<string>();
        extendedElements.forEach((extendedElement) => {
            Object.keys(extendedElement.meta.classes)
                .filter((name) => name !== 'root')
                .forEach((name) => {
                    res.add(name);
                });
        });
        return Array.from(res);
    }

    public getPseudoElementsDeep(sheetPath: string, selector: string) {
        return createElementsTree(this.getStylesheetMeta(sheetPath), selector, this.stylable.resolver);
    }

    public getTargetClass(sheetPath: string, className: string) {
        const meta = this.getStylesheetMeta(sheetPath);
        return this.getTransformer().scope(className, meta.namespace);
    }

    /***/

    // private getASTForPath(path: string){ return this.getStylesheetMeta(path).rawAst; }

    public getPreprocessorValue(preprocessorPropName: string, sheetPath: string, selector: string) {
        const sheet = this.getStylesheet(sheetPath);
        if (!sheet) {
            return undefined;
        }

        const foundPreprocessorValueInFirstSheet = findPreprocessorValueInSheet(sheet, selector);
        if (foundPreprocessorValueInFirstSheet) {
            return foundPreprocessorValueInFirstSheet;
        }

        const meta = sheet.getMeta();
        const elements = this.getTransformer().resolveSelectorElements(meta, selector)[0];
        const { resolved, name } = elements[elements.length - 1];
        if (resolved.length === 0 && nativePseudoElementsSet.has(name)) {
            const part = elements[elements.length - 2];
            return part ? findValueInResolved(this, part.resolved, name) : undefined;
        }

        return findValueInResolved(this, resolved);

        function findValueInResolved(
            driver: StylableDriver,
            resolved: CSSResolve<ClassSymbol | ElementSymbol>[],
            nativePseudo?: string
        ) {
            if (resolved.length < 1) {
                return undefined;
            }
            for (const cssResolve of resolved) {
                const allegedSheet = driver.getStylesheet(cssResolve.meta.source);
                const allegedClassName = cssResolve.symbol.name;

                if (allegedSheet) {
                    const foundPreprocessorValue = findPreprocessorValueInSheet(
                        allegedSheet,
                        '.' + allegedClassName + (nativePseudo ? `::${nativePseudo}` : '')
                    );
                    if (foundPreprocessorValue) {
                        return foundPreprocessorValue;
                    }
                }
            }
            return undefined;
        }

        // check if it is the requested preprocessor declaration, and return it if so:
        function findPreprocessorValueInSheet(allegedSheet: StylesheetDriver, allegedClassName: string) {
            const rules = allegedSheet.queryStyleRule(allegedClassName);

            if (rules.length > 0 && rules[0].nodes) {
                const preprocessorDecl = (rules[rules.length - 1].nodes as postcss.Declaration[]) // TODO check with barak
                    .find((decl) => decl.prop === preprocessorPropName) as postcss.Declaration;
                if (preprocessorDecl) {
                    // rule has the requested preprocessor:
                    return preprocessorDecl.value;
                }
            }

            // not the requested preprocessor declaration
            return undefined;
        }
    }

    // TODO concept should be expanded and moved to stylable as -st-archetype
    public getArchetypeName(sheetPath: string, name: string) {
        return this.getPreprocessorValue(ARCHETYPE_PROPNAME, sheetPath, name);
    }

    public getControllerPartType(sheetPath: string, selector: string) {
        return this.getPreprocessorValue(CONTROLLER_PART_TYPE_PROPNAME, sheetPath, selector);
    }

    // TODO: Move to stylable
    // TODO: mixins that resolve to root should return all used variables in stylesheet
    public getCSSMixinOverrides(sheetPath: string, name: string) {
        const resolvedSymbol = this.resolveSymbol(sheetPath, name);
        if (!resolvedSymbol) {
            return [];
        }

        const sheet = this.getStylesheet(resolvedSymbol.from);
        if (!sheet) {
            return [];
        }

        return sheet.getCSSMixinOverrides(resolvedSymbol.name);
    }

    public getExperiments() {
        return this.experiments;
    }

    public resolveSymbol(from: string, name: string): ISymbol | null {
        const sheet = this.getStylesheet(from);
        if (!sheet) {
            return null;
        }

        const symbol = sheet.getSymbol(name);
        if (!symbol) {
            return null;
        }

        if (symbol._kind === 'import') {
            return this.resolveSymbol(symbol.import.from, symbol.name !== 'default' ? symbol.name : 'root');
        } else if (symbol._kind !== 'class') {
            return null;
        }

        return { from, name };
    }

    /**
     * runs a given function and prevents ast transform to occur while running it
     * @param path - path of file being updated
     * @param func - function to be run
     * @param cb - callback to be called by updateFromAST after transform
     */
    public batch(path: string | string[], func: () => void, cb?: () => void) {
        // Start blocking updates:
        this.batching = true;

        func();

        // release blocking updates:
        this.batching = false;
        if (Array.isArray(path)) {
            path.forEach((path) => this.updateFromAST(path, cb));
        } else {
            this.updateFromAST(path, cb);
        }
    }

    public updateFileFromAST(path: string, modified: postcss.Node, cb?: () => void) {
        this.updateFromAST(path, cb);
        return updateNodeRevision(modified);
    }

    public registerJsModule(key: string, mixin: JsModuleDefinition) {
        this.jsModules[key] = mixin;
    }

    public registerJsModules(mixins: InlineModules) {
        Object.keys(mixins).forEach((key) => this.registerJsModule(key, mixins[key]));
    }

    public registerTransformationPlugins(plugins: TransformationPlugins) {
        this.transformationPlugins.push(...plugins);
    }

    public unregisterJsModule(key: string) {
        delete this.jsModules[key];
    }

    public clearFsCache(filterFunc: (key: string) => boolean) {
        const cache = this.stylable.fileProcessor.cache;
        Object.keys(cache)
            .filter(filterFunc)
            .forEach((key) => {
                delete cache[key];
            });
    }

    private clearCache(path: string) {
        const cacheToRemove = [];

        for (const key of this.aggregationCache.keys()) {
            if (key.includes(path)) {
                cacheToRemove.push(key);
            }
        }

        for (const key of cacheToRemove) {
            this.aggregationCache.delete(key);
        }
    }

    private updateFromAST(path: string, cb?: () => void) {
        if (this.batching) {
            return;
        }
        if (this.updateState[path] === undefined) {
            this.updateState[path] = { callbacks: [], lock: 0 };
        }
        // Update FS immediately:
        this.updateFsFromAst(path);

        this.clearCache(path);

        const stylesheet = this.getStylesheet(path)!;
        // restore source meta ref (should be same value)
        const meta = stylesheet.getMeta();
        // update CSS and runtime
        this.stylable.transform(meta);

        if (!this.updateState[path].lock) {
            this.updateState[path].lock = setTimeout(() => {
                // TODO: change hard coded /site.st.css
                this.buildCSS({ entries: this.entries });

                this.updateState[path].callbacks.forEach((func) => func());
                this.updateState[path].callbacks = [];
                this.updateState[path].lock = 0;
            }, 10) as any;
        }

        if (cb && !~this.updateState[path].callbacks.indexOf(cb)) {
            this.updateState[path].callbacks.push(cb);
        }
    }

    private updateFsFromAst(path: string) {
        const stylesheet = this.getStylesheet(path)!;
        const currentSourceAst = stylesheet.AST;
        // update FS
        this.writeFile(path, stylesheet.source);
        const meta = stylesheet.getMeta();
        meta.rawAst = currentSourceAst; // restore mutable-ast metadata
    }

    private getElementResolved(sheetPath: string, selector: string) {
        const meta = this.getStylesheetMeta(sheetPath);
        const transformer = this.getTransformer();
        const elements = transformer.resolveSelectorElements(meta, selector)[0];
        if (selector.split('::').length !== elements.length) {
            return null;
        }
        return elements[elements.length - 1].resolved;
    }
}
