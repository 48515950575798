import type * as postcss from 'postcss';
import {
    RefedMixin,
    SRule,
    CSSResolve,
    StylableMeta,
    ClassSymbol,
    ElementSymbol,
    StylableTransformer,
    evalDeclarationValue,
    Stylable,
    scopeSelector,
} from '@stylable/core';
import {
    parse,
    stringify,
    SelectorsNode,
    SelectorNode,
    AnySelectorNode,
    SelectorNodeType,
} from 'css-selector-tokenizer';
import { StylableShorthands } from './stylable-shorthands';
import type { FullDeclarationMap, EvalOverrides } from './types';
import { matchSelectorPartsWithStates, walkNodes } from './aggregation-tools/match-selector-parts';
import { calculateSpecificity, matchSpecificity } from './aggregation-tools/selector-specificity';
import {
    DEFAULT_FILTER,
    PROPS_SUPPORTING_COMMENTS,
    COMMENT_START_IN_RAWS_BETWEEN_REGEX,
} from './stylable-aggregation-constants';
import type { StylesheetDriver } from './stylable-stylesheet';

type ExtractedDeclMeta = Array<[string, string, StylableMeta, postcss.Rule, EvalOverrides]>;
type ElementStateMapping = Array<{ el: string; states: string[] }>;
export type MatchingRule = { meta: StylableMeta; rule: postcss.Rule; overrides: EvalOverrides };
export type MatchingRules = Array<MatchingRule>;

interface Options {
    filterDecl?: typeof DEFAULT_FILTER;
    scopes?: Set<string>;
    useRawDecl?: boolean;
    shallow?: boolean;
    disableBackgroundShorthand?: boolean;
}

export interface StaticComputedCSS {
    decls: FullDeclarationMap;
    declsOrder: ExtractedDeclMeta;
}

export function getStaticComputedCSS(
    stylable: Stylable,
    sheets: StylesheetDriver[],
    selector: string,
    { filterDecl = DEFAULT_FILTER, useRawDecl = false, disableBackgroundShorthand = false }: Options = {}
): StaticComputedCSS {
    const nonScopedMatchingRules = aggregateRules(stylable.createTransformer(), sheets[0].getMeta(), selector, {});
    for (let i = 1; i < sheets.length; i++) {
        const shallowMatching = aggregateRules(stylable.createTransformer(), sheets[i].getMeta(), selector, {}, true);
        nonScopedMatchingRules.unshift(shallowMatching[0]);
    }

    return mergeAggregatedRulesDecl(
        (meta, decl, overrides) => evalDeclarationValue(stylable.resolver, decl.value, meta, decl, overrides),
        filterScope(nonScopedMatchingRules, sheets[0].getActiveScopes()),
        filterDecl,
        useRawDecl,
        disableBackgroundShorthand
    );
}

function filterScope(matchingRules: MatchingRules[], scopes: Set<string>) {
    return matchingRules.map((chunk) => {
        return chunk.filter(({ rule }) => {
            const stScopeSelector = (rule as SRule).stScopeSelector;
            if (stScopeSelector) {
                return scopes.has(`@st-scope ${stScopeSelector}`);
            }
            if (rule.parent?.type === 'root') {
                return true;
            }
            if (
                rule.parent?.type === 'atrule' &&
                scopes.has(`@${(rule.parent as postcss.AtRule).name} ${(rule.parent as postcss.AtRule).params}`)
            ) {
                return true;
            }
            return false;
        });
    });
}

function getCommentedDeclValue(decl: postcss.Declaration) {
    if (~PROPS_SUPPORTING_COMMENTS.indexOf(decl.prop)) {
        let valueWithComments = '';
        const raws = decl.raws as any;

        if (raws.value && raws.value.raw) {
            valueWithComments = raws.value.raw;
        }

        if (raws.between && raws.between.match(COMMENT_START_IN_RAWS_BETWEEN_REGEX)) {
            valueWithComments = raws.between.slice(1).trim() + (valueWithComments || decl.value);
        }
        if (valueWithComments) {
            return decl.clone({ value: valueWithComments });
        }
    }
    return decl;
}

function sortBySpecificity(chunk: MatchingRules) {
    const options = { stylableMode: true };
    return chunk.sort((a, b) => {
        const aSelectors = a.rule.selectors;
        const bSelectors = b.rule.selectors;
        if (aSelectors.length !== 1 || bSelectors.length !== 1) {
            throw new Error('only single selector is supported for sorting');
        }
        const sA = calculateSpecificity(aSelectors[0], options);
        const sB = calculateSpecificity(bSelectors[0], options);

        return matchSpecificity(sA, sB);
    });
}

export function mergeAggregatedRulesDecl(
    evalDeclarationValue: (meta: StylableMeta, decl: postcss.Declaration, overrides?: EvalOverrides) => string,
    matchingRules: MatchingRules[],
    filterDecl = DEFAULT_FILTER,
    useRawDecl = false,
    disableBackgroundShorthand = false
): StaticComputedCSS {
    let l = matchingRules.length;
    const decls: FullDeclarationMap = {};
    const declsOrder: ExtractedDeclMeta = [];
    const short = new StylableShorthands();
    const all = [];
    while (l--) {
        all.push(...matchingRules[l]);
    }

    const allSorted = sortBySpecificity(all);
    for (const { meta, rule, overrides } of allSorted) {
        rule.walkDecls((decl) => {
            decl = getCommentedDeclValue(decl);
            const evaluated = useRawDecl ? decl.value : evalDeclarationValue(meta, decl, overrides);
            const shorts = useRawDecl
                ? { [decl.prop]: evaluated }
                : short.expandDeclaration(
                      decl.clone({ value: evaluated }),
                      undefined,
                      undefined,
                      disableBackgroundShorthand
                  );
            for (const [key, val] of Object.entries(shorts)) {
                if (filterDecl(key)) {
                    decls[key] = val;
                    declsOrder.push([key, val, meta, rule, overrides]);
                }
            }
        });
    }
    return { decls, declsOrder };
}

export function aggregateRules(
    transformer: StylableTransformer,
    rootMeta: StylableMeta,
    selector: string,
    overrides: EvalOverrides = {},
    shallow = false
) {
    const selectorElements = transformer.resolveSelectorElements(rootMeta, selector)[0];

    const elStateMapping = extractElementStateMapping(selector);

    if (selectorElements.length !== elStateMapping.length) {
        throw new Error(
            'Unsupported Selector provided this algorithm only support .x:state1:state2::y:state1:state2::z:state1:state2 like structure'
        );
    }

    const matchingRules: MatchingRules[] = [];

    selectorElements.forEach(({ resolved }, i) => {
        const resolvedToAggregate = shallow ? resolved.slice(0, 1) : resolved;
        resolvedToAggregate.forEach(({ meta, symbol }) => {
            const matchingSelector = getMatchingSelector(symbol, elStateMapping, i);

            const matchingSelectorAst = parse(matchingSelector);
            const ruleChunk: MatchingRules = [];
            meta.ast.walkRules((rawRule) => {
                // multiple selector support by splitting the rules into single selector rules
                rawRule.selectors.forEach((selector) => {
                    const stScopeSelector = (rawRule as SRule).stScopeSelector;

                    const rule = rawRule.clone({ selector, parent: rawRule.parent } as Partial<postcss.RuleProps>);

                    const currentSelector = stScopeSelector ? selector.slice(stScopeSelector.length + 1) : selector;
                    if (matchSelectorPartsWithStates(matchingSelectorAst, parse(currentSelector))) {
                        const mixins = getRuleMixins(rule);
                        if (mixins) {
                            handleMixins(rule, meta, overrides, mixins, transformer, elStateMapping, i, ruleChunk);
                        } else {
                            ruleChunk.push({
                                meta,
                                rule,
                                overrides,
                            });
                        }
                    }
                });
            });
            matchingRules.push(ruleChunk);
        });
    });
    return matchingRules;
}

function extractElementStateMapping(selector: string): ElementStateMapping {
    return selector.split('::').map((part) => {
        const [el, ...states] = part.split(':');
        return {
            el,
            states,
        };
    });
}

function getMatchingSelector(
    symbol: ClassSymbol | ElementSymbol,
    elStateMapping: { el: string; states: string[] }[],
    i: number
) {
    const prefix = symbol._kind === 'class' ? '.' : '';
    const matchingSelector =
        prefix +
        elStateMapping
            .slice(i)
            .map((part, idx) => {
                const statePart = part.states.length ? `:${part.states.join(':')}` : '';
                const elPart = idx === 0 ? symbol.name : part.el;
                return elPart + statePart;
            })
            .join('::');
    return matchingSelector;
}

/* Stylable Mixins Helpers */

function handleMixins(
    rule: postcss.Rule,
    meta: StylableMeta,
    overrides: EvalOverrides,
    mixins: RefedMixin[],
    transformer: StylableTransformer,
    elStateMapping: ElementStateMapping,
    elementIndex: number,
    ruleChunk: MatchingRules
) {
    if (!rule.nodes) {
        throw new Error(`Missing nodes in rule ${rule.toString()}`);
    }
    const mixinDecls = rule.nodes.filter((node) => {
        return node.type === 'decl' && node.prop === '-st-mixin';
    });

    /*
      We can maybe resolve this throw by taking the last mixin decl.
      since it's a bad practice to use multiple -st-mixin decls in the same rule we are not supporting it.
    */
    if (mixinDecls.length !== 1) {
        throw new Error(`Only support one -st-mixin declaration found ${mixinDecls.toString()}`);
    }

    const index = rule.nodes.indexOf(mixinDecls[0]);
    const beforeMixin = rule.nodes.slice(0, index + 1);
    const afterMixin = rule.nodes.slice(index + 1);

    const rootRules = [
        {
            meta,
            rule: rule.clone({ nodes: beforeMixin, parent: rule.parent } as Partial<postcss.RuleProps>),
            overrides,
        },
    ];
    const afterRootRules = [
        {
            meta,
            rule: rule.clone({ nodes: afterMixin, parent: rule.parent } as Partial<postcss.RuleProps>),
            overrides,
        },
    ];

    mixins.forEach((mix) => {
        const resolvedMixin = resolveMixin(transformer, mix, meta);

        if (!resolvedMixin) {
            throw new Error(`Failed to resolve mixin ${JSON.stringify(mix.ref)}`);
        }
        if (resolvedMixin?._kind === 'css') {
            if (resolvedMixin.symbol._kind === 'class' || resolvedMixin.symbol._kind === 'element') {
                const singleEl = [elStateMapping.slice(elementIndex)[0]];
                const matchingMixinSelector = getMatchingSelector(resolvedMixin.symbol, singleEl, 0);

                if (Array.isArray(mix.mixin.options)) {
                    throw new Error('Only named mixin arguments are supported');
                }

                // this assumes that the mixin has only one chunk
                const mixinRulesChunks = aggregateRules(transformer, resolvedMixin.meta, matchingMixinSelector, {
                    ...mix.mixin.options,
                    ...overrides,
                });
                if (mixinRulesChunks.length > 1) {
                    throw new Error('aggregate mixin rules only support one mixin chunk');
                }
                const mixinRules = mixinRulesChunks[0];

                processMixinsSelectors(rule, matchingMixinSelector, mixinRules);

                // this assumes the first rule of a mixin is the root
                const [mixinRoot, ...otherRules] = mixinRules;

                rootRules.push(mixinRoot);
                afterRootRules.push(...otherRules);
            }
        }
    });

    ruleChunk.push(...rootRules);
    ruleChunk.push(...afterRootRules);
}

function resolveMixin(transformer: StylableTransformer, mix: RefedMixin, meta: StylableMeta) {
    const symbol = meta.mappedSymbols[mix.mixin.type];
    if (symbol && (symbol._kind === 'class' || symbol._kind === 'element')) {
        const res: CSSResolve = {
            _kind: 'css',
            meta,
            symbol,
        };
        return res;
    } else {
        return transformer.resolver.deepResolve(mix.ref);
    }
}

function processMixinsSelectors(rootRule: postcss.Rule, matchingMixinSelector: string, mixinRules: MatchingRules) {
    const prefixType = parse(matchingMixinSelector).nodes[0].nodes[0];
    mixinRules.forEach(({ rule }) => {
        rule.selector = rule.selectors
            .map((selector) => {
                const targetSelector = stringify(
                    destructiveReplaceNode(parse(selector), prefixType, {
                        type: 'invalid',
                        value: '&',
                    })
                );
                return scopeSelector(rootRule.selector, targetSelector).selector;
            })
            .join(',');
    });
}

function destructiveReplaceNode(ast: SelectorsNode, matchNode: AnySelectorNode, replacementNode: SelectorNodeType) {
    walkNodes(ast, (node) => {
        if (node.type === matchNode.type && node.name === matchNode.name) {
            node.type = 'selector';
            (node as SelectorNode).nodes = [replacementNode];
        }
    });
    return ast;
}

function getRuleMixins(rule: postcss.Rule) {
    return (rule as SRule).mixins;
}
