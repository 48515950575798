import { DropDown, Option, Text, Tooltip } from '@wix/stylable-panel-components';
import React, { FC } from 'react';
import { usePanelContext } from './configurable-panel';
import { classes, style } from './state-drop-down.st.css';

export interface StateRendererProps {
    options: Option[];
    className?: string;
    state: string;
    title?: string;
    onSelect: (selector: string) => void;
    onForceState: (selector: string | null) => void;
}

export const StateDropDown: FC<StateRendererProps> = ({ className, title, state, options, onSelect, onForceState }) => {
    const { stateOverridesConfig } = usePanelContext();

    const getStateOverridesData = () => {
        const { content, disabled } = stateOverridesConfig || { content: '', disabled: true };
        const disabledTooltipText = stateOverridesConfig ? stateOverridesConfig.disabled : false;

        return {
            stateOverridesContent: content,
            stateOverridesDisabled: disabled,
            disabledTooltipText,
        };
    };

    const stateOverridesCallback = () => {
        const { callback } = stateOverridesConfig!;
        callback?.();
    };

    const { stateOverridesContent, stateOverridesDisabled, disabledTooltipText } = getStateOverridesData();
    return (
        <div className={style(classes.root, className)}>
            {title && <Text className={classes.label}>{`${title}:`}</Text>}
            <div className={classes.dropdownContainer}>
                <DropDown
                    className={classes.dropdown}
                    value={state}
                    options={options}
                    onHover={onForceState}
                    onSelect={onSelect}
                />
                {/*TODO restore these when implementing reset-states. see https://jira.wixpress.com/browse/STYL-949*/}
                <div className={classes.divider} />
                <div
                    onClick={stateOverridesCallback}
                    // TODO: Add this class to the state-drop-down.st.css file
                    className={classes.stateOverridesControls}
                >
                    <Tooltip className={classes.tooltip} disabled={disabledTooltipText} text={stateOverridesContent}>
                        <span className={classes.stateOverrideMenuLink}>
                            <span
                                className={style(classes.stateOverridesTrigger, {
                                    disabled: stateOverridesDisabled,
                                })}
                            />
                        </span>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};
