export const DIMENSION_INPUT_DEFAULTS = {
    backgroundVisualizer: { opacity: { value: 100, unit: '%' } },
    borderVisualizer: { width: { value: 0, unit: 'px' }, doubleWidth: { value: 3, unit: 'px' } },
    colorAdder: {
        hex: '#FFFFFF',
        hsb: { hue: 0, saturation: 0, value: 100 },
        rgb: [255, 255, 255],
    },
    colorPicker: {
        opacity: { value: 100, unit: '%' },
    },
    borderInitialConfig: {
        width: '1px',
        style: 'solid',
        fill: 'value(site_2_3)',
    },
    cornerVisualizer: { radius: '0px' },
    edgeVisualizerFactory: { edges: { value: 0, unit: 'px' } },
    flexChildSpacing: { spaceBetweenTextAndIcon: { value: 0, unit: 'px' } },
    gradientPicker: { scale: '100%', angle: { value: 0, unit: 'deg' } },
    iconVisualizer: {
        angle: { value: 0, unit: 'deg' },
        opacity: { value: 100, unit: '%' },
        size: '6px',
    },
    imageFill: {
        customScale: '100%',
        tileScale: '100%',
    },
    singleShadowInput: {
        angle: { value: 0, unit: 'deg' },
        blur: '0px',
        distance: '0px',
        spread: '0px',
        emptyShadow: {
            'offset-x': { number: 1, unit: 'px', raw: '1px' },
            'offset-y': { number: 1, unit: 'px', raw: '1px' },
            'blur-radius': { number: 0, unit: 'px', raw: '0px' },
            'spread-radius': { number: 0, unit: 'px', raw: '0px' },
            color: 'currentcolor',
        },
    },
    shadowsVisualizerFactory: {
        opacity: { value: 100, unit: '%' },
        newShadowDefault: '3px 3px 2px currentcolor',
    },
    textVisualizer: {
        fontSize: '6px',
        letterSpacing: '0em',
        lineHeight: '1.2em',
    },
};

// TODO: bring in type of panel host to stylable-panel-common
export function getDimensionInputDefaults(panelHost: any) {
    const panelHostDefaults = panelHost?.dimensionUnits?.defaults ?? {};
    return {
        ...DIMENSION_INPUT_DEFAULTS,
        ...panelHostDefaults,
    } as typeof DIMENSION_INPUT_DEFAULTS;
}
