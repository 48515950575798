import type { ComponentConfig, ComponentsMetadata } from '@stylable/webpack-extensions';
import type { DimensionID } from './dimension-input-types';

export interface PresetSectionLayouts {
    static: Record<string, string>;
    theme: Record<string, string>;
    user: Record<string, string>;
}

export enum StylePanelPlane {
    Vertical = 'vertical',
    VerticalCollapse = 'verticalCollapse',
}

export enum StylePanelPage {
    PresetPanel = 0,
    CustomizationPanel,
}

export enum SelectorConfigurationDisplay {
    Visible = 'visible',
    Hidden = 'hidden',
    ReplaceSelfWithOwnChildren = 'replaceSelfWithOwnChildren',
}

export interface SelectorConfiguration {
    hide?: boolean; // If true selector will not be displayed - Deprecated TODO remove
    display?: SelectorConfigurationDisplay; // This replaces the deprecated 'hide' field, and multiple options. default=visible
    nameKey?: string; // Translation key representing Name of element in a readable format
    stateDisplayKey?: string; // Translation key representing state displayer string
    primeElementSelector?: string; // Specify a selector to be chosen instead of the given one - used for state overrides. states are always applied on prime elements
    stateNameOverrides?: Record<string, StateNameOverride>; // Readable names for states used by element
    statesOrder?: string[]; // List of state names in the order we want to display them in the state dropdown
    copyControllers?: Record<string, CopyController[]>; // Specify selectors and the list of controllers to be copied from them
    controllerData?: Record<string, ControllerData>; // Specify any specific controller data for this selector
    foldInto?: boolean;
}

export interface EditorComponentConfig extends ComponentConfig {
    initialElement?: string; // If specified, this states which element should be shown first when opening stylable panel. (e.g ".root::label")
    presetSectionLayouts?: PresetSectionLayouts; // TODO deprecated - should be removed
    selectorConfiguration?: Record<string, SelectorConfiguration>;
    themePresets?: Record<string, string>;
}

export interface StateNameOverride {
    nameKey?: string;
    hidden?: boolean;
}

export interface CopyController {
    categoryId?: string;
    controllerKey?: string;
}

export interface ControllerData {
    translationKeys?: Record<string, string>;
    hideShownToggle?: boolean;
    glyphMode?: boolean;
    noStateResizing?: string[]; // TODO We can get rid of this after removing CategoryEditingPanel. see https://jira.wixpress.com/browse/STYL-1013
    noResizing?: boolean;
    noRotation?: boolean;
    noColor?: boolean;
}

export interface Coordinates {
    x: number;
    y: number;
}

export interface PanelComponentsMetadata extends ComponentsMetadata {
    components: { [path: string]: EditorComponentConfig };
}

export enum UnitRef {
    CENTIMETERS = 'centimeters',
    CHARACTER = 'character',
    CUSTOM = 'unitless',
    DEGREES = 'degrees',
    ELEMENTS = 'elements',
    GRADIANS = 'gradians',
    INCHES = 'inches',
    MILLIMETERS = 'millimeters',
    MILLISECONDS = 'milliseconds',
    PERCENTS = 'percents',
    PICAS = 'picas',
    PIXELS = 'pixels',
    POINTS = 'points',
    RADIANS = 'radians',
    ROOT_ELEMENTS = 'root-elements',
    SECONDS = 'seconds',
    TURNS = 'turns',
    VIEW_HEIGHT = 'viewport-height',
    VIEW_MAX = 'viewport-max',
    VIEW_MIN = 'viewport-min',
    VIEW_WIDTH = 'viewport-width',
    X_HEIGHT = 'x-height',
    SPX = 'spx',
}

export interface DimensionProps extends SliderConfig {
    displaySymbol?: string;
    min?: number;
    max?: number;
    step?: number;
    shiftStep?: number;
    noFloat?: boolean;
    formatter?: (value: number | string) => string;
    parseFormattedValue?: (value: string) => { value: number; unit: string };
    hidden?: boolean;
}

export interface SliderConfig {
    sliderMin?: number;
    sliderMax?: number;
    sliderStep?: number;
}

export type UnitRefPartialRecord = Partial<Record<UnitRef, DimensionProps>>;

export type SingleDimensionConfig = {
    disableUnitSelector?: boolean;
};

export type DimensionUnits = Partial<Record<DimensionID, UnitRefPartialRecord>> & {
    config?: Partial<Record<DimensionID, SingleDimensionConfig>>;
    defaults?: Record<string, any>;
};
export type DimensionKeywords = Partial<Record<DimensionID, string[] | undefined>>;

export type DeepPartial<T> = {
    [P in keyof T]?: DeepPartial<T[P]>;
};
