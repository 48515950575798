import type { FlexFlows } from '../shorthand-css-data';
import type { SimpleShorthandOpener, GetSimpleShorthandOpener, GetShorthandCloser } from '../shorthand-types';

import { flexDirectionDataType, flexWrapDataType } from '../../css-data-types';
import {
    unorderedListShorthandOpener,
    createShorthandOpener,
    shorthandCloserTemplate,
    createShorthandCloser,
} from '../shorthand-parser-utils';

// flex-flow
export const openFlexFlowShorthand: GetSimpleShorthandOpener<FlexFlows> = <V>() =>
    createShorthandOpener({
        prop: 'flex-flow',
        parts: [
            { prop: 'flex-direction', dataType: flexDirectionDataType },
            { prop: 'flex-wrap', dataType: flexWrapDataType },
        ],
        shorthandOpener: (astNodes, api, parts) => unorderedListShorthandOpener(parts)(astNodes, api),
    }) as SimpleShorthandOpener<V, FlexFlows>;

export const closeFlexFlowShorthand: GetShorthandCloser<FlexFlows> = <V>() =>
    createShorthandCloser<V, FlexFlows>(
        shorthandCloserTemplate<FlexFlows>`${'flex-direction'} ${'flex-wrap'}`
    );
