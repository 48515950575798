import { DIMENSION_ID, DimensionID, DimensionUnits, DimensionKeywords, LINE_HEIGHT } from '@wix/stylable-panel-common';
import type { DimensionInputConfig, DimensionVisualizerFactoryProps } from '@wix/stylable-panel-components';
import {
    SIZE_RANGE,
    PERCENTS_RANGE,
    DEGREES_RANGE,
    EDGE_SIZE,
    CORNER_RADIUS,
    LETTER_SPACING,
    BORDER_WIDTH_SOLID,
    BORDER_WIDTH_DOUBLE,
    UnitRefPartialRecord,
} from '@wix/stylable-panel-common';
import { DimensionVisualizerFactory } from '../visualizer-factories/dimension-visualizer-factory/dimension-visualizer-factory';

const _dimensionUnits: DimensionUnits = {
    [DIMENSION_ID.SIZE]: SIZE_RANGE,
    [DIMENSION_ID.OPACITY]: PERCENTS_RANGE,
    [DIMENSION_ID.ANGLE]: DEGREES_RANGE,
    [DIMENSION_ID.MARGIN]: EDGE_SIZE,
    [DIMENSION_ID.PADDING]: EDGE_SIZE,
    [DIMENSION_ID.CORNERS]: CORNER_RADIUS,
    [DIMENSION_ID.FONT_SIZE]: SIZE_RANGE,
    [DIMENSION_ID.LETTER_SPACING]: LETTER_SPACING,
    [DIMENSION_ID.LINE_HEIGHT]: LINE_HEIGHT,
    [DIMENSION_ID.WIDTH]: BORDER_WIDTH_SOLID,
    [DIMENSION_ID.WIDTH_DOUBLE]: BORDER_WIDTH_DOUBLE,
};

interface GetDimensionConfigOptions {
    id: DimensionID;
    dimensionUnits?: DimensionUnits;
    customUnits?: UnitRefPartialRecord;
    dimensionKeywords?: DimensionKeywords;
    customKeywords?: string[];
}

export const getDimensionConfig = ({
    id,
    dimensionUnits,
    customUnits = {},
    dimensionKeywords,
    customKeywords,
}: GetDimensionConfigOptions): DimensionInputConfig => {
    const idKeywords = dimensionKeywords?.[id];
    const config = dimensionUnits?.config?.[id] ?? {};
    const units = {
        ...(_dimensionUnits[id] || {}),
        ...customUnits,
        ...(dimensionUnits?.[id] || {}),
    };

    for (const [key, value] of Object.entries(units)) {
        if (!value || value.hidden) {
            delete units[key as keyof typeof units];
        }
    }
    return {
        units,
        keywords: idKeywords || customKeywords ? [...(customKeywords || []), ...(idKeywords || [])] : undefined,
        disableUnitSelector: config.disableUnitSelector ?? false,
    };
};

export const SizeVisualizer = DimensionVisualizerFactory(DIMENSION_ID.SIZE, {
    keepRange: true,
    isSlider: true,
    isBoundRange: true,
});

export const OpacityVisualizer = DimensionVisualizerFactory(DIMENSION_ID.OPACITY, {
    keepRange: true,
    isSlider: true,
});

export const AngleVisualizer = DimensionVisualizerFactory(DIMENSION_ID.ANGLE, {
    isSlider: true,
    isSliderCyclicKnob: true,
    isInputCyclic: true,
    useDisplaySymbol: true,
});

const PADDING = {
    PADDING_TOP: 'padding-top',
    PADDING_RIGHT: 'padding-right',
    PADDING_BOTTOM: 'padding-bottom',
    PADDING_LEFT: 'padding-left',
};

const MARGIN = {
    MARGIN_TOP: 'margin-top',
    MARGIN_RIGHT: 'margin-right',
    MARGIN_BOTTOM: 'margin-bottom',
    MARGIN_LEFT: 'margin-left',
};

export const PaddingTopInputVisualizer = DimensionVisualizerFactory(PADDING.PADDING_TOP, {
    keepRange: true,
});
export const PaddingRightInputVisualizer = DimensionVisualizerFactory(PADDING.PADDING_RIGHT, {
    keepRange: true,
});
export const PaddingBottomInputVisualizer = DimensionVisualizerFactory(PADDING.PADDING_BOTTOM, {
    keepRange: true,
});
export const PaddingLeftInputVisualizer = DimensionVisualizerFactory(PADDING.PADDING_LEFT, {
    keepRange: true,
});

export const MarginTopInputVisualizer = DimensionVisualizerFactory(MARGIN.MARGIN_TOP, {
    keepRange: true,
});
export const MarginRightInputVisualizer = DimensionVisualizerFactory(MARGIN.MARGIN_RIGHT, {
    keepRange: true,
});
export const MarginBottomInputVisualizer = DimensionVisualizerFactory(MARGIN.MARGIN_BOTTOM, {
    keepRange: true,
});
export const MarginLeftInputVisualizer = DimensionVisualizerFactory(MARGIN.MARGIN_LEFT, {
    keepRange: true,
});

const cornerVisualizerFactoryProps: DimensionVisualizerFactoryProps = {
    keepRange: true,
    inputDataAid: 'st_cornerinput_input',
};

const BORDERS = {
    TOP_LEFT_RADIUS: 'border-top-left-radius',
    TOP_RIGHT_RADIUS: 'border-top-right-radius',
    BOTTOM_RIGHT_RADIUS: 'border-bottom-right-radius',
    BOTTOM_LEFT_RADIUS: 'border-bottom-left-radius',
};

export const BorderTopLeftRadiusInputVisualizer = DimensionVisualizerFactory(
    BORDERS.TOP_LEFT_RADIUS,
    cornerVisualizerFactoryProps
);
export const BorderTopRightRadiusInputVisualizer = DimensionVisualizerFactory(
    BORDERS.TOP_RIGHT_RADIUS,
    cornerVisualizerFactoryProps
);
export const BorderBottomRightRadiusInputVisualizer = DimensionVisualizerFactory(
    BORDERS.BOTTOM_RIGHT_RADIUS,
    cornerVisualizerFactoryProps
);
export const BorderBottomLeftRadiusInputVisualizer = DimensionVisualizerFactory(
    BORDERS.BOTTOM_LEFT_RADIUS,
    cornerVisualizerFactoryProps
);

export const FontSizeVisualizer = DimensionVisualizerFactory(DIMENSION_ID.FONT_SIZE, {
    keepRange: true,
    isSlider: true,
});

export const LetterSpacingVisualizer = DimensionVisualizerFactory(DIMENSION_ID.LETTER_SPACING, {
    keepRange: true,
    isSlider: true,
});

export const LineHeightVisualizer = DimensionVisualizerFactory(DIMENSION_ID.LINE_HEIGHT, {
    keepRange: true,
    isSlider: true,
});

export const BorderWidthSolidVisualizer = DimensionVisualizerFactory(DIMENSION_ID.WIDTH, {
    keepRange: true,
    isBoundRange: true,
});

export const BorderWidthDoubleVisualizer = DimensionVisualizerFactory(DIMENSION_ID.WIDTH, {
    keepRange: true,
    isBoundRange: true,
});
