import type { IconProps } from '@wix/stylable-panel-common-react';
import React, { ReactElement } from 'react';
import { Button } from '../button/button';
import { Icon } from '../icon/icon';
import { Text } from '../text/text';
import { classes, style } from './tab-button.st.css';

export type TabIcon = () => ReactElement<IconProps>;

export interface TabButtonProps {
    id: string;
    className?: string;
    icon?: TabIcon;
    noIcon?: boolean;
    onClick?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    open?: boolean;
    openIndicator?: React.ReactElement<IconProps>;
    selected?: boolean;
    style?: React.CSSProperties;
    title?: string;
}

export const TabButton: React.FC<TabButtonProps> = ({
    className,
    noIcon,
    onClick,
    onMouseEnter,
    onMouseLeave,
    title,
    icon: propIcon,
    id: tabName,
    open: isOpen,
    openIndicator: propOpenIndicator,
    style: propStyle,
    selected = false,
}) => (
    <Button
        className={style(
            classes.root,
            {
                selected,
                tabName,
            },
            className
        )}
        data-aid={`st_tabbutton_${tabName}`}
        isOpen={isOpen}
        isSelected={selected}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={propStyle}
    >
        {!noIcon && <Icon className={classes.icon}>{propIcon ? propIcon() : <></>}</Icon>}
        {title && (
            <Text className={classes.title} isSelected={selected}>
                {title}
            </Text>
        )}
        {propOpenIndicator}
    </Button>
);
