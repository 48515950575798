import hoistNonReactStatics from 'hoist-non-react-statics';
import React from 'react';

interface OptimisticState<V> {
    value?: V;
    active: boolean;
}

interface OptimisticProps<V> {
    value?: V;
    onChange: (value: V, ...args: any[]) => void;
}

interface ExtraActions {
    [key: string]: (context: any, ...args: any) => void;
}

// The default value resolver works on simple objects and combines them using assign
function DEFAULT_VALUE_RESOLVER<V = string>(target: V | undefined, source: V): V {
    return typeof source === 'object' && typeof target === 'object' ? Object.assign({}, target, source) : source;
}

export function OptimisticWrapper<T extends React.ComponentType<any>, V = string>(
    WrappedComp: T,
    extraActions: ExtraActions,
    blockOnMouseDown = true,
    // Allows setting a resolver function that can combine non-trivial types
    valueResolver: (target: V | undefined, source: V) => V = DEFAULT_VALUE_RESOLVER
): T {
    const ret = class extends React.Component<OptimisticProps<V>, OptimisticState<V>> {
        public state: OptimisticState<V> = { value: undefined, active: false };
        private block = false;

        constructor(props: OptimisticProps<V>) {
            super(props);
            document.addEventListener('mousedown', this.blockOn);
            document.addEventListener('mouseup', this.blockOff);
            document.addEventListener('keydown', this.blockOn);
            document.addEventListener('keyup', this.blockOff);
        }

        public componentWillUnmount() {
            document.removeEventListener('mousedown', this.blockOn);
            document.removeEventListener('mouseup', this.blockOff);
            document.removeEventListener('keydown', this.blockOn);
            document.removeEventListener('keyup', this.blockOff);
        }

        // eslint-disable-next-line react/no-deprecated
        public componentWillReceiveProps() {
            if (this.state.value !== undefined && this.state.active === false) {
                if (!blockOnMouseDown || !this.block) {
                    this.resetValue();
                }
            }
        }
        public render() {
            const actions: any = {};
            Object.keys(extraActions).forEach((key: string) => {
                actions[key] = (...args: any[]) => extraActions[key](this, ...args);
            });
            return (
                <WrappedComp
                    {...this.props}
                    value={this.resolveOptimisticValue()}
                    onChange={this.onChange}
                    {...actions}
                />
            );
        }

        private resolveOptimisticValue = () =>
            this.state.value !== undefined ? valueResolver(this.props.value, this.state.value) : this.props.value;

        public onChange = (value: V, ...args: any[]) => {
            this.props.onChange && this.props.onChange(value, ...args);
            this.setState({ value });
        };

        public setActive = () => {
            this.setState({ active: true });
        };

        public setIdle = () => {
            this.setState({ active: false });
        };

        private blockOff = () => {
            this.block = false;
        };

        private blockOn = () => {
            this.block = true;
        };

        private resetValue = () => {
            this.setState({ value: undefined });
        };
    } as unknown as T;
    hoistNonReactStatics(ret, WrappedComp);
    return ret;
}
