import { BoxShadowVisualizer, ShadowProps } from '@wix/stylable-panel-controllers';
import React, { FC } from 'react';
import { usePanelContext } from '../configurable-panel';
import type { CpControllerCommonProps } from '../cp-controller';
import { generateVisualizerProps } from '../utils/generate-visualizer-props';

export type CpBoxShadowVisualizerProps = CpControllerCommonProps;
export const CpBoxShadowVisualizer: FC<CpBoxShadowVisualizerProps> = ({ editTargetSelector }) => {
    const context = usePanelContext();
    return (
        <BoxShadowVisualizer
            {...generateVisualizerProps<ShadowProps>({ context, editTargetSelector, key: 'CpBoxShadowVisualizer' })}
        />
    );
};
