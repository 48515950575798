import { IconKeywordVisualizerFactory } from '../visualizer-factories/icon-keyword-visualizer-factory/icon-keyword-visualizer-factory';
import {
    AlignmentCenterIcon,
    AlignmentJustifyIcon,
    AlignmentLeftIcon,
    AlignmentRightIcon,
    CapitalizeIcon,
    LowercaseIcon,
    UppercaseIcon,
} from '../controllers/text-visualizer/text-visualizer-icons';
import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';

const textTooltips = StylablePanelTranslationKeys.controller.text.tooltips;

export const TextTransformVisualizer = IconKeywordVisualizerFactory('text-transform', {
    tooltipKey: textTooltips.capitalization,
    MainIcon: CapitalizeIcon,
    options: [
        { id: 'none', displayName: '', icon: CapitalizeIcon },
        { id: 'uppercase', displayName: '', icon: UppercaseIcon },
        { id: 'lowercase', displayName: '', icon: LowercaseIcon },
        // { id: 'capitalize', displayName: '', icon: CapitalizeIcon }
    ],
});

export const TextAlignVisualizer = IconKeywordVisualizerFactory('text-align', {
    tooltipKey: textTooltips.textAlignment,
    MainIcon: AlignmentLeftIcon,
    options: [
        { id: 'left', displayName: '', icon: AlignmentLeftIcon },
        { id: 'center', displayName: '', icon: AlignmentCenterIcon },
        { id: 'right', displayName: '', icon: AlignmentRightIcon },
        { id: 'justify', displayName: '', icon: AlignmentJustifyIcon },
    ],
});
