import { getDimensionInputDefaults } from '@wix/stylable-panel-common';
import {
    DeclarationMap,
    FullDeclarationMap,
    GenericDeclarationMap,
    stringifyBorderImage,
    stringifyTopRightBottomLeft,
} from '@wix/stylable-panel-drivers';
import {
    BorderColors,
    Borders,
    BordersShallow,
    createCssValueAST,
    INITIAL_UNIVERSAL,
    NONE_KEYWORD,
} from '@wix/shorthands-opener';
import type { DeclarationValue, OriginNode } from '../../declaration-types';
import type { DeclarationVisualizerProps, ExtendedGlobalHost } from '../../types';
import { duplicatingEnsureSpace } from '../../utils';
import { BorderType } from './border-visualizer-side-controls';

export interface FullEdgesValue {
    top: string;
    right: string;
    bottom: string;
    left: string;
}

export interface BorderConfig {
    width: string;
    style: string;
    fill: string;
}

export interface BorderOrigins {
    width?: OriginNode | null;
    style?: OriginNode | null;
    fill?: OriginNode | null;
}

export interface EdgesOrigins {
    top?: OriginNode | null;
    right?: OriginNode | null;
    bottom?: OriginNode | null;
    left?: OriginNode | null;
}

export type BorderImageProps =
    | 'border-image-source'
    | 'border-image-slice'
    | 'border-image-width'
    | 'border-image-repeat';

export const DEFAULT_IMAGE_SLICE = '1';
const BORDER_IMAGE_WIDTH_INDEX = 3;

export const stringifyBorder = ({ width, style, fill }: BorderConfig) => [width, style, fill].join(' ').trim();

export const createBorderAST = (value: BorderConfig, origins: BorderOrigins) => {
    const ast: DeclarationValue = createCssValueAST(stringifyBorder(value));
    const { width, style, fill } = origins;
    if (width) {
        ast[0] = width;
    }
    if (style) {
        ast[1] = style;
    }
    if (fill) {
        ast[2] = fill;
    }
    return ast;
};

const createEdgeAST = (edges: FullEdgesValue, origins: EdgesOrigins) => {
    if ((Object.values(origins) as Array<OriginNode | null>).every((origin) => !origin)) {
        return createCssValueAST(stringifyTopRightBottomLeft(edges as unknown as FullDeclarationMap) as string);
    }

    const { top: topOrigin, right: rightOrigin, bottom: bottomOrigin, left: leftOrigin } = origins;
    if (topOrigin && topOrigin === rightOrigin && rightOrigin === bottomOrigin && bottomOrigin === leftOrigin) {
        return [topOrigin];
    }

    const { top, right, bottom, left } = edges;
    const ast: DeclarationValue = createCssValueAST(`${top} ${right} ${bottom} ${left}`);
    if (topOrigin) {
        ast[0] = topOrigin;
    }
    if (rightOrigin) {
        ast[1] = rightOrigin;
    }
    if (bottomOrigin) {
        ast[2] = bottomOrigin;
    }
    if (leftOrigin) {
        ast[3] = leftOrigin;
    }

    return ast;
};

export const createBorderImageASTs = (
    value: Partial<GenericDeclarationMap<BorderImageProps>>,
    widths: FullEdgesValue,
    widthOrigins: EdgesOrigins
) => {
    const borderWidthAST = createEdgeAST(widths, widthOrigins);

    const newWidth =
        value['border-image-width'] ?? stringifyTopRightBottomLeft(widths as unknown as FullDeclarationMap);
    const stringifiedBorderImage = stringifyBorderImage({
        'border-image-source': value['border-image-source'] ?? NONE_KEYWORD,
        'border-image-width': newWidth,
        'border-image-slice': value['border-image-slice'] ?? DEFAULT_IMAGE_SLICE,
        'border-image-repeat': value['border-image-repeat'],
    } as GenericDeclarationMap<BorderImageProps>) as string;
    const borderImageAST: DeclarationValue = createCssValueAST(stringifiedBorderImage);
    if (stringifiedBorderImage !== NONE_KEYWORD) {
        borderImageAST.splice(
            BORDER_IMAGE_WIDTH_INDEX,
            newWidth?.split(' ').length ?? 1,
            ...duplicatingEnsureSpace(borderWidthAST)
        );
    }

    return {
        'border-width': borderWidthAST,
        'border-image': borderImageAST,
    };
};

export enum SelectedBorder {
    None = -1,
    Top = 0,
    Right = 1,
    Bottom = 2,
    Left = 3,
    All = 4,
}

export enum BorderVisualizerStateActionType {
    ToggleLinked = 'toggleLinked',
    SelectBorder = 'selectBorder',
    HandleBlur = 'handleBlur',
    OpenStylePicker = 'openStylePicker',
    CloseStylePicker = 'closeStylePicker',
    ChangeStyle = 'changeStyle',
    OpenFillPicker = 'openFillPicker',
    Calc = 'calc',
}

export interface BorderVisualizerStateAction {
    type: BorderVisualizerStateActionType;
    select?: SelectedBorder;
}

export interface BorderSide extends BorderConfig {
    side: BorderEdges;
}

type BorderImageFullProps = 'border-image' | 'border-image-outset' | BorderImageProps;

export type BorderEdgeProps = 'border-top' | 'border-bottom' | 'border-left' | 'border-right';

type BorderRadiusProps =
    | 'border-radius'
    | 'border-top-left-radius'
    | 'border-top-right-radius'
    | 'border-bottom-right-radius'
    | 'border-bottom-left-radius';

export type BorderLonghandProps = Borders | BordersShallow | BorderEdgeProps;
export type BorderProps = 'border' | BorderLonghandProps | BorderImageFullProps | BorderRadiusProps;
export type BorderShorthandKeys =
    | 'border'
    | BordersShallow
    | 'border-top'
    | 'border-right'
    | 'border-bottom'
    | 'border-left'
    | 'border-image';

export type BorderVisualizerProps = DeclarationVisualizerProps<BorderProps>;

export type BorderDeclarationMap = GenericDeclarationMap<BorderProps>;

export type BorderEdges = 'top' | 'right' | 'bottom' | 'left';

type TopRightBottomLeft = Partial<FullEdgesValue>;

export function getSideValues(
    side: BorderEdges,
    declarationMapValue: BorderDeclarationMap,
    borderType: BorderType,
    panelHost: ExtendedGlobalHost | undefined
): BorderSide {
    const { DEFAULT_WIDTHS, DEFAULT_WIDTH } = getDefaultBorderDimensions(panelHost);
    const borderSideColor =
        borderType === BorderType.Solid
            ? declarationMapValue[`border-${side}-color` as BorderColors]
            : declarationMapValue['border-image-source'];
    let width = getSideWidth(declarationMapValue, borderType)[side];
    if (!width || !!~DEFAULT_WIDTHS.indexOf(width)) {
        width = DEFAULT_WIDTH.value + DEFAULT_WIDTH.unit;
    }
    const sideStyle = getSideStyle(side, declarationMapValue);
    const fill = borderSideColor
        ? borderSideColor !== INITIAL_UNIVERSAL
            ? borderSideColor
            : DEFAULT_COLOR
        : DEFAULT_COLOR;
    return { side, width, style: sideStyle, fill };
}

export function getSideStyle(side: BorderEdges, value?: DeclarationMap) {
    if (!value) {
        return DEFAULT_STYLE;
    }
    const sideStyle = value[`border-${side}-style`];
    if (!sideStyle || sideStyle === INITIAL_UNIVERSAL) {
        return DEFAULT_STYLE;
    }
    return sideStyle;
}

export function getSideWidth(value: DeclarationMap, borderType: BorderType = BorderType.Gradient): TopRightBottomLeft {
    const imageWidth = value['border-image-width'];
    if (imageWidth && imageWidth !== DEFAULT_IMAGE_WIDTH && borderType !== BorderType.Solid) {
        const widths = imageWidth.split(' ');
        if (widths.length > 0) {
            return {
                top: widths[0],
                right: widths[1] || widths[0],
                bottom: widths[2] || widths[0],
                left: widths[3] || widths[1] || widths[0],
            };
        }
    }

    return {
        top: value['border-top-width'],
        right: value['border-right-width'],
        bottom: value['border-bottom-width'],
        left: value['border-left-width'],
    };
}

const DEFAULT_IMAGE_WIDTH = '1';
export const DEFAULT_COLOR = 'currentcolor';
export const DEFAULT_STYLE = 'none';
export const SIDES: BorderEdges[] = ['top', 'right', 'bottom', 'left'];

export const getDefaultBorderDimensions = (panelHost?: ExtendedGlobalHost) => {
    const {
        borderVisualizer: { width: DEFAULT_WIDTH, doubleWidth: DEFAULT_DOUBLE_WIDTH },
        cornerVisualizer: { radius: DEFAULT_EMPTY_CORNERS },
    } = getDimensionInputDefaults(panelHost);
    const EMPTY_WIDTHS = [DEFAULT_WIDTH.value.toString(), DEFAULT_WIDTH.value + DEFAULT_WIDTH.unit];
    const DEFAULT_WIDTHS = [...EMPTY_WIDTHS, 'medium', INITIAL_UNIVERSAL];
    return {
        DEFAULT_WIDTH,
        DEFAULT_DOUBLE_WIDTH,
        EMPTY_WIDTHS,
        DEFAULT_WIDTHS,
        DEFAULT_EMPTY_CORNERS,
    };
};

export const BORDER_LONGHAND_PROPS: BorderLonghandProps[] = [
    'border-width',
    'border-style',
    'border-color',
    'border-top',
    'border-top-width',
    'border-top-style',
    'border-top-color',
    'border-right',
    'border-right-width',
    'border-right-style',
    'border-right-color',
    'border-bottom',
    'border-bottom-width',
    'border-bottom-style',
    'border-bottom-color',
    'border-left',
    'border-left-width',
    'border-left-style',
    'border-left-color',
];

export const BORDER_PROPS_LIST: BorderProps[] = [
    'border',
    ...BORDER_LONGHAND_PROPS,
    'border-image',
    'border-image-source',
    'border-image-slice',
    'border-image-width',
    'border-image-outset',
    'border-image-repeat',
];

export const OUTSIDE_PROPS_LIST: BorderProps[] = [
    'border-radius',
    'border-top-left-radius',
    'border-top-right-radius',
    'border-bottom-right-radius',
    'border-bottom-left-radius',
];

export const EMPTY_IMAGE_BORDER_CHANGE_VALUE = {
    'border-image': '',
    'border-image-source': '',
};

export const BORDER_SHORTHAND_KEYS: BorderShorthandKeys[] = [
    'border',
    'border-style',
    'border-width',
    'border-color',
    'border-top',
    'border-right',
    'border-bottom',
    'border-left',
    'border-image',
];

export const ALL_PROPS_LIST = BORDER_PROPS_LIST.concat(OUTSIDE_PROPS_LIST);
