import type { BorderProps } from '@wix/stylable-panel-controllers';
import { BorderVisualizer } from '@wix/stylable-panel-controllers';
import React, { FC } from 'react';
import { usePanelContext } from '../configurable-panel';
import type { CpControllerCommonProps } from '../cp-controller';
import { generateVisualizerProps } from '../utils/generate-visualizer-props';

export type CpBorderVisualizerCustomProps = {
    noResizing?: boolean;
};
type CpBorderVisualizerProps = CpControllerCommonProps & CpBorderVisualizerCustomProps;
export const CpBorderVisualizer: FC<CpBorderVisualizerProps> = ({ noResizing, editTargetSelector }) => {
    const controllerData = {
        noResizing: noResizing,
    };
    const context = usePanelContext();
    return (
        <BorderVisualizer
            {...generateVisualizerProps<BorderProps>({
                context,
                controllerData,
                editTargetSelector,
                key: 'CpBorderVisualizer',
            })}
        />
    );
};
