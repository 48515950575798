import { DownArrow } from '@wix/stylable-panel-common-react';
import { Button, Icon, Text } from '@wix/stylable-panel-components';
import React, { useRef, useState } from 'react';
import { DisplayModePickerPopup } from './display-mode-picker-popup';
import { classes, style } from './display-mode-picker.st.css';

export interface DisplayModePickerProps {
    displayModePickerTitle: string;
    displayModePickerContent: DisplayModePickerContent;
    handleDisplayModePickerBoxClick?: (box: DisplayModePickerBox) => void;
    isActionOpened: boolean;
    toggleDisplayModePicker?: (displayModePickerOpen: boolean) => boolean;
    className?: string;
    onDisplayModePickerPopupContentMenu?: () => void;
}

export interface DisplayModePickerState {
    displayModePickerOpen: boolean;
}

export interface DisplayModePickerBox {
    id: string;
    label: string;
    selected: boolean;
    backgroundCSS?: string;
    backgroundIcon?: JSX.Element;
}

export interface DisplayModePickerContent {
    title: string;
    content: DisplayModePickerBox[];
}

export const DisplayModePicker: React.FC<DisplayModePickerProps> = ({
    className,
    displayModePickerContent,
    displayModePickerTitle,
    handleDisplayModePickerBoxClick,
    isActionOpened,
    onDisplayModePickerPopupContentMenu,
    toggleDisplayModePicker,
}) => {
    const [displayModePickerOpen, setDisplayModePickerOpen] = useState(false);

    const displayModePicker = useRef<HTMLButtonElement>(null);

    const toggleDisplayModePickerInternal = () => {
        const newDisplayModePickerOpen = toggleDisplayModePicker
            ? toggleDisplayModePicker(displayModePickerOpen)
            : !displayModePickerOpen;
        setDisplayModePickerOpen(newDisplayModePickerOpen);
    };

    const onContextMenu = (event: React.MouseEvent) => {
        onDisplayModePickerPopupContentMenu?.();
        setDisplayModePickerOpen(false);
        event.preventDefault();
    };

    const onBoxClick = (box: DisplayModePickerBox) => {
        handleDisplayModePickerBoxClick?.(box);
        setDisplayModePickerOpen(false);
    };

    return !isActionOpened ? (
        <div className={style(classes.root, className)}>
            <Button
                className={classes.displayModePicker}
                onClick={toggleDisplayModePickerInternal}
                ref={displayModePicker}
            >
                <Text className={classes.displayModePickerTitle}>{displayModePickerTitle}</Text>
                <Icon>
                    <DownArrow className={classes.downArrow} />
                </Icon>
            </Button>
            {displayModePickerOpen && (
                <DisplayModePickerPopup
                    buttonWidth={displayModePicker.current?.getBoundingClientRect().width}
                    displayModePickerContent={displayModePickerContent}
                    onBoxClick={onBoxClick}
                    onContextMenu={onContextMenu}
                />
            )}
        </div>
    ) : null;
};
