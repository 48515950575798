import React, { useCallback } from 'react';

import { Drawer } from '@wix/stylable-panel-components';
import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';

import { TextShadowVisualizer } from '../../generated-visualizers';
import { useTranslate, useVisualizerChange } from '../../hooks';
import { createDeclarationMapFromVisualizerValue, createVisualizerValueFromDeclarationMap } from '../../utils';
import type { OpenedDeclarationArray } from '../../declaration-types';
import type { ShadowProps } from '../../visualizer-factories';

import { Stroke, TextVisualizerProps, TEXT_SHADOW_NONE } from './use-text-visualizer';

import { classes } from './text-visualizer.st.css';
import { strokeShadow, TextVisualizerValue } from './text-visualizer';

export type TextShadowProps = {
    props: TextVisualizerProps;
    stroke: Stroke;
    textShadowVisualizerShown: boolean;
    handleTextShadowVisualizerShownChange: (isShown: boolean) => void;
};

export function TextShadow({
    props,
    stroke,
    textShadowVisualizerShown,
    handleTextShadowVisualizerShownChange,
}: TextShadowProps) {
    const { drivers, siteVarsDriver, panelHost } = props;

    const translate = useTranslate(panelHost);
    const handleTextShadowChange = useVisualizerChange('text-shadow', props);

    const changeTextShadow = useCallback(
        (declarations: OpenedDeclarationArray<ShadowProps>) => {
            if (!handleTextShadowChange) {
                return;
            }

            let shadowValue = createDeclarationMapFromVisualizerValue(declarations as TextVisualizerValue, props)[
                'text-shadow'
            ];

            if (!shadowValue || shadowValue === TEXT_SHADOW_NONE) {
                shadowValue = '';
            }

            const strokeValue = stroke.color ? `${strokeShadow(stroke.color)}` : '';

            let newShadowValue = `${strokeValue}`;
            if (shadowValue) {
                newShadowValue = newShadowValue ? `${newShadowValue}, ${shadowValue}` : shadowValue;
            }

            handleTextShadowChange(newShadowValue);
        },
        [handleTextShadowChange, props, stroke.color]
    );

    const handleOnOpenClick = useCallback(
        () => handleTextShadowVisualizerShownChange(!textShadowVisualizerShown),
        [handleTextShadowVisualizerShownChange, textShadowVisualizerShown]
    );

    return (
        <Drawer
            drawerKey="text-shadow-drawer-inner"
            className={classes.textShadowDrawer}
            open={textShadowVisualizerShown}
            title={translate(StylablePanelTranslationKeys.controller.shadows.title)}
            onOpenClick={handleOnOpenClick}
        >
            {siteVarsDriver?.sheet && (
                <TextShadowVisualizer
                    key="text-shadow"
                    className={classes.textShadowVisualizer}
                    drivers={drivers}
                    siteVarsDriver={siteVarsDriver}
                    value={createVisualizerValueFromDeclarationMap(stroke.value)}
                    panelHost={panelHost}
                    onChange={changeTextShadow}
                />
            )}
        </Drawer>
    );
}
