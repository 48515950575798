import { OVERRIDE_STATE_PREFIX } from '@stylable/webpack-extensions/dist/stylable-forcestates-plugin';
import { DEFAULT_PLANE } from '@wix/stylable-panel-common';
import type { ExtendedGlobalHost } from '@wix/stylable-panel-controllers';
import { ComponentEditSession, StylableEditor, StylableOverride } from '@wix/stylable-panel-drivers';
import React from 'react';
import type { StylePanelPage } from '../panels';
import { setHelpLinkId } from '../utils/stylable-panel-help';
import { EditorPanel, StylePanelExternalProps } from './editor-panel';
import { classes, style } from './wix-panel.st.css';

export interface WixStyleEditorProps extends StylePanelExternalProps {
    onChange: (css: string) => void;
    stylableEditor: StylableEditor;
    className?: string;
    customPanel?: React.FC;
}

export class WixStyleEditor extends React.Component<WixStyleEditorProps> {
    private editor: StylableEditor;
    private noHoverOverride = false;
    private overrideDriver: StylableOverride | undefined;

    constructor(props: WixStyleEditorProps) {
        super(props);

        const { stylableEditor, panelHost } = props;

        this.editor = stylableEditor;
        this.editor.stylableDriver.registerBuildHook(this.onChange, true);

        this.initOverrideDriver();

        panelHost &&
            this.withSelection(({ componentName, selector }) => {
                this.setHelpLink(componentName, selector, panelHost);
            });
    }

    public componentDidUpdate() {
        this.overrideDriver?.override();
        this.noHoverOverride = false;
    }

    public componentWillUnmount() {
        this.editor.stylableDriver.unregisterBuildHook(this.onChange, true);
        this.removeOverrides(false);
        this.overrideDriver?.override();
        this.withSelection((selection) => {
            selection.selector = `.${selection.variant}`;
        });
    }

    public render() {
        const { categoryConfig, className, customPanel, initialView, panelHost, plane = DEFAULT_PLANE } = this.props;
        const selection = this.editor.getSelection(0);

        return selection ? (
            <div className={style(classes.root, className)}>
                <EditorPanel
                    className={classes.editor}
                    editSession={selection}
                    categoryConfig={categoryConfig}
                    panelHost={panelHost}
                    onElementSelect={this.handleElementSelection}
                    onForceState={this.setPartStateOverride}
                    onStateSelect={this.handlePartStateSelect}
                    onRemoveOverrides={this.removeOverrides}
                    onPageChange={this.pageChange}
                    initialView={initialView}
                    plane={plane}
                    customPanel={customPanel}
                />
            </div>
        ) : null;
    }

    public pageChange = (page: StylePanelPage) => {
        this.withSelection((selection) => {
            selection.page = page;
            this.forceUpdate();
        });
    };

    private setHelpLink = (componentName: string, selector: string, panelHost: ExtendedGlobalHost) =>
        setHelpLinkId(`${componentName}${selector}`, panelHost);

    private initOverrideDriver() {
        const { stageRoot } = this.props.panelHost || {};

        if (stageRoot) {
            this.overrideDriver = new StylableOverride(
                stageRoot,
                this.editor.stylableDriver,
                this.editor.stylesheetPath,
                OVERRIDE_STATE_PREFIX
            );
        }
    }

    private onChange = (_css: string, stylesheets: string[]) => {
        const { onChange: onChangeCss } = this.props;

        if (onChangeCss) {
            this.withSelection((selection) => {
                if (stylesheets.includes(selection.stylesheetPath)) {
                    onChangeCss(selection.getSourceCSS());
                }
            });
        }
    };

    private handleElementSelection = (selector: string) => {
        const { panelHost } = this.props;
        this.withSelection((selection) => {
            panelHost && this.setHelpLink(selection.componentName, selector, panelHost);

            const stateUpdated = selection.setSelector(selector);
            stateUpdated && this.removeOverrides();

            panelHost?.onElementSelect?.(selector);
            this.forceUpdate();
        });
    };

    private handlePartStateSelect = (stateSelector: string) => {
        const { panelHost } = this.props;
        const filterAfterStateMatch = stateSelector.match(/.*[^:]:[^:]+/);
        const overrideSelector = filterAfterStateMatch ? filterAfterStateMatch[0] : stateSelector;

        this.overrideDriver?.setSelector(overrideSelector);
        panelHost?.onForceState?.(overrideSelector);

        this.noHoverOverride = true;
        this.withSelection((selection) => {
            // TODO: Figure out if there's a better way to set this (through setSelector, without updateComputed)
            selection.selector = stateSelector;
            this.forceUpdate();
        });
    };

    private removeOverrides = (callParentRevert = true) => {
        const { panelHost } = this.props;
        callParentRevert && panelHost?.revertForceState?.();
        if (this.overrideDriver) {
            this.overrideDriver.setSelector(null);
            this.forceUpdate();
        }
    };

    private setPartStateOverride = (stateOverrideSelector: string | null) => {
        if (this.noHoverOverride) {
            this.noHoverOverride = false;
            return;
        }

        const { panelHost } = this.props;
        const selection = this.editor.getSelection(0);
        const stateSelector = stateOverrideSelector || selection?.selector || '';
        const filterAfterStateMatch = stateSelector.match(/.*[^:]:[^:]+/);
        const overrideSelector = filterAfterStateMatch ? filterAfterStateMatch[0] : stateSelector;

        this.overrideDriver?.setSelector(overrideSelector);
        panelHost?.onForceState?.(overrideSelector);

        this.forceUpdate();
    };

    private withSelection = (callback: (selection: ComponentEditSession) => void) => {
        const selection = this.editor.getSelection(0);
        selection && callback(selection);
    };
}
