import { DropDown, Option } from '@wix/stylable-panel-components';
import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import React from 'react';
import { getTranslate } from '../../hosts/translate';
import type { StylablePanelHost, TranslateFunc } from '../../types';
import type { FontTheme } from '../types/font-types';
import { classes, style } from './font-theme-selector.st.css';

const MAX_FONT_SIZE = 28;
export const CUSTOM_FONT_THEME_ID = 'font_custom';
const getCustomFontThemeOption = (translate: TranslateFunc, value?: string) =>
    ({
        id: CUSTOM_FONT_THEME_ID,
        displayName: `${translate(
            value || StylablePanelTranslationKeys.controller.text.fontThemeSelector.customOption
        )}*`,
    } as Option);

export interface FontThemeSelectorProps {
    value: string;
    fontThemes: FontTheme[];
    panelHost?: StylablePanelHost;
    onSelect?: (itemId: string) => void;
    disabled?: boolean;
    noCloseOnSelect?: boolean;
    className?: string;
    isCustom?: boolean;
}

export class FontThemeSelector extends React.Component<FontThemeSelectorProps> {
    private fontOptions: Option[] = [];

    constructor(props: FontThemeSelectorProps) {
        super(props);
        this.setFontOptions(props);
    }

    public render() {
        const {
            value,
            panelHost,
            onSelect,
            disabled,
            noCloseOnSelect,
            className,
            fontThemes,
            isCustom = false,
        } = this.props;
        const translate = getTranslate(panelHost);
        const trueValue = isCustom ? CUSTOM_FONT_THEME_ID : value;

        return (
            <DropDown
                className={style(classes.root, className)}
                value={trueValue}
                options={this.fontOptions}
                volatileOptions={[
                    getCustomFontThemeOption(
                        translate,
                        fontThemes.find((theme) => theme.cssClass === value)?.displayName
                    ),
                ]}
                customOptionRender={this.renderFontThemeOption}
                onSelect={onSelect}
                disabled={disabled}
                noCloseOnSelect={noCloseOnSelect}
                data-aid="st_font_theme_selector"
            />
        );
    }

    private renderFontThemeOption = (item: Option, index: number) => {
        const { fontThemes } = this.props;

        const { size, style: fontStyle, weight: fontWeight, unit = 'px' } = fontThemes[index];
        const numericSize = Math.round(parseFloat(size));
        const fontSize = numericSize <= MAX_FONT_SIZE ? `${numericSize}px` : `${MAX_FONT_SIZE}px`;

        return (
            <div className={classes.fontThemeOption}>
                <div style={{ fontSize, fontStyle, fontWeight }}>{item.displayName}</div>
                <div className={classes.fontThemeOptionSize}>
                    {numericSize}
                    {unit}
                </div>
            </div>
        );
    };

    private setFontOptions(props: FontThemeSelectorProps) {
        const { fontThemes, panelHost } = props;

        const translate = getTranslate(panelHost);

        this.fontOptions = fontThemes.map((fontTheme) => ({
            id: fontTheme.cssClass,
            displayName: translate(fontTheme.displayName),
        }));
    }
}
