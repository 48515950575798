import type { CompoundParser, GetCompoundParser } from './compound-types';
import type { CompoundsTypeMap } from './compound-css-data';

import { parseBoxShadowCompound, parseTextShadowCompound } from './parsers';

type CompoundMapToParser<M> = {
    [K in keyof M]: GetCompoundParser<M[K]>;
};
type CompoundParsersMap = CompoundMapToParser<CompoundsTypeMap>;

const compoundParsersMap: CompoundParsersMap = {
    'box-shadow': parseBoxShadowCompound,
    'text-shadow': parseTextShadowCompound,
};

export const getCompoundParser = <V, T extends keyof CompoundsTypeMap>(
    prop: T
): CompoundParser<V, CompoundsTypeMap[T]> => {
    const getParser = compoundParsersMap[prop] as GetCompoundParser<CompoundsTypeMap[T]>;
    return getParser<V>();
};
