import type { IconProps } from '@wix/stylable-panel-common-react';
import { SectionDesc, Sections, Tabs, TabsPlane } from '@wix/stylable-panel-components';
import { getTranslate, PanelEventList } from '@wix/stylable-panel-controllers';
import React, { FC, ReactElement } from 'react';
import { usePanelContext } from './configurable-panel';
import type { WhenProps } from './configurable-panel-utils';
import { filterElementChildrenByConditions } from './configurable-panel-utils';
import { classes, style } from './cp-sections.st.css';

type SingleSectionType = ReactElement<CpSectionProps>;
type SectionType = SingleSectionType | Array<SingleSectionType | undefined>;

export interface CpSectionsProps {
    children?: SectionType;
    className?: string;
}

export interface CpSectionProps extends WhenProps {
    id: string;
    children?: ReactElement | ReactElement[];
    className?: string;
    icon?: ReactElement<IconProps>;
    labelKey?: string;
}

export const CpSection: FC<CpSectionProps> = () => {
    return <div></div>;
};

function addSectionToOptions(section: React.ReactElement<CpSectionProps>) {
    const { id, icon } = section.props;
    const item: SectionDesc = { id };

    if (icon) {
        item.customButton = () => icon;
    }

    return item;
}

function transformChildrenToSections(node: SectionType | undefined) {
    const result: Sections = [];

    if (!node) {
        return [];
    }

    if (Array.isArray(node)) {
        node.forEach((element) => {
            element && result.push(addSectionToOptions(element));
        });
    } else {
        result.push(addSectionToOptions(node));
    }

    return result;
}

export const CpSections: FC<CpSectionsProps> = ({ className, children }) => {
    const context = usePanelContext();
    const {
        panelHost,
        panelLayoutConfiguration: { plane },
    } = context;
    const translate = getTranslate(panelHost);

    if (!children) {
        return null;
    }

    const filteredChildren = filterElementChildrenByConditions(children, context, false);
    const sections = transformChildrenToSections(filteredChildren as SectionType);

    const getSectionElementById = (id: string): ReactElement<CpSectionProps> | undefined =>
        React.Children.toArray(children).find((child) => {
            return child && React.isValidElement(child) && child.props.id === id;
        }) as ReactElement;

    const getSectionTitle = (id: string) => {
        const sectionElement = getSectionElementById(id);
        const labelKey = sectionElement?.props.labelKey;
        return labelKey ? translate(labelKey) : '';
    };

    const sectionContent = (id: string) => {
        const children = getSectionElementById(id)?.props.children;
        const filteredChildren = children ? filterElementChildrenByConditions(children, context, false) : undefined;

        return (
            <div className={classes.sectionContainer} key={id} data-aid={`st_categoryeditingpanel_section_${id}`}>
                {filteredChildren}
            </div>
        );
    };

    const onSelectTab = (tabIndex: number, isOpen: boolean | undefined) => {
        const action = isOpen !== undefined ? (isOpen ? 'expand' : 'collapse') : undefined;
        panelHost?.reportBI?.(PanelEventList.CLICK_ON_TAB_IN_PANEL, {
            tab: sections[tabIndex].id,
            action,
        });
    };

    return (
        <div className={style(classes.root, className)}>
            <Tabs
                className={classes.tabComponent}
                content={sectionContent}
                onSelectTab={onSelectTab}
                plane={plane as unknown as TabsPlane}
                // preferredTab={preferredSection}
                sectionIdToTitlesFunction={getSectionTitle}
                sections={sections}
                noSingleTab
                tooltips
            />
        </div>
    );
};
