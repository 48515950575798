import { Text } from '@wix/stylable-panel-components';
import React, { useEffect, useRef, useState } from 'react';
import type { DisplayModePickerBox, DisplayModePickerContent } from './display-mode-picker';
import { classes, style } from './display-mode-picker.st.css';
interface DisplayModePickerPopupProps {
    displayModePickerContent: DisplayModePickerContent;
    onBoxClick: (box: DisplayModePickerBox) => void;
    onContextMenu: (event: React.MouseEvent) => void;
    buttonWidth?: number;
}

const ARROW_SIZE = 10;
const PADDING = 24;

export const DisplayModePickerPopup: React.FC<DisplayModePickerPopupProps> = ({
    buttonWidth,
    displayModePickerContent,
    onBoxClick,
    onContextMenu,
}) => {
    const [popupWidth, setPopupWidth] = useState(0);

    const popupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const popupWidth = popupRef.current?.getBoundingClientRect()?.width;
        popupWidth && setPopupWidth(popupWidth);
    }, [popupRef]);

    const renderDisplayModePickerBox = (box: DisplayModePickerBox) => {
        const { backgroundIcon, id, label, selected, backgroundCSS: backgroundImage } = box;
        return (
            <span key={id} className={classes.boxWrapper}>
                <span
                    className={style(classes.box, { selected })}
                    style={backgroundImage ? { backgroundImage } : {}}
                    onClick={() => onBoxClick(box)}
                >
                    {backgroundIcon}
                </span>
                <Text className={classes.boxText}>{label}</Text>
            </span>
        );
    };

    const getMarginLeft = () => {
        const offset = (PADDING + ARROW_SIZE) / 2;
        return buttonWidth && popupWidth ? `-${popupWidth / 2 - buttonWidth + offset}px` : 0;
    };

    return (
        <div
            className={classes.displayModePickerPopup}
            onContextMenu={onContextMenu}
            ref={popupRef}
            style={buttonWidth && popupWidth ? { marginLeft: getMarginLeft() } : {}}
        >
            <div className={classes.displayModePickerTextWrapper}>
                <Text className={classes.displayModePickerText}>{displayModePickerContent.title}</Text>
            </div>
            <div className={classes.boxesContainer}>
                {displayModePickerContent.content.map(renderDisplayModePickerBox)}
            </div>
            <span className={classes.arrow} />
        </div>
    );
};
