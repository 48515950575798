import { CompositeBlock } from '@wix/stylable-panel-components';
import { applyMixin, removeMixin, StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import React, { useCallback } from 'react';
import { FontTheme, FontThemeSelector } from '../../components';
import { SaveCustomTheme } from '../../components/font-theme-selector/save-theme';
import { useTranslate, useVisualizerChange } from '../../hooks';
import type { ExtendedGlobalHost } from '../../types';
import type { TextDeclarationMap, TextVisualizerProps } from './use-text-visualizer';
import { classes, style } from './text-visualizer.st.css';

export type FontThemeBlockProps = Pick<ExtendedGlobalHost, 'saveTheme'> & {
    value: string;
    props: TextVisualizerProps;
    availableFontThemes: FontTheme[];
    declarationMapValue: TextDeclarationMap;
    handleFontThemeChange: (newFontTheme: string) => void;
    isCustomTheme: boolean;
    setIsCustomTheme: Function;
    className?: string;
};

export function FontThemeBlock({
    value,
    props,
    availableFontThemes,
    handleFontThemeChange,
    declarationMapValue,
    setIsCustomTheme,
    isCustomTheme,
    saveTheme,
    className,
}: FontThemeBlockProps) {
    const { panelHost } = props;
    const fixTextThemeBehaviorExperiment = panelHost?.experiments?.fixTextThemeBehavior;

    const translate = useTranslate(panelHost);
    const handleMixinChange = useVisualizerChange('-st-mixin', props);

    const onSelectFontTheme = useCallback(
        (newFontTheme: string) => {
            if (!handleMixinChange) {
                return;
            }
            setIsCustomTheme(false);
            handleFontThemeChange(newFontTheme);
            if (!fixTextThemeBehaviorExperiment) {
                handleMixinChange(applyMixin(newFontTheme, removeMixin(value, declarationMapValue['-st-mixin'])));
            }
        },
        [
            handleMixinChange,
            setIsCustomTheme,
            handleFontThemeChange,
            fixTextThemeBehaviorExperiment,
            value,
            declarationMapValue,
        ]
    );
    const handleShowTooltip = useCallback(
        () =>
            isCustomTheme
                ? {}
                : {
                      information: translate(
                          StylablePanelTranslationKeys.controller.text.fontThemeSelector.informationTooltip
                      ),
                  },
        [isCustomTheme, translate]
    );

    const handleSaveTheme = () => {
        saveTheme?.({}) || console.warn('No saveTheme was provided to <FontThemeSelector />');
    };
    return (
        <CompositeBlock
            key="font-theme"
            className={style(className, classes.controllerBlock)}
            title={translate(StylablePanelTranslationKeys.controller.text.fontThemeSelector.label)}
            divider
            {...handleShowTooltip()}
        >
            {panelHost?.experiments?.saveTheme && isCustomTheme && (
                <SaveCustomTheme
                    buttonText={translate(StylablePanelTranslationKeys.controller.text.fontThemeSelector.saveTheme)}
                    onSave={handleSaveTheme}
                />
            )}
            <FontThemeSelector
                className={classes.dropDown}
                value={value}
                fontThemes={availableFontThemes}
                panelHost={panelHost}
                onSelect={onSelectFontTheme}
                isCustom={isCustomTheme}
            />
        </CompositeBlock>
    );
}
