export { PanelComponentsMetadata } from '@wix/stylable-panel-common';

export type DeclarationMap = Record<string, string | undefined>;
export type GenericDeclarationMap<PROPS extends string = string> = Record<PROPS, string | undefined>;
export type FullDeclarationMap = Record<string, string>;
export type BlockVariantSet = DeclarationMap[];
export type SelectorSet = Record<string, DeclarationMap>;

export interface Archetype {
    props: Array<string | string[]>;
    propBlacklist?: Array<string | RegExp>;
    preferred?: string;
    blockVariantSets?: Record<string, BlockVariantSet>; // keys: section id's, for each section a custom archetype
}
export type ArchetypeList = Record<string, Archetype>;

export interface DynamicPartControllerMatch {
    name: string;
    params?: string;
}
export interface DynamicPartControllerSelector {
    controllersMatch: DynamicPartControllerMatch[];
    decls: DeclarationMap;
}
export interface DynamicPartInputSelector {
    params?: string;
    decls: DeclarationMap;
}
export type DynamicPartControllerSelectorValues = Record<string, DynamicPartControllerSelector>;
export type DynamicPartInputSelectorValues = Record<string, DynamicPartInputSelector>;

export type CssModifier = (css: string) => string;
export type TransformationPlugins = CssModifier[];

export type EvalOverrides = Record<string, string>;
export type EvalDeclarationValue = (value: string | undefined, overrides?: EvalOverrides) => string;

/**
 * @deprecated newElementTree is open for all users.
 */
export interface StylablePanelDriversExperiments /*extends Record<string, boolean | undefined> */ {
    newElementree?: boolean;
}

export interface ElementTree {
    [selector: string]: ElementTree;
}
