import {
    COLOR_ADDER_HSB,
    COLOR_ADDER_HSB_HUE,
    COLOR_ADDER_RGB,
    DimensionUnits,
    DIMENSION_ID,
    DIMENSION_INPUT_DEFAULTS,
} from '@wix/stylable-panel-common';
import chroma from 'chroma-js';
import React, { createRef } from 'react';
import { Button } from '../button/button';
import { DimensionInput, ZERO_STR } from '../dimension-input';
import { TranslateDriver } from '../drivers/translate-driver';
import { Tabs } from '../tabs/tabs';
import { Text } from '../text/text';
import { classes, style } from './color-adder.st.css';
import { colorFromHSVColor, HSVColor, hsvColorFromColor, isWhite } from './color-picker-utils';
import FreeformPicker from './freeform-picker';

export interface ColorAdderStrings {
    hexTabLabel: string;
    rgbTabLabel: string;
    hsbTabLabel: string;
    cancelButtonLabel: string;
    addButtonLabel: string;
}

const ColorAdderStringsFallbacks: ColorAdderStrings = {
    hexTabLabel: 'HEX',
    rgbTabLabel: 'RGB',
    hsbTabLabel: 'HSB',
    cancelButtonLabel: 'Cancel',
    addButtonLabel: 'Add',
};

export enum ColorAdderTabs {
    HEX = 0,
    RGB = 1,
    HSB = 2,
}

interface ColorAdderSingleSectionConfig {
    id: string;
    titleKey: keyof ColorAdderStrings;
}

const ColorAdderSectionConfig: Record<ColorAdderTabs, ColorAdderSingleSectionConfig> = {
    [ColorAdderTabs.HEX]: { id: 'hex', titleKey: 'hexTabLabel' },
    [ColorAdderTabs.RGB]: { id: 'rgb', titleKey: 'rgbTabLabel' },
    [ColorAdderTabs.HSB]: { id: 'hsb', titleKey: 'hsbTabLabel' },
};

const DEFAULT_COLORS = DIMENSION_INPUT_DEFAULTS.colorAdder;

const tabSections = Object.keys(ColorAdderSectionConfig).map((_key: string, currTab: ColorAdderTabs) => ({
    id: ColorAdderSectionConfig[currTab].id,
    titleKey: ColorAdderSectionConfig[currTab].titleKey,
}));

export interface ColorAdderProps {
    className?: string;
    'data-aid'?: string;
    initialColor?: string;
    onAdd?: (value: string) => void;
    onCancel?: () => void;
    style?: React.CSSProperties;
    translations?: Partial<ColorAdderStrings>;
    units?: DimensionUnits;
    translate?: (key: string) => string;
    showDimensionErrors?: boolean;
    translationKeys?: Record<string, Record<string, string>>;
}

export interface ColorAdderState {
    color: HSVColor;
    editingHex: string;
    hexError: boolean;
}

export default class ColorAdder extends React.Component<ColorAdderProps, ColorAdderState> {
    public readonly state: ColorAdderState = {
        color: DEFAULT_COLORS.hsb,
        editingHex: DEFAULT_COLORS.hex,
        hexError: false,
    };
    private hexInput = createRef<HTMLInputElement>();
    private initialColor!: chroma.Color;
    private translateDriver: TranslateDriver<ColorAdderStrings>;

    constructor(props: ColorAdderProps) {
        super(props);
        // TODO: get rid of the translateDriver
        this.translateDriver = new TranslateDriver<ColorAdderStrings>(ColorAdderStringsFallbacks, props.translations);
        this.initialColor = chroma(DEFAULT_COLORS.hex);
        if (props.initialColor) {
            try {
                this.initialColor = chroma(props.initialColor);
            } catch {
                //
            }
        }

        this.state = {
            color: hsvColorFromColor(this.initialColor) || DEFAULT_COLORS.hsb,
            editingHex: this.getEditingHex(this.initialColor) || DEFAULT_COLORS.hex,
            hexError: false,
        };
    }

    private getSectionTitle = (currTab: string) => {
        const { translate } = this.translateDriver;
        const titleKey = tabSections.find((value) => value.id === currTab)?.titleKey;
        return titleKey ? translate(titleKey) : '';
    };

    public render() {
        const { onCancel, onAdd, className, style: propStyle } = this.props;
        const { color, hexError } = this.state;
        const { translate } = this.translateDriver;

        const newColor = colorFromHSVColor(color);

        return (
            <div className={style(classes.root, className)} style={propStyle} data-aid={this.props['data-aid']}>
                <FreeformPicker className={classes.freeformPicker} color={color} onChange={this.handleFreeformChange} />
                <div className={classes.colorCompare}>
                    <span
                        className={style(
                            classes.colorComparePart,
                            {
                                white: isWhite(this.initialColor),
                            },
                            classes.colorCompareCurrent
                        )}
                        style={{ backgroundColor: this.initialColor.hex('rgb') }}
                        onClick={this.resetInitialColor}
                    />
                    <span
                        className={style(
                            classes.colorComparePart,
                            {
                                white: isWhite(newColor),
                            },
                            classes.colorCompareNew
                        )}
                        style={{ backgroundColor: newColor.hex('rgb') }}
                    />
                </div>
                <Tabs
                    className={classes.tabs}
                    sections={tabSections}
                    content={this.renderSelectedTabInputs}
                    sectionIdToTitlesFunction={this.getSectionTitle}
                    preferredTab={ColorAdderSectionConfig[ColorAdderTabs.HEX].id}
                    onSelectTab={(index) => this.selectTab(index as ColorAdderTabs)}
                    noScroll
                />
                <div className={classes.footer}>
                    <Button className={classes.cancelButton} onClick={onCancel}>
                        <Text className={classes.cancelButtonText}>{translate('cancelButtonLabel')}</Text>
                    </Button>
                    <Button
                        className={classes.addButton}
                        onClick={() => onAdd?.(this.getAddFormat(colorFromHSVColor(color)))}
                        disabled={hexError}
                        data-aid="st_coloradder_addbutton"
                    >
                        <Text className={classes.addButtonText}>{translate('addButtonLabel')}</Text>
                    </Button>
                </div>
            </div>
        );
    }

    private resetInitialColor = () => {
        this.setState({
            color: hsvColorFromColor(this.initialColor),
            editingHex: this.getEditingHex(this.initialColor),
        });
    };

    private renderSelectedTabInputs = (id: string) => {
        const { translate, translationKeys, units, showDimensionErrors } = this.props;
        const { color: hsvColor, editingHex, hexError } = this.state;
        const color = colorFromHSVColor(hsvColor);

        switch (id) {
            case ColorAdderSectionConfig[ColorAdderTabs.HEX].id:
                return (
                    <input
                        className={style(classes.textInput, { error: hexError })}
                        type="text"
                        value={editingHex}
                        onChange={(event) => this.setColorHex(event.target.value)}
                        onFocus={(event) => event.target.select()}
                        spellCheck={false}
                        ref={this.hexInput}
                        data-aid="st_coloradder_input"
                    />
                );
            case ColorAdderSectionConfig[ColorAdderTabs.RGB].id:
                return ['r', 'g', 'b'].map((channel) => (
                    <DimensionInput
                        key={`rgb_${channel}_input`}
                        className={classes.inputElement}
                        value={color.get(`rgb.${channel}`).toString()}
                        config={{ units: units?.[DIMENSION_ID.COLOR_ADDER_RGB] || COLOR_ADDER_RGB }}
                        keepRange={true}
                        isInputCyclic={true}
                        onChange={(value) => this.setColorRgb(channel, value)}
                        inputDataAid="st_coloradder_input"
                        showUnitErrors={showDimensionErrors}
                        translate={translate}
                        translationKeys={translationKeys}
                    />
                ));
            case ColorAdderSectionConfig[ColorAdderTabs.HSB].id:
                return Object.keys(hsvColor).map((channel) => (
                    <DimensionInput
                        key={`rgb_${channel}_input`}
                        className={classes.inputElement}
                        value={Math.round(hsvColor[channel as keyof HSVColor]).toString()}
                        config={{
                            units:
                                channel === 'hue'
                                    ? units?.[DIMENSION_ID.COLOR_ADDER_HSB_HUE] || COLOR_ADDER_HSB_HUE
                                    : units?.[DIMENSION_ID.COLOR_ADDER_HSB] || COLOR_ADDER_HSB,
                        }}
                        keepRange={true}
                        isInputCyclic={true}
                        onChange={(value) => this.setColorHsb(channel as keyof HSVColor, value)}
                        inputDataAid="st_coloradder_input"
                        showUnitErrors={showDimensionErrors}
                        translate={translate}
                        translationKeys={translationKeys}
                    />
                ));
        }

        return;
    };

    private handleFreeformChange = (color: HSVColor) => {
        this.setState({
            color,
            editingHex: this.getEditingHex(colorFromHSVColor(color)),
        });
    };

    private selectTab(tab: ColorAdderTabs) {
        const newState = { ...this.state };

        if (tab === ColorAdderTabs.HEX) {
            newState.editingHex = this.getEditingHex(colorFromHSVColor(this.state.color));
        } else {
            newState.hexError = false;
        }

        this.setState(newState);
    }

    private setColorHex = (value: string) => {
        const newState = { ...this.state };

        if (value.startsWith('#')) {
            newState.editingHex = value.slice(1, 7);
        } else {
            newState.editingHex = value.slice(0, 6);
        }
        try {
            newState.color = hsvColorFromColor(chroma(value));
            newState.hexError = false;
        } catch {
            newState.hexError = true;
        }

        this.setState(newState);
    };

    private setColorRgb(channel: string, value?: string) {
        this.setState({
            color: hsvColorFromColor(colorFromHSVColor(this.state.color).set(`rgb.${channel}`, value || ZERO_STR)),
        });
    }

    private setColorHsb(channel: keyof HSVColor, value?: string) {
        const newState = this.state;

        newState.color[channel] = parseInt(value || ZERO_STR, 10);

        this.setState(newState);
    }

    private getAddFormat(color: chroma.Color) {
        return color.hex('rgb').toUpperCase();
    }

    private getEditingHex(color: chroma.Color) {
        return color.hex('rgb').slice(1).toUpperCase();
    }
}
