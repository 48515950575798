import type { GenericDeclarationMap } from '@wix/stylable-panel-drivers';
import type { DeclarationVisualizerProps } from '../types';
import { controllerToVisualizerChange } from '../utils';

export function useVisualizerChange<PROP extends string>(
    mainProp: PROP,
    props: DeclarationVisualizerProps<PROP>
): ((value: string | undefined) => void) | undefined {
    const { onChange } = props;

    return onChange
        ? (value: string | undefined) => {
              onChange(
                  controllerToVisualizerChange(
                      {
                          [mainProp]: value,
                      } as GenericDeclarationMap<PROP>,
                      props
                  )
              );
          }
        : undefined;
}
