import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import type { SingleCategoryConfig } from '../../../../types';

const boxShadowColor = 'rgba(0, 0, 0, 0.1)';

export const shadowBlockVariants: SingleCategoryConfig = {
    titleKey: StylablePanelTranslationKeys.controller.shadows.title,
    blockVariantSet: [
        { 'box-shadow': 'none' },

        // ZZZ (zz's zeplin)
        { 'box-shadow': `0 0 26px 10px ${boxShadowColor}` },

        // {'box-shadow': `0 29px 42px -12px ${boxShadowColor}`},
        { 'box-shadow': `-0.24px 14px 42px -12px ${boxShadowColor}` },

        { 'box-shadow': `0 10px 30px 0 ${boxShadowColor}` },

        // {'box-shadow': `0 44px 26px -22px ${boxShadowColor}`},
        { 'box-shadow': `0 20px 18px -10px ${boxShadowColor}` },

        { 'box-shadow': `23px 15px 20px -10px ${boxShadowColor}` },
        { 'box-shadow': `-16px 17px 11px -12px ${boxShadowColor}` },

        { 'box-shadow': `0 0 30px 2px rgba(0, 0, 0, 0.07)` },
        { 'box-shadow': `0 16px 50px -12px rgba(211, 0, 0, 0.15)` },
        { 'box-shadow': `0 42px 26px 1px ${boxShadowColor}` },
        { 'box-shadow': `29px -29px 32px -1px rgba(0, 0, 0, 0.04)` },
        { 'box-shadow': `0 6px 10px 0 ${boxShadowColor}` },
        { 'box-shadow': `-19px -19px 16px -14px ${boxShadowColor}` },

        // {'box-shadow': `50px 0 24px -50px ${boxShadowColor}, -50px 0 24px -50px ${boxShadowColor}, 0 32px 24px -25px ${boxShadowColor}`},
        {
            'box-shadow': `12px 6px 5px -10px ${boxShadowColor}, -13px 0 14px -10px ${boxShadowColor}, 0 1px 6px ${boxShadowColor}`,
        },

        // generic inset shadows (todo: style)
        { 'box-shadow': `inset 0 6px 10px 0 ${boxShadowColor}` },
        { 'box-shadow': `inset 0px 1px 6px 4px ${boxShadowColor}` },

        // sandbox variants
        { 'box-shadow': '2px 2px 2px rgba(0, 0, 0, 0.4)' },
        { 'box-shadow': `0 8px 7px 4px ${boxShadowColor}` },
        { 'box-shadow': '-2px -2px 0 rgba(245, 81, 95, 0.4)' },
        { 'box-shadow': '0 25px 50px -12px rgba(211, 0, 0, 0.15)' },
        { 'box-shadow': '0 25px 30px -12px rgba(29, 44, 243, 0.15)' },
        { 'box-shadow': '-3px -3px 0 white,-4px -4px 0 #e7e7e7' },
        { 'box-shadow': '0px 6px 0px -3px rgba(0, 0, 0, 0.4)' },
        { 'box-shadow': '-5px 5px 0 value(site_2_1), -10px 10px 0 value(site_3_2), -15px 15px 0 value(site_4_2)' },
        { 'box-shadow': '4px 4px 0px value(site_3_2),-4px -4px 0px value(site_2_2)' },
        { 'box-shadow': '0 2px 8px 1px value(site_2_1)' },
        { 'box-shadow': '-2px -2px 0 value(site_1_4)' },
        { 'box-shadow': `-11.31px -11.31px 19px -6px ${boxShadowColor}` },

        {
            'box-shadow':
                '0 0 0 1px value(site_3_3), 0 0 0 7px value(site_1_1), 0 0 0 14px value(site_4_3), 0 0 0 21px value(site_1_1), 0 0 0 28px value(site_5_3)',
        },
        { 'box-shadow': '0 0 0 7px value(site_3_3), 0 0 0 21px value(site_1_1), 0 0 0 28px value(site_5_3)' },
        { 'box-shadow': '0 0 0 7px value(site_3_3), 0 0 0 21px value(site_5_3)' },
    ],
    props: [/box-shadow/],
    optional: [],
};

export default shadowBlockVariants;
