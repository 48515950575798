import {
    ClassSymbol,
    createSubsetAst,
    evalDeclarationValue,
    isCSSVarProp,
    matchSelectorTarget,
    scopeCSSVar,
    StylableMeta,
    StylableSymbol,
    StylableTransformer,
    VarSymbol,
} from '@stylable/core';
import { applyStylableForceStateSelectors } from '@stylable/webpack-extensions/dist/stylable-forcestates-plugin';
import * as postcss from 'postcss';
import { getNodeId } from './mutable-ast';
import {
    doesEndWithState,
    findRuleByClosestAncestor,
    findRuleByDeepness,
    findRuleBySameDeepness,
} from './rule-order-utils';
import type { EvalOverrides, StylablePanelDriversExperiments, TransformationPlugins } from './types';

export interface Scope {
    name: string;
    params: string;
}

export interface Var {
    name: string;
    value: string;
}

export interface ImportRequest {
    path: string;
    named?: string[];
    requestedDefaultImportName?: string;
}

export interface ImportData {
    from: string;
    default: string;
    named?: Record<string, string>;
}

interface SelectorRef {
    selector: string;
    index: number;
}

// TODO: Get from Stylable (nativePseudoElements)
export const NATIVE_STATES = ['hover', 'focus', 'disabled', 'active'];

const NATIVE_STATES_BY_ORDER = ['hover', 'active', 'focus', 'disabled'];

const { hasOwnProperty } = Object.prototype;

let counter = 0;

const appendNamedToRule = (rule: postcss.Rule, originalNamed?: Record<string, string>, newNamed?: string[]) => {
    // Remove previous named if exists
    if (rule.nodes) {
        const named = rule.nodes.find((decl) => decl.type === 'decl' && decl.prop === '-st-named')!;
        if (named) {
            rule.removeChild(named);
        }
    }

    // Add original named
    const resultNamed: Record<string, string> = originalNamed ? { ...originalNamed } : {};

    // Add new named
    if (newNamed) {
        newNamed.forEach((name) => {
            // Check if new name already exists (AS SOURCE), if not - add it
            if (!Object.keys(resultNamed).find((key) => resultNamed[key] === name)) {
                // Name not found - add name
                resultNamed[name] = name;
            }
        });
    }

    if (Object.keys(resultNamed).length > 0) {
        const namedValue = Object.keys(resultNamed)
            .map((key) => {
                if (key !== resultNamed[key]) {
                    return `${resultNamed[key]} as ${key}`;
                }
                return key;
            })
            .join(', ');

        const namedStatement = postcss.decl({
            prop: '-st-named',
            value: namedValue,
        });

        rule.append(namedStatement);
    }
    return resultNamed;
};

// TODO: move to language-service-tools?
const generateNewImport = (defaultImport: string, path: string): postcss.Rule => {
    const rule = postcss.rule({ selector: ':import' });

    const fromStatement = postcss.decl({
        prop: '-st-from',
        value: `'${path}'`,
    });

    const defaultStatement = postcss.decl({
        prop: '-st-default',
        value: defaultImport,
    });

    rule.append(fromStatement);
    rule.append(defaultStatement);

    return rule;
};

const parseScope = (scope: string) => {
    const scopeMatch = scope.match(/@(\S+)\s*(.*)/);
    if (!scopeMatch) {
        return undefined;
    }

    return { name: scopeMatch[1], params: scopeMatch[2] } as Scope;
};
const isStScope = (scope: string) => scope.startsWith('@st-scope');

export class StylesheetDriver {
    constructor(
        public getMeta: () => StylableMeta,
        public updateFromAST: (modified: postcss.Node, cb?: () => void) => number,
        private getTransformer: () => StylableTransformer,
        private scope?: string,
        private transformationPlugins?: TransformationPlugins,
        private experiments: StylablePanelDriversExperiments = {}
    ) {}

    public get path() {
        return this.getMeta().source;
    }
    public get AST(): postcss.Root {
        return this.getMeta().rawAst;
    }
    public get processedAST(): postcss.Root {
        return this.getMeta().ast;
    }
    public get outputAst(): postcss.Root | undefined {
        return this.getMeta().outputAst;
    }
    public get namespace() {
        return this.getMeta().namespace;
    }
    public get source() {
        return this.AST.toString();
    }
    public getActiveScopes() {
        return new Set<string>(this.scope ? [this.scope] : undefined);
    }
    public getExperiments() {
        return this.experiments;
    }
    public walkRules(
        callback: (rule: postcss.Rule, index?: number) => any,
        ast: postcss.Root = this.AST,
        scope: string | undefined = this.scope,
        onlyScope = false
    ): boolean | void {
        let scopeAtRules: postcss.AtRule[] = [];
        if (scope) {
            scopeAtRules = this.queryScopeRules(scope, ast) as postcss.AtRule[];
        }

        return ast.walkRules((rule) => {
            if ((ast === this.processedAST || ast === this.outputAst) && this.isProcessedStScopeRule(rule)) {
                if (!scope) {
                    return;
                }
                const parsedScope = parseScope(scope);
                if (!parsedScope || !rule.selector.startsWith(parsedScope.params)) {
                    return;
                }
            }
            if (
                (onlyScope && rule.parent === ast) ||
                (rule.parent !== ast &&
                    (scopeAtRules.length <= 0 || !scopeAtRules.find((scopeRule) => scopeRule === rule.parent)))
            ) {
                return;
            }

            return callback(rule);
        });
    }

    // TODO move import logic to import driver
    public getImportByPath(path: string) {
        const { imports } = this.getMeta();
        for (const currentImport of imports) {
            if (currentImport.from === path) {
                const importData: ImportData = Object.assign(
                    {
                        from: path,
                        default: currentImport.defaultExport,
                    },
                    Object.keys(currentImport.named).length > 0 ? { named: currentImport.named } : {}
                );
                return importData;
            }
        }
        return null;
    }

    public upsertImport({ path, named, requestedDefaultImportName = 'Import' }: ImportRequest): ImportData {
        let importData = this.getImportByPath(path);
        if (importData && !named) {
            return importData;
        }

        const ast = this.AST;
        let defaultImport;
        let rule: postcss.Rule;

        if (importData) {
            // Get original rule if exists:
            defaultImport = importData.default;
            rule = this.queryImportRule(path)!;

            ast.removeChild(rule); // TODO maybe can remove
        } else {
            // new rule:
            // TODO try first requestedDefaultImportName or generate name from path and add counter if name is not available in context
            defaultImport = requestedDefaultImportName + counter++;
            rule = generateNewImport(defaultImport, path);
        }

        const newNamed = appendNamedToRule(rule, (importData && importData.named) || undefined, named);

        ast.prepend(rule);
        this.updateFromAST(rule);
        importData = {
            from: path,
            default: defaultImport,
            named: newNamed,
        };

        return importData;
    }

    public queryStyleRule(selector: string, scope: string | undefined = this.scope, onlyScope = false) {
        const result: postcss.Rule[] = [];
        this.walkRules(
            (rule) => {
                if (rule.selector === selector) {
                    result.push(rule);
                }
            },
            this.AST,
            scope,
            onlyScope
        );
        return result;
    }

    public queryStyleRuleWithScopeFilter(selector: string, useScope = true) {
        return this.queryStyleRule(selector, useScope ? this.scope : '', useScope ? !!this.scope : false);
    }

    public queryImportRule(path: string): postcss.Rule | undefined {
        let result;
        this.walkRules((rule) => {
            if (rule.selector !== ':import') {
                return;
            }

            if (
                rule.nodes &&
                rule.nodes.find((decl) => {
                    return (
                        decl.type === 'decl' &&
                        decl.prop === '-st-from' &&
                        decl.value.replace(/['"]+/g, '').replace(/^\./, '') === path
                    );
                })
            ) {
                result = rule;
            }
        });
        return result;
    }

    public upsertStyleRule(selector: string, placeInOrder = false, useScope = true) {
        const result = this.queryStyleRuleWithScopeFilter(selector, useScope);
        if (result.length !== 0) {
            return result;
        }

        const parent = !useScope || !this.scope ? this.AST : this.upsertScopeRule(this.scope);
        if (!parent) {
            return result;
        }

        const newRule = postcss.rule({ selector });
        if (!placeInOrder) {
            parent.append(newRule);
        } else {
            const selectors: SelectorRef[] = [];

            for (let index = 0; index < parent.nodes.length; index++) {
                const node = parent.nodes[index];
                if (node.type === 'rule') {
                    selectors.push({ selector: node.selector, index });
                }
            }

            const prevRule = this.findRuleIndex(selector, selectors);
            parent.insertAfter(prevRule, newRule);
        }

        result.push(newRule);

        return result;
    }

    public queryScopeRules(scope: string, ast: postcss.Root = this.AST) {
        if (ast === this.AST || !isStScope(scope)) {
            const result: postcss.AtRule[] = [];
            ast.walkAtRules((rule) => {
                if (scope === `@${rule.name} ${rule.params}`) {
                    result.push(rule);
                }
            });
            return result;
        } else {
            /**TODO: THIS BROKEN! */
            const result: postcss.Rule[] = [];

            const parsedScope = parseScope(scope);
            if (!parsedScope) {
                return result;
            }

            ast.walkRules((rule) => {
                if (rule.selector.startsWith(parsedScope.params)) {
                    result.push(rule);
                }
            });
            return result;
        }
    }

    public upsertScopeRule(scope: string) {
        const result = this.queryScopeRules(scope);
        if (result.length > 0) {
            /** TODO: this needs to find the matching atRule */
            return result[result.length - 1];
        }

        const parsedScope = parseScope(scope);
        if (!parsedScope) {
            return undefined;
        }

        const { name, params } = parsedScope;
        const newScopeRule = postcss.atRule({ name, params, nodes: [] });
        this.AST.append(newScopeRule);

        return newScopeRule;
    }

    public removeRule(rule: postcss.Rule) {
        this.withAST((ast) => ast.removeChild(rule));
    }

    public addRule(rule: postcss.Rule) {
        this.withAST((ast) => ast.append(rule));
    }

    public getSymbol(name: string) {
        return this.getMeta().mappedSymbols[name];
    }

    public getSymbolType(name: string): string {
        const symbol = this.getSymbol(name);
        return symbol ? symbol._kind : '';
    }

    public resolveSymbolType(symbol: StylableSymbol) {
        if (!symbol) {
            return '';
        }

        const transformer = this.getTransformer();
        if (!transformer) {
            return '';
        }

        if (symbol._kind === 'import') {
            const resolved = transformer.resolver.deepResolve(symbol);
            if (resolved && resolved.symbol) {
                return resolved._kind === 'js' ? 'js' : resolved.symbol._kind;
            }
        }

        return symbol._kind;
    }

    public upsertDeclarationToRule(selector: string, decl: { prop: string; value?: string }) {
        const result = this.queryStyleRule(selector, this.scope, !!this.scope);
        if (result.length > 0) {
            const rule: postcss.Rule = result[!this.scope ? 0 : result.length - 1];
            const foundProp = rule.nodes.find((declaration) => (declaration as postcss.Declaration).prop === decl.prop);

            if (decl.value) {
                if (this.transformationPlugins) {
                    decl.value = this.transformationPlugins.reduce(
                        (previousValue, currentTransformation) => currentTransformation(previousValue),
                        decl.value
                    );
                }
                if (foundProp) {
                    foundProp.replaceWith(postcss.decl(decl as postcss.DeclarationProps));
                } else {
                    rule.append(postcss.decl(decl as postcss.DeclarationProps));
                }
                this.updateFromAST(rule);
            } else if (foundProp && decl.value === undefined) {
                foundProp.remove();
                this.updateFromAST(rule);
            }
        }
    }

    public upsertExtends(selector: string, componentType: string) {
        this.upsertDeclarationToRule(selector, {
            prop: '-st-extends',
            value: componentType,
        });
    }

    public extractDeclarations(
        fromRule: postcss.Rule,
        toRule: postcss.Rule,
        filter: (keyName: string) => boolean = () => true
    ) {
        this.moveDeclarations(fromRule, toRule, filter);
        this.updateFromAST(fromRule); // ToDo: change writeFile to accept a list of modified nodes
        this.updateFromAST(toRule);
    }

    public extractToRule(
        fromRule: postcss.Rule,
        newRulename: string,
        filter: (keyName: string) => boolean = () => true,
        destRule: postcss.Rule | null = null
    ) {
        this.withAST((ast) => {
            const newRule = postcss.rule({ selector: newRulename });
            this.moveDeclarations(fromRule, newRule, filter);
            ast.insertBefore(destRule || fromRule, newRule);
        });
    }

    public replaceRule(
        fromRule: postcss.Rule,
        destRule: postcss.Rule,
        filter: (keyName: string) => boolean = () => true
    ) {
        this.withAST((ast) => {
            const newRule = postcss.rule({ selector: destRule.selector });
            this.moveDeclarations(fromRule, newRule, filter);
            ast.insertBefore(destRule, newRule);
            ast.removeChild(destRule);
        });
    }

    public getNodeById(id: number): postcss.Declaration | postcss.Rule | null {
        let foundNode: postcss.Declaration | postcss.Rule | null = null;

        this.AST.walkRules((rule) => {
            if (foundNode) {
                return;
            }

            if (getNodeId(rule) === id) {
                foundNode = rule;
                return;
            }

            rule.walkDecls((decl) => {
                if (foundNode) {
                    return;
                }

                if (getNodeId(decl) === id) {
                    foundNode = decl;
                    return;
                }
            });
        });

        return foundNode;
    }

    // TODO: Move to stylable
    public getVarSymbols(): Record<string, VarSymbol> {
        const meta = this.getMeta();

        const transformer = this.getTransformer();
        if (!transformer) {
            return {};
        }

        const importedVars = meta.imports.reduce((importVars, importObj) => {
            Object.keys(importObj.named).forEach((importName) => {
                const importSymbol = this.getSymbol(importName);

                let varResolvedSymbol = importSymbol;
                if (importSymbol._kind === 'import') {
                    const resolved = transformer.resolver.deepResolve(importSymbol);
                    if (resolved && resolved.symbol) {
                        varResolvedSymbol = resolved.symbol;
                    }
                }

                if (varResolvedSymbol._kind === 'var') {
                    importVars[importName] = varResolvedSymbol;
                }
            });
            return importVars;
        }, {} as Record<string, VarSymbol>);

        const localVars = meta.vars.reduce((metaVars, varObj) => {
            metaVars[varObj.name] = varObj;
            return metaVars;
        }, {} as Record<string, VarSymbol>);

        return { ...importedVars, ...localVars };
    }

    public get vars() {
        return Object.keys(this.getVarSymbols());
    }

    public getNewPalleteVars() {
        const varNames = this.getVarNames();
        return varNames
            .filter((varName) => /color_\d{2}/.test(varName))
            .map((name) => ({ name, value: this.getVarValue(name) }));
    }

    public getGroupVars(pattern: RegExp, dimensions: 1 | 2) {
        const varNames = this.getVarNames();

        let output: Var[] | Var[][] = [];
        varNames.forEach((name) => {
            const nameMatch = name.match(pattern);
            if (!nameMatch || nameMatch[1] === undefined || (dimensions === 2 && nameMatch[2] === undefined)) {
                return;
            }
            const rowIndex = parseInt(nameMatch[1], 10);
            const colIndex = dimensions === 2 ? parseInt(nameMatch[2], 10) : -1;
            if (dimensions === 2 && output[rowIndex] === undefined) {
                output[rowIndex] = [];
            }
            const value = this.getVarValue(name);
            if (dimensions === 2) {
                (output[rowIndex] as Var[])[colIndex] = { name, value };
            } else {
                output[rowIndex] = { name, value };
            }
        });

        output = (output as Var[]).filter((palette) => !!palette);
        if (dimensions === 2) {
            output = (output as any).map((palette: Var[]) => palette.filter((varObj) => !!varObj));
        }

        return output;
    }

    public getVarValue(name: string) {
        return this.evalDeclarationValue(`value(${name})`);
    }

    public setVar(name: string, value: string) {
        const varsRules = this.upsertStyleRule(':vars', false, false);

        let found = false;
        let updatedRule = varsRules[0];

        varsRules.forEach((rule) => {
            if (!found) {
                rule.walkDecls((decl) => {
                    if (!found) {
                        if (decl.prop === name) {
                            found = true;
                            updatedRule = rule;
                            decl.value = value;
                        }
                    }
                });
            }
        });

        if (!found) {
            updatedRule.append(postcss.decl({ prop: name, value }));
        }

        this.updateFromAST(updatedRule);
    }

    public deleteVar(name: string) {
        const varsRules = this.queryStyleRule(':vars', '');
        if (varsRules.length === 0) {
            return;
        }

        let found = false;
        let updatedRule = varsRules[0];

        varsRules.forEach((rule) => {
            if (!found) {
                rule.walkDecls((decl) => {
                    if (!found) {
                        if (decl.prop === name) {
                            found = true;
                            updatedRule = rule;
                            rule.removeChild(decl);
                        }
                    }
                });
            }
        });

        if (found) {
            this.updateFromAST(updatedRule);
        }
    }

    public evalDeclarationValue(value: string | undefined, overrides?: EvalOverrides) {
        if (!value) {
            return '';
        }
        const transformer = this.getTransformer();
        return transformer
            ? evalDeclarationValue(
                  transformer.resolver,
                  value,
                  this.getMeta(),
                  postcss.decl({ prop: '', value }),
                  overrides
              )
            : value;
    }

    public evalDeclarationProp(prop: string) {
        const transformer = this.getTransformer();
        if (isCSSVarProp(prop) && transformer) {
            const scopedVar = scopeCSSVar(transformer.resolver, this.getMeta(), prop);
            return scopedVar ? scopedVar : prop;
        }
        return prop;
    }

    public collectSelectorUsedProps(selector: string) {
        let retVal: string[] = [];
        this.walkRules((rule: postcss.Rule) => {
            const arraysEqual = matchSelectorTarget(selector, rule.selector);

            if (arraysEqual && rule.nodes) {
                // match:
                // TODO: Refactor to walkDecls
                retVal = retVal.concat(
                    rule.nodes.filter((node) => node.type === 'decl').map((decl) => (decl as postcss.Declaration).prop)
                );
            }
        });
        return retVal;
    }

    public getCSSMixinOverrides(name: string) {
        const overrides: string[] = [];

        const mixinSubset = createSubsetAst(this.AST, `.${name}`);
        mixinSubset.walkDecls((decl) => {
            const valueRegex = /value\((\S[^()]+)\)/g;
            let regexResult;
            while ((regexResult = valueRegex.exec(decl.value)) !== null) {
                const result = regexResult[1];
                !~overrides.indexOf(result) && overrides.push(result);
            }
        });

        return overrides;
    }

    public getStyleStates(className: string) {
        let styles: string[] = [];
        let symbol: StylableSymbol = this.getSymbol(className);
        const transformer = this.getTransformer();

        if (transformer) {
            while (symbol) {
                if ((symbol as any)['-st-states']) {
                    styles = Object.keys((symbol as any)['-st-states']).concat(styles);
                }
                const resolved = transformer.resolver.deepResolve(symbol);
                symbol = resolved && resolved.symbol;
            }
        }

        return styles;
    }

    public getVariantStyleRules(selector: string): postcss.Rule[] {
        const result: postcss.Rule[] = [];
        this.walkRules((rule) => {
            if (rule.selector.match(new RegExp(`^${selector}$|^${selector}[:].*$`))) {
                result.push(rule);
            }
        });
        return result;
    }

    public getTargetRule(selector: string, applyForceStates = false) {
        const transformer = this.getTransformer();
        const targetSelector = transformer.scopeSelector(this.getMeta(), selector).selector;
        const mockAst = postcss.root({ nodes: [postcss.rule({ selector: targetSelector })] });

        if (applyForceStates) {
            applyStylableForceStateSelectors(mockAst);
        }

        return mockAst.nodes[0] as postcss.Rule;
    }

    public getTargetSelector(selector: string, applyForceStates = false) {
        return this.getTargetRule(selector, applyForceStates).selector;
    }

    public getVariantStates(variant: string) {
        let states: string[] = [];

        const meta = this.getMeta();

        const transformer = this.getTransformer();
        if (!transformer) {
            return [];
        }

        const elements = transformer.resolveSelectorElements(meta, variant)[0];
        const element = elements[elements.length - 1];
        element.resolved.forEach((resolve) => {
            if (!hasOwnProperty.call(resolve.symbol, '-st-states')) {
                return;
            }
            const newStates = Object.keys((resolve.symbol as ClassSymbol)['-st-states']!).filter(
                (state) => !~states.indexOf(state)
            );
            states = states.concat(newStates);
        });
        return states.concat(NATIVE_STATES);
    }

    public getVariantUsedStates(variant: string) {
        const meta = this.getMeta();

        const transformer = this.getTransformer();
        if (!transformer) {
            return [];
        }

        const possibleStates = this.getVariantStates(variant);
        const usedStates = new Set<string>();
        if (meta.outputAst) {
            possibleStates.forEach((state) => {
                const selector = transformer.scopeSelector(meta, `${variant}:${state}`).selector;
                this.walkRules((rule) => {
                    if (
                        rule.selector.includes(selector) &&
                        rule.nodes &&
                        rule.nodes.filter((node) => node.type !== 'comment').length > 0
                    ) {
                        usedStates.add(state);
                    }
                }, meta.outputAst);
            });
        } else {
            throw new Error('stylesheet meta not yet initialized.');
        }

        return Array.from(usedStates);
    }

    private withAST(cb: (ast: postcss.Root) => void) {
        const ast = this.AST;
        cb(ast);
        this.updateFromAST(ast);
    }

    private getVarNames() {
        const varNames: string[] = [];

        const varRules = this.queryStyleRule(':vars', '');
        varRules.forEach((rule) => {
            rule.walkDecls((decl) => {
                varNames.push(decl.prop);
            });
        });

        return varNames;
    }

    private findRuleIndex(newSelector: string, selectors: SelectorRef[]) {
        const prevRules: number[] = [];
        let prevRule: number | undefined;

        const selectorStrings = selectors.map((select) => select.selector);

        prevRule = this.findRuleByState(selectorStrings, newSelector);
        prevRule !== undefined && prevRules.push(prevRule);

        prevRule = findRuleBySameDeepness(selectorStrings, newSelector);
        prevRule !== undefined && prevRules.push(prevRule);

        prevRule = findRuleByClosestAncestor(selectorStrings, newSelector);
        prevRule !== undefined && prevRules.push(prevRule);

        prevRule = findRuleByDeepness(selectorStrings, newSelector);
        prevRule !== undefined && prevRules.push(prevRule);

        const indexInRefList = prevRules.length > 0 ? prevRules[0] : selectors.length - 1;
        return indexInRefList !== -1 ? selectors[indexInRefList].index : -1; // if got index -1, return index-1 so that the rule is added first (index 0)
    }

    private findRuleByState = (selectors: string[], newSelector: string) => {
        let newSelectorNoState = newSelector;
        let prevRule: number | undefined;
        // find same element without state:
        if (doesEndWithState(newSelector)) {
            newSelectorNoState = newSelector.substring(0, newSelector.lastIndexOf(':'));
            const newSelectorState = newSelector.substring(newSelector.lastIndexOf(':') + 1);
            const newSelectorParts = newSelectorNoState.split('::');
            let relevantClassName = newSelectorParts[newSelectorParts.length - 1];
            relevantClassName = relevantClassName[0] === '.' ? relevantClassName.substring(1) : relevantClassName;
            const stateList = NATIVE_STATES_BY_ORDER.concat(this.getStyleStates(relevantClassName));

            const sameElementIndex = selectors.findIndex((select) => select === newSelectorNoState);
            let amongstOtherStates: number | undefined;

            selectors.forEach((select, index) => {
                if (
                    select !== newSelectorNoState &&
                    select.startsWith(newSelectorNoState) &&
                    stateList &&
                    doesEndWithState(select)
                ) {
                    const selectState: string = select.substring(select.lastIndexOf(':') + 1);
                    if (
                        stateList.findIndex((value) => value === selectState) <
                        stateList.findIndex((value) => value === newSelectorState)
                    ) {
                        amongstOtherStates = index;
                    }
                }
            });

            if (amongstOtherStates !== undefined) {
                prevRule = amongstOtherStates;
            } else if (sameElementIndex !== -1) {
                // didn't find any other states which should come before this state
                prevRule = sameElementIndex;
            }
        }
        return prevRule;
    };

    private moveDeclarations(
        fromRule: postcss.Rule,
        toRule: postcss.Rule,
        filter: (keyName: string) => boolean = () => true
    ) {
        /* TODO: Get computed declaration out of mixin - whether it's just a class, or js mixin */
        const newDeclarations: postcss.Declaration[] = [];
        fromRule.walkDecls((decl: postcss.Declaration) => {
            if (!filter(decl.prop)) {
                return;
            }
            fromRule.removeChild(decl);
            newDeclarations.push(postcss.decl(decl));
        });
        newDeclarations.forEach((decl: postcss.Declaration) => toRule.append(decl));
    }

    private isProcessedStScopeRule(rule: postcss.Rule) {
        return (
            !!~rule.selector.indexOf(' ') &&
            rule.selectors.length === 1 &&
            !rule.selector.startsWith(`.${this.namespace}`)
        );
    }
}
