import type { StylePanelPlane } from '@wix/stylable-panel-common';
import type { StylablePanelHost } from '@wix/stylable-panel-controllers';
import type { ChangeAction, StylableDriver } from '@wix/stylable-panel-drivers';
import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import { getTranslate } from '@wix/stylable-panel-controllers';
import { WarningBanner } from '@wix/stylable-panel-components';
import React, { useContext } from 'react';
import type { StateOverridesConfig } from '../../editor-panel';
import { classes, style } from './configurable-panel.st.css';

export const PanelContext = React.createContext<ConfigurablePanelContext | null>(null);
export interface ConfigurablePanelContext {
    // Selection context
    selectedElement: string;
    activeTargetSelector: string;

    stylableDriver: StylableDriver;
    sheetPath: string;
    siteSheetPath: string;
    aggregationPaths?: string[];

    panelHost: StylablePanelHost;
    panelLayoutConfiguration: PanelLayoutConfiguration;
    stateOverridesConfig?: StateOverridesConfig;

    // Parameters:

    // Callbacks:
    changeRuleDeclarations?: (changeRequest: ChangeAction | ChangeAction[]) => void;
    onElementSelect?: (selector: string) => void;
    onStateSelect?: (selector: string) => void;
    onForceState?: (selector: string | null) => void;
    // TODO here we wanna pass all the other (non configurable) stuff
}

export interface PanelLayoutConfiguration {
    plane: StylePanelPlane;
    // TODO more layout config here
}

export interface ConfigurablePanelProps {
    context: ConfigurablePanelContext;
    CustomPanel: React.FC;
    className?: string;
    isCustomCssEditingActive?: boolean;
}

export const ConfigurablePanel = ({ CustomPanel, context, className, isCustomCssEditingActive = false }: ConfigurablePanelProps) => { 
    const translate = getTranslate(context.panelHost);
    return (
        <PanelContext.Provider value={context}>
            <div className={style(classes.root, className)}>
                {isCustomCssEditingActive && <WarningBanner text={translate(StylablePanelTranslationKeys.elementree.warningBanner)} />}
                <CustomPanel />
            </div>
        </PanelContext.Provider>
    );
};

export function usePanelContext() {
    const panelContext = useContext(PanelContext);
    if (!panelContext) {
        throw new Error('missing panel context!');
    }
    return panelContext;
}
