import { isInRect } from '@wix/stylable-panel-common-react';
import { Scrollbars } from '@wix/stylable-panel-components';
import React, { useRef } from 'react';
import { classes, style } from './border-style-picker.st.css';

export const BORDER_STYLES = [
    'solid',
    'dashed',
    'dotted',
    'double' /*,
    'groove',
    'ridge',
    'inset',
    'outset',
    'none',
    'hidden'*/,
] as const;

type SingleBorderStyle = typeof BORDER_STYLES[number];

export interface BorderStylePickerProps {
    className?: string;
    onBlur?: () => void;
    onChange?: (value: string) => void;
    onClose?: () => void;
    value?: string;
}

const scrollbarsStyle = {
    height: '273px',
};

const useMountEffect = (cb: () => void) => {
    const willMount = useRef(true);

    if (willMount.current) cb();

    willMount.current = false;
};

export const BorderStylePicker: React.FC<BorderStylePickerProps> = ({ className, onBlur, onChange, value }) => {
    const borderStylePicker = useRef<HTMLDivElement>(null);

    useMountEffect(() => {
        document.addEventListener('mousedown', handleDocumentMouseDown);

        return () => document.removeEventListener('mousedown', handleDocumentMouseDown);
    });

    const renderBorderStyleItem = (borderStyle: SingleBorderStyle) => (
        <div
            key={`border_style_${borderStyle}`}
            className={style(classes.borderStyleItem, { selected: value === borderStyle })}
            onClick={() => onChange?.(borderStyle)}
        >
            <div
                className={style(classes.borderStyleItemBorder, {
                    thick: borderStyle === 'double',
                })}
                style={{ borderTopStyle: borderStyle }}
            />
        </div>
    );

    const handleDocumentMouseDown = ({ clientX, clientY }: MouseEvent) => {
        if (
            !borderStylePicker.current ||
            isInRect(clientX, clientY, borderStylePicker.current.getBoundingClientRect())
        ) {
            return;
        }

        onBlur?.();
    };

    return (
        <div
            style={{ position: 'absolute' }}
            className={style(classes.root, className)}
            ref={borderStylePicker}>
            <Scrollbars style={scrollbarsStyle}>{BORDER_STYLES.map(renderBorderStyleItem)}</Scrollbars>
        </div>
    );
};
