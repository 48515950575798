import type { BackgroundLonghandProps, BackgroundProps } from './background-types';

export const BACKGROUND_LONGHAND_PROPS: BackgroundLonghandProps[] = [
    'background-attachment',
    'background-clip',
    'background-color',
    'background-image',
    'background-origin',
    'background-position',
    'background-position-x',
    'background-position-y',
    'background-repeat',
    'background-size',
];

export const ALL_BACKGROUND_PROPS = ['background', ...BACKGROUND_LONGHAND_PROPS] as BackgroundProps[];
