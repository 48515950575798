import {
    DimensionInput,
    DimensionVisualizerComponentProps,
    DimensionVisualizerFactoryProps,
} from '@wix/stylable-panel-components';
import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import React from 'react';
import { useTextFromSingleProp, useTranslate } from '../../hooks';
import type { DeclarationVisualizerProps, VisualizerFC } from '../../types';
import { classes, style } from './dimension-visualizer.st.css';

export interface DimensionVisualizerProps<MAIN extends string>
    extends DeclarationVisualizerProps<MAIN>,
        DimensionVisualizerComponentProps {}

export type DimensionVisualizer<MAIN extends string> = VisualizerFC<MAIN, DimensionVisualizerProps<MAIN>>;

export function DimensionVisualizerFactory<MAIN extends string>(
    main: MAIN,
    dimensionInputProps: DimensionVisualizerFactoryProps
) {
    const DimensionVisualizer: DimensionVisualizer<MAIN> = (props) => {
        const {
            className,
            config,
            forceFocus,
            isDisabled,
            onBlur,
            onFocus,
            opacitySliderColor,
            showUnitErrors,
            panelHost,
            tooltipContent,
            isDebounced,
            debounceWait,
        } = props;

        const [value, handleChange] = useTextFromSingleProp(main, props);

        return (
            <DimensionInput
                className={style(classes.root, className)}
                config={config}
                forceFocus={forceFocus}
                isDisabled={isDisabled}
                onBlur={onBlur}
                onChange={handleChange}
                onFocus={onFocus}
                opacitySliderColor={opacitySliderColor}
                showUnitErrors={showUnitErrors ?? panelHost?.showDimensionErrors}
                tooltipContent={tooltipContent}
                translate={useTranslate(panelHost)}
                translationKeys={StylablePanelTranslationKeys.component.dimensionInput}
                value={value ?? ''}
                isDebounced={isDebounced}
                debounceWait={debounceWait}
                {...dimensionInputProps}
            />
        );
    };

    DimensionVisualizer.INPUT_PROPS = [main];

    return DimensionVisualizer;
}
