import type { Gaps } from '../shorthand-css-data';
import type {
    SimpleShorthandOpener,
    ShorthandPart,
    GetSimpleShorthandOpener,
    GetShorthandCloser,
} from '../shorthand-types';

import { lengthPercentageDataType } from '../../css-data-types';
import {
    splitSimpleShorthandOpener,
    unorderedListShorthandOpener,
    createShorthandOpener,
    createShorthandCloserTemplateFromParts,
    createShorthandCloser,
} from '../shorthand-parser-utils';

const getGapShorthandParts = <V>() =>
    [
        {
            prop: 'row-gap',
            dataType: lengthPercentageDataType,
            partOpener: splitSimpleShorthandOpener(['row-gap', 'column-gap']),
        },
        {
            prop: 'column-gap',
            dataType: lengthPercentageDataType,
        },
    ] as ShorthandPart<V, Gaps>[];

// gap
export const openGapShorthand: GetSimpleShorthandOpener<Gaps> = <V>() =>
    createShorthandOpener({
        prop: 'gap',
        parts: getGapShorthandParts(),
        shorthandOpener: (astNodes, api, parts) => unorderedListShorthandOpener(parts)(astNodes, api),
    }) as SimpleShorthandOpener<V, Gaps>;

export const closeGapShorthand: GetShorthandCloser<Gaps> = <V>() =>
    createShorthandCloser<V, Gaps>(createShorthandCloserTemplateFromParts(getGapShorthandParts()));
