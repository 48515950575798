import type { IconProps } from '@wix/stylable-panel-common-react';
import keycode from 'keycode';
import React, { createRef, SyntheticEvent } from 'react';
import { Scrollbars, ScrollbarsProps } from '../scrollbars';
import { OptionListCustomRender, OptionListItem } from './option-list-item';
import { classes, style } from './option-list.st.css';

export const SCROLL_MARGIN_HEIGHT = 15;

export interface Option {
    id: string;
    displayName: string;
    fallbackName?: string;
    subLabel?: string;
    disabled?: boolean;
    hasDivider?: boolean;
    isAction?: boolean;
    indentation?: number;
    icon?: React.ComponentType<IconProps>;
    font?: string;
}

export function optionsFromStrings(values: string[]): Option[] {
    return values.map((value) => ({ id: value, displayName: value }));
}

export interface OptionListProps {
    value?: string;
    options: Option[];
    customOptionRender?: OptionListCustomRender;
    selectedIcon?: boolean;
    noScroll?: boolean;
    noKeyboardInteraction?: boolean;
    hoverOnlyFromLabel?: boolean;
    onHover?: (itemId: string | null) => void;
    onSelect?: (itemId: string, source: OptionSelectSource) => void;
    onClose?: () => void;
    scrollbarProps?: Partial<ScrollbarsProps>;
    className?: string;
    style?: React.CSSProperties;
}

export interface OptionListState {
    hoveredOption: number;
}

export enum OptionSelectSource {
    OPTION,
    LABEL,
    ICON,
}
const DEFAULT_OPTION_SELECT_SOURCE = OptionSelectSource.OPTION;

export class OptionList extends React.Component<OptionListProps, OptionListState> {
    public state: OptionListState = { hoveredOption: -1 };

    private optionList = createRef<HTMLDivElement>();

    public hasHovered() {
        return this.state.hoveredOption !== -1;
    }

    public render() {
        const { noScroll, className, style: propStyle, scrollbarProps } = this.props;

        return (
            <div
                className={style(classes.root, className)}
                style={propStyle}
                onMouseLeave={this.handleLeave}
                ref={this.optionList}
            >
                {!noScroll ? (
                    <Scrollbars universal autoHeight {...(scrollbarProps || {})}>
                        {this.renderOptions()}
                    </Scrollbars>
                ) : (
                    this.renderOptions()
                )}
            </div>
        );
    }

    public componentDidMount() {
        if (!this.props.noKeyboardInteraction) {
            document.addEventListener('keydown', this.handleKeyDown);
        }

        const selectedIndex = this.getSelectedIndex();
        if (~selectedIndex) {
            this.scrollToItem(selectedIndex);
        }
    }

    public componentWillUnmount() {
        if (!this.props.noKeyboardInteraction) {
            document.removeEventListener('keydown', this.handleKeyDown);
        }
    }

    private renderOptions() {
        const { value, options, customOptionRender, selectedIcon, hoverOnlyFromLabel } = this.props;
        const { hoveredOption } = this.state;

        return options.map((item: Option, index) => {
            return (
                <OptionListItem
                    key={`option_${index}`}
                    index={index}
                    id={item.id}
                    isSelected={item.id === value}
                    hovered={index === hoveredOption}
                    hoverOnlyFromLabel={hoverOnlyFromLabel}
                    showSelectedIcon={selectedIcon}
                    item={item}
                    customOptionRender={customOptionRender}
                    className={classes.item}
                    onSelect={this.handleSelectItem}
                    onHover={this.handleHoverItem}
                />
            );
        });
    }

    private handleKeyDown = (event: KeyboardEvent) => {
        const { options, onClose } = this.props;
        const { hoveredOption: stateHoveredOption } = this.state;

        const selectedIndex = this.getSelectedIndex() || 0;
        const hoveredOption = stateHoveredOption !== -1 ? stateHoveredOption : selectedIndex;

        switch (event.keyCode) {
            case keycode('esc'):
                this.handleLeave();
                onClose && onClose();
                break;
            case keycode('down'):
                this.handleKeyboardHover((hoveredOption + 1) % options.length);
                break;
            case keycode('up'):
                this.handleKeyboardHover((hoveredOption - 1 + options.length) % options.length);
                break;
            case keycode('tab'):
            case keycode('enter'):
                if (stateHoveredOption !== -1) {
                    this.handleSelectItem(options[stateHoveredOption].id);
                }
                break;
            default:
                if (event.keyCode >= keycode('a') && event.keyCode <= keycode('z')) {
                    this.handleKeyboardHover(
                        this.getNextAlphabeticalOptionIndex(hoveredOption, String.fromCharCode(event.keyCode))
                    );
                }
        }
    };

    private handleKeyboardHover = (hoveredOption: number) => {
        const option = this.props.options[hoveredOption];
        if (option) {
            this.handleHoverItem(this.props.options[hoveredOption].id, hoveredOption, null, true);
        }
        this.setState({ hoveredOption });
    };

    private handleLeave = () => this.props.onHover && this.props.onHover(null);

    private handleHoverItem = (
        id: string | null,
        index: number,
        _event: React.SyntheticEvent | null,
        fromKeyboard = false
    ) => {
        const { onHover } = this.props;

        if (fromKeyboard && id) {
            this.scrollToItem(index);
        }

        onHover?.(id);
    };

    private scrollToItem = (index: number) => {
        const item = this.optionList.current?.querySelector(`div[id=option_${index}]`) as HTMLDivElement;

        const scrollContainer = item.parentElement;
        if (!scrollContainer) {
            return;
        }

        const itemOffsetBottom = item.offsetTop + item.offsetHeight;
        const scrollOffsetHeight = scrollContainer.offsetHeight - SCROLL_MARGIN_HEIGHT;

        if (itemOffsetBottom > scrollOffsetHeight + scrollContainer.scrollTop) {
            scrollContainer.scrollTop = itemOffsetBottom - scrollOffsetHeight;
        } else if (item.offsetTop < scrollContainer.scrollTop) {
            scrollContainer.scrollTop = item.offsetTop;
        }
    };

    private getNextAlphabeticalOptionIndex = (hoveredOption: number, character: string) => {
        const { options } = this.props;

        const firstCharMatch = (option: Option) =>
            !option.disabled &&
            option.displayName.length >= 1 &&
            character.length >= 1 &&
            option.displayName[0].toLowerCase() === character[0].toLowerCase();

        const optionsAfter = options.slice(hoveredOption + 1);
        const indexInAfter = optionsAfter.findIndex(firstCharMatch);
        if (~indexInAfter) {
            return indexInAfter + hoveredOption + 1;
        }

        const optionsBefore = options.slice(0, hoveredOption);
        const indexInBefore = optionsBefore.findIndex(firstCharMatch);

        return ~indexInBefore ? indexInBefore : hoveredOption;
    };

    private handleSelectItem = (id: string, source?: OptionSelectSource, e?: SyntheticEvent) => {
        const { onSelect } = this.props;

        this.handleLeave();
        onSelect && onSelect(id, source || DEFAULT_OPTION_SELECT_SOURCE);
        e && e.stopPropagation();
    };

    private getSelectedIndex = () => {
        const { value, options } = this.props;
        return options.findIndex((option) => option.id === value);
    };
}
