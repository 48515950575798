import { useCallback, useMemo, useRef } from 'react';
import { useTranslate, useVisualizerChange } from '../../hooks';
import { createDeclarationMapFromVisualizerValue } from '../../utils';
import type { IconVisualizerProps } from './icon-types';
import { ICON_DISPLAY_HIDDEN } from './icon-visualizer';
import chroma from 'chroma-js';
import { DIMENSION_INPUT_DEFAULTS, getDimensionInputDefaults } from '@wix/stylable-panel-common';

export const DEFAULT_ANGLE = DIMENSION_INPUT_DEFAULTS.iconVisualizer.angle;
export const DEFAULT_OPACITY = DIMENSION_INPUT_DEFAULTS.iconVisualizer.opacity;

export const useIconVisualizer = (props: IconVisualizerProps) => {
    const { controllerData, panelHost } = props;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const DEFAULT_SIZE = useMemo(() => getDimensionInputDefaults(panelHost).iconVisualizer.size, [panelHost?.dimensionUnits?.defaults]);

    const angle = useRef(DEFAULT_ANGLE.value + DEFAULT_ANGLE.unit);
    const declarationMapValue = createDeclarationMapFromVisualizerValue(props.value, props);

    const { display, fill, transform, width = DEFAULT_SIZE, height = DEFAULT_SIZE } = declarationMapValue;

    const translate = useTranslate(panelHost);

    const hideShownToggle = controllerData && !!controllerData.hideShownToggle;
    const elementShown = display !== ICON_DISPLAY_HIDDEN;
    const handleAngleChange = useVisualizerChange('transform', props);
    const handleColorChange = useVisualizerChange('fill', props);

    const changeColorAlpha = useCallback(
        (value: string) => {
            if (!handleColorChange) {
                return;
            }

            try {
                const color = chroma(fill ?? '');
                const numberValue = parseFloat(value);

                handleColorChange(color.alpha(numberValue / 100).css());
            } catch {
                //
            }
        },
        [fill, handleColorChange]
    );

    const changeAngle = useCallback(
        (value: string) => {
            if (!handleAngleChange) {
                return;
            }

            try {
                const newAngle = parseInt(value, 10) % 360;
                angle.current = newAngle + DEFAULT_ANGLE.unit;
                handleAngleChange(`rotate(${angle.current})`);
            } catch {
                //
            }
        },
        [handleAngleChange]
    );

    return {
        width,
        height,
        angle,
        transform,
        fill,
        hideShownToggle,
        elementShown,
        translate,
        handleColorChange,
        changeColorAlpha,
        changeAngle,
    };
};
