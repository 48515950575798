import React from 'react';
import { classes, style } from './text.st.css';

type TextProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & {
    isDisabled?: boolean;
    isSelected?: boolean;
};

export const Text: React.FC<TextProps> = ({
    children,
    className,
    style: customStyle,
    isDisabled = false,
    isSelected = false,
    ...props
}) => (
    <span
        className={style(classes.root, { disabled: isDisabled, selected: isSelected }, className)}
        style={customStyle}
        {...props}
    >
        {children || ''}
    </span>
);
