import { DEFAULT_PLANE, DIMENSION_ID } from '@wix/stylable-panel-common';
import { OptimisticWrapper, Text } from '@wix/stylable-panel-components';
import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import React, { useCallback, useState } from 'react';
import type { OpenedDeclarationArray } from '../../declaration-types';
import { useColorPicker } from '../../hooks';
import type { VisualizerFC } from '../../types';
import { createDeclarationMapFromVisualizerValue, visualizerOptimisticValueResolver } from '../../utils';
import { EMPTY_FONT_FAMILY } from '../font-family-visualizer/font-family-visualizer';
import { FontFamilyBlock } from './font-family-block';
import { FontThemeBlock } from './font-theming-block';
import { GlyphMode } from './glyph-mode';
import { IconRowsBlock } from './icon-block';
import { LineHeightInput } from './line-height-input';
import { ShownToggle } from './show-toggle';
import { SliderBlock } from './slider-block';
import { TextShadow } from './text-shadow';
import { ColorPickerTextType } from './text-visualizer-icons';
import { classes, style } from './text-visualizer.st.css';
import {
    AUTOMATIC_LINE_HEIGHT,
    TextProps,
    TextVisualizerProps,
    TEXT_SHADOW_NONE,
    useTextVisualizer,
} from './use-text-visualizer';

export const DISPLAY_SHOWN = 'initial';
export const ID_FUNCTION = (changeValue: string) => changeValue;

export function strokeShadow(color: string) {
    return `1px 0 ${color}, -1px 0 ${color}, 0 1px ${color}, 0 -1px ${color}`;
}

export interface TextVisualizerState {
    fontTheme: { name: string; isCustom: boolean };
    textShadowVisualizerShown: boolean;
}

const TEXT_PROPS_LIST: TextProps[] = [
    'font',
    'font-family',
    'font-weight',
    'font-size',
    'color',
    'font-style',
    'text-decoration-line',
    'text-transform',
    'text-align',
    'direction',
    'letter-spacing',
    'line-height',
    'text-shadow',
];

const OUTSIDE_PROPS_LIST: TextProps[] = ['display', 'background-color', '-st-mixin'];

export type TextVisualizerValue = OpenedDeclarationArray<TextProps>;

export type TextVisualizer = VisualizerFC<TextProps, TextVisualizerProps>;

export const TextVisualizerInner: TextVisualizer = (props) => {
    const { plane = DEFAULT_PLANE, panelHost, className, siteVarsDriver } = props;

    const availableFontThemes = panelHost?.getFontThemes?.() || [];
    const declarationMapValue = createDeclarationMapFromVisualizerValue(props.value, props);
    const [isCustomTheme, setIsCustomTheme] = useState(false);

    const {
        hideShownToggle,
        stroke,
        elementShown,
        titleKey,
        fontTheme,
        glyphMode,
        handleFontThemeChange,
        handleTextShadowVisualizerShownChange,
        changeFromBlock,
        translate,
        handleCustomLineHeightChange,
        textShadowVisualizerShown,
        DEFAULT_TEXT_ATTRIBUTES
    } = useTextVisualizer(props);

    const openColorPickerPanel = useColorPicker({
        className: classes.colorPicker,
        panelHost,
        siteVarsDriver,
    });

    const openColorPicker = useCallback(
        (colorPickerType: ColorPickerTextType) => {
            if (!openColorPickerPanel) {
                return;
            }

            let title = '';
            let currentColor: string | undefined;
            let onChange: (value: string) => void = () => {
                /**/
            };
            let onReset: (() => void) | undefined;

            switch (colorPickerType) {
                case ColorPickerTextType.Text:
                    title = translate(StylablePanelTranslationKeys.controller.text.colorPickers.textTitle);
                    currentColor = declarationMapValue.color;
                    onChange = changeFromBlock.bind(this, 'color', ID_FUNCTION);
                    break;
                case ColorPickerTextType.Background:
                    title = translate(StylablePanelTranslationKeys.controller.text.colorPickers.backgroundTitle);
                    currentColor = declarationMapValue['background-color'];
                    onChange = changeFromBlock.bind(this, 'background-color', ID_FUNCTION);
                    onReset = () => changeFromBlock('background-color', ID_FUNCTION, '');
                    break;
                case ColorPickerTextType.Stroke:
                    title = translate(StylablePanelTranslationKeys.controller.text.colorPickers.strokeTitle);
                    currentColor = stroke.color;
                    onChange = changeFromBlock.bind(this, 'text-shadow', (changeValue: string) => {
                        const strokeOtherLayers = stroke.value;

                        if (changeValue) {
                            const strokeShadowLayers = strokeShadow(changeValue);
                            return strokeOtherLayers['text-shadow'] !== TEXT_SHADOW_NONE
                                ? `${strokeShadowLayers}, ${strokeOtherLayers['text-shadow']}`
                                : strokeShadowLayers;
                        }

                        return strokeOtherLayers['text-shadow'];
                    });
                    onReset = () => changeFromBlock('text-shadow', ID_FUNCTION, stroke.value['text-shadow']);
                    break;
            }

            openColorPickerPanel({
                title,
                currentColor,
                onHover: onChange as (value: string | null) => void,
                onChange,
                onReset,
            });
        },
        [changeFromBlock, declarationMapValue, openColorPickerPanel, stroke.color, stroke.value, translate]
    );

    const renderControllers = useCallback(() => {
        const {
            'font-family': fontFamily = EMPTY_FONT_FAMILY,
            // 'font-weight': fontWeight = EMPTY_FONT_WEIGHT,
            'font-size': fontSize = DEFAULT_TEXT_ATTRIBUTES.fontSize,
            'letter-spacing': letterSpacing = DEFAULT_TEXT_ATTRIBUTES.letterSpacing,
            'line-height': lineHeight = DEFAULT_TEXT_ATTRIBUTES.lineHeight,
        } = declarationMapValue;

        const handleSaveTheme = () => {
            panelHost?.saveTheme?.(declarationMapValue);
        };

        const noResizing = props.controllerData?.noResizing;
        const isCustomLineHeight = lineHeight !== AUTOMATIC_LINE_HEIGHT;
        return [
            noResizing ? null : (
                <FontThemeBlock
                    saveTheme={handleSaveTheme}
                    key={'font_theme_block'}
                    value={fontTheme}
                    props={props}
                    availableFontThemes={availableFontThemes}
                    declarationMapValue={declarationMapValue}
                    handleFontThemeChange={handleFontThemeChange}
                    setIsCustomTheme={setIsCustomTheme}
                    isCustomTheme={isCustomTheme}
                    className={classes.fontThemeBlock}
                />
            ),
            noResizing ? null : (
                <FontFamilyBlock
                    key={'font_family_block'}
                    value={fontFamily}
                    props={props}
                    changeFromBlock={changeFromBlock}
                />
            ),
            noResizing ? null : (
                <SliderBlock
                    key={'font_size_slider_block'}
                    title={translate(StylablePanelTranslationKeys.controller.text.fontSizeSelectorLabel)}
                    value={fontSize}
                    prop={'font-size'}
                    informationTooltip={translate(
                        StylablePanelTranslationKeys.controller.text.fontSizeSelectorInformationTooltip
                    )}
                    props={props}
                    changeFromBlock={changeFromBlock}
                />
            ),
            <IconRowsBlock
                key={'icon-row-block'}
                noResizing={noResizing}
                props={props}
                declarationMapValue={declarationMapValue}
                openColorPicker={openColorPicker}
                changeFromBlock={changeFromBlock}
                stroke={stroke}
            />,
            noResizing ? null : (
                <SliderBlock
                    key={'letter_spacing_slider_block'}
                    title={translate(StylablePanelTranslationKeys.controller.text.letterSpacingSelectorLabel)}
                    value={letterSpacing}
                    prop={'letter-spacing'}
                    props={props}
                    changeFromBlock={changeFromBlock}
                />
            ),
            !noResizing ? (
                <LineHeightInput
                    key={'line_height_input'}
                    className={classes.lineHeightInput}
                    panelHost={panelHost}
                    onChange={handleCustomLineHeightChange}
                    isCustom={isCustomLineHeight}
                >
                    {isCustomLineHeight && (
                        <SliderBlock
                            title={''}
                            value={lineHeight}
                            prop={DIMENSION_ID.LINE_HEIGHT}
                            props={props}
                            changeFromBlock={changeFromBlock}
                        />
                    )}
                </LineHeightInput>
            ) : null,
            <TextShadow
                key={'text-shadow'}
                props={props}
                stroke={stroke}
                textShadowVisualizerShown={textShadowVisualizerShown}
                handleTextShadowVisualizerShownChange={handleTextShadowVisualizerShownChange}
            />,
        ];
    }, [
        changeFromBlock,
        declarationMapValue,
        fontTheme,
        handleCustomLineHeightChange,
        handleFontThemeChange,
        handleTextShadowVisualizerShownChange,
        isCustomTheme,
        openColorPicker,
        panelHost,
        props,
        stroke,
        textShadowVisualizerShown,
        translate,
        DEFAULT_TEXT_ATTRIBUTES
    ]);

    return (
        <div className={style(classes.root, { plane }, className)}>
            <Text className={classes.title}>{translate(titleKey)}</Text>
            {hideShownToggle ? null : (
                <ShownToggle
                    elementShown={elementShown}
                    glyphMode={glyphMode}
                    props={props}
                    changeFromBlock={changeFromBlock}
                />
            )}
            {elementShown ? (
                !glyphMode ? (
                    renderControllers()
                ) : (
                    <GlyphMode
                        props={props}
                        declarationMapValue={declarationMapValue}
                        openColorPicker={openColorPicker}
                    />
                )
            ) : null}
        </div>
    );
};

TextVisualizerInner.BLOCK_VARIANT_CONTROLLER = false;
TextVisualizerInner.INPUT_PROPS = TEXT_PROPS_LIST;
TextVisualizerInner.OUTSIDE_PROPS = OUTSIDE_PROPS_LIST;

export const TextVisualizer = OptimisticWrapper<TextVisualizer, TextVisualizerValue>(
    TextVisualizerInner,
    {},
    true,
    visualizerOptimisticValueResolver
);
