import { Diagnostics, ensureStylableImports, parseStylableImport } from '@stylable/core';
import { AnyNode, AtRule, ChildNode, Container, Declaration, parse, Root, Rule } from 'postcss';
import { cssShorthandMap } from './css-utils/css-shorthands';

export interface ScopedData {
    computed?: string[];
    initial?: string | string[];
}
type CssLongHand = { [name: string]: ScopedData };
export type CssShortHandMap = Record<string, ScopedData | CssLongHand>;

function getShorthandProps(_prop: string) {
    const shortHands = cssShorthandMap as CssShortHandMap;
    return shortHands[_prop] ? (shortHands[_prop] as ScopedData).computed! : [];
}

const createDeclarationMap = (rule: Rule) => {
    const declarations: Record<string, Declaration> = {};
    rule.walkDecls((decl) => {
        // removing redundant long-hand declarations
        for (const unreachableLongHandDecl of getShorthandProps(decl.prop)) {
            delete declarations[unreachableLongHandDecl];
        }
        declarations[decl.prop] = decl;
        decl.remove();
    });
    return declarations;
};

function mergeDecls(rule: Rule) {
    const declarations = createDeclarationMap(rule);
    const finalRule = rule.clone({ nodes: Object.values(declarations) });
    return finalRule;
}

export function mergeStylesheets(destination: string, source: string) {
    const filterImports = (node: AnyNode) =>
        (node.type === 'rule' && node.selector === ':import') || (node.type === 'atrule' && node.name === 'st-import');
    const astA = parse(destination);
    const astB = parse(source);
    const allParsedImports = astB.nodes.filter(filterImports).map((rule) => {
        const parsed = parseStylableImport(rule as Rule, '*', new Diagnostics());
        rule.remove();
        return parsed;
    });
    const { diagnostics } = ensureStylableImports(astA, allParsedImports, { newImport: 'none' });
    if (diagnostics.reports.length > 0) {
        throw new Error(`Error merging :import rule, diagnostics: ${diagnostics.reports}`);
    }

    return merge(astA, astB).toString();
}

function merge(destination: Root | AtRule, source: Root | AtRule) {
    mergeNodes(destination, source);
    removeOverriddenDecls(destination);
    return destination;
}

function mergeNodes<T extends Root | AtRule>(destination: T, source: T): T {
    let i = destination.nodes.length;
    const handled = new Set<ChildNode>();
    while (i--) {
        const node = destination.nodes[i];
        if (node.type === 'rule') {
            const nodesToMerge = getAllMatchingNode(source.nodes, node);
            for (const nodeToMerge of nodesToMerge) {
                handled.add(nodeToMerge);
            }
            node.replaceWith(mergeDecls(appendToFirst([node, ...nodesToMerge]) as Rule));
        } else if (node.type === 'atrule') {
            const nodesToMerge = getAllMatchingNode(source.nodes, node);
            for (const nodeToMerge of nodesToMerge) {
                handled.add(nodeToMerge);
            }
            nodesToMerge.reduce((a, b) => mergeNodes(a as AtRule, b as AtRule), node);
        }
    }

    for (const node of source.nodes) {
        if (!handled.has(node)) {
            destination.append(node);
        }
    }
    return destination;
}

function removeOverriddenDecls(nodesA: Container, { unsafeMergeAll = false } = {}) {
    // assumes no two selector from the same variant on applied on the same node

    const rules = new Set<Rule>();
    nodesA.walkRules((current) => {
        const currentDecls = current.nodes.filter((node) => node.type === 'decl') as Declaration[];

        const currentDeclProps = new Set(currentDecls.map((decl) => decl.prop));
        for (const rule of rules) {
            if (isSamePath(current, rule)) {
                if (unsafeMergeAll) {
                    current.prepend(rule.nodes);
                } else {
                    for (const node of rule.nodes.slice()) {
                        if (node.type === 'decl' && currentDeclProps.has(node.prop) /** TODO: handle shorthand */) {
                            node.remove();
                        }
                    }
                }

                if (removeNodeAndEachParentIfEmpty(rule)) {
                    rules.delete(rule);
                }
            }
        }
        rules.add(current);
    });
}

function removeNodeAndEachParentIfEmpty(node?: AtRule | Rule | Root) {
    if (!node) {
        return;
    }
    const nodes = node.nodes.filter((node) => node.type !== 'comment');
    const parent = node.parent; // before removing capture parent
    if (nodes.length === 0) {
        node.remove();
        if (parent && parent.type !== 'root') {
            removeNodeAndEachParentIfEmpty(parent as AtRule | Rule | Root);
        }
        return true;
    }
    return false;
}

function isSamePath(nodeA: AnyNode, nodeB: AnyNode): boolean {
    if (nodeA.type !== nodeB.type) {
        return false;
    }
    switch (nodeA.type) {
        case 'rule':
            nodeB = nodeB as typeof nodeA;
            if (nodeA.selector !== nodeB.selector) {
                return false;
            }
            break;
        case 'atrule':
            nodeB = nodeB as typeof nodeA;
            if (nodeA.name !== nodeB.name || nodeA.params !== nodeB.params) {
                return false;
            }
            break;
        case 'root':
            return true;
    }

    if (nodeA.parent) {
        nodeB = nodeB as typeof nodeA;
        const a = nodeA.parent;
        const b = nodeB.parent;
        return nodeB.parent ? isSamePath(a as AnyNode, b as AnyNode) : false;
    }

    return false;
}

function appendToFirst<T extends (AtRule | Rule)[]>(rules: T): T[0] {
    return rules.reduce((a, b) => {
        a.append(b.nodes);
        return a;
    });
}

function getAllMatchingNode(b: ChildNode[], node: Rule | AtRule): Array<Rule | AtRule> {
    let result: Array<Rule | AtRule> = [];
    result = b.filter((bNode): bNode is Rule | AtRule => {
        if (node.type === 'rule' && bNode.type === 'rule') {
            return node.selector === bNode.selector;
        } else if (node.type === 'atrule' && bNode.type === 'atrule') {
            return node.name === bNode.name && node.params === bNode.params;
        }
        return false;
    });

    return result;
}
