import type { StylableDriver } from './stylable-driver';
import type { FullDeclarationMap } from './types';
import { getStaticComputedCSS } from './get-static-computed-css';
import { DEFAULT_FILTER, SELECTOR_STATE_REGEX } from './stylable-aggregation-constants';
import type { StylesheetDriver } from './stylable-stylesheet';

export type FilterFunc = (prop: string) => boolean;

function matchRegex(selector: string, matcher: RegExp) {
    const match = selector.match(matcher);
    return { match, name: match ? match[1] : '' };
}

export const aggregateSelectorDeclarations = (
    stylableDriver: StylableDriver,
    sheetPaths: string | string[],
    selector: string,
    filter: FilterFunc = DEFAULT_FILTER,
    useRawDecl = false,
    // We don't open background shorthands since they are fully opened by the background-driver
    disableBackgroundShorthand = true
): FullDeclarationMap => {
    const sheets = (Array.isArray(sheetPaths) ? sheetPaths : [sheetPaths]).map((sheetPath) =>
        stylableDriver.getStylesheet(sheetPath)
    ) as StylesheetDriver[];
    if (!sheets.length) {
        return {};
    }

    const stateName = matchRegex(selector, SELECTOR_STATE_REGEX).name;

    // TODO: this is work around issue for when to filter -st- stuff related to states (should be removed)
    const filterDecl = stateName
        ? (prop: string) => prop !== '-st-extends' && prop !== '-st-states' && filter(prop)
        : filter;

    try {
        return getStaticComputedCSS(stylableDriver.stylable, sheets, selector, {
            filterDecl,
            useRawDecl,
            disableBackgroundShorthand,
        }).decls;
    } catch (e) {
        throw new Error('getStaticComputedCSS error caused by:\n' + (e as Error)?.stack);
    }
};
