import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import type { SingleCategoryConfig } from '../../../../types';
import { diagonalLinesGenerator } from '../category-config-formatters';

export const fillBlockVariants: SingleCategoryConfig = {
    titleKey: StylablePanelTranslationKeys.controller.fills.title,
    blockVariantSet: [
        { background: 'transparent' },
        { background: 'value(site_3_1)' },
        { background: 'linear-gradient(to bottom, value(site_2_3), rgba(0,0,0,0))' },
        diagonalLinesGenerator('value(site_4_1)', [
            {
                primaryColor: 'value(site_1_2)',
                patternDirection: 3,
                primaryWidth: 10,
                secondaryWidth: 1,
            },
        ]),
        { background: 'linear-gradient(111deg, value(site_5_3), value(site_5_2) 50%, value(site_5_1))' },
        diagonalLinesGenerator('value(site_1_1)', [
            {
                primaryColor: 'value(site_2_1)',
                patternDirection: 1,
                primaryWidth: 10,
                secondaryWidth: 2,
            },
        ]),
        {
            background:
                'linear-gradient(315deg, rgba(255, 255, 255, 0) 12.5%, rgba(229, 220, 219, 0.06) 12.5%, rgba(229, 220, 219, 0.06) 25%, rgba(202, 185, 182, 0.13) 25%, rgba(202, 185, 182, 0.13) 37.5%, rgba(176, 150, 146, 0.21) 37.5%, rgba(176, 150, 146, 0.21) 50%, rgba(149, 116, 109, 0.27) 50%, rgba(149, 116, 109, 0.27) 62.5%, rgba(123, 81, 73, 0.35) 62.5%, rgba(123, 81, 73, 0.35) 75%, rgba(96, 46, 36, 0.42) 75%, rgba(96, 46, 36, 0.42) 87.5%, rgba(70, 11, 0, 0.49) 87.5%), linear-gradient(45deg, rgba(255, 255, 255, 0) 12.5%, rgba(219, 227, 230, 0.06) 12.5%, rgba(219, 227, 230, 0.06) 25%, rgba(182, 200, 204, 0.13) 25%, rgba(182, 200, 204, 0.13) 37.5%, rgba(146, 172, 179, 0.21) 37.5%, rgba(146, 172, 179, 0.21) 50%, rgba(109, 144, 153, 0.27) 50%, rgba(109, 144, 153, 0.27) 62.5%, rgba(73, 116, 128, 0.35) 62.5%, rgba(73, 116, 128, 0.35) 75%, rgba(36, 89, 102, 0.42) 75%, rgba(36, 89, 102, 0.42) 87.5%, rgba(0, 61, 77, 0.49) 87.5%)',
        },
        {
            background:
                'linear-gradient(225deg, rgba(0, 185, 232, 0.27) 25%, rgba(85, 208, 240, 0.18) 25%, rgba(85, 208, 240, 0.18) 50%, rgba(170, 232, 247, 0.09) 50%, rgba(170, 232, 247, 0.09) 75%, rgba(255, 255, 255, 0) 75%), linear-gradient(315deg, rgba(137, 191, 36, 0.33) 25%, rgba(176, 212, 109, 0.22) 25%, rgba(176, 212, 109, 0.22) 50%, rgba(216, 234, 182, 0.11) 50%, rgba(216, 234, 182, 0.11) 75%, rgba(255, 255, 255, 0) 75%), linear-gradient(135deg, rgba(255, 208, 0, 0.33) 25%, rgba(255, 224, 85, 0.22) 25%, rgba(255, 224, 85, 0.22) 50%, rgba(255, 239, 170, 0.11) 50%, rgba(255, 239, 170, 0.11) 75%, rgba(255, 255, 255, 0) 75%), linear-gradient(45deg, rgba(211, 35, 0, 0.36) 25%, rgba(226, 108, 85, 0.24) 25%, rgba(226, 108, 85, 0.24) 50%, rgba(240, 182, 170, 0.12) 50%, rgba(240, 182, 170, 0.12) 75%, rgba(255, 255, 255, 0) 75%)',
        },

        { background: 'value(site_1_5)' },
        { background: 'value(site_4_1)' },
        { background: 'value(site_5_1)' },
        { background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000)' },
        { background: 'linear-gradient(45deg, rgba(0, 0, 0, 0), value(site_3_1))' },
        { background: 'linear-gradient(300deg, value(site_2_4), value(site_2_1))' },
        { background: 'linear-gradient(90deg, value(site_3_3), value(site_3_1))' },
        {
            background:
                'linear-gradient(0deg, value(site_4_2), value(site_4_2) 45%, value(site_1_1) 45% ,value(site_1_1) 55%,value(site_4_2) 55%,value(site_4_2))',
        },
        {
            background:
                'linear-gradient(to bottom, value(site_5_3), value(site_5_3) 35%, value(site_1_1) 35% ,value(site_1_1) 45% ,value(site_5_1) 45%,value(site_5_1))',
        },
        diagonalLinesGenerator('value(site_3_1)', [
            {
                primaryColor: 'value(site_3_2)',
                patternDirection: 3,
                primaryWidth: 2,
                secondaryWidth: 2,
            },
        ]),
        diagonalLinesGenerator('value(site_5_1)', [
            {
                primaryColor: 'value(site_5_2)',
                patternDirection: 1,
                primaryWidth: 5,
                secondaryWidth: 1,
            },
        ]),
        diagonalLinesGenerator('value(site_3_1)', [
            {
                primaryColor: 'value(site_3_2)',
                patternDirection: 1,
                primaryWidth: 5,
                secondaryWidth: 1,
            },
            {
                primaryColor: 'value(site_3_2)',
                patternDirection: 3,
                primaryWidth: 5,
                secondaryWidth: 1,
            },
        ]),
        diagonalLinesGenerator('value(site_4_1)', [
            {
                primaryColor: 'value(site_4_2)',
                patternDirection: 1,
                primaryWidth: 5,
                secondaryWidth: 1,
            },
            {
                primaryColor: 'value(site_4_2)',
                patternDirection: 3,
                primaryWidth: 5,
                secondaryWidth: 1,
            },
            {
                primaryColor: 'value(site_4_2)',
                patternDirection: 2,
                primaryWidth: 5,
                secondaryWidth: 1,
            },
            {
                primaryColor: 'value(site_4_2)',
                patternDirection: 0,
                primaryWidth: 5,
                secondaryWidth: 1,
            },
        ]),
        {
            background:
                'linear-gradient(315deg, value(site_2_1) 17%, rgba(253, 240, 183, 0) 5%), linear-gradient(45deg, #ffc498 15%, rgba(253, 240, 183, 0) 5%), linear-gradient(315deg, value(site_5_1) 23%, rgba(253, 240, 183, 0) 5%), linear-gradient(45deg, #96fff3 21%, rgba(253, 240, 183, 0) 5%), linear-gradient(315deg, value(site_5_5) 28%, rgba(253, 240, 183, 0) 17%), linear-gradient(45deg, value(site_2_5) 28%, rgba(253, 240, 183, 0) 19%), linear-gradient(315deg, #d87aff 34%, rgba(253, 240, 183, 0) 25%), linear-gradient(45deg, #ffc498 34%, rgba(253, 240, 183, 0) 25%), linear-gradient(315deg, #96fff3 40%, rgba(253, 240, 183, 0) 25%), linear-gradient(45deg, #fdf0b7 40%, rgba(253, 240, 183, 0) 25%), radial-gradient(circle at 51% 30%, value(site_1_1) 25%, #a4ffcb 17%), value(site_1_1)',
        },
    ],
    props: [/background(?!-(blend-mode))/],
    optional: [
        // "isolation",
        // /background-(attachment|blend-mode|clip|size)/,
        // /mask/,
        // "mix-blend-mode",
        // "object-fit",
        // "object-position"
    ],
};
export default fillBlockVariants;
