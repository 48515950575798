export enum SideControlKey {
    Width = 'width',
    Fill = 'fill',
    Style = 'style',
}

export enum BorderType {
    Solid = 0,
    Gradient,
    Image,
    Pattern,
}

interface SideControlOptions {
    selected: boolean;
    borderType: BorderType;
    noResizing?: boolean;
}

interface SideControlConfiguration {
    // tooltipKey: string;
    // horizontalAdjust?: number;
    include: boolean;
}

export type SideControlConfigurationMap = Record<SideControlKey, SideControlConfiguration>;

export const getSideControlConfiguration = ({
    selected,
    borderType,
    noResizing,
}: SideControlOptions): SideControlConfigurationMap => {
    // const borderTranslationKeys = StylablePanelTranslationKeys.controller.borders;
    const solid = borderType === BorderType.Solid;
    const image = borderType === BorderType.Image || borderType === BorderType.Pattern;
    return {
        [SideControlKey.Width]: {
            include: !noResizing,
        },
        [SideControlKey.Fill]: {
            include: selected || image || !!noResizing,
        },
        [SideControlKey.Style]: {
            include: selected && solid,
        },
    };
};

export const getSideControls = (sideControlConfiguration: SideControlConfigurationMap) => {
    return (Object.keys(sideControlConfiguration) as SideControlKey[]).filter(
        (sideControlKey) => sideControlConfiguration[sideControlKey].include
    );
};
