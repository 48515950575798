export enum DataTypeType {
    Unknown = 'UNKNOWN',
    Common = 'COMMON',
    Universal = '<universal>',
    Number = '<number>',
    Length = '<length>',
    Percentage = '<percentage>',
    LengthPercentage = '<length-percentage>',
    Angle = '<angle>',
    Width = '<width>',
    CustomIdent = '<custom-ident>',
    LineStyle = '<line-style>',
    OutlineStyle = "<'outline-style'>",
    LineWidth = '<line-width>',
    Color = '<color>',
    OutlineColor = "<'outline-color'>",
    BorderImageSlice = "<'border-image-slice'>",
    BorderImageOutset = "<'border-image-outset'>",
    BorderImageRepeat = "<'border-image-repeat'>",
    BorderImageWidth = "<'border-image-width'>",
    Gradient = '<gradient>',
    Image = '<image>',
    ImageSource = '<image-source>',
    BgPosition = '<bg-position>',
    BgSize = '<bg-size>',
    RepeatStyle = '<repeat-style>',
    Attachment = '<attachment>',
    Box = '<box>',
    FontStyle = '<font-style>',
    FontVariant = '<font-variant>',
    FontWeight = '<font-weight>',
    FontStretch = '<font-stretch>',
    FontSize = '<font-size>',
    LineHeight = '<line-height>',
    FontFamily = '<font-family>',
    Font = '<font>',
    AlignContent = "<'align-content'>",
    JustifyContent = "<'justify-content'>",
    AlignItems = "<'align-items'>",
    JustifyItems = "<'justify-items'>",
    FlexGrow = '<flex-grow>',
    FlexShrink = '<flex-shrink>',
    FlexBasis = '<flex-basis>',
    FlexDirection = '<flex-direction>',
    FlexWrap = '<flex-wrap>',
    Flex = "<'flex'>",
    GridLine = '<grid-line>',
    GridLineEnd = '<grid-line-end>',
    LineNames = '<line-names>',
    TrackList = '<track-list>',
    AutoTrackList = '<auto-track-list>',
    ExplicitTrackList = '<explicit-track-list>',
    TrackListWithStrings = '<track-list-with-strings>',
    GridTemplateAreas = '<grid-template-areas>',
    GridTemplateRows = '<grid-template-rows>',
    GridTemplateColumns = '<grid-template-columns>',
    GridTemplate = '<grid-template>',
    GridAutoFlow = '<grid-auto-flow>',
    GridAutoRows = '<grid-auto-rows>',
    GridAutoColumns = '<grid-auto-columns>',
    Grid = '<grid>',
    Overflow = '<overflow>',
    TextDecorationLine = "<'text-decoration-line'>",
    TextDecorationStyle = "<'text-decoration-style'>",
    ListStyle = "<'list-style'>",
    ListStylePosition = "<'list-style-position'>",
    ListStyleType = "<'list-style-type'>",
    Inset = "<'inset'>",
    Shadow = '<shadow>',
}

export type KeywordsMap = Map<string, boolean>;
export const keywordsMap = (keywords: string[]): KeywordsMap => new Map(keywords.map((keyword) => [keyword, true]));

export interface DataTypeData {
    initial?: string;
    keywords?: string | KeywordsMap;
    prefix?: DataTypeType;
}
export const DATA_TYPES_MAP = new Map<DataTypeType, DataTypeData>();

export const DEFAULT_LAYER_SEPERATOR = ',';
export const INTERNAL_VALUE_SEPERATOR = '/';

export const AUTO_KEYWORD = 'auto';
export const NONE_KEYWORD = 'none';
export const NORMAL_KEYWORD = 'normal';

// <universal>
export const INITIAL_UNIVERSAL = 'initial';
export const UNIVERSAL_KEYWORDS = keywordsMap(['inherit', INITIAL_UNIVERSAL, 'unset', 'revert']);
DATA_TYPES_MAP.set(DataTypeType.Universal, {
    initial: INITIAL_UNIVERSAL,
    keywords: UNIVERSAL_KEYWORDS,
});

export const getInitialValue = (dataType: DataTypeType) => DATA_TYPES_MAP.get(dataType)?.initial ?? INITIAL_UNIVERSAL;

// <length> / <length-percentage> / <width> / <margin> / <padding> / <border-radius>
export const DEFAULT_DIMENSION = '0';
DATA_TYPES_MAP.set(DataTypeType.Length, { initial: DEFAULT_DIMENSION });
DATA_TYPES_MAP.set(DataTypeType.LengthPercentage, { initial: DEFAULT_DIMENSION });
DATA_TYPES_MAP.set(DataTypeType.Width, { initial: DEFAULT_DIMENSION });

// <length>
const ABSOLUTE_LENGTH_UNITS = ['px', 'cm', 'mm', 'Q', 'in', 'pc', 'pt'];
const RELATIVE_LENGTH_UNITS = [
    'em',
    'rem',
    'vw',
    'vh',
    'vi',
    'vb',
    'vmin',
    'vmax',
    'ex',
    'cap',
    'ch',
    'ic',
    'lh',
    'rlh',
    'cqw',
    'cqh',
    'cqi',
    'cqb',
    'cqmin',
    'cqmax',
];
export const SCALED_LENGTH_UNITS = ['spx'];
export const LENGTH_UNITS = [...ABSOLUTE_LENGTH_UNITS,...RELATIVE_LENGTH_UNITS, ...SCALED_LENGTH_UNITS];
export const LENGTH_UNITS_MAP = keywordsMap(LENGTH_UNITS);
export const CALC_FUNCTION = 'calc';
export const SPX_FORMATTER = 'spx';

// <percentage>
export const PERCENTAGE_UNIT = '%';
export const LENGTH_PERCENTAGE_UNITS = keywordsMap(LENGTH_UNITS.concat(PERCENTAGE_UNIT));

// <angle>
export const ANGLE_UNITS = keywordsMap(['deg', 'grad', 'rad', 'turn']);

// <flex>
export const FLEX_UNIT = 'fr';
export const FLEX_NUMBER_RANGE_MIN = 0;

// <line-style>
export const LINE_STYLE_KEYWORDS = keywordsMap([
    NONE_KEYWORD,
    'hidden',
    'dotted',
    'dashed',
    'solid',
    'double',
    'groove',
    'ridge',
    'inset',
    'outset',
]);
export const INITIAL_LINE_STYLE = NONE_KEYWORD;
DATA_TYPES_MAP.set(DataTypeType.LineStyle, {
    initial: INITIAL_LINE_STYLE,
    keywords: LINE_STYLE_KEYWORDS,
});
DATA_TYPES_MAP.set(DataTypeType.OutlineStyle, { initial: INITIAL_LINE_STYLE });

// <line-width>
export const LINE_WIDTH_KEYWORDS = keywordsMap(['thin', 'medium', 'thick']);
export const INITIAL_LINE_WIDTH = 'medium';
DATA_TYPES_MAP.set(DataTypeType.LineWidth, { initial: INITIAL_LINE_WIDTH });

// <color>
export const COLOR_SPACE_FUNCTIONS = keywordsMap(['rgb', 'rgba', 'hsl', 'hsla', 'hexAlpha']);
// <named-color>
const NAMED_COLOR_KEYWORDS = [
    'transparent',
    'aliceblue',
    'antiquewhite',
    'aqua',
    'aquamarine',
    'azure',
    'beige',
    'bisque',
    'black',
    'blanchedalmond',
    'blue',
    'blueviolet',
    'brown',
    'burlywood',
    'cadetblue',
    'chartreuse',
    'chocolate',
    'coral',
    'cornflowerblue',
    'cornsilk',
    'crimson',
    'cyan',
    'darkblue',
    'darkcyan',
    'darkgoldenrod',
    'darkgray',
    'darkgreen',
    'darkgrey',
    'darkkhaki',
    'darkmagenta',
    'darkolivegreen',
    'darkorange',
    'darkorchid',
    'darkred',
    'darksalmon',
    'darkseagreen',
    'darkslateblue',
    'darkslategray',
    'darkslategrey',
    'darkturquoise',
    'darkviolet',
    'deeppink',
    'deepskyblue',
    'dimgray',
    'dimgrey',
    'dodgerblue',
    'firebrick',
    'floralwhite',
    'forestgreen',
    'fuchsia',
    'gainsboro',
    'ghostwhite',
    'gold',
    'goldenrod',
    'gray',
    'green',
    'greenyellow',
    'grey',
    'honeydew',
    'hotpink',
    'indianred',
    'indigo',
    'ivory',
    'khaki',
    'lavender',
    'lavenderblush',
    'lawngreen',
    'lemonchiffon',
    'lightblue',
    'lightcoral',
    'lightcyan',
    'lightgoldenrodyellow',
    'lightgray',
    'lightgreen',
    'lightgrey',
    'lightpink',
    'lightsalmon',
    'lightseagreen',
    'lightskyblue',
    'lightslategray',
    'lightslategrey',
    'lightsteelblue',
    'lightyellow',
    'lime',
    'limegreen',
    'linen',
    'magenta',
    'maroon',
    'mediumaquamarine',
    'mediumblue',
    'mediumorchid',
    'mediumpurple',
    'mediumseagreen',
    'mediumslateblue',
    'mediumspringgreen',
    'mediumturquoise',
    'mediumvioletred',
    'midnightblue',
    'mintcream',
    'mistyrose',
    'moccasin',
    'navajowhite',
    'navy',
    'oldlace',
    'olive',
    'olivedrab',
    'orange',
    'orangered',
    'orchid',
    'palegoldenrod',
    'palegreen',
    'paleturquoise',
    'palevioletred',
    'papayawhip',
    'peachpuff',
    'peru',
    'pink',
    'plum',
    'powderblue',
    'purple',
    'rebeccapurple',
    'red',
    'rosybrown',
    'royalblue',
    'saddlebrown',
    'salmon',
    'sandybrown',
    'seagreen',
    'seashell',
    'sienna',
    'silver',
    'skyblue',
    'slateblue',
    'slategray',
    'slategrey',
    'snow',
    'springgreen',
    'steelblue',
    'tan',
    'teal',
    'thistle',
    'tomato',
    'turquoise',
    'violet',
    'wheat',
    'white',
    'whitesmoke',
    'yellow',
    'yellowgreen',
];
const CURRENT_COLOR_KEYWORD = 'currentcolor';
// <deprecated-system-color>
const DEPRECATED_SYSTEM_COLOR_KEYWORD = [
    'ActiveBorder',
    'ActiveCaption',
    'AppWorkspace',
    'Background',
    'ButtonFace',
    'ButtonHighlight',
    'ButtonShadow',
    'ButtonText',
    'CaptionText',
    'GrayText',
    'Highlight',
    'HighlightText',
    'InactiveBorder',
    'InactiveCaption',
    'InactiveCaptionText',
    'InfoBackground',
    'InfoText',
    'Menu',
    'MenuText',
    'Scrollbar',
    'ThreeDDarkShadow',
    'ThreeDFace',
    'ThreeDHighlight',
    'ThreeDLightShadow',
    'ThreeDShadow',
    'Window',
    'WindowFrame',
    'WindowText',
];
export const COLOR_KEYWORDS = keywordsMap(
    NAMED_COLOR_KEYWORDS.concat(CURRENT_COLOR_KEYWORD).concat(DEPRECATED_SYSTEM_COLOR_KEYWORD)
);
export const INITIAL_COLOR = CURRENT_COLOR_KEYWORD;
DATA_TYPES_MAP.set(DataTypeType.Color, { initial: INITIAL_COLOR });

// <'outline-color'>
export const OUTLINE_COLOR_INVERT_KEYWORD = 'invert';
DATA_TYPES_MAP.set(DataTypeType.OutlineColor, { initial: INITIAL_COLOR });

// <background-color>
export const INITIAL_BACKGROUND_COLOR = 'transparent';

// <border-image-slice>
export const BORDER_IMAGE_SLICE_FILL_KEYWORD = 'fill';
export const INITIAL_BORDER_IMAGE_SLICE = '100%';
DATA_TYPES_MAP.set(DataTypeType.BorderImageSlice, { initial: INITIAL_BORDER_IMAGE_SLICE });

// <border-image-outset>
export const INITIAL_BORDER_IMAGE_OUTSET = DEFAULT_DIMENSION;
DATA_TYPES_MAP.set(DataTypeType.BorderImageOutset, {
    initial: INITIAL_BORDER_IMAGE_OUTSET,
    prefix: DataTypeType.BorderImageWidth,
});

// <border-image-repeat>
export const BORDER_IMAGE_REPEAT_MULTIPLE_KEYWORDS = keywordsMap(['stretch', 'repeat', 'round', 'space']);
export const INITIAL_BORDER_IMAGE_REPEAT = 'stretch';
DATA_TYPES_MAP.set(DataTypeType.BorderImageRepeat, { initial: INITIAL_BORDER_IMAGE_REPEAT });

// <border-image-width>
export const INITIAL_BORDER_IMAGE_WIDTH = '1';
DATA_TYPES_MAP.set(DataTypeType.BorderImageWidth, {
    initial: INITIAL_BORDER_IMAGE_WIDTH,
    prefix: DataTypeType.BorderImageSlice,
});

// <gradient>
export const GRADIENT_FUNCTIONS = keywordsMap([
    'linear-gradient',
    'repeating-linear-gradient',
    'radial-gradient',
    'repeating-radial-gradient',
    'conic-gradient',
]);

// <image>
export const IMAGE_FUNCTIONS = keywordsMap(['url', 'image', 'image-set', 'element', 'paint', 'cross-fade']);

// <image-source>
export const IMAGE_SOURCE_NONE_KEYWORD = NONE_KEYWORD;
export const INITIAL_IMAGE_SOURCE = IMAGE_SOURCE_NONE_KEYWORD;
DATA_TYPES_MAP.set(DataTypeType.ImageSource, { initial: INITIAL_IMAGE_SOURCE });

// <bg-position>
export const BG_POSITION_CENTER_KEYWORD = 'center';
export const BG_POSITION_VERTICAL_KEYWORDS = ['top', 'bottom'];
export const BG_POSITION_VERTICAL_KEYWORDS_MAP = keywordsMap(BG_POSITION_VERTICAL_KEYWORDS);
export const BG_POSITION_HORIZONTAL_KEYWORDS = ['left', 'right'];
export const BG_POSITION_HORIZONTAL_KEYWORDS_MAP = keywordsMap(BG_POSITION_HORIZONTAL_KEYWORDS);
export const BG_POSITION_ALL_EDGES_KEYWORDS = keywordsMap(
    BG_POSITION_HORIZONTAL_KEYWORDS.concat(BG_POSITION_VERTICAL_KEYWORDS)
);
export const INITIAL_BG_POSITION = '0% 0%';
DATA_TYPES_MAP.set(DataTypeType.BgPosition, { initial: INITIAL_BG_POSITION });

// <bg-size>
export const BG_SIZE_KEYWORDS = keywordsMap(['cover', 'contain']);
export const INITIAL_BG_SIZE = AUTO_KEYWORD;
DATA_TYPES_MAP.set(DataTypeType.BgSize, {
    initial: INITIAL_BG_SIZE,
    prefix: DataTypeType.BgPosition,
});

// <repeat-style>
export const REPEAT_STYLE_SINGLE_KEYWORDS = keywordsMap(['repeat-x', 'repeat-y']);
export const REPEAT_STYLE_MULTIPLE_KEYWORDS = keywordsMap(['repeat', 'space', 'round', 'no-repeat']);
export const INITIAL_REPEAT_STYLE = 'repeat';
DATA_TYPES_MAP.set(DataTypeType.RepeatStyle, { initial: INITIAL_REPEAT_STYLE });

// <attachment>
export const ATTACHMENT_KEYWORDS = keywordsMap(['scroll', 'fixed', 'local']);
export const INITIAL_ATTACHMENT = 'scroll';
DATA_TYPES_MAP.set(DataTypeType.Attachment, {
    initial: INITIAL_ATTACHMENT,
    keywords: ATTACHMENT_KEYWORDS,
});

// <box>
export const BOX_KEYWORDS = keywordsMap(['border-box', 'padding-box', 'content-box']);
export const INITIAL_BACKGROUND_ORIGIN = 'padding-box';
export const INITIAL_BACKGROUND_CLIP = 'border-box';
DATA_TYPES_MAP.set(DataTypeType.Box, { keywords: BOX_KEYWORDS });

// <font>
export const FONT_SINGLE_VALUE_KEYWORDS = keywordsMap([
    'caption',
    'icon',
    'menu',
    'message-box',
    'small-caption',
    'status-bar',
]);
DATA_TYPES_MAP.set(DataTypeType.Font, { keywords: FONT_SINGLE_VALUE_KEYWORDS });

export const COMMON_FONT_PREFIX_NORMAL = NORMAL_KEYWORD;

// <font-style>
export const FONT_STYLE_KEYWORDS = keywordsMap([COMMON_FONT_PREFIX_NORMAL, 'italic']);
export const FONT_STYLE_OBLIQUE_KEYWORD = 'oblique';
export const INITIAL_FONT_STYLE = COMMON_FONT_PREFIX_NORMAL;
DATA_TYPES_MAP.set(DataTypeType.FontStyle, { initial: INITIAL_FONT_STYLE });

// <font-variant>
const FONT_VARIANT_LIGATURES_KEYWORDS = [
    'common-ligatures',
    'no-common-ligatures',
    'discretionary-ligatures',
    'no-discretionary-ligatures',
    'historical-ligatures',
    'no-historical-ligatures',
    'contextual',
    'no-contextual',
];
const FONT_VARIANT_CAPS_KEYWORDS = [
    'small-caps',
    'all-small-caps',
    'petite-caps',
    'all-petite-caps',
    'unicase',
    'titling-caps',
];
const FONT_VARIANT_NUMERIC_KEYWORDS = [
    'lining-nums',
    'oldstyle-nums',
    'proportional-nums',
    'tabular-nums',
    'diagonal-fractions',
    'stacked-fractions',
    'ordinal',
    'slashed-zero',
];
const FONT_VARIANT_EAST_ASIAN_KEYWORDS = [
    'jis78',
    'jis83',
    'jis90',
    'jis04',
    'simplified',
    'traditional',
    'full-width',
    'proportional-width',
    'ruby',
];
export const FONT_VARIANT_KEYWORDS = keywordsMap(
    [COMMON_FONT_PREFIX_NORMAL, NONE_KEYWORD]
        .concat(FONT_VARIANT_LIGATURES_KEYWORDS)
        .concat(FONT_VARIANT_CAPS_KEYWORDS)
        .concat(FONT_VARIANT_NUMERIC_KEYWORDS)
        .concat(FONT_VARIANT_EAST_ASIAN_KEYWORDS)
);
export const INITIAL_FONT_VARIANT = COMMON_FONT_PREFIX_NORMAL;
DATA_TYPES_MAP.set(DataTypeType.FontVariant, {
    initial: INITIAL_FONT_VARIANT,
    keywords: FONT_VARIANT_KEYWORDS,
});

// <font-weight>
const FONT_WEIGHT_ABSOLUTE_KEYWORDS = [COMMON_FONT_PREFIX_NORMAL, 'bold'];
const FONT_WEIGHT_RELATIVE_KEYWORDS = ['lighter', 'bolder'];
export const FONT_WEIGHT_KEYWORDS = keywordsMap(FONT_WEIGHT_ABSOLUTE_KEYWORDS.concat(FONT_WEIGHT_RELATIVE_KEYWORDS));
export const FONT_WEIGHT_NUMBER_RANGE_MIN = 1;
export const FONT_WEIGHT_NUMBER_RANGE_MAX = 1000;
export const INITIAL_FONT_WEIGHT = COMMON_FONT_PREFIX_NORMAL;
DATA_TYPES_MAP.set(DataTypeType.FontWeight, { initial: INITIAL_FONT_WEIGHT });

// <font-stretch>
export const FONT_STRETCH_KEYWORDS = keywordsMap([
    COMMON_FONT_PREFIX_NORMAL,
    'ultra-condensed',
    'extra-condensed',
    'condensed',
    'semi-condensed',
    'semi-expanded',
    'expanded',
    'extra-expanded',
    'ultra-expanded',
]);
export const INITIAL_FONT_STRETCH = COMMON_FONT_PREFIX_NORMAL;
DATA_TYPES_MAP.set(DataTypeType.FontStretch, {
    initial: INITIAL_FONT_STRETCH,
    keywords: FONT_STRETCH_KEYWORDS,
});

// <font-size>
const FONT_SIZE_ABSOLUTE_KEYWORDS = [
    'xx-small',
    'x-small',
    'small',
    'medium',
    'large',
    'x-large',
    'xx-large',
    'xxx-large',
];
const FONT_SIZE_RELATIVE_KEYWORDS = ['larger', 'smaller'];
export const FONT_SIZE_KEYWORDS = keywordsMap(FONT_SIZE_ABSOLUTE_KEYWORDS.concat(FONT_SIZE_RELATIVE_KEYWORDS));
export const INITIAL_FONT_SIZE = 'medium';
DATA_TYPES_MAP.set(DataTypeType.FontSize, { initial: INITIAL_FONT_SIZE });

// <line-height>
export const LINE_HEIGHT_KEYWORD = NORMAL_KEYWORD;
export const INITIAL_LINE_HEIGHT = LINE_HEIGHT_KEYWORD;
DATA_TYPES_MAP.set(DataTypeType.LineHeight, {
    initial: INITIAL_LINE_HEIGHT,
    prefix: DataTypeType.FontSize,
});

// <font-family>
export const FONT_FAMILY_GENERIC_KEYWORDS = keywordsMap([
    'serif',
    'sans-serif',
    'cursive',
    'fantasy',
    'monospace',
    'system-ui',
    'ui-serif',
    'ui-sans-serif',
    'ui-monospace',
    'ui-rounded',
    'emoji',
    'math',
    'fangsong',
]);
export const INITIAL_FONT_FAMILY = '';
DATA_TYPES_MAP.set(DataTypeType.FontFamily, { initial: INITIAL_FONT_FAMILY });

// <baseline-position>
const BASELINE_KEYWORD = 'baseline';

const STRETCH_KEYWORD = 'stretch';

// <content-distribution>
export const CONTENT_DISTRIBUTION_KEYWORDS = keywordsMap([
    'space-between',
    'space-around',
    'space-evenly',
    STRETCH_KEYWORD,
]);

// <content-position>
const CONTENT_POSITION_LIST = ['center', 'flex-start', 'flex-end'];
export const CONTENT_POSITION_KEYWORDS = keywordsMap(CONTENT_POSITION_LIST);

// <align-content>
export const ALIGN_CONTENT_KEYWORDS = keywordsMap([NORMAL_KEYWORD, BASELINE_KEYWORD]);
export const INITIAL_ALIGN_CONTENT = NORMAL_KEYWORD;
DATA_TYPES_MAP.set(DataTypeType.AlignContent, { initial: INITIAL_ALIGN_CONTENT });

// <self-position>
export const SELF_POSITION_KEYWORDS = keywordsMap(
    CONTENT_POSITION_LIST.concat(['start', 'end', 'self-start', 'self-end'])
);

// <justify-content>
export const JUSTIFY_CONTENT_KEYWORDS = keywordsMap([NORMAL_KEYWORD, 'left', 'right']);
export const INITIAL_JUSTIFY_CONTENT = NORMAL_KEYWORD;
DATA_TYPES_MAP.set(DataTypeType.JustifyContent, { initial: INITIAL_JUSTIFY_CONTENT });

// <align-items>
export const ALIGN_ITEMS_KEYWORDS = keywordsMap([NORMAL_KEYWORD, STRETCH_KEYWORD, BASELINE_KEYWORD]);
export const INITIAL_ALIGN_ITEMS = NORMAL_KEYWORD;
DATA_TYPES_MAP.set(DataTypeType.AlignItems, { initial: INITIAL_ALIGN_ITEMS });

// <justify-items>
export const JUSTIFY_ITEMS_KEYWORDS = keywordsMap(['legacy', 'left', 'right', 'center']);
export const INITIAL_JUSTIFY_ITEMS = 'legacy';
DATA_TYPES_MAP.set(DataTypeType.JustifyItems, { initial: INITIAL_JUSTIFY_ITEMS });

// <'flex'>
export const FLEX_SINGLE_VALUE_KEYWORDS = keywordsMap([INITIAL_UNIVERSAL, AUTO_KEYWORD, NONE_KEYWORD]);
DATA_TYPES_MAP.set(DataTypeType.Flex, { keywords: FLEX_SINGLE_VALUE_KEYWORDS });

// <flex-grow>
export const INITIAL_FLEX_GROW = DEFAULT_DIMENSION;
export const AUTO_FLEX_GROW = '1';
DATA_TYPES_MAP.set(DataTypeType.FlexGrow, { initial: INITIAL_FLEX_GROW });

// <flex-shrink>
export const INITIAL_FLEX_SHRINK = '1';
export const NONE_FLEX_SHRINK = DEFAULT_DIMENSION;
DATA_TYPES_MAP.set(DataTypeType.FlexShrink, { initial: INITIAL_FLEX_SHRINK });

// <flex-basis>
const FLEX_BASIS_CONTENT_KEYWORD = 'content';
const FLEX_BASIS_INTRINSIC_SIZING_KEYWORDS = ['fill', 'max-content', 'min-content', 'fit-content'];
export const FLEX_BASIS_KEYWORDS = keywordsMap(FLEX_BASIS_INTRINSIC_SIZING_KEYWORDS.concat(FLEX_BASIS_CONTENT_KEYWORD));
export const AUTO_FLEX_BASIS = AUTO_KEYWORD;
export const INITIAL_FLEX_BASIS = DEFAULT_DIMENSION;
DATA_TYPES_MAP.set(DataTypeType.FlexBasis, { initial: INITIAL_FLEX_BASIS });

// <flex-direction>
export const FLEX_DIRECTION_KEYWORDS = keywordsMap(['row', 'row-reverse', 'column', 'column-reverse']);
export const INITIAL_FLEX_DIRECTION = 'row';
DATA_TYPES_MAP.set(DataTypeType.FlexDirection, {
    initial: INITIAL_FLEX_DIRECTION,
    keywords: FLEX_DIRECTION_KEYWORDS,
});

// <flex-wrap>
export const FLEX_WRAP_KEYWORDS = keywordsMap(['nowrap', 'wrap', 'wrap-reverse']);
export const INITIAL_FLEX_WRAP = 'nowrap';
DATA_TYPES_MAP.set(DataTypeType.FlexWrap, {
    initial: INITIAL_FLEX_WRAP,
    keywords: FLEX_WRAP_KEYWORDS,
});

// <grid-line>
export const GRID_LINE_SPAN_KEYWORD = 'span';
export const GRID_LINE_AUTO_KEYWORD = AUTO_KEYWORD;
export const INITIAL_GRID_LINE = AUTO_KEYWORD;
DATA_TYPES_MAP.set(DataTypeType.GridLine, { initial: INITIAL_GRID_LINE });
DATA_TYPES_MAP.set(DataTypeType.GridLineEnd, {
    initial: INITIAL_GRID_LINE,
    prefix: DataTypeType.GridLine,
});

// <track-breadth> / <inflexible-breadth>
export const BREADTH_KEYWORDS = keywordsMap(['min-content', 'max-content', AUTO_KEYWORD]);

// <line-names>
export const LINE_NAMES_EXCLUDE_KEYWORDS = keywordsMap(['span', AUTO_KEYWORD]);

export const MINMAX_FUNCTION = 'minmax';
export const REPEAT_FUNCTION = 'repeat';

// <track-size>
export const TRACK_SIZE_FUNCTIONS = keywordsMap([MINMAX_FUNCTION, 'fit-content']);
export const INITIAL_TRACK_SIZE = AUTO_KEYWORD;

// <explicit-track-list>
DATA_TYPES_MAP.set(DataTypeType.ExplicitTrackList, { prefix: DataTypeType.GridTemplateRows });

// <auto-repeat>
export const AUTO_REPEAT_FIRST_ARGUMENT_KEYWORDS = keywordsMap(['auto-fill', 'auto-fit']);

// <grid-template-areas>
export const GRID_TEMPLATE_AREAS_NONE_KEYWORD = NONE_KEYWORD;
export const INITIAL_GRID_TEMPLATE_AREAS = GRID_TEMPLATE_AREAS_NONE_KEYWORD;
DATA_TYPES_MAP.set(DataTypeType.GridTemplateAreas, { initial: INITIAL_GRID_TEMPLATE_AREAS });

// <grid-template-rows> / <grid-template-columns>
export const GRID_TEMPLATE_AXIS_NONE_KEYWORD = NONE_KEYWORD;
export const INITIAL_GRID_TEMPLATE_AXIS = GRID_TEMPLATE_AXIS_NONE_KEYWORD;
DATA_TYPES_MAP.set(DataTypeType.GridTemplateRows, { initial: INITIAL_GRID_TEMPLATE_AXIS });
DATA_TYPES_MAP.set(DataTypeType.GridTemplateColumns, {
    initial: INITIAL_GRID_TEMPLATE_AXIS,
    prefix: DataTypeType.GridTemplateRows,
});

// <grid-template>
export const GRID_TEMPLATE_SINGLE_VALUE_KEYWORD = NONE_KEYWORD;
DATA_TYPES_MAP.set(DataTypeType.GridTemplate, { keywords: GRID_TEMPLATE_SINGLE_VALUE_KEYWORD });

// <grid-auto-flow>
export const GRID_AUTO_FLOW_KEYWORD = 'auto-flow';
export const GRID_AUTO_FLOW_ROW_KEYWORD = 'row';
export const GRID_AUTO_FLOW_COLUMN_KEYWORD = 'column';
export const GRID_AUTO_FLOW_DENSE_KEYWORD = 'dense';
export const INITIAL_GRID_AUTO_FLOW = GRID_AUTO_FLOW_ROW_KEYWORD;
DATA_TYPES_MAP.set(DataTypeType.GridAutoFlow, { initial: INITIAL_GRID_AUTO_FLOW });

// <grid-auto-rows> / <grid-auto-columns>
export const INITIAL_GRID_AUTO_AXIS = AUTO_KEYWORD;
DATA_TYPES_MAP.set(DataTypeType.GridAutoRows, { initial: INITIAL_GRID_AUTO_AXIS });
DATA_TYPES_MAP.set(DataTypeType.GridAutoColumns, { initial: INITIAL_GRID_AUTO_AXIS });

// <grid>
export const GRID_SINGLE_VALUE_KEYWORD = NONE_KEYWORD;
DATA_TYPES_MAP.set(DataTypeType.Grid, { keywords: GRID_SINGLE_VALUE_KEYWORD });

// <overflow>
export const OVERFLOW_KEYWORDS = keywordsMap(['visible', 'hidden', 'clip', 'scroll', AUTO_KEYWORD]);
export const INITIAL_OVERFLOW = 'visible';
DATA_TYPES_MAP.set(DataTypeType.Overflow, {
    initial: INITIAL_OVERFLOW,
    keywords: OVERFLOW_KEYWORDS,
});

// <text-decoration-line>
export const TEXT_DECORATION_LINE_NONE_KEYWORD = NONE_KEYWORD;
export const TEXT_DECORATION_LINE_KEYWORDS = keywordsMap(['underline', 'overline', 'line-through']);
export const INITIAL_TEXT_DECORATION_LINE = TEXT_DECORATION_LINE_NONE_KEYWORD;
DATA_TYPES_MAP.set(DataTypeType.TextDecorationLine, { initial: INITIAL_TEXT_DECORATION_LINE });

// <text-decoration-style>
export const TEXT_DECORATION_STYLE_KEYWORDS = keywordsMap(['solid', 'double', 'dotted', 'dashed', 'wavy']);
export const INITIAL_TEXT_DECORATION_STYLE = 'solid';
DATA_TYPES_MAP.set(DataTypeType.TextDecorationStyle, {
    initial: INITIAL_TEXT_DECORATION_STYLE,
    keywords: TEXT_DECORATION_STYLE_KEYWORDS,
});

// <list-style>
export const LIST_STYLE_SINGLE_VALUE_KEYWORD = NONE_KEYWORD;
DATA_TYPES_MAP.set(DataTypeType.ListStyle, { keywords: LIST_STYLE_SINGLE_VALUE_KEYWORD });

// <list-style-position>
export const LIST_STYLE_POSITION_KEYWORDS = keywordsMap(['inside', 'outside']);
export const INITIAL_LIST_STYLE_POSITION = 'outside';
DATA_TYPES_MAP.set(DataTypeType.ListStylePosition, {
    initial: INITIAL_LIST_STYLE_POSITION,
    keywords: LIST_STYLE_POSITION_KEYWORDS,
});

// <list-style-type>
export const LIST_STYLE_TYPE_KEYWORDS = keywordsMap([
    NONE_KEYWORD,
    'disc',
    'circle',
    'square',
    'decimal',
    'cjk-decimal',
    'decimal-leading-zero',
    'lower-roman',
    'upper-roman',
    'lower-greek',
    'lower-alpha',
    'lower-latin',
    'upper-alpha',
    'upper-latin',
    'arabic-indic',
    '-moz-arabic-indic',
    'armenian',
    'bengali',
    '-moz-bengali',
    'cambodian',
    'khmer',
    'cjk-earthly-branch',
    '-moz-cjk-earthly-branch',
    'cjk-heavenly-stem',
    '-moz-cjk-heavenly-stem',
    'cjk-ideographic',
    'devanagari',
    '-moz-devanagari',
    'ethiopic-numeric',
    'georgian',
    'gujarati',
    '-moz-gujarati',
    'gurmukhi',
    '-moz-gurmukhi',
    'hebrew',
    'hiragana',
    'hiragana-iroha',
    'japanese-formal',
    'japanese-informal',
    'kannada',
    '-moz-kannada',
    'katakana',
    'katakana-iroha',
    'korean-hangul-formal',
    'korean-hanja-formal',
    'korean-hanja-informal',
    'lao',
    '-moz-lao',
    'lower-armenian',
    'malayalam',
    '-moz-malayalam',
    'mongolian',
    'myanmar',
    '-moz-myanmar',
    'oriya',
    '-moz-oriya',
    'persian',
    '-moz-persian',
    'simp-chinese-formal',
    'simp-chinese-informal',
    'tamil',
    '-moz-tamil',
    'telugu',
    '-moz-telugu',
    'thai',
    '-moz-thai',
    'tibetan',
    'trad-chinese-formal',
    'trad-chinese-informal',
    'upper-armenian',
    'disclosure-open',
    'disclosure-closed',
    '-moz-ethiopic-halehame',
    '-moz-ethiopic-halehame-am',
    'ethiopic-halehame-ti-er',
    '-moz-ethiopic-halehame-ti-er',
    'ethiopic-halehame-ti-et',
    '-moz-ethiopic-halehame-ti-et',
    'hangul',
    '-moz-hangul',
    'hangul-consonant',
    '-moz-hangul-consonant',
    'urdu',
    '-moz-urdu',
]);
export const INITIAL_LIST_STYLE_TYPE = 'disc';
DATA_TYPES_MAP.set(DataTypeType.ListStyleType, { initial: INITIAL_LIST_STYLE_TYPE });

// <inset>
export const INSET_KEYWORD = 'inset';
DATA_TYPES_MAP.set(DataTypeType.Inset, { keywords: INSET_KEYWORD });

// <shadow>
export const SHADOW_SINGLE_VALUE_KEYWORDS = keywordsMap([NONE_KEYWORD]);
DATA_TYPES_MAP.set(DataTypeType.Shadow, { keywords: SHADOW_SINGLE_VALUE_KEYWORDS });
