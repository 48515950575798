import { DEFAULT_PLANE } from '@wix/stylable-panel-common';
import type { CustomInputProps } from '@wix/stylable-panel-controllers';
import React from 'react';
import { classes, style } from './icon-radio-group-input.st.css';

export interface IconRadioGroupIcon {
    default: React.FC;
    defaultVertical?: React.FC;
    checked?: React.FC;
}

export interface IconRadioGroupOption {
    propValue: string;
    icon: IconRadioGroupIcon;
}

export const IconRadioGroupInputFactory = (
    options: IconRadioGroupOption[],
    defaultPropValue?: string,
    largeIcons?: boolean
): React.ComponentType<CustomInputProps> =>
    function IconRadioGroupInput({ className, onChange, plane = DEFAULT_PLANE, value = defaultPropValue }) {
        const renderIconRadioGroup = () =>
            options.map(({ propValue, icon }) => {
                const checked = propValue === value;
                const IconRadioGroup = checked && icon.checked ? icon.checked : icon.default;

                return (
                    <span
                        key={propValue}
                        className={style(classes.icon, { checked })}
                        onClick={() => onChange(propValue)}
                    >
                        <input
                            key={`${propValue}_radio`}
                            className={classes.hiddenInput}
                            type="radio"
                            name={propValue}
                            value={propValue}
                            checked={checked}
                            onChange={() => null}
                        />
                        <IconRadioGroup />
                    </span>
                );
            });

        return (
            <div
                className={style(
                    classes.root,
                    {
                        large: !!largeIcons,
                        plane,
                    },
                    className
                )}
            >
                {renderIconRadioGroup()}
            </div>
        );
    };
