import { useState, useRef, useCallback, useEffect } from 'react';

import {
    CSSCodeAst,
    Backgrounds,
    evaluateAst,
    getShorthandLayers,
    INITIAL_BACKGROUND_COLOR,
    INITIAL_IMAGE_SOURCE,
} from '@wix/shorthands-opener';

import {
    SiteVarsDriver,
    CalculatedBackgroundLayers,
    FullDeclarationMap,
    DEFAULT_EVAL_DECLARATION_VALUE,
} from '@wix/stylable-panel-drivers';

import type { OriginNode, EvaluatedAst, ParseShorthandAPI, OpenedDeclarationArray } from '../../declaration-types';
import { BackgroundLayer, BackgroundShorthandLayer, BackgroundProps } from './background-types';
import { getShorthandOpener, evalOpenedDeclarationValue, sanitizeOpenedDeclaration } from '../../utils';

const getCodeAstText = (ast: CSSCodeAst) => ast.text;
const getSimpleAstText = (simpleValue: EvaluatedAst) => getCodeAstText(simpleValue.value);
const getMultipleCodeAstText = (multipleAst: CSSCodeAst[]) =>
    multipleAst.map((value) => getCodeAstText(value)).join(' ');
const getMultipleAstText = (multipleValue: EvaluatedAst[]) =>
    multipleValue.map((value) => getSimpleAstText(value)).join(' ');
const getSimpleLayerPropText = (prop: keyof BackgroundShorthandLayer, layer: BackgroundShorthandLayer) =>
    getSimpleAstText(layer[prop] as EvaluatedAst);
const getMultipleLayerPropText = (prop: keyof BackgroundShorthandLayer, layer: BackgroundShorthandLayer) =>
    getMultipleAstText(layer[prop] as EvaluatedAst[]);
const getBackgroundImagesLayer = (layer: BackgroundShorthandLayer) =>
    ({
        'background-attachment': getSimpleLayerPropText('background-attachment', layer),
        'background-clip': getSimpleLayerPropText('background-clip', layer),
        'background-image': getSimpleLayerPropText('background-image', layer),
        'background-origin': getSimpleLayerPropText('background-origin', layer),
        'background-position': getMultipleLayerPropText('background-position', layer),
        'background-repeat': getMultipleLayerPropText('background-repeat', layer),
        'background-size': getMultipleLayerPropText('background-size', layer),
    } as Record<Backgrounds, string>);

function classifyImageLayer(value: FullDeclarationMap): BackgroundLayer {
    let type: BackgroundLayer = BackgroundLayer.Gradient;

    if (
        value.background === undefined &&
        value['background-image'] !== undefined &&
        !~value['background-image'].indexOf('gradient')
    ) {
        type = BackgroundLayer.Image;
    }

    return type;
}

export interface UseBackgroundLayersOptions {
    value: OpenedDeclarationArray<BackgroundProps>;
    shorthandApi: ParseShorthandAPI;
    stringifyExpression?: (v: OriginNode) => string;
    siteVarsDriver?: SiteVarsDriver;
}

export function useBackgroundLayers({
    value,
    shorthandApi,
    stringifyExpression,
    siteVarsDriver,
}: UseBackgroundLayersOptions) {
    // TODO: This should be deprecated and replaced completely with the background-driver logic
    const calcBackgroundLayers = useCallback((): CalculatedBackgroundLayers => {
        const evalDeclarationValue =
            siteVarsDriver?.evalDeclarationValue?.bind(siteVarsDriver) ?? DEFAULT_EVAL_DECLARATION_VALUE;
        const opener = getShorthandOpener('background');

        let backgroundLayers: CalculatedBackgroundLayers = { images: [] };
        let numImageLayers = 0;

        for (const decl of value) {
            if (decl.value.length === 0) {
                continue;
            }

            const shorthandLayers = getShorthandLayers(
                evaluateAst(sanitizeOpenedDeclaration(decl).value, shorthandApi)
            );

            switch (decl.name) {
                case 'background': {
                    backgroundLayers = { images: [] };

                    shorthandLayers.forEach((layer, index) => {
                        const shorthand = evalOpenedDeclarationValue(
                            layer.map((ast) => ast.value),
                            evalDeclarationValue,
                            stringifyExpression
                        );
                        let imagesLayer: Record<string, string> = {};

                        try {
                            const opened = opener(shorthand, shorthandApi);

                            if (index === shorthandLayers.length - 1) {
                                backgroundLayers.solid = getSimpleAstText(opened.color);
                            }

                            imagesLayer = getBackgroundImagesLayer(opened.layers[0]);
                        } catch (e) {
                            console.warn(e);
                            imagesLayer = {
                                background: getMultipleCodeAstText(shorthand as CSSCodeAst[]),
                            };
                        }

                        backgroundLayers.images.push(imagesLayer);
                    });

                    numImageLayers = backgroundLayers.images.length;

                    break;
                }
                case 'background-color': {
                    if (shorthandLayers[0]) {
                        backgroundLayers.solid = getSimpleAstText(shorthandLayers[0][0]);
                    }

                    break;
                }
                case 'background-image': {
                    shorthandLayers.forEach((value, i) => {
                        if (!backgroundLayers.images[i]) {
                            backgroundLayers.images[i] = {};
                        }

                        backgroundLayers.images[i]['background-image'] = getSimpleAstText(value[0]);
                    });

                    numImageLayers = shorthandLayers.length;

                    break;
                }
                case 'background-repeat':
                case 'background-attachment':
                case 'background-position':
                case 'background-clip':
                case 'background-origin':
                case 'background-size': {
                    for (let i = 0; i < Math.max(shorthandLayers.length, backgroundLayers.images.length); i++) {
                        if (!backgroundLayers.images[i]) {
                            backgroundLayers.images[i] = {};
                        }

                        const layers = shorthandLayers[i % shorthandLayers.length];
                        if (layers) {
                            backgroundLayers.images[i][decl.name] = getSimpleAstText(layers[0]);
                            if (
                                layers[1] &&
                                (decl.name === 'background-position' ||
                                    decl.name === 'background-size' ||
                                    decl.name === 'background-repeat')
                            ) {
                                backgroundLayers.images[i][decl.name] += ` ${getSimpleAstText(layers[1])}`;
                            }
                        }
                    }

                    break;
                }
            }
        }

        backgroundLayers.images = backgroundLayers.images.slice(0, numImageLayers);

        if (
            backgroundLayers.images.length > 0 &&
            backgroundLayers.images[backgroundLayers.images.length - 1]['background-image'] === INITIAL_IMAGE_SOURCE
        ) {
            // Delete last empty layer (if it only had background-color)
            backgroundLayers.images.pop();
        }

        if (backgroundLayers.solid === INITIAL_BACKGROUND_COLOR) {
            delete backgroundLayers.solid;
        }

        return backgroundLayers;
    }, [value, shorthandApi, stringifyExpression, siteVarsDriver]);

    const calcLayerTypes = (backgroundLayers: CalculatedBackgroundLayers) => {
        const showSolidLayer = !!backgroundLayers.solid || backgroundLayers.images.length === 0;
        const layerTypes: BackgroundLayer[] = backgroundLayers.images.map((layerValue) =>
            classifyImageLayer(layerValue)
        );
        if (showSolidLayer) {
            layerTypes.push(BackgroundLayer.Solid);
        }
        return layerTypes;
    };

    const backgroundLayers = useRef(calcBackgroundLayers());
    const layerTypes = useRef(calcLayerTypes(backgroundLayers.current));

    const [, refreshBackgroundLayers] = useState(false);

    useEffect(() => {
        backgroundLayers.current = calcBackgroundLayers();
        layerTypes.current = calcLayerTypes(backgroundLayers.current);
        refreshBackgroundLayers((toggle) => !toggle);
    }, [calcBackgroundLayers]);

    return { backgroundLayers, layerTypes };
}
