import { AddSkin, DeleteIndicator } from '@wix/stylable-panel-common-react';
import { CompositeBlock, Scrollbars, Tooltip } from '@wix/stylable-panel-components';
import type { Var } from '@wix/stylable-panel-drivers';
import React, { useState } from 'react';
import { classes, style } from './my-skins-picker.st.css';

export interface CustomSkinsPickerProps {
    className?: string;
    onItemClick?: (index: number, value: Var) => void;
    onItemEnter?: (index: number, value: Var) => void;
    onItemLeave?: (index: number, value: Var) => void;
    onItemRemove?: (index: number, value: Var) => void;
    value?: string;
    valueType?: string;
}

export type CustomSkinsPicker = React.FC<CustomSkinsPickerProps>;

export interface MySkinsPickerProps extends CustomSkinsPickerProps {
    addTooltipLabel?: string;
    information?: string;
    isChecked?: (index: number, color: Var) => boolean;
    isWhite?: (value: string) => boolean;
    itemDataAid?: string;
    onAdd?: () => void;
    onReset?: () => void;
    radioGroupName?: string;
    title?: string;
    values?: Var[];
}

export interface MySkinsPickerState {
    hoveredItemIndex: number;
}

export const MySkinsPicker: React.FC<MySkinsPickerProps> = ({
    addTooltipLabel,
    className,
    information,
    isChecked,
    isWhite,
    itemDataAid,
    onAdd,
    onItemClick,
    onItemEnter,
    onItemLeave,
    onItemRemove,
    onReset,
    radioGroupName,
    title,
    values,
}) => {
    const [hoveredItemIndex, setHoveredItemIndex] = useState<number>(-1);

    const renderResetButton = () =>
        onReset ? (
            <span className={style(classes.userSkinOption, classes.resetButton)} onClick={onReset}>
                <span className={style(classes.userSkinItem, { white: true })}>
                    <div className={classes.diagonal} />
                </span>
            </span>
        ) : null;

    const renderAddButton = () =>
        onAdd ? (
            <Tooltip text={addTooltipLabel} verticalAdjust={-7}>
                <span className={style(classes.userSkinOption, classes.addButton)} onClick={onAdd}>
                    <AddSkin className={classes.addIcon} />
                </span>
            </Tooltip>
        ) : null;

    const renderDeleteIndicator = (index: number, color: Var) =>
        onItemRemove ? (
            <div
                key={`my_skin_${color.name}_deleteindicator`}
                data-aid="st_skinspicker_deleteindicator"
                className={classes.deleteIndicator}
                onClick={(event) => {
                    event.stopPropagation();
                    onItemLeave && onItemLeave(index, color);
                    values && values[index + 1] && onItemEnter?.(index + 1, values[index + 1]);
                    onItemRemove(index, color);
                }}
            >
                <DeleteIndicator />
            </div>
        ) : null;

    const renderUserSkinOption = (color: Var, index: number) => (
        <span key={`my_skin_${color.name}`} className={classes.userSkinOption}>
            <input
                className={classes.hiddenRadio}
                type="radio"
                name={radioGroupName}
                value={color.value}
                checked={isChecked ? isChecked(index, color) : false}
                onChange={() => null}
            />
            <div
                className={style(classes.userSkinItem, { white: isWhite ? isWhite(color.value) : false })}
                style={{ background: color.value }}
                data-aid={itemDataAid}
                onClick={() => onItemClick?.(index, color)}
                onMouseEnter={() => handleUserSkinItemMouseEnter(index, color)}
                onMouseLeave={() => handleUserSkinItemMouseLeave(index, color)}
            >
                {hoveredItemIndex === index ? renderDeleteIndicator(index, color) : null}
            </div>
        </span>
    );

    const handleUserSkinItemMouseEnter = (index: number, value: Var) => {
        setHoveredItemIndex(index);
        onItemEnter?.(index, value);
    };

    const handleUserSkinItemMouseLeave = (index: number, value: Var) => {
        setHoveredItemIndex(-1);
        onItemLeave?.(index, value);
    };

    return (
        <div className={style(classes.root, className)}>
            <Scrollbars>
                <CompositeBlock className={classes.mySkins} title={title} information={information}>
                    <div className={classes.customSkins}>
                        {renderAddButton()}
                        {renderResetButton()}
                        {values?.map(renderUserSkinOption)}
                    </div>
                </CompositeBlock>
            </Scrollbars>
        </div>
    );
};
