import { Close, isInRect } from '@wix/stylable-panel-common-react';
import React, { createRef } from 'react';
import { Button } from '../button/button';
import { Icon } from '../icon/icon';
import { Text } from '../text/text';
import { classes, style } from './popup-panel.st.css';

export interface PopupPanelProps {
    title?: string;
    children?: React.ReactNode;
    onClose?: () => void;
    onBlur?: () => void;
    className?: string;
    style?: React.CSSProperties;
}

// TODO: Test
// TODO: Move onBlur behavior here
export class PopupPanel extends React.Component<PopupPanelProps> {
    private popupPanel = createRef<HTMLDivElement>();

    constructor(props: PopupPanelProps) {
        super(props);
        document.addEventListener('mousedown', this.handleDocumentMouseDown);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleDocumentMouseDown);
    }

    public render() {
        const { title, children, onClose, className, style: propStyle } = this.props;

        return (
            <div className={style(classes.root, className)} style={propStyle} ref={this.popupPanel}>
                <div className={classes.header}>
                    <Text className={classes.headerText}>{title}</Text>
                    <Button className={classes.closeButton} onClick={onClose}>
                        <Icon>
                            <Close />
                        </Icon>
                    </Button>
                </div>
                {children}
            </div>
        );
    }

    private handleDocumentMouseDown = (event: MouseEvent) => {
        const { onBlur } = this.props;

        if (
            this.popupPanel.current &&
            isInRect(event.clientX, event.clientY, this.popupPanel.current.getBoundingClientRect())
        ) {
            return;
        }

        onBlur?.();
    };
}
