import { Button, Icon, Text, Tooltip } from '@wix/stylable-panel-components';
import React, { ReactNode, useRef, useState } from 'react';
import { BIParams, PanelEventList } from '../../../hosts/bi';
import { classes, style } from './background-control.st.css';

export interface Action {
    key: string;
    tooltip: string;
    icon: JSX.Element;
    label?: string;
    content?: () => JSX.Element | null;
    run?: () => void;
}

export interface Point {
    x: string;
    y: string;
}

export interface BackgroundControlProps {
    actions?: Action[];
    children?: ReactNode;
    className?: string;
    customPreviewComponent?: string;
    displayModePicker?: (openedAction: string) => JSX.Element | undefined;
    onChange?: (point: Point) => void;
    onClickAction?: (action: Action) => void;
    openedAction?: string;
    previewProps?: Record<string, any>;
    previewStyle?: Record<string, string | undefined>;
    renderActionButtons?: boolean;
    reportBI?: (event: PanelEventList, params?: BIParams) => void;
    style?: React.CSSProperties;
    value?: Point;
}

const getMouseRectPercent = (rect: DOMRect, clientCoord: number, vertical?: boolean) => {
    const newStopLeft = ((clientCoord - rect[vertical ? 'top' : 'left']) / rect[vertical ? 'height' : 'width']) * 100;

    if (newStopLeft < 0) {
        return '0%';
    }
    if (newStopLeft > 100) {
        return '100%';
    }

    return `${Math.round(newStopLeft)}%`;
};

export const BackgroundControl: React.FC<BackgroundControlProps> = ({
    actions,
    children,
    className,
    customPreviewComponent,
    displayModePicker,
    onChange,
    onClickAction,
    openedAction = '',
    previewProps,
    previewStyle,
    renderActionButtons = true,
    reportBI,
    style: propStyle,
    value,
}) => {
    const preview = useRef<HTMLElement | null>();
    const control = useRef<HTMLDivElement | null>();

    const [dragging, setDragging] = useState<boolean>(false);

    const renderActionContent = (openedAction: string) => {
        const action = actions?.find(({ key }) => key === openedAction);
        const content = action?.content ? action.content() : renderPositionSelectorContent();

        return (
            <div key="actionContent" className={classes.actionContent}>
                {content}
            </div>
        );
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement> | MouseEvent) => {
        document.addEventListener('mousemove', handlePositionDrag);
        document.addEventListener('mouseup', finishPositionDrag);

        handlePositionDrag(event);
        setDragging(true);
    };

    const renderPositionSelectorContent = () => {
        const positionMarkerStyle = value ? { left: value.x, top: value.y } : {};

        return (
            <div className={classes.positionContainer}>
                <div className={style(classes.positionMarker, { dragging })} style={positionMarkerStyle} />
            </div>
        );
    };

    const finishPositionDrag = () => {
        const { MOVE_FOCAL_POINT_CLICK } = PanelEventList;
        reportBI?.(MOVE_FOCAL_POINT_CLICK);

        document.removeEventListener('mousemove', handlePositionDrag);
        document.removeEventListener('mouseup', finishPositionDrag);
        setDragging(false);
    };

    const handlePositionDrag = (event: React.MouseEvent<HTMLDivElement> | MouseEvent) => {
        const dragArea = customPreviewComponent ? preview.current : control.current;

        if (!dragArea) {
            return;
        }

        const newX = parseInt(
            getMouseRectPercent((dragArea as HTMLDivElement).getBoundingClientRect(), event.clientX),
            10
        );
        const newY = parseInt(
            getMouseRectPercent((dragArea as HTMLDivElement).getBoundingClientRect(), event.clientY, true),
            10
        );

        onChange?.({ x: `${newX}%`, y: `${newY}%` });
    };

    const renderActionIcon = (action: Action) => (
        <Tooltip className={classes.actionSelectorTooltip} key={action.key} text={action.tooltip}>
            <Button className={classes.actionSelector} onClick={() => onClickAction?.(action)}>
                <Icon>{action.icon}</Icon>
            </Button>
        </Tooltip>
    );

    // TODO: extract to another component
    const renderTopControls = () => (
        <div className={classes.topControlsContainer}>
            <div className={style(classes.topControls, { inAction: !displayModePicker || !!openedAction })}>
                {displayModePicker?.(openedAction || '')}
                <div className={classes.actionsContainer}>
                    {renderActionButtons && actions ? (
                        !openedAction ? (
                            actions.map(renderActionIcon)
                        ) : (
                            <Text className={classes.topControlsOpenedAction}>
                                {actions.find((action) => action.key === openedAction)?.label}
                            </Text>
                        )
                    ) : undefined}
                </div>
            </div>
        </div>
    );

    const PreviewComp: any = customPreviewComponent || 'div';

    return (
        <div className={style(classes.root, className)} style={propStyle}>
            {renderTopControls()}
            <div className={classes.previewContainer}>
                <PreviewComp
                    className={classes.preview}
                    style={previewStyle}
                    {...previewProps}
                    ref={(c: HTMLDivElement) => (preview.current = c)}
                />
                <div className={classes.control} onMouseDown={handleMouseDown} ref={(c) => (control.current = c)}>
                    {openedAction && renderActionContent(openedAction)}
                    {children}
                </div>
            </div>
        </div>
    );
};
