import type {
    BorderStyles,
    BorderWidths,
    BorderColors,
    BordersShallow,
    Borders,
    BordersTop,
    BordersRight,
    BordersBottom,
    BordersLeft,
    CssEdge,
} from '../shorthand-css-data';
import type {
    SimpleOpenedShorthand,
    SimpleShorthandOpener,
    ShorthandPart,
    GetSimpleShorthandOpener,
    ShorthandCloser,
    GetShorthandCloser,
} from '../shorthand-types';

import { DataType, lineStyleDataType, lineWidthDataType, colorDataType } from '../../css-data-types';
import {
    edgesShorthandOpener,
    unorderedListShorthandOpener,
    createShorthandOpener,
    createShorthandOpenerFromPart,
    getOpenedNodeValue,
    edgesShorthandCloser,
    shorthandCloserTemplate,
    createShorthandCloser,
} from '../shorthand-parser-utils';
import { CannotCloseBorderError } from '../shorthand-parser-errors';

const borderShorthandPart = <V, T extends string>(
    prop: string,
    dataType: DataType,
    openedProps?: T[]
): ShorthandPart<V, T> => ({
    prop,
    dataType,
    partOpener: openedProps ? edgesShorthandOpener(prop) : undefined,
    openedProps,
});

const getBorderStyleShorthandPart = <V>() =>
    borderShorthandPart<V, BorderStyles>('border-style', lineStyleDataType, [
        'border-top-style',
        'border-right-style',
        'border-bottom-style',
        'border-left-style',
    ]);
const getBorderWidthShorthandPart = <V>() =>
    borderShorthandPart<V, BorderWidths>('border-width', lineWidthDataType, [
        'border-top-width',
        'border-right-width',
        'border-bottom-width',
        'border-left-width',
    ]);

const getBorderColorShorthandPart = <V>() =>
    borderShorthandPart<V, BorderColors>('border-color', colorDataType, [
        'border-top-color',
        'border-right-color',
        'border-bottom-color',
        'border-left-color',
    ]);

// border
export const openBorderShorthand: GetSimpleShorthandOpener<Borders> = <V>() =>
    createShorthandOpener({
        prop: 'border',
        parts: [
            getBorderStyleShorthandPart(),
            getBorderWidthShorthandPart(),
            getBorderColorShorthandPart(),
        ] as ShorthandPart<V, Borders>[],
        shorthandOpener: (astNodes, api, parts, shallow) =>
            unorderedListShorthandOpener(parts, { shallow })(astNodes, api),
    }) as SimpleShorthandOpener<V, Borders>;

export const openBorderShorthandShallow: GetSimpleShorthandOpener<BordersShallow> = <V>() => {
    const borderShallowOpener: SimpleShorthandOpener<V, BordersShallow> = (shortHand, api) =>
        openBorderShorthand<V>()(shortHand, api, true) as unknown as SimpleOpenedShorthand<V, BordersShallow>;
    return borderShallowOpener;
};

export const closeBorderShorthand =
    <V>(): ShorthandCloser<V, Borders> =>
    (opened, api, detachExpression, shallow) => {
        if (shallow) {
            const borderShallowCloser = createShorthandCloser<V, BordersShallow>(
                shorthandCloserTemplate<BordersShallow>`${'border-style'} ${'border-width'} ${'border-color'}`
            );
            return borderShallowCloser(
                opened as unknown as SimpleOpenedShorthand<V, BordersShallow>,
                api,
                detachExpression
            );
        }

        const borderClosedTop = closeBorderTopShorthand<V>()(opened, api, detachExpression);
        const borderClosedTopValue = getOpenedNodeValue(borderClosedTop, api);
        if (
            getOpenedNodeValue(closeBorderRightShorthand<V>()(opened, api, detachExpression), api) !==
                borderClosedTopValue ||
            getOpenedNodeValue(closeBorderBottomShorthand<V>()(opened, api, detachExpression), api) !==
                borderClosedTopValue ||
            getOpenedNodeValue(closeBorderLeftShorthand<V>()(opened, api, detachExpression), api) !==
                borderClosedTopValue
        ) {
            throw new CannotCloseBorderError();
        }

        return borderClosedTop;
    };

const borderEdgeProp = (edge: CssEdge, item: string) => `border-${edge}-${item}`;
const borderEdgeShorthandOpener = <V, T extends string>(edge: CssEdge) =>
    createShorthandOpener({
        prop: `border-${edge}`,
        parts: [
            borderShorthandPart<V, BorderStyles>(borderEdgeProp(edge, 'style'), lineStyleDataType),
            borderShorthandPart<V, BorderWidths>(borderEdgeProp(edge, 'width'), lineWidthDataType),
            borderShorthandPart<V, BorderColors>(borderEdgeProp(edge, 'color'), colorDataType),
        ] as ShorthandPart<V, T>[],
        shorthandOpener: (astNodes, api, parts) =>
            unorderedListShorthandOpener(parts, { shallow: true })(astNodes, api),
    }) as SimpleShorthandOpener<V, T>;

// border-top
export const openBorderTopShorthand: GetSimpleShorthandOpener<BordersTop> = <V>() =>
    borderEdgeShorthandOpener<V, BordersTop>('top');

export const closeBorderTopShorthand: GetShorthandCloser<BordersTop> = <V>() =>
    createShorthandCloser<V, BordersTop>(
        shorthandCloserTemplate<BordersTop>`${borderEdgeProp('top', 'style')} ${borderEdgeProp(
            'top',
            'width'
        )} ${borderEdgeProp('top', 'color')}`
    );

// border-right
export const openBorderRightShorthand: GetSimpleShorthandOpener<BordersRight> = <V>() =>
    borderEdgeShorthandOpener<V, BordersRight>('right');

export const closeBorderRightShorthand: GetShorthandCloser<BordersRight> = <V>() =>
    createShorthandCloser<V, BordersRight>(
        shorthandCloserTemplate<BordersRight>`${borderEdgeProp('right', 'style')} ${borderEdgeProp(
            'right',
            'width'
        )} ${borderEdgeProp('right', 'color')}`
    );

// border-bottom
export const openBorderBottomShorthand: GetSimpleShorthandOpener<BordersBottom> = <V>() =>
    borderEdgeShorthandOpener<V, BordersBottom>('bottom');

export const closeBorderBottomShorthand: GetShorthandCloser<BordersBottom> = <V>() =>
    createShorthandCloser<V, BordersBottom>(
        shorthandCloserTemplate<BordersBottom>`${borderEdgeProp('bottom', 'style')} ${borderEdgeProp(
            'bottom',
            'width'
        )} ${borderEdgeProp('bottom', 'color')}`
    );

// border-left
export const openBorderLeftShorthand: GetSimpleShorthandOpener<BordersLeft> = <V>() =>
    borderEdgeShorthandOpener<V, BordersLeft>('left');

export const closeBorderLeftShorthand: GetShorthandCloser<BordersLeft> = <V>() =>
    createShorthandCloser<V, BordersLeft>(
        shorthandCloserTemplate<BordersLeft>`${borderEdgeProp('left', 'style')} ${borderEdgeProp(
            'left',
            'width'
        )} ${borderEdgeProp('left', 'color')}`
    );

// border-style
export const openBorderStyleShorthand: GetSimpleShorthandOpener<BorderStyles> = <V>() =>
    createShorthandOpenerFromPart(getBorderStyleShorthandPart<V>());

export const closeBorderStyleShorthand: GetShorthandCloser<BorderStyles> = <V>() =>
    edgesShorthandCloser<V, BorderStyles>('border-style');

// border-width
export const openBorderWidthShorthand: GetSimpleShorthandOpener<BorderWidths> = <V>() =>
    createShorthandOpenerFromPart(getBorderWidthShorthandPart<V>());

export const closeBorderWidthShorthand: GetShorthandCloser<BorderWidths> = <V>() =>
    edgesShorthandCloser<V, BorderWidths>('border-width');

// border-color
export const openBorderColorShorthand: GetSimpleShorthandOpener<BorderColors> = <V>() =>
    createShorthandOpenerFromPart(getBorderColorShorthandPart<V>());

export const closeBorderColorShorthand: GetShorthandCloser<BorderColors> = <V>() =>
    edgesShorthandCloser<V, BorderColors>('border-color');
