import type { Paddings } from '../shorthand-css-data';
import type { GetSimpleShorthandOpener, GetShorthandCloser } from '../shorthand-types';

import { edgesShorthandOpener, createShorthandOpenerFromPart, edgesShorthandCloser } from '../shorthand-parser-utils';
import { ALWAYS_DATA_TYPE } from '../../css-data-types';

// padding
export const openPaddingShorthand: GetSimpleShorthandOpener<Paddings> = <V>() =>
    createShorthandOpenerFromPart<V, Paddings>({
        prop: 'padding',
        dataType: ALWAYS_DATA_TYPE,
        partOpener: edgesShorthandOpener('padding'),
        openedProps: ['padding-top', 'padding-right', 'padding-bottom', 'padding-left'],
    });

export const closePaddingShorthand: GetShorthandCloser<Paddings> = <V>() =>
    edgesShorthandCloser<V, Paddings>('padding');
