import type {
    CSSAstNode,
    ParseShorthandAPI,
    EvaluatedAst,
    DataTypeMatch,
    OpenedShorthandValue,
    OpenedShorthand,
    SimpleOpenedShorthand,
    ShorthandOpener,
    ShorthandOpenerInner,
    SimpleShorthandOpenerInner,
    UnorderedListShorthandOptions,
    BeforeOpenerCheck,
    ShorthandPart,
    ShorthandOpenerData,
    SimpleShorthandOpener,
    ShorthandCloserTemplate,
    ShorthandCloser,
} from './shorthand-types';

import {
    CSSSeparatorTokens,
    CSSCodeAst,
    isCSSCodeAst,
    createCssValueAST,
    valueTextNode,
    getFullText,
} from '../tokenizers';
import { evaluateAst } from './shorthands-ast-evaluation';
import {
    DataTypeType,
    DataType,
    unorderedListPredicate,
    universalDataType,
    DEFAULT_LAYER_SEPERATOR,
} from '../css-data-types';
import {
    CssEdge,
    CssCorner,
    CSS_PROPERTY_DELIMITER,
    EDGE_SHORTHAND_EDGES,
    CORNER_SHORTHAND_CORNERS,
    EDGE_SHORTHAND_INDICES_BY_LENGTH,
} from './shorthand-css-data';
import {
    NoDataTypeMatchError,
    NoMandatoryPartMatchError,
    InvalidIntersectionValueError,
    InvalidEdgesInputLengthError,
} from './shorthand-parser-errors';

export const matchDataType = <V>(
    dataType: DataType,
    astNodes: EvaluatedAst<V>[],
    index: number,
    prevDataType?: DataTypeType
) => dataType.predicate(astNodes[index].value, index, astNodes, prevDataType);

const getDataTypeMatch = <V>(
    astNodes: EvaluatedAst<V>[],
    index: number,
    parts: ShorthandPart<V>[],
    prevDataType: DataTypeType
): DataTypeMatch => {
    for (let i = 0; i < parts.length; i++) {
        let matchAmount = 0;
        if ((matchAmount = Number(matchDataType(parts[i].dataType, astNodes, index, prevDataType))) > 0) {
            return { matchAmount, matchIndex: i };
        }
    }

    return { matchAmount: 1, matchIndex: -1 };
};

export const isOpenedInitial = <V, T extends string>(opened: OpenedShorthand<V, T>, prop: T, dataType: DataType) => {
    const openedAst = opened[prop] as EvaluatedAst<V>[];
    return openedAst.length === 1 && openedAst[0].value.text === dataType.initial;
};

// TODO: Mark initial in value/origin + Test initials
const getInitialAst = <V>(value: string): EvaluatedAst<V> => ({
    value: createCssValueAST(value)[0],
});

const setInitialOpenedProps = <V, T extends string>(
    shorthandProp: string,
    parts: ShorthandPart<V>[],
    opened: OpenedShorthand<V>,
    shallow?: boolean
): OpenedShorthand<V, T> => {
    for (const part of parts) {
        const props = shallow || !part.openedProps ? [part.prop] : part.openedProps;
        for (const prop of props) {
            if (opened[prop] === undefined) {
                if (!part.mandatory) {
                    const initialAst = getInitialAst<V>(part.dataType.initial);
                    opened[prop] = !part.multipleItems ? initialAst : [initialAst];
                } else {
                    throw new NoMandatoryPartMatchError(shorthandProp, prop);
                }
            }
        }
    }

    return opened;
};

const setCommonProps = <V, T extends string>(
    parts: ShorthandPart<V>[],
    nodes: EvaluatedAst<V>[],
    opened: OpenedShorthand<V>
): OpenedShorthand<V, T> => {
    for (let i = 0; i < parts.length && nodes.length > 0; i++) {
        const { prop } = parts[i];
        if (opened[prop] === undefined) {
            let commonNode: EvaluatedAst<V> | undefined;
            if ((commonNode = nodes.shift())) {
                opened[prop] = commonNode;
            }
        }
    }

    return opened;
};

export const edgesShorthandOpener =
    <V, T extends string>(prop: string, corners?: boolean): SimpleShorthandOpenerInner<V, T> =>
    (astNodes) => {
        if (astNodes.length < 1 || astNodes.length > 4) {
            throw new InvalidEdgesInputLengthError(prop, astNodes.length);
        }

        const [propPrefix, propSuffix] = prop.split(CSS_PROPERTY_DELIMITER);
        const prefix = propPrefix + CSS_PROPERTY_DELIMITER;
        const suffix = propSuffix ? CSS_PROPERTY_DELIMITER + propSuffix : '';
        const edgeIndices = EDGE_SHORTHAND_INDICES_BY_LENGTH[astNodes.length - 1];
        const edges = !corners ? EDGE_SHORTHAND_EDGES : CORNER_SHORTHAND_CORNERS;

        const edgeProp = (edge: CssEdge | CssCorner) => `${prefix}${edge}${suffix}` as T;
        const edgeValue = (index: number) => astNodes[edgeIndices[index]];

        return {
            [edgeProp(edges[0])]: edgeValue(0),
            [edgeProp(edges[1])]: edgeValue(1),
            [edgeProp(edges[2])]: edgeValue(2),
            [edgeProp(edges[3])]: edgeValue(3),
        } as SimpleOpenedShorthand<V>;
    };

export const intersectionBeforeOpenerCheck =
    <V>(prop: string, dataTypes: DataType[]): BeforeOpenerCheck<V> =>
    (astNodes) => {
        const validateValue = (value: CSSCodeAst, dataType: DataType) => {
            if (!dataType.predicate(value)) {
                throw new InvalidIntersectionValueError(prop, value.text);
            }
        };

        if (astNodes.length === 1) {
            for (const dataType of dataTypes) {
                validateValue(astNodes[0].value, dataType);
            }
        } else if (astNodes.length > 1 && dataTypes.length === astNodes.length) {
            for (let i = 0; i < dataTypes.length; i++) {
                validateValue(astNodes[i].value, dataTypes[i]);
            }
        }
    };

export const splitSimpleShorthandOpener =
    <V, T extends string>(props: string[]): SimpleShorthandOpenerInner<V, T> =>
    (astNodes) => {
        const opened: SimpleOpenedShorthand<V> = {};
        for (const prop of props) {
            opened[prop] = astNodes[0];
        }
        return opened;
    };

export const splitShorthandOpener =
    <V, T extends string>(props: string[]): ShorthandOpenerInner<V, T> =>
    (astNodes) => {
        const opened: OpenedShorthand<V> = {};
        for (const prop of props) {
            opened[prop] = astNodes;
        }
        return opened;
    };

export const singleKeywordShorthandOpener =
    <V, T extends string>(
        keywordValueMap: Record<string, Record<T, string>>,
        multipleItems = false
    ): ShorthandOpenerInner<V, T> =>
    (astNodes) => {
        const opened: OpenedShorthand<V> = {};
        const keywordValues = keywordValueMap[astNodes[0].value.text];
        const props = Object.keys(keywordValues) as T[];
        for (const prop of props) {
            const initialAst = getInitialAst<V>(keywordValues[prop]);
            opened[prop] = !multipleItems ? initialAst : [initialAst];
        }
        return opened;
    };

export const unorderedListShorthandOpener =
    <V, T extends string>(
        parts: ShorthandPart<V>[],
        { shallow, commonValue }: UnorderedListShorthandOptions = {}
    ): ShorthandOpenerInner<V, T> =>
    (astNodes, api) => {
        let opened: OpenedShorthand<V> = {};

        const unfoundParts = [...parts];
        const commonMatch = commonValue ? unorderedListPredicate(commonValue) : () => false;
        const commonNodes: EvaluatedAst<V>[] = [];
        let prevDataType: DataTypeType = DataTypeType.Unknown;
        for (let index = 0; index < astNodes.length; ) {
            let currNode = astNodes[index];
            if (commonValue && commonMatch(currNode.value)) {
                commonNodes.push(astNodes[index++]);
                continue;
            }
            const dataTypeMatch: DataTypeMatch = getDataTypeMatch(astNodes, index, unfoundParts, prevDataType);
            const { matchAmount, matchIndex } = dataTypeMatch;
            const foundPart = unfoundParts[matchIndex];
            if (matchIndex !== -1 && foundPart) {
                let matchLength = matchAmount;
                const { prop, dataType, partOpener, beforeOpenerCheck, multipleItems, multipleSplit } = foundPart;
                beforeOpenerCheck?.(astNodes);
                if (dataType.prefix && currNode.value.text === dataType.prefix.prefixChar) {
                    currNode = astNodes[++index];
                    matchLength--;
                }
                const nodes = matchLength === 1 ? currNode : astNodes.slice(index, index + matchLength);
                if (shallow || !partOpener) {
                    opened[prop] = multipleItems
                        ? ((!multipleSplit ? opened[prop] || [] : []) as EvaluatedAst<V>[]).concat(nodes)
                        : nodes;
                } else {
                    opened = {
                        ...opened,
                        ...partOpener(Array.isArray(nodes) ? nodes : [nodes], api),
                    };
                }
                unfoundParts.splice(matchIndex, 1);
                prevDataType = dataType.dataType;
                index += matchLength;
            } else {
                throw new NoDataTypeMatchError(currNode.value.text);
            }
        }

        if (commonValue) {
            opened = setCommonProps(parts, commonNodes, opened);
        }

        return opened;
    };

export const getShorthandLayers = <V>(
    astNodes: EvaluatedAst<V>[],
    seperator = DEFAULT_LAYER_SEPERATOR
): EvaluatedAst<V>[][] => {
    const layers: EvaluatedAst<V>[][] = [];
    let layerIndex = 0;
    for (const node of astNodes) {
        if (node.value.text === seperator) {
            layerIndex++;
            continue;
        }
        if (!layers[layerIndex]) {
            layers[layerIndex] = [];
        }
        layers[layerIndex].push(node);
    }
    return layers;
};

export const getOpenedLayer = <V, T extends string>(
    opened: OpenedShorthand<V, T>,
    index: number,
    multipleItemProps?: T[]
): OpenedShorthand<V, T> | undefined => {
    const layer: OpenedShorthand<V> = {};
    const props = Object.keys(opened) as T[];
    let hasValue = false;
    for (const prop of props) {
        const value = opened[prop];
        if (!value) {
            continue;
        }
        if (multipleItemProps && multipleItemProps.includes(prop)) {
            const valueInIndex = (value as EvaluatedAst<V>[])[index];
            if (!Array.isArray(valueInIndex)) {
                if (index === 0) {
                    layer[prop] = value;
                }
            } else {
                layer[prop] = valueInIndex;
            }
        } else {
            layer[prop] = index === 0 && !Array.isArray(value) ? value : (value as EvaluatedAst<V>[])[index];
        }
        if (layer[prop]) {
            hasValue = true;
        }
    }
    return hasValue ? layer : undefined;
};

export const layersShorthandOpener =
    <V, T extends string>(
        prop: string,
        singleLayerOpener: ShorthandOpenerInner<V, T>,
        singleLayerParts: ShorthandPart<V>[],
        lastLayerOpener?: ShorthandOpenerInner<V, T>,
        lastLayerParts?: ShorthandPart<V>[]
    ): ShorthandOpenerInner<V, T> =>
    (astNodes, api) => {
        const layers = getShorthandLayers(astNodes);
        let openedLayers = (!lastLayerOpener ? layers : layers.slice(0, -1)).map((layer) =>
            setInitialOpenedProps(prop, singleLayerParts, singleLayerOpener(layer, api))
        );
        if (lastLayerOpener) {
            openedLayers = openedLayers.concat([
                setInitialOpenedProps(prop, lastLayerParts || [], lastLayerOpener(layers[layers.length - 1], api)),
            ]);
        }

        if (layers.length === 1) {
            return openedLayers[0];
        }
        const opened: OpenedShorthand<V> = {};
        for (const layer of openedLayers) {
            const layerProps = Object.keys(layer) as T[];
            for (const prop of layerProps) {
                const existingValue = opened[prop];
                const propValue = layer[prop] as EvaluatedAst<V>;
                if (!existingValue) {
                    opened[prop] = propValue;
                } else {
                    if (!Array.isArray(propValue)) {
                        opened[prop] = Array.isArray(existingValue)
                            ? existingValue.concat(propValue)
                            : [existingValue, propValue];
                    } else {
                        opened[prop] = Array.isArray((existingValue as EvaluatedAst<V>[])[0])
                            ? (existingValue as EvaluatedAst<V>[]).concat([propValue])
                            : [existingValue as EvaluatedAst<V>, propValue];
                    }
                }
            }
        }
        return opened;
    };

const getShorthandPartsProps = <V, T extends string>(parts: ShorthandPart<V, T>[], shallow?: boolean) => {
    let partsProps: string[] = [];
    for (const part of parts) {
        partsProps = partsProps.concat(shallow || !part.openedProps ? part.prop : part.openedProps);
    }
    return partsProps;
};

const openSingleKeywordShorthand = <V, T extends string>(
    shorthandProp: string,
    partProps: string[],
    astNodes: EvaluatedAst<V>[],
    api: ParseShorthandAPI<V>,
    part?: ShorthandPart<V, T>
): SimpleOpenedShorthand<V, T> => {
    let opened: SimpleOpenedShorthand<V> = {};

    if (astNodes.length === 1) {
        const node = astNodes[0];
        const universalPart: ShorthandPart<V> = {
            prop: shorthandProp,
            dataType: universalDataType,
            partOpener: splitSimpleShorthandOpener(partProps),
        };
        /* Identify shorthand single-keyword with predicate */
        const matchingPart =
            part && part.dataType.predicate(node.value)
                ? part
                : universalPart.dataType.predicate(node.value)
                ? universalPart
                : undefined;
        if (matchingPart) {
            /* Return the opened single-keyword shorthand, using opener if it exists */
            opened = matchingPart.partOpener ? matchingPart.partOpener([node], api) : { [matchingPart.prop]: node };
        }
    }

    return opened;
};

export const createShorthandOpener =
    <V, T extends string>({
        prop,
        singleKeywordPart,
        parts,
        shorthandOpener,
    }: ShorthandOpenerData<V, T>): ShorthandOpener<V, T> =>
    (shortHand, api, shallow) => {
        /* Evaluate the full input AST */
        const astNodes = evaluateAst(shortHand, api);

        /* Try opening the shorthand as a single keyword and return it if so */
        const singleKeywordOpened = openSingleKeywordShorthand(
            prop,
            getShorthandPartsProps(parts, shallow),
            astNodes,
            api,
            singleKeywordPart
        );
        if (Object.keys(singleKeywordOpened).length > 0) {
            return singleKeywordOpened;
        }

        // TODO: Catch errors and return some value on error?

        /* Open the shorthand using the provided method */
        const opened = shorthandOpener(astNodes, api, parts, shallow);

        /* Return the opened shorthand, after setting missing initial values */
        return setInitialOpenedProps(prop, parts, opened, shallow);
    };

export const createShorthandOpenerFromPart = <V, T extends string>(
    part: ShorthandPart<V, T>
): SimpleShorthandOpener<V, T> => {
    return createShorthandOpener({
        prop: part.prop,
        parts: [part],
        shorthandOpener: (astNodes, api, _parts, shallow) =>
            part.partOpener ? part.partOpener(astNodes, api, shallow) : ({} as OpenedShorthand<V, T>),
    }) as SimpleShorthandOpener<V, T>;
};

export const getOpenedNode = <V>(
    nodeOrNodes: OpenedShorthandValue<V>,
    api: ParseShorthandAPI<V>,
    detachExpressions = false,
    multiExpressions: string[] = []
): CSSAstNode<V>[] => {
    if (Array.isArray(nodeOrNodes)) {
        return nodeOrNodes.reduce(
            (values, node) => values.concat(getOpenedNode(node, api, detachExpressions, multiExpressions)),
            [] as CSSAstNode<V>[]
        );
    }

    const { origin } = nodeOrNodes;
    if (!origin || detachExpressions) {
        return [nodeOrNodes.value];
    }

    const expression = api.toString(origin);
    if (multiExpressions.includes(expression)) {
        return [];
    }
    if (api.getValue(origin).length > 1) {
        multiExpressions.push(expression);
    }
    return [origin];
};

export const getOpenedNodeValue = <V>(nodes: CSSAstNode<V>[], api: ParseShorthandAPI<V>): string =>
    nodes
        .reduce((value, node) => value + (isCSSCodeAst(node) ? getFullText(node) : ' ' + api.toString(node)), '')
        .trim();

export const edgesShorthandCloser =
    <V, T extends string = string>(prop: string, corners?: boolean): ShorthandCloser<V, T> =>
    (opened, api, detachExpressions = false) => {
        const [propPrefix, propSuffix] = prop.split(CSS_PROPERTY_DELIMITER);
        const prefix = propPrefix + CSS_PROPERTY_DELIMITER;
        const suffix = propSuffix ? CSS_PROPERTY_DELIMITER + propSuffix : '';
        const edges = !corners ? EDGE_SHORTHAND_EDGES : CORNER_SHORTHAND_CORNERS;
        const multiExpressions: string[] = [];

        const edgeProp = (edge: CssEdge | CssCorner) => `${prefix}${edge}${suffix}` as T;
        const edgeValue = (index: number) =>
            getOpenedNode(opened[edgeProp(edges[index])], api, detachExpressions, multiExpressions);

        const edgeNodes = [edgeValue(0), edgeValue(1), edgeValue(2), edgeValue(3)];
        const edgeValues = edgeNodes.map((nodes) => getOpenedNodeValue(nodes, api));
        if (edgeValues[3] === edgeValues[1]) {
            edgeNodes.pop();
            edgeValues.pop();
            if (edgeValues[2] === edgeValues[0]) {
                edgeNodes.pop();
                edgeValues.pop();
                if (edgeValues[1] === edgeValues[0]) {
                    edgeNodes.pop();
                    edgeValues.pop();
                }
            }
        }

        return fixAstNodesPositions(
            edgeNodes.filter((nodes) => nodes.length > 0).map((nodes) => nodes[0]),
            api
        );
    };

export const shorthandCloserTemplate = <T extends string>(
    strings: TemplateStringsArray,
    ...keys: string[]
): ShorthandCloserTemplate<T> => ({ strings, keys } as ShorthandCloserTemplate<T>);

export const createShorthandCloserTemplateFromParts = <V, T extends string>(
    parts: ShorthandPart<V, T>[]
): ShorthandCloserTemplate<T> => {
    const keys = parts.map(({ prop }) => prop) as T[];
    return {
        keys,
        strings: [''].concat(Array(keys.length).fill(' ')),
    };
};

export const createShorthandCloser =
    <V, T extends string = string>(template: ShorthandCloserTemplate<T>): ShorthandCloser<V, T> =>
    (opened, api, detachExpressions = false) => {
        const multiExpressions: string[] = [];
        return fixAstNodesPositions(
            (template.strings as string[]).reduce((nodes, currString, index) => {
                const currKey = template.keys[index];
                return currKey === undefined
                    ? nodes
                    : nodes.concat(
                          valueTextNode(currString),
                          getOpenedNode(opened[currKey], api, detachExpressions, multiExpressions)
                      );
            }, [] as CSSAstNode<V>[]),
            api
        );
    };

export const fixAstNodesPositions = <V>(
    nodes: CSSAstNode<V>[],
    api: ParseShorthandAPI<V>,
    start = 0
): CSSAstNode<V>[] => {
    let currPosition = start;
    const fixedNodes: CSSAstNode<V>[] = [];

    nodes.forEach((node) => {
        let nodeText = '';

        if (isCSSCodeAst(node)) {
            nodeText = node.text.trim();
            if (nodeText.length === 0) {
                return;
            }

            const before: CSSSeparatorTokens[] = [];
            if (fixedNodes.length > 0 && nodeText !== ',') {
                // Trailing spaces are added only after the first valid node
                before.push({
                    type: 'space',
                    value: ' ',
                    start: currPosition,
                    end: currPosition + 1,
                });
                currPosition++;
            }

            let args: CSSCodeAst[] = [];
            if (node.type === 'call') {
                args = fixAstNodesPositions(
                    node.args,
                    api,
                    // Current position + function name + (
                    currPosition + node.name.length + 1
                ) as CSSCodeAst[];
            }

            fixedNodes.push({
                ...node,
                ...(nodeText === '/' ? { type: '/' } : nodeText === ',' ? { type: ',' } : {}),
                text: nodeText,
                start: currPosition,
                end: currPosition + nodeText.length,
                before,
                ...(args.length > 0 ? { args } : {}),
            });
        } else {
            nodeText = api.toString(node);
            if (fixedNodes.length > 0) {
                currPosition++; // To account for a space before non-first expression nodes
            }
            fixedNodes.push(node);
        }

        currPosition += nodeText.length;
    });

    return fixedNodes;
};
