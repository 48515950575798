import { DEFAULT_PLANE, DIMENSION_ID } from '@wix/stylable-panel-common';
import { BackgroundBox, CompositeBlock, OptimisticWrapper, Text } from '@wix/stylable-panel-components';
import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import chroma from 'chroma-js';
import React, { useCallback } from 'react';
import { AngleVisualizer, getDimensionConfig, OpacityVisualizer, SizeVisualizer } from '../../generated-visualizers';
import { useColorPicker } from '../../hooks';
import {
    controllerToVisualizerChange,
    createDeclarationMapFromVisualizerValue,
    createVisualizerValueFromDeclarationMap,
    visualizerOptimisticValueResolver,
} from '../../utils';
import type { IconDeclarationMap, IconVisualizerCompProps, IconVisualizerValue } from './icon-types';
import { classes, style } from './icon-visualizer.st.css';
import { ShowToggle } from './show-toggle';
import { DEFAULT_ANGLE, DEFAULT_OPACITY, useIconVisualizer } from './use-icon-visualizer';

export const ICON_DISPLAY_HIDDEN = 'none';
export const ICON_DISPLAY_SHOWN = 'initial';

export const IconVisualizerInner: IconVisualizerCompProps = (props) => {
    const { plane = DEFAULT_PLANE, panelHost, className, onChange, siteVarsDriver, drivers } = props;

    const {
        width,
        height,
        angle,
        transform,
        hideShownToggle,
        elementShown,
        fill,
        translate,
        changeColorAlpha,
        handleColorChange,
        changeAngle,
    } = useIconVisualizer(props);

    const openColorPickerPanel = useColorPicker({
        className: classes.colorPicker,
        title: translate(StylablePanelTranslationKeys.controller.icon.colorPickerTitle),
        panelHost,
        siteVarsDriver,
    });

    const openColorPicker = useCallback(() => {
        if (!openColorPickerPanel) {
            return;
        }

        const onChange = (fill: string | null) => handleColorChange?.(fill || undefined);

        openColorPickerPanel({
            currentColor: fill,
            onHover: onChange,
            onChange,
        });
    }, [fill, openColorPickerPanel, handleColorChange]);

    const renderControllers = useCallback(() => {
        const { value: defaultOpacityValue, unit: defaultOpacityUnit } = DEFAULT_OPACITY;

        const noResizing = props.controllerData?.noResizing;
        const noRotation = props.controllerData?.noRotation;
        const noColor = props.controllerData?.noColor;

        const { dimensionUnits, dimensionKeywords } = panelHost || {};
        const sizeDimensionConfig = getDimensionConfig({ id: DIMENSION_ID.SIZE, dimensionUnits, dimensionKeywords });
        const opacityDimensionConfig = getDimensionConfig({
            id: DIMENSION_ID.OPACITY,
            dimensionUnits,
            dimensionKeywords,
        });
        const angleDimensionConfig = getDimensionConfig({ id: DIMENSION_ID.ANGLE, dimensionUnits, dimensionKeywords });

        let opacityValue = defaultOpacityValue;
        try {
            const color = chroma(fill || '');
            opacityValue = Math.floor(color.alpha() * 100);
        } catch {
            //
        }

        const opacity = opacityValue + defaultOpacityUnit;

        if (transform) {
            angle.current = transform.replace(/[^\d]/g, '') + DEFAULT_ANGLE.unit;
        }

        let iconValue = width || height;
        // lazily fix width/height set in percent
        if (iconValue.indexOf('%') > -1) iconValue = iconValue.replace('%', 'px');

        return [
            !noResizing ? (
                <CompositeBlock
                    key="size"
                    className={classes.controllerBlock}
                    title={translate(StylablePanelTranslationKeys.controller.icon.sizeLabel)}
                    divider
                >
                    <SizeVisualizer
                        config={sizeDimensionConfig}
                        drivers={drivers}
                        className={classes.inputElementSize}
                        panelHost={panelHost}
                        value={
                            // TODO: Optimization: The value passed here comes directly from the value prop.
                            //  We can find the width or height declaration nodes and pass them directly to the SizeVisualizer
                            createVisualizerValueFromDeclarationMap({
                                size: iconValue,
                            })
                        }
                        onChange={(value: any) => {
                            if (onChange) {
                                const iconSize = createDeclarationMapFromVisualizerValue(value, {
                                    value: [],
                                    drivers,
                                }).size;
                                onChange(
                                    // TODO: Optimization: Have a version of controllerToVisualizerChange that gets the visualizer value directly,
                                    // and then we won't need to call createDeclarationMapFromVisualizerValue and then convert it back.
                                    controllerToVisualizerChange(
                                        {
                                            width: iconSize,
                                            height: iconSize,
                                        } as IconDeclarationMap,
                                        props
                                    )
                                );
                            }
                        }}
                    />
                </CompositeBlock>
            ) : null,
            // TODO: Extract to component
            !noColor ? (
            <CompositeBlock
                key="fill"
                className={classes.controllerBlock}
                title={translate(StylablePanelTranslationKeys.controller.icon.colorLabel)}
                divider
            >
                <div className={classes.inputElementOpacityWrapper}>
                    <OpacityVisualizer
                        drivers={drivers}
                        className={classes.inputElementOpacity}
                        value={createVisualizerValueFromDeclarationMap({ opacity })}
                        config={opacityDimensionConfig}
                        onChange={(value) => {
                            const { opacity } = createDeclarationMapFromVisualizerValue(value, { value: [], drivers });
                            if (opacity) {
                                changeColorAlpha(opacity);
                            }
                        }}
                        opacitySliderColor={fill}
                        isDisabled={!fill}
                        panelHost={panelHost}
                    />
                    <BackgroundBox
                        className={classes.colorBox}
                        value={fill}
                        noColor={!fill}
                        showNoColorDiagonal
                        onClick={openColorPicker}
                    />
                </div>
            </CompositeBlock>) : null,
            !noRotation ? (
            <CompositeBlock
                key="transform"
                className={style(classes.controllerBlock)}
                title={translate(StylablePanelTranslationKeys.controller.icon.angleInputLabel)}
            >
                <span className={classes.inputElementAngleWrapper}>  
                    <AngleVisualizer
                        drivers={drivers}
                        className={classes.inputElementAngle}
                        value={createVisualizerValueFromDeclarationMap({
                            angle: angle.current,
                        })}
                        config={angleDimensionConfig}
                        onChange={(value) => {
                            const { angle } = createDeclarationMapFromVisualizerValue(value, { value: [], drivers });
                            if (angle) {
                                changeAngle(angle);
                            }
                        }}
                        panelHost={panelHost}
                    />
                </span>
            </CompositeBlock>
            ) : null,
        ];
    }, [
        changeAngle,
        changeColorAlpha,
        drivers,
        fill,
        height,
        onChange,
        openColorPicker,
        panelHost,
        props,
        transform,
        translate,
        width,
        angle,
    ]);

    return (
        <div className={style(classes.root, { plane }, className)}>
            <Text className={classes.title}>{translate(StylablePanelTranslationKeys.controller.icon.title)}</Text>
            {hideShownToggle ? null : <ShowToggle elementShown={elementShown} props={props} />}
            {elementShown && renderControllers()}
        </div>
    );
};

IconVisualizerInner.BLOCK_VARIANT_CONTROLLER = false;
IconVisualizerInner.INPUT_PROPS = ['width', 'height', 'fill'];
IconVisualizerInner.OUTSIDE_PROPS = ['display', 'transform'];

export const IconVisualizer = OptimisticWrapper<IconVisualizerCompProps, IconVisualizerValue>(
    IconVisualizerInner,
    {},
    true,
    visualizerOptimisticValueResolver
);
