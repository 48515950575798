import { ArrowTree } from '@wix/stylable-panel-common-react';
import { ListViewSelectionType } from '@wixc3/sui-list-view';
import {
    ITreeView,
    ToggledTreeViewItemState,
    TreeView,
    TreeViewRenderItemProps,
    TreeViewSlots,
    TreeViewState,
} from '@wixc3/sui-tree-view';
import React, { ForwardedRef, SyntheticEvent, useState } from 'react';
import { OptionSelectSource } from '../option-list/option-list';
import { OptionListItem } from '../option-list/option-list-item';
import { TooltipAttachTo } from '../tooltip/tooltip';
import { classes, style } from './tree-display.st.css';

const INDENTATION_OFFSET = 18;
const FLAT_TREE_INDENTATION_OFFSET = 24;
const FLAT_TREE_TOOLTIP_ATTACH_TO = TooltipAttachTo.Top;

type TreeViewDataItem = {
    name: string;
};

export interface TreeDisplayItems {
    id: string;
    dataItem: TreeViewDataItem;
    children?: TreeDisplayItems[];
}

export interface TreeDisplayProps {
    value?: string;
    items: TreeDisplayItems[];
    flatTree?: boolean;
    className?: string;
    onHover?: (itemId: string | null) => void;
    onSelect?: (itemId: string) => void;
}

type TreeItemProps = TreeViewRenderItemProps<TreeViewDataItem> & {
    flatTree?: boolean;
    onHover?: (itemId: string | null) => void;
    onSelect?: (itemId: string, source: OptionSelectSource, e: SyntheticEvent, treeView: ITreeView) => void;
};

const Item = (props: TreeItemProps) => {
    const {
        itemIndex,
        dataItemId,
        dataItem,
        level,
        flatTree,
        onHover,
        onSelect,
        isSelected,
        hasChildren,
        isExpanded,
        treeView,
    } = props;

    function onSelectFunc(id: string, source: OptionSelectSource, e: SyntheticEvent) {
        onSelect && onSelect(`${id}`, source, e, treeView);
    }

    const indentation = { paddingLeft: `${level * (!flatTree ? INDENTATION_OFFSET : FLAT_TREE_INDENTATION_OFFSET)}px` };
    const Icon = (props: any) => {
        return hasChildren ? (
            <div {...props} style={indentation}>
                {!flatTree ? <ArrowTree className={style(classes.arrow)} /> : null}
            </div>
        ) : (
            <div className={style(classes.arrowPlaceholder, { flatTree: !!flatTree })} style={indentation} />
        );
    };
    return (
        <OptionListItem
            index={itemIndex}
            key={`${itemIndex}`}
            id={`${dataItemId}`}
            className={style(classes.item, { expanded: isExpanded })}
            item={{
                id: `${dataItemId}`,
                displayName: dataItem.name,
                icon: Icon,
            }}
            onSelect={onSelectFunc}
            onHover={onHover}
            isSelected={isSelected}
            hoverOnlyFromLabel={!flatTree}
            preventOverflowWithEllipsis={true}
            attachTooltipTo={flatTree ? FLAT_TREE_TOOLTIP_ATTACH_TO : undefined}
        />
    );
};

function getExpandedUpToTarget(root: TreeDisplayItems[], targetId: string, agg: string[] = []): string[] {
    // Base case:
    const match = root.find((item) => item.id === targetId);
    if (match) {
        const newAgg = agg.slice();
        newAgg.push(match.id);
        return newAgg;
    }

    let matchInChildren: string[] = [];
    root.forEach((item) => {
        const newAgg = agg.slice();
        newAgg.push(item.id);
        if (item.children) {
            const subResult = getExpandedUpToTarget(item.children, targetId, newAgg);
            if (subResult.length > 0) {
                matchInChildren = subResult;
            }
        }
    });
    return matchInChildren;
}

//TODO should remove this
/*
This function returns the siblings of a tree node
 */
// function getSiblings(root: TreeDisplayItems[], targetId: string): string[] | undefined{
//     let foundId = false;
//     let matchInChildren: string[] | undefined;
//     root.forEach((item) => {
//         // Base case
//         if (item.id === targetId) {
//             foundId = true
//         }
//         // recursive
//         if (item.children && !matchInChildren) {
//             const subResult = getSiblings(item.children, targetId);
//             if (subResult) {
//                 matchInChildren = subResult
//             }
//         }
//     });
//
//     if (foundId) {
//         // Base case
//         const result = root.slice();
//         return result.filter((val) => val.id !== targetId).map((val) => val.id);
//     }
//     if (matchInChildren) {
//         return matchInChildren
//     }
//     return undefined;
// }

export function getChildFromTree(root: TreeDisplayItems[], id: string): TreeDisplayItems | undefined {
    let match: TreeDisplayItems | undefined;
    root.forEach((item) => {
        if (item.id === id) {
            match = item;
        }

        if (item.children && !match) {
            const subResult = getChildFromTree(item.children, id);
            if (subResult) {
                match = subResult;
            }
        }
    });
    if (match) {
        return match;
    }
    return undefined;
}

function isParentOf(root: TreeDisplayItems[], parentId: string, childId: string): boolean {
    const subTree = getChildFromTree(root, parentId);
    if (subTree && subTree.children) {
        const child = getChildFromTree(subTree.children, childId);
        if (child) {
            return true;
        }
    }
    return false;
}

function getAllTreeIds(root: TreeDisplayItems[]): string[] {
    return root.reduce((allTreeIds, item) => {
        return allTreeIds.concat([item.id]).concat(item.children ? getAllTreeIds(item.children) : []);
    }, [] as string[]);
}

const initialState = () => {
    return {
        selectedIds: [],
        toggledItemsIds: [],
        selectionStartId: null,
        currentNavigatableItemId: null,
        disabledIds: [],
        typeAheadValue: null,
    };
};

const getExpandedInitialNodes = (value: string | undefined, items: TreeDisplayItems[], flatTree: boolean): string[] => {
    if (flatTree) {
        return getAllTreeIds(items);
    }
    const targetId = value;
    if (targetId) {
        return getExpandedUpToTarget(items, targetId);
    }
    return [];
};

function isItemSelected(value: string | undefined, dataItemId: string) {
    return value === dataItemId;
}

export const TreeDisplay = React.forwardRef((props: TreeDisplayProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { className, items, flatTree, onHover, value } = props;
    const [treeViewState, setTreeViewState] = useState<TreeViewState>(
        Object.assign({}, initialState(), { toggledItemsIds: getExpandedInitialNodes(value, items, flatTree || false) })
    );

    const handleLeave = () => onHover?.(null);

    const localOnSelectLabel = (
        dataItemId: string,
        source: OptionSelectSource,
        _e: SyntheticEvent,
        treeView: ITreeView
    ) => {
        const { onSelect, items, value, flatTree } = props;

        function toggleExpandSelf(isExpanded?: boolean) {
            treeView.updateState((stateController) => {
                stateController.toggleExpanded(dataItemId, isExpanded);
            });
        }

        // transform state:
        if (flatTree) {
            onSelect?.(dataItemId);
        } else if (source === OptionSelectSource.ICON) {
            // Collapse or expand self:
            toggleExpandSelf();
            if (value && isParentOf(items, dataItemId, value)) {
                onSelect?.(dataItemId);
            }
        } else if (source === OptionSelectSource.LABEL) {
            if (isItemSelected(value, dataItemId)) {
                toggleExpandSelf();
            } else {
                toggleExpandSelf(true); // expand if possible
            }

            // fire onSelect
            onSelect?.(dataItemId);
        }
    };

    const treeViewItemView = TreeViewSlots.renderItem<TreeViewDataItem>().provide(Item, {
        props: (props) => ({
            ...props,
            flatTree,
            onHover,
            onSelect: localOnSelectLabel,
            isSelected: isItemSelected(value, props.dataItemId as string),
        }),
    });

    return (
        <div className={style(classes.root, className)} onMouseLeave={handleLeave} ref={ref}>
            <TreeView
                renderItem={treeViewItemView}
                selectionType={ListViewSelectionType.Single}
                toggledTreeViewItemState={ToggledTreeViewItemState.Expanded}
                treeViewState={treeViewState}
                onChange={(state: TreeViewState) => {
                    setTreeViewState(state);
                }}
            >
                {items}
            </TreeView>
        </div>
    );
});
