import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import type { SingleCategoryConfig } from '../../../../types';
import { textStroke, textShadowFxGenerator, glazedTextShadowBottom } from '../category-config-formatters';

export const textBlockVariants: SingleCategoryConfig = {
    titleKey: StylablePanelTranslationKeys.controller.text.title,
    blockVariantSet: [
        {
            'font-family': 'Poppins',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'none',
            'text-shadow': 'none',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Poppins',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'none',
            'text-shadow': '2px 2px 2px rgba(0, 0, 0, 0.4)',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Poppins',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'none',
            'text-shadow': '1px 1px 0  rgba(155, 155, 155, 0.6)',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Poppins',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'none',
            'text-shadow': '2px 4px 3px rgba(0, 0, 0, 0.2)',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Poppins',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'none',
            'text-shadow': '-2px -2px value(site_3_1)',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Poppins',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'none',
            'text-shadow': '2px 2px 6px value(site_4_1)',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Poppins',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'none',
            'text-shadow': '4px 4px 3px value(site_5_1)',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Poppins',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'none',
            'text-shadow': textStroke(1, 'value(site_2_1)'),
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Poppins',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'none',
            'text-shadow':
                '0.25rem -0.25rem rgba(255, 0, 255, 0.75), -0.25rem -0.25rem rgba(0, 255, 255, 0.75), 0 0.5rem rgba(255, 255, 0, 0.75)',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Poppins',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'none',
            'text-shadow': textShadowFxGenerator(100, 'blue'),
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Poppins',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'none',
            'text-shadow': glazedTextShadowBottom(13, '#8b2d23'),
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Poppins',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0.2em',
            'line-height': '0.2em',
        },
        {
            'font-family': 'Suez One',
            'font-weight': '600',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0.15em',
            'line-height': '0.15em',
        },
        {
            'font-family': 'Anton',
            'font-weight': '600',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0.15em',
            'line-height': '0.15em',
        },
        {
            'font-family': 'Libre Baskerville',
            'font-weight': '600',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0.05em',
            'line-height': '0.5em',
        },
        {
            'font-family': 'Sarina',
            'font-weight': '600',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0.05em',
            'line-height': '0.5em',
        },
        {
            'font-family': 'Work Sans',
            'font-weight': '800',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0.2em',
            'line-height': '0.2em',
        },
        {
            'font-family': 'Damion',
            'font-weight': '800',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0.2em',
            'line-height': '0.2em',
        },
        {
            'font-family': 'Dancing Script',
            'font-weight': '800',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0.2em',
            'line-height': '0.2em',
        },
        {
            'font-family': 'Barlow',
            'font-weight': '500',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0.1em',
            'line-height': '0.1em',
        },
        {
            'font-family': 'Avenir',
            'font-weight': '500',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0.1em',
            'line-height': '0.1em',
        },
        {
            'font-family': 'Amatic SC',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Basic',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Caudex',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Chelsea Market',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Cinzel',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Cookie',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Corben',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Cormorant Garamond',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'EB Garamond',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Enriqueta',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Forum',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Fredericka the Great',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Jockey One',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Josefin Slab',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Jura',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Kelly Slab',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Lato',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Lobster',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Marck Script',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Monoton',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Mr De Haviland',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Niconne',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Noticia Text',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Open Sans Condensed',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Oswald',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Overlock',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Patrick Hand',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Play',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Playfair Display',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Questrial',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Raleway',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Roboto',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Rozha One',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Sacramento',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Sail',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Signika',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
        {
            'font-family': 'Spinnaker',
            'font-weight': '400',
            'font-style': 'normal',
            'text-transform': 'uppercase',
            'text-shadow': '0 0 0 transparent',
            'letter-spacing': '0',
            'line-height': '0',
        },
    ],
    props: [
        'font',
        'font-family',
        'font-weight',
        'font-size',
        'font-style',
        'text-transform',
        'text-align',
        // 'text-decoration',
        'text-decoration-line',
        'color',
        'direction',
        'letter-spacing',
        'line-height',
        // 'line-0',
        'text-shadow',
    ],
    optional: [
        // /font-(?!(family|weight|size|style))/,
        // /text-(?!(transform|align|decoration|shadow))/,
        // 'hanging-punctuation',
        // 'hyphens'
    ],
};

export default textBlockVariants;
