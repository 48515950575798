import { DEFAULT_PLANE, StylePanelPlane } from '@wix/stylable-panel-common';
import type { BIParams, ExtendedGlobalHost, PanelEventList, VisualizerType } from '@wix/stylable-panel-controllers';
import {
    ArchetypeList,
    BlockVariantSet,
    ChangeAction,
    DeclarationMap,
    ElementTree,
    elementTreeSelectors,
    SelectorConfigurationDriver,
    StylableDriver,
} from '@wix/stylable-panel-drivers';
import React from 'react';
import type { StateOverridesConfig, StateOverridesStates, StylePanelView } from '../../editor-panel';
import type { CategoryConfig } from '../../types';
import type { StylePanelChildProps } from '../style-panel/style-panel';
import { CategoryEditingPanel } from './category-editing-panel';
import { classes, style } from './customization-panel.st.css';
import Elementree from './elementree';
import PartStateSelector from './part-state-selector';

export interface CustomizationPanelEvents {
    onForceState?: (selector: string | null) => void;
    onElementSelect?: (selector: string) => void;
    onStateSelect?: (selector: string) => void;
    changeRuleDeclarations?: (changeRequest: ChangeAction | ChangeAction[]) => void;
    revertRule?: (changedCallback: () => void) => void;
    onSaveBlockVariant?: (category: string, variant: DeclarationMap) => void;
    onDeleteBlockVariant?: (category: string, index: number) => void;
}

export interface CustomizationPanelProps extends StylePanelChildProps, CustomizationPanelEvents {
    selector: string;
    stateSelector: string;
    elements: ElementTree;
    controllerPartTypes: Record<string, string[]>;
    selectorConfiguration: SelectorConfigurationDriver;
    states: string[];
    panelHost?: ExtendedGlobalHost;
    siteSheetPath: string;
    sheetPath: string;
    aggregationPaths?: string[];
    stylableDriver: StylableDriver;
    archetypeList?: ArchetypeList;
    categoryConfiguration?: CategoryConfig;
    controllers?: Record<string, VisualizerType>;
    userBlockVariants?: Record<string, BlockVariantSet>;
    plane?: StylePanelPlane;
    initialView?: StylePanelView;
    stateOverridesConfig?: StateOverridesConfig;
    handleStateOverrides?: (action?: StateOverridesStates) => void;
    reportBI?: (event: PanelEventList, params?: BIParams) => void;
}

/**
 * @deprecated This will be replaced by configurable-panel.ts
 */
export class CustomizationPanel extends React.Component<CustomizationPanelProps> {
    public static defaultProps: Partial<CustomizationPanelProps> = {
        archetypeList: {},
        categoryConfiguration: { all: { props: [/[\s\S]*/] } },
    };

    public componentDidMount() {
        const { handleStateOverrides } = this.props;
        handleStateOverrides && handleStateOverrides();
    }

    public render() {
        const {
            selector,
            stateSelector,
            elements,
            controllerPartTypes,
            selectorConfiguration,
            states,
            panelHost,
            onForceState,
            onElementSelect,
            onStateSelect,
            siteSheetPath,
            sheetPath,
            aggregationPaths,
            stylableDriver,
            archetypeList = {},
            categoryConfiguration,
            controllers,
            onSaveBlockVariant,
            onDeleteBlockVariant,
            userBlockVariants,
            changeRuleDeclarations,
            revertRule,
            plane = DEFAULT_PLANE,
            initialView,
            reportBI,
            stateOverridesConfig,
            handleStateOverrides,
            className,
        } = this.props;

        const elementTree = this.renderElementree(
            selector,
            elements,
            selectorConfiguration,
            stylableDriver,
            sheetPath,
            panelHost,
            reportBI,
            handleStateOverrides,
            onElementSelect
        );

        const aggregationPathsFilled = aggregationPaths || [sheetPath];

        return (
            <div className={style(classes.root, { plane }, className)}>
                <div className={classes.mainPanel}>
                    <div className={classes.elementSelectors}>
                        {elementTree}
                        <PartStateSelector
                            className={classes.partStateSelector}
                            selector={stateSelector}
                            rootSelector={selector}
                            states={states}
                            selectorConfiguration={selectorConfiguration}
                            panelHost={panelHost}
                            plane={plane}
                            onForceState={onForceState}
                            onSelect={onStateSelect}
                            reportBI={reportBI}
                            hideOnNoStates
                            stateOverridesConfig={stateOverridesConfig}
                            handleStateOverrides={handleStateOverrides}
                        />
                    </div>
                    <CategoryEditingPanel
                        className={classes.categoryEditorPanelSelector}
                        siteSheetPath={siteSheetPath}
                        sheetPath={sheetPath}
                        aggregationPaths={aggregationPathsFilled}
                        stylableDriver={stylableDriver}
                        controllerPartTypes={controllerPartTypes}
                        copyControllers={this.getCopyControllers()}
                        controllerData={this.getControllerData()}
                        inheritedStateSelectors={this.getInheritedStateSelectors()}
                        archetypeList={archetypeList}
                        selector={stateSelector}
                        categoryConfiguration={categoryConfiguration!}
                        controllers={controllers}
                        saveBlockVariant={onSaveBlockVariant}
                        deleteBlockVariant={onDeleteBlockVariant}
                        userBlockVariants={userBlockVariants}
                        changeRuleDeclarations={changeRuleDeclarations}
                        revertRule={revertRule}
                        panelHost={panelHost}
                        plane={plane}
                        initialSection={initialView ? initialView.section : undefined}
                        reportBI={reportBI}
                    />
                </div>
            </div>
        );
    }

    private renderElementree(
        selector: string,
        elements: ElementTree,
        selectorConfiguration: SelectorConfigurationDriver,
        stylableDriver: StylableDriver,
        sheetPath: string,
        panelHost: ExtendedGlobalHost | undefined,
        reportBI: ((event: PanelEventList, params?: BIParams) => void) | undefined,
        handleStateOverrides: ((action?: StateOverridesStates) => void) | undefined,
        onElementSelect: ((selector: string) => void) | undefined
    ) {
        const { plane } = this.props;

        return (
            <Elementree
                className={classes.elementree}
                selector={selector}
                elements={elements}
                selectorConfiguration={selectorConfiguration}
                styleSheet={stylableDriver.getStylesheet(sheetPath)}
                panelHost={panelHost}
                plane={plane}
                reportBI={reportBI}
                handleStateOverrides={handleStateOverrides}
                onSelect={onElementSelect}
                disableAddPseudoElementsToNode
                hideOnSingleItem
            />
        );
    }

    private getCopyControllers() {
        const { selector, elements, selectorConfiguration } = this.props;

        const copyControllers = selectorConfiguration.getCopyControllers(selector);
        if (!copyControllers) {
            return undefined;
        }

        const elementSelectors = elementTreeSelectors(elements);

        Object.keys(copyControllers).forEach((copySelector) => {
            if (!~elementSelectors.indexOf(copySelector)) {
                delete copyControllers[copySelector];
            }
        });

        return copyControllers;
    }

    private getControllerData() {
        const { selector, selectorConfiguration } = this.props;
        const configuration = selectorConfiguration.getSelectorConfiguration(selector);
        return configuration ? configuration.controllerData : undefined;
    }

    private getInheritedStateSelectors() {
        const { selector, selectorConfiguration } = this.props;

        return selectorConfiguration.getInheritedStateSelectors(selector);
    }
}
