import type { DeclarationVisualizerProps } from '../types';
import { getTextFromSinglePropVisualizer } from '../utils';
import { useVisualizerChange } from './use-visualizer-change';

export function useTextFromSingleProp<PROP extends string>(
    mainProp: PROP,
    props: DeclarationVisualizerProps<PROP>
): [string | undefined, ((value?: string) => void) | undefined] {
    const value = getTextFromSinglePropVisualizer(mainProp, props);

    const handleChange = useVisualizerChange(mainProp, props);

    return [value, handleChange];
}
