import { Edges, SelectedEdge } from './edge-types';

export function edgeToSelectedBorder(edge?: Edges): SelectedEdge {
    switch (edge) {
        case 'top':
            return SelectedEdge.Top;
        case 'right':
            return SelectedEdge.Right;
        case 'bottom':
            return SelectedEdge.Bottom;
        case 'left':
            return SelectedEdge.Left;
    }
    return SelectedEdge.None;
}

export function selectedBorderToEdge(edge?: SelectedEdge): Edges | null {
    switch (edge) {
        case SelectedEdge.Top:
            return 'top';
        case SelectedEdge.Right:
            return 'right';
        case SelectedEdge.Bottom:
            return 'bottom';
        case SelectedEdge.Left:
            return 'left';
    }
    return null;
}
