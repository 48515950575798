import React, { PropsWithChildren, ReactElement } from 'react';
import type { ConfigurablePanelContext } from './configurable-panel';

export type StringOrPredicate = string | ((s: string) => boolean);
export type BooleanOrPredicate = boolean | (() => boolean);

export interface WhenProps {
    whenCondition?: BooleanOrPredicate;
    whenSelectedElement?: StringOrPredicate;
    whenTargetSelector?: StringOrPredicate;
}

function shouldFilterByWhenProp(
    whenCond: BooleanOrPredicate | StringOrPredicate | undefined,
    predicateNotFunction: () => boolean,
    predicateIfFunction: (whenCond: Function) => boolean
) {
    if (whenCond !== undefined) {
        if (whenCond instanceof Function) {
            return predicateIfFunction(whenCond);
        } else {
            return predicateNotFunction();
        }
    }
    return false;
}

interface FilterByWhenConditionsOptions extends WhenProps {
    context: ConfigurablePanelContext;
}
export function shouldFilterByWhenConditions(options: FilterByWhenConditionsOptions) {
    const { whenCondition, whenSelectedElement, whenTargetSelector, context } = options;
    const shouldFilterByCondition = shouldFilterByWhenProp(
        whenCondition,
        () => !whenCondition,
        (whenCondition) => !whenCondition()
    );
    const shouldFilterByElement = shouldFilterByWhenProp(
        whenSelectedElement,
        () => whenSelectedElement !== context.selectedElement,
        (whenSelectedElement) => !whenSelectedElement(context.selectedElement)
    );
    const shouldFilterByTargetSelector = shouldFilterByWhenProp(
        whenTargetSelector,
        () => whenTargetSelector !== context.activeTargetSelector,
        (whenTargetSelector) => !whenTargetSelector(context.activeTargetSelector)
    );

    return shouldFilterByElement || shouldFilterByTargetSelector || shouldFilterByCondition;
}

function shouldFilterNode<T extends ReactElement<PropsWithChildren<WhenProps>>>(
    newNode: T,
    context: ConfigurablePanelContext
) {
    const { whenSelectedElement, whenCondition, whenTargetSelector } = newNode.props;
    return shouldFilterByWhenConditions({ context, whenCondition, whenSelectedElement, whenTargetSelector });
}

export function filterElementChildrenByConditions(
    node: React.ReactNode,
    context: ConfigurablePanelContext,
    recursive = true
) {
    const result: React.ReactElement[] = [];

    const filterNode = (n: React.ReactNode) => {
        if (!n || !React.isValidElement(n)) {
            return;
        }
        let newChildren = n.props.children;
        if (
            recursive &&
            n.props.children &&
            (Array.isArray(n.props.children) || React.isValidElement(n.props.children))
        ) {
            newChildren = filterElementChildrenByConditions(n.props.children, context, true);
        }
        const newNode = React.cloneElement(n, undefined, newChildren);
        if (shouldFilterNode(newNode, context)) {
            return;
        }

        result.push(newNode);
    };
    if (Array.isArray(node)) {
        node.forEach((element) => {
            filterNode(element);
        });
    } else {
        filterNode(node);
    }
    return result;
}
