import type { Margins } from '../shorthand-css-data';
import type { GetSimpleShorthandOpener, GetShorthandCloser } from '../shorthand-types';

import { edgesShorthandOpener, createShorthandOpenerFromPart, edgesShorthandCloser } from '../shorthand-parser-utils';
import { ALWAYS_DATA_TYPE } from '../../css-data-types';

// margin
export const openMarginShorthand: GetSimpleShorthandOpener<Margins> = <V>() =>
    createShorthandOpenerFromPart<V, Margins>({
        prop: 'margin',
        dataType: ALWAYS_DATA_TYPE,
        partOpener: edgesShorthandOpener('margin'),
        openedProps: ['margin-top', 'margin-right', 'margin-bottom', 'margin-left'],
    });

export const closeMarginShorthand: GetShorthandCloser<Margins> = <V>() => edgesShorthandCloser<V, Margins>('margin');
