import { DownArrow, isClickInElement, SmallDownArrow } from '@wix/stylable-panel-common-react';
import keycode from 'keycode';
import React, { useRef, useState } from 'react';
import { Button } from '../button/button';
import { Icon } from '../icon/icon';
import { Option, OptionList } from '../option-list/option-list';
import { Text } from '../text/text';
import { classes, style } from './drop-down.st.css';

export interface KeywordVisualizerFactoryProps {
    options: Option[];
    customOptionRender?: (item: Option, index: number) => JSX.Element | undefined;
    'data-aid'?: string;
    noCloseOnSelect?: boolean;
    noOpenOnSingleOption?: boolean;
    noToggleOnEnter?: boolean;
    selectedIcon?: boolean;
    hideArrow?: boolean;
    smallArrow?: boolean;
    volatileOptions?: Option[];
}

export interface KeywordVisualizerComponentProps {
    disabled?: boolean;
}

export interface DropDownProps extends KeywordVisualizerFactoryProps, KeywordVisualizerComponentProps {
    value: string;
    className?: string;
    onHover?: (itemId: string | null) => void;
    onSelect?: (itemId: string) => void;
    style?: React.CSSProperties;
}

export const DropDown = ({
    className,
    customOptionRender,
    disabled,
    noCloseOnSelect,
    noOpenOnSingleOption,
    noToggleOnEnter,
    onHover,
    onSelect,
    options,
    selectedIcon,
    smallArrow,
    hideArrow,
    'data-aid': dataAid,
    style: propStyle,
    value,
    volatileOptions,
}: DropDownProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const optionList = useRef<OptionList>(null);

    const closePopup = () => {
        cleanupClickEventListener();
        setOpen(false);
    };

    const openPopup = () => {
        document.addEventListener('mousedown', handleDocumentMouseDown);
        setOpen(true);
    };

    const toggleOpen = (event?: React.MouseEvent<HTMLButtonElement>) => {
        if (disabled || (noOpenOnSingleOption && options.length <= 1)) {
            return;
        }
        event?.stopPropagation();
        open ? closePopup() : openPopup();
    };

    const cleanupClickEventListener = () => {
        document.removeEventListener('mousedown', handleDocumentMouseDown);
    };

    const handleDocumentMouseDown = (event: MouseEvent) => {
        if (optionList.current && !isClickInElement(event, optionList.current)) {
            closePopup();
        }
    };

    const handleSelectItem = (id: string) => {
        onSelect?.(id);
        !noCloseOnSelect && closePopup();
    };

    const addFocusEventListeners = () => {
        if (!noToggleOnEnter) {
            document.addEventListener('keydown', handleKeyDown);
        }
    };

    const cleanupFocusEventListeners = () => {
        if (!noToggleOnEnter) {
            document.removeEventListener('keydown', handleKeyDown);
        }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (!noToggleOnEnter && event.keyCode === keycode('enter')) {
            if (!optionList.current || !optionList.current.hasHovered()) {
                toggleOpen();
            }
        }
    };

    const canOpen = () => !noOpenOnSingleOption || options.length > 1;

    const getValueItem = () => {
        let valueItem = options.find((item: Option) => value === item.id);
        if (!valueItem && volatileOptions && volatileOptions?.length > 0) {
            valueItem = volatileOptions.find((item: Option) => value === item.id);
        }
        return valueItem;
    };

    const getButtonStyle = () => {
        const fontFamily = getValueItem()?.font;
        return fontFamily ? { fontFamily } : {};
    };

    return (
        <span
            className={style(classes.root, className)}
            style={propStyle}
            tabIndex={0}
            onFocus={addFocusEventListeners}
            onBlur={cleanupFocusEventListeners}
        >
            <Button
                className={style(classes.dropDownButton, {
                    open,
                    disabled: !!disabled,
                })}
                onClick={toggleOpen}
                data-aid={dataAid || 'st_dropdown'}
                isOpen={open}
            >
                <Text className={classes.dropDownText} style={getButtonStyle()}>
                    {getValueItem()?.displayName?.trim() || ''}
                </Text>
                {canOpen() && !hideArrow && (
                    <Icon className={classes.dropDownIcon}>
                        {smallArrow ? (
                            <SmallDownArrow className={classes.arrow} />
                        ) : (
                            <DownArrow className={classes.arrow} />
                        )}
                    </Icon>
                )}
            </Button>
            {open && (
                <OptionList
                    className={classes.popup}
                    value={value}
                    options={options}
                    customOptionRender={customOptionRender}
                    selectedIcon={selectedIcon}
                    onHover={onHover}
                    onSelect={handleSelectItem}
                    onClose={closePopup}
                    ref={optionList}
                />
            )}
        </span>
    );
};
