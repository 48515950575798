import type { ControllerData } from '@wix/stylable-panel-common';
import {
    createDeclarationMapFromVisualizerValue,
    createDeclarationVisualizerDrivers,
    createVisualizerValueFromDeclarationMap,
    OpenedDeclarationArray,
} from '@wix/stylable-panel-controllers';
import type { DeclarationMap } from '@wix/stylable-panel-drivers';
import type { ConfigurablePanelContext } from '../configurable-panel';
import { classes } from '../cp-controller.st.css';

export interface generateVisualizerPropsArgs {
    context: ConfigurablePanelContext;
    key: string;
    astValue?: boolean; // This is needed for BackgroundVisualizer. Should be possibly renamed
    disableBackgroundShorthand?: boolean; // This is needed for BackgroundVisualizer.
    controllerData?: ControllerData;
    editTargetSelector?: string;
}
export function generateVisualizerProps<T extends string>(args: generateVisualizerPropsArgs) {
    const {
        activeTargetSelector,
        aggregationPaths,
        changeRuleDeclarations,
        panelHost,
        sheetPath,
        siteSheetPath,
        stylableDriver,
        panelLayoutConfiguration: { plane },
    } = args.context;
    const selector = args.editTargetSelector || activeTargetSelector;

    function onControllerChange(values: DeclarationMap) {
        changeRuleDeclarations?.({
            selector,
            values,
        });
    }

    const siteVarsDriver = stylableDriver.getSiteVarsDriver(siteSheetPath);
    const visualizerDrivers = createDeclarationVisualizerDrivers(); // TODO should this be moved higher? does it even matter?
    //
    if (!siteVarsDriver) {
        throw new Error('missing site vars drivers');
    }

    const getCssDeclarationValues = () => {
        return stylableDriver.aggregateSelectorDeclarations(
            aggregationPaths || [sheetPath],
            selector,
            undefined,
            undefined,
            args.disableBackgroundShorthand
        );
    };

    return {
        plane,
        panelHost,
        siteVarsDriver,
        className: classes.controller,
        controllerData: args.controllerData,
        drivers: visualizerDrivers,
        key: args.key,
        onChange: (declaration: OpenedDeclarationArray<T>) =>
            onControllerChange(createDeclarationMapFromVisualizerValue(declaration)),
        value: createVisualizerValueFromDeclarationMap<T>(getCssDeclarationValues(), !!args.astValue),
    };
}
