import { useRef, useMemo, useEffect } from 'react';

import { DIMENSION_INPUT_DEFAULTS } from '@wix/stylable-panel-common';

import type { OpenedDeclarationList } from '../../types';
import type { EdgeMainProp, EdgeProps, EdgeDeclarationMap } from './edge-types';
import type {
    GetPropOptions,
    GetPropOptionReturn,
    SetPropOptions,
    UseVisualizerDriver,
} from '../../visualizer-driver-types';
import {
    getDeclarationValue,
    getDeclarationText,
    getOpenedDeclarationList,
    getLatestOpenedNode,
    createDeclarationMapFromVisualizerValue,
    getSimpleShorthandChange,
} from '../../utils';

export type EdgeUpdateEffect = () => void;
export interface SetPropEdgeOptions extends SetPropOptions {
    linked?: boolean;
}

export function EdgeDriverFactory(main: EdgeMainProp, inputProps: EdgeProps[]) {
    const useEdgeDriver: UseVisualizerDriver<EdgeProps, EdgeUpdateEffect, GetPropOptions, SetPropEdgeOptions> = (
        props
    ) => {
        const { drivers, onChange } = props;

        const openedDeclarationList = useRef(getOpenedDeclarationList(main, inputProps, props));
        const prevOpenedDeclarationList = useRef<OpenedDeclarationList<EdgeProps>>();

        return {
            useVisualizerUpdate: (effect) =>
                useEffect(
                    () => {
                        prevOpenedDeclarationList.current = openedDeclarationList.current;
                        openedDeclarationList.current = getOpenedDeclarationList(main, inputProps, props);

                        effect?.();
                    },
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    [props, effect]
                ),
            getProp: <OPTIONS extends GetPropOptions = GetPropOptions>(prop: EdgeProps, options?: OPTIONS, defaultEdges?: typeof DIMENSION_INPUT_DEFAULTS.edgeVisualizerFactory.edges) => {
                const DEFAULT_EDGES = defaultEdges ?? DIMENSION_INPUT_DEFAULTS.edgeVisualizerFactory.edges;
                const declarationList =
                    (options?.prev ? prevOpenedDeclarationList.current : openedDeclarationList.current) ??
                    ({ [prop]: [] } as OpenedDeclarationList<string>);

                if (options?.type === 'latest') {
                    return getLatestOpenedNode(prop, declarationList) as GetPropOptionReturn<EdgeProps, OPTIONS>;
                }

                const defaultEdgeValue = DEFAULT_EDGES.value + DEFAULT_EDGES.unit;
                const declValue = getDeclarationValue(declarationList, prop, defaultEdgeValue);

                return (
                    options?.type === 'string'
                        ? getDeclarationText(
                              declValue[declValue.length - 1],
                              options.stringifyExpression ?? drivers.variables.getVariableValue
                          )?.trim()
                        : declValue
                ) as GetPropOptionReturn<EdgeProps, OPTIONS>;
            },
            setProp: useMemo(
                () =>
                    onChange
                        ? (prop, options) => (declarations) => {
                              const changeValue = createDeclarationMapFromVisualizerValue(declarations, {
                                  value: [],
                                  drivers,
                              })[prop];

                              if (changeValue) {
                                  options?.beforeChange?.();

                                  onChange(
                                      getSimpleShorthandChange<EdgeMainProp, EdgeProps>(
                                          main,
                                          { [options?.linked ? main : prop]: `${changeValue}` } as EdgeDeclarationMap,
                                          openedDeclarationList.current,
                                          props,
                                          options?.linked
                                      )
                                  );
                              }
                          }
                        : undefined,
                [drivers, onChange, props]
            ),
        };
    };
    return useEdgeDriver;
}
