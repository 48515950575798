import type { TypedCompoundValueParser } from './compound-types';
import { KeywordsMap, Dimension, parseDimension, EMPTY_DIMENSION } from '../css-data-types';

export const identityCompoundValueParser: TypedCompoundValueParser<string> = (text) => text;

export const keywordsCompoundValueParser =
    (keywords: string | KeywordsMap): TypedCompoundValueParser<boolean> =>
    (text) =>
        typeof keywords === 'string' ? text === keywords : keywords.has(text);

export const dimensionCompoundValueParser: TypedCompoundValueParser<Dimension> = (text) =>
    parseDimension(text) ?? EMPTY_DIMENSION;
