import { CompositeBlock, RadioOptions, RadioSelect, SelectOnChangeEvent } from '@wix/stylable-panel-components';
import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import React, { FC, useState } from 'react';
import { getTranslate } from '../../hosts/translate';
import type { ExtendedGlobalHost } from '../../types';
import { classes, style } from './line-height-input.st.css';

export interface LineHeightInputProps {
    className?: string;
    isCustom: boolean;
    onChange: (isCustom: boolean) => void;
    panelHost?: ExtendedGlobalHost;
}

export const LineHeightInput: FC<LineHeightInputProps> = ({ children, onChange, isCustom, panelHost, className }) => {
    const translate = getTranslate(panelHost);
    const labels = {
        automatic: translate(StylablePanelTranslationKeys.controller.text.lineHeightSelectorAutomatic),
        custom: translate(StylablePanelTranslationKeys.controller.text.lineHeightSelectorCustom),
    };
    const [selectOptions, setSelectOptions] = useState<RadioOptions[]>([
        { label: labels.automatic, checked: !isCustom },
        { label: labels.custom, checked: isCustom },
    ]);
    const handleSelectChange: SelectOnChangeEvent = ({ target }, index) => {
        const { value, checked } = target;
        setSelectOptions(
            selectOptions.map((option, i) => {
                option.checked = i === index && checked;
                return option;
            })
        );
        onChange(value === labels.custom);
    };

    return (
        <div className={style(classes.root, className)}>
            <CompositeBlock
                className={classes.customSelect}
                title={translate(StylablePanelTranslationKeys.controller.text.lineHeightSelectorLabel)}
                information={translate(
                    StylablePanelTranslationKeys.controller.text.lineHeightSelectorInformationTooltip
                )}
            >
                <RadioSelect
                    onChange={handleSelectChange}
                    className={classes.lineHeightSelect}
                    options={selectOptions}
                />
            </CompositeBlock>
            {children}
        </div>
    );
};
