import type { Overflows } from '../shorthand-css-data';
import type {
    SimpleShorthandOpener,
    ShorthandPart,
    GetSimpleShorthandOpener,
    GetShorthandCloser,
} from '../shorthand-types';

import { overflowDataType } from '../../css-data-types';
import {
    splitSimpleShorthandOpener,
    unorderedListShorthandOpener,
    createShorthandOpener,
    createShorthandCloserTemplateFromParts,
    createShorthandCloser,
} from '../shorthand-parser-utils';

const getOverflowShorthandParts = <V>() =>
    [
        {
            prop: 'overflow-x',
            dataType: overflowDataType,
            partOpener: splitSimpleShorthandOpener(['overflow-x', 'overflow-y']),
        },
        {
            prop: 'overflow-y',
            dataType: overflowDataType,
        },
    ] as ShorthandPart<V, Overflows>[];

// overflow
export const openOverflowShorthand: GetSimpleShorthandOpener<Overflows> = <V>() =>
    createShorthandOpener({
        prop: 'overflow',
        parts: getOverflowShorthandParts(),
        shorthandOpener: (astNodes, api, parts) => unorderedListShorthandOpener(parts)(astNodes, api),
    }) as SimpleShorthandOpener<V, Overflows>;

export const closeOverflowShorthand: GetShorthandCloser<Overflows> = <V>() =>
    createShorthandCloser<V, Overflows>(createShorthandCloserTemplateFromParts(getOverflowShorthandParts()));
