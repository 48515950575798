import chroma from 'chroma-js';
import type { GradientNode } from 'gradient-parser';
import React, { useCallback, useEffect, useRef } from 'react';

import { createCssValueAST } from '@wix/shorthands-opener';

import { DIMENSION_ID, DIMENSION_INPUT_DEFAULTS } from '@wix/stylable-panel-common';
import { Gradient as GradientIcon, Image, Solid } from '@wix/stylable-panel-common-react';
import { BackgroundBox, OptimisticWrapper, Option, Tooltip } from '@wix/stylable-panel-components';
import {
    FullDeclarationMap,
    normalizeGradientColorStop,
    StylablePanelTranslationKeys,
    verifiedExpandGradient,
} from '@wix/stylable-panel-drivers';

import type { OpenedDeclarationArray } from '../../declaration-types';
import { getDimensionConfig, OpacityVisualizer } from '../../generated-visualizers';
import type { DeclarationChangeValue, VisualizerFC } from '../../types';
import {
    compareVisualizerValues,
    createDeclarationMapFromVisualizerValue,
    createDeclarationVisualizer,
    createVisualizerValueFromDeclarationMap,
    getDeclarationChangeTextValue,
    getDeclarationText,
    isFullExpressionVisualizer,
    stringifyBackgroundImage,
    visualizerOptimisticValueResolver,
} from '../../utils';
import { LayersController /*, { LAYER_HIDDEN_PROP }*/ } from '../layers-controller/layers-controller';
import { ALL_BACKGROUND_PROPS } from './background-consts';
import { BackgroundLayer, BackgroundProps, BackgroundVisualizerProps } from './background-types';

import { DEFAULT_PLANE } from '@wix/stylable-panel-common';
import { classes, style } from './background-visualizer.st.css';
import { useBackgroundVisualizerState } from './use-background-visualizer-state';
import { useFillPicker } from '../../hooks';
import { FillPickerTabs } from '../../pickers';

const DEFAULT_OPACITY = DIMENSION_INPUT_DEFAULTS.backgroundVisualizer.opacity;

const addLayerMenuOptions: Option[] = [
    { id: 'solid', displayName: 'Solid', icon: Solid },
    { id: 'gradient', displayName: 'Gradient', icon: GradientIcon },
    { id: 'image', displayName: 'Image', icon: Image },
];

function constrainBackgroundImageForPreview(value: FullDeclarationMap): FullDeclarationMap {
    const retVal = Object.assign({}, value);
    if (retVal['background-repeat'] && retVal['background-repeat'] === 'no-repeat') {
        retVal['background-size'] = 'cover';
        retVal['background-position'] = 'center';
    } else {
        retVal['background-size'] = '80%';
        retVal['background-position'] = '0% 0%';
        retVal['background-repeat'] = 'repeat';
    }
    return retVal;
}

export type BackgroundVisualizerValue = OpenedDeclarationArray<BackgroundProps>;

export type BackgroundVisualizer = VisualizerFC<BackgroundProps, BackgroundVisualizerProps>;

export const BackgroundVisualizerInner: BackgroundVisualizer = (props) => {
    const { drivers, value, panelHost, plane = DEFAULT_PLANE, inlineLayers, className, siteVarsDriver } = props;
    const currValue = useRef(value);
    const prevValue = useRef<BackgroundVisualizerValue>();
    const {
        backgroundLayers,
        layerTypes,
        fillPickerIndex,
        shouldOpenFillPicker,
        translate,
        changeBackgroundColor,
        changeBackgroundColorAlpha,
        changeBackgroundGradientAlpha,
        changeBackgroundLayer,
        addNewBackgroundLayer,
        replaceBackgroundLayers,
        removeBackgroundLayer,
        duplicateBackgroundLayer,
        closeFillPicker,
        setShouldOpenFillPicker,
        setFillPickerIndex,
    } = useBackgroundVisualizerState(props);

    const openFillPickerPanel = useFillPicker({
        className: classes.fillPicker,
        title: translate(StylablePanelTranslationKeys.controller.fills.fillPickerTitle),
        drivers,
        panelHost,
        siteVarsDriver,
    });

    const openFillPicker = useCallback(
        (layerIndex: number) => {
            if (!openFillPickerPanel) {
                return;
            }

            const solid = layerTypes.current[layerIndex] === BackgroundLayer.Solid;
            let layerValue: string | undefined;
            let index = layerIndex;
            if (solid) {
                layerValue = backgroundLayers.current.solid;
                index = backgroundLayers.current.images.length;
            } else {
                const value = backgroundLayers.current.images[layerIndex];
                layerValue =
                    value && typeof value !== 'string' ? stringifyBackgroundImage(value.background || value) : value;
            }
            let fillPickerTab = layerTypes.current[layerIndex] as any as FillPickerTabs;
            let fillPickerValue = layerValue;
            if (layerValue && layerTypes.current[layerIndex] === BackgroundLayer.Gradient) {
                const expanded = verifiedExpandGradient(layerValue);
                if (expanded) {
                    const gradientAST = expanded['background-image'] as GradientNode;
                    const colorStops = gradientAST.colorStops.map((stop) => normalizeGradientColorStop(stop));
                    if (
                        gradientAST.type === 'linear-gradient' &&
                        colorStops.length === 2 &&
                        colorStops[0] === colorStops[1]
                    ) {
                        fillPickerValue = colorStops[0];
                        fillPickerTab = FillPickerTabs.Solid;
                    }
                }
            }

            const onChange = (value: DeclarationChangeValue, tab: FillPickerTabs) => {
                let textValue = getDeclarationChangeTextValue(value);
                let declarations = Array.isArray(value) ? value : undefined;
                if (layerTypes.current[index] === BackgroundLayer.Solid) {
                    changeBackgroundColor(textValue, declarations);
                } else {
                    if (declarations && tab === FillPickerTabs.Solid) {
                        textValue = getDeclarationChangeTextValue(value, drivers.variables.getVariableValue);
                        declarations = undefined;
                    }
                    changeBackgroundLayer(tab, textValue, index, declarations);
                }
            };

            openFillPickerPanel({
                // key: `fill_picker_${index}`,
                value: fillPickerValue,
                tab: fillPickerTab,
                onChange,
                onAstChange: onChange,
                onClose: () => closeFillPicker(),
            });
            if (layerIndex !== fillPickerIndex) {
                setFillPickerIndex(layerIndex);
            }
        },
        [
            backgroundLayers,
            changeBackgroundColor,
            changeBackgroundLayer,
            closeFillPicker,
            drivers.variables.getVariableValue,
            fillPickerIndex,
            layerTypes,
            openFillPickerPanel,
            setFillPickerIndex,
        ]
    );

    const renderBackgroundLayer = useCallback(
        (type: BackgroundLayer, value: FullDeclarationMap, index: number) => {
            const { value: defaultOpacityValue, unit: defaultOpacityUnit } = DEFAULT_OPACITY;

            const solid = type === BackgroundLayer.Solid;

            const dimensionConfig = getDimensionConfig({
                id: DIMENSION_ID.OPACITY,
                dimensionUnits: panelHost?.dimensionUnits,
                dimensionKeywords: panelHost?.dimensionKeywords,
            });

            const layerValueBase = value.background || value;
            const layerValue = stringifyBackgroundImage(layerValueBase);
            const layerPreviewStyle =
                type === BackgroundLayer.Image
                    ? stringifyBackgroundImage(constrainBackgroundImageForPreview(value))
                    : layerValue;

            let opacityValue = defaultOpacityValue;
            let opacityDisabled = true;
            let opacityChange: (value: string) => void = () => null;
            let noColor = false;

            // TODO: Try to make this happen once, and use in renderFillPicker
            if (layerValue && type === BackgroundLayer.Gradient) {
                const expanded = verifiedExpandGradient(layerValue);
                if (expanded) {
                    const gradientAST = expanded['background-image'] as GradientNode;
                    const colorStops = gradientAST.colorStops.map((stop) => normalizeGradientColorStop(stop));
                    try {
                        opacityValue = Math.max(
                            ...colorStops.map((stop) => Math.floor((chroma(stop).alpha() as any as number) * 100))
                        );
                        opacityChange = (val) => changeBackgroundGradientAlpha(expanded, val, index);
                        opacityDisabled = false;
                    } catch {
                        //
                    }
                }
            } else if (solid) {
                try {
                    const color = chroma(layerValue || '');
                    opacityValue = Math.floor((color.alpha() as any as number) * 100);
                } catch {
                    //
                }
                opacityChange = (val) => changeBackgroundColorAlpha(val);
                if (!layerValue) {
                    noColor = true;
                } else {
                    opacityDisabled = false;
                }
            }

            const opacity = opacityValue + defaultOpacityUnit;

            // TODO: Wrap solid layers with propertyWrappers
            return (
                <span
                    key={`background_layer_${index}`}
                    className={classes.backgroundLayer} /*{...{[LAYER_HIDDEN_PROP]: hidden}}*/
                >
                    <Tooltip
                        className={classes.backgroundTooltip}
                        text={translate(StylablePanelTranslationKeys.controller.fills.layerThumbnailTooltip)}
                        verticalAdjust={-1}
                        horizontalAdjust={-5}
                    >
                        <BackgroundBox
                            className={classes.backgroundBox}
                            value={layerPreviewStyle}
                            selected={index === fillPickerIndex}
                            noColor={noColor}
                            onClick={() => openFillPicker(index)}
                            data-aid="st_backgroundcontroller_backgroundbox"
                        />
                    </Tooltip>
                    {noColor || !opacityDisabled ? (
                        <OpacityVisualizer
                            drivers={drivers}
                            className={classes.opacityInput}
                            value={createVisualizerValueFromDeclarationMap({ opacity })}
                            config={dimensionConfig}
                            isDisabled={opacityDisabled}
                            onChange={(value) => {
                                const { opacity } = createDeclarationMapFromVisualizerValue(value, {
                                    value: [],
                                    drivers,
                                });
                                if (opacity) {
                                    opacityChange(opacity);
                                }
                            }}
                            panelHost={panelHost}
                        />
                    ) : (
                        <div className={classes.imagePlaceholder} />
                    )}
                </span>
            );
        },
        [
            fillPickerIndex,
            changeBackgroundColorAlpha,
            changeBackgroundGradientAlpha,
            openFillPicker,
            drivers,
            panelHost,
            translate,
        ]
    );
    const disabled = isFullExpressionVisualizer('background', props);
    const showSolidLayer = !!backgroundLayers.current.solid || backgroundLayers.current.images.length === 0;

    useEffect(() => {
        prevValue.current = currValue.current;
        currValue.current = value;

        if (compareVisualizerValues(currValue.current, prevValue.current, props)) {
            if (shouldOpenFillPicker) {
                openFillPicker(0);
                setShouldOpenFillPicker(false);
            } else if (fillPickerIndex !== -1 && layerTypes.current[fillPickerIndex] === BackgroundLayer.Image) {
                openFillPicker(fillPickerIndex);
            }
        }
        
    }, [fillPickerIndex, layerTypes, openFillPicker, props, setShouldOpenFillPicker, shouldOpenFillPicker, value]);

    return (
        <div className={style(classes.root, { plane, disabled }, className)}>
            <LayersController
                className={classes.layersController}
                title={translate(StylablePanelTranslationKeys.controller.fills.title)}
                addLayerLabel={translate(StylablePanelTranslationKeys.controller.fills.addLayerButtonLabel)}
                addOptions={!inlineLayers ? addLayerMenuOptions : []}
                plane={plane}
                panelHost={panelHost}
                onAdd={(id) => addNewBackgroundLayer(id)}
                onReplace={(srcIndex, dstIndex) => replaceBackgroundLayers(srcIndex, dstIndex)}
                onDuplicate={(index) => duplicateBackgroundLayer(index)}
                onRemove={(index) => removeBackgroundLayer(index)}
                // onHide={(hidden, index) => this.showHideBackgroundLayer(hidden, index)}
            >
                {backgroundLayers.current.images.map((val, index) => {
                    const type = layerTypes.current[index];
                    return renderBackgroundLayer(type, val, index);
                })}
                {showSolidLayer
                    ? renderBackgroundLayer(
                          BackgroundLayer.Solid,
                          { background: backgroundLayers.current.solid! },
                          backgroundLayers.current.images.length
                      )
                    : null}
            </LayersController>
        </div>
    );
};

BackgroundVisualizerInner.defaultProps = {
    inlineLayers: true,
};
BackgroundVisualizerInner.INPUT_PROPS = ALL_BACKGROUND_PROPS;

const backgroundVisualizerOptimisticValueResolver = (
    targetValue: BackgroundVisualizerValue | undefined,
    sourceValue: BackgroundVisualizerValue
) => {
    const resolvedValue = visualizerOptimisticValueResolver(targetValue, sourceValue);
    return resolvedValue.reduce((wrappedValue, value) => {
        let valueAST = value.value;
        if (value.name === 'background' && value.value.length === 1) {
            const declarationText = getDeclarationText(value);
            if (declarationText) {
                valueAST = createCssValueAST(declarationText);
            }
        }
        wrappedValue.push({
            ...value,
            value: valueAST,
        });
        return wrappedValue;
    }, [] as BackgroundVisualizerValue);
};

export const BackgroundVisualizer = OptimisticWrapper<BackgroundVisualizer, BackgroundVisualizerValue>(
    BackgroundVisualizerInner,
    {},
    true,
    backgroundVisualizerOptimisticValueResolver
);
BackgroundVisualizer.INPUT_PROPS = BackgroundVisualizerInner.INPUT_PROPS;
BackgroundVisualizer.BLOCK_VARIANT_CONTROLLER = false;
BackgroundVisualizer.AST_VALUE = true;

export const BackgroundDeclarationVisualizer = createDeclarationVisualizer<BackgroundProps>(
    'background',
    BackgroundVisualizer
);
