import { gradientFromAST, parseSolidGradient, solidGradient, stringifyGradient } from '@wix/stylable-panel-drivers';
import { INITIAL_BACKGROUND_COLOR, INITIAL_IMAGE_SOURCE } from '@wix/shorthands-opener';
import chroma from 'chroma-js';
import type { GradientNode } from 'gradient-parser';
import { useCallback, useMemo, useState } from 'react';
import type { OpenedDeclarationArray } from '../../declaration-types';
import { useTranslate } from '../../hooks';
import { PanelEventList } from '../../hosts/bi';
import { FillPickerSectionConfig, FillPickerTabs } from '../../pickers';
import { createShorthandOpenerApi } from '../../utils';
import { SetBackgroundPropOptions, useBackgroundDriver } from './background-driver';
import type { BackgroundDeclarationMap, BackgroundProps, BackgroundVisualizerProps } from './background-types';
import { useBackgroundLayers } from './use-background-layers';

const { LAYER_ACTION_CLICK, ADD_LAYER_CLICK, COMPONENT_EDITED } = PanelEventList;

export const useBackgroundVisualizerState = (props: BackgroundVisualizerProps) => {
    const { value, drivers, panelHost, siteVarsDriver, inlineLayers, selectorState } = props;
    const translate = useTranslate(panelHost);
    const shorthandApi = useMemo(() => createShorthandOpenerApi(drivers.variables), [drivers.variables]);

    const { backgroundLayers, layerTypes } = useBackgroundLayers({
        value,
        shorthandApi,
        stringifyExpression: drivers.variables.getVariableValue,
        siteVarsDriver,
    });

    const [fillPickerIndex, setFillPickerIndex] = useState(-1);
    const [shouldOpenFillPicker, setShouldOpenFillPicker] = useState(false);

    const reportBackgroundChangeEvent = useCallback((eventType, value, tabIndex = -1) => {
        const tab = FillPickerSectionConfig[tabIndex as FillPickerTabs];
        const newValue = {
            ...value,
            tab: tab?.id,
        }
        panelHost?.reportBI && panelHost.reportBI(
            COMPONENT_EDITED,
            {
                control_id: eventType,
                panel_type: 'backgroundFill_FillPicker',
                stage_type: selectorState ?? 'regular',
                new_value: JSON.stringify(newValue),
            }
        );
    }, [panelHost, selectorState]);

    // TODO: Think about moving it back to the background-visualizer component
    const { setProp } = useBackgroundDriver(props);
    const changeBackgroundColor = useCallback(
        (value: string | null, declarations?: OpenedDeclarationArray<string>) => {
            if (!setProp) {
                return;
            }

            let newProp;
            if (value && value.includes('linear-gradient') && backgroundLayers.current.images.length === 0) {
                newProp = {
                    value: {
                      'background-image': value,
                      'background-color': INITIAL_BACKGROUND_COLOR,
                    } as BackgroundDeclarationMap,
                    layerIndex: 0,
                }
                setProp(newProp);
            } else {
                newProp = {
                  name: 'background-color' as BackgroundProps,
                  value: declarations || value,
                }
                setProp(newProp);
            }
            reportBackgroundChangeEvent('BackgroundColor', newProp);
        },
        [backgroundLayers, reportBackgroundChangeEvent, setProp]
    );

    const changeBackgroundLayer = useCallback(
        (
            tab: FillPickerTabs,
            value: string | null,
            layerIndex: number,
            declarations?: OpenedDeclarationArray<string>
        ) => {
            if (!setProp) {
                return;
            }

            let setValue = value || '';
            if (value && !~value.indexOf('linear-gradient') && tab === FillPickerTabs.Solid) {
                setValue =
                    layerIndex !== backgroundLayers.current.images.length - 1 || !!backgroundLayers.current.solid
                        ? solidGradient(value)
                        : value;
            }

            let newProp;
            if (!backgroundLayers.current.solid && layerIndex === backgroundLayers.current.images.length - 1) {
                if (tab !== FillPickerTabs.Solid) {
                    newProp = {
                      value: {
                        'background-color': INITIAL_BACKGROUND_COLOR,
                        background: setValue,
                      } as BackgroundDeclarationMap,
                      layerIndex,
                    };
                    setProp(newProp);
                } else {
                    newProp = {
                      value: {
                        'background-color': setValue,
                        'background-image': INITIAL_IMAGE_SOURCE,
                      } as BackgroundDeclarationMap,
                      layerIndex,
                    };
                    setProp(newProp);
                }
            } else {
                newProp = { name: 'background' as BackgroundProps, value: declarations || setValue, layerIndex };
                setProp(newProp);
            }
        },
        [backgroundLayers, reportBackgroundChangeEvent, setProp]
    );

    const changeBackgroundColorAlpha = useCallback(
        (value: string) => {
            if (!setProp) {
                return;
            }

            let newProp;
            try {
                const color = chroma(backgroundLayers.current.solid || '');
                const numberValue = parseFloat(value);
                newProp = { name: 'background-color' as BackgroundProps, value: color.alpha(numberValue / 100).css() };
                setProp(newProp);
            } catch {
                //
            }
            reportBackgroundChangeEvent('BackgroundColorAlpha', newProp);
        },
        [backgroundLayers, reportBackgroundChangeEvent, setProp]
    );

    const changeBackgroundGradientAlpha = useCallback(
        (expandedGradient: Record<string, string | GradientNode>, value: string, layerIndex: number) => {
            if (!setProp) {
                return;
            }

            const numericValue = parseInt(value);
            const gradient = gradientFromAST(expandedGradient['background-image'] as GradientNode);
            const maxOpacity = Math.max(
                ...gradient.colorStops.map((stop) => Math.floor(chroma(stop.color).alpha() * 100))
            );
            const multiplier = numericValue / maxOpacity;
            for (const colorStop of gradient.colorStops) {
                const currentColor = chroma(colorStop.color);
                // Applying alpha to each color stop respectfully to the main alpha
                const newAlpha =
                    multiplier === Infinity
                        ? parseFloat((numericValue / 100).toFixed(2))
                        : parseFloat((currentColor.alpha() * multiplier).toFixed(2));
                colorStop.color = currentColor.alpha(newAlpha).css();
            }

            const newProp = {
              name: 'background-image' as BackgroundProps,
              value: stringifyGradient(gradient, false),
              layerIndex,
            };
            setProp(newProp);
            reportBackgroundChangeEvent('BackgroundGradientAlpha', newProp);
        },
        [reportBackgroundChangeEvent, setProp]
    );

    const getLastSolidColor = useCallback(
        () =>
            parseSolidGradient(
                backgroundLayers.current.images[backgroundLayers.current.images.length - 1].background ||
                    backgroundLayers.current.images[backgroundLayers.current.images.length - 1]['background-image']
            ),
        [backgroundLayers]
    );

    const addNewBackgroundLayer = useCallback(
        (id?: string) => {
            if (!setProp || (!id && !inlineLayers)) {
                return;
            }

            const setPropOptions: SetBackgroundPropOptions[] = [];
            const shouldSendSolid = !backgroundLayers.current.solid && backgroundLayers.current.images.length === 0;

            const getSolidNewValue = () =>
                shouldSendSolid
                    ? FillPickerSectionConfig[FillPickerTabs.Solid].defaultValue
                    : solidGradient(FillPickerSectionConfig[FillPickerTabs.Solid].defaultValue);

            let newLayerString = '';
            switch (id) {
                case 'solid':
                    newLayerString = getSolidNewValue();
                    break;
                case 'gradient':
                    newLayerString = FillPickerSectionConfig[FillPickerTabs.Gradient].defaultValue;
                    break;
                case 'image':
                    newLayerString = FillPickerSectionConfig[FillPickerTabs.Image].defaultValue;
                    break;
                case undefined:
                    newLayerString = getSolidNewValue();
                    setShouldOpenFillPicker(true);
                    break;
            }

            panelHost?.reportBI && panelHost.reportBI(
              ADD_LAYER_CLICK,
              {
                type: 'background',
                state: selectorState ?? 'regular',
              }
            );

            if (newLayerString) {
                if (!shouldSendSolid || id === 'gradient' || id === 'image') {
                    setPropOptions.push({
                        name: 'background',
                        action: 'add',
                        value: newLayerString,
                        layerIndex: 0,
                        forceShorthand: true,
                    });
                    if (backgroundLayers.current.solid) {
                        setPropOptions.push({
                            name: 'background',
                            action: 'refresh-color',
                            value: backgroundLayers.current.solid,
                            layerIndex: backgroundLayers.current.images.length + 1,
                        });
                    }
                } else {
                    setPropOptions.push({ name: 'background-color', value: newLayerString });
                }
            }

            if (setPropOptions.length > 0) {
                setProp(setPropOptions);
            }
        },
        [setProp, inlineLayers, backgroundLayers, panelHost, selectorState]
    );

    const replaceBackgroundLayers = useCallback(
        (srcIndex: number, dstIndex: number) => {
            if (!setProp || srcIndex === dstIndex) {
                return;
            }

            const setPropOptions: SetBackgroundPropOptions[] = [];
            const allLayers = [...backgroundLayers.current.images];
            let numImageLayers = backgroundLayers.current.images.length;
            let shouldResetSolid = false;

            if (backgroundLayers.current.solid) {
                const newSolidGradientLayer = solidGradient(backgroundLayers.current.solid);
                allLayers.push({ background: newSolidGradientLayer });
                if (srcIndex === numImageLayers || dstIndex === numImageLayers) {
                    shouldResetSolid = true;
                    setPropOptions.push({
                        name: 'background-image',
                        action: 'remove',
                        value: null,
                        layerIndex: numImageLayers,
                    });
                    setPropOptions.push({ name: 'background-color', value: INITIAL_BACKGROUND_COLOR });
                    setPropOptions.push({
                        name: 'background',
                        action: 'add',
                        value: newSolidGradientLayer,
                        layerIndex: numImageLayers,
                    });
                }
            } else {
                shouldResetSolid = true;
            }

            setPropOptions.push({
                name: 'background',
                action: 'swap',
                value: null,
                layerIndex: srcIndex,
                targetLayerIndex: dstIndex,
            });

            const srcLayer = { ...allLayers[srcIndex] };
            allLayers[srcIndex] = allLayers[dstIndex];
            allLayers[dstIndex] = srcLayer;
            backgroundLayers.current.images = allLayers;
            numImageLayers = backgroundLayers.current.images.length;

            if (shouldResetSolid) {
                const lastSolidColor = getLastSolidColor();
                if (lastSolidColor) {
                    setPropOptions.push({
                        name: 'background-image',
                        action: 'remove',
                        value: null,
                        layerIndex: numImageLayers - 1,
                    });
                    setPropOptions.push({
                        name: 'background',
                        action: 'add',
                        value: lastSolidColor,
                        layerIndex: numImageLayers - 1,
                    });
                }
            }

            setProp(setPropOptions);
        },
        [backgroundLayers, getLastSolidColor, setProp]
    );

    const removeBackgroundLayer = useCallback(
        (layerIndex: number) => {
            if (!setProp) {
                return;
            }

            const setPropOptions: SetBackgroundPropOptions[] = [];
            let shouldDeleteSolid = false;

            if (layerIndex === backgroundLayers.current.images.length) {
                delete backgroundLayers.current.solid;
                shouldDeleteSolid = true;
            } else {
                backgroundLayers.current.images.splice(layerIndex, 1);
                setPropOptions.push({
                    name: 'background-image',
                    action: 'remove',
                    value: null,
                    layerIndex,
                });
            }

            if (!backgroundLayers.current.solid && backgroundLayers.current.images.length > 0) {
                const solidColor = getLastSolidColor();
                if (solidColor) {
                    setPropOptions.push({
                        action: 'remove',
                        value: {
                            'background-color': solidColor,
                            'background-image': undefined,
                        } as BackgroundDeclarationMap,
                        layerIndex: backgroundLayers.current.images.length - 1,
                    });
                } else if (shouldDeleteSolid) {
                    setPropOptions.push({ name: 'background-color', value: INITIAL_BACKGROUND_COLOR });
                }
            }

            setProp(setPropOptions);
            panelHost?.reportBI && panelHost.reportBI(
                LAYER_ACTION_CLICK,
                {
                    action: 'delete',
                    state: selectorState ?? 'regular',
                }
            );
        },
        [backgroundLayers, getLastSolidColor, panelHost, selectorState, setProp]
    );

    const duplicateBackgroundLayer = useCallback(
        (layerIndex: number) => {
            if (!setProp) {
                return;
            }

            if (backgroundLayers.current.solid && layerIndex === backgroundLayers.current.images.length) {
                setProp({
                    name: 'background',
                    action: 'add',
                    value: solidGradient(backgroundLayers.current.solid),
                    layerIndex: backgroundLayers.current.images.length,
                });
            } else if (backgroundLayers.current.images.length > 0) {
                setProp({ name: 'background', action: 'duplicate', value: null, layerIndex });
            }
            panelHost?.reportBI && panelHost.reportBI(
                LAYER_ACTION_CLICK,
                {
                  action: 'duplicate',
                  state: selectorState ?? 'regular',
                }
            );
        },
        [backgroundLayers, panelHost, selectorState, setProp]
    );

    const closeFillPicker = useCallback(() => {
        setFillPickerIndex(-1);
    }, []);

    return {
        backgroundLayers,
        layerTypes,
        translate,
        changeBackgroundColor,
        changeBackgroundLayer,
        changeBackgroundColorAlpha,
        changeBackgroundGradientAlpha,
        getLastSolidColor,
        addNewBackgroundLayer,
        replaceBackgroundLayers,
        removeBackgroundLayer,
        duplicateBackgroundLayer,
        closeFillPicker,
        // State handlers
        fillPickerIndex,
        setFillPickerIndex,
        shouldOpenFillPicker,
        setShouldOpenFillPicker,
    };
};
