import React from 'react';

import type {
    VisualizerPropertyWrapperProps,
    VisualizerPropertyWrapper,
    VisualizerPropertyWrappersMap,
    DeclarationVisualizer,
    DeclarationVisualizerAPIProps,
    DeclarationVisualizerProps,
    VisualizerComponentClass,
} from '../types';

export const EmptyVisualizerWrapper = <PROPS extends string>({ children }: VisualizerPropertyWrapperProps<PROPS>) =>
    children as JSX.Element;

export const getVisualizerWrapper = <PROPS extends string>(
    wrappers: VisualizerPropertyWrappersMap<PROPS> | undefined,
    part: PROPS,
    DefaultPropertyWrapper?: VisualizerPropertyWrapper<PROPS>
): VisualizerPropertyWrapper<PROPS> => wrappers?.[part] ?? DefaultPropertyWrapper ?? EmptyVisualizerWrapper;

export const wrapPureVisualizer =
    <PROPS extends string>(part: PROPS, visualizer: DeclarationVisualizer<PROPS>) =>
    ({ propertyWrappers, DefaultPropertyWrapper, drivers, ...wrapperProps }: DeclarationVisualizerAPIProps<PROPS>) => {
        const { title, mainProp, claims, visualizer: Visualizer } = visualizer;

        const Wrapper = getVisualizerWrapper(propertyWrappers, part, DefaultPropertyWrapper);

        return (
            <Wrapper title={title} mainProp={mainProp} claims={claims} {...wrapperProps}>
                <Visualizer {...wrapperProps} drivers={drivers} />
            </Wrapper>
        );
    };

export const createDeclarationVisualizer = <PROPS extends string>(
    mainProp: PROPS,
    visualizer: React.ComponentType<DeclarationVisualizerProps<PROPS>>,
    title: string = mainProp
): DeclarationVisualizer<PROPS> => ({
    title,
    mainProp,
    claims: new Set((visualizer as unknown as VisualizerComponentClass).INPUT_PROPS as PROPS[]),
    visualizer,
    valueValidator: () => true,
});
