import type { Backgrounds, BackgroundLayer as BackgroundLayerGeneric } from '@wix/shorthands-opener';

import type { GenericDeclarationMap } from '@wix/stylable-panel-drivers';

import type { DeclarationVisualizerProps } from '../../types';
import type { OriginNode, OpenedDeclaration, OpenedDeclarationArray } from '../../declaration-types';

export enum BackgroundLayer {
    Solid = 0,
    Gradient,
    Image,
}

export type BackgroundDeclaration = OpenedDeclaration<BackgroundProps>;
export type BackgroundDeclarations = OpenedDeclarationArray<BackgroundProps>;
export type BackgroundDeclarationMap = GenericDeclarationMap<BackgroundProps>;
export type BackgroundShorthandLayer = BackgroundLayerGeneric<OriginNode>;

export type BackgroundLonghandProps = Backgrounds | 'background-position-x' | 'background-position-y';
export type BackgroundProps = BackgroundLonghandProps | 'background';

export interface BackgroundVisualizerProps extends DeclarationVisualizerProps<BackgroundProps> {
    inlineLayers?: boolean;
}
