import { useCallback, useMemo } from 'react';
import { useDeclarationMapValue, useTranslate } from '../../hooks';
import type { FontFamilyVisualizerProps } from './font-family-visualizer';
import type { Option } from '@wix/stylable-panel-components';
import type { SelectableFont } from '../../components';
import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';

export const EMPTY_FONT_FAMILY = 'Default';

const FONT_FAMILY_OPTION_ID_KEY = 'fontFamily';
const FONT_FAMILY_CSS_VALUE_KEY = 'cssFontFamily';

export const useFontFamilyVisualizer = (props: FontFamilyVisualizerProps) => {
    const { panelHost } = props;

    const translate = useTranslate(panelHost);

    const selectableFonts = useMemo(() => panelHost?.getSelectableFonts?.() ?? [], [panelHost]);

    const fontOptions = useMemo(
        () =>
            panelHost?.getFontsItems?.(selectableFonts)?.reduce((options, fontGroup) => {
                options.push({
                    id: fontGroup.groupName,
                    displayName: translate(fontGroup.groupName),
                    disabled: true,
                });
                fontGroup.items.forEach((fontItem) => {
                    options.push({
                        id: fontItem.value,
                        displayName: fontItem.label,
                        subLabel: fontItem.example ? translate(fontItem.example) : undefined,
                        font: fontItem.cssFontFamily,
                    });
                });
                return options;
            }, [] as Option[]) ?? [],
        [panelHost, selectableFonts, translate]
    );

    const [declarationMapValue, handleChange] = useDeclarationMapValue('font-family', props);

    const searchSelectableFonts = useCallback(
        (value: string, searchKey: keyof SelectableFont, valueKey: keyof SelectableFont, matchPrefix = false) => {
            for (const currSelectableFont of selectableFonts) {
                if (!currSelectableFont.fonts) {
                    continue;
                }

                for (const currFont of currSelectableFont.fonts) {
                    const searchString = currFont[searchKey] as string;

                    if ((matchPrefix && value.startsWith(searchString)) || value === searchString) {
                        return currFont[valueKey];
                    }
                }
            }

            return undefined;
        },
        [selectableFonts]
    );

    const stringValue = useMemo(() => {
        const value = declarationMapValue ?? EMPTY_FONT_FAMILY;

        return value !== EMPTY_FONT_FAMILY
            ? (searchSelectableFonts(value, FONT_FAMILY_CSS_VALUE_KEY, FONT_FAMILY_OPTION_ID_KEY, true) as string) ||
                  EMPTY_FONT_FAMILY
            : EMPTY_FONT_FAMILY;
    }, [declarationMapValue, searchSelectableFonts]);

    const handleSelect = useCallback(
        (itemId: string) => {
            if (!handleChange) {
                return;
            }

            if (itemId === EMPTY_FONT_FAMILY) {
                handleChange(itemId);
                return;
            }

            const selectedItem = fontOptions.find((option) => option.id === itemId);
            if (selectedItem) {
                const cssFontFamily = searchSelectableFonts(
                    selectedItem.id,
                    FONT_FAMILY_OPTION_ID_KEY,
                    FONT_FAMILY_CSS_VALUE_KEY
                ) as string | undefined;
                if (cssFontFamily) {
                    handleChange(cssFontFamily);
                }
            }
        },
        [fontOptions, handleChange, searchSelectableFonts]
    );

    const emptyFontFamilyOption = useMemo(
        () =>
            ({
                id: EMPTY_FONT_FAMILY,
                displayName: translate(StylablePanelTranslationKeys.controller.text.fontFamilySelectorDefaultOption),
            } as Option),
        [translate]
    );

    return {
        fontOptions,
        stringValue,
        handleSelect,
        emptyFontFamilyOption,
    };
};
