import { BoxShadowMap, Dimension, evaluateAst, getShorthandLayers } from '@wix/shorthands-opener';

import { SiteVarsDriver, removeComments, DEFAULT_EVAL_DECLARATION_VALUE } from '@wix/stylable-panel-drivers';

import type {
    OriginNode,
    ParseShorthandAPI,
    ParsedCompoundAst,
    ParsedCompound,
    OpenedDeclarationArray,
} from '../../declaration-types';
import { ShadowProps, ShadowLayer, parseShadowFromCompound } from './shadow-utils';
import {
    getCompoundParser,
    getDeclarationText,
    evalOpenedDeclarationValue,
    getPropValueDeclaration,
    sanitizeOpenedDeclaration,
} from '../../utils';
import type { ExtendedGlobalHost } from '../../types';
import { getDimensionInputDefaults } from '@wix/stylable-panel-common';

const getEmptyShadow = (panelHost: ExtendedGlobalHost | undefined) => {
    const emptyShadow = getDimensionInputDefaults(panelHost).singleShadowInput.emptyShadow;
    // Santa-editor performs Object.freeze on the panelHost defaults, so we need to do a deep clone
    return {
        ...emptyShadow,
        'blur-radius': {...emptyShadow['blur-radius']},
        'spread-radius': {...emptyShadow['spread-radius']},
        'offset-x': {...emptyShadow['offset-x']},
        'offset-y': {...emptyShadow['offset-y']},
    } as typeof emptyShadow
}

export const calcShadowLayers = <S extends ShadowProps>(
    prop: S,
    value: OpenedDeclarationArray<S>,
    shorthandApi: ParseShorthandAPI,
    panelHost: ExtendedGlobalHost | undefined,
    stringifyExpression?: (v: OriginNode) => string,
    siteVarsDriver?: SiteVarsDriver
): ShadowLayer[] => {
    const EMPTY_SHADOW = getEmptyShadow(panelHost);
    const decl = getPropValueDeclaration(value, prop);
    if (!decl || !getDeclarationText(decl, stringifyExpression)?.includes(' ')) {
        return [{ layer: { ...EMPTY_SHADOW }, origins: {} }];
    }

    const evalDeclarationValue =
        siteVarsDriver?.evalDeclarationValue?.bind(siteVarsDriver) ?? DEFAULT_EVAL_DECLARATION_VALUE;
    const parser = getCompoundParser(prop);

    const compoundLayers = getShorthandLayers(
        evaluateAst(sanitizeOpenedDeclaration(decl, removeComments).value, shorthandApi)
    );

    return compoundLayers.map((layer) => {
        const compound = evalOpenedDeclarationValue(
            layer.map((ast) => ast.origin || ast.value),
            evalDeclarationValue,
            stringifyExpression
        );

        try {
            const parsedShadowCompound = parser(compound, shorthandApi);

            return {
                layer: parseShadowFromCompound(prop, parsedShadowCompound)[0],
                origins: {
                    'blur-radius': (parsedShadowCompound['blur-radius'] as ParsedCompoundAst<Dimension>).origin,
                    'spread-radius': (
                        (parsedShadowCompound as ParsedCompound<BoxShadowMap>)[
                            'spread-radius'
                        ] as ParsedCompoundAst<Dimension>
                    )?.origin,
                    color: (parsedShadowCompound.color as ParsedCompoundAst<string>).origin,
                },
            };
        } catch (e) {
            console.warn(e);
            return { layer: { ...EMPTY_SHADOW }, origins: {} };
        }
    });
};
