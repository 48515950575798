import type {
    EvaluatedAst,
    OpenedShorthand,
    SimpleOpenedShorthand,
    SimpleShorthandOpener,
    SimpleShorthandOpenerInner,
    FullShorthandOpener,
    ShorthandPart,
    GetSimpleShorthandOpener,
    ShorthandCloser,
    GetShorthandCloser,
} from '../shorthand-types';

import { createCssValueAST, valueTextNode } from '../../tokenizers';
import { codeToEvaluatedAst } from '../shorthands-ast-evaluation';
import { GridTemplates, GRID_TEMPLATE_KEYWORD_VALUE_MAP } from '../shorthand-css-data';
import {
    ParseTrackListWithStringsMatch,
    trackSizePredicate,
    parseTrackListWithStrings,
    getTrackListAreasText,
    getTrackListRowsText,
    gridTemplateAreasDataType,
    gridTemplateRowsDataType,
    gridTemplateColumnsDataType,
    explicitTrackListDataType,
    gridTemplateSingleValueDataType,
} from '../../css-data-types';
import {
    matchDataType,
    isOpenedInitial,
    singleKeywordShorthandOpener,
    createShorthandOpener,
    getOpenedNode,
    shorthandCloserTemplate,
    createShorthandCloser,
    fixAstNodesPositions,
} from '../shorthand-parser-utils';
import { NoMandatoryPartMatchError, CannotCloseGridTemplateError } from '../shorthand-parser-errors';

type OpenedGridTemplates<V> = OpenedShorthand<V, GridTemplates>;
type GridTemplateShorthandCloser<V> = ShorthandCloser<V, GridTemplates, OpenedGridTemplates<V>>;

export const getGridTemplateShorthandParts = <V>() =>
    [
        { prop: 'grid-template-areas', dataType: gridTemplateAreasDataType, multipleItems: true },
        { prop: 'grid-template-rows', dataType: gridTemplateRowsDataType, multipleItems: true },
        { prop: 'grid-template-columns', dataType: gridTemplateColumnsDataType, multipleItems: true },
    ] as ShorthandPart<V, GridTemplates>[];

const openGridTemplateShorthandColumns = <V>(
    opened: Partial<OpenedGridTemplates<V>>,
    astNodes: EvaluatedAst<V>[],
    prevMatchLength: number,
    explicit = false
) => {
    const columnsMatch = matchDataType(
        !explicit ? gridTemplateColumnsDataType : explicitTrackListDataType,
        astNodes,
        prevMatchLength,
        gridTemplateRowsDataType.dataType
    );
    if (!columnsMatch || Number(columnsMatch) !== astNodes.length - prevMatchLength) {
        throw new NoMandatoryPartMatchError('grid-template', 'grid-template-columns');
    }

    opened['grid-template-columns'] = astNodes.slice(prevMatchLength + 1, prevMatchLength + Number(columnsMatch));
    return opened;
};

const openGridTemplateShorthandRowsColumnsSyntax = <V>(astNodes: EvaluatedAst<V>[], rowsLength: number) => {
    if (astNodes.length === rowsLength) {
        throw new NoMandatoryPartMatchError('grid-template', 'grid-template-columns');
    }

    const opened: Partial<OpenedGridTemplates<V>> = {
        'grid-template-rows': astNodes.slice(0, rowsLength),
    };
    return openGridTemplateShorthandColumns(opened, astNodes, rowsLength);
};

const openGridTemplateShorthandAreasSyntax = <V>(
    astNodes: EvaluatedAst<V>[],
    trackList: ParseTrackListWithStringsMatch
) => {
    const opened: Partial<OpenedGridTemplates<V>> = {
        'grid-template-areas': codeToEvaluatedAst(createCssValueAST(getTrackListAreasText(trackList))),
        'grid-template-rows': codeToEvaluatedAst(createCssValueAST(getTrackListRowsText(trackList))),
    };

    const trackListLength = Number(trackList.match);
    return astNodes.length > trackListLength
        ? openGridTemplateShorthandColumns(opened, astNodes, trackListLength, true)
        : opened;
};

export const openGridTemplateShorthandSyntax =
    <V>(): FullShorthandOpener<V, GridTemplates> =>
    (astNodes) => {
        const rowsMatch = matchDataType(gridTemplateRowsDataType, astNodes, 0);
        if (rowsMatch) {
            return openGridTemplateShorthandRowsColumnsSyntax(astNodes, Number(rowsMatch)) as OpenedGridTemplates<V>;
        } else {
            const trackList = parseTrackListWithStrings(astNodes[0].value, 0, astNodes);
            if (!trackList.match) {
                throw new NoMandatoryPartMatchError('grid-template', 'grid-template-rows');
            }

            return openGridTemplateShorthandAreasSyntax(astNodes, trackList) as OpenedGridTemplates<V>;
        }
    };

// grid-template
export const openGridTemplateShorthand: GetSimpleShorthandOpener<GridTemplates> = <V>() =>
    createShorthandOpener({
        prop: 'grid-template',
        singleKeywordPart: {
            prop: 'grid-template',
            dataType: gridTemplateSingleValueDataType,
            partOpener: singleKeywordShorthandOpener(
                GRID_TEMPLATE_KEYWORD_VALUE_MAP,
                true
            ) as SimpleShorthandOpenerInner<V, GridTemplates>,
        },
        parts: getGridTemplateShorthandParts(),
        shorthandOpener: openGridTemplateShorthandSyntax(),
    }) as SimpleShorthandOpener<V, GridTemplates>;

const closeGridTemplateShorthandRowsColumnsSyntax: GetShorthandCloser<GridTemplates> = <V>() =>
    createShorthandCloser<V, GridTemplates>(
        shorthandCloserTemplate<GridTemplates>`${'grid-template-rows'} / ${'grid-template-columns'}`
    );

const closeGridTemplateShorthandAreasSyntax =
    <V>(): GridTemplateShorthandCloser<V> =>
    (opened, api, detachExpression) => {
        const openedAreas = opened['grid-template-areas'] as EvaluatedAst<V>[];
        const openedRows = opened['grid-template-rows'] as EvaluatedAst<V>[];

        const areasList = [...openedAreas];
        const splicedAreasRows = openedRows.reduce((splicedAreasRows, value) => {
            const valueList = [value];
            if (trackSizePredicate(value.value)) {
                const currArea = areasList.shift();
                if (!currArea) {
                    throw new CannotCloseGridTemplateError();
                }

                valueList.unshift(currArea);
            }
            return splicedAreasRows.concat(valueList);
        }, [] as EvaluatedAst<V>[]);
        if (areasList.length > 0) {
            throw new CannotCloseGridTemplateError();
        }

        return getOpenedNode(splicedAreasRows, api, detachExpression);
    };

export const closeGridTemplateShorthand =
    <V>(): GridTemplateShorthandCloser<V> =>
    (opened, api, detachExpression) => {
        if (isOpenedInitial(opened, 'grid-template-areas', gridTemplateAreasDataType)) {
            return closeGridTemplateShorthandRowsColumnsSyntax<V>()(
                opened as SimpleOpenedShorthand<V, GridTemplates>,
                api,
                detachExpression
            );
        }

        const closedAst = closeGridTemplateShorthandAreasSyntax<V>()(opened, api, detachExpression);
        return fixAstNodesPositions(
            closedAst
                .concat({ ...valueTextNode('/'), type: '/' })
                .concat(getOpenedNode(opened['grid-template-columns'], api, detachExpression)),
            api
        );
    };
