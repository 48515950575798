import { BackgroundProps, BackgroundVisualizer } from '@wix/stylable-panel-controllers';
import React, { FC } from 'react';
import { usePanelContext } from '../configurable-panel';
import type { CpControllerCommonProps } from '../cp-controller';
import { generateVisualizerProps } from '../utils/generate-visualizer-props';

export type CpBackgroundVisualizerProps = CpControllerCommonProps;
export const CpBackgroundVisualizer: FC<CpBackgroundVisualizerProps> = ({ editTargetSelector }) => {
    const context = usePanelContext();

    return (
        <BackgroundVisualizer
            {...generateVisualizerProps<BackgroundProps>({
                astValue: true,
                disableBackgroundShorthand: true,
                context,
                editTargetSelector,
                key: 'CpBackgroundVisualizer',
            })}
        />
    );
};
