import type { GenericDeclarationMap } from '@wix/stylable-panel-drivers';
import { INITIAL_IMAGE_SOURCE, INITIAL_UNIVERSAL, type BorderStyles } from '@wix/shorthands-opener';
import { useReducer } from 'react';
import {
    SelectedBorder,
    type BorderEdges,
    type BorderProps,
    SIDES,
    DEFAULT_STYLE,
    getSideValues,
    getDefaultBorderDimensions,
    type BorderVisualizerStateAction,
    BorderVisualizerStateActionType,
} from './border-utils';
import { BorderType } from './border-visualizer-side-controls';
import type { ExtendedGlobalHost } from '../../types';

export interface BorderVisualizerState {
    borderType: BorderType;
    selected: SelectedBorder;
    editing: SelectedBorder;
    stylePicker: SelectedBorder;
    linked: boolean;
}

function sideToSelectedBorder(side?: BorderEdges): SelectedBorder {
    switch (side) {
        case 'top':
            return SelectedBorder.Top;
        case 'right':
            return SelectedBorder.Right;
        case 'bottom':
            return SelectedBorder.Bottom;
        case 'left':
            return SelectedBorder.Left;
    }
    return SelectedBorder.None;
}

export const useBorderVisualizerState = (
    declarationMapValue: GenericDeclarationMap<BorderProps>,
    isChangeFromWithin: boolean,
    panelHost: ExtendedGlobalHost | undefined
) => {
    const calcVisualizerState = (state: BorderVisualizerState): BorderVisualizerState => {
        let borderType: BorderType = BorderType.Solid;
        const imageSource = declarationMapValue['border-image-source'];
        const hasImageSource =
            imageSource !== undefined &&
            imageSource !== INITIAL_IMAGE_SOURCE &&
            imageSource !== INITIAL_UNIVERSAL &&
            declarationMapValue['border-image-slice'] &&
            SIDES.some(
                (side) =>
                    !!declarationMapValue[`border-${side}-style` as BorderStyles] &&
                    declarationMapValue[`border-${side}-style` as BorderStyles] !== DEFAULT_STYLE
            );
        if (hasImageSource) {
            if (imageSource?.includes('gradient')) {
                borderType = BorderType.Gradient;
            } else {
                borderType = BorderType.Image;
            }
        }
        let selected: SelectedBorder | undefined;
        let linked: boolean | undefined;

        if (!isChangeFromWithin) {
            const sideValuesPerSide = SIDES.map((side) => getSideValues(side, declarationMapValue, borderType, panelHost));
            if (
                sideValuesPerSide.every(
                    (sideValues) =>
                        sideValues.width === sideValuesPerSide[0].width &&
                        sideValues.style === sideValuesPerSide[0].style &&
                        sideValues.fill === sideValuesPerSide[0].fill
                )
            ) {
                selected = SelectedBorder.All;
                linked = true;
            } else {
                const {DEFAULT_WIDTHS} = getDefaultBorderDimensions(panelHost);
                const nonEmptyMarker = sideValuesPerSide.map((v) => !~DEFAULT_WIDTHS.indexOf(v.width));
                const hasNonEmptySides = nonEmptyMarker.filter((f) => f).length > 0;
                const hasSingleNonEmpty = nonEmptyMarker.filter((f) => f).length === 1;
                const nonEmptyIndex = nonEmptyMarker.indexOf(true);
                if (hasSingleNonEmpty) {
                    selected = sideToSelectedBorder(sideValuesPerSide[nonEmptyIndex].side);
                } else if (hasNonEmptySides) {
                    selected = SelectedBorder.None;
                    linked = false;
                } else {
                    selected = SelectedBorder.None;
                    linked = true;
                }
            }
        }
        return {
            ...state,
            borderType,
            selected: selected ?? state.selected,
            linked: linked ?? state.linked,
        };
    };

    const [{ borderType, selected, editing, stylePicker, linked }, dispatchState] = useReducer(
        (state: BorderVisualizerState, action: BorderVisualizerStateAction): BorderVisualizerState => {
            switch (action.type) {
                case BorderVisualizerStateActionType.ToggleLinked:
                    return {
                        ...state,
                        linked: !state.linked,
                        selected: state.linked ? SelectedBorder.None : SelectedBorder.All,
                    };
                case BorderVisualizerStateActionType.SelectBorder:
                    return { ...state, selected: action.select ?? state.selected };
                case BorderVisualizerStateActionType.HandleBlur:
                    return { ...state, editing: SelectedBorder.None };
                case BorderVisualizerStateActionType.OpenStylePicker:
                    return {
                        ...state,
                        stylePicker:
                            action.select !== undefined
                                ? state.stylePicker === SelectedBorder.None
                                    ? action.select
                                    : SelectedBorder.None
                                : state.stylePicker,
                    };
                case BorderVisualizerStateActionType.CloseStylePicker:
                    return { ...state, editing: SelectedBorder.None, stylePicker: SelectedBorder.None };
                case BorderVisualizerStateActionType.ChangeStyle:
                    return { ...state, stylePicker: SelectedBorder.None };
                case BorderVisualizerStateActionType.OpenFillPicker:
                    return { ...state, stylePicker: SelectedBorder.None, editing: SelectedBorder.All };
                case BorderVisualizerStateActionType.Calc:
                    return calcVisualizerState(state);
            }
        },
        {
            borderType: BorderType.Solid,
            selected: SelectedBorder.None,
            editing: SelectedBorder.None,
            stylePicker: SelectedBorder.None,
            linked: false,
        } as BorderVisualizerState,
        calcVisualizerState
    );

    return { borderType, selected, editing, stylePicker, linked, dispatchState };
};
