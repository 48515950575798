/* This is an automatically generated file. Do not change or commit! */

export const cssShorthandMap = {
  '--*': {
    computed: [],
    initial: 'seeProse'
  },
  '-ms-accelerator': {
    computed: [],
    initial: 'false'
  },
  '-ms-block-progression': {
    computed: [],
    initial: 'tb'
  },
  '-ms-content-zoom-chaining': {
    computed: [],
    initial: 'none'
  },
  '-ms-content-zooming': {
    computed: [],
    initial: 'zoomForTheTopLevelNoneForTheRest'
  },
  '-ms-content-zoom-limit': {
    computed: [
      '-ms-content-zoom-limit-max',
      '-ms-content-zoom-limit-min'
    ],
    initial: [
      '-ms-content-zoom-limit-max',
      '-ms-content-zoom-limit-min'
    ]
  },
  '-ms-content-zoom-limit-max': {
    computed: [],
    initial: '400%'
  },
  '-ms-content-zoom-limit-min': {
    computed: [],
    initial: '100%'
  },
  '-ms-content-zoom-snap': {
    computed: [
      '-ms-content-zoom-snap-type',
      '-ms-content-zoom-snap-points'
    ],
    initial: [
      '-ms-content-zoom-snap-type',
      '-ms-content-zoom-snap-points'
    ]
  },
  '-ms-content-zoom-snap-points': {
    computed: [],
    initial: 'snapInterval(0%, 100%)'
  },
  '-ms-content-zoom-snap-type': {
    computed: [],
    initial: 'none'
  },
  '-ms-filter': {
    computed: [],
    initial: '\'\''
  },
  '-ms-flow-from': {
    computed: [],
    initial: 'none'
  },
  '-ms-flow-into': {
    computed: [],
    initial: 'none'
  },
  '-ms-grid-columns': {
    computed: [],
    initial: 'none'
  },
  '-ms-grid-rows': {
    computed: [],
    initial: 'none'
  },
  '-ms-high-contrast-adjust': {
    computed: [],
    initial: 'auto'
  },
  '-ms-hyphenate-limit-chars': {
    computed: [],
    initial: 'auto'
  },
  '-ms-hyphenate-limit-lines': {
    computed: [],
    initial: 'no-limit'
  },
  '-ms-hyphenate-limit-zone': {
    computed: [],
    initial: '0'
  },
  '-ms-ime-align': {
    computed: [],
    initial: 'auto'
  },
  '-ms-overflow-style': {
    computed: [],
    initial: 'auto'
  },
  '-ms-scrollbar-3dlight-color': {
    computed: [],
    initial: 'dependsOnUserAgent'
  },
  '-ms-scrollbar-arrow-color': {
    computed: [],
    initial: 'ButtonText'
  },
  '-ms-scrollbar-base-color': {
    computed: [],
    initial: 'dependsOnUserAgent'
  },
  '-ms-scrollbar-darkshadow-color': {
    computed: [],
    initial: 'ThreeDDarkShadow'
  },
  '-ms-scrollbar-face-color': {
    computed: [],
    initial: 'ThreeDFace'
  },
  '-ms-scrollbar-highlight-color': {
    computed: [],
    initial: 'ThreeDHighlight'
  },
  '-ms-scrollbar-shadow-color': {
    computed: [],
    initial: 'ThreeDDarkShadow'
  },
  '-ms-scrollbar-track-color': {
    computed: [],
    initial: 'Scrollbar'
  },
  '-ms-scroll-chaining': {
    computed: [],
    initial: 'chained'
  },
  '-ms-scroll-limit': {
    computed: [
      '-ms-scroll-limit-x-min',
      '-ms-scroll-limit-y-min',
      '-ms-scroll-limit-x-max',
      '-ms-scroll-limit-y-max'
    ],
    initial: [
      '-ms-scroll-limit-x-min',
      '-ms-scroll-limit-y-min',
      '-ms-scroll-limit-x-max',
      '-ms-scroll-limit-y-max'
    ]
  },
  '-ms-scroll-limit-x-max': {
    computed: [],
    initial: 'auto'
  },
  '-ms-scroll-limit-x-min': {
    computed: [],
    initial: '0'
  },
  '-ms-scroll-limit-y-max': {
    computed: [],
    initial: 'auto'
  },
  '-ms-scroll-limit-y-min': {
    computed: [],
    initial: '0'
  },
  '-ms-scroll-rails': {
    computed: [],
    initial: 'railed'
  },
  '-ms-scroll-snap-points-x': {
    computed: [],
    initial: 'snapInterval(0px, 100%)'
  },
  '-ms-scroll-snap-points-y': {
    computed: [],
    initial: 'snapInterval(0px, 100%)'
  },
  '-ms-scroll-snap-type': {
    computed: [],
    initial: 'none'
  },
  '-ms-scroll-snap-x': {
    computed: [
      '-ms-scroll-snap-type',
      '-ms-scroll-snap-points-x'
    ],
    initial: [
      '-ms-scroll-snap-type',
      '-ms-scroll-snap-points-x'
    ]
  },
  '-ms-scroll-snap-y': {
    computed: [
      '-ms-scroll-snap-type',
      '-ms-scroll-snap-points-y'
    ],
    initial: [
      '-ms-scroll-snap-type',
      '-ms-scroll-snap-points-y'
    ]
  },
  '-ms-scroll-translation': {
    computed: [],
    initial: 'none'
  },
  '-ms-text-autospace': {
    computed: [],
    initial: 'none'
  },
  '-ms-touch-select': {
    computed: [],
    initial: 'grippers'
  },
  '-ms-user-select': {
    computed: [],
    initial: 'text'
  },
  '-ms-wrap-flow': {
    computed: [],
    initial: 'auto'
  },
  '-ms-wrap-margin': {
    computed: [],
    initial: '0'
  },
  '-ms-wrap-through': {
    computed: [],
    initial: 'wrap'
  },
  '-moz-appearance': {
    computed: [],
    initial: 'noneButOverriddenInUserAgentCSS'
  },
  '-moz-binding': {
    computed: [],
    initial: 'none'
  },
  '-moz-border-bottom-colors': {
    computed: [],
    initial: 'none'
  },
  '-moz-border-left-colors': {
    computed: [],
    initial: 'none'
  },
  '-moz-border-right-colors': {
    computed: [],
    initial: 'none'
  },
  '-moz-border-top-colors': {
    computed: [],
    initial: 'none'
  },
  '-moz-context-properties': {
    computed: [],
    initial: 'none'
  },
  '-moz-float-edge': {
    computed: [],
    initial: 'content-box'
  },
  '-moz-force-broken-image-icon': {
    computed: [],
    initial: '0'
  },
  '-moz-image-region': {
    computed: [],
    initial: 'auto'
  },
  '-moz-orient': {
    computed: [],
    initial: 'inline'
  },
  '-moz-outline-radius': {
    computed: [
      '-moz-outline-radius-topleft',
      '-moz-outline-radius-topright',
      '-moz-outline-radius-bottomright',
      '-moz-outline-radius-bottomleft'
    ],
    initial: [
      '-moz-outline-radius-topleft',
      '-moz-outline-radius-topright',
      '-moz-outline-radius-bottomright',
      '-moz-outline-radius-bottomleft'
    ]
  },
  '-moz-outline-radius-bottomleft': {
    computed: [],
    initial: '0'
  },
  '-moz-outline-radius-bottomright': {
    computed: [],
    initial: '0'
  },
  '-moz-outline-radius-topleft': {
    computed: [],
    initial: '0'
  },
  '-moz-outline-radius-topright': {
    computed: [],
    initial: '0'
  },
  '-moz-stack-sizing': {
    computed: [],
    initial: 'stretch-to-fit'
  },
  '-moz-text-blink': {
    computed: [],
    initial: 'none'
  },
  '-moz-user-focus': {
    computed: [],
    initial: 'none'
  },
  '-moz-user-input': {
    computed: [],
    initial: 'auto'
  },
  '-moz-user-modify': {
    computed: [],
    initial: 'read-only'
  },
  '-moz-window-dragging': {
    computed: [],
    initial: 'drag'
  },
  '-moz-window-shadow': {
    computed: [],
    initial: 'default'
  },
  '-webkit-appearance': {
    computed: [],
    initial: 'noneButOverriddenInUserAgentCSS'
  },
  '-webkit-border-before': {
    computed: [
      'border-width',
      'border-style',
      'color'
    ],
    initial: [
      'border-width',
      'border-style',
      'color'
    ]
  },
  '-webkit-border-before-color': {
    computed: [],
    initial: 'currentcolor'
  },
  '-webkit-border-before-style': {
    computed: [],
    initial: 'none'
  },
  '-webkit-border-before-width': {
    computed: [],
    initial: 'medium'
  },
  '-webkit-box-reflect': {
    computed: [],
    initial: 'none'
  },
  '-webkit-line-clamp': {
    computed: [],
    initial: 'none'
  },
  '-webkit-mask': {
    computed: [
      '-webkit-mask-image',
      '-webkit-mask-repeat',
      '-webkit-mask-attachment',
      '-webkit-mask-position',
      '-webkit-mask-origin',
      '-webkit-mask-clip'
    ],
    initial: [
      '-webkit-mask-image',
      '-webkit-mask-repeat',
      '-webkit-mask-attachment',
      '-webkit-mask-position',
      '-webkit-mask-origin',
      '-webkit-mask-clip'
    ]
  },
  '-webkit-mask-attachment': {
    computed: [],
    initial: 'scroll'
  },
  '-webkit-mask-clip': {
    computed: [],
    initial: 'border'
  },
  '-webkit-mask-composite': {
    computed: [],
    initial: 'source-over'
  },
  '-webkit-mask-image': {
    computed: [],
    initial: 'none'
  },
  '-webkit-mask-origin': {
    computed: [],
    initial: 'padding'
  },
  '-webkit-mask-position': {
    computed: [],
    initial: '0% 0%'
  },
  '-webkit-mask-position-x': {
    computed: [],
    initial: '0%'
  },
  '-webkit-mask-position-y': {
    computed: [],
    initial: '0%'
  },
  '-webkit-mask-repeat': {
    computed: [],
    initial: 'repeat'
  },
  '-webkit-mask-repeat-x': {
    computed: [],
    initial: 'repeat'
  },
  '-webkit-mask-repeat-y': {
    computed: [],
    initial: 'repeat'
  },
  '-webkit-mask-size': {
    computed: [],
    initial: 'auto auto'
  },
  '-webkit-overflow-scrolling': {
    computed: [],
    initial: 'auto'
  },
  '-webkit-tap-highlight-color': {
    computed: [],
    initial: 'black'
  },
  '-webkit-text-fill-color': {
    computed: [],
    initial: 'currentcolor'
  },
  '-webkit-text-stroke': {
    computed: [
      '-webkit-text-stroke-width',
      '-webkit-text-stroke-color'
    ],
    initial: [
      '-webkit-text-stroke-width',
      '-webkit-text-stroke-color'
    ]
  },
  '-webkit-text-stroke-color': {
    computed: [],
    initial: 'currentcolor'
  },
  '-webkit-text-stroke-width': {
    computed: [],
    initial: '0'
  },
  '-webkit-touch-callout': {
    computed: [],
    initial: 'default'
  },
  '-webkit-user-modify': {
    computed: [],
    initial: 'read-only'
  },
  'accent-color': {
    computed: [],
    initial: 'auto'
  },
  'align-content': {
    computed: [],
    initial: 'normal'
  },
  'align-items': {
    computed: [],
    initial: 'normal'
  },
  'align-self': {
    computed: [],
    initial: 'auto'
  },
  'align-tracks': {
    computed: [],
    initial: 'normal'
  },
  'all': {
    computed: [],
    initial: 'noPracticalInitialValue'
  },
  'animation': {
    computed: [
      'animation-name',
      'animation-duration',
      'animation-timing-function',
      'animation-delay',
      'animation-direction',
      'animation-iteration-count',
      'animation-fill-mode',
      'animation-play-state',
      'animation-timeline'
    ],
    initial: [
      'animation-name',
      'animation-duration',
      'animation-timing-function',
      'animation-delay',
      'animation-iteration-count',
      'animation-direction',
      'animation-fill-mode',
      'animation-play-state',
      'animation-timeline'
    ]
  },
  'animation-composition': {
    computed: [],
    initial: 'replace'
  },
  'animation-delay': {
    computed: [],
    initial: '0s'
  },
  'animation-direction': {
    computed: [],
    initial: 'normal'
  },
  'animation-duration': {
    computed: [],
    initial: '0s'
  },
  'animation-fill-mode': {
    computed: [],
    initial: 'none'
  },
  'animation-iteration-count': {
    computed: [],
    initial: '1'
  },
  'animation-name': {
    computed: [],
    initial: 'none'
  },
  'animation-play-state': {
    computed: [],
    initial: 'running'
  },
  'animation-timing-function': {
    computed: [],
    initial: 'ease'
  },
  'animation-timeline': {
    computed: [],
    initial: 'auto'
  },
  'appearance': {
    computed: [],
    initial: 'none'
  },
  'aspect-ratio': {
    computed: [],
    initial: 'auto'
  },
  'azimuth': {
    computed: [],
    initial: 'center'
  },
  'backdrop-filter': {
    computed: [],
    initial: 'none'
  },
  'backface-visibility': {
    computed: [],
    initial: 'visible'
  },
  'background': {
    computed: [
      'background-image',
      'background-position',
      'background-size',
      'background-repeat',
      'background-origin',
      'background-clip',
      'background-attachment',
      'background-color'
    ],
    initial: [
      'background-image',
      'background-position',
      'background-size',
      'background-repeat',
      'background-origin',
      'background-clip',
      'background-attachment',
      'background-color'
    ]
  },
  'background-attachment': {
    computed: [],
    initial: 'scroll'
  },
  'background-blend-mode': {
    computed: [],
    initial: 'normal'
  },
  'background-clip': {
    computed: [],
    initial: 'border-box'
  },
  'background-color': {
    computed: [],
    initial: 'transparent'
  },
  'background-image': {
    computed: [],
    initial: 'none'
  },
  'background-origin': {
    computed: [],
    initial: 'padding-box'
  },
  'background-position': {
    computed: [
      'background-position-x',
      'background-position-y'
    ],
    initial: '0% 0%'
  },
  'background-position-x': {
    computed: [],
    initial: '0%'
  },
  'background-position-y': {
    computed: [],
    initial: '0%'
  },
  'background-repeat': {
    computed: [],
    initial: 'repeat'
  },
  'background-size': {
    computed: [],
    initial: 'auto auto'
  },
  'block-overflow': {
    computed: [],
    initial: 'clip'
  },
  'block-size': {
    computed: [],
    initial: 'auto'
  },
  'border': {
    computed: [
      'border-width',
      'border-style',
      'border-color'
    ],
    initial: [
      'border-width',
      'border-style',
      'border-color'
    ]
  },
  'border-block': {
    computed: [
      'border-block-width',
      'border-block-style',
      'border-block-color'
    ],
    initial: [
      'border-block-width',
      'border-block-style',
      'border-block-color'
    ]
  },
  'border-block-color': {
    computed: [],
    initial: 'currentcolor'
  },
  'border-block-style': {
    computed: [],
    initial: 'none'
  },
  'border-block-width': {
    computed: [],
    initial: 'medium'
  },
  'border-block-end': {
    computed: [
      'border-top-width',
      'border-top-style',
      'border-top-color'
    ],
    initial: [
      'border-top-width',
      'border-top-style',
      'border-top-color'
    ]
  },
  'border-block-end-color': {
    computed: [],
    initial: 'currentcolor'
  },
  'border-block-end-style': {
    computed: [],
    initial: 'none'
  },
  'border-block-end-width': {
    computed: [],
    initial: 'medium'
  },
  'border-block-start': {
    computed: [
      'border-width',
      'border-style',
      'border-block-start-color'
    ],
    initial: [
      'border-width',
      'border-style',
      'color'
    ]
  },
  'border-block-start-color': {
    computed: [],
    initial: 'currentcolor'
  },
  'border-block-start-style': {
    computed: [],
    initial: 'none'
  },
  'border-block-start-width': {
    computed: [],
    initial: 'medium'
  },
  'border-bottom': {
    computed: [
      'border-bottom-width',
      'border-bottom-style',
      'border-bottom-color'
    ],
    initial: [
      'border-bottom-width',
      'border-bottom-style',
      'border-bottom-color'
    ]
  },
  'border-bottom-color': {
    computed: [],
    initial: 'currentcolor'
  },
  'border-bottom-left-radius': {
    computed: [],
    initial: '0'
  },
  'border-bottom-right-radius': {
    computed: [],
    initial: '0'
  },
  'border-bottom-style': {
    computed: [],
    initial: 'none'
  },
  'border-bottom-width': {
    computed: [],
    initial: 'medium'
  },
  'border-collapse': {
    computed: [],
    initial: 'separate'
  },
  'border-color': {
    computed: [
      'border-bottom-color',
      'border-left-color',
      'border-right-color',
      'border-top-color'
    ],
    initial: [
      'border-top-color',
      'border-right-color',
      'border-bottom-color',
      'border-left-color'
    ]
  },
  'border-end-end-radius': {
    computed: [],
    initial: '0'
  },
  'border-end-start-radius': {
    computed: [],
    initial: '0'
  },
  'border-image': {
    computed: [
      'border-image-outset',
      'border-image-repeat',
      'border-image-slice',
      'border-image-source',
      'border-image-width'
    ],
    initial: [
      'border-image-source',
      'border-image-slice',
      'border-image-width',
      'border-image-outset',
      'border-image-repeat'
    ]
  },
  'border-image-outset': {
    computed: [],
    initial: '0'
  },
  'border-image-repeat': {
    computed: [],
    initial: 'stretch'
  },
  'border-image-slice': {
    computed: [],
    initial: '100%'
  },
  'border-image-source': {
    computed: [],
    initial: 'none'
  },
  'border-image-width': {
    computed: [],
    initial: '1'
  },
  'border-inline': {
    computed: [
      'border-inline-width',
      'border-inline-style',
      'border-inline-color'
    ],
    initial: [
      'border-inline-width',
      'border-inline-style',
      'border-inline-color'
    ]
  },
  'border-inline-end': {
    computed: [
      'border-width',
      'border-style',
      'border-inline-end-color'
    ],
    initial: [
      'border-width',
      'border-style',
      'color'
    ]
  },
  'border-inline-color': {
    computed: [],
    initial: 'currentcolor'
  },
  'border-inline-style': {
    computed: [],
    initial: 'none'
  },
  'border-inline-width': {
    computed: [],
    initial: 'medium'
  },
  'border-inline-end-color': {
    computed: [],
    initial: 'currentcolor'
  },
  'border-inline-end-style': {
    computed: [],
    initial: 'none'
  },
  'border-inline-end-width': {
    computed: [],
    initial: 'medium'
  },
  'border-inline-start': {
    computed: [
      'border-width',
      'border-style',
      'border-inline-start-color'
    ],
    initial: [
      'border-width',
      'border-style',
      'color'
    ]
  },
  'border-inline-start-color': {
    computed: [],
    initial: 'currentcolor'
  },
  'border-inline-start-style': {
    computed: [],
    initial: 'none'
  },
  'border-inline-start-width': {
    computed: [],
    initial: 'medium'
  },
  'border-left': {
    computed: [
      'border-left-width',
      'border-left-style',
      'border-left-color'
    ],
    initial: [
      'border-left-width',
      'border-left-style',
      'border-left-color'
    ]
  },
  'border-left-color': {
    computed: [],
    initial: 'currentcolor'
  },
  'border-left-style': {
    computed: [],
    initial: 'none'
  },
  'border-left-width': {
    computed: [],
    initial: 'medium'
  },
  'border-radius': {
    computed: [
      'border-bottom-left-radius',
      'border-bottom-right-radius',
      'border-top-left-radius',
      'border-top-right-radius'
    ],
    initial: [
      'border-top-left-radius',
      'border-top-right-radius',
      'border-bottom-right-radius',
      'border-bottom-left-radius'
    ]
  },
  'border-right': {
    computed: [
      'border-right-width',
      'border-right-style',
      'border-right-color'
    ],
    initial: [
      'border-right-width',
      'border-right-style',
      'border-right-color'
    ]
  },
  'border-right-color': {
    computed: [],
    initial: 'currentcolor'
  },
  'border-right-style': {
    computed: [],
    initial: 'none'
  },
  'border-right-width': {
    computed: [],
    initial: 'medium'
  },
  'border-spacing': {
    computed: [],
    initial: '0'
  },
  'border-start-end-radius': {
    computed: [],
    initial: '0'
  },
  'border-start-start-radius': {
    computed: [],
    initial: '0'
  },
  'border-style': {
    computed: [
      'border-bottom-style',
      'border-left-style',
      'border-right-style',
      'border-top-style'
    ],
    initial: [
      'border-top-style',
      'border-right-style',
      'border-bottom-style',
      'border-left-style'
    ]
  },
  'border-top': {
    computed: [
      'border-top-width',
      'border-top-style',
      'border-top-color'
    ],
    initial: [
      'border-top-width',
      'border-top-style',
      'border-top-color'
    ]
  },
  'border-top-color': {
    computed: [],
    initial: 'currentcolor'
  },
  'border-top-left-radius': {
    computed: [],
    initial: '0'
  },
  'border-top-right-radius': {
    computed: [],
    initial: '0'
  },
  'border-top-style': {
    computed: [],
    initial: 'none'
  },
  'border-top-width': {
    computed: [],
    initial: 'medium'
  },
  'border-width': {
    computed: [
      'border-bottom-width',
      'border-left-width',
      'border-right-width',
      'border-top-width'
    ],
    initial: [
      'border-top-width',
      'border-right-width',
      'border-bottom-width',
      'border-left-width'
    ]
  },
  'bottom': {
    computed: [],
    initial: 'auto'
  },
  'box-align': {
    computed: [],
    initial: 'stretch'
  },
  'box-decoration-break': {
    computed: [],
    initial: 'slice'
  },
  'box-direction': {
    computed: [],
    initial: 'normal'
  },
  'box-flex': {
    computed: [],
    initial: '0'
  },
  'box-flex-group': {
    computed: [],
    initial: '1'
  },
  'box-lines': {
    computed: [],
    initial: 'single'
  },
  'box-ordinal-group': {
    computed: [],
    initial: '1'
  },
  'box-orient': {
    computed: [],
    initial: 'inlineAxisHorizontalInXUL'
  },
  'box-pack': {
    computed: [],
    initial: 'start'
  },
  'box-shadow': {
    computed: [],
    initial: 'none'
  },
  'box-sizing': {
    computed: [],
    initial: 'content-box'
  },
  'break-after': {
    computed: [],
    initial: 'auto'
  },
  'break-before': {
    computed: [],
    initial: 'auto'
  },
  'break-inside': {
    computed: [],
    initial: 'auto'
  },
  'caption-side': {
    computed: [],
    initial: 'top'
  },
  'caret': {
    computed: [
      'caret-color',
      'caret-shape'
    ],
    initial: [
      'caret-color',
      'caret-shape'
    ]
  },
  'caret-color': {
    computed: [],
    initial: 'auto'
  },
  'caret-shape': {
    computed: [],
    initial: 'auto'
  },
  'clear': {
    computed: [],
    initial: 'none'
  },
  'clip': {
    computed: [],
    initial: 'auto'
  },
  'clip-path': {
    computed: [],
    initial: 'none'
  },
  'color': {
    computed: [],
    initial: 'canvastext'
  },
  'color-scheme': {
    computed: [],
    initial: 'normal'
  },
  'column-count': {
    computed: [],
    initial: 'auto'
  },
  'column-fill': {
    computed: [],
    initial: 'balance'
  },
  'column-gap': {
    computed: [],
    initial: 'normal'
  },
  'column-rule': {
    computed: [
      'column-rule-color',
      'column-rule-style',
      'column-rule-width'
    ],
    initial: [
      'column-rule-width',
      'column-rule-style',
      'column-rule-color'
    ]
  },
  'column-rule-color': {
    computed: [],
    initial: 'currentcolor'
  },
  'column-rule-style': {
    computed: [],
    initial: 'none'
  },
  'column-rule-width': {
    computed: [],
    initial: 'medium'
  },
  'column-span': {
    computed: [],
    initial: 'none'
  },
  'column-width': {
    computed: [],
    initial: 'auto'
  },
  'columns': {
    computed: [
      'column-width',
      'column-count'
    ],
    initial: [
      'column-width',
      'column-count'
    ]
  },
  'contain': {
    computed: [],
    initial: 'none'
  },
  'contain-intrinsic-size': {
    computed: [
      'contain-intrinsic-width',
      'contain-intrinsic-height'
    ],
    initial: [
      'contain-intrinsic-width',
      'contain-intrinsic-height'
    ]
  },
  'contain-intrinsic-block-size': {
    computed: [],
    initial: 'none'
  },
  'contain-intrinsic-height': {
    computed: [],
    initial: 'none'
  },
  'contain-intrinsic-inline-size': {
    computed: [],
    initial: 'none'
  },
  'contain-intrinsic-width': {
    computed: [],
    initial: 'none'
  },
  'container': {
    computed: [
      'container-name',
      'container-type'
    ],
    initial: [
      'container-name',
      'container-type'
    ]
  },
  'container-name': {
    computed: [],
    initial: 'none'
  },
  'container-type': {
    computed: [],
    initial: 'normal'
  },
  'content': {
    computed: [],
    initial: 'normal'
  },
  'content-visibility': {
    computed: [],
    initial: 'visible'
  },
  'counter-increment': {
    computed: [],
    initial: 'none'
  },
  'counter-reset': {
    computed: [],
    initial: 'none'
  },
  'counter-set': {
    computed: [],
    initial: 'none'
  },
  'cursor': {
    computed: [],
    initial: 'auto'
  },
  'direction': {
    computed: [],
    initial: 'ltr'
  },
  'display': {
    computed: [],
    initial: 'inline'
  },
  'empty-cells': {
    computed: [],
    initial: 'show'
  },
  'filter': {
    computed: [],
    initial: 'none'
  },
  'flex': {
    computed: [
      'flex-grow',
      'flex-shrink',
      'flex-basis'
    ],
    initial: [
      'flex-grow',
      'flex-shrink',
      'flex-basis'
    ]
  },
  'flex-basis': {
    computed: [],
    initial: 'auto'
  },
  'flex-direction': {
    computed: [],
    initial: 'row'
  },
  'flex-flow': {
    computed: [
      'flex-direction',
      'flex-wrap'
    ],
    initial: [
      'flex-direction',
      'flex-wrap'
    ]
  },
  'flex-grow': {
    computed: [],
    initial: '0'
  },
  'flex-shrink': {
    computed: [],
    initial: '1'
  },
  'flex-wrap': {
    computed: [],
    initial: 'nowrap'
  },
  'float': {
    computed: [],
    initial: 'none'
  },
  'font': {
    computed: [
      'font-style',
      'font-variant',
      'font-weight',
      'font-stretch',
      'font-size',
      'line-height',
      'font-family'
    ],
    initial: [
      'font-style',
      'font-variant',
      'font-weight',
      'font-stretch',
      'font-size',
      'line-height',
      'font-family'
    ]
  },
  'font-family': {
    computed: [],
    initial: 'dependsOnUserAgent'
  },
  'font-feature-settings': {
    computed: [],
    initial: 'normal'
  },
  'font-kerning': {
    computed: [],
    initial: 'auto'
  },
  'font-language-override': {
    computed: [],
    initial: 'normal'
  },
  'font-optical-sizing': {
    computed: [],
    initial: 'auto'
  },
  'font-palette': {
    computed: [],
    initial: 'normal'
  },
  'font-variation-settings': {
    computed: [],
    initial: 'normal'
  },
  'font-size': {
    computed: [],
    initial: 'medium'
  },
  'font-size-adjust': {
    computed: [],
    initial: 'none'
  },
  'font-smooth': {
    computed: [],
    initial: 'auto'
  },
  'font-stretch': {
    computed: [],
    initial: 'normal'
  },
  'font-style': {
    computed: [],
    initial: 'normal'
  },
  'font-synthesis': {
    computed: [],
    initial: 'weight style'
  },
  'font-variant': {
    computed: [],
    initial: 'normal'
  },
  'font-variant-alternates': {
    computed: [],
    initial: 'normal'
  },
  'font-variant-caps': {
    computed: [],
    initial: 'normal'
  },
  'font-variant-east-asian': {
    computed: [],
    initial: 'normal'
  },
  'font-variant-emoji': {
    computed: [],
    initial: 'normal'
  },
  'font-variant-ligatures': {
    computed: [],
    initial: 'normal'
  },
  'font-variant-numeric': {
    computed: [],
    initial: 'normal'
  },
  'font-variant-position': {
    computed: [],
    initial: 'normal'
  },
  'font-weight': {
    computed: [],
    initial: 'normal'
  },
  'forced-color-adjust': {
    computed: [],
    initial: 'auto'
  },
  'gap': {
    computed: [
      'row-gap',
      'column-gap'
    ],
    initial: [
      'row-gap',
      'column-gap'
    ]
  },
  'grid': {
    computed: [
      'grid-template-rows',
      'grid-template-columns',
      'grid-template-areas',
      'grid-auto-rows',
      'grid-auto-columns',
      'grid-auto-flow',
      'grid-column-gap',
      'grid-row-gap',
      'column-gap',
      'row-gap'
    ],
    initial: [
      'grid-template-rows',
      'grid-template-columns',
      'grid-template-areas',
      'grid-auto-rows',
      'grid-auto-columns',
      'grid-auto-flow',
      'grid-column-gap',
      'grid-row-gap',
      'column-gap',
      'row-gap'
    ]
  },
  'grid-area': {
    computed: [
      'grid-row-start',
      'grid-column-start',
      'grid-row-end',
      'grid-column-end'
    ],
    initial: [
      'grid-row-start',
      'grid-column-start',
      'grid-row-end',
      'grid-column-end'
    ]
  },
  'grid-auto-columns': {
    computed: [],
    initial: 'auto'
  },
  'grid-auto-flow': {
    computed: [],
    initial: 'row'
  },
  'grid-auto-rows': {
    computed: [],
    initial: 'auto'
  },
  'grid-column': {
    computed: [
      'grid-column-start',
      'grid-column-end'
    ],
    initial: [
      'grid-column-start',
      'grid-column-end'
    ]
  },
  'grid-column-end': {
    computed: [],
    initial: 'auto'
  },
  'grid-column-gap': {
    computed: [],
    initial: '0'
  },
  'grid-column-start': {
    computed: [],
    initial: 'auto'
  },
  'grid-gap': {
    computed: [
      'grid-row-gap',
      'grid-column-gap'
    ],
    initial: [
      'grid-row-gap',
      'grid-column-gap'
    ]
  },
  'grid-row': {
    computed: [
      'grid-row-start',
      'grid-row-end'
    ],
    initial: [
      'grid-row-start',
      'grid-row-end'
    ]
  },
  'grid-row-end': {
    computed: [],
    initial: 'auto'
  },
  'grid-row-gap': {
    computed: [],
    initial: '0'
  },
  'grid-row-start': {
    computed: [],
    initial: 'auto'
  },
  'grid-template': {
    computed: [
      'grid-template-columns',
      'grid-template-rows',
      'grid-template-areas'
    ],
    initial: [
      'grid-template-columns',
      'grid-template-rows',
      'grid-template-areas'
    ]
  },
  'grid-template-areas': {
    computed: [],
    initial: 'none'
  },
  'grid-template-columns': {
    computed: [],
    initial: 'none'
  },
  'grid-template-rows': {
    computed: [],
    initial: 'none'
  },
  'hanging-punctuation': {
    computed: [],
    initial: 'none'
  },
  'height': {
    computed: [],
    initial: 'auto'
  },
  'hyphenate-character': {
    computed: [],
    initial: 'auto'
  },
  'hyphenate-limit-chars': {
    computed: [],
    initial: 'auto'
  },
  'hyphens': {
    computed: [],
    initial: 'manual'
  },
  'image-orientation': {
    computed: [],
    initial: 'from-image'
  },
  'image-rendering': {
    computed: [],
    initial: 'auto'
  },
  'image-resolution': {
    computed: [],
    initial: '1dppx'
  },
  'ime-mode': {
    computed: [],
    initial: 'auto'
  },
  'initial-letter': {
    computed: [],
    initial: 'normal'
  },
  'initial-letter-align': {
    computed: [],
    initial: 'auto'
  },
  'inline-size': {
    computed: [],
    initial: 'auto'
  },
  'input-security': {
    computed: [],
    initial: 'auto'
  },
  'inset': {
    computed: [
      'top',
      'bottom',
      'left',
      'right'
    ],
    initial: [
      'top',
      'bottom',
      'left',
      'right'
    ]
  },
  'inset-block': {
    computed: [
      'inset-block-start',
      'inset-block-end'
    ],
    initial: [
      'inset-block-start',
      'inset-block-end'
    ]
  },
  'inset-block-end': {
    computed: [],
    initial: 'auto'
  },
  'inset-block-start': {
    computed: [],
    initial: 'auto'
  },
  'inset-inline': {
    computed: [
      'inset-inline-start',
      'inset-inline-end'
    ],
    initial: [
      'inset-inline-start',
      'inset-inline-end'
    ]
  },
  'inset-inline-end': {
    computed: [],
    initial: 'auto'
  },
  'inset-inline-start': {
    computed: [],
    initial: 'auto'
  },
  'isolation': {
    computed: [],
    initial: 'auto'
  },
  'justify-content': {
    computed: [],
    initial: 'normal'
  },
  'justify-items': {
    computed: [],
    initial: 'legacy'
  },
  'justify-self': {
    computed: [],
    initial: 'auto'
  },
  'justify-tracks': {
    computed: [],
    initial: 'normal'
  },
  'left': {
    computed: [],
    initial: 'auto'
  },
  'letter-spacing': {
    computed: [],
    initial: 'normal'
  },
  'line-break': {
    computed: [],
    initial: 'auto'
  },
  'line-clamp': {
    computed: [],
    initial: 'none'
  },
  'line-height': {
    computed: [],
    initial: 'normal'
  },
  'line-height-step': {
    computed: [],
    initial: '0'
  },
  'list-style': {
    computed: [
      'list-style-image',
      'list-style-position',
      'list-style-type'
    ],
    initial: [
      'list-style-type',
      'list-style-position',
      'list-style-image'
    ]
  },
  'list-style-image': {
    computed: [],
    initial: 'none'
  },
  'list-style-position': {
    computed: [],
    initial: 'outside'
  },
  'list-style-type': {
    computed: [],
    initial: 'disc'
  },
  'margin': {
    computed: [
      'margin-bottom',
      'margin-left',
      'margin-right',
      'margin-top'
    ],
    initial: [
      'margin-bottom',
      'margin-left',
      'margin-right',
      'margin-top'
    ]
  },
  'margin-block': {
    computed: [
      'margin-block-start',
      'margin-block-end'
    ],
    initial: [
      'margin-block-start',
      'margin-block-end'
    ]
  },
  'margin-block-end': {
    computed: [],
    initial: '0'
  },
  'margin-block-start': {
    computed: [],
    initial: '0'
  },
  'margin-bottom': {
    computed: [],
    initial: '0'
  },
  'margin-inline': {
    computed: [
      'margin-inline-start',
      'margin-inline-end'
    ],
    initial: [
      'margin-inline-start',
      'margin-inline-end'
    ]
  },
  'margin-inline-end': {
    computed: [],
    initial: '0'
  },
  'margin-inline-start': {
    computed: [],
    initial: '0'
  },
  'margin-left': {
    computed: [],
    initial: '0'
  },
  'margin-right': {
    computed: [],
    initial: '0'
  },
  'margin-top': {
    computed: [],
    initial: '0'
  },
  'margin-trim': {
    computed: [],
    initial: 'none'
  },
  'mask': {
    computed: [
      'mask-image',
      'mask-mode',
      'mask-repeat',
      'mask-position',
      'mask-clip',
      'mask-origin',
      'mask-size',
      'mask-composite'
    ],
    initial: [
      'mask-image',
      'mask-mode',
      'mask-repeat',
      'mask-position',
      'mask-clip',
      'mask-origin',
      'mask-size',
      'mask-composite'
    ]
  },
  'mask-border': {
    computed: [
      'mask-border-mode',
      'mask-border-outset',
      'mask-border-repeat',
      'mask-border-slice',
      'mask-border-source',
      'mask-border-width'
    ],
    initial: [
      'mask-border-mode',
      'mask-border-outset',
      'mask-border-repeat',
      'mask-border-slice',
      'mask-border-source',
      'mask-border-width'
    ]
  },
  'mask-border-mode': {
    computed: [],
    initial: 'alpha'
  },
  'mask-border-outset': {
    computed: [],
    initial: '0'
  },
  'mask-border-repeat': {
    computed: [],
    initial: 'stretch'
  },
  'mask-border-slice': {
    computed: [],
    initial: '0'
  },
  'mask-border-source': {
    computed: [],
    initial: 'none'
  },
  'mask-border-width': {
    computed: [],
    initial: 'auto'
  },
  'mask-clip': {
    computed: [],
    initial: 'border-box'
  },
  'mask-composite': {
    computed: [],
    initial: 'add'
  },
  'mask-image': {
    computed: [],
    initial: 'none'
  },
  'mask-mode': {
    computed: [],
    initial: 'match-source'
  },
  'mask-origin': {
    computed: [],
    initial: 'border-box'
  },
  'mask-position': {
    computed: [],
    initial: 'center'
  },
  'mask-repeat': {
    computed: [],
    initial: 'repeat'
  },
  'mask-size': {
    computed: [],
    initial: 'auto'
  },
  'mask-type': {
    computed: [],
    initial: 'luminance'
  },
  'masonry-auto-flow': {
    computed: [],
    initial: 'pack'
  },
  'math-depth': {
    computed: [],
    initial: '0'
  },
  'math-shift': {
    computed: [],
    initial: 'normal'
  },
  'math-style': {
    computed: [],
    initial: 'normal'
  },
  'max-block-size': {
    computed: [],
    initial: 'none'
  },
  'max-height': {
    computed: [],
    initial: 'none'
  },
  'max-inline-size': {
    computed: [],
    initial: 'none'
  },
  'max-lines': {
    computed: [],
    initial: 'none'
  },
  'max-width': {
    computed: [],
    initial: 'none'
  },
  'min-block-size': {
    computed: [],
    initial: '0'
  },
  'min-height': {
    computed: [],
    initial: 'auto'
  },
  'min-inline-size': {
    computed: [],
    initial: '0'
  },
  'min-width': {
    computed: [],
    initial: 'auto'
  },
  'mix-blend-mode': {
    computed: [],
    initial: 'normal'
  },
  'object-fit': {
    computed: [],
    initial: 'fill'
  },
  'object-position': {
    computed: [],
    initial: '50% 50%'
  },
  'offset': {
    computed: [
      'offset-position',
      'offset-path',
      'offset-distance',
      'offset-anchor',
      'offset-rotate'
    ],
    initial: [
      'offset-position',
      'offset-path',
      'offset-distance',
      'offset-anchor',
      'offset-rotate'
    ]
  },
  'offset-anchor': {
    computed: [],
    initial: 'auto'
  },
  'offset-distance': {
    computed: [],
    initial: '0'
  },
  'offset-path': {
    computed: [],
    initial: 'none'
  },
  'offset-position': {
    computed: [],
    initial: 'auto'
  },
  'offset-rotate': {
    computed: [],
    initial: 'auto'
  },
  'opacity': {
    computed: [],
    initial: '1'
  },
  'order': {
    computed: [],
    initial: '0'
  },
  'orphans': {
    computed: [],
    initial: '2'
  },
  'outline': {
    computed: [
      'outline-color',
      'outline-width',
      'outline-style'
    ],
    initial: [
      'outline-color',
      'outline-style',
      'outline-width'
    ]
  },
  'outline-color': {
    computed: [],
    initial: 'invertOrCurrentColor'
  },
  'outline-offset': {
    computed: [],
    initial: '0'
  },
  'outline-style': {
    computed: [],
    initial: 'none'
  },
  'outline-width': {
    computed: [],
    initial: 'medium'
  },
  'overflow': {
    computed: [
      'overflow-x',
      'overflow-y'
    ],
    initial: 'visible'
  },
  'overflow-anchor': {
    computed: [],
    initial: 'auto'
  },
  'overflow-block': {
    computed: [],
    initial: 'auto'
  },
  'overflow-clip-box': {
    computed: [],
    initial: 'padding-box'
  },
  'overflow-clip-margin': {
    computed: [],
    initial: '0px'
  },
  'overflow-inline': {
    computed: [],
    initial: 'auto'
  },
  'overflow-wrap': {
    computed: [],
    initial: 'normal'
  },
  'overflow-x': {
    computed: [],
    initial: 'visible'
  },
  'overflow-y': {
    computed: [],
    initial: 'visible'
  },
  'overscroll-behavior': {
    computed: [
      'overscroll-behavior-x',
      'overscroll-behavior-y'
    ],
    initial: 'auto'
  },
  'overscroll-behavior-block': {
    computed: [],
    initial: 'auto'
  },
  'overscroll-behavior-inline': {
    computed: [],
    initial: 'auto'
  },
  'overscroll-behavior-x': {
    computed: [],
    initial: 'auto'
  },
  'overscroll-behavior-y': {
    computed: [],
    initial: 'auto'
  },
  'padding': {
    computed: [
      'padding-bottom',
      'padding-left',
      'padding-right',
      'padding-top'
    ],
    initial: [
      'padding-bottom',
      'padding-left',
      'padding-right',
      'padding-top'
    ]
  },
  'padding-block': {
    computed: [
      'padding-block-start',
      'padding-block-end'
    ],
    initial: [
      'padding-block-start',
      'padding-block-end'
    ]
  },
  'padding-block-end': {
    computed: [],
    initial: '0'
  },
  'padding-block-start': {
    computed: [],
    initial: '0'
  },
  'padding-bottom': {
    computed: [],
    initial: '0'
  },
  'padding-inline': {
    computed: [
      'padding-inline-start',
      'padding-inline-end'
    ],
    initial: [
      'padding-inline-start',
      'padding-inline-end'
    ]
  },
  'padding-inline-end': {
    computed: [],
    initial: '0'
  },
  'padding-inline-start': {
    computed: [],
    initial: '0'
  },
  'padding-left': {
    computed: [],
    initial: '0'
  },
  'padding-right': {
    computed: [],
    initial: '0'
  },
  'padding-top': {
    computed: [],
    initial: '0'
  },
  'page': {
    computed: [],
    initial: 'auto'
  },
  'page-break-after': {
    computed: [],
    initial: 'auto'
  },
  'page-break-before': {
    computed: [],
    initial: 'auto'
  },
  'page-break-inside': {
    computed: [],
    initial: 'auto'
  },
  'paint-order': {
    computed: [],
    initial: 'normal'
  },
  'perspective': {
    computed: [],
    initial: 'none'
  },
  'perspective-origin': {
    computed: [],
    initial: '50% 50%'
  },
  'place-content': {
    computed: [
      'align-content',
      'justify-content'
    ],
    initial: [
      'align-content',
      'justify-content'
    ]
  },
  'place-items': {
    computed: [
      'align-items',
      'justify-items'
    ],
    initial: [
      'align-items',
      'justify-items'
    ]
  },
  'place-self': {
    computed: [
      'align-self',
      'justify-self'
    ],
    initial: [
      'align-self',
      'justify-self'
    ]
  },
  'pointer-events': {
    computed: [],
    initial: 'auto'
  },
  'position': {
    computed: [],
    initial: 'static'
  },
  'print-color-adjust': {
    computed: [],
    initial: 'economy'
  },
  'quotes': {
    computed: [],
    initial: 'dependsOnUserAgent'
  },
  'resize': {
    computed: [],
    initial: 'none'
  },
  'right': {
    computed: [],
    initial: 'auto'
  },
  'rotate': {
    computed: [],
    initial: 'none'
  },
  'row-gap': {
    computed: [],
    initial: 'normal'
  },
  'ruby-align': {
    computed: [],
    initial: 'space-around'
  },
  'ruby-merge': {
    computed: [],
    initial: 'separate'
  },
  'ruby-position': {
    computed: [],
    initial: 'alternate'
  },
  'scale': {
    computed: [],
    initial: 'none'
  },
  'scrollbar-color': {
    computed: [],
    initial: 'auto'
  },
  'scrollbar-gutter': {
    computed: [],
    initial: 'auto'
  },
  'scrollbar-width': {
    computed: [],
    initial: 'auto'
  },
  'scroll-behavior': {
    computed: [],
    initial: 'auto'
  },
  'scroll-margin': {
    computed: [
      'scroll-margin-bottom',
      'scroll-margin-left',
      'scroll-margin-right',
      'scroll-margin-top'
    ],
    initial: [
      'scroll-margin-bottom',
      'scroll-margin-left',
      'scroll-margin-right',
      'scroll-margin-top'
    ]
  },
  'scroll-margin-block': {
    computed: [
      'scroll-margin-block-start',
      'scroll-margin-block-end'
    ],
    initial: [
      'scroll-margin-block-start',
      'scroll-margin-block-end'
    ]
  },
  'scroll-margin-block-start': {
    computed: [],
    initial: '0'
  },
  'scroll-margin-block-end': {
    computed: [],
    initial: '0'
  },
  'scroll-margin-bottom': {
    computed: [],
    initial: '0'
  },
  'scroll-margin-inline': {
    computed: [
      'scroll-margin-inline-start',
      'scroll-margin-inline-end'
    ],
    initial: [
      'scroll-margin-inline-start',
      'scroll-margin-inline-end'
    ]
  },
  'scroll-margin-inline-start': {
    computed: [],
    initial: '0'
  },
  'scroll-margin-inline-end': {
    computed: [],
    initial: '0'
  },
  'scroll-margin-left': {
    computed: [],
    initial: '0'
  },
  'scroll-margin-right': {
    computed: [],
    initial: '0'
  },
  'scroll-margin-top': {
    computed: [],
    initial: '0'
  },
  'scroll-padding': {
    computed: [
      'scroll-padding-bottom',
      'scroll-padding-left',
      'scroll-padding-right',
      'scroll-padding-top'
    ],
    initial: [
      'scroll-padding-bottom',
      'scroll-padding-left',
      'scroll-padding-right',
      'scroll-padding-top'
    ]
  },
  'scroll-padding-block': {
    computed: [
      'scroll-padding-block-start',
      'scroll-padding-block-end'
    ],
    initial: [
      'scroll-padding-block-start',
      'scroll-padding-block-end'
    ]
  },
  'scroll-padding-block-start': {
    computed: [],
    initial: 'auto'
  },
  'scroll-padding-block-end': {
    computed: [],
    initial: 'auto'
  },
  'scroll-padding-bottom': {
    computed: [],
    initial: 'auto'
  },
  'scroll-padding-inline': {
    computed: [
      'scroll-padding-inline-start',
      'scroll-padding-inline-end'
    ],
    initial: [
      'scroll-padding-inline-start',
      'scroll-padding-inline-end'
    ]
  },
  'scroll-padding-inline-start': {
    computed: [],
    initial: 'auto'
  },
  'scroll-padding-inline-end': {
    computed: [],
    initial: 'auto'
  },
  'scroll-padding-left': {
    computed: [],
    initial: 'auto'
  },
  'scroll-padding-right': {
    computed: [],
    initial: 'auto'
  },
  'scroll-padding-top': {
    computed: [],
    initial: 'auto'
  },
  'scroll-snap-align': {
    computed: [],
    initial: 'none'
  },
  'scroll-snap-coordinate': {
    computed: [],
    initial: 'none'
  },
  'scroll-snap-destination': {
    computed: [],
    initial: '0px 0px'
  },
  'scroll-snap-points-x': {
    computed: [],
    initial: 'none'
  },
  'scroll-snap-points-y': {
    computed: [],
    initial: 'none'
  },
  'scroll-snap-stop': {
    computed: [],
    initial: 'normal'
  },
  'scroll-snap-type': {
    computed: [],
    initial: 'none'
  },
  'scroll-snap-type-x': {
    computed: [],
    initial: 'none'
  },
  'scroll-snap-type-y': {
    computed: [],
    initial: 'none'
  },
  'scroll-timeline': {
    computed: [
      'scroll-timeline-name',
      'scroll-timeline-axis'
    ],
    initial: [
      'scroll-timeline-name',
      'scroll-timeline-axis'
    ]
  },
  'scroll-timeline-axis': {
    computed: [],
    initial: 'block'
  },
  'scroll-timeline-name': {
    computed: [],
    initial: 'none'
  },
  'shape-image-threshold': {
    computed: [],
    initial: '0.0'
  },
  'shape-margin': {
    computed: [],
    initial: '0'
  },
  'shape-outside': {
    computed: [],
    initial: 'none'
  },
  'tab-size': {
    computed: [],
    initial: '8'
  },
  'table-layout': {
    computed: [],
    initial: 'auto'
  },
  'text-align': {
    computed: [],
    initial: 'startOrNamelessValueIfLTRRightIfRTL'
  },
  'text-align-last': {
    computed: [],
    initial: 'auto'
  },
  'text-combine-upright': {
    computed: [],
    initial: 'none'
  },
  'text-decoration': {
    computed: [
      'text-decoration-line',
      'text-decoration-style',
      'text-decoration-color',
      'text-decoration-thickness'
    ],
    initial: [
      'text-decoration-color',
      'text-decoration-style',
      'text-decoration-line'
    ]
  },
  'text-decoration-color': {
    computed: [],
    initial: 'currentcolor'
  },
  'text-decoration-line': {
    computed: [],
    initial: 'none'
  },
  'text-decoration-skip': {
    computed: [],
    initial: 'objects'
  },
  'text-decoration-skip-ink': {
    computed: [],
    initial: 'auto'
  },
  'text-decoration-style': {
    computed: [],
    initial: 'solid'
  },
  'text-decoration-thickness': {
    computed: [],
    initial: 'auto'
  },
  'text-emphasis': {
    computed: [
      'text-emphasis-style',
      'text-emphasis-color'
    ],
    initial: [
      'text-emphasis-style',
      'text-emphasis-color'
    ]
  },
  'text-emphasis-color': {
    computed: [],
    initial: 'currentcolor'
  },
  'text-emphasis-position': {
    computed: [],
    initial: 'over right'
  },
  'text-emphasis-style': {
    computed: [],
    initial: 'none'
  },
  'text-indent': {
    computed: [],
    initial: '0'
  },
  'text-justify': {
    computed: [],
    initial: 'auto'
  },
  'text-orientation': {
    computed: [],
    initial: 'mixed'
  },
  'text-overflow': {
    computed: [],
    initial: 'clip'
  },
  'text-rendering': {
    computed: [],
    initial: 'auto'
  },
  'text-shadow': {
    computed: [],
    initial: 'none'
  },
  'text-size-adjust': {
    computed: [],
    initial: 'autoForSmartphoneBrowsersSupportingInflation'
  },
  'text-transform': {
    computed: [],
    initial: 'none'
  },
  'text-underline-offset': {
    computed: [],
    initial: 'auto'
  },
  'text-underline-position': {
    computed: [],
    initial: 'auto'
  },
  'top': {
    computed: [],
    initial: 'auto'
  },
  'touch-action': {
    computed: [],
    initial: 'auto'
  },
  'transform': {
    computed: [],
    initial: 'none'
  },
  'transform-box': {
    computed: [],
    initial: 'view-box'
  },
  'transform-origin': {
    computed: [],
    initial: '50% 50% 0'
  },
  'transform-style': {
    computed: [],
    initial: 'flat'
  },
  'transition': {
    computed: [
      'transition-delay',
      'transition-duration',
      'transition-property',
      'transition-timing-function'
    ],
    initial: [
      'transition-delay',
      'transition-duration',
      'transition-property',
      'transition-timing-function'
    ]
  },
  'transition-delay': {
    computed: [],
    initial: '0s'
  },
  'transition-duration': {
    computed: [],
    initial: '0s'
  },
  'transition-property': {
    computed: [],
    initial: 'all'
  },
  'transition-timing-function': {
    computed: [],
    initial: 'ease'
  },
  'translate': {
    computed: [],
    initial: 'none'
  },
  'unicode-bidi': {
    computed: [],
    initial: 'normal'
  },
  'user-select': {
    computed: [],
    initial: 'auto'
  },
  'vertical-align': {
    computed: [],
    initial: 'baseline'
  },
  'view-transition-name': {
    computed: [],
    initial: 'none'
  },
  'visibility': {
    computed: [],
    initial: 'visible'
  },
  'white-space': {
    computed: [],
    initial: 'normal'
  },
  'widows': {
    computed: [],
    initial: '2'
  },
  'width': {
    computed: [],
    initial: 'auto'
  },
  'will-change': {
    computed: [],
    initial: 'auto'
  },
  'word-break': {
    computed: [],
    initial: 'normal'
  },
  'word-spacing': {
    computed: [],
    initial: 'normal'
  },
  'word-wrap': {
    computed: [],
    initial: 'normal'
  },
  'writing-mode': {
    computed: [],
    initial: 'horizontal-tb'
  },
  'z-index': {
    computed: [],
    initial: 'auto'
  },
  'zoom': {
    computed: [],
    initial: 'normal'
  }
};
