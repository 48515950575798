import type { IconProps } from '@wix/stylable-panel-common-react';
import { Button, Icon, Tooltip } from '@wix/stylable-panel-components';
import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import React from 'react';
import type { TranslateFunc } from '../../types';
import { classes } from './text-visualizer.st.css';

export enum IconKey {
    Bold = 'bold',
    Italic = 'italic',
    Underline = 'underline',
    Strikethrough = 'strikethrough',
    TextColor = 'textColor',
    BackgroundColor = 'backgroundColor',
    StrokeColor = 'strokeColor',
    Transform = 'transform',
    Direction = 'direction',
    Alignment = 'alignment',
}

interface IconOptions {
    noHighlight?: boolean;
    noResizing?: boolean;
}

export interface IconConfiguration {
    tooltipKey?: string;
    MainIcon?: React.FC;
    SecondaryIcon?: React.FC;
    hide?: boolean;
}

export type IconConfigurationMap = Record<IconKey, IconConfiguration>;

export const getIconConfiguration = ({ noHighlight, noResizing }: IconOptions): IconConfigurationMap => {
    const textTooltips = StylablePanelTranslationKeys.controller.text.tooltips;
    return {
        [IconKey.Bold]: {
            hide: noResizing,
        },
        [IconKey.Italic]: {
            tooltipKey: textTooltips.italic,
            MainIcon: ItalicIcon,
            hide: noResizing,
        },
        [IconKey.Underline]: {
            tooltipKey: textTooltips.underline,
            MainIcon: UnderlineIcon,
        },
        [IconKey.Strikethrough]: {
            tooltipKey: textTooltips.strikethrough,
            MainIcon: StrikethroughIcon,
        },
        [IconKey.Transform]: {
            hide: noResizing,
        },
        [IconKey.TextColor]: {
            tooltipKey: textTooltips.textColor,
            MainIcon: TextColorIcon,
        },
        [IconKey.StrokeColor]: {
            tooltipKey: textTooltips.outlineColor,
            MainIcon: StrokeColorIcon,
        },
        [IconKey.BackgroundColor]: {
            tooltipKey: textTooltips.highlightColor,
            MainIcon: BackgroundColorIcon,
            hide: noHighlight,
        },
        [IconKey.Direction]: {
            tooltipKey: textTooltips.textDirection,
            MainIcon: DirectionLeftIcon,
            SecondaryIcon: DirectionRightIcon,
            hide: noResizing,
        },
        [IconKey.Alignment]: {
            hide: noResizing,
        },
    };
};

export const MAX_ROW_ICONS = 5;

export const getIconRows = (iconConfiguration: IconConfigurationMap, iconsPerRow = MAX_ROW_ICONS) => {
    const filteredIconKeys = (Object.keys(iconConfiguration) as IconKey[]).filter(
        (iconKey) => !iconConfiguration[iconKey].hide
    );

    const iconRows: IconKey[][] = [];
    while (filteredIconKeys.length > 0) {
        iconRows.push(filteredIconKeys.splice(0, iconsPerRow));
    }

    return iconRows;
};

export interface IconInRowProps {
    iconKey: IconKey;
    iconConfiguration: IconConfigurationMap;
    translate: TranslateFunc;
}

export enum ColorPickerTextType {
    None = 0,
    Text,
    Background,
    Stroke,
}

type OpenColorPicker = (colorPickerType: ColorPickerTextType) => void;

interface ColorIconProps extends IconInRowProps {
    type: ColorPickerTextType;
    color: string | undefined;
    openColorPicker: OpenColorPicker;
    iconClassName: string;
}

export const ColorIcon: React.FC<ColorIconProps> = ({
    iconKey,
    iconConfiguration,
    type,
    color,
    openColorPicker,
    iconClassName,
    translate,
}) => {
    const { tooltipKey, MainIcon } = iconConfiguration[iconKey];
    const IconButton = MainIcon as React.FC<ColorIconSVGProps>;

    // TODO: Checked when ColorPicker is open
    return (
        <Tooltip className={classes.colorIconTooltip} text={tooltipKey && translate(tooltipKey)}>
            <Button className={classes.colorIconButton} onClick={() => openColorPicker(type)}>
                <Icon>
                    <IconButton className={iconClassName} color={color} />
                </Icon>
            </Button>
        </Tooltip>
    );
};

export interface ColorIconSVGProps extends IconProps {
    color?: string;
}

export const BoldIcon: React.FC<IconProps> = ({ className }) => (
    <svg className={className} width="11" height="14" viewBox="0 0 11 14">
        <path
            fillRule="evenodd"
            d="M6.74 14H1.98c-1.09 0-1.99-.9-1.99-2V2c0-1.1.9-2 1.99-2h3.9C7.34 0 8.5.64 9.25 1.79c.81 1.24.87 2.83.16 4.15-.14.27-.15.51-.35.73 1 .65 1.71 1.7 1.83 2.9.11 1.15-.24 2.24-.98 3.07C9.13 13.5 7.97 14 6.74 14zM1.98 8v4h4.98c.67 0 2 .06 2-2 0-1.94-.9-2-2-2H1.98zm0-2h3.99c.62 0 1.99 0 1.99-2 0-1.94-1.21-2-1.99-2H1.98v4z"
            fill="currentColor"
        />
    </svg>
);

export const ItalicIcon: React.FC<IconProps> = ({ className }) => (
    <svg className={className} width="8" height="14" viewBox="0 0 8 14">
        <path fillRule="evenodd" d="M8 2V0H1v2h2.71L2.12 12H0v2h7v-2H4.15L5.74 2H8z" fill="currentColor" />
    </svg>
);

export const UnderlineIcon: React.FC<IconProps> = ({ className }) => (
    <svg className={className} width="12" height="16" viewBox="0 0 12 16">
        <path
            fillRule="evenodd"
            d="M0 16v-2h12v2H0zm6-3.75c-2.8 0-5-2.24-5-5.21V1.09C1 .49 1.4 0 2 0s1 .49 1 1.09v5.95c0 1.5 1.21 3.03 3 3.03s3-1.53 3-3.03V1.09C9 .49 9.4 0 10 0s1 .49 1 1.09v5.95c0 2.97-2.2 5.21-5 5.21z"
            fill="currentColor"
        />
    </svg>
);

export const StrikethroughIcon: React.FC<IconProps> = ({ className }) => (
    <svg className={className} width="30" height="25" viewBox="0 0 30 25">
        <path
            fillRule="evenodd"
            d="M17.0883089,14 L8,14 L8,13 L14.9201,13 L13.9871,13 C11.7891,13 10.0001,11.314 10.0001,9.242 C10.0001,8.175 10.4641,7.134 11.2731,6.387 C12.2671,5.471 13.4921,5 14.7911,5 C16.0911,5 17.3171,5.471 18.2441,6.326 L19.2621,7.266 L17.9041,8.734 L16.8871,7.795 C15.7931,6.785 13.7931,6.784 12.6951,7.796 C12.2241,8.231 12.0001,8.724 12.0001,9.242 C12.0001,10.212 12.8921,11 13.9871,11 L14.9201,11 C16.5572021,11 18.0000891,11.7971332 18.8323182,13 L23,13 L23,14 L19.3454863,14 C19.4996086,14.4346492 19.5831,14.8993449 19.5831,15.382 C19.5831,17.929 17.3761,20 14.6641,20 L14.5061,20 C12.6441,20 10.9641,19.033 10.1211,17.477 L11.8791,16.523 C12.3651,17.421 13.3961,18 14.5061,18 L14.6641,18 C16.2731,18 17.5831,16.825 17.5831,15.382 C17.5831,14.8670642 17.3997336,14.3899074 17.0883089,14 Z"
            fill="currentColor"
        />
    </svg>
);

export const CapitalizeIcon: React.FC<IconProps> = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M5.59341 13.0001H10.4066L10 12.0001L8 7.0001L6 12.0001L5.59341 13.0001ZM4.78022 15.0001L3.15385 19.0001H1L6.38461 6.07705C6.65278 5.422 7.2922 4.99573 8 5.00003C8.7078 4.99573 9.34722 5.422 9.61539 6.07705L15 19.0001H12.8462L11.2198 15.0001H4.78022ZM21 13.0001V12.8301C21 11.8194 20.1807 11.0001 19.17 11.0001H18.41C17.7708 10.9978 17.1577 11.2539 16.71 11.7101C16.3179 12.1022 15.6821 12.1022 15.29 11.7101C14.8979 11.318 14.8979 10.6822 15.29 10.2901C16.1183 9.46419 17.2403 9.00032 18.41 9.0001H19.17C21.283 9.00559 22.9945 10.7171 23 12.8301V16.0001V19.0001H18C16.3431 19.0001 15 17.657 15 16.0001C15 14.3432 16.3431 13.0001 18 13.0001H21ZM21 15.0001H18C17.4477 15.0001 17 15.4478 17 16.0001C17 16.5524 17.4477 17.0001 18 17.0001H21V16.0001V15.0001Z"
            fill="currentColor"
        />
    </svg>
);

export const UppercaseIcon: React.FC<IconProps> = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M13.5 10.6001L15.3846 6.07705C15.6528 5.422 16.2922 4.99573 17 5.00003C17.7078 4.99573 18.3472 5.422 18.6154 6.07705L24 19.0001H21.8462L20.2198 15.0001H15.3333L13.5 10.6001ZM14.5934 13.0001H19.4066L19 12.0001L17 7.0001L15 12.0001L14.5934 13.0001ZM5.59341 13.0001H10.4066L10 12.0001L8 7.0001L6 12.0001L5.59341 13.0001ZM4.78022 15.0001L3.15385 19.0001H1L6.38461 6.07705C6.65278 5.422 7.2922 4.99573 8 5.00003C8.7078 4.99573 9.34722 5.422 9.61539 6.07705L15 19.0001H12.8462L11.2198 15.0001H4.78022Z"
            fill="currentColor"
        />
    </svg>
);

export const LowercaseIcon: React.FC<IconProps> = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M19.0041 11V10.83C19.0041 9.81932 18.1848 9 17.1741 9H16.4141C15.7749 8.99772 15.1618 9.25376 14.7141 9.71C14.322 10.1021 13.6862 10.1021 13.2941 9.71C12.902 9.31788 12.902 8.68212 13.2941 8.29C14.1224 7.4641 15.2444 7.00022 16.4141 7H17.1741C19.2871 7.0055 20.9986 8.71703 21.0041 10.83V14V17H16.0041C14.3472 17 13.0041 15.6569 13.0041 14C13.0041 12.3431 14.3472 11 16.0041 11H19.0041ZM19.0041 13H16.0041C15.4518 13 15.0041 13.4477 15.0041 14C15.0041 14.5523 15.4518 15 16.0041 15H19.0041V14V13ZM9.00409 11V10.83C9.00409 9.81932 8.18477 9 7.17409 9H6.41409C5.77486 8.99772 5.16182 9.25376 4.71409 9.71C4.32197 10.1021 3.68621 10.1021 3.29409 9.71C2.90197 9.31788 2.90197 8.68212 3.29409 8.29C4.12243 7.4641 5.24437 7.00022 6.41409 7H7.17409C9.28706 7.0055 10.9986 8.71703 11.0041 10.83V14V17H6.00409C4.34724 17 3.00409 15.6569 3.00409 14C3.00409 12.3431 4.34724 11 6.00409 11H9.00409ZM9.00409 13H6.00409C5.45181 13 5.00409 13.4477 5.00409 14C5.00409 14.5523 5.45181 15 6.00409 15H9.00409V14V13Z"
            fill="currentColor"
        />
    </svg>
);

export const AlignmentLeftIcon: React.FC<IconProps> = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M19 17V19H5V17H19ZM14 14V16H5V14H14ZM18 11V13H5V11H18ZM14 8V10H5V8H14ZM19 5V7H5V5H19Z"
            fill="currentColor"
        />
    </svg>
);

export const AlignmentRightIcon: React.FC<IconProps> = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M19 17V19H5V17H19ZM19 14V16H9V14H19ZM19 11V13H6V11H19ZM19 8V10H9V8H19ZM19 5V7H5V5H19Z"
            fill="currentColor"
        />
    </svg>
);

export const AlignmentCenterIcon: React.FC<IconProps> = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M19 17V19H5V17H19ZM16 14V16H8V14H16ZM18 11V13H6V11H18ZM16 8V10H8V8H16ZM19 5V7H5V5H19Z"
            fill="currentColor"
        />
    </svg>
);

export const AlignmentJustifyIcon: React.FC<IconProps> = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M15 17V19H6V17H15ZM19 14V16H6V14H19ZM19 11V13H6V11H19ZM19 8V10H6V8H19ZM19 5V7H6V5H19Z"
            fill="currentColor"
        />
    </svg>
);

export const DirectionLeftIcon: React.FC<IconProps> = ({ className }) => (
    <svg className={className} width="12" height="14" viewBox="0 0 12 14">
        <path
            fillRule="evenodd"
            d="M10 13.5v-14h2v14h-2zm-3-8H5.7c-1.82 0-3.3-1.18-3.3-3s1.48-3 3.3-3H9v14H7v-8zm-5.39 7.79c-.18.14-.4.21-.61.21a1 1 0 0 1-.79-.39.99.99 0 0 1 .18-1.4l1.58-1.21L.39 9.29a.99.99 0 0 1-.18-1.4 1 1 0 0 1 1.4-.18l3.66 2.79-3.66 2.79z"
            fill="currentColor"
        />
    </svg>
);

export const DirectionRightIcon: React.FC<IconProps> = ({ className }) => (
    <svg className={className} width="18" height="14" viewBox="0 0 18 14">
        <path
            fillRule="evenodd"
            d="M15.79 11l1.6 1.21c.44.33.53.96.19 1.4-.2.25-.5.39-.81.39-.21 0-.43-.07-.61-.21L12.44 11l3.72-2.79c.44-.34 1.08-.25 1.42.18.34.44.25 1.07-.19 1.4L15.79 11zM8.65 0h2.03v14H8.65V0zM5.61 6H4.29C2.44 6 .95 4.82.95 3s1.49-3 3.34-3H7.64v14H5.61V6z"
            fill="currentColor"
        />
    </svg>
);

export const TextColorIcon: React.FC<ColorIconSVGProps> = ({ className, color }) => (
    <svg className={className} width="22px" height="18px">
        <path fill={color || 'black'} fillRule="evenodd" d="M 17 3C 17 3 27.03 17 17 17 6.9 17 17 3 17 3Z" />
        <path
            fillRule="evenodd"
            d="M 16.96 18C 13.35 18 12 14.95 12 13.03 12 9.97 15.69 4.09 16.12 3.43 16.12 3.43 16.95 2.12 16.95 2.12 16.95 2.12 17.8 3.42 17.8 3.42 18.23 4.08 22 9.97 22 13.03 22 15.09 20.44 18 16.96 18ZM 16.96 3.97C 16.96 3.97 13 10.18 13 13.03 13 14.52 14.02 16.99 16.96 16.99 19.89 16.99 21 14.52 21 13.03 21 10.18 16.96 3.97 16.96 3.97ZM 3.6 9.02C 3.6 9.02 2 13.03 2 13.03 2 13.03-0 13.03-0 13.03-0 13.03 5 1 5 1 5 1 6 0 6 0 6 0 7 0 7 0 7 0 8 1 8 1 8 1 11 7.01 11 7.01 11 7.01 11 11.02 11 11.02 11 11.02 9.4 9.02 9.4 9.02 9.4 9.02 3.6 9.02 3.6 9.02ZM 7 3.01C 7 3.01 6 3.01 6 3.01 6 3.01 4.4 7.01 4.4 7.01 4.4 7.01 8.6 7.01 8.6 7.01 8.6 7.01 7 3.01 7 3.01Z"
            fill="currentColor"
        />
    </svg>
);

export const BackgroundColorIcon: React.FC<ColorIconSVGProps> = ({ className, color }) => (
    <svg className={className} width="28" height="24" viewBox="0 0 28 24">
        <path
            d="M22.958 21C20.024 21 19 18.489 19 17C19 14.156 22.958 7.95801 22.958 7.95801C22.958 7.95801 27 14.156 27 17C27 18.489 25.893 21 22.958 21Z"
            fill={color || 'transparent'}
        />
        <path
            d="M18.146 10.771C19.594 7.918 21.317 5.297 22 4.396V3C22 1.896 21.105 1 20 1H4H1C0.448 1 0 1.448 0 2C0 2.553 0.448 3 1 3H4V6H2C1.448 6 1 6.448 1 7C1 7.553 1.448 8 2 8H4V10H3C2.448 10 2 10.448 2 11C2 11.553 2.448 12 3 12H4V14H3C2.448 14 2 14.448 2 15C2 15.553 2.448 16 3 16H4V18C4 19.105 4.896 20 6 20H16.984C16.127 18.655 16.041 17.081 16.374 15.441L15.393 13H9.774L8 17H6L11.043 5.178C11.343 4.452 11.987 4 12.722 4H12.723C13.459 4 14.102 4.452 14.402 5.178L17.29 12.602C17.55 11.986 17.841 11.373 18.146 10.771ZM22.9495 6.0785L23.7965 7.3765C24.2275 8.0365 28.0005 13.9475 28.0005 17.0005C28.0005 19.0595 26.4365 22.0005 22.9585 22.0005C19.3465 22.0005 18.0005 18.9175 18.0005 17.0005C18.0005 13.9525 21.6945 8.0435 22.1155 7.3845L22.9495 6.0785ZM19.0005 17.0005C19.0005 18.4895 20.0245 21.0005 22.9585 21.0005C25.8935 21.0005 27.0005 18.4895 27.0005 17.0005C27.0005 14.1565 22.9585 7.9585 22.9585 7.9585C22.9585 7.9585 19.0005 14.1565 19.0005 17.0005ZM14.5897 11.0005H10.6607L12.7227 6.3525L14.5897 11.0005Z"
            fill="currentColor"
        />
    </svg>
);

export const StrokeColorIcon: React.FC<ColorIconSVGProps> = ({ className, color }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M17.958 21C15.024 21 14 18.489 14 17C14 14.156 17.958 7.95801 17.958 7.95801C17.958 7.95801 22 14.156 22 17C22 18.489 20.893 21 17.958 21Z"
            fill={color || 'transparent'}
        />
        <path
            d="M5.06101 11.9942L3.74377 16.2304H1L5.64266 4.27647C6.04255 3.24683 7.20142 2.73632 8.23106 3.13621C8.75268 3.33879 9.16557 3.75064 9.36948 4.27174L12 10.9942L10.5 14.9942L10.4403 15.0055L9.72937 11.9942H5.06101ZM5.68289 9.99418H9.2572L8.55577 7.02307C8.47448 6.67873 8.21729 6.40298 7.87945 6.29793C7.35207 6.13395 6.79161 6.42854 6.62762 6.95591L5.68289 9.99418Z"
            stroke="currentColor"
        />
        <path
            d="M18.797 7.37649C19.228 8.03649 23.001 13.9475 23.001 17.0005C23.001 19.0595 21.437 22.0005 17.959 22.0005C14.347 22.0005 13.001 18.9175 13.001 17.0005C13.001 13.9525 16.695 8.04349 17.116 7.38449L17.95 6.07849L18.797 7.37649ZM17.959 21.0005C20.894 21.0005 22.001 18.4895 22.001 17.0005C22.001 14.1565 17.959 7.95849 17.959 7.95849C17.959 7.95849 14.001 14.1565 14.001 17.0005C14.001 18.4895 15.025 21.0005 17.959 21.0005Z"
            fill="currentColor"
        />
    </svg>
);
