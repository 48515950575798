import React, { ChangeEventHandler } from 'react';
import { Text } from '../text/text';
import { classes, style } from './radio-select.st.css';

export interface RadioOptions {
    label: string;
    checked?: boolean;
    disabled?: boolean;
}

export type SelectOnChangeEvent = (event: Parameters<ChangeEventHandler<HTMLInputElement>>[0], index: number) => void;

export interface RadioSelectProps {
    onChange: SelectOnChangeEvent;
    options: RadioOptions[];
    className?: string;
}

export const RadioSelect: React.FC<RadioSelectProps> = ({ onChange, options, className = '' }) => {
    const handleSelect =
        (index: number): ChangeEventHandler<HTMLInputElement> =>
        (event) => {
            onChange(event, index);
        };

    return (
        <ul className={style(classes.root, className)}>
            {options.map((option, i) => {
                const optionId = option.label + i;
                const disabled = !!option.disabled;
                return (
                    <li key={optionId} className={style(classes.radioGroup, { disabled })}>
                        <input
                            type="radio"
                            id={optionId}
                            name="radio-group"
                            value={option.label}
                            className={classes.radioInput}
                            checked={!!option.checked}
                            disabled={disabled}
                            onChange={handleSelect(i)}
                        />
                        <label htmlFor={optionId} className={classes.radioLabel}>
                            <Text className={classes.radioText}>{option.label}</Text>
                        </label>
                    </li>
                );
            })}
        </ul>
    );
};
