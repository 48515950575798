import { DIMENSION_ID } from '@wix/stylable-panel-common';
import { BackgroundBox, CompositeBlock } from '@wix/stylable-panel-components';
import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import chroma from 'chroma-js';
import React, { useCallback, useMemo } from 'react';
import type { OpenedDeclarationArray } from '../../declaration-types';
import { getDimensionConfig, OpacityVisualizer } from '../../generated-visualizers';
import { useTranslate, useVisualizerChange } from '../../hooks';
import { createDeclarationMapFromVisualizerValue, createVisualizerValueFromDeclarationMap } from '../../utils';
import type { TextDeclarationMap, TextVisualizerProps } from './use-text-visualizer';
import { ColorPickerTextType } from './text-visualizer-icons';
import { classes } from './text-visualizer.st.css';

export type FontThemeBlockProps = {
    props: TextVisualizerProps;
    declarationMapValue: TextDeclarationMap;
    openColorPicker: (colorPickerType: ColorPickerTextType) => void;
};

export function GlyphMode({ props, declarationMapValue, openColorPicker }: FontThemeBlockProps) {
    const { panelHost, drivers } = props;

    const { color } = declarationMapValue;
    const translate = useTranslate(panelHost);
    const handleColorChange = useVisualizerChange('color', props);

    let opacityValue = 100;
    try {
        const parsedColor = chroma(color || '');
        opacityValue = Math.floor(parsedColor.alpha() * 100);
    } catch {
        //
    }

    const dimensionConfig = useMemo(
        () =>
            getDimensionConfig({
                id: DIMENSION_ID.OPACITY,
                dimensionUnits: panelHost?.dimensionUnits,
                dimensionKeywords: panelHost?.dimensionKeywords,
            }),
        [panelHost?.dimensionUnits, panelHost?.dimensionKeywords]
    );

    const changeColorAlpha = useCallback(
        (value: string) => {
            if (!handleColorChange) {
                return;
            }

            try {
                const parsedColor = chroma(color || '');
                const numberValue = parseFloat(value);

                handleColorChange(parsedColor.alpha(numberValue / 100).css());
            } catch {
                //
            }
        },
        [color, handleColorChange]
    );

    const handleOnChange = useCallback(
        (value: OpenedDeclarationArray<'opacity'>) => {
            const { opacity } = createDeclarationMapFromVisualizerValue(value, { value: [], drivers });
            if (opacity) {
                changeColorAlpha(opacity);
            }
        },
        [changeColorAlpha, drivers]
    );

    // TODO: Extract to component
    return (
        <CompositeBlock
            key="color"
            className={classes.controllerBlock}
            title={translate(StylablePanelTranslationKeys.controller.separators.colorLabel)}
            divider
        >
            <div className={classes.opacityWrapper}>
                <BackgroundBox
                    className={classes.colorBox}
                    value={color}
                    noColor={!color}
                    showNoColorDiagonal
                    onClick={() => openColorPicker(ColorPickerTextType.Text)}
                />
                <OpacityVisualizer
                    drivers={drivers}
                    className={classes.inputElementOpacity}
                    value={createVisualizerValueFromDeclarationMap({
                        opacity: `${opacityValue}%`,
                    })}
                    config={dimensionConfig}
                    opacitySliderColor={color}
                    onChange={handleOnChange}
                    isDisabled={!color}
                    panelHost={panelHost}
                />
            </div>
        </CompositeBlock>
    );
}
