import { StylablePanelTranslationKeys } from '@wix/stylable-panel-drivers';
import type { SingleCategoryConfig } from '../../../../types';
import { gradDef, colDef } from '../category-config-formatters';

const gradientDeg0 = `120deg`;
const gradientDefs0 = [colDef('#b5f3e8', 0, 60), colDef('#a2a3e9', 60, 100)];

const gradientDeg1 = `45deg`;
const gradientDefs1 = [colDef('#1d2cf3', 0, 40), colDef('#ff4f4f', 40, 100)];

const gradientDeg2 = `45deg`;
const gradientDefs2 = [colDef('value(site_2_1)', 0, 40), colDef('value(site_4_1)', 40, 100)];

export const borderBlockVariants: SingleCategoryConfig = {
    titleKey: StylablePanelTranslationKeys.controller.borders.title,
    blockVariantSet: [
        { border: 'none' }, // clear
        { border: '1px solid value(site_2_4)' }, // solid simple fg color
        { border: '7px double value(site_4_3)' }, // double border
        {
            border: '13px solid',
            'border-image-slice': '1',
            'border-image-source': 'linear-gradient(316deg, value(site_2_1), value(site_5_1))',
        }, // simple gradient

        // {'border': '7px solid pink', 'border-image': 'repeating-linear-gradient(45deg, pink, pink 1%, purple, purple 1%, purple 8%) 10'},
        // {'border': '7px solid pink', 'border-image': 'repeating-linear-gradient(45deg, black, black 8%, white, white 6%, white 10%) 20'},
        // {'border': '7px solid pink', 'border-image': 'repeating-linear-gradient(45deg, black, black 8%, white, white 6%, white 10%) 10'},
        {
            border: '3px solid',
            'border-image-slice': '1',
            'border-image-source': gradDef('linear-gradient', gradientDeg2, gradientDefs2),
        },

        // Border Gradient
        {
            border: '13px solid',
            'border-image-slice': '1',
            'border-image-source': 'linear-gradient(to bottom, #f6f6f6, #eaeaea 22%, #bebebe 48%, #4d4d4d)',
        },
        {
            border: '13px solid',
            'border-image-slice': '1',
            'border-image-source':
                'linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.5) 22%, rgba(150, 150, 150, 0.5) 48%, rgba(80, 80, 80, 0.5))',
        },
        {
            border: '13px solid',
            'border-image-slice': '1',
            'border-image-source': 'radial-gradient(circle at 54% -56%, #fff932, #ffc3a8 66%, #ffb3c9)',
        },
        {
            border: '13px solid',
            'border-image-slice': '1',
            'border-image-source': 'linear-gradient(to right, #ff8989, #ff4f4f)',
        },
        {
            border: '13px solid',
            'border-image-slice': '1',
            'border-image-source': 'linear-gradient(to bottom, #ffd9e4, #bfdbdc 62%, #b5f3e8)',
        },
        {
            border: '13px solid',
            'border-image-slice': '1',
            'border-image-source': 'linear-gradient(to left, #ffd9e4, #566fb8 68%, #1d2cf3)',
        },
        {
            border: '13px solid',
            'border-image-slice': '1',
            'border-image-source': 'linear-gradient(to bottom, #ffcbaa, #f7e4d5)',
        },
        {
            border: '13px solid',
            'border-image-slice': '1',
            'border-image-source': 'linear-gradient(316deg, #ff858a, #e43292)',
        },
        {
            border: '13px solid',
            'border-image-slice': '1',
            'border-image-source': 'linear-gradient(to left, #b5f3e8, #26dcbc)',
        },
        {
            border: '13px solid',
            'border-image-slice': '1',
            'border-image-source': 'linear-gradient(225deg, #20ce88, #80f8c8 26%, #87e6cf 44%, #a2a3e9 73%, #566fb8)',
        },
        {
            border: '13px solid',
            'border-image-slice': '1',
            'border-image-source': 'linear-gradient(to left, #20ce88, #d9fd33)',
        },
        {
            border: '13px solid',
            'border-image-slice': '1',
            'border-image-source': 'linear-gradient(315deg, #a2a3e9, #b45ad3 50%, #532563)',
        },
        {
            border: '13px solid',
            'border-image-slice': '1',
            'border-image-source': 'linear-gradient(to left, #b45ad3, #ffcbaa)',
        },
        {
            border: '13px solid',
            'border-image-slice': '1',
            'border-image-source': 'linear-gradient(to top, #e43292, #ff8c62 55%, #ffd45a)',
        },
        {
            border: '13px solid',
            'border-image-slice': '1',
            'border-image-source': 'linear-gradient(45deg, #2cede4, #566fb8)',
        },
        {
            border: '13px solid',
            'border-image-slice': '1',
            'border-image-source': gradDef('linear-gradient', gradientDeg0, gradientDefs0),
        },
        {
            border: '13px solid',
            'border-image-slice': '1',
            'border-image-source': gradDef('linear-gradient', gradientDeg1, gradientDefs1),
        },
        {
            border: '13px solid',
            'border-image-slice': '1',
            'border-image-source': gradDef('linear-gradient', gradientDeg2, gradientDefs2),
        },

        // Borders
        { border: '2px solid value(site_3_1)' },
        { border: '8px solid value(site_5_2)' },
        { border: '3px dashed value(site_3_1)' },
        { border: '3px dashed value(site_1_3)' },
        { border: '1px dashed value(site_4_3)' },
        { border: '3px dotted value(site_5_2)' },
        { border: '1px solid value(site_5_4)', 'border-left': '6px solid value(site_5_4)' },
        { border: '6px solid value(site_4_5)', 'border-right': '1px solid value(site_4_5)' },
        {
            border: '1px solid value(site_5_5)',
            'border-right': '10px solid value(site_5_5)',
            'border-top': '4px solid value(site_5_5)',
        },
        { border: '7px solid rgba(0, 0, 0, 0.5)' },
        {
            border: '7px solid value(site_4_3)',
            'border-right-color': 'value(site_4_2)',
            'border-top-color': 'value(site_4_2)',
            'border-left-color': 'value(site_5_2)',
            'border-bottom-color': 'value(site_5_2)',
        },
        { border: '3px solid value(site_4_3)', 'border-top': '3px dotted', 'border-bottom': '3px dotted' },

        // sandbox variants
        { border: '2px solid value(site_4_3)' },
        { border: '1px solid value(site_3_2)' },
        { border: '1px dashed value(site_2_4)' },
        { border: '4px double value(site_1_3)' },
        {
            border: '1px solid value(site_2_2)',
            'border-right': '1px solid value(site_2_2)',
            'border-left': '1px solid value(site_2_2)',
        },
        { border: '1px solid black' },
        {
            'border-style': 'solid',
            'border-width': '4px',
            'border-image-source':
                'linear-gradient(10deg, #ffb3c9,#ffb3c9 20%,#20ce88 20%, #50ce88 80%, #d9fd33 80%, #d9fd33)',
            'border-image-slice': '1',
        },
    ],
    props: [
        /border-(left|right|bottom|top|color|width|image)(?!(.*-radius))/, // borders without radius
        'border',
    ],
    optional: [
        // /outline/
        // /border-.*(style)/,
    ],
};

export default borderBlockVariants;
