import { isClickInElement, MoreActions, MoreStates } from '@wix/stylable-panel-common-react';
import React, { createRef } from 'react';
import { Button } from '../button/button';
import { TranslateDriver } from '../drivers/translate-driver';
import { Icon } from '../icon/icon';
import { Option, OptionList } from '../option-list/option-list';
import { Tooltip } from '../tooltip/tooltip';
import { TabButton } from './tab-button';
import { classes, style } from './tabs-item-selection.st.css';

const DEFAULT_ITEMS_PER_PAGE = 3;
const CONTEXT_MENU_ITEM_HEIGHT = 36;

export interface TabsItemSelectionAction {
    id: string;
    displayName: string;
    disabled?: boolean;
    callback: () => void;
}

export interface TabsItemSelectionStrings {
    contextMenuTooltip: string;
}

const TabsItemSelectionStringsFallbacks: TabsItemSelectionStrings = {
    contextMenuTooltip: 'View more',
};

export interface TabsItemSelectionProps {
    items: Option[];
    selectedItemId?: string;
    itemsPerPage?: number;
    actions?: TabsItemSelectionAction[];
    onSelectItem?: (itemId: string) => void;
    onHoverItem?: (itemId: string | null) => void;
    translations?: Partial<TabsItemSelectionStrings>;
    className?: string;
}

export interface TabsItemSelectionState {
    contextMenuOpen: boolean;
    pageWidth: number;
}

export class TabsItemSelection extends React.Component<TabsItemSelectionProps, TabsItemSelectionState> {
    public static defaultProps: Partial<TabsItemSelectionProps> = {
        itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    };

    private contextMenuWrapper = createRef<HTMLDivElement>();
    private itemsWrapper = createRef<HTMLSpanElement>();
    private optionList = createRef<OptionList>();

    private translateDriver: TranslateDriver<TabsItemSelectionStrings>;
    private pageStartIndex = 0;

    constructor(props: TabsItemSelectionProps) {
        super(props);

        this.setPageData(props);
        this.translateDriver = new TranslateDriver<TabsItemSelectionStrings>(
            TabsItemSelectionStringsFallbacks,
            props.translations
        );

        this.state = {
            contextMenuOpen: false,
            pageWidth: 0,
        };
    }

    public render() {
        const { items, selectedItemId, actions = [], onSelectItem, onHoverItem, className } = this.props;
        const { contextMenuOpen } = this.state;

        const isContextMenu = this.hasScroll() || actions.length > 1;
        const contextMenuItems =
            contextMenuOpen && isContextMenu
                ? items.concat(
                      actions.map(({ id, displayName, disabled }) => ({ id, displayName, isAction: true, disabled }))
                  )
                : [];
        const contextMenuHeight = CONTEXT_MENU_ITEM_HEIGHT * contextMenuItems.length;
        if (contextMenuItems.length > 0 && items.length > 0) {
            contextMenuItems[items.length - 1].hasDivider = true;
        }
        const itemWidth = this.getItemWidth();

        return (
            <div className={style(classes.root, className)}>
                <span
                    className={classes.itemsWrapper}
                    onMouseLeave={onHoverItem ? () => onHoverItem(null) : undefined}
                    ref={this.itemsWrapper}
                >
                    <div className={classes.itemsList} style={{ left: this.getItemsListScroll() }}>
                        {items.map(({ id, displayName }) => (
                            <TabButton
                                key={id}
                                id={id}
                                className={classes.item}
                                title={displayName}
                                selected={selectedItemId === id}
                                noIcon
                                onClick={onSelectItem ? () => onSelectItem(id) : undefined}
                                onMouseEnter={onHoverItem ? () => onHoverItem(id) : undefined}
                                style={{ width: itemWidth }}
                            />
                        ))}
                    </div>
                </span>
                <span className={classes.controls}>
                    <div className={classes.contextMenuWrapper} ref={this.contextMenuWrapper}>
                        {this.renderActionButton(isContextMenu)}
                        {contextMenuOpen ? (
                            <OptionList
                                className={classes.popup}
                                value={selectedItemId}
                                options={contextMenuItems}
                                onHover={this.handleHoverItem}
                                onSelect={this.handleSelectItem}
                                onClose={this.closePopup}
                                scrollbarProps={{
                                    autoHeightMin: contextMenuHeight,
                                    autoHeightMax: contextMenuHeight,
                                }}
                                style={this.getContextMenuStyle()}
                                ref={this.optionList}
                            />
                        ) : null}
                    </div>
                </span>
            </div>
        );
    }

    public componentDidUpdate(prevProps: TabsItemSelectionProps) {
        if (prevProps.selectedItemId !== this.props.selectedItemId) {
            this.setPageData(this.props);
        }
    }

    public componentDidMount() {
        if (this.itemsWrapper.current) {
            this.setState({ pageWidth: this.itemsWrapper.current.getBoundingClientRect().width });
        }
    }

    public componentWillUnmount() {
        this.cleanupClickEventListeners();
    }

    private renderActionButton(isContextMenu = false) {
        const { actions } = this.props;
        const { translate } = this.translateDriver;

        let singleAction: TabsItemSelectionAction | undefined;
        if (actions && actions.length === 1) {
            singleAction = actions[0];
        }

        let hasButton = false;
        let tooltipText: string | undefined;
        let disabled = false;
        let onClick: (() => void) | undefined;
        let icon: JSX.Element | undefined;

        if (isContextMenu) {
            hasButton = true;
            tooltipText = translate('contextMenuTooltip');
            onClick = () => this.openPopup();
            icon = <MoreStates />;
        } else if (singleAction) {
            hasButton = true;
            tooltipText = singleAction.displayName;
            disabled = !!singleAction.disabled;
            onClick = singleAction.callback;
            icon = <MoreActions />;
        }

        return hasButton && icon ? (
            <Tooltip
                className={classes.actionButtonTooltip}
                text={tooltipText}
                verticalAdjust={-12}
                disabled={disabled}
            >
                <Button className={style(classes.actionButton, { disabled })} onClick={onClick} disabled={disabled}>
                    <Icon>{icon}</Icon>
                </Button>
            </Tooltip>
        ) : null;
    }

    private setPageData(props: TabsItemSelectionProps) {
        const { items, selectedItemId, itemsPerPage = DEFAULT_ITEMS_PER_PAGE } = props;
        if (!this.hasScroll() || selectedItemId === undefined) {
            return;
        }

        const selectedItemIndex = items.findIndex((item) => item.id === selectedItemId);
        if (
            selectedItemIndex === -1 ||
            (selectedItemIndex >= this.pageStartIndex && selectedItemIndex <= this.pageStartIndex + itemsPerPage - 1)
        ) {
            return;
        }

        if (
            items.length % itemsPerPage === 0 ||
            selectedItemIndex < Math.floor(items.length / itemsPerPage) * itemsPerPage
        ) {
            this.pageStartIndex = selectedItemIndex - (selectedItemIndex % itemsPerPage);
        } else {
            this.pageStartIndex = items.length - itemsPerPage;
        }
    }

    private hasScroll() {
        const { items, itemsPerPage = DEFAULT_ITEMS_PER_PAGE } = this.props;
        return items.length > itemsPerPage;
    }

    private getItemsListScroll() {
        const { itemsPerPage = DEFAULT_ITEMS_PER_PAGE } = this.props;
        return `${(-100 / itemsPerPage) * this.pageStartIndex}%`;
    }

    private getItemWidth() {
        const { items, itemsPerPage = DEFAULT_ITEMS_PER_PAGE } = this.props;
        const { pageWidth } = this.state;

        return pageWidth / Math.min(items.length, itemsPerPage);
    }

    private getContextMenuStyle = () => {
        if (!this.contextMenuWrapper.current) {
            return {};
        }

        const contextButtonRect = this.contextMenuWrapper.current.getBoundingClientRect();

        return {
            top: `calc(${contextButtonRect.top}px + ${contextButtonRect.height}px)`,
            transform: `translateX(${contextButtonRect.left}px)`,
        } as Partial<React.CSSProperties>;
    };

    private openPopup() {
        document.addEventListener('click', this.handleDocumentClick);
        this.setState({ contextMenuOpen: true });
    }

    private handleDocumentClick = (event: MouseEvent) => {
        if (this.optionList.current && !isClickInElement(event, this.optionList.current)) {
            this.closePopup();
        }
    };

    private handleHoverItem = (id: string | null) => {
        const { actions, onHoverItem } = this.props;
        if (onHoverItem && (id === null || !actions || !actions.find((action) => action.id === id))) {
            onHoverItem(id);
        }
    };

    private handleSelectItem = (id: string) => {
        const { actions, onSelectItem } = this.props;
        const actionItem = actions && actions.find((action) => action.id === id);
        if (actionItem) {
            actionItem.callback();
        } else {
            onSelectItem && onSelectItem(id);
        }
        this.closePopup();
    };

    public closePopup = () => {
        this.cleanupClickEventListeners();
        this.setState({ contextMenuOpen: false });
    };

    private cleanupClickEventListeners() {
        document.removeEventListener('click', this.handleDocumentClick);
    }
}
