import {
    ColorStop,
    DEFAULT_EVAL_DECLARATION_VALUE,
    expandGradient,
    Gradient,
    isStripesGradient,
    SiteVarsDriver,
} from '@wix/stylable-panel-drivers';
import chroma from 'chroma-js';
import type { GradientNode, RadialGradientNode } from 'gradient-parser';

const MIX_MODE = 'rgb';

// TODO: Extract to stylable-gradient
export const setRepeatData = (gradient: Gradient) => {
    if (!gradient.type.startsWith('repeating')) {
        gradient.repeatData = undefined;
        return gradient;
    }

    if (isStripesGradient(gradient)) {
        const offset = parseFloat(gradient.colorStops[1].length);
        const size = parseFloat(gradient.colorStops[3].length) - offset;
        gradient.repeatData = {
            lengths: ['0%', '100%'],
            repeats: -1,
            offset,
            size,
        };
        return gradient;
    }

    const start = parseFloat(parseFloat(gradient.colorStops[0].length).toFixed(3));
    const end = parseFloat(parseFloat(gradient.colorStops[gradient.colorStops.length - 1].length).toFixed(3));
    const multiplier = 100 / (end - start);

    const lengths: string[] = [];
    gradient.colorStops.forEach((stop) => lengths.push(`${(parseFloat(stop.length) - start) * multiplier}%`));
    gradient.repeatData = {
        lengths,
        repeats: Math.ceil(multiplier),
        offset: Math.round(start),
        size: Math.round(end - start),
    };
    return gradient;
};

// TODO: Extract to stylable-gradient
export const getNewStopInsertData = ({ colorStops, repeatData, type }: Gradient, addStopLeft: string) => {
    const isRepeating = type.startsWith('repeating');
    let firstStopLength = 0;
    let lastStopLength = 100;
    let multiplier = 1;
    if (isRepeating && repeatData) {
        firstStopLength = parseFloat(parseFloat(colorStops[0].length).toFixed(3));
        lastStopLength = parseFloat(parseFloat(colorStops[colorStops.length - 1].length).toFixed(3));
        multiplier = 100 / (lastStopLength - firstStopLength);
    }
    const addStopNum = parseFloat(addStopLeft);

    const lengths = isRepeating && repeatData ? repeatData.lengths : colorStops.map((stop) => stop.length);
    let index = lengths.findIndex((currentLen) => addStopNum < parseFloat(currentLen));

    let color = '';
    if (index === 0) {
        color = colorStops[0].color;
    } else if (index === -1) {
        index = colorStops.length;
        color = colorStops[index - 1].color;
    } else {
        const startColor = colorStops[index - 1].color;
        const endColor = colorStops[index].color;
        const startLength = parseFloat(colorStops[index - 1].length);
        const endLength = parseFloat(colorStops[index].length);
        const newStopLength = isRepeating && repeatData ? addStopNum / multiplier + repeatData.offset : addStopNum;
        const mixedColor = chroma.mix(
            startColor,
            endColor,
            (newStopLength - startLength) / (endLength - startLength),
            MIX_MODE
        );
        color = mixedColor.css();
    }

    let length = addStopLeft;
    if (isRepeating && repeatData) {
        length = `${addStopNum / multiplier + repeatData.offset}%`;
        repeatData.lengths.splice(index, 0, addStopLeft);
    }

    return { index, colorStop: { color, length } as ColorStop };
};

// TODO: Extract to stylable-gradient
export const getCurrentRadialOrientation = ({ orientation }: Gradient) => {
    const gradientAST = expandGradient(`radial-gradient(${orientation}, black)`, 'background-image', 'background')[
        'background-image'
    ] as GradientNode;
    if (!gradientAST || !gradientAST.orientation) {
        return null;
    }

    const atValue = (gradientAST.orientation as RadialGradientNode['orientation'])![0].at!.value;
    if (!atValue.y) {
        return null;
    }

    return (gradientAST.orientation as RadialGradientNode['orientation'])![0];
};

// TODO: Extract to stylable-gradient
export const getStepsGradient = (
    siteVarsDriver: SiteVarsDriver | undefined,
    { colorStops, orientation, steps }: Gradient,
    forceStepLength?: boolean
) => {
    const evalDeclarationValue =
        siteVarsDriver?.evalDeclarationValue?.bind(siteVarsDriver) ?? DEFAULT_EVAL_DECLARATION_VALUE;

    const startStop = colorStops[0];
    const endStop = colorStops[colorStops.length - 1];

    const stepsGradient: Gradient = {
        type: 'linear-gradient',
        orientation: orientation,
        colorStops: [],
        steps: -1,
    };

    if (!startStop || !endStop) {
        return stepsGradient;
    }

    const colors: string[] = [];
    for (let i = 0; i < steps; i++) {
        const color = chroma.mix(
            evalDeclarationValue(startStop.color),
            evalDeclarationValue(endStop.color),
            i / (steps - 1),
            MIX_MODE
        );
        colors.push(color.css());
    }

    let innerStepSpan = 100 / steps;
    if (forceStepLength) {
        stepsGradient.colorStops.push({ color: colors[0], length: `${innerStepSpan}%` });
    } else {
        stepsGradient.colorStops.push(startStop);
        innerStepSpan = (parseFloat(endStop.length) - parseFloat(startStop.length)) / (steps - 2);
    }
    const startLength = parseFloat(stepsGradient.colorStops[0].length);
    stepsGradient.colorStops.push({ color: colors[1], length: `${startLength}%` });

    for (let i = 1; i < colors.length - 2; i++) {
        stepsGradient.colorStops.push({ color: colors[i], length: `${startLength + innerStepSpan * i}%` });
        stepsGradient.colorStops.push({ color: colors[i + 1], length: `${startLength + innerStepSpan * i}%` });
    }

    if (colors.length > 2) {
        if (forceStepLength) {
            stepsGradient.colorStops.push({
                color: colors[colors.length - 2],
                length: `${innerStepSpan * (steps - 1)}%`,
            });
            stepsGradient.colorStops.push({
                color: colors[colors.length - 1],
                length: `${innerStepSpan * (steps - 1)}%`,
            });
        } else {
            stepsGradient.colorStops.push({ color: colors[colors.length - 2], length: endStop.length });
            stepsGradient.colorStops.push(endStop);
        }
    }

    return stepsGradient;
};

// TODO: Extract to stylable-gradient
export const getStripesGradient = ({ colorStops, orientation, repeatData }: Gradient): Gradient => {
    const startStop = colorStops[0];
    const endStop = colorStops[colorStops.length - 1];

    return {
        type: 'repeating-linear-gradient',
        orientation: orientation,
        colorStops:
            startStop && endStop && repeatData
                ? [
                      { color: startStop.color, length: `0%` },
                      { color: startStop.color, length: `${repeatData.offset}px` },
                      { color: endStop.color, length: `${repeatData.offset}px` },
                      { color: endStop.color, length: `${repeatData.offset + repeatData.size}px` },
                  ]
                : [],
        steps: -1,
    };
};

// TODO: Extract to stylable-gradient
export const getLength = ({ colorStops, repeatData, steps, type }: Gradient, index: number): string => {
    if (repeatData && type.startsWith('repeating')) {
        if (repeatData.repeats === -1) {
            return index === 0 ? '0%' : '100%';
        }
        return repeatData.lengths[index];
    }
    if (steps === 2) {
        return index === 0 ? '0%' : '100%';
    }
    return colorStops[index].length;
};
