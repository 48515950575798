import { ShadowProps, TextShadowVisualizer } from '@wix/stylable-panel-controllers';
import React, { FC } from 'react';
import { usePanelContext } from '../configurable-panel';
import type { CpControllerCommonProps } from '../cp-controller';
import { generateVisualizerProps } from '../utils/generate-visualizer-props';

export type CpTextShadowVisualizerProps = CpControllerCommonProps;
export const CpTextShadowVisualizer: FC<CpTextShadowVisualizerProps> = ({ editTargetSelector }) => {
    const context = usePanelContext();
    return (
        <TextShadowVisualizer
            {...generateVisualizerProps<ShadowProps>({ context, editTargetSelector, key: 'CpTextShadowVisualizer' })}
        />
    );
};
