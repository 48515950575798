import React from 'react';
import type { CustomInputProps } from '../../drivers';

export abstract class CommonInput<S> extends React.Component<CustomInputProps, S> {
    // eslint-disable-next-line react/no-deprecated
    public componentWillMount() {
        this.setValues(this.props);
    }

    public componentDidUpdate() {
        this.setValues(this.props);
    }

    protected abstract setValues(props: CustomInputProps): string | undefined;

    protected abstract getCssStringValue(): string;
}
