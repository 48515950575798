import { Button, Icon, Tooltip } from '@wix/stylable-panel-components';
import React, { useCallback, useMemo } from 'react';
import { useDeclarationMapValue, useTranslate } from '../../hooks';
import type { DeclarationVisualizerProps, VisualizerFC } from '../../types';
import { classes, style } from './icon-toggle-visualizer.st.css';

type IconToggleFactoryProps = {
    tooltipKey: string;
    MainIcon: React.FunctionComponent;
    SecondaryIcon?: React.FunctionComponent;
    checkedPredicate: (value: string | undefined) => boolean;
    checkedValue: string;
    uncheckedValue: string;
};

export interface IconToggleVisualizerProps<MAIN extends string> extends DeclarationVisualizerProps<MAIN> {}

export type IconToggleVisualizer<MAIN extends string> = VisualizerFC<MAIN, IconToggleVisualizerProps<MAIN>>;

export function IconToggleVisualizerFactory<MAIN extends string>(main: MAIN, iconToggleProps: IconToggleFactoryProps) {
    const { tooltipKey, MainIcon, SecondaryIcon, checkedValue, uncheckedValue, checkedPredicate } = iconToggleProps;
    const IconToggleVisualizer: IconToggleVisualizer<MAIN> = (props) => {
        const { panelHost, className } = props;

        const translate = useTranslate(panelHost);
        const [value, handleChange] = useDeclarationMapValue(main, props);
        const isChecked = useMemo(() => checkedPredicate(value), [value]);
        const IconToggle = useMemo(() => (isChecked && SecondaryIcon ? SecondaryIcon : MainIcon), [isChecked]);

        const onToggleChange = useCallback(() => {
            handleChange?.(isChecked ? uncheckedValue : checkedValue);
        }, [handleChange, isChecked]);

        return (
            <Tooltip className={style(classes.root, className)} text={tooltipKey && translate(tooltipKey)}>
                <Button
                    className={classes.toggleButton}
                    data-id={`icon_${main}`}
                    isChecked={isChecked && !SecondaryIcon}
                    onClick={onToggleChange}
                    isToggleButton
                >
                    <input className={classes.hiddenInput} type="checkbox" checked={isChecked} onChange={() => true} />
                    {IconToggle && (
                        <Icon>
                            <IconToggle />
                        </Icon>
                    )}
                </Button>
            </Tooltip>
        );
    };

    IconToggleVisualizer.INPUT_PROPS = [main];

    return IconToggleVisualizer;
}
