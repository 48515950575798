import type { UnitRefPartialRecord } from './types';
import { MAX_INPUT_VALUE } from './consts';

export const PERCENTS_RANGE: UnitRefPartialRecord = { percents: { min: 0, max: 100 } };
export const DEGREES_RANGE: UnitRefPartialRecord = { degrees: { min: 0, max: 360, displaySymbol: '\u00B0' } };
export const COLOR_ADDER_RGB: UnitRefPartialRecord = { unitless: { min: 0, max: 255 } };
export const COLOR_ADDER_HSB: UnitRefPartialRecord = { unitless: { min: 0, max: 100 } };
export const COLOR_ADDER_HSB_HUE: UnitRefPartialRecord = { unitless: { min: 0, max: 360 } };
export const IMAGE_SCALE: UnitRefPartialRecord = { percents: { min: 1, max: 1000, sliderMax: 300 } };
export const GRADIENT_POSITION_SCALE: UnitRefPartialRecord = { percents: { min: 100, max: 200 } };
export const IMAGE_PATTERN_SCALE: UnitRefPartialRecord = { percents: { min: 1, max: 1000, sliderMax: 100 } };
export const BORDER_WIDTH_SOLID: UnitRefPartialRecord = { pixels: { min: 0, max: 100 } };
export const BORDER_WIDTH_DOUBLE: UnitRefPartialRecord = { pixels: { min: 0, max: 100 } };
export const EDGE_SIZE: UnitRefPartialRecord = { pixels: { min: 0 } };
export const SIZE_RANGE: UnitRefPartialRecord = { pixels: { min: 6, max: 100 } };
export const LETTER_SPACING: UnitRefPartialRecord = { elements: { min: -0.1, max: 0.7, step: 0.05, shiftStep: 10 } };
export const LINE_HEIGHT: UnitRefPartialRecord = { elements: { min: 0.5, max: 3, step: 0.01, shiftStep: 10 } };
export const SINGLE_SHADOW_DISTANCE: UnitRefPartialRecord = { pixels: { min: 0, max: 15 } };
export const SINGLE_SHADOW_BLUR: UnitRefPartialRecord = { pixels: { min: 0, max: 100 } };
export const SINGLE_SHADOW_SPREAD: UnitRefPartialRecord = { pixels: { min: -100, max: 100 } };
export const GRADIENT_REPEATS: UnitRefPartialRecord = { pixels: { min: 0, max: 10 } };
export const GRADIENT_OFFSET: UnitRefPartialRecord = { pixels: { min: 0, max: 10 } };
export const GRADIENT_SIZE: UnitRefPartialRecord = { pixels: { min: 0, max: 100 } };
export const GRADIENT_STEPS: UnitRefPartialRecord = { pixels: { min: 2, max: 12 } };
export const FLEX_CHILD_SPACING: UnitRefPartialRecord = { pixels: { min: 0, max: 50 } };
export const CORNER_RADIUS: UnitRefPartialRecord = {
    pixels: { min: 0, max: MAX_INPUT_VALUE },
    elements: { min: 0.5, max: 5, step: 0.1, shiftStep: 1 },
    percents: { min: 0 },
};
