import { isInRect } from '@wix/stylable-panel-common-react';
import { useEffect } from 'react';

export function useClickOutside(ref: React.MutableRefObject<HTMLDivElement | null>, callback: () => void) {
    useEffect(() => {
        const onClickEvent = (event: MouseEvent) => {
            if (ref.current && !isInRect(event.clientX, event.clientY, ref.current.getBoundingClientRect())) {
                callback();
            }
        };
        document.addEventListener('click', onClickEvent);
        return () => {
            document.removeEventListener('click', onClickEvent);
        };
    }, [callback, ref]);
}
