import { useCallback, useMemo } from 'react';

import {
    CSSCodeAst,
    Backgrounds,
    createCssValueAST,
    valueTextNode,
    INITIAL_UNIVERSAL,
    INITIAL_BACKGROUND_COLOR,
    INITIAL_IMAGE_SOURCE,
} from '@wix/shorthands-opener';

import {
    EvalDeclarationValue,
    DEFAULT_LOAD_SITE_COLORS,
    DEFAULT_WRAP_SITE_COLOR,
    DEFAULT_EVAL_DECLARATION_VALUE,
} from '@wix/stylable-panel-drivers';

import type { DeclarationChangeValue } from '../../types';
import type {
    BackgroundDeclaration,
    BackgroundDeclarations,
    BackgroundDeclarationMap,
    BackgroundShorthandLayer,
    BackgroundProps,
    BackgroundVisualizerProps,
} from './background-types';
import { OpenedBackgroundShorthand, openBackgroundShorthand, closeBackgroundShorthand } from './background-shorthand';
import { BACKGROUND_LONGHAND_PROPS, ALL_BACKGROUND_PROPS } from './background-consts';
import {
    OriginNode,
    ParseShorthandAPI,
    EvaluatedAst,
    OpenedShorthandValue,
    DeclarationValue,
    BaseDeclarationNode,
    OpenedShortHandDeclarationNode,
    OpenedDeclaration,
    OpenedDeclarationArray,
    isDeclarationExpressionItem,
    isOpenedShortHand,
    getVariableExpression,
    createOpenedShorthandDeclarationNode,
    createNonCommittedDeclarationNode,
} from '../../declaration-types';
import {
    spaceNode,
    flatMap,
    flattenDeclarationShorthand,
    valueCommaNode,
    wrapDeclarationValue,
    wrapOpenedDeclarationsSiteColors,
    getPropValueDeclaration,
    wrapChangeWithLonghandsClear,
    createVisualizerValueFromDeclarationMap,
    createShorthandOpenerApi,
    sanitizeOpenedDeclaration,
    joinDeclarationValues,
    splitShorthandLayers,
    applyOpenedShorthandChangeTemplate,
    getChildChangesTemplate,
} from '../../utils';
import { FORMATTERS } from '../../utils/formatter-utils';

type OpenedBackgroundShortHandDeclarationNode = OpenedShortHandDeclarationNode<Backgrounds>;
type OpenedBackgroundLayer = Record<keyof BackgroundShorthandLayer, OpenedBackgroundShortHandDeclarationNode>;
type BackgroundChangeType = 'empty' | 'shorthand' | 'longhand';
interface ClassifiedBackgroundChange {
    type: BackgroundChangeType;
    value: BackgroundDeclaration;
}
interface OpenedBackgroundShorthandNodes {
    'background-color': OpenedBackgroundShortHandDeclarationNode;
    layers: OpenedBackgroundLayer[];
}
interface OpenedBackgroundDeclarationMap {
    'background-color': OpenedDeclarationArray<'background-color'>;

    'background-attachment': OpenedDeclarationArray<'background-attachment'>[];
    'background-clip': OpenedDeclarationArray<'background-clip'>[];
    'background-image': OpenedDeclarationArray<'background-image'>[];
    'background-origin': OpenedDeclarationArray<'background-origin'>[];
    'background-position': OpenedDeclarationArray<'background-position'>[];
    'background-repeat': OpenedDeclarationArray<'background-repeat'>[];
    'background-size': OpenedDeclarationArray<'background-size'>[];

    'background-position-x': OpenedDeclarationArray<'background-position-x'>[];
    'background-position-y': OpenedDeclarationArray<'background-position-y'>[];

    background: OpenedDeclarationArray<'background'>[];
}

const getOpenedBackgroundShorthandNodes = (
    originalDecl: BaseDeclarationNode<BackgroundProps>,
    shorthandValue: BackgroundDeclaration,
    shorthandApi: ParseShorthandAPI,
    evalDeclarationValue?: EvalDeclarationValue,
    stringifyExpression?: (v: OriginNode) => string,
    formatters?: string[]
): OpenedBackgroundShorthandNodes => {
    const createOpenedBackgroundShorthandDeclarationNode = (name: Backgrounds, value: OpenedShorthandValue) =>
        createOpenedShorthandDeclarationNode({
            name,
            value: flattenDeclarationShorthand(value),
            originalDecl,
            important: originalDecl.important,
        });

    const getOpenedBackgroundShorthandLayer = (layer: BackgroundShorthandLayer) =>
        (Object.keys(layer) as Array<keyof BackgroundShorthandLayer>).reduce((openedLayer, prop) => {
            openedLayer[prop] = createOpenedBackgroundShorthandDeclarationNode(prop, layer[prop]);
            return openedLayer;
        }, {} as OpenedBackgroundLayer);

    const opened = openBackgroundShorthand(
        shorthandValue,
        shorthandApi,
        evalDeclarationValue,
        stringifyExpression,
        (layers) => layers,
        formatters
    );

    return {
        'background-color': createOpenedBackgroundShorthandDeclarationNode('background-color', opened.color),
        layers: opened.layers.map(getOpenedBackgroundShorthandLayer),
    };
};

const openBackgroundDeclarationMap = (
    declarations: BackgroundDeclarations,
    shorthandApi: ParseShorthandAPI,
    evalDeclarationValue?: EvalDeclarationValue,
    stringifyExpression?: (v: OriginNode) => string,
    formatters?: string[]
) =>
    declarations.reduce(
        (declarationMap, current) => {
            const prop = current.name;
            if (current.value.length === 0 || !ALL_BACKGROUND_PROPS.includes(prop)) {
                return declarationMap;
            }
            const sanitizedCurrent = sanitizeOpenedDeclaration(current);
            if (prop !== 'background-color') {
                (declarationMap[prop] as BackgroundDeclarations[]).push(
                    splitShorthandLayers(sanitizedCurrent, shorthandApi)
                );
            } else if (prop === 'background-color') {
                declarationMap['background-color'].push(sanitizedCurrent as OpenedDeclaration<'background-color'>);
            }
            if (prop === 'background') {
                try {
                    const openedNodes = getOpenedBackgroundShorthandNodes(
                        current,
                        sanitizedCurrent,
                        shorthandApi,
                        evalDeclarationValue,
                        stringifyExpression,
                        formatters
                    );
                    BACKGROUND_LONGHAND_PROPS.forEach((prop) => {
                        if (prop === 'background-color') {
                            declarationMap['background-color'].push(
                                openedNodes['background-color'] as OpenedDeclaration<'background-color'>
                            );
                        } else {
                            if (prop !== 'background-position-x' && prop !== 'background-position-y') {
                                (declarationMap[prop] as BackgroundDeclarations[]).push(
                                    openedNodes.layers.map((layer) => layer[prop])
                                );
                            }
                        }
                    });
                } catch (e) {
                    console.warn(e);
                }
            }
            return declarationMap;
        },
        ALL_BACKGROUND_PROPS.reduce((acc, prop) => {
            acc[prop] = [];
            return acc;
        }, {} as OpenedBackgroundDeclarationMap)
    );

const handleBackgroundLonghandLayerChange = (
    currValues: BackgroundDeclarations,
    changeValue: DeclarationValue,
    action: SetBackgroundPropAction = 'modify',
    layerIndex?: number,
    targetLayerIndex?: number
) => {
    let layers = currValues.map(
        action !== 'add' && action !== 'refresh-color' && action !== 'duplicate' && action !== 'swap'
            ? (decl, index) => (decl.name === 'background-color' || index === layerIndex ? changeValue : decl.value)
            : (decl) => decl.value
    );
    switch (action) {
        case 'remove':
            layers = layers.filter((_decl, index) => index !== layerIndex);
            break;
        case 'add':
        case 'refresh-color':
            if (layerIndex !== undefined) {
                layers.splice(layerIndex, 0, changeValue);
            }
            break;
        case 'duplicate':
            if (layerIndex !== undefined) {
                layers.splice(layerIndex, 0, layers[layerIndex]);
            }
            break;
        case 'swap':
            if (layerIndex !== undefined && targetLayerIndex !== undefined && layerIndex !== targetLayerIndex) {
                const srcLayer = [...layers[layerIndex]];
                const srcLayerBefore = !isDeclarationExpressionItem(srcLayer[0]) ? srcLayer[0].before : [spaceNode()];
                const dstLayer = [...layers[targetLayerIndex]];
                const dstLayerBefore = !isDeclarationExpressionItem(dstLayer[0]) ? dstLayer[0].before : [spaceNode()];
                if (!isDeclarationExpressionItem(srcLayer[0])) {
                    srcLayer[0].before = dstLayerBefore;
                }
                if (!isDeclarationExpressionItem(dstLayer[0])) {
                    dstLayer[0].before = srcLayerBefore;
                }
                layers.splice(layerIndex, 1, dstLayer);
                layers.splice(targetLayerIndex, 1, srcLayer);
            }
            break;
    }
    // TODO: Add space before comma if there isn't one
    return joinDeclarationValues(layers, () => [valueCommaNode()]);
};

const wrapClassifiedBackgroundChange = (
    changeValue: BackgroundDeclarations,
    backgroundDeclarationMap: OpenedBackgroundDeclarationMap,
    props: BackgroundVisualizerProps,
    action: SetBackgroundPropAction = 'modify',
    layerIndex?: number,
    targetLayerIndex?: number
) =>
    changeValue.reduce((value, declaration) => {
        const prop = declaration.name;
        let newValue = declaration.value;
        let changeType: BackgroundChangeType = 'empty';
        const propValue = backgroundDeclarationMap[prop];
        let declarationListValue = (
            prop === 'background-color'
                ? propValue
                : prop !== 'background-position-x' && prop !== 'background-position-y'
                ? propValue[propValue.length - 1]
                : undefined
        ) as BackgroundDeclarations | undefined;

        if (declarationListValue) {
            if (declarationListValue.every((decl) => isOpenedShortHand(decl))) {
                changeType = 'shorthand';
                if (prop !== 'background-color') {
                    declarationListValue = layerIndex !== undefined ? [declarationListValue[layerIndex]] : props.value;
                }
            } else {
                changeType = 'longhand';
                newValue = handleBackgroundLonghandLayerChange(
                    declarationListValue,
                    newValue,
                    action,
                    layerIndex,
                    targetLayerIndex
                );
            }
        }

        if (action === 'remove' && changeType === 'shorthand' && newValue.length === 0) {
            newValue = [valueTextNode(INITIAL_UNIVERSAL)];
        }

        if (
            (action === 'add' || action === 'refresh-color') &&
            changeType === 'shorthand' &&
            declarationListValue &&
            declarationListValue[0] === undefined
        ) {
            declarationListValue = propValue[propValue.length - 1] as BackgroundDeclarations | undefined;
            if (declarationListValue) {
                declarationListValue = [declarationListValue[declarationListValue.length - 1]];
            }
        }

        value.push({
            type: changeType,
            value: wrapDeclarationValue(
                prop,
                newValue,
                declarationListValue ? getPropValueDeclaration(declarationListValue, prop) : undefined
            ),
        });

        return value;
    }, [] as ClassifiedBackgroundChange[]);

const handleBackgroundLonghandLayerRemove = (
    backgroundChange: ClassifiedBackgroundChange[],
    backgroundDeclarationMap: OpenedBackgroundDeclarationMap,
    props: BackgroundVisualizerProps,
    action: SetBackgroundPropAction = 'modify',
    layerIndex?: number
) => {
    if (
        action === 'remove' &&
        backgroundChange.length === 1 &&
        backgroundChange[0].type === 'longhand' &&
        backgroundChange[0].value.name === 'background-image'
    ) {
        return ALL_BACKGROUND_PROPS.reduce((longhandRemoveChanges, prop) => {
            if (
                prop !== 'background' &&
                prop !== 'background-color' &&
                prop !== 'background-image' &&
                prop !== 'background-position-x' &&
                prop !== 'background-position-y'
            ) {
                const propChanges = wrapClassifiedBackgroundChange(
                    [createNonCommittedDeclarationNode({ name: prop, value: [] })],
                    backgroundDeclarationMap,
                    props,
                    action,
                    layerIndex
                );
                if (propChanges.every((change) => change.type === 'longhand')) {
                    return longhandRemoveChanges.concat(propChanges);
                }
            }
            return longhandRemoveChanges;
        }, [] as ClassifiedBackgroundChange[]);
    }
    return [];
};

const applyOpenedBackgroundShorthandChange = (
    changeDecls: OpenedShortHandDeclarationNode<BackgroundProps>[],
    shorthandApi: ParseShorthandAPI,
    action: SetBackgroundPropAction = 'modify',
    evalDeclarationValue?: EvalDeclarationValue,
    stringifyExpression?: (v: OriginNode) => string,
    layerIndex?: number,
    formatters?: string[]
) => {
    let shouldDelete = action === 'remove';

    return applyOpenedShorthandChangeTemplate<'background', BackgroundProps, OpenedBackgroundShorthand>(
        changeDecls,
        shorthandApi,
        (value) => {
            return openBackgroundShorthand(
                value,
                shorthandApi,
                evalDeclarationValue,
                stringifyExpression,
                (shorthandLayers) => {
                    if (layerIndex !== undefined) {
                        switch (action) {
                            case 'add':
                            case 'refresh-color':
                            case 'duplicate':
                                shorthandLayers.splice(
                                    layerIndex,
                                    0,
                                    action === 'duplicate'
                                        ? shorthandLayers[layerIndex]
                                        : createNonCommittedDeclarationNode({
                                              name: 'background',
                                              value: createCssValueAST(INITIAL_IMAGE_SOURCE),
                                          })
                                );
                                break;
                        }
                    }
                    return shorthandLayers;
                },
                formatters
            );
        },
        (opened, prop, newValueAst) => {
            if (prop === 'background-color') {
                opened.color = newValueAst;
            } else if (
                layerIndex !== undefined &&
                prop !== 'background' &&
                prop !== 'background-position-x' &&
                prop !== 'background-position-y'
            ) {
                if (shouldDelete && action === 'remove') {
                    opened.layers.splice(layerIndex, 1);
                    shouldDelete = false;
                } else {
                    (opened.layers[layerIndex][prop] as EvaluatedAst) = newValueAst;
                }
            }
        },
        closeBackgroundShorthand
    );
};

const getBackgroundChanges = (
    changeValue: BackgroundDeclarations,
    backgroundDeclarationMap: OpenedBackgroundDeclarationMap,
    props: BackgroundVisualizerProps,
    shorthandApi: ParseShorthandAPI,
    action: SetBackgroundPropAction = 'modify',
    evalDeclarationValue?: EvalDeclarationValue,
    stringifyExpression?: (v: OriginNode) => string,
    layerIndex?: number,
    targetLayerIndex?: number,
    formatters?: string[]
) => {
    let backgroundChanges = wrapClassifiedBackgroundChange(
        changeValue,
        backgroundDeclarationMap,
        props,
        action,
        layerIndex,
        targetLayerIndex
    );
    backgroundChanges = backgroundChanges.concat(
        handleBackgroundLonghandLayerRemove(backgroundChanges, backgroundDeclarationMap, props, action, layerIndex)
    );

    return getChildChangesTemplate<'background', BackgroundProps>(
        backgroundChanges.map(({ value }) => value),
        shorthandApi,
        (decls) =>
            applyOpenedBackgroundShorthandChange(
                decls,
                shorthandApi,
                action,
                evalDeclarationValue,
                stringifyExpression,
                layerIndex,
                formatters
            )
    );
};

const openBackgroundDeclarationsForChange = (
    declarations: BackgroundDeclarations,
    shorthandApi: ParseShorthandAPI,
    formatters?: string[]
) =>
    flatMap(declarations, (decl) => {
        if (decl.name !== 'background' || decl.value.length === 0) {
            return [decl];
        }
        const opened = openBackgroundShorthand(decl, shorthandApi, undefined, undefined, undefined, formatters);
        if (opened.layers.length !== 1) {
            return [decl];
        }
        const changeLayer = opened.layers[0];
        let openDeclarations = (Object.keys(changeLayer) as Array<keyof BackgroundShorthandLayer>).reduce(
            (layerDeclarations, name) => {
                layerDeclarations.push(
                    createNonCommittedDeclarationNode({
                        name,
                        value: flattenDeclarationShorthand(changeLayer[name]),
                    })
                );
                return layerDeclarations;
            },
            [] as BackgroundDeclarations
        );
        if (opened.color.value.type !== 'text' || opened.color.value.text !== INITIAL_BACKGROUND_COLOR) {
            openDeclarations = openDeclarations.concat([
                createNonCommittedDeclarationNode({
                    name: 'background-color',
                    value: flattenDeclarationShorthand(opened.color),
                }),
            ]);
        }
        return openDeclarations;
    });

type SetBackgroundPropValue = DeclarationChangeValue | BackgroundDeclarationMap;
export type SetBackgroundPropAction = 'modify' | 'add' | 'refresh-color' | 'duplicate' | 'swap' | 'remove';
export interface SetBackgroundPropOptions {
    value: SetBackgroundPropValue;
    name?: BackgroundProps;
    action?: SetBackgroundPropAction;
    forceShorthand?: boolean;
    layerIndex?: number;
    targetLayerIndex?: number;
    defaultValue?: string;
}
type UseBackgroundVisualizerDriver = (props: BackgroundVisualizerProps) => {
    setProp?: (options: SetBackgroundPropOptions | SetBackgroundPropOptions[]) => void;
};

export const useBackgroundDriver: UseBackgroundVisualizerDriver = (props) => {
    const { value, drivers, panelHost, siteVarsDriver, onChange } = props;

    const shorthandApi = useMemo(() => createShorthandOpenerApi(drivers.variables), [drivers.variables]);
    const evalDeclarationValue = useMemo(
        () => siteVarsDriver?.evalDeclarationValue?.bind(siteVarsDriver) ?? DEFAULT_EVAL_DECLARATION_VALUE,
        [siteVarsDriver]
    );
    const loadSiteColors = useMemo(
        () => siteVarsDriver?.loadSiteColors?.bind(siteVarsDriver) ?? DEFAULT_LOAD_SITE_COLORS,
        [siteVarsDriver]
    );
    const wrapSiteColor = useMemo(
        () => siteVarsDriver?.wrapSiteColor?.bind(siteVarsDriver) ?? DEFAULT_WRAP_SITE_COLOR,
        [siteVarsDriver]
    );
    const backgroundDeclarationMap = useMemo(
        () =>
            openBackgroundDeclarationMap(
                value,
                shorthandApi,
                evalDeclarationValue,
                getVariableExpression,
                panelHost?.formatters ?? FORMATTERS
            ),
        [shorthandApi, evalDeclarationValue, value, panelHost?.formatters]
    );

    const getForcedColorRefresh = useCallback(
        (
            options: SetBackgroundPropOptions,
            openedDeclarations: BackgroundDeclarations,
            declarationMap: OpenedBackgroundDeclarationMap
        ): SetBackgroundPropOptions | undefined => {
            const { name = 'background', action = 'modify', forceShorthand = false, layerIndex } = options;

            if (!forceShorthand && action === 'refresh-color' && name === 'background' && openedDeclarations) {
                const isSingleTextNodeWithCompare = (prop: BackgroundProps, compare: (text: string) => boolean) => {
                    const decl = openedDeclarations.find((decl) => decl.name === prop);
                    if (decl && decl.value.length === 1) {
                        const node = decl.value[0] as CSSCodeAst;
                        return (node.type === 'text' || node.type === 'call') && compare(node.text);
                    }
                    return false;
                };

                if (
                    isSingleTextNodeWithCompare('background-image', (text) => text === INITIAL_IMAGE_SOURCE) &&
                    isSingleTextNodeWithCompare('background-color', (text) => text !== INITIAL_BACKGROUND_COLOR)
                ) {
                    const existingColorValue = backgroundDeclarationMap['background-color'];
                    const imageValue = declarationMap['background-image'];
                    const currImageValue = imageValue[imageValue.length - 1];
                    return layerIndex === currImageValue.length - 1
                        ? { name: 'background-color', action: 'modify', value: existingColorValue }
                        : { ...options, name: 'background', action: 'add', value: existingColorValue };
                }
            }

            return undefined;
        },
        [backgroundDeclarationMap]
    );

    const setPropInner = useCallback(
        (options: SetBackgroundPropOptions, declarationMap: OpenedBackgroundDeclarationMap): BackgroundDeclarations => {
            const {
                value,
                name = 'background',
                action = 'modify',
                forceShorthand = false,
                layerIndex,
                targetLayerIndex,
                defaultValue,
            } = options;
            const formatters = panelHost?.formatters ?? FORMATTERS;

            const declarations = (
                Array.isArray(value)
                    ? [{ ...value[0], name }]
                    : createVisualizerValueFromDeclarationMap(
                          value === null || typeof value === 'string' ? { [name]: value ?? defaultValue } : value,
                          true
                      )
            ) as BackgroundDeclarations;

            const openedDeclarations =
                !forceShorthand && layerIndex !== undefined
                    ? openBackgroundDeclarationsForChange(declarations, shorthandApi, formatters)
                    : declarations;

            const forcedColorRefreshOptions = getForcedColorRefresh(options, openedDeclarations, declarationMap);
            if (forcedColorRefreshOptions) {
                return setPropInner(forcedColorRefreshOptions, declarationMap);
            }

            return getBackgroundChanges(
                openedDeclarations,
                declarationMap,
                props,
                shorthandApi,
                action,
                evalDeclarationValue,
                getVariableExpression,
                layerIndex,
                targetLayerIndex,
                formatters
            );
        },
        [shorthandApi, evalDeclarationValue, getForcedColorRefresh, panelHost?.formatters, props]
    );

    const sendChange = useCallback(
        (value: BackgroundDeclarations) => {
            if (onChange) {
                let valueForChange = value;
                if (siteVarsDriver) {
                    loadSiteColors();
                    valueForChange = wrapOpenedDeclarationsSiteColors(valueForChange, wrapSiteColor);
                }
                valueForChange = wrapChangeWithLonghandsClear<'background', BackgroundProps>(
                    'background',
                    valueForChange,
                    Object.keys(backgroundDeclarationMap) as BackgroundProps[],
                    props.value,
                    true
                );
                onChange(valueForChange);
            }
        },
        [loadSiteColors, wrapSiteColor, backgroundDeclarationMap, siteVarsDriver, props.value, onChange]
    );

    const setProp = useMemo(
        () =>
            onChange
                ? (options: SetBackgroundPropOptions | SetBackgroundPropOptions[]) => {
                      const optionsList = Array.isArray(options) ? options : [options];
                      let currValue: BackgroundDeclarations | undefined;
                      optionsList.forEach((option) => {
                          const declarationMap = !currValue
                              ? backgroundDeclarationMap
                              : openBackgroundDeclarationMap(
                                    currValue,
                                    shorthandApi,
                                    evalDeclarationValue,
                                    getVariableExpression,
                                    panelHost?.formatters ?? FORMATTERS
                                );
                          currValue = setPropInner(option, declarationMap);
                      });
                      if (currValue) {
                          sendChange(currValue);
                      }
                  }
                : undefined,
        [
            shorthandApi,
            evalDeclarationValue,
            backgroundDeclarationMap,
            setPropInner,
            sendChange,
            panelHost?.formatters,
            onChange,
        ]
    );

    return { setProp };
};
