import type { Outlines } from '../shorthand-css-data';
import type { SimpleShorthandOpener, GetSimpleShorthandOpener, GetShorthandCloser } from '../shorthand-types';

import { outlineStyleDataType, lineWidthDataType, outlineColorDataType } from '../../css-data-types';
import {
    unorderedListShorthandOpener,
    createShorthandOpener,
    shorthandCloserTemplate,
    createShorthandCloser,
} from '../shorthand-parser-utils';

// outline
export const openOutlineShorthand: GetSimpleShorthandOpener<Outlines> = <V>() =>
    createShorthandOpener({
        prop: 'outline',
        parts: [
            { prop: 'outline-style', dataType: outlineStyleDataType },
            { prop: 'outline-width', dataType: lineWidthDataType },
            { prop: 'outline-color', dataType: outlineColorDataType },
        ],
        shorthandOpener: (astNodes, api, parts) => unorderedListShorthandOpener(parts)(astNodes, api),
    }) as SimpleShorthandOpener<V, Outlines>;

export const closeOutlineShorthand: GetShorthandCloser<Outlines> = <V>() =>
    createShorthandCloser<V, Outlines>(
        shorthandCloserTemplate<Outlines>`${'outline-style'} ${'outline-width'} ${'outline-color'}`
    );
