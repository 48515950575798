import type { FontPrefixes, FontSuffixes, Fonts, OpenedFontShorthand } from '../shorthand-css-data';
import type { OpenedShorthand, ShorthandOpener, ShorthandPart, GetShorthandCloser } from '../shorthand-types';

import {
    fontSingleValueDataType,
    fontStyleDataType,
    fontVariantDataType,
    fontWeightDataType,
    fontStretchDataType,
    fontSizeDataType,
    lineHeightDataType,
    fontFamilyDataType,
    COMMON_FONT_PREFIX_NORMAL,
} from '../../css-data-types';
import {
    matchDataType,
    unorderedListShorthandOpener,
    createShorthandOpener,
    shorthandCloserTemplate,
    createShorthandCloser,
} from '../shorthand-parser-utils';
import { NoMandatoryPartMatchError } from '../shorthand-parser-errors';

// TODO: Though not directly settable by font, the longhands font-size-adjust and font-kerning are also reset to their initial values.
// font
export const openFontShorthand = <V>(): ShorthandOpener<V, Fonts, OpenedFontShorthand<V>> => {
    const fontPrefixShorthandParts: ShorthandPart<V>[] = [
        { prop: 'font-style', dataType: fontStyleDataType, multipleItems: true },
        { prop: 'font-variant', dataType: fontVariantDataType },
        { prop: 'font-weight', dataType: fontWeightDataType },
        { prop: 'font-stretch', dataType: fontStretchDataType },
    ];
    const fontSizeShorthandPart: ShorthandPart<V> = {
        prop: 'font-size',
        dataType: fontSizeDataType,
        mandatory: true,
    };
    const fontSuffixShorthandParts: ShorthandPart<V>[] = [
        fontSizeShorthandPart,
        { prop: 'line-height', dataType: lineHeightDataType },
        {
            prop: 'font-family',
            dataType: fontFamilyDataType,
            multipleItems: true,
            mandatory: true,
        },
    ];
    const openFontShorthandPrefixInner = unorderedListShorthandOpener<V, FontPrefixes>(fontPrefixShorthandParts, {
        commonValue: COMMON_FONT_PREFIX_NORMAL,
    });
    const openFontShorthandSuffixInner = unorderedListShorthandOpener<V, FontSuffixes>(fontSuffixShorthandParts);

    return createShorthandOpener({
        prop: 'font',
        singleKeywordPart: { prop: 'font', dataType: fontSingleValueDataType },
        parts: fontPrefixShorthandParts.concat(fontSuffixShorthandParts) as ShorthandPart<V, Fonts>[],
        shorthandOpener: (astNodes, api) => {
            let opened: OpenedShorthand<V> = {};

            const prefixEndPart = fontSizeShorthandPart;
            let prefixEndIndex = -1;
            for (let i = 0; i < astNodes.length; i++) {
                if (matchDataType(prefixEndPart.dataType, astNodes, i)) {
                    prefixEndIndex = i;
                    break;
                }
            }
            if (prefixEndIndex !== -1) {
                opened = {
                    ...openFontShorthandPrefixInner(astNodes.slice(0, prefixEndIndex), api),
                    ...openFontShorthandSuffixInner(astNodes.slice(prefixEndIndex), api),
                };
            } else {
                throw new NoMandatoryPartMatchError('font', prefixEndPart.prop);
            }

            return opened;
        },
    }) as ShorthandOpener<V, Fonts, OpenedFontShorthand<V>>;
};

export const closeFontShorthand: GetShorthandCloser<Fonts> = <V>() =>
    createShorthandCloser<V, Fonts>(
        shorthandCloserTemplate<Fonts>`${'font-style'} ${'font-variant'} ${'font-weight'} ${'font-stretch'} ${'font-size'} / ${'line-height'} ${'font-family'}`
    );
